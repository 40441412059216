import React from 'react'
import PropTypes from 'prop-types'

import ScheduleTimeRange from '../ScheduleTimeRange'
import DateInput from '../../../../../FormComponents/DateInput'
import { scheduleStatuses } from '../../../../../../services/stores'

import styles from './form.module.scss'

const SpecialScheduleForm = props => {
  const { specialSchedules } = props

  const handleTimeChange = (index, time, type) => {
    const updatedSchedules = [ ...specialSchedules ]
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      [type]: time
    }
    props.onChange(updatedSchedules)
  }

  /**
   * handles date change
   *
   * @param {number} index - item index in the specialSchedules list
   * @param {Date} date - selected date
  */
  const handleDateChange = (index, date) => {
    const updatedSchedules = [ ...specialSchedules ]
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      date
    }
    props.onChange(updatedSchedules)
  }

  /**
   * when 'closed' check is clicked, switch the item state
   * and notify the parent
   *
   * @param {number} index - item index in the specialSchedules list
  */
  const handleClosedClick = index => {
    const updatedSchedules = [ ...specialSchedules ]
    const state = updatedSchedules[index].state === scheduleStatuses.CLOSED
      ? scheduleStatuses.OPEN
      : scheduleStatuses.CLOSED

    updatedSchedules[index] = {
      ...updatedSchedules[index],
      state
    }
    props.onChange(updatedSchedules)
  }

  const handleAddClick = () => {
    if (props.onAddSpecialSchedule) {
      props.onAddSpecialSchedule()
    }
  }

  /**
   * to delete a special schedule, we have to mark it as 'deleted',
   * so instead of removing an item (as we do with regular schedules),
   * we switch the 'deleted' flag for that particular item.
   *
   * @param {number} index - item index in the specialSchedules list
  */
  const handleRemoveClick = index => {
    let updatedSchedules = [ ...specialSchedules ]
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      deleted: true
    }

    // handle the first item selection for delete
    // we have to replace with an empty placeholder
    // so we always have something to fill out
    const activeScheds = updatedSchedules.filter(s => !s.deleted)
    if (activeScheds && activeScheds.length === 0) {
      updatedSchedules = [
        { state: null, deleted: false },
        ...updatedSchedules
      ]
    }
    props.onChange(updatedSchedules)
  }

  return (
    <div className={styles.container}>
      <div className={ styles.specialScheduleRow }>
        {
          specialSchedules
            .filter(s => !s.deleted)
            .map((s, i) => {
              const closeIsDisabled = !s.from && !s.to && s.state === null

              return (
                <div className={ styles.itemRow } key={ `time-item-${i}` }>
                  <div className={ styles.dateWrapper }>
                    <DateInput
                      placeholder='Date'
                      onChange={ date => handleDateChange(i, date) }
                      value={ s.date || null }
                    />
                  </div>
                  <div className={ styles.itemInputWrapper }>
                    <ScheduleTimeRange
                      index={ i }
                      schedule={ s }
                      closeIsDisabled={ closeIsDisabled }
                      onTimeChange={ handleTimeChange }
                      onClosedClick={ handleClosedClick }
                    />
                  </div>
                  {
                    (i > 0 || specialSchedules.length > 0) && (
                      <div className={ styles.btnRemove } onClick={ () => { handleRemoveClick(i) } } />
                    )
                  }
                </div>
              )
            })
        }
      </div>
      <div className={ styles.addSpecialSchedule } onClick={ handleAddClick }>
        + Add another special hour
      </div>
    </div>
  )
}

SpecialScheduleForm.propTypes = {
  store: PropTypes.object,
  specialSchedules: PropTypes.array,
  onChange: PropTypes.func,
  onAddSpecialSchedule: PropTypes.func,
  onRemoveSpecialSchedule: PropTypes.func
}

export default SpecialScheduleForm
