import shortid from 'shortid'
import _ from 'lodash'

import { userHasAccess } from './auth'

export const zsfSmsFooter = () => {
  return '\nReply STOP to opt-out\n'
}

export const journeyTriggers = {
  PRIVY: 'privy_signup',
  KEYWORD: 'sms_keyword',
}

export const journeyTypes = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DRAFT: 'draft',
}

export const steps = [
  { label: 'Setup', key: 'setup', completed: false },
  { label: 'Design message', key: 'design', completed: false },
  { label: 'Review & Activate', key: 'review', completed: false }
]

const triggerMap = {
  'privy_signup': 'Popup',
  'sms_keyword': 'Keyword',
  'toast_order': 'Toast Order',
  'square_order': 'Square Order'
}

export const triggers = [
  {
    value: journeyTriggers.PRIVY,
    label: 'Popup',
    paramLabel: 'Privy campaign ID',
    paramPlaceholder: 'Enter Privy campaign ID',
    onlyAdmin: false
  },
  {
    value: journeyTriggers.KEYWORD,
    label: 'Keyword',
    paramLabel: 'Keyword',
    paramPlaceholder: 'Enter SMS keyword',
    onlyAdmin: false
  },
  {
    value: 'toast_order',
    label: 'Toast Order',
    onlyAdmin: true
  },
  {
    value: 'square_order',
    label: 'Square Order',
    onlyAdmin: true
  },
]

export const getTriggers = (user) => {
  if (userHasAccess(user, [])) {
    return triggers
  }
  return triggers.filter(trigger => !trigger.onlyAdmin)
}

export const units = [
  { value: 'hour', label: 'Hours' },
  { value: 'minute', label: 'Minutes' }
]

export const statuses = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

/**
 * get steps with the `completed` flag set depending on which fields are completed
 *
 * @param {Object} journey
 *
 * @returns {Array} - list of steps
*/
export const getSteps = journey => {
  if (!journey) {
    return steps
  }
  const res = [ ...steps ]
  if (journey.journey_name && journey.journey_trigger_id && !_.isEmpty(journey.journey_trigger)) {
    res[0].completed = true
  }
  if (!_.isEmpty(journey.journey_messages)) {
    res[1].completed = true
  }
  return res
}

/**
 * map journey list to local structure
 *
 * @param {Object} data - journeys data to map
 * @param {Array} data.journeys - actual list of journeys received from the BE
 * @param {number} data.total - total journeys count
 *
 * @returns {Object} - mapped journeys
*/
export const mapJourneys = (data, chain) => {
  const items = data.journeys.map((item) => {
    // converting trigger like privy_signup to Privy Signup
    const triggerName = triggerMap[item.trigger] || item.trigger
    item.chainName = chain.name
    item.triggerName = triggerName
    return item
  })

  return {
    items,
    total: data.total
  }
}

/**
 * map a single journey
 *
 * @param data {Object} - data to map in
 *
 * @returns {Object} - mapped journey
*/
export const mapJourneyIn = (data) => {
  const selectedTrigger = triggers.find(t => t.value === data.trigger)
  const mappedJourney = {
    id: data.id,
    status: data.status,
    journey_name: data.name,
    journey_trigger: selectedTrigger,
    journey_trigger_id: data.trigger_id,
    shortlinks: data.shortlinks
  }

  if (data.messages && data.messages.length) {
    mappedJourney.journey_messages = data.messages.map(msg => {
      const selectedUnit = units.find(u => u.value === msg.unit)
      // check and handle footer generation
      const footerRegex = new RegExp(`[\\n\\s]*${zsfSmsFooter().replace(/\n/g, '')}[\\n\\s]*`, 'gim')
      let footerChecked = false
      if (msg.content.match(footerRegex)) {
        footerChecked = true
        msg.content = msg.content.replace(footerRegex, '').trim()
      }

      return {
        id: shortid.generate(),
        time: msg.delay,
        unit: selectedUnit,
        text: msg.content,
        mms: {
          image: _.get(msg, 'mms.image'),
          imageUrl: _.get(msg, 'mms.image_url'),
        },
        include_reply_footer: footerChecked,
        formIsValid: true
      }
    })
  }

  return mappedJourney
}

/**
 * checks if a message includes some shortlink defined for the journey
*/
export const getMessageShortlink = (journey, msg) => {
  const { shortlinks } = journey
  if (!journey || !msg || !msg.text) {
    return null
  }
  for (let i = 0; i < shortlinks.length; i++) {
    if (msg.text.match(new RegExp(`(^|\\s)${shortlinks[i].long_url}($|\\s)`, 'im'))) {
      return shortlinks[i]
    }
  }
  return null
}

export const mapJourneyListForSelectInput = (list = []) => {
  return list.map((journey) => {
    return {
      ...journey,
      label: journey.trigger_id,
      value: journey.id,
    }
  })
}
