export const types = {
  MMS_UPLOAD_IMAGE: 'MMS_UPLOAD_IMAGE',
  MMS_UPLOAD_IMAGE_RESOLVED: 'MMS_UPLOAD_IMAGE_RESOLVED',
  MMS_UPLOAD_IMAGE_REJECTED: 'MMS_UPLOAD_IMAGE_REJECTED',
  MMS_UPLOAD_CLEAR: 'MMS_UPLOAD_CLEAR'
}

export const actions = {
  uploadMMSImage(opts) {
    return {
      type: types.MMS_UPLOAD_IMAGE,
      opts
    }
  },

  uploadMMSImageResolved(data, id) {
    return {
      type: types.MMS_UPLOAD_IMAGE_RESOLVED,
      id,
      data
    }
  },

  uploadMMSImageRejected(error) {
    return {
      type: types.MMS_UPLOAD_IMAGE_REJECTED,
      error
    }
  },

  clearMMSImageState() {
    return {
      type: types.MMS_UPLOAD_CLEAR
    }
  }
}
