import _ from 'lodash'
import { types } from '../actions/Segment'

export const getPredicatesInitialState = () => {
  return [
    {
      type: 'or',
      predicates: [
        {
          type: 'and',
          predicates: []
        }
      ]
    }
  ]
}

export const initialState = {
  segment: {
    loading: false,
    error: null,
    data: null
  },
  segmentList: {
    loading: false,
    error: null,
    list: null
  },
  dynamicCampaignList: {
    loading: false,
    error: null,
    campaigns: null,
  },
  dynamicJourneyList: {
    loading: false,
    error: null,
    list: null
  },
  gettingDynamicSegment: false,
  dynamicSegment: {
    success: false,
    loading: false,
    error: null,
    data: {
      payload: {
        predicates: getPredicatesInitialState()
      }
    },
  },
  updateStatus: {
    submitting: false,
    success: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Create Segment
    case types.CREATE_SEGMENT_INTENT:
      return {
        ...state,
        segment: {
          loading: true,
          error: null,
          data: null
        }
      }
    case types.CREATE_SEGMENT_INTENT_RESOLVED:
      return {
        ...state,
        segment: {
          loading: false,
          error: null,
          data: action.data
        },
      }
    case types.CREATE_SEGMENT_INTENT_REJECTED:
      return {
        ...state,
        segment: {
          loading: false,
          error: action.error,
          data: null
        },
      }

    case types.CREATE_SEGMENT_CLEAR: {
      return {
        ...state,
        segment: initialState.segment
      }
    }

    case types.GET_SEGMENTS_INTENT:
      return {
        ...state,
        segmentList: {
          loading: true,
          error: null,
          list: null
        }
      }
    case types.GET_SEGMENTS_INTENT_RESOLVED:
      return {
        ...state,
        segmentList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_SEGMENTS_INTENT_REJECTED:
      return {
        ...state,
        segmentList: {
          loading: false,
          error: action.error,
          list: null
        },
      }
      // Get campaigns for dynamic segment predicate to select campaign
    case types.GET_SEGMENT_CAMPAIGNS_INTENT:
      return {
        ...state,
        dynamicCampaignList: {
          loading: true,
          error: null,
          campaigns: null,
        },
      }
    case types.GET_SEGMENT_CAMPAIGNS_INTENT_RESOLVED:
      return {
        ...state,
        dynamicCampaignList: {
          loading: false,
          error: null,
          ...action.data
        },
      }
    case types.GET_SEGMENT_CAMPAIGNS_INTENT_REJECTED:
      return {
        ...state,
        dynamicCampaignList: {
          loading: false,
          error: action.error,
          campaigns: null
        },
      }

    // Get journeys for dynamic segments
    case types.GET_SEGMENT_JOURNEYS_INTENT:
      return {
        ...state,
        dynamicJourneyList: {
          loading: true,
          error: null,
          list: null
        }
      }
    case types.GET_SEGMENT_JOURNEYS_INTENT_RESOLVED:
      return {
        ...state,
        dynamicJourneyList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_SEGMENT_JOURNEYS_INTENT_REJECTED:
      return {
        ...state,
        dynamicJourneyList: {
          loading: false,
          error: action.error,
          list: null
        },
      }

    case types.SET_PREDICATES: {
      return {
        ...state,
        dynamicSegment: {
          ...state.dynamicSegment,
          data: {
            ...state.dynamicSegment.data,
            payload: {
              predicates: [...action.data]
            }
          }
        }
      }
    }
    // dynamic segments
    case types.UPSERT_DYNAMIC_SEGMENT_INTENT:
      return {
        ...state,
        dynamicSegment: {
          ...state.dynamicSegment,
          success: false,
          loading: true,
          error: null,
        }
      }

    case types.UPSERT_DYNAMIC_SEGMENT_INTENT_RESOLVED:
      return {
        ...state,
        dynamicSegment: {
          ...state.dynamicSegment,
          loading: false,
          success: true,
          error: null,
          data: {
            ...state.dynamicSegment.data,
            //new updated data from backend response
            ...action.data
          },
          segmentId: _.get(action, 'data.id'),
        },
        segment: {
          ...state.segment,
          data: action.data
        }
      }

    case types.UPSERT_DYNAMIC_SEGMENT_INTENT_REJECTED:
      return {
        ...state,
        dynamicSegment: {
          ...state.dynamicSegment,
          loading: false,
          success: false,
          error: action.error,
          // to keep title and description of the segment data
          data: state.dynamicSegment.data
        }
      }

    case types.GET_DYNAMIC_SEGMENT_INTENT:
      return {
        ...state,
        gettingDynamicSegment: true,
        dynamicSegment: {
          ...state.dynamicSegment,
          loading: true,
          error: null,
          data: null
        }
      }

    case types.GET_DYNAMIC_SEGMENT_INTENT_RESOLVED:
      const data = { ...action.data }
      if (!data.payload) {
        data.payload = {
          predicates: getPredicatesInitialState()
        }
      }

      return {
        ...state,
        gettingDynamicSegment: false,
        dynamicSegment: {
          ...state.dynamicSegment,
          loading: false,
          error: null,
          data
        }
      }

    case types.GET_DYNAMIC_SEGMENT_INTENT_REJECTED:
      return {
        ...state,
        gettingDynamicSegment: false,
        dynamicSegment: {
          ...state.dynamicSegment,
          loading: false,
          error: action.error,
          data: null
        }
      }

    case types.CLEAR_DYNAMIC_SEGMENT:
      return {
        ...state,
        gettingDynamicSegment: false,
        dynamicSegment: {
          ...initialState.dynamicSegment,
          data: {
            payload: {
              predicates: getPredicatesInitialState()
            }
          },
        },
      }

    default:
      return state
  }
}
