export const types = {
  CAMPAIGN_CREATE_INTENT: 'CAMPAIGN_CREATE_INTENT',
  CAMPAIGN_CREATE_INTENT_RESOLVED: 'CAMPAIGN_CREATE_INTENT_RESOLVED',
  CAMPAIGN_CREATE_INTENT_REJECTED: 'CAMPAIGN_CREATE_INTENT_REJECTED',
  CAMPAIGN_CREATE_CLEAR: 'CAMPAIGN_CREATE_CLEAR',

  CAMPAIGN_UPDATE_INTENT: 'CAMPAIGN_UPDATE_INTENT',
  CAMPAIGN_UPDATE_INTENT_RESOLVED: 'CAMPAIGN_UPDATE_INTENT_RESOLVED',
  CAMPAIGN_UPDATE_INTENT_REJECTED: 'CAMPAIGN_UPDATE_INTENT_REJECTED',
  CAMPAIGN_UPDATE_CLEAR: 'CAMPAIGN_UPDATE_CLEAR',

  CAMPAIGN_SHORTLINK_CREATE_INTENT: 'CAMPAIGN_SHORTLINK_CREATE_INTENT',
  CAMPAIGN_SHORTLINK_CREATE_INTENT_RESOLVED: 'CAMPAIGN_SHORTLINK_CREATE_INTENT_RESOLVED',
  CAMPAIGN_SHORTLINK_CREATE_INTENT_REJECTED: 'CAMPAIGN_SHORTLINK_CREATE_INTENT_REJECTED',
  CAMPAIGN_SHORTLINK_CLEAR: 'CAMPAIGN_SHORTLINK_CLEAR',
  CAMPAIGN_CLEAR_ERRORS: 'CAMPAIGN_CLEAR_ERRORS',

  GET_SEGMENT_OPTIONS_INTENT: 'GET_SEGMENT_OPTIONS_INTENT',
  GET_SEGMENT_OPTIONS_INTENT_RESOLVED: 'GET_SEGMENT_OPTIONS_INTENT_RESOLVED',
  GET_SEGMENT_OPTIONS_INTENT_REJECTED: 'GET_SEGMENT_OPTIONS_INTENT_REJECTED',

  GET_CAMPAIGNS_INTENT: 'GET_CAMPAIGNS_INTENT',
  GET_CAMPAIGNS_INTENT_RESOLVED: 'GET_CAMPAIGNS_INTENT_RESOLVED',
  GET_CAMPAIGNS_INTENT_REJECTED: 'GET_CAMPAIGNS_INTENT_REJECTED',

  GET_CAMPAIGN_CHART_INTENT: 'GET_CAMPAIGN_CHART_INTENT',
  GET_CAMPAIGN_CHART_INTENT_RESOLVED: 'GET_CAMPAIGN_CHART_INTENT_RESOLVED',
  GET_CAMPAIGN_CHART_INTENT_REJECTED: 'GET_CAMPAIGN_CHART_INTENT_REJECTED',
  GET_CAMPAIGN_CHART_CLEAR : 'GET_CAMPAIGN_CHART_CLEAR',

  GET_CAMPAIGN_INTENT: 'GET_CAMPAIGN_INTENT',
  GET_CAMPAIGN_INTENT_RESOLVED: 'GET_CAMPAIGN_INTENT_RESOLVED',
  GET_CAMPAIGN_INTENT_REJECTED: 'GET_CAMPAIGN_INTENT_REJECTED',
  GET_CAMPAIGN_CLEAR: 'GET_CAMPAIGN_CLEAR',

  CAMPAIGN_SAVE_STEP_INTENT: 'CAMPAIGN_SAVE_STEP_INTENT',
  CAMPAIGN_SAVE_STEP_INTENT_RESOLVED: 'CAMPAIGN_SAVE_STEP_INTENT_RESOLVED',
  CAMPAIGN_SAVE_STEP_INTENT_REJECTED: 'CAMPAIGN_SAVE_STEP_INTENT_REJECTED',

  CAMPAIGN_SEND_TEST_MSG_INTENT: 'CAMPAIGN_SEND_TEST_MSG_INTENT',
  CAMPAIGN_SEND_TEST_MSG_INTENT_RESOLVED: 'CAMPAIGN_SEND_TEST_MSG_INTENT_RESOLVED',
  CAMPAIGN_SEND_TEST_MSG_INTENT_REJECTED: 'CAMPAIGN_SEND_TEST_MSG_INTENT_REJECTED',

  CAMPAIGN_GET_ESTIMATED_RECIPIENTS: 'CAMPAIGN_GET_ESTIMATED_RECIPIENTS',
  CAMPAIGN_GET_ESTIMATED_RECIPIENTS_RESOLVED: 'CAMPAIGN_GET_ESTIMATED_RECIPIENTS_RESOLVED',
  CAMPAIGN_GET_ESTIMATED_RECIPIENTS_REJECTED: 'CAMPAIGN_GET_ESTIMATED_RECIPIENTS_REJECTED',

  CAMPAIGN_CANCEL_INTENT: 'CAMPAIGN_CANCEL_INTENT',
  CAMPAIGN_CANCEL_INTENT_RESOLVED: 'CAMPAIGN_CANCEL_INTENT_RESOLVED',
  CAMPAIGN_CANCEL_INTENT_REJECTED: 'CAMPAIGN_CANCEL_INTENT_REJECTED',

  CAMPAIGN_SCHEDULE_INTENT: 'CAMPAIGN_SCHEDULE_INTENT',
  CAMPAIGN_SCHEDULE_INTENT_RESOLVED: 'CAMPAIGN_SCHEDULE_INTENT_RESOLVED',
  CAMPAIGN_SCHEDULE_INTENT_REJECTED: 'CAMPAIGN_SCHEDULE_INTENT_REJECTED',
}

export const actions = {
  createCampaign(data) {
    return {
      type: types.CAMPAIGN_CREATE_INTENT,
      data
    }
  },

  createCampaignResolved(data) {
    return {
      type: types.CAMPAIGN_CREATE_INTENT_RESOLVED,
      data
    }
  },

  createCampaignRejected(error) {
    return {
      type: types.CAMPAIGN_CREATE_INTENT_REJECTED,
      error
    }
  },

  createCampaignClear() {
    return {
      type: types.CAMPAIGN_CREATE_CLEAR
    }
  },

  updateCampaign(data) {
    return {
      type: types.CAMPAIGN_UPDATE_INTENT,
      data
    }
  },

  updateCampaignResolved(data) {
    return {
      type: types.CAMPAIGN_UPDATE_INTENT_RESOLVED,
      data
    }
  },

  updateCampaignRejected(error) {
    return {
      type: types.CAMPAIGN_UPDATE_INTENT_REJECTED,
      error
    }
  },

  updateCampaignClear() {
    return {
      type: types.CAMPAIGN_UPDATE_CLEAR
    }
  },

  clearErrors() {
    return {
      type: types.CAMPAIGN_CLEAR_ERRORS
    }
  },

  createShortLink(data) {
    return {
      type: types.CAMPAIGN_SHORTLINK_CREATE_INTENT,
      data
    }
  },

  createShortLinkResolved(data) {
    return {
      type: types.CAMPAIGN_SHORTLINK_CREATE_INTENT_RESOLVED,
      data
    }
  },

  createShortLinkRejected(error) {
    return {
      type: types.CAMPAIGN_SHORTLINK_CREATE_INTENT_REJECTED,
      error
    }
  },

  createShortLinkClear() {
    return {
      type: types.CAMPAIGN_SHORTLINK_CLEAR,
    }
  },

  getCampaigns(data) {
    return {
      type: types.GET_CAMPAIGNS_INTENT,
      data
    }
  },

  getCampaignsResolved(data) {
    return {
      type: types.GET_CAMPAIGNS_INTENT_RESOLVED,
      data
    }
  },

  getCampaignsRejected(error) {
    return {
      type: types.GET_CAMPAIGNS_INTENT_REJECTED,
      error
    }
  },

  getCampaign(campaignId) {
    return {
      type: types.GET_CAMPAIGN_INTENT,
      campaignId
    }
  },

  getCampaignResolved(data) {
    return {
      type: types.GET_CAMPAIGN_INTENT_RESOLVED,
      data
    }
  },

  getCampaignRejected(error) {
    return {
      type: types.GET_CAMPAIGN_INTENT_REJECTED,
      error
    }
  },

  getCampaignClear() {
    return {
      type: types.GET_CAMPAIGN_CLEAR,
    }
  },


  getSegmentOptions(data) {
    return {
      type: types.GET_SEGMENT_OPTIONS_INTENT,
      data
    }
  },

  getSegmentOptionsResolved(data) {
    return {
      type: types.GET_SEGMENT_OPTIONS_INTENT_RESOLVED,
      data
    }
  },

  getSegmentOptionsRejected(error) {
    return {
      type: types.GET_SEGMENT_OPTIONS_INTENT_REJECTED,
      error
    }
  },


  getCampaignChart(campaign) {
    return {
      type: types.GET_CAMPAIGN_CHART_INTENT,
      campaign
    }
  },

  getCampaignChartResolved(data) {
    return {
      type: types.GET_CAMPAIGN_CHART_INTENT_RESOLVED,
      data
    }
  },

  getCampaignChartRejected(error) {
    return {
      type: types.GET_CAMPAIGN_CHART_INTENT_REJECTED,
      error
    }
  },

  getCampaignChartClear() {
    return {
      type: types.GET_CAMPAIGN_CHART_CLEAR,
    }
  },

  sendTestMessage(data) {
    return {
      type: types.CAMPAIGN_SEND_TEST_MSG_INTENT,
      data
    }
  },

  sendTestMessageResolved(data) {
    return {
      type: types.CAMPAIGN_SEND_TEST_MSG_INTENT_RESOLVED,
      data
    }
  },

  sendTestMessageRejected(data) {
    return {
      type: types.CAMPAIGN_SEND_TEST_MSG_INTENT_REJECTED,
      data
    }
  },

  getEstimatedRecipients(campaignId) {
    return {
      type: types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS,
      campaignId
    }
  },

  getEstimatedRecipientsResolved(data) {
    return {
      type: types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS_RESOLVED,
      data
    }
  },

  getEstimatedRecipientsRejected(error) {
    return {
      type: types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS_REJECTED,
      error
    }
  },

  cancelCampaign(campaign) {
    return {
      type: types.CAMPAIGN_CANCEL_INTENT,
      campaign
    }
  },

  cancelCampaignResolved(data) {
    return {
      type: types.CAMPAIGN_CANCEL_INTENT_RESOLVED,
      data
    }
  },

  cancelCampaignRejected(error) {
    return {
      type: types.CAMPAIGN_CANCEL_INTENT_REJECTED,
      error
    }
  },

  scheduleCampaign(campaign) {
    return {
      type: types.CAMPAIGN_SCHEDULE_INTENT,
      campaign
    }
  },

  scheduleCampaignResolved(data) {
    return {
      type: types.CAMPAIGN_SCHEDULE_INTENT_RESOLVED,
      data
    }
  },

  scheduleCampaignRejected(error) {
    return {
      type: types.CAMPAIGN_SCHEDULE_INTENT_REJECTED,
      error
    }
  }
}
