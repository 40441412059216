import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'


import { Button } from '../../../FormComponents'
import './styles.scss'

const AutomationHeader = props => {
  const { title, isValid } = props
  const isPosting = useSelector(state => state.LoyaltyIvr.posting || state.LoyaltyIvr.rating.posting)

  const onClose = () => {
    window.location.hash = '#automations'
  }

  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit()
    }
  }

 
  return (
    <div className='eg-automation-header'>
      <div className='section'>
        <div className='close' onClick={onClose}/>
        <div className='title'>{ title }</div>
      </div>
      <div className='section'>
        <Button
          id='automation-publish-btn'
          text='PUBLISH'
          disabled={ !isValid }
          onClick={ handleSubmit }
          loading={ isPosting }
        />
      </div>
    </div>
  )
}

AutomationHeader.propTypes = {
  title: PropTypes.string,
  validator: PropTypes.object,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func
}

export default AutomationHeader
