import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import AutomationHeader from '../AutomationHeader'
import AutomationCard from '../AutomationCard'
import AutomationSms, { fields as smsFields } from './AutomationSms'
import LoyaltySignupPage, { fields as signupPageFields } from './LoyaltySignupPage'
import useValidator from '../../../../hooks/validator'
import { actions as ivrActions } from '../../../../actions/LoyaltyIvr'
import { actions as storeActions } from '../../../../actions/Store'
import { validateSchema } from '../../../../services/loyaltyIvr'


import './styles.scss'

const tabItems = [
  { key: 'sms', label: 'SMS' },
  { key: 'loyalty_signup', label: 'Loyalty Signup Page' }
]

const SmsLoyaltySettings = props => {
  const { store } = props
  const validator = useValidator({
    mode: 'all',
    defaultValues: {
      [smsFields.SENDER_NAME]: _.get(store, 'ivr.sender_name'),
      [smsFields.SMS_TEXT]: _.get(store, 'ivr.message'),
      [smsFields.LOYALTY_LINK]: _.get(store, 'ivr.url'),
      [smsFields.OPTIMIZED_PAGE]: String(_.get(store, 'ivr.optimized_page')) === 'true',
      [signupPageFields.HEADLINE]: _.get(store, 'ivr.loyalty_page.headline'),
      [signupPageFields.SUBTEXT]: _.get(store, 'ivr.loyalty_page.subtext'),
      [signupPageFields.DISCOUNT_AMOUNT]: _.get(store, 'ivr.loyalty_page.discount_amount')
    }
  })
  const [ selectedTab, setSelectedTab ] = useState(tabItems[0])
  const dispatch = useDispatch()
  const saved = useSelector(state => state.LoyaltyIvr.saved)
  const storeUuid = store.uuid

  useEffect(() => {
    if (saved) {
      window.location.hash = '#automations'
      dispatch(ivrActions.resetSaved())
      dispatch(storeActions.getStore({ restaurantUuid: storeUuid }))
    }
  }, [saved, dispatch, storeUuid])

  const handleTabChange = item => {
    setSelectedTab(item)
  }

  const handleSubmit = () => {
    dispatch(ivrActions.saveSmsLoyalty({
      store,
      ...validator.getValues()
    }))
  }

  const values = validator.watch()

  return (
    <div className='eg-sms-loyalty-settings'>
      <AutomationHeader
        title="SMS Loyalty Signup"
        validator={ validator }
        isValid={ validateSchema(values) }
        // values={ values }
        onSubmit={ handleSubmit }
      />
      <AutomationCard
        tabItems={ tabItems }
        selectedItem={ selectedTab }
        onTabChange={ handleTabChange }
      >
        <AutomationSms
          store={ store }
          visible={ selectedTab.key === 'sms' }
          validator={ validator }
          values={ values }
        />
        <LoyaltySignupPage
          store={ store }
          visible={ selectedTab.key === 'loyalty_signup' }
          validator={ validator }
          onSwitchTab={ () => handleTabChange(tabItems[0]) }
          values={ values }
        />
      </AutomationCard>
    </div>
  )
}

SmsLoyaltySettings.propTypes = {
  store: PropTypes.object.isRequired
}

export default SmsLoyaltySettings
