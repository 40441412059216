import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { userHasAccess, userRoles } from '../../../../services/auth'
import AccessControlledComponent from '../../../AccessControlledComponent'

import './styles.scss'

const IntegrationCard = (props) => {
  const user = useSelector(state => state.User.user)
  const isCheckingStatus = useSelector(state => state.OauthConnect.connecting)
  const loadingFivetranConnection = useSelector(state => state.OauthConnect.fivetranConnections.loading)
  const isStoreStatusChecking = useSelector(({Store}) => Store.integrationLoading)
  const clickable = userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE])
  const { title, subtitle, logoClassName, isConnected = false, onClick, isBeta = false  } = props

  const handleClick = (e) => {
    if (clickable) {
      onClick(e)
    }
  }

  const loading = isCheckingStatus || isStoreStatusChecking || loadingFivetranConnection

  return (
    <div className='eg-integration-card' onClick={ handleClick } data-testid={logoClassName}>
      { loading && <div className='loader' /> }
      { isConnected && (
        <div className='status' data-testid={`${logoClassName}-status`}>
          CONNECTED
        </div>
      )}
      <div className='wrapper'>
        <div className={logoClassName} />
      </div>
      <footer className='footer'>
        <div className='row'>
          <div className='title'>{ title } {isBeta && <span className='beta'>Beta</span>} </div>
          <AccessControlledComponent roles={ [userRoles.RESTAURANT_ADMIN, userRoles.SQUARE_WRITE] }>
            { !isConnected && <div onClick={onClick} className='arrow'/> }
          </AccessControlledComponent>
        </div>
        <div className='subtitle'>
          { subtitle }
        </div>
      </footer>
    </div>
  )
}

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  logoClassName: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  isBeta: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default IntegrationCard
