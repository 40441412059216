import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { statuses } from '../../../services/virtualNumbers'
import { actions as numberActions } from '../../../actions/VirtualNumber'
import { Button } from '../../FormComponents'

import LocalSpinner from '../../LocalSpinner'

import './styles.scss'

const statusClasses = {
  [statuses.ACTIVE]: 'active',
  [statuses.INACTIVE]: 'inactive'
}

const NumberList = props => {
  const {
    numbers,
    showNewNumber = false,
    location,
    match,
    error,
    getNumbers,
    clearNumberList
  } = props

  const restaurantUuid = _.get(match, 'params.uuid')

  useEffect(() => {
    if (numbers === null && !error) {
      getNumbers({ restaurantUuid })
    }
  }, [ getNumbers, numbers, restaurantUuid, error ])

  useEffect(() => {
    return () => {
      clearNumberList()
    }
  }, [ clearNumberList ])

  const handleItemClick = item => {
    if (item && item.forwardNumbers && item.forwardNumbers.length) {
      props.history.push(`#numbers/${item.id}/details`)
    } else {
      props.history.push(`#numbers/${item.id}/forward`)
    }
  }

  const handleNewNumberClick = () => {
    props.history.push('#numbers/create')
  }

  const selectedId = location.hash.split('/')[1]

  return (
    <div className='eg-number-list'>
      <div className='title'>Numbers</div>
      <div className='list'>
        {
          showNewNumber && (
            <div className={ `item ${selectedId === 'create' && 'selected'}` } onClick={ handleNewNumberClick }>
              New number
            </div>
          )
        }
        {
          numbers && numbers.length >= 0
            ? numbers.map(number => {
              const statusClass = statusClasses[number.status]
              const selectedClass = number.id === selectedId ? 'selected' : ''
              return (
                <div
                  className={ `item ${selectedClass} ${statusClass}` }
                  key={ number.id }
                  onClick={ () => handleItemClick(number) }
                >
                  { number.title }
                  <span className='itemdesc'>{number.formattedNumber}</span>
                </div>
              )
            })
            : (
              <div className='spinner'>
                <LocalSpinner />
              </div>
            )
        }
      </div>
      <div className='btn-new'>
        <Button
          text='Add new number'
          className='button'
          onClick={ handleNewNumberClick }
        />
      </div>
    </div>
  )
}

NumberList.propTypes = {
  numbers: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  showNewNumber: PropTypes.bool,
  error: PropTypes.bool,
  getNumbers: PropTypes.func,
  clearNumberList: PropTypes.func
}

const mapStateToProps = state => ({
  numbers: state.VirtualNumber.numbers,
  error: state.VirtualNumber.error
})

const mapDispatchToProps = dispatch => ({
  getNumbers(opts) {
    dispatch(numberActions.getNumbers(opts))
  },

  clearNumberList() {
    dispatch(numberActions.clearNumberList())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NumberList))
