import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './styles.scss'

const BackLink = props => {
  const { children, to, onClick } = props

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <div className='eg-back-link' onClick={handleClick}>
      <Link to={to} className='back-link'>
        {children}
      </Link>
    </div>
  )
}

BackLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
}

export default BackLink
