import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import LocalSpinner from '../../LocalSpinner'
import SegmentItem from './SegmentItem'
import { getNumberWithCommas } from '../../../utils/number'
import './styles.scss'


/**
 * Segment List
 *
 * @component
 * @example
 *
 *
 * const data = {
 *   loading: false,
 *   error: null,
 *   list: {
 *    items: []
 *   }
 * }
 *
 * return (
 *  <SegmentList data={SegmentList} />
 * )
*/
const SegmentList = ({ data, onItemClick, onEditClick }) => {
  const { loading, error, list = {} } = data
  const items = _.get(list, 'items') || []

  if(loading) {
    return (
      <div className='eg-segment-list'>
        <LocalSpinner className='loader' />
      </div>
    )
  }

  if(error) {
    return (
      <div className='eg-segment-list'>
        <div className='title'>Something went wrong.</div>
        <div className='no-result'>
          Please refresh the page. If the issue persist please contact us.
        </div>
      </div>
    )
  }

  if(items && items.length === 0) {
    return (
      <div className='eg-segment-list'>
        <div className='title'>No segments</div>
        <div className='no-result'>
        This is where you will see all created segments.
        </div>
      </div>
    )
  }

  const total= _.get(list, 'total')
  return (
    <div className='eg-segment-list'>
      <div className='total'> {getNumberWithCommas(total)} Segments </div>
      <div className='table'>
        <div className='column'>
          <div className='name'>NAME</div>
          <div className='subscriber'>SUBSCRIBERS</div>
          <div className='date-updated'>LAST EDITED</div>
          <div className='date-added'>DATE ADDED</div>
          <div className='opts-header' />
        </div>
        {
          items.map((item, index) => (
            <SegmentItem
              item={item}
              key={index}
              // onStatusChange={ (status) => onStatusChange(item, status) }
              onItemClick={ () => onItemClick(item) }
              onEditClick={ () => onEditClick(item) }
            />
          ))
        }
      </div>
    </div>
  )
}

SegmentList.propTypes = {
  data: PropTypes.object,
  onItemClick: PropTypes.func,
  onEditClick: PropTypes.func
}

export default SegmentList
