import React from 'react'
import PropTypes from 'prop-types'

import EmojiPicker from '../../EmojiPicker'

import './styles.scss'

export const types = {
  DEFAULT: 'default',
  WITH_SHORT_LINK: 'with_short_link'
}

/**
 * Custom textarea component
 *
 * @description it renders a textarea with additional features, passed as "addons"
 *
 * @component
 * @example
 *
 * const handleShortlinkClick = () => {
 *   // do something with the shortlink
 * }
 *
 * <CustomTextarea
 *  name: 'description'
 *  hasShortLink
 *  hasEmojis
 *  addons={[
 *    {
 *      component: () => <SomeAddonComponent />, // The addon component, which handles the actual feature
 *      trigger: () => <TriggerComponent /> // The addon trigger, e.g. a button that activates the addon
 *    },
 *    ...
 *  ]}
 * />
*/
const CustomTextarea = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    className = '',
    hasShortLink = false,
    hasEmojis = false,
    addons = [],
    onShortLinkClick,
    defaultValue,
    onEmojiPick,
    onChange
  } = props

  const onInputChange = (event) => {
    onChange && onChange(event)
  }

  const handleEmojiPick = emojiObject => {
    onEmojiPick(emojiObject)
  }

  const renderLinkShortener = () => {
    return (
      <div data-testid='eg-shortlink-trigger' className='shortlink-wrapper' onClick={ onShortLinkClick }>
        <div className='shortlink-icon'></div>
      </div>
    )
  }

  return (
    <div className='eg-custom-textarea'>
      <div className={ `textarea ${className}` }>
        <textarea
          id={ id || name }
          aria-label={ name }
          name={ name }
          placeholder='Message'
          type='text'
          defaultValue={ defaultValue }
          onChange={ onInputChange }
          ref={ ref }
        />
        {
          addons.map((addon, i) => (
            <div className='addon' key={ `textarea-addon-${i}` }>
              { addon.component() }
            </div>
          ))
        }
        <div className='utils-wrapper'>
          { hasShortLink && renderLinkShortener() }
          {
            hasEmojis && (
              <EmojiPicker onPick={ handleEmojiPick } />
            )
          }
          {
            addons.map((addon, i) => (
              addon.triggers(`addon-trigger-${i}`)
            ))
          }
        </div>
      </div>
    </div>
  )
})

CustomTextarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  shortLink: PropTypes.string,
  hasShortLink: PropTypes.bool,
  hasEmojis: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  addons: PropTypes.array,
  onShortLinkClick: PropTypes.func,
  onEmojiPick: PropTypes.func,
  onChange: PropTypes.func
}

export default CustomTextarea
