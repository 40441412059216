export const types = {
  GET_RATINGS_SUMMARY_INTENT: 'GET_RATINGS_SUMMARY_INTENT',
  GET_RATINGS_SUMMARY_INTENT_RESOLVED: 'GET_RATINGS_SUMMARY_INTENT_RESOLVED',
  GET_RATINGS_SUMMARY_INTENT_REJECTED: 'GET_RATINGS_SUMMARY_INTENT_REJECTED',

  GET_CHART_DATA_INTENT: 'GET_CHART_DATA_INTENT',
  GET_CHART_DATA_INTENT_RESOLVED: 'GET_CHART_DATA_INTENT_RESOLVED',
  GET_CHART_DATA_INTENT_REJECTED: 'GET_CHART_DATA_INTENT_REJECTED',

  GET_STORE_BREAKDOWN_INTENT: 'GET_STORE_BREAKDOWN_INTENT',
  GET_STORE_BREAKDOWN_INTENT_RESOLVED: 'GET_STORE_BREAKDOWN_INTENT_RESOLVED',
  GET_STORE_BREAKDOWN_INTENT_REJECTED: 'GET_STORE_BREAKDOWN_INTENT_REJECTED',

  GET_REVIEW_INTENT: 'GET_REVIEW_INTENT',
  GET_REVIEW_INTENT_RESOLVED: 'GET_REVIEW_INTENT_RESOLVED',
  GET_REVIEW_INTENT_REJECTED: 'GET_REVIEW_INTENT_REJECTED',

  GET_REVIEWS_INTENT: 'GET_REVIEWS_INTENT',
  GET_REVIEWS_INTENT_RESOLVED: 'GET_REVIEWS_INTENT_RESOLVED',
  GET_REVIEWS_INTENT_REJECTED: 'GET_REVIEWS_INTENT_REJECTED',

  SEND_REPLY_INTENT: 'SEND_REPLY_INTENT',
  SEND_REPLY_INTENT_RESOLVED: 'SEND_REPLY_INTENT_RESOLVED',
  SEND_REPLY_INTENT_REJECTED: 'SEND_REPLY_INTENT_REJECTED',

  GET_EXPORT_REVIEWS_INTENT: 'GET_EXPORT_REVIEWS_INTENT',

}

export const actions = {
  getRatingsSummary(opts) {
    return {
      type: types.GET_RATINGS_SUMMARY_INTENT,
      opts
    }
  },

  getRatingsSummaryResolved(data) {
    return {
      type: types.GET_RATINGS_SUMMARY_INTENT_RESOLVED,
      data
    }
  },

  getRatingsSummaryRejected(error) {
    return {
      type: types.GET_RATINGS_SUMMARY_INTENT_REJECTED,
      error
    }
  },

  getChartData(opts) {
    return {
      type: types.GET_CHART_DATA_INTENT,
      opts
    }
  },

  getChartDataResolved(data) {
    return {
      type: types.GET_CHART_DATA_INTENT_RESOLVED,
      data
    }
  },

  getChartDataRejected(error) {
    return {
      type: types.GET_CHART_DATA_INTENT_REJECTED,
      error
    }
  },

  getStoreBreakDown(opts) {
    return {
      type: types.GET_STORE_BREAKDOWN_INTENT,
      opts
    }
  },

  getStoreBreakDownResolved(data) {
    return {
      type: types.GET_STORE_BREAKDOWN_INTENT_RESOLVED,
      data
    }
  },

  getStoreBreakDownRejected(error) {
    return {
      type: types.GET_STORE_BREAKDOWN_INTENT_REJECTED,
      error
    }
  },

  getReview(opts) {
    return {
      type: types.GET_REVIEW_INTENT,
      opts
    }
  },

  getReviewResolved(data) {
    return {
      type: types.GET_REVIEW_INTENT_RESOLVED,
      data
    }
  },

  getReviewRejected(error) {
    return {
      type: types.GET_REVIEW_INTENT_REJECTED,
      error
    }
  },

  sendReply(opts) {
    return {
      type: types.SEND_REPLY_INTENT,
      opts
    }
  },

  sendReplyResolved(data) {
    return {
      type: types.SEND_REPLY_INTENT_RESOLVED,
      data
    }
  },

  sendReplyRejected(error) {
    return {
      type: types.SEND_REPLY_INTENT_REJECTED,
      error
    }
  },

  getReviews(opts) {
    return {
      type: types.GET_REVIEWS_INTENT,
      opts
    }
  },

  getReviewsResolved(data) {
    return {
      type: types.GET_REVIEWS_INTENT_RESOLVED,
      data
    }
  },

  getReviewsRejected(error) {
    return {
      type: types.GET_REVIEWS_INTENT_REJECTED,
      error
    }
  },

  getExportReview(opts) {
    return {
      type: types.GET_EXPORT_REVIEWS_INTENT,
      opts
    }
  },
}
