import { types } from '../actions/Campaign'

const allTimeSegments = [
  { value: 'all_opted_in_customers', label: 'All subscribers' },
]

export const initialState = {
  campaignList: {
    loading: false,
    error: null,
    campaigns: null,
  },
  campaignCancel: {
    submitting: false,
    error: null
  },
  campaignUpdate: {
    submitting: false,
    success: false,
    data: {},
    error: null
  },
  campaignCreate: {
    submitting: false,
    success: false,
    data: {},
    error: null
  },
  shortLink: {
    submitting: false,
    success: false,
    data: null,
    error: null
  },
  campaignSchedule: {
    data: null,
    submitting: false,
    error: null
  },
  campaign: {
    data: {}
  },
  segmentOptions: {
    loading: false,
    error: null,
    list: null,
  },
  chart: {
    data: null,
    submitting: false,
    error: null
  },
  submittingTestMsg: false,
  testMsgSubmitted: false,
  testMsgFailed: false,
  loadingEstimatedRecipients: true,
  error: null
}

export default (state = initialState, action) => {
  switch(action.type) {
    // Create campaign
    case types.CAMPAIGN_CREATE_INTENT:
      return {
        ...state,
        campaignCreate: {
          ...state.campaignCreate,
          success: false,
          submitting: true,
          error: null
        }
      }
    case types.CAMPAIGN_CREATE_INTENT_RESOLVED:
      return {
        ...state,
        campaign: {
          data: action.data,
        },
        campaignCreate: {
          ...state.campaignCreate,
          success: true,
          submitting: false,
          data: action.data,
          error: null
        }
      }
    case types.CAMPAIGN_CREATE_INTENT_REJECTED:
      return {
        ...state,
        campaignCreate: {
          ...state.campaignCreate,
          success: false,
          submitting: false,
          error: action.error
        }
      }

    case types.CAMPAIGN_CREATE_CLEAR:
      return {
        ...state,
        campaignCreate: initialState.campaignCreate
      }

    // Update (patch) campaign
    case types.CAMPAIGN_UPDATE_INTENT:
      return {
        ...state,
        campaignUpdate: {
          ...state.campaignUpdate,
          error: null,
          success: false,
          submitting: true
        }
      }
    case types.CAMPAIGN_UPDATE_INTENT_RESOLVED:
      return {
        ...state,
        campaign: {
          // persisting campaign data
          data: action.data
        },
        campaignUpdate: {
          ...state.campaignUpdate,
          success: true,
          error: null,
          submitting: false
        }
      }
    case types.CAMPAIGN_UPDATE_INTENT_REJECTED:
      return {
        ...state,
        campaignUpdate: {
          ...state.campaignUpdate,
          error: action.error,
          success: false,
          submitting: false
        }
      }
    case types.CAMPAIGN_UPDATE_CLEAR:
      return {
        ...state,
        // Clearing all the updated state from update flow, line number 99.
        campaign: initialState.campaign,
        campaignCreate: initialState.campaignCreate,
        campaignUpdate: initialState.campaignUpdate,
        campaignSchedule: initialState.campaignSchedule,
      }

    case types.CAMPAIGN_CLEAR_ERRORS:
      return {
        ...state,
        // Clearing all the updated state from update flow, line number 99.
        campaignUpdate: {
          ...state.campaignUpdate,
          error: null
        },
        campaignSchedule: {
          ...state.campaignSchedule,
          error: null
        },
      }

    // Get campaigns
    case types.GET_CAMPAIGNS_INTENT:
      return {
        ...state,
        campaignList: {
          loading: true,
          error: null,
          campaigns: null,
        },
      }
    case types.GET_CAMPAIGNS_INTENT_RESOLVED:
      return {
        ...state,
        campaignList: {
          loading: false,
          error: null,
          ...action.data
        },
      }
    case types.GET_CAMPAIGNS_INTENT_REJECTED:
      return {
        ...state,
        campaignList: {
          loading: false,
          error: action.error,
          campaigns: null
        },
      }

    // Get single campaign
    case types.GET_CAMPAIGN_INTENT:
      return {
        ...state,
        campaign: {
          loading: true,
          error: null,
          data: null,
        },
      }
    case types.GET_CAMPAIGN_INTENT_RESOLVED:
      return {
        ...state,
        campaign: {
          loading: false,
          error: null,
          data: action.data
        },
      }
    case types.GET_CAMPAIGN_INTENT_REJECTED:
      return {
        ...state,
        campaign: {
          loading: false,
          error: action.error,
          campaigns: null
        },
      }

    case types.GET_CAMPAIGN_CLEAR: {
      return {
        ...state,
        campaign: initialState.campaign,
      }
    }

    case types.GET_SEGMENT_OPTIONS_INTENT:
      return {
        ...state,
        segmentOptions: {
          loading: true,
          error: null,
          list: null
        }
      }
    case types.GET_SEGMENT_OPTIONS_INTENT_RESOLVED:
      return {
        ...state,
        segmentOptions: {
          loading: false,
          error: null,
          list: [ ...allTimeSegments,  ...action.data]
        },
      }
    case types.GET_SEGMENT_OPTIONS_INTENT_REJECTED:
      return {
        ...state,
        segmentOptions: {
          loading: false,
          error: action.error,
          list: null,
        },
      }


    case types.GET_CAMPAIGN_CHART_INTENT:
      return {
        ...state,
        chart: {
          data: null,
          submitting: true,
          error: null
        },
      }
    case types.GET_CAMPAIGN_CHART_INTENT_RESOLVED:
      return {
        ...state,
        chart: {
          data: action.data,
          submitting: false,
          error: null
        },
      }
    case types.GET_CAMPAIGN_CHART_INTENT_REJECTED:
      return {
        ...state,
        chart: {
          data: null,
          submitting: false,
          error: action.error
        },
      }
    case types.GET_CAMPAIGN_CHART_CLEAR:
      return {
        ...state,
        chart: initialState.chart
      }

      // generate short link
    case types.CAMPAIGN_SHORTLINK_CREATE_INTENT:
      return {
        ...state,
        shortLink: {
          submitting: true,
          success: false,
          data: null,
          error: null
        },
      }
    case types.CAMPAIGN_SHORTLINK_CREATE_INTENT_RESOLVED:
      return {
        ...state,
        //TODO: Refactor the code to have same source of truth for campaign, create and update
        // Probably we don't need campaignCreate information
        campaignCreate: {
          ...state.campaignCreate,
          data: action.data
        },
        campaign: {
          ...state.campaign,
          data: action.data,
        },
        shortLink: {
          submitting: false,
          success: true,
          data: action.data,
          error: null
        },
      }
    case types.CAMPAIGN_SHORTLINK_CREATE_INTENT_REJECTED:
      return {
        ...state,
        shortLink: {
          submitting: false,
          success: false,
          data: null,
          error: action.error
        },
      }
    case types.CAMPAIGN_SHORTLINK_CLEAR:
      return {
        ...state,
        shortLink: {
          submitting: false,
          success: false,
          data: null,
          error: null
        },
      }

    // Send SMS test message
    case types.CAMPAIGN_SEND_TEST_MSG_INTENT:
      return {
        ...state,
        testMsgSubmitted: false,
        testMsgFailed: false,
        submittingTestMsg: true
      }
    case types.CAMPAIGN_SEND_TEST_MSG_INTENT_RESOLVED:
      return {
        ...state,
        testMsgSubmitted: true,
        testMsgFailed: false,
        submittingTestMsg: false
      }
    case types.CAMPAIGN_SEND_TEST_MSG_INTENT_REJECTED:
      return {
        ...state,
        testMsgSubmitted: false,
        testMsgFailed: true,
        submittingTestMsg: false
      }

    // Get estimated recipients
    case types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS:
      return {
        ...state,
        loadingEstimatedRecipients: true
      }
    case types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS_RESOLVED:
      return {
        ...state,
        loadingEstimatedRecipients: false,
        recipients: action.data.estimated_customer_count
      }
    case types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS_REJECTED:
      return {
        ...state,
        loadingEstimatedRecipients: false,
        error: action.error
      }

    // Cancel scheduled campaign
    case types.CAMPAIGN_CANCEL_INTENT:
      return {
        ...state,
        campaignCancel: {
          ...state.campaignCancel,
          error: null,
          submitting: true
        }
      }
    case types.CAMPAIGN_CANCEL_INTENT_RESOLVED:
      return {
        ...state,
        campaignCancel: {
          ...state.campaignCancel,
          error: null,
          submitting: false
        }
      }
    case types.CAMPAIGN_CANCEL_INTENT_REJECTED:
      return {
        ...state,
        campaignCancel: {
          ...state.campaignCancel,
          error: action.error,
          submitting: false
        }
      }

    case types.CAMPAIGN_SCHEDULE_INTENT:
      return {
        ...state,
        campaignSchedule: {
          ...state.campaignSchedule,
          error: null,
          submitting: true
        }
      }

    case types.CAMPAIGN_SCHEDULE_INTENT_RESOLVED:
      return {
        ...state,
        campaignSchedule: {
          ...state.campaignSchedule,
          error: null,
          submitting: false,
          data: action.data,
        }
      }

    case types.CAMPAIGN_SCHEDULE_INTENT_REJECTED:
      return {
        ...state,
        campaignSchedule: {
          ...state.campaignSchedule,
          error: action.error,
          submitting: false
        }
      }

    default:
      return state
  }
}
