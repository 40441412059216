import { types } from '../actions/Chain'

export const initialState = {
  chainList: {
    chains: []
  },
  loadingChains: false,
  searching: false,
  navigating: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHAINS_INTENT:
      return {
        ...state,
        loadingChains: true
      }

    case types.GET_CHAINS_INTENT_RESOLVED:
      const updatedList = {
        ...action.data,
        chains: [
          ...state.chainList.chains,
          ...action.data.chains
        ]
      }
      return {
        ...state,
        chainList: updatedList,
        loadingChains: false
      }

    case types.GET_CHAINS_INTENT_REJECTED:
      return {
        ...state,
        loadingChains: false,
        error: true
      }

    // TODO: double-check the use of this reducer type
    case types.SWITCH_CHAIN:
      return {
        ...state,
        chain: action.chain
      }

    case types.SEARCH_CHAINS_INTENT:
      return {
        ...state,
        searching: true,
        navigating: true
      }

    case types.SEARCH_CHAINS_INTENT_RESOLVED:
      return {
        ...state,
        chainList: {
          ...action.data,
          chains: action.data.chains
        },
        searching: false,
        navigating: true
      }

    case types.SEARCH_CHAINS_INTENT_REJECTED:
      return {
        ...state,
        searching: false,
        error: true
      }

    case types.RESET_LIST:
      return initialState

    default:
      return state
  }
}
