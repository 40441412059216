import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const labels = {
  [statuses.ACTIVE]: 'Active',
  [statuses.INACTIVE]: 'Inactive',
}

const StatusWidget = props => {
  const { status, className = '' } = props
  const classes = classNames([{
    'eg-status-widget': true
  }, status, className])

  return (
    <div className={ classes }>
      { labels[status] }
    </div>
  )
}

StatusWidget.propTypes = {
  status: PropTypes.oneOf(Object.values(statuses)),
  className: PropTypes.string
}

export default StatusWidget
