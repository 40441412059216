import React, { useEffect, createRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar }  from 'react-modern-calendar-datepicker'
import PopupFooter from './PopupFooter'
import { CloseButton } from '../../../components/FormComponents'
import './styles.scss'

const todayDate = moment()

/**
 * React Calendar Date picker expects the date object in a specific format. so * this function converts into that kind of date object
 * @param {Object} dateObj A date object
 * @returns {Object} {day: 3, month: 8, year: 2021} <== Date format object for * React Modern calendar date picker.
 */
export const getFormattedDateObject = (dateObj) => {
  return {
    day: parseInt(dateObj.format('D')),
    month: parseInt(dateObj.format('M')),
    year: parseInt(dateObj.format('YYYY'))
  }
}

export const timeIntervals = [
  {
    key: 'today',
    label: 'Today',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(todayDate.clone()),
    }
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
    range: {
      to: getFormattedDateObject(todayDate.clone().subtract(1, 'days')),
      from: getFormattedDateObject(todayDate.clone().subtract(1, 'days')),
    }
  },
  {
    key: 'this_week',
    label: 'This Week',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(todayDate.clone().startOf('isoWeek')),
    }
  },
  {
    key: 'last_week',
    label: 'Last Week',
    range: {
      to: getFormattedDateObject(todayDate.clone().subtract(1, 'weeks').endOf('isoWeek')),
      from: getFormattedDateObject(todayDate.clone().subtract(1, 'weeks').startOf('isoWeek')),
    }
  },
  {
    key: 'last_14_days',
    label: 'Last 14 days',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(todayDate.clone().subtract(13, 'days')),
    }
  },
  {
    key: 'this_month',
    label: 'This Month',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(todayDate.clone().startOf('month')),
    }
  },
  {
    key: 'last_month',
    label: 'Last Month',
    range: {
      to: getFormattedDateObject(todayDate.clone().subtract(1, 'months').endOf('month')),
      from: getFormattedDateObject(todayDate.clone().subtract(1, 'months').startOf('month')),
    }
  },
  {
    key: 'this_year',
    label: 'This Year',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(todayDate.clone().startOf('year')),
    }
  },
  {
    key: 'last_year',
    label: 'Last Year',
    range: {
      to: getFormattedDateObject(todayDate.clone().subtract(1, 'years').endOf('year')),
      from: getFormattedDateObject(todayDate.clone().subtract(1, 'years').startOf('year')),
    }
  },
  {
    key: 'all_time',
    label: 'All Time',
    range: {
      to: getFormattedDateObject(todayDate.clone()),
      from: getFormattedDateObject(moment('01/01/1970')),
    }
  },
]

const Popup = (props) => {
  const fromInputRef = createRef()
  const toInputRef = createRef()
  const {
    onClose,
    onItemChange,
    selectedItem,
    selectedDayRange,
  } = props
  const modalRef = createRef()

  const onCloseClick = (event) => {
    event.stopPropagation()
    onClose()
  }

  const onPageClick = useCallback((e) =>{
    if (modalRef.current && !modalRef.current.contains(e.target)){
      // Clicked outside the box
      onClose()
    }
  }, [modalRef, onClose])

  useEffect(() => {
    window.addEventListener('click', onPageClick)
    return () => {
      window.removeEventListener('click', onPageClick)
    }
  }, [onPageClick])

  /**
   * Updating the value in custom input date boxes in footer. i.e Start and End labels
   * @param {Object} data {from:..., to:...}
   */
  const updateCustomDateInputs = (data) => {
    const { from, to } = data
    const fromDateString = from ? moment(new Date(`${from.year}/${from.month}/${from.day}`)).format('YYYY-MM-DD') : ''
    const toDateString = to ? moment(new Date(`${to.year}/${to.month}/${to.day}`)).format('YYYY-MM-DD') : ''
    if(fromInputRef && fromInputRef.current) {
      fromInputRef.current.value = fromDateString
    }
    if(toInputRef && toInputRef.current) {
      toInputRef.current.value = toDateString
    }
  }

  const onIntervalClick = (option) => {
    updateCustomDateInputs(option.range)
    onItemChange(option)
  }

  const onCalendarRangeChange = (data) => {
    const { from, to } = data
    updateCustomDateInputs(data)
    let label
    // Starting date selection
    if(from) {
      label= `${from.month}/${from.day}/${from.year} - `
    }
    // End date selected
    if(to) {
      label = `${label}${to.month}/${to.day}/${to.year}`
    }
    onItemChange({
      label,
      range: data
    })
  }

  const onDateInputChange = (data) => {
    const { from, to } = data
    let label
    // Starting date selection
    if(from) {
      label= `${from.month}/${from.day}/${from.year} - `
    }
    // End date selected
    if(to) {
      label = `${label}${to.month}/${to.day}/${to.year}`
    }
    onItemChange({
      label,
      range: data
    })
  }

  return (
    <div className='eg-time-options' ref={modalRef}>
      <div className='mobile-header'>
        <div className='title'>Select date</div>
        <CloseButton className='close' onClick={ onCloseClick } />
      </div>
      <div className='left-part'>
        {
          timeIntervals.map((option) => {
            const isSelected = selectedItem.key === option.key
            const containerClassNames = `time-option ${isSelected && 'selected'}`
            return (
              <div
                className={containerClassNames}
                onClick={() => onIntervalClick(option)}
                key={option.key}
              >
                {option.label}
              </div>
            )
          })
        }
      </div>
      <div className='right-part'>
        <Calendar
          value={selectedDayRange}
          onChange={onCalendarRangeChange}
          colorPrimary='#22998F'
          colorPrimaryLight='#D5F2F0'
          calendarClassName='calendar-container'
          calendarRangeStartClassName='starting-day'
          calendarRangeEndClassName='ending-day'
          maximumDate={getFormattedDateObject(todayDate)}
        />
        <PopupFooter
          selectedItem={selectedItem}
          onChange={onDateInputChange}
          fromInputRef={fromInputRef}
          toInputRef={toInputRef}
        />
      </div>
    </div>
  )
}


Popup.propTypes = {
  onClose: PropTypes.func,
  onItemChange: PropTypes.func,
  selectedItem: PropTypes.object,
  selectedDayRange: PropTypes.object,
}

export default Popup
