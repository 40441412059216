import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * create number
 *
 * @param {object} options
 * @param {object} options.data - new number information
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - resolves with number information, including created uuid
 */
export const createNumber = (options, tokens) => {
  const { data } = options
  const uuid = data.restaurantUuid
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${uuid}/twilio/phone_numbers`,
    method: METHODS.POST,
    data: {
      location: data.location,
      purpose: data.purpose,
      title: data.title
    },
    headers: HeaderFactory(tokens)
  })
}

/**
 * get number by uuid
 *
 * @param {object} options
 * @param {string} options.id - number uuid
 * @param {string} options.restaurantUuid - selected restaurant/store
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - resolves with virtual number details
*/
export const getNumber = (options, tokens) => {
  const { id, restaurantUuid } = options
  const uuid = restaurantUuid
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${uuid}/twilio/phone_numbers/${id}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get a list of numbers
 *
 * @param {object} options
 * @param {string} options.restaurantUuid - selected restaurant/store
 * @param {object} options.opts - extra options (e.g. sort, page, size)
 *
 * @returns {Promise} - resolves with a list of number objects
*/
export const getNumbers = (options, tokens) => {
  const { restaurantUuid } = options
  const uuid = restaurantUuid
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${uuid}/twilio/phone_numbers`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * forward a virtual number to a real one
 *
 * @param {object} options
 * @param {string} options.restaurantUuid - selected restaurant/store
 * @param {object} options.data - body to be sent
 * @param {string} options.data.forward_number - number to be forwarded to
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - resolves with virtual number including the forwarded number
*/
export const forwardNumber = (options, tokens) => {
  const { restaurantUuid, data } = options
  const twilioBaseUrl = `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${restaurantUuid}/twilio`

  return axios({
    url: `${twilioBaseUrl}/phone_numbers/${data.phoneUuid}/forward`,
    method: METHODS.POST,
    data: {
      forward_number: data.forward_number
    },
    headers: HeaderFactory(tokens)
  })
}
