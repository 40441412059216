import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Picker from 'emoji-picker-react'

import { useOutsideClick } from '../../hooks/shared'

import './styles.scss'

const EmojiPicker = props => {
  const { onPick } = props
  const [ visible, setVisible ] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    setVisible(false)
  })

  const handleEmojiClick = (event, emoji) => {
    setVisible(false)
    onPick(emoji, event)
  }

  return (
    <div className='eg-emoji-picker' ref={ wrapperRef } data-testid='eg-emoji-picker'>
      <div className='trigger' onClick={ () => setVisible(!visible) } />
      {
        visible && (
          <div className='picker-wrapper'>
            <Picker onEmojiClick={ handleEmojiClick } />
          </div>
        )
      }
    </div>
  )
}

EmojiPicker.propTypes = {
  onPick: PropTypes.func
}

export default EmojiPicker
