import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import EGAnalytic, { analyticEvents } from '../../services/analytics'

import CustomerMessage from '../../components/CustomerMessage'
import { types as msgTypes } from '../../components/CustomerMessage/CustomerMessage'
import TextField from '../../components/FormComponents/TextField'
import Button from '../../components/FormComponents/Button'
import FormValidator from '../../validators/FormValidator'
import { email } from '../../validators/customValidations'
import { PATHS } from '../../constants'
import styles from './index.module.scss'
import logo from '../../asset/zsf.svg'

const EMAIL_FIELD = 'email'
const formFieldsNames = [ EMAIL_FIELD ]

const errorCodes = {
  USER_NOT_FOUND: 'UserNotFoundException',
  LIMIT_EXCEEDED: 'LimitExceededException'
}

const errorMessages = {
  [errorCodes.USER_NOT_FOUND]: 'The email supplied was not found',
  [errorCodes.LIMIT_EXCEEDED]: 'Attempt limit exceeded, please try after some time.'
}

export default class ResetPassword extends React.Component {

  static propTypes = {
    errorCode: PropTypes.string,
    posting: PropTypes.bool,
    history: PropTypes.object,
    onSubmit: PropTypes.func
  }

  constructor() {
    super()

    // initial state
    this.state = {
      isFormDirty: false,
      [EMAIL_FIELD]: { value: '' }
    }

    // bindings
    this.renderErrors = this.renderErrors.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.goToSignin = this.goToSignin.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    // validations
    this.validator = new FormValidator([
      ...email(EMAIL_FIELD)
    ])
  }

  /**
   * redirects the user to signin page
  */
  goToSignin() {
    this.props.history.push(PATHS.LOGIN)
  }

  /**
   * render error messages
  */
  renderErrors() {
    if (!this.props.errorCode) {
      return null
    }

    return (
      <CustomerMessage type={ msgTypes.MSG_WARNING } show={ true }>
        { errorMessages[this.props.errorCode] }
      </CustomerMessage>
    )
  }

  /**
   * update state with fields information
   *
   * @param event {object} input event
   */
  handleInputChange(event) {
    let value = get(event, 'target.value') || ''
    if(get(event, 'target.name') === EMAIL_FIELD) {
      value =  (typeof value === 'string') ? value.trim() : value
    }
    event.preventDefault()
    if (!this.state.isFormDirty) {
      EGAnalytic.track(analyticEvents.PASSWORD_RESET_STARTED)
    }
    this.setState({
      isFormDirty: true,
      [event.target.name]: {
        value,
        dirty: true
      }
    })
  }

  /**
   * check if the form is in a valid state
   */
  isValid() {
    return this.validation.isValid && !this.props.posting
  }

  /**
   * handles form submission
  */
  handleSubmit() {
    this.props.onSubmit(this.state[EMAIL_FIELD].value)
  }

  render() {
    // Get the form input values and statuses from state to validate.
    const formFields = {}
    formFieldsNames.forEach(fieldName => formFields[fieldName] = this.state[fieldName])
    this.validation = this.validator.validate(formFields)

    return (
      <div className={ styles.container }>
        <img className={styles.zsfLogo} src={logo} alt='Zero Store Front'/>
        <div className={ styles.resetPassWrapper }>
          <h2>Reset Password</h2>
          <p>
            Enter the email address associated with your account,
            and we’ll email you a link to reset your password.
          </p>

          { this.renderErrors() }

          <div className={ styles.formWrapper }>
            <TextField
              className={ styles.textField }
              name={EMAIL_FIELD}
              label="Email"
              placeholder="Email address"
              errorMessage={this.validation[EMAIL_FIELD].message}
              error={!this.validation[EMAIL_FIELD].isValid && this.state[EMAIL_FIELD].dirty}
              onChange={this.handleInputChange}
              onEnterPress={ this.onSubmit }
              value={this.state[EMAIL_FIELD].value}
            />

            <article className={styles.buttonContainer}>
              <Button
                text="Send verification code"
                className={ styles.submitBtn }
                disabled={ !this.isValid() }
                loading={ this.props.posting }
                onClick={ this.handleSubmit }
                primary
              />

              <Button
                text="Back to sign in"
                className={ styles.backBtn }
                loading={ this.props.posting }
                onClick={ this.goToSignin }
              />
            </article>
          </div>

        </div>
      </div>
    )
  }
}
