import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import CallList from '../../Calls/CallList'
import PaginationSection from '../../PaginationSection'
import LocalSpinner from '../../LocalSpinner'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants'
import './styles.scss'

const NumberActivity = props => {
  const {
    number,
    callList,
    loading,
    match,
    getCallLogs
  } = props

  const numberUuid = number.id
  const restaurantUuid = match.params.uuid

  useEffect(() => {
    getCallLogs({
      numberUuid,
      restaurantUuid,
      size: DEFAULT_ITEMS_PER_PAGE,
      page: 1
    })
  }, [numberUuid, restaurantUuid, getCallLogs])

  const handlePageChange = page => {
    getCallLogs({
      numberUuid,
      restaurantUuid,
      size: callList.size,
      page
    })
  }

  const handleSizeChange = size => {
    getCallLogs({
      numberUuid,
      restaurantUuid,
      size: size,
      page: 1
    })
  }

  return (
    <div className='eg-number-activity'>
      {
        callList.items && !!callList.items.length && !loading && (
          <>
            <div className='calls'>
              <CallList showHeaders={ false } calls={ callList } />
            </div>
            <div className='pagination'>
              <PaginationSection
                total={ callList.total }
                size={ callList.size }
                page={ callList.page }
                onPageChange={ handlePageChange }
                onSizeChange={ handleSizeChange }
                sizeOptionsDirection='top'
              />
            </div>
          </>
        )
      }
      {
        callList.items && !callList.items.length && !loading && (
          <div className='emptyview'>
            <div className='image' />
            <div className='title'>
              No recorded call activity yet
            </div>
            <div className='description'>
              All calls recorded from this number will appear here
            </div>
          </div>
        )
      }
      {
        loading && (
          <LocalSpinner className='spinner' />
        )
      }
    </div>
  )
}

NumberActivity.propTypes = {
  number: PropTypes.object.isRequired,
  callList: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  getCallLogs: PropTypes.func
}

export default NumberActivity

