import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export const resizeTypes = {
  NO_RESIZE: 'no-resize',
  ALL: 'all',
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

/**
 * TextArea - textarea input component
 *
 * Props:
 * name {string}           - component name
 * label {string}          - component label
 * placeholder {string}    - text displayed when field is empty
 * error {string}          - validation error string
 * onChange {function}     - function triggered when component changes
 */
export default class TextArea extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    resizeType: PropTypes.string,
    className: PropTypes.string,
    containerClass: PropTypes.string,
    error: PropTypes.string,
    forwardedRef: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }

  static defaultProps = {
    resizable: resizeTypes.ALL,
    containerClass: ''
  }

  getResizeTypeClass = () => {
    switch(this.props.resizeType) {
      case resizeTypes.NO_RESIZE: return styles.noResize
      case resizeTypes.VERTICAL: return styles.verticalResize 
      case resizeTypes.HORIZONTAL: return styles.horizontalResize
      case resizeTypes.ALL: // $FALL_THROUGH
      default: return ''
    }
  }

  render() {
    return (
      <div className={ `${styles.container} ${this.props.containerClass}` }>
        { this.props.label ? <div className={ styles.label }>{ this.props.label }</div> : null }
        <textarea
          id={ this.props.name }
          name={ this.props.name }
          value={ this.props.value }
          className={`${this.props.error ? styles.error : ''} ${this.props.className || ''} ${this.getResizeTypeClass()}`}
          placeholder={ this.props.placeholder }
          onChange={ this.props.onChange }
          onBlur={ this.props.onBlur }
          ref={ this.props.forwardedRef }
        />
        {
          this.props.error
            ? <div className={styles.errorMessage}>{this.props.error}</div>
            : null
        }
      </div>
    )
  }
}
