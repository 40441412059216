import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { predicateTypes } from '../../../../../services/segments'
import AmountPredicate from '../../Predicates/AmountPredicate'
import DatePredicate from '../../Predicates/DatePredicate'
import TypePredicate from '../../Predicates/TypePredicate'
import './styles.scss'

const DEFAULT_NUMBER_PREDICATE =  {
  type: predicateTypes.SHORTLINK_CLICK
}

const ShortLinkTemplate = props => {
  const { predicates, onChange, onDateChange, onClearPredicates } = props
  const numberPredicate= predicates.find((item) => item.type === predicateTypes.SHORTLINK_CLICK )
  const datePredicates = predicates.filter((item) => item.type === predicateTypes.DATE )
  const typePredicate = predicates.find((item) => item.type === predicateTypes.ID)


  return (
    <>
      <div className='predicate-cell'>
        <AmountPredicate
          predicate={numberPredicate || DEFAULT_NUMBER_PREDICATE}
          onChange={onChange}
        />
      </div>
      <div className='text-node'>
        {_.get(numberPredicate, 'amount.value') > 1 ?  'times' : 'time' }
      </div>
      {numberPredicate && (
        <div className='predicate-cell'>
          <DatePredicate
            onDateChange={onDateChange}
            datePredicates={datePredicates}
            onClearPredicates={onClearPredicates}
          />
        </div>
      )}
      {/* { haveNumberPredicateData && ( */}
          {/* <> */}
          <div className='text-node'> where the </div>
          <TypePredicate
            typePredicate={typePredicate}
            onClearPredicates={onClearPredicates}
            onChange={onChange}
          />
          {/* </> */}
        {/* ) */}
      {/* } */}
    </>
  )
}

ShortLinkTemplate.propTypes = {
  predicates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onClearPredicates: PropTypes.func.isRequired,
}

export default ShortLinkTemplate
