
import querystring from 'query-string'
import { globalHistory } from '@reach/router'


/**
 * Gets the basepath of a path. This is, the first
 * section
 *
 * @param {string} path -  the full path
 *
 * @returns {string} - basepath
*/
export const getBasePath = path => {
  return path.replace(/^(\/?[^\/]+)\/?.*$/, '$1')
}

/**
 * Updates passed query parameter in the url
 * @param {object} queryObj - query options
 * @param {number} queryObj.page - page number
 */
export function updateUrlQuery(queryObj) {
  const  queryParameter = globalHistory.location.search
  const queryParameterObj = querystring.parse(queryParameter)
  const newQueryObject = {...queryParameterObj, ...queryObj}
  const stringifyQuery = querystring.stringify(newQueryObject)
  //Dynamically updating the query string for the url without reloading the page.
  // Refernce. |> https://smartlazycoding.com/javascript-tutorial/change-the-url-without-reloading-the-page
  window.history.pushState(null, null, `?${stringifyQuery}`)
}
