import React, { useEffect } from 'react'
import {  useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { getPredicatesInitialState } from '../../../../reducers/Segment'
import Button, { types as buttonTypes } from '../../../FormComponents/Button'
import PredicateConditions from './PredicateConditions'
import { actions  as segmentActions } from '../../../../actions/Segment'
import LocalSpinner from '../../../LocalSpinner'
import { PATHS } from '../../../../constants'

import './styles.scss'

/**
 * Segment review step
 *
 * @component
 * @example
 *
 * const handleSubmit = () => {
 *   // Handles the last step submit, which should be publishing the segment
 * }
 *
 * const handleBackClick = () => {
 *   // Handles when user clicks on "Back" click
 * }
 *
 * // isEditable is used to tell the component if we're in the creating/editing process
 * // if it's false, then the component will be in read only mode, allowing the user
 * // to click 'Edit' and redirect to edit mode
 * const { isEditable } = props
 *
 * // tells the component if we're submitting
 * const submitting = useSelector(...)
 *
 * <ReviewSegment
 *   submitting={ isSubmitting }
 *   editable={ isEditable }
 *   onSubmit={ handleSubmit }
 *   onBackClick={ handleBackClick }
 * />
*/
const ReviewSegment = props => {
  const dispatch = useDispatch()
  const {
    submitting,
    history,
    editable = true,
    onBackClick,
    onSubmit
  } = props
  const chain = useSelector(state => state.User.chain)
  const { data } = useSelector(({Segment}) => Segment.dynamicSegment)
  const predicates = _.get(data, 'payload.predicates', [])
  const { loading: loadingJourneys }  = useSelector(state => state.Segment.dynamicJourneyList)
  const { loading: loadingCampaigns }  = useSelector(state => state.Segment.dynamicCampaignList)

  useEffect(() => {
    dispatch(segmentActions.getJourneysForDynamicSegment())
    dispatch(segmentActions.getCampaignsForDynamicSegment())
  }, [dispatch])

  const handleBackClick = () => {
    onBackClick && onBackClick()
  }

  const handleEditClick = () => {
    history.push(`${PATHS.SEGMENTS}/edit-dynamic/${data.id}`)
  }

  const handleSubmit = (data) => {
    if (onSubmit) {
      onSubmit('review', data)
    }
  }

  if (loadingCampaigns || loadingJourneys) return (
    <div className='eg-create-segment-review'>
      <LocalSpinner className='loader'/>
    </div>
  )

  return (
    <div className='eg-create-segment-review'>
      <div className='step-body'>
        <div className='head'>
          <div>
            <div className='title'>
              { editable ? 'Review your segment details' : `${chain.name} | ${data.name}` }
            </div>
            <div className='subtitle'>
              {
                editable
                  ? 'Looking good! Before creating your segment, please review the information below.'
                  : data.description
              }
            </div>
          </div>
          {
            !editable && (
              <div>
                <Button
                  className='edit-button'
                  text='EDIT SEGMENT'
                  type={buttonTypes.SECONDARY}
                  onClick={ handleEditClick }
                />
              </div>
            )
          }
        </div>
        <div className='segment-details'>
          {
            editable && (
              <>
                <div className='label'>
                  Name
                </div>
                <div className='name'>
                  {_.get(data, 'name')}
                </div>
                <div  className='label'>
                  Description
                </div>
                <div  className='description'>
                  {_.get(data, 'description')}
                </div>
              </>
            )
          }
          <div  className='label'>
            SEGMENT CONDITIONS
          </div>
          {
            (predicates.length === 0 || _.isEqual(predicates, getPredicatesInitialState())) && (
              <div>No predicates configured for this segment.</div>
            )
          }
          {predicates.map((predicateObject, index) => {
            return (
              <div key={ `review-predicate-${index}` }>
                {index !== 0 ? <div className='and-label'>and</div> : ''}
                <PredicateConditions
                  predicates={predicateObject.predicates}
                />
              </div>
            )
          })}
          {/* {renderPredicateConditions(predicateData)} */}
        </div>
      </div>
      {
        editable && (
          <div className='actions'>
            <Button
              className='action-button'
              text='BACK'
              type={buttonTypes.SECONDARY}
              onClick={handleBackClick}
            />
            <Button
              className='action-button submit'
              text='CREATE SEGMENT'
              loading={submitting}
              onClick={handleSubmit}
            />
          </div>
        )
      }
    </div>
  )
}

ReviewSegment.propTypes = {
  submitting: PropTypes.bool,
  onBackClick: PropTypes.func,
  editable: PropTypes.bool,
  history: PropTypes.object,
  onSubmit: PropTypes.func
}

export default withRouter(ReviewSegment)
