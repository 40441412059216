import React from 'react'

import { PrivateRoute, OnlyPublicRoute } from './Routes'
import { userRoles } from '../services/auth'
import { PATHS } from '../constants'

import Login from '../containers/Login'
import Orders from '../containers/Orders'
import Dashboard from '../containers/Dashboard'
import Customers from '../containers/Customers'
import Chains from '../containers/Chains'
import Reviews from '../containers/Reviews'
import Stores from '../containers/Stores'
import StoreDetail from '../containers/StoreDetail'
import StoreCreate from '../containers/StoreCreate'
import ResetPassword from '../containers/ResetPassword'
import ConfirmNewPassword from '../containers/ConfirmNewPassword'
import ReviewOverview from '../containers/ReviewOverview'
import ReviewList from '../containers/ReviewList'
import Reports from '../containers/Reports'
import CallLogs from '../containers/CallLogs'
import AccountSettings from '../containers/AccountSettings'
import CustomerDetails from '../containers/CustomerDetails'
import ShopifyOauthCallback from '../containers/ShopifyOauthCallback'
import FacebookOauthCallback from '../containers/FacebookOauthCallback'
// import MailChimpOauthCallback from '../containers/MailChimpOauthCallback'
import FivetranOauthCallback from '../containers/FivetranOauthCallback'
import SquareOauthCallback from '../containers/SquareOauthCallback'
import ConstantContactOauthCallback from '../containers/ConstantContactOauthCallback'
import GoogleBusinessOauthCallback from '../containers/GoogleBusinessOauthCallback'
import CloverOauthCallback from '../containers/CloverOauthCallback'
import AttentiveOauthCallback from '../containers/AttentiveOauthCallback'
import Integrations from '../containers/Integrations'
import Campaigns from '../containers/Campaigns'
import CampaignDashboard from '../containers/Campaigns/CampaignDashboard'
import CampaignCreate from '../containers/Campaigns/CampaignCreate'
import CampaignUpdate from '../containers/Campaigns/CampaignUpdate'
import Journeys from '../containers/Journeys'
import JourneyCreate from '../containers/Journeys/JourneyCreate'
import JourneyUpdate from '../containers/Journeys/JourneyUpdate'
import JourneyView from '../containers/Journeys/JourneyView'
import Segment from '../containers/Segment'
import DynamicSegmentCreate from '../containers/Segment/DynamicSegmentCreate'
import DynamicSegmentUpdate from '../containers/Segment/DynamicSegmentUpdate'
import DynamicSegmentView from '../containers/Segment/DynamicSegmentView'
// import UserPlan from '../containers/UserPlan'
import Channels from '../containers/Channels'
import Settings from '../containers/Settings'

const Routing = () => {
  return (
    <>
      {/* Public routes */}
      <OnlyPublicRoute
        exact
        path={PATHS.LOGIN}
        component={Login}
      />
      <OnlyPublicRoute
        exact
        path={PATHS.RESET_PASSWORD}
        component={ResetPassword}
      />
      <OnlyPublicRoute
        exact
        path={PATHS.CONFIRM_NEW_PASSWORD}
        component={ConfirmNewPassword}
      />

      {/* Private routes */}
      <PrivateRoute
        exact
        path={PATHS.ORDERS}
        component={Orders}
        roles={[userRoles.RESTAURANT_ADMIN]}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMERS}
        component={Customers}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}
      />
      {/*
      <PrivateRoute
        exact
        path={ PATHS.CUSTOMER_DETAIL }
        component={ CustomerDetail }
        roles={ [userRoles.RESTAURANT_ADMIN] }
      />
    */}
      <PrivateRoute
        exact
        path={PATHS.CHAINS}
        component={Chains}
      />
      <PrivateRoute
        exact
        path={PATHS.REVIEWS}
        component={Reviews}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.REVIEW_MANAGER]}
      />
      <PrivateRoute
        exact
        path={PATHS.DASHBOARD}
        component={Dashboard}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.REVIEWS_OVERVIEW}
        component={ReviewOverview}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.REVIEW_MANAGER]}
      />
      <PrivateRoute
        exact
        path={PATHS.REVIEWS_LIST}
        component={ReviewList}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.REVIEW_MANAGER]}
      />
      <PrivateRoute
        exact
        path={PATHS.STORES}
        component={Stores}
      />
      <PrivateRoute
        path={PATHS.STORE}
        component={StoreDetail}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.STORE_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.STORE_CREATE}
        component={StoreCreate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.CALLS}
        component={CallLogs}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.TWILIO_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.REPORTS}
        component={Reports}
        roles={[userRoles.REPORT_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.ACCOUNT_SETTINGS}
        component={AccountSettings}
      />
      <PrivateRoute
        exact
        path={PATHS.CUSTOMER_DETAIL}
        component={CustomerDetails}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}
      />

      <PrivateRoute
        exact
        path={PATHS.SHOPIFY_OAUTH_CALLBACK}
        component={ShopifyOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.FACEBOOK_OAUTH_CALLBACK}
        component={FacebookOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.CONSTANT_CONTACT_OAUTH_CALLBACK}
        component={ConstantContactOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.CLOVER_OAUTH_CALLBACK}
        component={CloverOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.ATTENTIVE_OAUTH_CALLBACK}
        component={AttentiveOauthCallback}
        // roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
        roles={ [] } // superadmins only for now
      />
      {/* <PrivateRoute
        exact
        path={PATHS.MAILCHIMP_OAUTH_CALLBACK}
        component={MailChimpOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      /> */}
      <PrivateRoute
        exact
        path={PATHS.FIVETRAN_OAUTH_CALLBACK}
        component={FivetranOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.SQUARE_OAUTH_CALLBACK}
        component={SquareOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.GOOGLE_BUSINESS_OAUTH_CALLBACK}
        component={GoogleBusinessOauthCallback}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.INTEGRATIONS}
        component={Integrations}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.SMS_CAMPAIGNS}
        component={Campaigns}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}
      />
      <PrivateRoute
        exact
        path={`${PATHS.SMS_CAMPAIGN}/:campaignId`}
        component={CampaignDashboard}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}
      />
      <PrivateRoute
        exact
        path={PATHS.SMS_CAMPAIGNS_CREATE}
        component={CampaignCreate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_WRITE]}
      />
      <PrivateRoute
        exact
        path={ `${PATHS.SMS_CAMPAIGNS}/edit/:campaignId` }
        component={ CampaignUpdate }
        roles={ [userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_WRITE] }
      />
      <PrivateRoute
        exact
        path={ PATHS.SMS_JOURNEYS }
        component={ Journeys }
        roles={ [userRoles.RESTAURANT_ADMIN, userRoles.SMS_JOURNEYS_READ] }
      />
      <PrivateRoute
        exact
        path={PATHS.SMS_JOURNEYS_CREATE}
        component={JourneyCreate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_JOURNEYS_WRITE]}
      />
      <PrivateRoute
        exact
        path={`${PATHS.SMS_JOURNEYS}/edit/:journeyId`}
        component={JourneyUpdate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_JOURNEYS_WRITE]}
      />
      <PrivateRoute
        exact
        path={ `${PATHS.SMS_JOURNEYS}/view/:journeyId` }
        component={ JourneyView }
        roles={ [userRoles.RESTAURANT_ADMIN, userRoles.SMS_JOURNEYS_READ] }
      />
      <PrivateRoute
        exact
        path={PATHS.SEGMENTS}
        component={Segment}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_WRITE]}
      />
      <PrivateRoute
        exact
        path={PATHS.DYNAMIC_SEGMENTS_CREATE}
        component={DynamicSegmentCreate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_WRITE]}
      />
      <PrivateRoute
        exact
        path={`${PATHS.SEGMENTS}/edit-dynamic/:segmentId`}
        component={DynamicSegmentUpdate}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_WRITE]}
      />
      <PrivateRoute
        exact
        path={`${PATHS.SEGMENTS}/view-dynamic/:segmentId`}
        component={DynamicSegmentView}
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_WRITE]}
      />
      {/*
      <PrivateRoute
        exact
        path='/user-plan'
        component={ UserPlan }
      />
      */}
      <PrivateRoute
        exact
        path={ PATHS.CHANNELS }
        component={ Channels }
        // roles={ [userRoles.RESTAURANT_ADMIN, userRoles.CHANNEL_READ] }
        roles={ [] } // superadmins only for now
      />
      <PrivateRoute
        exact
        path={PATHS.SETTINGS}
        component={ Settings }
        roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}
      />
    </>
  )
}

export default Routing
