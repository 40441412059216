import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { statuses } from '../../../../services/journeys'
import { userHasAccess } from '../../../../services/auth'
import JourneyOptions from '../../JourneyOptions'
import './styles.scss'


/**
 * Journey list row item view
 *
 * @component
 * @example
 *
 * const item = {
 *   'id': '608bffdaf58d4e0009121b65',
 *   'chain': {
 *     'uuid': '1f77a64c-7933-48fe-af05-34f29621d3d3'
 *     'name': 'Halal guys'
 *   },
 *   'name': 'My first sjfmss',
 *   'trigger': 'SignUp',
 *   'status: 'active'
 *   'stats': {
 *     delivered:  5
 *   }
 * }
 *
 * return (
 *  <JourneyItem item={item} />
 * )
*/
const JourneyItem = ({ item, onStatusChange, onItemClick }) => {
  const { user } = useSelector(state => state.User)
  const handleStatusChange = (status) => {
    onStatusChange(status)
  }

  const handleItemClick = () => {
    onItemClick(item)
  }

  return (
    <div className='eg-journey-item'>
      <div className='name' onClick={handleItemClick}>
        <div className='icon' />
        <div className='details'>
          <div className='title'>{item.name}</div>
          <div className='info' >
            Trigger Type: <span>{item.triggerName}</span>
          </div>
          {
            !!item.trigger_id && (
              <div className='info triggerid'>
                Trigger: <span>{item.trigger_id}</span>
              </div>
            )
          }
        </div>
      </div>
      <div className='status'><div className={item.status} />{item.status}</div>
      {/* <div className='in_progress'>{get(item, 'stats.in_progress')}</div> */}
      <div className='sent'>{get(item, 'stats.delivered')}</div>
      <div className='opted_out'>{get(item, 'stats.opted_out')}</div>
      <div className='options'>
        {
          userHasAccess(user, []) && item.status !== statuses.DRAFT && (
            <JourneyOptions journey={item} onStatusClick={handleStatusChange} onEditClick={ handleItemClick } />
          )
        }
      </div>
    </div>
  )
}

JourneyItem.propTypes = {
  item: PropTypes.object,
  onStatusChange: PropTypes.func,
  onItemClick: PropTypes.func
}

export default JourneyItem
