import { types } from '../actions/User'
import { mapCognitoUserData } from '../services/auth'
import { mapRestaurantsToArray } from '../services/restaurant'

const initialState = {
  posting: false,
  errorCode: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATE_INTENT:
      return {
        ...state,
        authError: false,
        posting: true
      }
    case types.AUTHENTICATE_INTENT_RESOLVED:
      return {
        ...state,
        googleConnectModalOffRestros: [],
        isAuthenticated: true,
        authError: false,
        posting: false
      }
    case types.AUTHENTICATE_INTENT_REJECTED:
      return {
        ...state,
        authError: true,
        posting: false
      }

    case types.LOGOUT_INTENT:
      return {
        ...state,
        isAuthenticated: false
      }

    case types.UPDATE_AUTH_INFO:
      return {
        ...state,
        ...mapCognitoUserData(action.data)
      }

    case types.UPDATE_CHAIN_INFO:
      return {
        ...state,
        chain: action.chain
      }
    
    case types.SET_GOOGLE_CONNECT_MODAL_OFF:
      return {
        ...state,
        googleConnectModalOffRestros: mapRestaurantsToArray(state.googleConnectModalOffRestros,action.restaurantUuid)
      }
       
    case types.CHANGE_TOKEN:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          idToken: {
            ...state.tokens.idToken,
            jwtToken: '123abcdef'
          }
        }
      }

    case types.RESET_PASSWORD_INTENT:
      return {
        ...state,
        posting: true,
        errorCode: null
      }

    case types.RESET_PASSWORD_INTENT_RESOLVED:
      return {
        ...state,
        posting: false,
        errorCode: null
      }

    case types.RESET_PASSWORD_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        errorCode: action.errorCode
      }

    case types.CONFIRM_PASSWORD_INTENT:
      return {
        ...state,
        posting: true,
        errorCode: null
      }

    case types.CONFIRM_PASSWORD_INTENT_RESOLVED:
      return {
        ...state,
        posting: false,
        errorCode: null
      }

    case types.CONFIRM_PASSWORD_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        errorCode: action.errorCode
      }

    case types.CLEAR_MESSAGES:
      return {
        ...state,
        authError: false
      }

    case types.SET_USER_ROLES_RESOLVED:
      return {
        ...state,
        ...action.updatedState
      }

    case types.UPDATE_USER_INFO_INTENT:
      return {
        ...state,
        posting: true
      }

    case types.UPDATE_USER_INFO_INTENT_RESOLVED:
      return {
        ...state,
        posting: false
      }

    case types.UPDATE_USER_INFO_INTENT_REJECTED:
      return {
        ...state,
        posting: false
      }

    case types.UPDATE_USER_PASSWORD_INTENT:
      return {
        ...state,
        posting: true
      }

    case types.UPDATE_USER_PASSWORD_INTENT_RESOLVED:
      return {
        ...state,
        posting: false
      }

    case types.UPDATE_USER_PASSWORD_INTENT_REJECTED:
      return {
        ...state,
        posting: false
      }
    case types.GET_PLAN_TOKEN_INTENT_RESOLVED:
      return {
        ...state,
        user: {
          ...state.user,
          plan: {
            tokenData: action.data
          }
        }
      }

    default:
      return state
  }
}
