export const types = {

  // S3 upload docs(i.e csvs, images)
  UPLOAD_DOC_INTENT: 'UPLOAD_DOC_INTENT',
  UPLOAD_DOC_RESOLVED: 'UPLOAD_DOC_RESOLVED',
  UPLOAD_DOC_REJECTED: 'UPLOAD_DOC_REJECTED',
}

export const actions = {
  /**
   * @param {Object} opts type of{fileData: [], storeUuid: 'ekdnfs-kjflskfjs-...'}
   */
  uploadDoc(opts) {
    return {
      type: types.UPLOAD_DOC_INTENT,
      opts
    }
  },

  uploadDocResolved() {
    return {
      type: types.UPLOAD_DOC_RESOLVED,
    }
  },

  uploadDocRejected(error) {
    return {
      type: types.UPLOAD_DOC_REJECTED,
      error: error
    }
  },

}
