import moment from 'moment'

/**
 * Retrieves a array of dates, on which the activites/events happened
 * maps the events to in a object to their corresponding date
 * @param {*} events 
 */
export const mapDateArrayAndEvents = (reduxEvent, events) => {
  const dates = [...reduxEvent.dates]
  const dateEvents = {...reduxEvent.dateEvents}
  events.map((event) => {
    if(!event.start_at) return null
    const date = moment(event.start_at).format('MMM D, YYYY')
    if(dateEvents[date]){ 
      dateEvents[date].push(event)
    } else {
      dates.push(date)
      dateEvents[date] = [event]
    }
    return null
  })

  return { dates, dateEvents }
}
