import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomerHeader from '../../components/CustomerHeader'
import LocalSpinner from '../../components/LocalSpinner'
import { ChannelList } from '../../components/Channels'
import ChannelOverview from '../../components/Channels/ChannelOverview'
import { actions as channelActions } from '../../actions/Channel'
import { actions as storeActions } from '../../actions/Store'
import TimeSelector from '../../components/TimeSelector'
import StoreFilter from '../../components/Store/StoreFilter'
import './styles.scss'

const Channels = () => {
  const dispatch = useDispatch()
  const channelList = useSelector(state => state.Channel.channelList)
  const loadingChannels = useSelector(state => state.Channel.loadingChannels)
  const [selectedTime, setSelectedTime] = useState(null)

  useEffect(() => {
    dispatch(storeActions.getStoreList())
  }, [dispatch])

  const onDateApply = useCallback((appliedDates, timeObject) => {
    dispatch(channelActions.getChannels(appliedDates))
    timeObject && setSelectedTime(timeObject)
  }, [dispatch])

  const onStoreFilterChange = () => {
    dispatch(channelActions.getChannels())
  }

  return (
    <div className='eg-channels-container'>
      <CustomerHeader />
      <div className='header'>
        <h2 className='title'>Channels</h2>
        <div className='filters'>
          <StoreFilter onFilterChange={onStoreFilterChange} className='store-filter'/>
          <TimeSelector onDateApply={onDateApply}/>
        </div>
      </div>
      <div className='body'>
        {
          loadingChannels ? (
            <div className='spinner'>
              <LocalSpinner />
            </div>
          ) : (
            <>
              <ChannelOverview selectedTime={selectedTime} channelList={ channelList } />
              <div>
                <ChannelList
                  channels={ channelList.channels }
                  hasChannels={ channelList.has_channels }
                  totals={ channelList.total }
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default Channels
