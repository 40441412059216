import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { predicateTypes, predicateAttributes, predicateComparison } from '../../../../../services/segments'
import { Select } from '../../../../FormComponents'
import DateInput from '../../../../FormComponents/DateInput'

import './styles.scss'

const TIME_OPTIONS = {
  OVER_ALL_TIME: 'over_all_time',
  AFTER: 'after',
  ON: 'on',
  BETWEEN: 'between',
}

export const dateTypeOptions = [
  { label: 'over all time', value: TIME_OPTIONS.OVER_ALL_TIME },
  { label: 'after', value: TIME_OPTIONS.AFTER},
  { label: 'on', value: TIME_OPTIONS.ON },
  { label: 'between', value: TIME_OPTIONS.BETWEEN},
]

DatePredicate.propTypes = {
  datePredicates: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onClearPredicates: PropTypes.func.isRequired,
}

/**
 * Get all values for differnt date types. To, After and between( to, from values)
 * @param {Array} datePredicates all the date predicates that we have in redux state.
 * @param {Object} selectedOption current selected option in date predicate. ( i.e To, After, Between).. { label: 'To', value: 'To' }
 * @returns {Object} returns date values of onDate, AfterDate, BetweenTo and BetweenFrom
 */
const getAllDateValues = (datePredicates, selectedOption) => {
  const onDateData = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.EQ)
  const afterDateData = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.GTE)
  //Probably do the new Date in mapping
  const onDate = _.get(onDateData, 'value', null) && new Date(_.get(onDateData, 'value'))
  const afterDate = _.get(afterDateData, 'value', null) && new Date(_.get(afterDateData, 'value'))

  let betweenFrom = null, betweenTo = null
  const betweenFromData = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.GTE)
  const betweenTodata = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.LTE)
  betweenFrom = _.get(betweenFromData, 'value', null) && new Date(_.get(betweenFromData, 'value'))
  betweenTo = _.get(betweenTodata, 'value', null) && new Date(_.get(betweenTodata, 'value'))

  return {
    onDate,
    afterDate,
    betweenFrom,
    betweenTo
  }
}

function DatePredicate(props) {
  let { datePredicates, onDateChange, onClearPredicates } = props
  const [ selectedPredicate, setSelectedPredicate] = useState(dateTypeOptions[0])
  const mounted = useRef()


  const {
    onDate,
    afterDate,
    betweenFrom,
    betweenTo
  } = getAllDateValues(datePredicates, selectedPredicate)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      if(betweenFrom && betweenTo) {
      // set between option
        setSelectedPredicate(dateTypeOptions[3])
      } else if(onDate) {
      // set on Option
        setSelectedPredicate(dateTypeOptions[2])
      } else if(afterDate) {
      // set after option
        setSelectedPredicate(dateTypeOptions[1])
      } else {
      // set all time option
        setSelectedPredicate(dateTypeOptions[0])
      }
    }
  }, [afterDate, betweenFrom, betweenTo, onDate])


  const handleOnSelect = (item) => {
    onClearPredicates(predicateTypes.DATE)
    setSelectedPredicate(item)
  }

  const onDateValueChange = (date, comparison) => {
    const predicateData =   {
      type: predicateTypes.DATE,
      attribute: predicateAttributes.ACTIVITY_DATE,
      comparison,
      value: date.toISOString(),
    }
    onDateChange(predicateData, comparison)
  }

  const renderDateBox = () => {
    if(selectedPredicate.value === TIME_OPTIONS.OVER_ALL_TIME) {
      return null
    }else if(selectedPredicate.value === TIME_OPTIONS.AFTER) {
      return (
        <DateInput
          className='date-input'
          value={afterDate}
          onChange={(date) => onDateValueChange(date, predicateComparison.GTE)}
          disablePrevDays={false}
        />
      )
    } else if(selectedPredicate.value === TIME_OPTIONS.ON) {
      return (
        <DateInput
          className='date-input'
          value={onDate}
          onChange={(date) => onDateValueChange(date, predicateComparison.EQ)}
          disablePrevDays={false}
        />
      )
    } else if(selectedPredicate.value === TIME_OPTIONS.BETWEEN) {
      return (
        <>
        <DateInput
          className='date-input'
          value={betweenFrom}
          onChange={(date) => onDateValueChange(date, predicateComparison.GTE)}
          disablePrevDays={false}
        />
        <div className='text-node between-and'>and</div>
         <DateInput
           className='date-input'
           value={betweenTo}
           onChange={(date) => onDateValueChange(date, predicateComparison.LTE)}
           disablePrevDays={false}
         />
        </>
      )
    }
  }

  return (
    <div className='eg-segments-predicates-date'>
      <Select
        className='condition'
        options={ dateTypeOptions }
        selectedItem={ selectedPredicate }
        onChange={ handleOnSelect }
      />
      {renderDateBox()}
    </div>
  )
}

export default DatePredicate
