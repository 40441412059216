import _ from 'lodash'
import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects'
import { types, actions } from '../actions/Campaign'
import { authorizedRequest } from './Auth'
import { NetworkError } from '../services/error'
import { mapCampaigns, mapCampaignToFormData, mapCampaignChartData } from '../services/campaigns'
import { mapSegmentOptions } from '../services/segments'
import {
  sendTestMessage,
  getEstimatedRecipients,
  createCampaign,
  createShortnedUrl,
  updateCampaign,
  getCampaignList,
  getCampaign,
  getCampaignChartData,
  cancelCampaign,
  scheduleCampaign
} from '../api/Campaign'

import {
  getSegmentList
} from '../api/Segment'

import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

/**
 * Gets mapped segments from segment storage to get information about segments
 * in process of campaign creation flow.
 * @returns {Array} - mapped segments.
 */
function* getSegments() {
  const { list } = yield select(state => state.Campaign.segmentOptions)
  return list ? list : []
}

function* createCampaignHandler(opts) {
  try {
    const chain = yield select(state => state.User.chain)
    const segments = yield getSegments()
    const res = yield call(authorizedRequest, createCampaign, {
      ...opts,
      chain
    })
    if (res && res.data) {
      yield put(actions.createCampaignResolved(mapCampaignToFormData(res.data, chain, segments)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${error && error.message}`
    }))
    yield put(actions.createCampaignRejected(error))
  }
}

function* updateCampaignHandler(opts) {
  try {
    const chain = yield select(state => state.User.chain)
    const segments = yield getSegments()
    const res = yield call(authorizedRequest, updateCampaign, {
      ...opts,
      chain
    })
    if (res && res.data) {
      if (_.get(opts, 'data.step') === 3 && _.get(res, 'data.status') === 'draft') {
        yield put(actions.scheduleCampaign(res.data))
      }
      yield put(actions.updateCampaignResolved(mapCampaignToFormData(res.data, chain, segments)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorCode = _.get(error, 'data.errorCode')
    // if the error is out of allowed time range one, then
    // won't display a global error since it should be a friendly
    // warning handled within the schedule component
    if (errorCode !== 'quiet_time_schedule') {
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.ERROR,
        message: `Error: ${error && error.message}`
      }))
    }
    yield put(actions.updateCampaignRejected(_.get(error, 'data') || error))
  }
}

function* getCampaignListHandler(opts) {
  const { page = 1, size = 10, status } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getCampaignList, {
      page,
      size,
      status,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedCampaignList = { ...mapCampaigns(res.data, chain), page, size, status}
      yield put(actions.getCampaignsResolved(mappedCampaignList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getCampaignsRejected(error))
  }
}

function* getCampaignHandler(opts) {
  const { campaignId } = opts
  const chain = yield select(state => state.User.chain)
  const segments = yield getSegments()
  try {
    const res = yield call(authorizedRequest, getCampaign, {
      chainUuid: chain.restaurant_chain_uuid,
      campaignId
    })
    if (res && res.data) {
      yield put(actions.getCampaignResolved(mapCampaignToFormData(res.data, chain, segments)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Not able to get campaign data. ${_.get(error, 'message')}`
    }))
    yield put(actions.getCampaignRejected(error))
  }
}

function* getSegmentOptionsHandler(opts) {
  const { statuses, campaignId } = _.get(opts, 'data', {})
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getSegmentList, {
      page: 1,
      size: 999,
      chainUuid: chain.restaurant_chain_uuid,
      statuses
    })
    if (res && res.data) {
      const mappedSegmentOptions = mapSegmentOptions(res.data.segments)
      yield put(actions.getSegmentOptionsResolved(mappedSegmentOptions))
      // We got segments from the backend, let's get campaign data with id
      // so we have the segment target to map in segment list/options
      if(campaignId) {
        yield put(actions.getCampaign(campaignId))
      }
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Not able to get available segments.'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.getSegmentOptionsResolved(error))
  }
}

function* getCampaignChartHandler(opts) {
  const { campaign } = opts
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getCampaignChartData, {
      chainUuid: chain.restaurant_chain_uuid,
      campaign
    })
    if (res && res.data) {
      yield put(actions.getCampaignChartResolved(mapCampaignChartData(res.data, campaign)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    console.error('error', error)
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Not able to get chart data. ${_.get(error, 'message')}`
    }))
    yield put(actions.getCampaignChartRejected(error))
  }
}

function* createShortLinkHandler(opts) {
  const { campaignId, url } = opts.data
  const chain = yield select(state => state.User.chain)
  const segments = yield getSegments()
  try {
    const res = yield call(authorizedRequest, createShortnedUrl, {
      chainUuid: chain.restaurant_chain_uuid,
      campaignId,
      url
    })
    if (res && res.data) {
      yield put(actions.createShortLinkResolved(mapCampaignToFormData(res.data, chain, segments)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Something went wrong. ${_.get(error, 'message')}`
    }))
    yield put(actions.createShortLinkRejected(error))
  }
}

function* sendTestMessageHandler(opts) {
  const { chain, user } = yield select(state => state.User)
  try {
    const res = yield call(authorizedRequest, sendTestMessage, {
      ...opts,
      chain,
      user
    })
    if (res && res.data) {
      yield put(actions.sendTestMessageResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Test message successfully sent!'
      }))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: 'There was an error while sending the test message.'
    }))
    yield put(actions.sendTestMessageRejected(error))
  }
}

function* getEstimatedRecipientsHandler(opts) {
  const chainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  try {
    const res = yield call(authorizedRequest, getEstimatedRecipients, {
      chainUuid,
      campaignId: opts.campaignId
    })
    if (res && res.data) {
      yield put(actions.getEstimatedRecipientsResolved(res.data))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getEstimatedRecipientsRejected(error))
  }
}

function *cancelCampaignHandler(opts) {
  const chainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  try {
    const res = yield call(authorizedRequest, cancelCampaign, {
      chainUuid,
      campaign: opts.campaign
    })
    if (res && res.data) {
      yield put(actions.cancelCampaignResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Campaign cancelled successfully'
      }))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Something went wrong'
    }))
    yield put(actions.cancelCampaignRejected(error))
  }
}

function *scheduleCampaignHandler(opts) {
  const chainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  try {
    const res = yield call(authorizedRequest, scheduleCampaign, {
      chainUuid,
      campaign: opts.campaign
    })
    if (res && res.data) {
      yield put(actions.scheduleCampaignResolved(res.data))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Something went wrong'
    }))
    yield put(actions.scheduleCampaignRejected(_.get(error, 'data') || error))
  }
}

export default function* () {
  yield takeLatest(types.CAMPAIGN_CREATE_INTENT, createCampaignHandler)
  yield takeLatest(types.CAMPAIGN_UPDATE_INTENT, updateCampaignHandler)
  yield takeLatest(types.GET_CAMPAIGNS_INTENT, getCampaignListHandler)
  yield takeLatest(types.GET_CAMPAIGN_INTENT, getCampaignHandler)
  yield takeLatest(types.GET_SEGMENT_OPTIONS_INTENT, getSegmentOptionsHandler)
  yield takeLatest(types.GET_CAMPAIGN_CHART_INTENT, getCampaignChartHandler)
  yield takeLatest(types.CAMPAIGN_SHORTLINK_CREATE_INTENT, createShortLinkHandler)
  yield takeLatest(types.CAMPAIGN_SEND_TEST_MSG_INTENT, sendTestMessageHandler)
  yield takeLatest(types.CAMPAIGN_GET_ESTIMATED_RECIPIENTS, getEstimatedRecipientsHandler)
  yield takeLatest(types.CAMPAIGN_CANCEL_INTENT, cancelCampaignHandler)
  yield takeLatest(types.CAMPAIGN_SCHEDULE_INTENT, scheduleCampaignHandler)
}
