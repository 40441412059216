import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export const types = {
  MSG_SUCCESS: 'msgSuccess',
  MSG_WARNING: 'msgWarning',
  MSG_ALERT: 'msgAlert',
  MSG_ERROR: 'msgError'
}

export default class CustomerMessage extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any
  }

  static defaultProps = {
    show: false,
    type: types.MSG_SUCCESS,
    className: ''
  }

  render() {
    const cssClass = styles[this.props.type]
    return (
      this.props.show ? (
        <div className={ `${styles.container} ${this.props.className} ${cssClass} ${ !this.props.show ? 'hide' : '' }` }>
          { this.props.children }
        </div>
      ) : null
    )
  }
}
