import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import mobileLogo from './images/mobileLogo.svg'

export default class GoogleConnectModalContent extends React.Component{
    static propTypes = {
      restaurantEmail: PropTypes.string,
      children: PropTypes.any
    }
    
    render(){
      return ( 
            <>
              <div className={styles.mobileLogoContainer}>
                <img src={mobileLogo} alt='Mobile light Error Screen'/>
              </div>
              <div className={styles.infoTitle}>
              Oh no. Your inbox is no longer connected with Eatgeek.
              </div>
              <div className={styles.infoDescription}>
              The integration with your inbox <span className={styles.boldText}>{this.props.restaurantEmail}</span> has expired. Reconnect it or connect a new account if you wish to continue importing orders from your inbox. Make sure to integrate the email addresss where you currently receive your caterings orders. 
              </div>

              <div className={styles.gConnectButton}>
                {this.props.children} {/* Google Connect Button */}
              </div>
            </>
      )
    }
}
