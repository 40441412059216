import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import querystring from 'query-string'

import { actions as journeyActions } from '../../../actions/Journey'
import { getSteps } from '../../../services/journeys'
import LocalSpinner from '../../../components/LocalSpinner'
import Journey from '../../../components/Journeys/Create'

const JourneyUpdateContainer = props => {
  const { journeyId } = props.match.params
  const dispatch = useDispatch()
  const journey = useSelector(state => _.get(state, 'Journey.journey.data'))
  const qs = querystring.parse(props.location.search)
  const stepNumber = _.get(qs, 'step', '1')
  const steps = getSteps(journey)
  const step = steps[stepNumber - 1]

  useEffect(() => {
    dispatch(journeyActions.getJourney(journeyId))
  }, [dispatch, journeyId])

  if (!journey) {
    return <LocalSpinner />
  }

  if (stepNumber && stepNumber > 1) {
    for (let i = 0; i < stepNumber; i++) {
      steps[i].completed = true
    }
  }

  return <Journey history={ props.history } journey={ journey } step={ step } />
}

JourneyUpdateContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
}

export default JourneyUpdateContainer
