import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { Select } from '../../../../FormComponents'
import { predicateTypes, predicateAttributes, predicateComparison, predicateValues } from '../../../../../services/segments'


import './styles.scss'

const predicateOptions = [
  {
    value: {
      type: predicateTypes.PURCHASE,
      attribute: predicateAttributes.SOURCE,
      comparison: predicateComparison.EQ,
    },
    label: 'made a purchase'
  },
  {
    value: {
      type: predicateTypes.SIGN_UP,
      attribute: predicateAttributes.SOURCE_MEDIUM,
      comparison: predicateComparison.EQ,
      value: predicateValues.PRIVY,
    },
    label: 'signed up'
  },
  {
    value: {
      type: predicateTypes.SHORTLINK_CLICK
    },
    label: 'clicked a shortlink'
  }
]

const SourcePredicate = props => {
  const { predicates, onChange } = props
  const selectedOption = predicateOptions.find(option => option.value.type === _.get(predicates, '[0].type'))

  const onSourceChange = (src) => {
    const predicates = [
      {...src.value}
    ]
    onChange(predicates)
  }

  return (
    <div className='eg-segments-predicates-source'>
      <div className='cell'>
        <Select
          name='segments-predicates-source-option'
          className='sources'
          placeholder='Choose activity'
          options={ predicateOptions }
          selectedItem={ selectedOption || null }
          onChange={ onSourceChange }
        />
      </div>
    </div>
  )
}

SourcePredicate.propTypes = {
  predicates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SourcePredicate
