import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ChipInput, Button } from '../../FormComponents'
import { types as buttonTypes } from '../../FormComponents/Button'
import EGAnalytic, { analyticEvents } from '../../../services/analytics'
import StatelessModal from '../../StatelessModal'

import './styles.scss'

const phoneRegex = /^\d{10}$/
const fields = {
  RECIPIENTS: 'sms_test_recipients'
}

const formatPhone = (number) => {
  return number.replace(/^(\d{3})(\d{3})(\d+)/, '($1) $2-$3')
}

/**
 * SMS Test message modal
 *
 * @component
 * @example
 *
 * const [ showComponent, setShowComponent ] = useState(true)
 *
 * const handleClose = () => {
 *   setShowComponent(false)
 * }
 *
 * return (
 *  { showComponent && <TestMessage onClose={ handleClose } /> }
 * )
*/
const TestMessage = props => {
  const { submitting, onClose, onSubmit, flowName, step, message } = props
  const defaultPhone = useSelector(state => state.User.user.phone_number)
  let defaultPhoneNumbers = []
  if (defaultPhone) {
    const phone = defaultPhone.replace(/^\+1/, '')
    defaultPhoneNumbers = [
      {
        key: phone,
        label: formatPhone(phone)
      }
    ]
  }
  const [ numbers, setNumbers ] = useState(defaultPhoneNumbers)
  const [ validPhone, setValidPhone ] = useState(false)
  const chain = useSelector(state => state.User.chain)

  useEffect(() => {
    EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_MODAL_DISPLAYED, {
      ...EGAnalytic.mapChain(chain),
      messageContext: flowName,
      messageContextStep: step,
    })
  }, [chain, flowName, step])

  const handleModalClose = () => {
    if (onClose) {
      EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_MODAL_ABANDONED, {
        ...EGAnalytic.mapChain(chain),
        messageContext: flowName,
        messageContextStep: step,
      })
      onClose()
    }
  }

  const handlePhonesChange = (value) => {
    if (!value.match(phoneRegex)) return
    setNumbers([
      ...numbers,
      {
        key: value,
        label: formatPhone(value)
      }
    ])
  }

  const handlePhonesDelete = value => {
    const updatedList = [ ...numbers.filter(n => n.label !== value) ]
    setNumbers(updatedList)
    if (!updatedList.length) {
      setValidPhone(false)
    }
  }

  const handleInputChange = event => {
    const { value } = event.target
    if (!value) {
      setValidPhone(false)
    } else {
      setValidPhone(!!value.match(phoneRegex))
    }
  }

  const formIsValid = () => {
    return validPhone || !!numbers.length
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(numbers, message)
    }
  }

  return (
    <div className='eg-campaign-test-message' data-testid='eg-campaign-test-message'>
      <StatelessModal className='modal' onClose={ handleModalClose }>
        <div className='title'>Send a test message</div>
        <div className='body'>
          <label htmlFor={ fields.RECIPIENTS }>Test message recipients</label>
          <div className='sublabel'>
            Enter 10-digit phone numbers without formatting
          </div>
          <ChipInput
            id={ fields.RECIPIENTS }
            value={ numbers.map(n => n.label) }
            onAdd={ handlePhonesChange }
            onDelete={ handlePhonesDelete }
            onInputChange={ handleInputChange }
          />
        </div>
        <div className='actions'>
          <Button
            className='action-button'
            text='CANCEL'
            type={ buttonTypes.SECONDARY }
            onClick={ handleModalClose }
          />
          <Button
            className='action-button submit'
            text='SEND TEST'
            disabled={ !formIsValid() }
            loading={ submitting }
            onClick={ handleSubmit }
          />
        </div>
      </StatelessModal>
    </div>
  )
}

TestMessage.propTypes = {
  message: PropTypes.object,
  step: PropTypes.string.isRequired,
  flowName: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

export default TestMessage
