import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styles from './index.module.scss'

let slider

const defaultSliderSetting = {
  dots: false,
  arrows: false,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: styles.slider,
}


const ImageSlider = ({selectedIndex, imageArray=[], withThumbnail=false, sliderSetting={}}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(selectedIndex)

  const changeImage = (index) => {
    slider.slickGoTo(index)
  }

  const goToPrevious = () => {
    slider.slickPrev()
  }

  const goToNext = () => {
    slider.slickNext()
  }

  const beforeSlideChange = (oldIndex, newIndex)  => {
    setSelectedImageIndex(newIndex)
  }

  const getImages = () => {
    const mappedImage = imageArray.map((image, index) => {
      return (
        <div key={index}>
          <img alt="Main Slide Part" key={index} className={styles.mainImage} src={image} />
        </div>
      )
    })
    return mappedImage   
  }

  const GetThumbnails = () => {
    if(!withThumbnail) return null
    const mappedThumbnails = imageArray.map((thumbnail, index) => {
      const className = index === selectedImageIndex ? styles.selectedThumbnail : styles.thumbnail
      return (
        <img
          key={index}
          alt="Restaurant Photos"
          className={className}
          src={thumbnail}
          onClick={() => changeImage(index) }
        />
      )
    })
    return (
      <div className={styles.thumbnailContainer}>
        {mappedThumbnails}
      </div>
    )
  }

  const MainSlider = () => {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.leftArrow} onClick={goToPrevious}><div /></div>
        <Slider 
          {...defaultSliderSetting}
          initialSlide={selectedImageIndex}
          ref={ s => slider = s }
          beforeChange={beforeSlideChange}
          {...sliderSetting}
        >
          { getImages() }
        </Slider>
        <div className={styles.rightArrow}  onClick={goToNext}><div /></div>
      </div> 
    )
  }

  return (
    <div className={styles.sliderContainer}>
      <MainSlider />
      <div className={styles.indexNumber}>
        {`${selectedImageIndex + 1} of ${imageArray.length}`}
      </div> 
      <GetThumbnails />
    </div>
  )
}

ImageSlider.propTypes = {
  selectedIndex: PropTypes.number,
  imageArray: PropTypes.array,
  withThumbnail: PropTypes.bool, 
  //A boolean value To render all images as
  // thumbnails below the slider,
  sliderSetting: PropTypes.object,
  // Slider setting to override.
}

export default ImageSlider
