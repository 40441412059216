import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../FormComponents'
import { PATHS } from '../../../constants'

import './styles.scss'

const ConnectCard = props => {
  const { buttonLabel = 'CONNECT', className = '' } = props
  return (
    <div className={ `eg-channels-connect-card ${className}` }>
      <div className='card-title'>Connect your marketing channels to see insights.</div>
      <div className='description'>We will generate recommendations based on your input.</div>
      <Button
        className='connect-button'
        text={ buttonLabel }
        onClick={() => {window.location.assign((PATHS.INTEGRATIONS))}}
      />
    </div>
  )
}

ConnectCard.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string
}

export default ConnectCard
