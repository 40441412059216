import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// import _ from 'lodash'

import {
  NumberList,
  NumberSettings,
  NumberActivity,
  TabBar,
  StatusWidget,
  SectionHeader
} from '../../VirtualNumber'
import LocalSpinner from '../../LocalSpinner'

import './styles.scss'

const tabs = {
  ACTIVITY: 'activity',
  SETTINGS: 'settings'
}

const tabItems = [
  { label: 'Activity', key: tabs.ACTIVITY },
  { label: 'Settings', key: tabs.SETTINGS }
]

const StoreNumbers = props => {
  const {
    numberId,
    match,
    getNumber,
    clearNumber,
    redirectToDefault
  } = props
  const restaurantUuid = match.params.uuid

  const [ selectedTab, setSelectedTab ] = useState(tabItems[0])

  useEffect(() => {
    if (numberId) {
      getNumber(numberId, restaurantUuid)
    }
    else {
      // if we don't receive a specific numberId, we have to decide what to display,
      // depending if the restaurant has numbers or not
      redirectToDefault(restaurantUuid)
    }
    return () => {
      clearNumber()
    }
  }, [ getNumber, clearNumber, redirectToDefault, numberId, restaurantUuid ])

  const renderHeaderRight = () => {
    return (
      <div className='header-number'>
        { props.virtualNumber.formattedNumber }
        <span className='status'>
          <StatusWidget status={props.virtualNumber.status} />
        </span>
      </div>
    )
  }

  const handleTabChange = (item) => {
    setSelectedTab(item)
  }

  // if we don't have a numberId, there is nothing to do
  if (!numberId) {
    return null
  }

  return (
    <div className='eg-number-details'>
      <div className='numbers'>
        <NumberList restaurantUuid={ restaurantUuid } />
      </div>
      {
        (!props.virtualNumber || props.loading) ? (
          <div className='section-wrapper'>
            <LocalSpinner />
          </div>
        ) : (
          <div className='section-wrapper'>
            <SectionHeader
              title={ props.virtualNumber.title }
              rightContent={ renderHeaderRight }
            />
            <TabBar
              tabItems={ tabItems }
              selectedTab={ selectedTab }
              onChange={ handleTabChange }
            />
            {
              selectedTab.key === tabs.ACTIVITY ? (
                <NumberActivity number={ props.virtualNumber } />
              ) : (
                <NumberSettings number={ props.virtualNumber } />
              )
            }
          </div>
        )
      }
    </div>
  )
}

StoreNumbers.propTypes = {
  numberId: PropTypes.string,
  virtualNumber: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  restaurantUuid: PropTypes.string,
  getNumber: PropTypes.func,
  clearNumber: PropTypes.func,
  redirectToDefault: PropTypes.func
}

export default withRouter(StoreNumbers)
