import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import { actions } from '../../../actions/Journey'
import StepsWidget from '../../StepsWidget'
import CustomerHeader from '../../CustomerHeader'
import BackLink from '../../BackLink'
import SetupJourney from '../Create/Setup'
import DesignJourney from '../Create/Design'
import ReviewJourney from '../Create/Review'
import { PATHS } from '../../../constants'
import SuccessMessage from '../../Journeys/SuccessMessage'
import ConfirmationModal from '../../ConfirmationModal'
import EGAnalytic, { analyticEvents } from '../../../services/analytics'
import { fields as setupFields } from '../Create/Setup'
import { steps, statuses } from '../../../services/journeys'
import './styles.scss'

const JourneyCreate = props => {
  const { history, journey, step = steps[0] } = props
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(step)
  const [readyToSend, setReadyToSend] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const journeyData = useSelector(state => _.get(state, 'Journey.journey.data')) || {}
  const {
    submitting,
    success: successSubmission
  } = useSelector(state => state.Journey.create)
  let journeyId = useSelector(state => state.Journey.create.journeyId)
  if (journey) {
    journeyId = journey.id
  }
  const chain = useSelector(state => state.User.chain)
  const {
    submitting: submittingStateChange,
    success: stateChangeSuccess
  } = useSelector(state => state.Journey.updateStatus)
  const prevStep = useRef({})

  useEffect(() => {
    return () => {
      // when component is unmounted, clear component and redux states
      setCurrentStep(steps[0])
      dispatch(actions.clear())
      steps.forEach(s => s.completed = false)
    }
  }, [dispatch])

  // handle success calls after each step
  useEffect(() => {
    // move ahead to the next step
    if (prevStep.current.key !== currentStep.key) {
      prevStep.current = { ...currentStep }
      return
    }
    if (successSubmission) {
      let stepIndex = 0
      steps.forEach((s, i) => { if (s.key === currentStep.key) stepIndex = i })
      if (stepIndex < steps.length - 1) {
        setCurrentStep(steps[stepIndex + 1])
      }
      steps[stepIndex].completed = true
    }
  }, [successSubmission, currentStep])

  useEffect(() => {
    if (stateChangeSuccess) {
      EGAnalytic.track(analyticEvents.JOURNEY_ACTIVATED, {
        ...EGAnalytic.mapChain(chain),
        journeyName: journeyData[setupFields.NAME],
        triggerName: journeyData[setupFields.TRIGGER].label,
        triggerId: journeyData[setupFields.TRIGGER_ID],
      })
      setReadyToSend(false)
      setShowSuccess(true)
    }
  }, [chain, journeyData, stateChangeSuccess])

  const handleCancel = () => {
    history.push(PATHS.SMS_JOURNEYS)
  }

  const handleStepClick = step => {
    setCurrentStep(step)
  }

  const handleSuccessClose = () => {
    setShowSuccess(false)
    history.replace(PATHS.SMS_JOURNEYS)
  }

  const handleSubmit = (step, data) => {
    if (step === 'review') {
      setReadyToSend(true)
    } else {
      const dataToProcess = {
        ...journeyData,
        ...data
      }
      dispatch(actions.upsertJourney(dataToProcess, step))
    }
  }

  const handleActivate = () => {
    dispatch(actions.setJourneyState({
      journeyId,
      state: journey && journey.status === statuses.ACTIVE ? statuses.INACTIVE : statuses.ACTIVE
    }))
  }

  const trackJourneyCancel = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_CREATION_ABANDONED, EGAnalytic.mapChain(chain))
  }

  return (
    <div className='eg-journey-create'>
      <CustomerHeader />
      <BackLink to={PATHS.SMS_JOURNEYS} onClick={trackJourneyCancel}>Journeys</BackLink>
      <div className='body'>
        <div className='steps-container'>
          <StepsWidget
            steps={steps}
            selectedStep={currentStep}
            onClick={handleStepClick}
          />
        </div>
        {
          currentStep.key === 'setup' && (
            <SetupJourney
              data={journeyData}
              submitting={submitting}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'design' && (
            <DesignJourney
              data={journeyData}
              submitting={submitting}
              onBackClick={() => setCurrentStep(steps[0])}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'review' && (
            <ReviewJourney
              data={journeyData}
              submitting={submitting}
              onEditMessageClick={() => setCurrentStep(steps[1])}
              onEditTriggerClick={() => setCurrentStep(steps[0])}
              onSubmit={handleSubmit}
            />
          )
        }
      </div>
      {
        readyToSend && (
          <ConfirmationModal
            title={ `${journey && journey.status === statuses.ACTIVE ? 'Deactivate' : 'Activate'} journey?` }
            text='This will take effect immediately'
            loading={ submittingStateChange }
            onCancel={ () => setReadyToSend(false) }
            onConfirm={ handleActivate }
          />
        )
      }
      { showSuccess && <SuccessMessage data={journeyData} onClose={handleSuccessClose} />}
    </div>
  )
}

JourneyCreate.propTypes = {
  history: PropTypes.object,
  journey: PropTypes.object,
  step: PropTypes.object
}

export default JourneyCreate

