import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { types as appTypes } from '../actions/App'

import User from './User'
import Notifications from './Notifications'
import EgNotification from './EgNotification'
import Dashboard from './Dashboard'
import Order from './Order'
import Customer from './Customer'
import Call from './Call'
import VirtualNumber from './VirtualNumber'
import Spinner from './Spinner'
import Route from './Route'
import Chain from './Chain'
import Sidebar from './Sidebar'
import GoogleConnect from './GoogleConnect'
import Square from './Square'
import Modal from './Modal'
import Review from './Review'
import Plaid from './Plaid'
import Event from './Event'
import Store from './Store'
import Upload from './Upload'
import Filter from './Filter'
import OauthConnect from './OauthConnect'
import Channel from './Channel'
import Interactions from './Interactions'
import LoyaltyIvr from './LoyaltyIvr'
import Campaign from './Campaign'
import Journey from './Journey'
import Segment from './Segment'
import SMS from './SMS'
import Report from './Report'

export const history = createBrowserHistory()

const appReducer = combineReducers({
  User,
  Notifications,
  EgNotification,
  router: connectRouter(history),
  Dashboard,
  Order,
  Customer,
  Spinner,
  Route,
  Call,
  VirtualNumber,
  Chain,
  Sidebar,
  GoogleConnect,
  Square,
  Modal,
  Review,
  Plaid,
  Event,
  Store,
  Upload,
  Filter,
  OauthConnect,
  Channel,
  Interactions,
  LoyaltyIvr,
  Campaign,
  Journey,
  Segment,
  SMS,
  Report
})

const rootReducer = (state, action) => {
  if (action.type === appTypes.RESET_APP) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
