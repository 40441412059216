import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import StoreCreate from './StoreCreate'
import { actions as storeActions } from '../../actions/Store'

const mapStateToProps = state => ({
  categories: state.Store.categories,
  loadingStore: state.Store.loadingStore,
  loadingCategories: state.Store.loadingCategories,
})

const mapDispatchToProps = dispatch => ({
  createStore(data, section) {
    dispatch(storeActions.createStore(data, section))
  },
  getCategories() {
    dispatch(storeActions.getCategories())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreCreate))
