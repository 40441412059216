import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Button, { types as buttonTypes } from '../../../../FormComponents/Button'
import { ScheduleForm } from './Schedule'
import { SpecialScheduleForm } from './SpecialSchedule'

import styles from './form.module.scss'

const OperationDetails = props => {
  const store = props.store
  const hasSchedules = !!_.get(store, 'operationDetails.specialSchedule.length')
  const [schedules, setSchedules] = useState(store.operationDetails.schedule)
  const [specialSchedules, setSpecialSchedules] = useState(
    hasSchedules
      ? _.get(store, 'operationDetails.specialSchedule')
      : [{ state: null, deleted: false }]
  )

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleSaveClick = () => {
    if (props.onSave) {
      props.onSave({
        schedule: schedules,
        specialSchedule: specialSchedules
      })
    }
  }

  const handleScheduleChange = updatedSchedules => {
    setSchedules(updatedSchedules)
  }

  const handleSpecialScheduleChange = updatedSchedules => {
    setSpecialSchedules(updatedSchedules)
  }

  const handleSpecialScheduleAdd = () => {
    setSpecialSchedules([
      ...specialSchedules,
      { state: null }
    ])
  }

  const isValid = () => {
    let valid = true
    schedules.forEach(s => {
      const from = _.get(s, 'time.from')
      const to = _.get(s, 'time.to')
      if ((from && !to) || (to && !from)) {
        valid = false
      }
    })
    return valid
  }

  return (
    <div className={ styles.operationDetailsContainer }>
      <h3>Operation details</h3>
      <p>Indicate your store operating hours.</p>
      <div className={ styles.formContainer }>
        <div className={ styles.formWrapper }>
          <div className={ styles.leftSection }>Operating hours</div>
          <ScheduleForm
            store={ store }
            schedules={ schedules }
            onChange={ handleScheduleChange }
          />
        </div>
        <div className={ styles.separator } />
        <div className={ styles.formWrapper }>
          <div className={ styles.leftSection }>Special hours</div>
          <SpecialScheduleForm
            store={ store }
            specialSchedules={ specialSchedules }
            onChange={ handleSpecialScheduleChange }
            onAddSpecialSchedule={ handleSpecialScheduleAdd }
          />
        </div>
        <div className={ styles.actionsWrapper }>
          <Button
            text='CANCEL'
            type={ buttonTypes.SECONDARY }
            onClick={ handleCancelClick }
          />
          <Button
            text='SAVE'
            type={ buttonTypes.PRIMARY }
            onClick={ handleSaveClick }
            disabled={ !isValid() }
          />
        </div>
      </div>
    </div>
  )
}

OperationDetails.propTypes = {
  store: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default OperationDetails
