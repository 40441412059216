import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmNewPassword from './ConfirmNewPassword'
import { actions } from '../../actions/User'

const mapStateToProps = state => ({
  posting: state.User.posting,
  errorCode: state.User.errorCode
})

const mapDispatchToProps = dispatch => ({
  onSubmit(data) {
    dispatch(actions.confirmPassword(data))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmNewPassword))
