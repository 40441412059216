import axios from 'axios'
import { METHODS, HeaderFactory, constructStoreQuery } from './utils'
import moment from 'moment'
import _ from 'lodash'
// import { getListMock } from '../test/mocks/customerInteractions'

/**
 * get customer by id
 *
 * @param {number} restaurantId
 * @param {number} customerId
 */
export const getCustomerById = (opts, tokens) => {
  const { restaurantChainUuid, customerId } = opts
  // build parameter
  const qparams = [`chain_uuid=${restaurantChainUuid}`]
  return axios({
    url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers/${customerId}?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * Gets order summary for a given customer
 * @param {*} param0 
 * @param {*} tokens 
 */
export const getOrderSummary = (opts, tokens) => {
  const { restaurantChainUuid, selectedStores, customerId } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  // build parameters
  const qparams = [...storeQueryParams]
  return axios({
    url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers?sources[0]=square&sources[1]=partner_orders&customer_id=${customerId}&${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

export const getCustomerActivities = async (opts, tokens) => {
  const { restaurantChainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  // build parameters
  const qparams = [...storeQueryParams]
  if (opts) {
    if (opts.sort) {
      if (opts.sort.key === 'customer') {
        // TODO: add support for multiple-fields sorting
        qparams.push(`order[0][0]=first_name&order[0][1]=${opts.sort.direction}`)
        qparams.push(`order[1][0]=last_name&order[1][1]=${opts.sort.direction}`)
      } else {
        qparams.push(`order[0][0]=${opts.sort.key}&order[0][1]=${opts.sort.direction}`)
      }
    }
    if (opts.page) {
      qparams.push(`page=${opts.page}`)
    }
    if (opts.size) {
      qparams.push(`size=${opts.size}`)
    }
    if (opts.search) {
      qparams.push(`search=${opts.search}`)
    }
  }
  return axios({
    url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

const appendCustomerFilters = (qparams, opts) => {
  const updatedParams = [ ...qparams ]
  if (!_.isEmpty(opts.filters)) {
    if (opts.filters.date_filter) {
      updatedParams.push(`registration_date[from]=${opts.filters.date_filter.value}`)
      updatedParams.push(`registration_date[to]=${moment().utc().startOf('day').format()}`)
    }
    if (opts.filters.total_interactions) {
      const { total_interactions: totalInteractions } = opts.filters
      updatedParams.push(`total_interactions[${totalInteractions.key}]=${totalInteractions.value}`)
    }
    if (opts.filters.last_source && opts.filters.last_source.length) {
      const { last_source: lastSource } = opts.filters
      lastSource.forEach((source, i) => {
        updatedParams.push(`source[${i}]=${source.key}`)
      })
    }
    if (opts.filters.source_medium && opts.filters.source_medium.length) {
      const { source_medium: sourceMedium } = opts.filters
      sourceMedium.forEach((medium, i) => {
        updatedParams.push(`source_mediums[${i}]=${medium.key}`)
      })
    }
    if (opts.filters.interaction_types && opts.filters.interaction_types.length) {
      const { interaction_types: interactionTypes } = opts.filters
      interactionTypes.forEach((type, i) => {
        updatedParams.push(`activity_types[${i}]=${type.key}`)
      })
    }
  }
  return updatedParams
}

export const getCustomers = async (opts, tokens) => {
  const { restaurantChainUuid } = opts
  let qparams = []
  if (opts) {
    if (opts.sort) {
      const field = opts.sort.direction === 'ASC' ? opts.sort.key : `-${opts.sort.key}`
      qparams.push(`sort=${field}`)
    }
    if (opts.page) {
      qparams.push(`page=${opts.page}`)
    }
    if (opts.size) {
      qparams.push(`size=${opts.size}`)
    }
    if (opts.search) {
      qparams.push(`search=${opts.search}`)
    }

    // customer filters
    qparams = appendCustomerFilters(qparams, opts)
  }
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/customer?chain_uuid=${restaurantChainUuid}&${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}


export const getCustomerCsvUrl = (opts, tokens) => {
  const { restaurantChainUuid, allData } = opts
  let qparams = [`chain_uuid=${restaurantChainUuid}`]
  if(!allData) {
    // customer filters
    qparams = appendCustomerFilters(qparams, opts)
  }

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/customer/export-csv?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

export const getCustomerInteractions = async (opts, tokens) => {
  const { restaurantChainUuid, customerId } = opts
  const qparams = []
  if (opts) {
    if (opts.sort) {
      const field = opts.sort.direction === 'ASC' ? opts.sort.key : `-${opts.sort.key}`
      qparams.push(`sort=${field}`)
    }
    if (opts.page) {
      qparams.push(`page=${opts.page}`)
    }
    if (opts.size) {
      qparams.push(`size=${opts.size}`)
    }
    if (opts.search) {
      qparams.push(`search=${opts.search}`)
    }
  }

  const strParams = `chain_uuid=${restaurantChainUuid}&${qparams.join('&')}`

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/customer/${customerId}/activity?${strParams}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
