import axios from 'axios'
import _ from 'lodash'
import { METHODS, HeaderFactory } from './utils'

/**
 * Call to create a segment with selected csv file
 * @param {Object}} opts
 * @param opts.chainUuid
 * @param opts.name segment name
 * @param opts.description segment description
 * @param opts.segmentFile selected CSV file info
 * @param {*} tokens
 * @returns
 */
export const createSegment = async (opts, tokens) => {
  const { chainUuid, name, description, segmentFile, onUploadProgress } = opts
  const payload = {
    name,
    file: {
      filename: segmentFile.name,
      content_type: 'text/csv',
    }
  }
  if(description) {
    payload.description = description
  }
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/chain/${chainUuid}/segment`
  const response = await axios({
    url: `${baseUrl}?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: payload
  })

  const url = response.data.url
  //upload the segment CSV file
  await axios.request({
    method: 'put',
    url,
    data: segmentFile,
    onUploadProgress,
    headers: {
      'Content-Type': 'text/csv'
    },
  })
  delete response.data.url
  return response
}

/**
 * get segment list
 *
 * @param opts {Object} - options
 * @param opts.chainUuid {string} - chain UUID
 * @param opts.page {number} - page number
 * @param opts.size {number} - segments per page
 * @param tokens {Object} - user auth tokens
 *
 * @returns {Promise} - request promise
*/
export const getSegmentList = (opts, tokens) => {
  const { chainUuid, page, size, statuses } = opts
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/segment`
  const qparams = [
    `chain_uuid=${chainUuid}`,
    `page=${page}`,
    `size=${size}`,
  ]
  if(statuses && statuses.length) {
    statuses.forEach((status, index) => {
      qparams.push(`statuses[${index}]=${status}`)
    })
  }
  return axios({
    url: `${baseUrl}?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

export const upsertDynamicSegment = ({ opts, chainUuid, segmentId }, tokens) => {
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}`
  const payload = {
    name: _.get(opts, 'data.name'),
    // TODO: move 'dynamic' stirng contstant in service file
    type: 'dynamic'
  }

  const description = _.get(opts, 'data.description')
  if(description) {
    payload.description = description
  }
  if(opts.predicates) {
    payload.payload = {
      predicates: opts.predicates
    }
  }

  if (opts.step === 'setup' && !segmentId) {
    return axios({
      url: `${baseUrl}/chain/${chainUuid}/segment`,
      method: METHODS.POST,
      headers: HeaderFactory(tokens),
      data: payload
    })
  } if (opts.step === 'setup' && segmentId) {
    // type not required
    delete payload.type
    return axios({
      url: `${baseUrl}/segment/${segmentId}?chain_uuid=${chainUuid}`,
      method: METHODS.PATCH,
      headers: HeaderFactory(tokens),
      data: payload
    })
  } else if (opts.step === 'design' && segmentId) {
    // type not required
    delete payload.type
    return axios({
      url: `${baseUrl}/segment/${segmentId}?chain_uuid=${chainUuid}`,
      method: METHODS.PATCH,
      headers: HeaderFactory(tokens),
      data: payload
    })
  } else if (opts.step === 'review' && segmentId) {
    // type not required
    delete payload.type
    payload.status = 'published'
    return axios({
      url: `${baseUrl}/segment/${segmentId}?chain_uuid=${chainUuid}`,
      method: METHODS.PATCH,
      headers: HeaderFactory(tokens),
      data: payload
    })
  }
}

/**
 * get segment
 *
 * @param opts {Object} - options
 * @param opts.chainUuid {string} - chain UUID
 * @param opts.id {string} - segment id
 * @param tokens {Object} - user auth tokens
 *
 * @returns {Promise} - request promise
*/
export const getDynamicSegment = (opts, tokens) => {
  const { chainUuid, id } = opts
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/segment/${id}`

  return axios({
    url: `${baseUrl}?chain_uuid=${chainUuid}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
