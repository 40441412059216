import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { predicateTypes, predicateAttributes, predicateComparison } from '../../../../../services/segments'
import { Select } from '../../../../FormComponents'
import Input from '../../../../FormComponents/Input'

import './styles.scss'

export const numberOptions = [
  { label: 'equal to', value: predicateComparison.EQ },
  { label: 'less than', value: predicateComparison.LTE},
  { label: 'more than', value:  predicateComparison.GTE},
  // { label: 'at least once', value: NUMBER_OPTIONS.AT_LEAST_ONCE},
]

NumberPredicate.propTypes = {
  predicate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isCurrency: PropTypes.bool,
}

function NumberPredicate(props) {
  const { onChange, predicate, isCurrency=false } = props
  const comparision = _.get(predicate, 'comparison')
  let selectedOption = numberOptions[0]
  if(comparision) {
    selectedOption = numberOptions.find((option) =>  option.value === comparision)
  }
  const [ selectedPredicate, setSelectedPredicate ] = useState(selectedOption)
  const [ value, setValue ] = useState(_.get(predicate, 'value'))

  const handleOnSelect = (item) => {
    const predicateData =   {
      type: predicateTypes.NUMBER,
      attribute: predicateAttributes.TOTAL,
      comparison: item.value,
      value: value || 1,
    }
    onChange(predicateData)
    setSelectedPredicate(item)
  }

  const onNumberChange = (number) => {
    const predicateData =   {
      type: predicateTypes.NUMBER,
      attribute: predicateAttributes.TOTAL,
      comparison: selectedPredicate.value,
      value: number,
    }
    onChange(predicateData)
  }

  const onInputChange = (newValue) => {
    if(newValue < 0) return
    setValue(newValue)
    onNumberChange(newValue)
  }

  return (
    <div className='eg-segments-predicates-number'>
      <Select
        className='condition'
        options={ numberOptions }
        selectedItem={ selectedPredicate }
        onChange={ handleOnSelect }
      />
      {isCurrency &&  (
        <div className='text-node text-currency'>
            $
        </div>)}
      <Input
        type='number'
        value={ value }
        onChange={(event) => onInputChange(event.target.value)}
        className='number-input'
        id='number-input'
      />
    </div>
  )
}

export default NumberPredicate
