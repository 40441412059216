import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects'
import { types, actions } from '../actions/Report'
import { authorizedRequest } from './Auth'
import { NetworkError } from '../services/error'
import {
  getReportList
} from '../api/Report'
import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

function* getReportListHandler(opts) {
  const { page = 1, size = 10 } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getReportList, {
      chainUuid: chain.restaurant_chain_uuid,
      page,
      size
    })
    if (res && res.data) {
      yield put(actions.getReportsResolved({...res.data, page, size}))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.getReportsRejected(error))
  }
}


export default function* () {
  yield takeLatest(types.GET_REPORTS_INTENT, getReportListHandler)
}
