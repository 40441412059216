

import React from 'react'
import PropType from 'prop-types'

import './styles.scss'

const FilterFooter = (props) => {
  const { showClear = false, onClear, onApply } = props

  const handleEvent = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  return (
    <div className='eg-filter-footer' onClick={handleEvent}>
      {showClear
        ? (
          <div className='clear' onClick={onClear} data-testid='store-clear'>
            RESET
          </div>
        )
        : <div />
      }
      <div className='apply' onClick={onApply} data-testid='store-apply'>
        APPLY
      </div>
    </div>
  )
}

FilterFooter.propTypes = {
  showClear: PropType.bool.isRequired,
  onClear: PropType.func.isRequired,
  onApply: PropType.func.isRequired,
}

export default FilterFooter
