import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '../../../../FormComponents/TextField'
import Button, { types as buttonTypes } from '../../../../FormComponents/Button'
import FormValidator from '../../../../../validators/FormValidator'
import {
  url as urlValidator
} from '../../../../../validators/customValidations'
import { linkSections } from '../../../../../services/stores'

import styles from './index.module.scss'
import { SOCIAL_MEDIA_LINKS } from './constants.js'

let validation

const getValidatorRules = () => {
  let validatorsRules = []
  SOCIAL_MEDIA_LINKS.forEach((media) => {
    validatorsRules.push(...urlValidator(media.key, false))
  })
  return new FormValidator(validatorsRules)
}

const validator = getValidatorRules()

const SocialMediaLinks = props => {
  const store = props.store
  const [ formFields, setFormFields ] = useState(() => {
    return getInitialState(store)
  })

  const getFieldError = (fieldName) => {
    if (formFields[fieldName].dirty && !validation[fieldName].isValid) {
      return validation[fieldName].message
    }
    return ''
  }

  const handleInputChange = (event) => {
    event.persist()
    setFormFields(prevState => {
      if (!event.target) {
        return prevState
      }
      return {
        ...prevState,
        [event.target.name]: {
          value: event.target.value,
          dirty: true
        }
      }
    })
  }

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleSaveClick = () => {
    if (props.onSave) {
      const payload = []
      Object.keys(formFields).forEach(key => {
        payload.push({
          subtype: key,
          type: linkSections.MEDIA_PROFILE,
          url: formFields[key].value
        })
      })
      props.onSave(payload)
    }
  }

  validation = validator.validate(formFields)

  return (
    <div className={ styles.linksContainer }>
      <h3>Social media profiles</h3>
      <p>Add links to your store social media pages.</p>
      <div className={ styles.linksFormContainer }>
        {
          SOCIAL_MEDIA_LINKS.map((media, index) => {
            return (
              <div className={ styles.linksFormRow }>
                <div className={styles.leftPart}>
                  <div className={ styles.logoWrapper}>
                    <div className={styles[media.key]} />
                  </div>
                  <span>{media.label}</span>
                </div>
                <TextField
                  name={ media.key }
                  label=''
                  placeholder='https://'
                  value={ formFields[media.key].value }
                  className={ styles.inputField }
                  errorMessage={ getFieldError(media.key) }
                  error={!validation[media.key].isValid && formFields[media.key].dirty}
                  onChange={ handleInputChange }
                />
              </div>
            )
          })
        }
      </div>
      <div className={ styles.actionsWrapper }>
        <Button
          text='CANCEL'
          type={ buttonTypes.SECONDARY }
          onClick={ handleCancelClick }
        />
        <Button
          text='SAVE'
          type={ buttonTypes.PRIMARY }
          disabled={ !validation.isValid }
          onClick={ handleSaveClick }
        />
      </div>
    </div>
  )
}

/**
 * Sets initial state for the from, i.e getting all the existing field values
 * @param {Object} store restaurant store info
 */
const getInitialState = (store) => {
  const initialFormState = SOCIAL_MEDIA_LINKS.reduce((obj, media) => {
    const mediaUrl = getLink(store, media)
    obj[media.key] = {
      value: mediaUrl,
      dirty: false,
    }
    return  obj
  }, {})
  return initialFormState
}

/**
 * Retrieves link for the social media account from store.
 * @param {Object} store  restaurant store info
 * @param {Object} media 
 */
const getLink = (store, media) => {
  const mediaProfilesLinks = store && store.mediaProfiles
  const mediaLink = mediaProfilesLinks
      && mediaProfilesLinks.find(link => link.subtype === media.key)
  return mediaLink ? mediaLink.url : ''
}

SocialMediaLinks.propTypes = {
  store: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default SocialMediaLinks
