import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import { actions } from '../../../actions/Campaign'
import StepsWidget from '../../../components/StepsWidget'
import SetupCampaign from '../../../components/Campaigns/Create/Setup'
import DesignCampaign from '../../../components/Campaigns/Create/Design'
import ReviewCampaign from '../../../components/Campaigns/Create/Review'
import CustomerHeader from '../../../components/CustomerHeader'
import BackLink from '../../../components/BackLink'
import EGAnalytic, { analyticEvents } from '../../../services/analytics'
import { getSteps, getStepIndex } from '../../../services/campaigns'
import { PATHS } from '../../../constants'
import './styles.scss'

const CampaignCreate = props => {
  const { history, campaign } = props
  const [steps] = useState(getSteps(campaign))
  const dispatch = useDispatch()
  const {
    submitting: submittingCreate,
    success: successCreate
  } = useSelector((state) => state.Campaign.campaignCreate)
  const {
    submitting: submittingUpdate,
    success: successUpdate
  } = useSelector((state) => state.Campaign.campaignUpdate)
  const chain = useSelector(state => state.User.chain)
  const [currentStep, setCurrentStep] = useState(steps[0])
  const [campaignData, setCampaignData] = useState(campaign || {})
  const prevStep = useRef({})

  useEffect(() => {
    setCampaignData(campaign)
  }, [campaign])

  // switch to next step after submission
  useEffect(() => {
    if (prevStep.current.key !== currentStep.key) {
      prevStep.current = { ...currentStep }
      return
    }
    const successSubmission = successCreate || successUpdate
    const stepIndex = getStepIndex(currentStep)
    if (successSubmission && stepIndex < steps.length - 1) {
      setCurrentStep(steps[stepIndex + 1])
      steps[stepIndex].completed = true
    }
  }, [successCreate, successUpdate, currentStep, dispatch, steps])

  const trackCampaignCancel = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_CREATION_ABANDONED, EGAnalytic.mapChain(chain))
  }

  const handleCancel = () => {
    trackCampaignCancel()
    history.push(PATHS.SMS_CAMPAIGNS)
  }

  const handleStepChange = step => {
    setCurrentStep(step)
  }

  const handleSubmit = (step, data) => {
    let stepIndex = 0
    steps.forEach((s, i) => { if (s.key === step) stepIndex = i })
    const submitData = { ...data, step: stepIndex + 1 }
    if (step === 'setup' && !_.get(campaign, 'id')) {
      dispatch(actions.createCampaign(submitData))
    } else {
      dispatch(actions.updateCampaign({ ...submitData, campaignId: _.get(campaign, 'id') }))
    }
    setCampaignData({
      ...campaignData,
      ...data
    })
  }

  const goBackPath = _.get(history, 'location.state.preUrl') || PATHS.SMS_CAMPAIGNS

  return (
    <div className='eg-campaign-create'>
      <CustomerHeader />
      <BackLink to={goBackPath} onClick={trackCampaignCancel}>Campaigns</BackLink>
      <div className='body'>
        <div className='steps-container'>
          <StepsWidget
            steps={steps}
            selectedStep={currentStep}
            onClick={handleStepChange}
          />
        </div>
        {
          currentStep.key === 'setup' && (
            <SetupCampaign
              data={campaignData}
              submitting={ submittingCreate || submittingUpdate }
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'design' && (
            <DesignCampaign
              data={campaignData}
              submitting={ submittingUpdate }
              onBackClick={() => handleStepChange(steps[0])}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'review' && (
            <ReviewCampaign
              data={ campaignData }
              campaignId={ campaign.id }
              submitting={ submittingUpdate }
              onEditMessageClick={() => handleStepChange(steps[1])}
              onEditRecipientsClick={() => handleStepChange(steps[0])}
              onSubmit={ handleSubmit }
            />
          )
        }
      </div>
    </div>
  )
}

CampaignCreate.propTypes = {
  campaign: PropTypes.object,
  history: PropTypes.object
}

export default CampaignCreate

