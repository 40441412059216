import React from 'react'
import PropTypes from 'prop-types'

import Pagination from '../Pagination'
import { Select } from '../../components/FormComponents'

import './styles.scss'

const pageSizes = [ 25, 50, 100 ].map(i => ({ value: i, label: `${i} rows` }))

const PaginationSection = props => {
  const {
    total,
    size = pageSizes[0],
    page,
    className = '',
    sizeOptionsDirection = 'bottom'
  } = props

  const handleSizeChange = (e) => {
    if (props.onSizeChange) {
      props.onSizeChange(e.value)
    }
  }

  const handlePageChange = (e) => {
    if (props.onPageChange) {
      props.onPageChange(e)
    }
  }

  const selectedItem = { value: size, label: `${size} rows` }

  return (
    <div className={ `eg-pagination-section ${className}` }>
      <div className='size'>
        Showing
        <Select
          className='dropdown'
          options={ pageSizes }
          selectedItem={ selectedItem }
          onChange={ handleSizeChange }
          menuPlacement={ sizeOptionsDirection }
        />
      </div>
      <Pagination
        className=''
        currentPage={ page }
        pageCount={ Math.ceil(total / size) }
        onPageClick={ handlePageChange }
        marginPages={ 1 }
        pageRange={ 1 }
      />
    </div>
  )
}

PaginationSection.propTypes = {
  total: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  page:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  selectedSize: PropTypes.number,
  sizeOptionsDirection: PropTypes.string,
  onSizeChange: PropTypes.func,
  onPageChange: PropTypes.func
}

export default PaginationSection
