import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../Checkbox'

import styles from './index.module.scss'

/**
 * FilterOptions base component. This shouldn't be used as a "final" filter component
 * since is a generic-purpose one. It should be wrapped with some specific one
*/
export default class FilterOptions extends React.Component {
  static propTypes = {
    actionLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    items: PropTypes.array,
    selectedItems: PropTypes.array,
    multiple: PropTypes.bool,
    actionClass: PropTypes.string,
    actionActiveClass: PropTypes.string,
    applyOnClickOut: PropTypes.bool,
    customItemsComponent: PropTypes.object,
    applyDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onApply: PropTypes.func
  }

  static defaultProps = {
    applyOnClickOut: false,
    applyDisabled: false
  }

  constructor() {
    super()

    // initial state
    this.state = {
      isActive: false
    }

    // bindings
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleActionClick = this.handleActionClick.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleApply = this.handleApply.bind(this)
    this.handleMultipleItemsClick = this.handleMultipleItemsClick.bind(this)
    this.handleSingleItemClick = this.handleSingleItemClick.bind(this)
    this.renderItems = this.renderItems.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Handles the action if clicking outside the component.
   * for multiple selection filters, this implies to apply the selected
   * filters.
   *
   * For single selection items, on the other hand, we simply hide
   * the dropdown since we don't have anything to apply in this case.
   *
   * There is an exception though, when we pass `applyOnClickOut=true`
   * the component will apply changes anyway. This is useful when we
   * have `customItemsComponent` set and the behaviour is defined in
   * the parent component.
  */
  handleClickOutside(e) {
    if(!this.state.isActive || this.node.contains(e.target)) {
      return
    }
    if (this.props.multiple || this.props.applyOnClickOut) {
      this.handleApply()
    } else {
      this.setState({ isActive: false })
    }
  }

  /**
   * handles the trigger button click action.
   * i.e. opening/closing the filter dropdown
  */
  handleActionClick() {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  /**
   * handles de action of clearing the filters
  */
  handleClear() {
    this.setState({ isActive: false }, () => {
      if (this.props.onClear) {
        this.props.onClear()
      }
    })
  }

  /**
   * handle item click in multiple selection mode
  */
  handleMultipleItemsClick(item, isSelected) {
    if (!isSelected) {
      this.props.onChange([
        ...this.props.selectedItems,
        item.key
      ])
    } else {
      let indexToDelete
      this.props.selectedItems.forEach((si, i) => {
        if (si === item.key) { indexToDelete = i }
      })
      const updatedItems = [ ...this.props.selectedItems ]
      updatedItems.splice(indexToDelete, 1)
      this.props.onChange(updatedItems)
    }
  }

  /**
   * handle item click in single selection mode
  */
  handleSingleItemClick(item) {
    this.setState({ isActive: false }, () => {
      this.props.onApply(item.key)
    })
  }

  /**
   * this method is triggered when the filter is applied. This is
   * ready for update parent filters
  */
  handleApply(opts = {}) {
    const { close = true } = opts
    const apply = () => {
      if (this.props.onApply && !this.props.applyDisabled) {
        this.props.onApply(this.props.selectedItems)
      }
    }
    if (close) {
      this.setState({ isActive: false }, apply)
    } else {
      apply()
    }
  }

  renderItems() {
    if (this.props.customItemsComponent) {
      return this.props.customItemsComponent
    }

    if (!this.props.items) {
      return null
    }

    return this.props.items.map((item, index) => {
      const isSelected = this.props.selectedItems.find(i => {
        return i === item.key
      }) !== undefined

      // multiple selection
      if (this.props.multiple) {
        return (
          <div
            key={ index }
            className={ `${styles.filterItem}` }
            onClick={ () => this.handleMultipleItemsClick(item, isSelected) }
          >
            <Checkbox checked={ isSelected } />
            <div className={ styles.itemWrapper }>
              { typeof item.content === 'function' ? item.content() : item.content }
            </div>
          </div>
        )
      }

      // single selection
      return (
        <div
          key={ index }
          className={ `${styles.filterItem} ${styles.singleFilterItem}` }
          onClick={ () => this.handleSingleItemClick(item, isSelected) }
        >
          <div className={ styles.itemWrapper }>
            { typeof item.content === 'function' ? item.content() : item.content }
          </div>
          {
            isSelected ? <i className="zmdi zmdi-check"></i> : null
          }
        </div>
      )
    })
  }

  render() {
    const activeClass = this.state.isActive ? `${styles.active} ${this.props.actionActiveClass || ''}` : ''
    const hasItemsClass = this.props.selectedItems && this.props.selectedItems.length
      ? `${styles.greenBackground} ${this.props.actionActiveClass || ''}`
      : ''
    const actionLabel = this.props.actionLabel
    const actionClass = this.props.actionClass || ''
    const applyDisabledClass = this.props.applyDisabled ? styles.applyDisabled : ''
    const closeOnApply = !this.props.applyDisabled

    return (
      <div className={ styles.filterContainer } ref={ (node) => this.node = node } data-testid='eg-filter-options-container'>
        <div
          className={ `${styles.filterTrigger} ${activeClass} ${hasItemsClass} ${actionClass}` }
          onClick={ this.handleActionClick }
        >
          { typeof actionLabel === 'function' ? actionLabel() : actionLabel }
        </div>
        <div className={ `${styles.filterBody} ${activeClass} eg-filter-options-container-body` }>
          { this.props.customItemsComponent ? this.props.customItemsComponent : this.renderItems() }
          {
            (this.props.multiple || this.props.customItemsComponent) && (
              <div className={ styles.filterFooter }>
                <div className={ styles.btnSecondary } onClick={ this.handleClear }>CLEAR</div>
                <div
                  className={ `${styles.btnPrimary} ${applyDisabledClass}` }
                  onClick={ () => this.handleApply({ close: closeOnApply }) }
                >APPLY</div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
