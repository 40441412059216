export const types = {
  LOGOUT_INTENT: 'LOGOUT_INTENT',
  LOGOUT_INTENT_RESOLVED: 'LOGOUT_INTENT_RESOLVED',
  LOGOUT_INTENT_REJECTED: 'LOGOUT_INTENT_REJECTED',
  AUTHENTICATE_INTENT: 'AUTHENTICATE_INTENT',
  AUTHENTICATE_INTENT_RESOLVED: 'AUTHENTICATE_INTENT_RESOLVED',
  AUTHENTICATE_INTENT_REJECTED: 'AUTHENTICATE_INTENT_REJECTED',
  UPDATE_AUTH_INFO: 'UPDATE_AUTH_INFO',
  UPDATE_CHAIN_INFO: 'UPDATE_CHAIN_INFO',
  CHANGE_TOKEN: 'CHANGE_TOKEN', // -> For testing purposes
  INITIATE_GOOGLE_CONNECT_MODAL_OFF: 'INITIATE_GOOGLE_CONNECT_MODAL_OFF',
  SET_GOOGLE_CONNECT_MODAL_OFF: 'SET_GOOGLE_CONNECT_MODAL_OFF',   // To set boolean in local storage to show the google connect modal, when the google sign in token is broken.
  RESET_PASSWORD_INTENT: 'RESET_PASSWORD_INTENT',
  RESET_PASSWORD_INTENT_RESOLVED: 'RESET_PASSWORD_INTENT_RESOLVED',
  RESET_PASSWORD_INTENT_REJECTED: 'RESET_PASSWORD_INTENT_REJECTED',
  CONFIRM_PASSWORD_INTENT: 'CONFIRM_PASSWORD_INTENT',
  CONFIRM_PASSWORD_INTENT_RESOLVED: 'CONFIRM_PASSWORD_INTENT_RESOLVED',
  CONFIRM_PASSWORD_INTENT_REJECTED: 'CONFIRM_PASSWORD_INTENT_REJECTED',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  SET_USER_ROLES: 'SET_USER_ROLES',
  SET_USER_ROLES_RESOLVED: 'SET_USER_ROLES_RESOLVED',
  UPDATE_USER_INFO_INTENT: 'UPDATE_USER_INFO_INTENT',
  UPDATE_USER_INFO_INTENT_RESOLVED: 'UPDATE_USER_INFO_INTENT_RESOLVED',
  UPDATE_USER_INFO_INTENT_REJECTED: 'UPDATE_USER_INFO_INTENT_REJECTED',
  UPDATE_USER_PASSWORD_INTENT: 'UPDATE_USER_PASSWORD_INTENT',
  UPDATE_USER_PASSWORD_INTENT_RESOLVED: 'UPDATE_USER_PASSWORD_INTENT_RESOLVED',
  UPDATE_USER_PASSWORD_INTENT_REJECTED: 'UPDATE_USER_PASSWORD_INTENT_REJECTED',
  GET_PLAN_TOKEN_INTENT: 'GET_PLAN_TOKEN_INTENT',
  GET_PLAN_TOKEN_INTENT_RESOLVED: 'GET_PLAN_TOKEN_INTENT_RESOLVED',
  GET_PLAN_TOKEN_INTENT_REJECTED: 'GET_PLAN_TOKEN_INTENT_REJECTED',
}

export const actions = {
  /**
   * Generates an Logout Action
   */
  Logout() {
    return { type: types.LOGOUT_INTENT }
  },

  /**
   * Generates an Authentication Action
   * @param {string} email - Email to log into the application
   * @param {string} password - Password to log into the application
   */
  Authenticate(email, password, pageReferrer) {
    return {
      type: types.AUTHENTICATE_INTENT,
      data: { email, password, pageReferrer }
    }
  },

  /**
   * Generates an Authentication Resolved Action
   * @param {object} response - Fetch API response
   * @param {object} payload - Fetch API Payload encoded as JSON
   */
  AuthenticateResolved(payload) {
    return {
      type: types.AUTHENTICATE_INTENT_RESOLVED,
      payload,
      isAuthenticated: true
    }
  },

  /**
   * Generates an Authentication Rejected Action
   * @param {object} error - Fetch API response
   * @param {object} payload - Fetch API Payload encoded as JSON
   */
  AuthenticateRejected(error, payload) {
    return {
      type: types.AUTHENTICATE_INTENT_REJECTED,
      error,
      payload,
      isAuthenticated: false
    }
  },

  /**
   * update tokens
   */
  UpdateAuthInfo(data) {
    return {
      type: types.UPDATE_AUTH_INFO,
      data
    }
  },

  /**
   * update user resturant info
   */
  UpdateChainInfo(chains) {
    return {
      type: types.UPDATE_CHAIN_INFO,
      chain: chains[0]
    }
  },

  initiateGoogleConnectModalDisabling(restaurantUuid){
    return {
      type: types.INITIATE_GOOGLE_CONNECT_MODAL_OFF,
      restaurantUuid
    }
  },
  /**
   * Puts this restaurant Id in Google Connect modal off list 
   */
  putInModalOffList(restaurantUuid){
    return {
      type: types.SET_GOOGLE_CONNECT_MODAL_OFF,
      restaurantUuid
    }
  },

  changeToken() {
    return {
      type: types.CHANGE_TOKEN
    }
  },

  resetPassword(email) {
    return {
      type: types.RESET_PASSWORD_INTENT,
      data: { email }
    }
  },

  resetPasswordResolved() {
    return {
      type: types.RESET_PASSWORD_INTENT_RESOLVED
    }
  },

  resetPasswordRejected(errorCode) {
    return {
      type: types.RESET_PASSWORD_INTENT_REJECTED,
      errorCode
    }
  },

  confirmPassword(data) {
    return {
      type: types.CONFIRM_PASSWORD_INTENT,
      data
    }
  },

  confirmPasswordResolved() {
    return {
      type: types.CONFIRM_PASSWORD_INTENT_RESOLVED
    }
  },

  confirmPasswordRejected(errorCode) {
    return {
      type: types.CONFIRM_PASSWORD_INTENT_REJECTED,
      errorCode
    }
  },

  clearMessages() {
    return {
      type: types.CLEAR_MESSAGES
    }
  },

  setUserRoles(chain) {
    return {
      type: types.SET_USER_ROLES,
      chain
    }
  },

  setUserRolesResolved(updatedState) {
    return {
      type: types.SET_USER_ROLES_RESOLVED,
      updatedState
    }
  },

  updateUserInfo(data) {
    return {
      type: types.UPDATE_USER_INFO_INTENT,
      data
    }
  },

  updateUserInfoResolved(data) {
    return {
      type: types.UPDATE_USER_INFO_INTENT_RESOLVED,
      data
    }
  },

  updateUserInfoRejected(error) {
    return {
      type: types.UPDATE_USER_INFO_INTENT_RESOLVED,
      error
    }
  },

  updateUserPassword(data) {
    return {
      type: types.UPDATE_USER_PASSWORD_INTENT,
      data
    }
  },

  updateUserPasswordResolved(data) {
    return {
      type: types.UPDATE_USER_PASSWORD_INTENT_RESOLVED,
      data
    }
  },

  updateUserPasswordRejected(error) {
    return {
      type: types.UPDATE_USER_PASSWORD_INTENT_RESOLVED,
      error
    }
  },

  // User plans
  getPlanToken(chain) {
    return {
      type: types.GET_PLAN_TOKEN_INTENT,
      chain
    }
  },

  getPlanTokenResolved(tokenData) {
    return {
      type: types.GET_PLAN_TOKEN_INTENT_RESOLVED,
      data: tokenData
    }
  },

  getPlanTokenRejected(error) {
    return {
      type: types.GET_PLAN_TOKEN_INTENT_REJECTED,
      error
    }
  }
}
