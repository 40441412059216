import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

import './calendar.scss'
import styles from './index.module.scss'

export const viewTypes = {
  MONTH: 'dayGridMonth',
  WEEK: 'timeGridWeek',
  DAY: 'timeGridDay'
}

export default class Calendar extends React.Component {
  static propTypes = {
    events: PropTypes.array,
    view: PropTypes.oneOf(Object.values(viewTypes)),
    date: PropTypes.string,
    onDatesRender: PropTypes.func,
    onEventClick: PropTypes.func
  }

  static defaultProps = {
    view: viewTypes.MONTH,
    date: moment().format('YYYY-MM-DD')
  }

  constructor() {
    super()
    this.calendarRef = React.createRef()

    // bindings
    this.mapEvents = this.mapEvents.bind(this)
    this.eventRender = this.eventRender.bind(this)
    this.handleDatesRender = this.handleDatesRender.bind(this)
    this.handleEventClick = this.handleEventClick.bind(this)
  }

  mapEvents(info, cb) {
    cb(this.props.events.map(e => {
      const event = {
        ...e,
        classNames: [
          styles.eventSlot,
          e.className
        ]
      }
      return event
    }))
  }

  eventRender({ el, event }) {
    const body = event.extendedProps.body || ''
    const title = el.querySelector('.fc-title')
    if (title) {
      const content = `<span class="${styles.slotTitle}">${event.title}</span>${body}`
      title.innerHTML = content
    }
  }

  handleDatesRender(info) {
    const startDate = _.get(info, 'view.activeStart')
    const endDate = _.get(info, 'view.activeEnd')
    this.props.onDatesRender(
      moment(startDate).format('YYYY-MM-DDT00:00:00'),
      moment(endDate).subtract(1, 'day').format('YYYY-MM-DDT23:59:59'),
      info
    )
  }

  handleEventClick(info) {
    this.props.onEventClick(info)
  }

  render() {
    return (
      <FullCalendar
        className={ styles.calendar }
        defaultView={ this.props.view }
        defaultDate={ this.props.date }
        plugins={[ dayGridPlugin, timeGridPlugin ]}
        events={ this.mapEvents }
        eventRender={ this.eventRender }
        allDaySlot={ false }
        timeFormat='H:mm a'
        height='auto'
        header={{
          left: 'title prev, next',
          center: '',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        views={{
          week: {
            columnHeaderHtml: date => {
              const dayStr = moment(date).format('ddd')
              const dayNum = moment(date).format('D')
              return `
                ${dayStr}
                <div class="fc-week-daynumber">${dayNum}</div>
              `
            }
          }
        }}
        datesRender={ this.handleDatesRender }
        eventClick={ this.handleEventClick }
      />
    )
  }
}
