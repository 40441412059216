export const types = {
  ROUTE_CHANGE: 'ROUTE_CHANGE'
}

export const actions = {
  routeChange(path) {
    return {
      type: types.ROUTE_CHANGE,
      path
    }
  }
}
