import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { actions } from '../../../actions/Customer'
import StatelessModal from '../../StatelessModal' 

import RadioButton from '../../FormComponents/RadioButton'
// import { constructSelectedFilterTextInfo } from './util.js'
import  './styles.scss'

const ExportCustomer = (props) => {
  const { filters } = props
  const dispatch = useDispatch()
  const [exportModal, setExportModal] = useState(false)
  const [downloadSelectedData, setDownloadSelectedData] = useState(true)
  const isAdmin = useSelector(({ User }) => User.user.isAdmin)

  // If user is not admin, then we are not going to render anything related with export feature
  if(!isAdmin) {
    return null
  }
 
  const onExportModalOpen = () => {
    setExportModal(true)
  }

  const onExportModalClose = () => {
    setExportModal(false)
  }

  const renderCsvModal = () => {
    if(exportModal) {
      // const selectedFilterText = constructSelectedFilterTextInfo(selectedStore, filters)
      return (
        <StatelessModal
          onClose={ onExportModalClose }
          className='eg-export-madal'
          backGroundClassName='eg-export-background'>
          <div className='content'>
            <div className='title'>Export Customers</div>
            <RadioButton 
              selected={downloadSelectedData}
              onClick={() => setDownloadSelectedData(true)}
              label= 'Selected Data'
              className='radio-button'
            >
              {/* Export only data macthing the selected filters <br/>
              {selectedFilterText} */}
            </RadioButton>
            <RadioButton
              selected={!downloadSelectedData}
              onClick={() => setDownloadSelectedData(false)}
              label= 'All Data'
              className='radio-button'
            >
              {/* Export all available data all time */}
            </RadioButton>
            <div className='footer'>
              <div className='button' data-testid='export-csv-button' onClick={onExportClick}>EXPORT</div>
            </div>
          </div>
        </StatelessModal>
      )
    }
    return null
  }

  const onExportClick = () => {
    setExportModal(false)
    if(downloadSelectedData) {
      const opts = { filters }
      dispatch(actions.getExportCustomers(opts))
    } else {
      dispatch(actions.getExportCustomers({allData: true}))
    }
  } 

  return (
    <div className='eg-export-customer'>
      <div onClick={onExportModalOpen} className='button' data-testid='export-csv'> 
        <div className='logo' />EXPORT
      </div>
      {renderCsvModal()}
    </div>
  )
}

ExportCustomer.propTypes = {
  filters: PropTypes.any.isRequired,
}

export default ExportCustomer
