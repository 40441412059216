import React, {useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import StatelessModal from '../../StatelessModal'
import { scheduleTypes } from '../../../services/campaigns'
import { getFormattedDateWithTimeZone } from '../../../services/dates'
import './styles.scss'

/**
 * Success model content, when campaign is successfully scheduled.
 *
 * @component
 * @example
 *
 *
 * const handleClose = () => {
 * ... closes the success modal
 * }
 *
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return (
 *  <SuccessMessage onClose={ handleClose } data={data} />
 * )
*/
const SuccessMessage = props => {
  const { data = {}, onClose } = props
  const { recipients } = useSelector(state => state.Campaign)

  const handleModalClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    // Automatically close success modal after 2 seconds
    setTimeout(() => handleModalClose(), 2000)
  }, [handleModalClose])

  return (
    <StatelessModal className='eg-campaign-success-modal' onClose={ handleModalClose }>
      <div className='eg-campaign-success-message'>
        <div className='success-logo'>
        </div>
        <div className='title'>It's in the send queue!</div>
        <div className='sublabel'>{get(data,'campaign_name')}</div>
        <div className='content'>
          <div className='row'>
            <div className='title'>Scheduled delivery</div>
            <div className='value'>
              { scheduleTypes.INSTANT === data.type
                ? 'Starts in 10 minutes'
                : `${getFormattedDateWithTimeZone(data.schedule_date, get(data, 'timezone.timezone'), 'MMM DD, YYYY h:mm A')} ${get(data, 'timezone.label')}`}
            </div>
          </div>
          <div className='row'>
            <div className='title'>Segment</div>
            <div className='value'>{get(data,'campaign_segment.label')}</div>
          </div>
          <div className='row'>
            <div className='title'>Receipient count</div>
            <div className='value'>{recipients}</div>
          </div>
        </div>
      </div>
    </StatelessModal>
  )
}

SuccessMessage.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
}

export default SuccessMessage
