import React from 'react'
import PropTypes from 'prop-types'

import TabSelector from '../../../TabSelector'
import './styles.scss'

const AutomationCard = props => {
  const handleTabChange = tab => {
    if (props.onTabChange) {
      props.onTabChange(tab)
    }
  }

  return (
    <div className='eg-automation-card'>
      <div className='tabs-wrapper'>
        <TabSelector
          data-testid='eg-automation-selector'
          items={ props.tabItems }
          selectedItem={ props.selectedItem }
          onChange={ handleTabChange }
          className='tabs-container'
        />
      </div>
      { props.children }
    </div>
  )
}

AutomationCard.propTypes = {
  children: PropTypes.any,
  tabItems: PropTypes.array,
  selectedItem: PropTypes.object,
  onTabChange: PropTypes.func
}

export default AutomationCard
