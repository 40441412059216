import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class CustomerCard extends React.Component {
  static propTypes = {
    order: PropTypes.object.isRequired
  }

  render() {
    const customer = this.props.order.customer
    const partner = this.props.order.restaurant_partner
    return (
      <div className={ styles.container }>
        <div className={ styles.title }>Customer</div>
        <div className={ styles.customerSnippet }>
          <div>
            { customer.first_name } { customer.last_name }
            <span>Ordered via { partner.name ? partner.name : 'N/A'}</span>
          </div>
        </div>
        <div className={ styles.sectionTitle }>Email</div>
        <p>{ customer.email ? customer.email : 'N/A' }</p>
        <div className={ styles.phoneSection }>
          <div className={ styles.titleDark }>PHONE NUMBER</div>
          <p>{ customer.phone ? customer.phone : 'N/A' }</p>
        </div>
      </div>
    )
  }
}
