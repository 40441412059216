import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {
  useDispatch
} from 'react-redux'
import DynamicSegmentCreate from '../../../components/Segments/DynamicSegmentCreate'
import { actions } from '../../../actions/Segment'

const DynamicSegmentCreateContainer = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      // clear dynamicSegment state before the component unmounts
      dispatch(actions.clearDynamicSegment())
    }
  }, [dispatch])

  return <DynamicSegmentCreate history={ props.history } />
}

DynamicSegmentCreateContainer.propTypes = {
  history: PropTypes.object
}

export default DynamicSegmentCreateContainer
