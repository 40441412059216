import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Customer'
import { NetworkError } from '../services/error'

import get from 'lodash/get'

import { authorizedRequest } from './Auth'
import {
  getCustomers,
  getCustomerById,
  getCustomerCsvUrl,
  getCustomerInteractions
} from '../api/Customer'

import {
  mapCustomerIn,
  mapInteractionsIn
} from '../services/customers'

import { actions as spinnerActions } from '../actions/Spinner'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import { updateUrlQuery } from '../services/path'


const DEFAULT_ACTIVITY_SIZE = 10

/**
 * Get customer by id
 *
 * @param {object} payload
 *    - customerId
*/
function* getCustomerByIdHandler({ data }) {
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.uuid)
    const res = yield call(authorizedRequest, getCustomerById, {
      restaurantChainUuid,
      customerId: data.customerId,
    })
    if (res && res.data) {
      yield put(actions.getCustomerResolved(res.data))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getCustomerRejected())
  }
}

export function* getCustomersHandler({ opts={} }) {
  // if page number is in the options, then update the url.
  opts.page && updateUrlQuery({page: opts.page})
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const customerList = yield select(state => state.Customer.customerList)
    const { size, sort } = customerList
    opts = {size, sort, ...opts}
    const res = yield call(authorizedRequest, getCustomers, {
      ...opts,
      restaurantChainUuid,
      selectedStores
    })
    if (res && res.data) {
      const size = res.data.size || opts.size
      const total = res.data.total
      const lastPage = (size && total) && Math.ceil(total / size)
      const mappedCustomers = {
        customers: res.data.customers.map(customer => mapCustomerIn(customer)),
        page: res.data.page || opts.page,
        size,
        sort: opts.sort,
        filters: opts.filters,
        total,
        search: opts.search || '',
        lastPage
      }
      yield put(actions.getCustomersResolved(mappedCustomers))
    } else {
      throw new Error(res)
    }
  } catch(error) {
    yield put(actions.getCustomersRejected(error, {
      customers: [],
      page: 1,
      size: opts.size,
      sort: opts.sort,
      filters: opts.filters,
      total: 0,
      search: opts.search || ''
    }))
  }
}

/**
 * get signed url to download reviews in a csv file
*/
function* handleGetExportCustomers({ opts }) {
  yield put(spinnerActions.loading())
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const response = yield call(authorizedRequest, getCustomerCsvUrl, {
      restaurantChainUuid,
      selectedStores,
      ...opts
    })
    if(response && response.status === 200) {
      const url = get(response, 'data.url')
      window.open(url, '_blank')
    } else {
      throw new Error(response)
    }
  }
  catch (error) {
    // console.log('Failed to export csv file for customer.', error)
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: 'We are Sorry. Something went wrong. Could you please try again?'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
  }
  yield put(spinnerActions.loadingResolved())
}

function* getInteractionsHandler({ opts }) {
  if(!opts.page) opts.page =  1
  if(!opts.size) opts.size =  DEFAULT_ACTIVITY_SIZE
  if(opts.page === 1) {
    yield put(spinnerActions.loading())
  }
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const response = yield call(authorizedRequest, getCustomerInteractions, {
      restaurantChainUuid,
      ...opts
    })
    if (response && response.status === 200) {
      yield handleInteractionSuccessResponse(opts, response)
    } else {
      throw new NetworkError(response)
    }
  } catch (error) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: error.message
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getCustomerInteractionsRejected(error))
  }
  if(opts.page === 1) {
    yield put(spinnerActions.loadingResolved())
  }
}

/**
 * Handles responses
 * @param {*} opts 
 * @param {*} response 
 */
function * handleInteractionSuccessResponse(opts, response) {
  let rawActivites = yield select(({ Customer }) => Customer.customerInteractions.rawActivites)
  const page = opts.page 
  const size =  opts.size
  const total = response.data.total
  const isLastPage = Math.ceil(total/size) === page
  const customer = mapCustomerIn(response.data.customer)
  if( page === 1 ) {
  // Setting existing activities as empty array when it's first page request.
    rawActivites = []
  }
  const interactions = mapInteractionsIn([...rawActivites, ...response.data.activities], customer, isLastPage)
  yield put(actions.getCustomerInteractionsResolved({
    customer,
    interactions,
    rawActivites: [...rawActivites, ...response.data.activities],
    page,
    size,
    total
  }))
}

export default function* () {
  yield takeLatest(types.GET_CUSTOMERS_INTENT, getCustomersHandler)
  yield takeLatest(types.GET_CUSTOMER_INTENT, getCustomerByIdHandler)
  yield takeLatest(types.GET_EXPORT_CUSTOMERS_INTENT, handleGetExportCustomers)
  yield takeLatest(types.GET_CUSTOMER_INTERACTIONS_INTENT, getInteractionsHandler)
}
