import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export const DEFAULT_PLACEHOLDER = 'Search'

export default class Search extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    className: ''
  }

  constructor() {
    super()

    // bindings
    this.fieldChanged = this.fieldChanged.bind(this)
  }

  fieldChanged(e) {
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <div className={ `${styles.container} ${this.props.className}` }>
        <input
          data-testid='eg-search'
          type='text'
          className={ styles.input }
          placeholder={ this.props.placeholder || DEFAULT_PLACEHOLDER }
          value={ this.props.value }
          onChange={ this.fieldChanged }
        />
      </div>
    )
  }
}
