import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export const gcTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

export default class GoogleConnectButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    className: '',
    loading: false,
    loadingText: 'Connecting...',
    type: gcTypes.PRIMARY
  }

  constructor() {
    super()

    // bindings
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    if (this.props.onClick && !this.props.loading) {
      this.props.onClick(e)
    }
  }

  render() {
    const disabledClass = this.props.loading ? styles.disabled : ''
    return (
      <div
        className={ `${styles.container} ${styles[this.props.type]} ${this.props.className} ${disabledClass}` }
        onClick={ this.handleClick }
      >
        <div className={ styles.logo } />
        <div className={ styles.label }>
          { !this.props.loading ? this.props.text : this.props.loadingText }
        </div>
      </div>
    )
  }
}
