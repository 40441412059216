import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import FilterOptions from '../../../FilterOptions'

import './styles.scss'

const defaultActionLabel = 'All time'

const DateFilter = props => {
  const dateItems = [
    { label: 'Last 30 days', value: moment().subtract(30, 'days').utc().startOf('day').format() },
    { label: 'Last 3 months', value: moment().subtract(3, 'months').utc().startOf('day').format() },
    { label: 'Last 6 months', value: moment().subtract(6, 'months').utc().startOf('day').format() },
    { label: 'Last year', value: moment().subtract(1, 'year').utc().startOf('day').format() },
    { label: 'All time', value: null }
  ]

  const handleFilterChange = (item, cb) => {
    let actionLabel
    if (!item) {
      actionLabel = defaultActionLabel
    } else {
      const dateItem = dateItems.find(d => d.value === item)
      actionLabel = dateItem ? dateItem.label : defaultActionLabel
    }
    if (cb) {
      cb({ filterKeys: item ? [item] : [], actionLabel })
    }
  }

  const items = dateItems.map(item => ({
    key: item.value,
    content: () => {
      return (
        <div className='item'>
          { item.label }
        </div>
      )
    }
  }))

  return (
    <div className='eg-filter-date' >
      <FilterOptions
        actionLabel={ props.actionLabel }
        actionClass='action'
        actionActiveClass='active'
        onApply={ (items) => handleFilterChange(items, props.onApply) }
        items={ items }
        selectedItems={ props.filter.filterKeys }
      />
    </div>
  )
}

DateFilter.propTypes = {
  filter: PropTypes.object,
  actionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

DateFilter.defaultProps = {
  actionLabel: defaultActionLabel
}

export default DateFilter
