import React from 'react'
import PropTypes from 'prop-types'

import { secondsToHuman } from '../../../services/dates'

import './styles.scss'

const CallList = props => {
  const { calls, showHeaders = true } = props
  const totalItems = calls.items.length

  return (
    <div className='eg-call-list'>
      <div className='body'>
        {
          showHeaders && (
            <div className='headers'>
              <div className='cell -header'>Caller</div>
              <div className='cell -header'>Receiving line</div>
              <div className='cell -header'>Duration</div>
              <div className='cell -header'>Status</div>
              <div className='cell -header'>Date &amp; time</div>
            </div>
          )
        }
        <div className='list'>
          {
            calls.items.map((item, i) => {
              const isLast = i === totalItems - 1
              return (
                <React.Fragment key={ item.id }>
                  <div className={ `cell -body -state-${item.status} ${isLast && '-last'}` }>
                    <span>{ item.caller }</span> called
                  </div>
                  <div className={ `cell -body ${isLast && '-last'}` }>
                    via <span>{ item.receivingLine }</span>
                  </div>
                  <div className={ `cell -body ${isLast && '-last'}` }>
                    { parseInt(item.duration) ? secondsToHuman(item.duration) : '-' }
                  </div>
                  <div className={ `cell -body ${isLast && '-last'}` }>
                    <div className={ item.status }>{ item.statusLabel }</div>
                  </div>
                  <div className={ `cell -body ${isLast && '-last'}` }>
                    { item.date }
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

CallList.propTypes = {
  calls: PropTypes.object,
  showHeaders: PropTypes.bool
}

export default CallList
