import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import {actions as EgNotificationActions} from '../../../../actions/EgNotification'
import { types as EgNotificationTypes } from '../../../EgNotification/EgNotification'

import './styles.scss'

const isDateValid = (dateString) => {
  return moment(dateString, 'YYYY-MM-DD', true).isValid()
}

const isDateAfterToday = (dateString)  => {
  const today = new Date()
  const selectedDate = new Date(dateString)
  return today.getTime() < selectedDate.getTime()
}

/**
 * Converts a date string `1992-12-25' into a object
 * @param {String} dateInputString `1992-12-25'
 * @returns {Object} {day: 25, month: 12, year: 1992}
 */
const convertDateString = (dateInputString) => {
  const [year, month, day] = dateInputString.split('-').map((str) => parseInt(str) )
  return {year, month, day}
}

const PopupFooter = (props) => {
  const dispatch = useDispatch()
  const { selectedItem, onChange, fromInputRef, toInputRef } = props
  const from = _.get(selectedItem, 'range.from')
  const to = _.get(selectedItem, 'range.to')
  const fromDateString = from ? moment(new Date(`${from.year}/${from.month}/${from.day}`)).format('YYYY-MM-DD') : ''
  const toDateString = to ? moment(new Date(`${to.year}/${to.month}/${to.day}`)).format('YYYY-MM-DD') : ''

  const showWarningNotification = (message) => {
    dispatch(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.WARNING,
      message
    }))
  }

  const applySelectedDates = (data) => {
    if(data.from && data.to) {
      onChange(data)
    }
  }

  const fromDateChange = (event) => {
    let fromString = event.target.value
    if(isDateAfterToday(fromString)) {
      return showWarningNotification('You can not select a day after today.')
    }
    let from, to = null
    if(isDateValid(fromString)){
      from = convertDateString(fromString)
    }
    if(isDateValid(toDateString)) {
      to = convertDateString(toDateString)
    }
    const range = {from, to}
    applySelectedDates(range)
  }

  const toDateChange = (event) => {
    let toString = event.target.value
    if(isDateAfterToday(toString)) {
      return showWarningNotification('You can not select a day after today.')
    }
    let from, to = null
    if(isDateValid(fromDateString)){
      from = convertDateString(fromDateString)
    }
    if(isDateValid(toString)) {
      to = convertDateString(toString)
    }
    const range = {from, to}
    applySelectedDates(range)
  }

  return (
    <div className='eg-popup-footer'>
      <div>
        <label>START</label>
        <input className='date' data-testid='input-from' defaultValue={fromDateString} type='date' onChange={fromDateChange} ref={fromInputRef}/>
        {(fromDateString && !isDateValid(fromDateString)) && <div className='error'>
          Enter a valid date
        </div>}
      </div>
      <div>
        <label>END</label>
        <input className='date' data-testid='input-to' defaultValue={toDateString} type='date' onChange={toDateChange} ref={toInputRef}/>
        {(toDateString && !isDateValid(toDateString)) && <div className='error'>
          Enter a valid date
        </div>}
      </div>
    </div>
  )
}


PopupFooter.propTypes = {
  selectedItem: PropTypes.object,
  onChange: PropTypes.func,
  fromInputRef: PropTypes.object,
  toInputRef: PropTypes.object,
}

export default PopupFooter
