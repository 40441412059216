import { connect } from 'react-redux'
import ReviewList from './ReviewList'
import { actions as reviewActions } from '../../actions/Review'
import { actions as storeActions } from '../../actions/Store'
import { actions as filterActions } from '../../actions/Filter'

const mapStateToProps = state => ({
  restaurant: state.User.chain,
  reviewsLoading: state.Review.reviewsLoading,
  reviewList: state.Review.reviewList,
  lastUsedChainUuid: state.Store.lastUsedChainUuid,
  selectedStores: state.Filter.selectedStores,
})

const mapDispatchToProps = dispatch => ({
  getReviews(opts) {
    dispatch(reviewActions.getReviews(opts))
  },
  
  getStoreList() {
    dispatch(storeActions.getStoreList())
  },

  clearStoreFilter() {
    dispatch(filterActions.setStoreFilter([]))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList)
