import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import FilterOptions from '../../FilterOptions'

import './styles.scss'

const defaultActionLabel = 'All Time'

const timeAgo = (qty, type) => {
  return moment().subtract(qty, type).utc().startOf('day').format()
}

const DateFilter = props => {
  const [ actionLabel, setActionLabel ] = useState(props.actionLabel)
  const dateItems = [
    { label: 'Last 30 Days', value: timeAgo(30, 'days'), key: 'last_30d' },
    { label: 'Last 3 Months', value: timeAgo(3, 'months'), key: 'last_3m' },
    { label: 'Last 6 Months', value: timeAgo(6, 'months'), key: 'last_6m' },
    { label: 'Last Year', value: timeAgo(1, 'year'), key: 'last_1y' },
    { label: 'All Time', value: null }
  ]
  const { className = '' } = props

  const handleFilterChange = (itemValue) => {
    const item = dateItems.find(i => i.value === itemValue)
    setActionLabel(item.label)
    if (props.onApply && itemValue) {
      props.onApply({
        date_filter: item
      })
    } else if (props.onClear && !itemValue) {
      props.onClear({
        filterType: 'date_filter'
      })
    }
  }

  const items = dateItems.map(item => ({
    key: item.value,
    content: () => {
      return (
        <div className='item'>
          { item.label }
        </div>
      )
    }
  }))

  return (
    <div className={ `eg-date-filter ${className}` } data-testid='eg-date-filter'>
      <FilterOptions
        actionLabel={ actionLabel }
        actionClass='action'
        actionActiveClass='active'
        onApply={ handleFilterChange }
        items={ items }
        selectedItems={ props.selectedItem ? [ props.selectedItem ] : [] }
      />
    </div>
  )
}

DateFilter.propTypes = {
  actionLabel: PropTypes.string,
  selectedItem: PropTypes.object,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

DateFilter.defaultProps = {
  actionLabel: defaultActionLabel
}

export default DateFilter

