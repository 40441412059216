import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '../../../../FormComponents/TextField'
import Button, { types as buttonTypes } from '../../../../FormComponents/Button'
import FormValidator from '../../../../../validators/FormValidator'
import {
  url as urlValidator
} from '../../../../../validators/customValidations'
import { linkTypes, linkSections } from '../../../../../services/stores'

import styles from './index.module.scss'

const {
  WEBSITE,
  RESERVATION_URL,
  MENU_URL,
  ORDER_ONLINE_URL
} = linkTypes

let validation
const validator = new FormValidator([
  ...urlValidator(WEBSITE, false),
  ...urlValidator(RESERVATION_URL, false),
  ...urlValidator(MENU_URL, false),
  ...urlValidator(ORDER_ONLINE_URL, false)
])

const Links = props => {
  const store = props.store
  const links = store && store.links
  const websiteLink = links && links.find(link => link.subtype === linkTypes.WEBSITE)
  const reservationLink = links && links.find(link => link.subtype === linkTypes.RESERVATION_URL)
  const menuLink = links && links.find(link => link.subtype === linkTypes.MENU_URL)
  const orderLink = links && links.find(link => link.subtype === linkTypes.ORDER_ONLINE_URL)

  const [ formFields, setFormFields ] = useState(() => {
    return {
      website: { value: websiteLink ? websiteLink.url : '', dirty: false },
      reservation_url: { value: reservationLink ? reservationLink.url : '', dirty: false },
      menu_url: { value: menuLink ? menuLink.url : '', dirty: false },
      order_online_url: {value: orderLink ? orderLink.url : '', dirty: false}
    }
  })

  const getFieldError = (fieldName) => {
    if (formFields[fieldName].dirty && !validation[fieldName].isValid) {
      return validation[fieldName].message
    }
    return ''
  }

  const handleInputChange = (event) => {
    event.persist()
    setFormFields(prevState => {
      if (!event.target) {
        return prevState
      }
      return {
        ...prevState,
        [event.target.name]: {
          value: event.target.value,
          dirty: true
        }
      }
    })
  }

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleSaveClick = () => {
    if (props.onSave) {
      const payload = []
      Object.keys(formFields).forEach(key => {
        payload.push({
          subtype: key,
          type: linkSections.WEBSITE_LINK,
          url: formFields[key].value
        })
      })
      props.onSave(payload)
    }
  }

  validation = validator.validate(formFields)

  return (
    <div className={ styles.linksContainer }>
      <h3>Website and links</h3>
      <p>Add links to your website, online delivery, reservation, and catering.</p>
      <div className={ styles.linksFormContainer }>
        <div className={ styles.linksFormRow }>
          <span>Website</span>
          <TextField
            name={ WEBSITE }
            label=''
            placeholder='https://'
            value={ formFields[WEBSITE].value }
            className={ styles.inputField }
            errorMessage={ getFieldError(WEBSITE) }
            error={!validation[WEBSITE].isValid && formFields[WEBSITE].dirty}
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.linksFormRow }>
          <span>Reservation URL</span>
          <TextField
            name={ RESERVATION_URL }
            label=''
            placeholder='https://'
            value={ formFields[RESERVATION_URL].value }
            className={ styles.inputField }
            errorMessage={ getFieldError(RESERVATION_URL) }
            error={!validation[RESERVATION_URL].isValid && formFields[RESERVATION_URL].dirty}
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.linksFormRow }>
          <span>Menu URL</span>
          <TextField
            name={ MENU_URL }
            label=''
            placeholder='https://'
            value={ formFields[MENU_URL].value }
            className={ styles.inputField }
            errorMessage={ getFieldError(MENU_URL) }
            error={!validation[MENU_URL].isValid && formFields[MENU_URL].dirty}
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.linksFormRow }>
          <span>Order online URL</span>
          <TextField
            name={ ORDER_ONLINE_URL }
            label=''
            placeholder='https://'
            value={ formFields[ORDER_ONLINE_URL].value }
            className={ styles.inputField }
            errorMessage={ getFieldError(ORDER_ONLINE_URL) }
            error={!validation[ORDER_ONLINE_URL].isValid && formFields[ORDER_ONLINE_URL].dirty}
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.actionsWrapper }>
          <Button
            text='CANCEL'
            type={ buttonTypes.SECONDARY }
            onClick={ handleCancelClick }
          />
          <Button
            text='SAVE'
            type={ buttonTypes.PRIMARY }
            disabled={ !validation.isValid }
            onClick={ handleSaveClick }
          />
        </div>
      </div>
    </div>
  )
}

Links.propTypes = {
  store: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default Links
