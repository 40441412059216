export const types = {
  GET_CUSTOMERS_INTENT: 'GET_CUSTOMERS_INTENT',
  GET_CUSTOMERS_INTENT_RESOLVED: 'GET_CUSTOMERS_INTENT_RESOLVED',
  GET_CUSTOMERS_INTENT_REJECTED: 'GET_CUSTOMERS_INTENT_REJECTED',

  GET_CUSTOMER_INTENT: 'GET_CUSTOMER_INTENT',
  GET_CUSTOMER_INTENT_RESOLVED: 'GET_CUSTOMER_INTENT_RESOLVED',
  GET_CUSTOMER_INTENT_REJECTED: 'GET_CUSTOMER_INTENT_REJECTED',

  GET_ORDER_SUMMARY_INTENT: 'GET_ORDER_SUMMARY_INTENT',
  GET_ORDER_SUMMARY_INTENT_RESOLVED:  'GET_ORDER_SUMMARY_INTENT_RESOLVED',
  GET_ORDER_SUMMARY_INTENT_REJECTED: 'GET_ORDER_SUMMARY_INTENT_REJECTED', 
  CLEAR_CUSTOMERS: 'CLEAR_CUSTOMERS',

  GET_EXPORT_CUSTOMERS_INTENT: 'GET_EXPORT_CUSTOMERS_INTENT',

  GET_CUSTOMER_INTERACTIONS_INTENT: 'GET_CUSTOMER_INTERACTIONS_INTENT',
  GET_CUSTOMER_INTERACTIONS_INTENT_RESOLVED: 'GET_CUSTOMER_INTERACTIONS_INTENT_RESOLVED',
  GET_CUSTOMER_INTERACTIONS_INTENT_REJECTED: 'GET_CUSTOMER_INTERACTIONS_INTENT_REJECTED',
}

export const actions = {
  getCustomer(customerId) {
    return {
      type: types.GET_CUSTOMER_INTENT,
      data: { customerId }
    }
  },

  getCustomerResolved(data) {
    return {
      type: types.GET_CUSTOMER_INTENT_RESOLVED,
      data
    }
  },

  getCustomerRejected() {
    return {
      type: types.GET_CUSTOMER_INTENT_REJECTED
    }
  },

  getCustomers(opts) {
    return {
      type: types.GET_CUSTOMERS_INTENT,
      opts
    }
  },

  getCustomersResolved(data) {
    return {
      type: types.GET_CUSTOMERS_INTENT_RESOLVED,
      data
    }
  },

  getCustomersRejected(error, data) {
    return {
      type: types.GET_CUSTOMERS_INTENT_REJECTED,
      error,
      data
    }
  },

  clearCustomers() {
    return {
      type: types.CLEAR_CUSTOMERS
    }
  },

  getExportCustomers(opts) {
    return {
      type: types.GET_EXPORT_CUSTOMERS_INTENT,
      opts
    }
  },

  getCustomerInteractions(opts) {
    return {
      type: types.GET_CUSTOMER_INTERACTIONS_INTENT,
      opts
    }
  },

  getCustomerInteractionsResolved(data) {
    return {
      type: types.GET_CUSTOMER_INTERACTIONS_INTENT_RESOLVED,
      data
    }
  },

  getCustomerInteractionsRejected(error) {
    return {
      type: types.GET_CUSTOMER_INTERACTIONS_INTENT_REJECTED,
      error
    }
  }
}
