import React from 'react'
import PropTypes from 'prop-types'

import {  Input, TextArea } from '../../../../FormComponents'
import { renderInput } from '../../../../FormComponents/utils'
import PhonePreview, { HeaderSMS } from '../../../../PhonePreview'
import './styles.scss'


export const fields = {
  SENDER_NAME: 'sender_name',
  SMS_TEXT: 'message',
  RATING_LINK: 'url'
}

const AutomationSms = props => {
  const { validator, store, visible, values } = props
  const { register, errors } = validator
  const linkPlaceholder = `https://rebrand.ly/${store.storeInfo.name.replaceAll(' ', '-')}?feedback=true`

  return (
    <div className={ `eg-automation-sms ${!visible ? 'hidden' : ''}` }>
      <div className='form-wrapper'>
        <div className='title'>SMS</div>
        <div className='form'>
          <div className='field'>
            <label className='label' htmlFor={ fields.SENDER_NAME }>Sender's name</label>
            {
              renderInput(fields.SENDER_NAME, validator, { required: false }, 'Enter Sender\'s name (Optional)')
            }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.SMS_TEXT }>SMS text</label>
            <TextArea
              id={ fields.SMS_TEXT }
              name={ fields.SMS_TEXT }
              className={ `textarea ${errors[fields.SMS_TEXT] ? 'error' : ''}` }
              forwardedRef={ register({ required: true }) }
            />
            { errors[fields.SMS_TEXT] && <div className='error-msg'>{ errors[fields.SMS_TEXT].message }</div> }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.RATING_LINK }>Link to rating page</label>
            <Input
              id={ fields.RATING_LINK }
              name={ fields.RATING_LINK }
              placeholder= {linkPlaceholder}
              className={ `${errors[fields.RATING_LINK] ? 'error' : ''}` }
              ref={ register({ required: true }) }
            />
            { errors[fields.RATING_LINK] && <div className='error-msg'>{ errors[fields.RATING_LINK].message }</div> }
            <div className='link-note'>
            NOTE: Changing this may cause redirect issues to the rating page so make sure to input a correct and valid link before publishing
            </div>
          </div>
        </div>
      </div>
      <div className='preview'>
        <div className='title'>PREVIEW</div>
        <PhonePreview className='phone'>
          <HeaderSMS title={ values[fields.SENDER_NAME] || '<Sender name here>' } />
          <div className='iphone-font-mini'>
            Text message<br />
            Thursday 11:45 AM
          </div>
          <div className='message-wrapper' data-testid='sms-preview-message-wrapper'>
            <p>
              { values[fields.SMS_TEXT] || '<SMS message here>' }
            </p>
            <p className='link'>
              { values[fields.RATING_LINK] || '<Rating link here>' }
            </p>
            <p>
              Powered by ZeroStorefront<br />
              Reply STOP to opt-out
            </p>
          </div>
        </PhonePreview>
      </div>
    </div>
  )
}

AutomationSms.propTypes = {
  visible: PropTypes.bool.isRequired,
  validator: PropTypes.object,
  store: PropTypes.object,
  values: PropTypes.object,
}

export default AutomationSms
