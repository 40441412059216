import React, { useState } from 'react'
import PropTypes from 'prop-types'

import StatelessModal from '../../../../StatelessModal'
import ImageSlider from '../../../../ImageSlider'
import ImageCollage from './ImageCollage'

import styles from './info.module.scss'

const Info = props => {
  const store = props.store
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(null)
  
  let images = store.imageLinks || []

  const Modal = () => {
    // If no images has been selected/clicked
    // We won't show the slider modal.
    if(selectedImageIndex === null) return null
    return  (
      <StatelessModal
        className={ styles.modal }
        closeStyle={styles.closeStyle}
        onClose={ () => setSelectedImageIndex(null) }>
        <ImageSlider
          selectedIndex={selectedImageIndex}
          imageArray={store.imageLinks}
          withThumbnail={true}
        />
      </StatelessModal>
    )
  }

  return (
    <div className={styles.container}>
      <h3>Photos</h3>
      <ImageCollage
        images={images}
        onImageClick={setSelectedImageIndex}
      />
      <Modal />
    </div>
  )
}

Info.propTypes = {
  store: PropTypes.object,
  onEditClick: PropTypes.func
}

export default Info
