export const types = {
  DASHBOARD_GET_CHAIN_INFO: 'DASHBOARD_GET_CHAIN_INFO',
  DASHBOARD_GET_CHAIN_INFO_RESOLVED: 'DASHBOARD_GET_CHAIN_INFO_RESOLVED',
  DASHBOARD_GET_CHAIN_INFO_REJECTED: 'DASHBOARD_GET_CHAIN_INFO_REJECTED',

  DASHBOARD_GET_CARD_INFO: 'DASHBOARD_GET_CARD_INFO',
  DASHBOARD_GET_CARD_INFO_RESOLVED: 'DASHBOARD_GET_CARD_INFO_RESOLVED',
  DASHBOARD_GET_CARD_INFO_REJECTED: 'DASHBOARD_GET_CARD_INFO_REJECTED',
}

export const actions = {
  getChainInfo(data) {
    return {
      type: types.DASHBOARD_GET_CHAIN_INFO,
      data
    }
  },

  getChainInfoResolved(data) {
    return {
      type: types.DASHBOARD_GET_CHAIN_INFO_RESOLVED,
      data
    }
  },

  getChainInfoRejected(error) {
    return {
      type: types.DASHBOARD_GET_CHAIN_INFO_REJECTED,
      error
    }
  },

  getCardInfo(data) {
    return {
      type: types.DASHBOARD_GET_CARD_INFO,
      data
    }
  },

  getCardInfoResolved(data) {
    return {
      type: types.DASHBOARD_GET_CARD_INFO_RESOLVED,
      data
    }
  },

  getCardInfoRejected(error) {
    return {
      type: types.DASHBOARD_GET_CARD_INFO_REJECTED,
      error
    }
  }
}
