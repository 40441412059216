import React, { useState } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { toCurrency } from '../../../../utils/number'
import { channelTypes } from '../../../../services/channels'
import { getFormattedDate } from '../../../../services/dates'
import { PATHS } from '../../../../constants'
// import ChannelHover from './ChannelHover'
import './styles.scss'

const channelTypeColors = {
  [channelTypes.EMAIL] : '#FABE4A',
  [channelTypes.MESSAGING] : '#53CC8E',
  [channelTypes.RESERVATIONS] : '#1ACBF1',
  [channelTypes.SEARCH] : '#6062FA',
  [channelTypes.SOCIAL] : '#3AB5AB',
  [channelTypes.IN_STORE] : '#2BBFB3',
  [channelTypes.UNKNOWN] : '#A4ADB6',
}

const getClassname = channel => {
  return channel.source
}

const isSubChannelClickable = (type, subChannel) => {
  return (type === channelTypes.MESSAGING && subChannel.sourceId) || subChannel.link
}

const ChannelSection = props => {
  const { channels, type, history, isExpandable = true } = props
  const [ selectedChannel, setSelectedChannel ] = useState(null)
  const [showChannels, setShowChannels] = useState(false)

  const sectionTotals = channels.length > 0? {
    spend: _.sumBy(channels, 'spend'),
    income: _.sumBy(channels, 'income'),
    avg_ticket: _.sumBy(channels, 'avg_ticket') / channels.length,
    roi: _.sumBy(channels, 'roi') / channels.length
  } : {
    spend: 0,
    income: 0,
    avg_ticket: 0,
    roi: 0
  }

  const isSelected = channel => {
    if (!channel || !selectedChannel) return false
    // NOTE: check if we receive an id instead
    return selectedChannel.source === channel.source
  }

  const handleClick = (channel) => {
    if (channel.subchannels && channel.subchannels.length) {
      setSelectedChannel(isSelected(channel) ? null : channel)
    }
  }

  const onToggleChannel = () => {
    if(isExpandable) {
      setShowChannels(!showChannels)
    }
  }

  const  handleSubchannelClick = (event, subChannel) => {
    event && event.stopPropagation()
    // if subChannel not clickable return from here
    if(!isSubChannelClickable(type, subChannel)) return

    if(type === channelTypes.MESSAGING && subChannel.sourceId) {
      history.push(`${PATHS.SMS_CAMPAIGN}/${subChannel.sourceId}`)
    } else if(subChannel.link) {
      const win = window.open(subChannel.link, '_blank')
      win.focus()
    }
  }

  const hasChannels = channels && channels.length
  return (
    <div
      className={`eg-channel-section ${hasChannels ? '' : 'fade-channel-section'}`}
      data-testid={`${type}-channels`}
      style={{borderLeft: `4px solid ${channelTypeColors[type]}`}}
    >
      <div className='summary' onClick={onToggleChannel} data-testid={`${type}-channels-summary`}  >
        <div className='title'>
          <span className='type-color' style={{backgroundColor: channelTypeColors[type]}}></span>
          { type }
        </div>
        <div className='spend mobile-label'>SPEND</div>
        <div className='income mobile-label'>INCOME</div>
        <div className='avg-ticket mobile-label'>AVG. TICKET</div>
        <div className='roi mobile-label'>ROI</div>
        <div className='spend' data-testid='spend'>{ toCurrency(sectionTotals.spend) }</div>
        <div className='income'>{ toCurrency(sectionTotals.income) }</div>
        <div className='avg-ticket'>{ toCurrency(sectionTotals.avg_ticket) }</div>
        <div className='roi'>{sectionTotals.roi ? `${sectionTotals.roi.toFixed(2)}x` : '-'}</div>
      </div>
      { showChannels &&
        channels.map((channel, i) => {
          const hasSubchannels = channel.subchannels && channel.subchannels.length > 0
          const arrowDisplayClass = hasSubchannels ? 'has-children' : ''
          const selectedClass = isSelected(channel) ? 'selected' : ''

          return (
            <div className='row' key={ `channel-row-${i}` } data-testid={ `channel-row-${channel.source}` } onClick={ () => handleClick(channel) } >
              <div className='channel'>
                {/* {
                  !selectedChannel && <ChannelHover channel={channel} />
                } */}
                <div className='title'>
                  <div className={ `arrow ${arrowDisplayClass} ${selectedClass}` }></div>
                  <div className={ `name ${getClassname(channel)}` }>
                    { channel.name || channel.source }
                  </div>
                </div>
                <div className='spend mobile-label'>SPEND</div>
                <div className='income mobile-label'>INCOME</div>
                <div className='avg-ticket mobile-label'>AVG. TICKET</div>
                <div className='roi mobile-label'>ROI</div>
                <div className='spend'>{ toCurrency(channel.spend) }</div>
                <div className='income'>{ toCurrency(channel.income) }</div>
                <div className='avg-ticket'>{ toCurrency(channel.avg_ticket) }</div>
                <div className='roi' data-testid='overview-channel-item-roi'>{channel.roi ? `${channel.roi.toFixed(2)}x` : '-'}</div>
              </div>
              {
                hasSubchannels && isSelected(channel) && (
                  <div className='subchannels' >
                    <div className='left-edge'></div>
                    <div className='items'>
                      {
                        channel.subchannels.map((subchannel, i) => {
                          const isClickable = isSubChannelClickable(type, subchannel)
                          const className = isClickable ? 'sub-channel clickable' : 'sub-channel'

                          return (
                            <div key={i} className={className} onClick={(event) => handleSubchannelClick(event, subchannel)}>
                              <div className='border-top' />
                              <div className='item' key={ `subchannel-item-${i}` } data-testid='subchannel-item' >
                                <div className='title'>
                                  { subchannel.name || subchannel.source }
                                  <div className='channel-item-date'>{ getFormattedDate(subchannel.date, false) }</div>
                                </div>
                                <div className='spend mobile-label'>SPEND</div>
                                <div className='income mobile-label'>INCOME</div>
                                <div className='avg-ticket mobile-label'>AVG. TICKET</div>
                                <div className='roi mobile-label'>ROI</div>
                                <div className='spend'>
                                  { toCurrency(subchannel.spend) }
                                </div>
                                <div className='income'>
                                  { toCurrency(subchannel.income) }
                                </div>
                                <div className='avg-ticket'>
                                  { toCurrency(subchannel.avg_ticket) }
                                </div>
                                <div className='roi'>
                                  {subchannel.roi ? `${subchannel.roi.toFixed(2)}x` : '-'}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

ChannelSection.propTypes = {
  channels: PropTypes.array,
  isExpandable: PropTypes.bool,
  type: PropTypes.string,
  history: PropTypes.object,
}

export default withRouter(ChannelSection)
