import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import LocalSpinner from '../../../LocalSpinner'
import Button, { types as buttonTypes } from '../../../FormComponents/Button'
import { actions } from '../../../../actions/Campaign'
import { parsePreview } from '../../../../services/campaigns'
import { toTitleCase } from '../../../../services/string'
import Schedule from '../../Schedule'
import SuccessMessage from '../../SuccessMessage'
import TestMessage from '../../TestMessage'
import EGAnalytic, { analyticEvents } from '../../../../services/analytics'
import { PATHS } from '../../../../constants'
import { fields as setUpFields } from '../Setup'
import { hasNonShortenedLink } from '../../../../services/sms'
import './styles.scss'

/**
 * ReviewCampaign - Step 3 of create SMS campaign flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return (
 *  <ReviewCampaign
 *    data={ data }
 *    onEditMessageClick={ () => switchToMessageStep() }
 *    onEditRecipientsClick={ () => switchToRecipientsStep() }
 *    onSubmit={ handleSubmit }
 *  />
 * )
*/
const ReviewCampaign = props => {
  const {
    campaignId,
    history,
    onEditMessageClick,
    onEditRecipientsClick,
    onSubmit
  } = props
  const [data, setData] = useState(props.data)
  const {
    loadingEstimatedRecipients,
    recipients,
    submittingTestMsg,
    testMsgSubmitted: testMsgSent,
  } = useSelector(state => state.Campaign)
  const submitting = useSelector(state => state.Campaign.campaignUpdate.submitting || state.Campaign.campaignSchedule.submitting)
  const { error: scheduleError } = useSelector(state => state.Campaign.campaignSchedule)
  const { error: updateError } = useSelector(state => state.Campaign.campaignUpdate)
  const chain = useSelector(state => state.User.chain)
  const dispatch = useDispatch()
  const [showScheduler, setShowScheduler] = useState(false)
  const [dataSubmitted, setDataSubmitted] = useState(false)
  const [showSuccessComponent, setShowSuccessComponent] = useState(false)
  const [displayTestSmsModal, setDisplayTestSmsModal] = useState(false)
  const [testMsgSubmitted, setTestMsgSubmitted] = useState(false)
  const [scheduleData, setScheduleData] = useState(null)

  useEffect(() => {
    setData(props.data)
  }, [dispatch, props.data])

  useEffect(() => {
    if (!testMsgSent) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_WARNING_DISPLAYED, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Campaigns',
        messageContextStep: 'Review',
      })
    }
  }, [chain, testMsgSent])

  useEffect(() => {
    if (testMsgSubmitted && !submittingTestMsg) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_SENT, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Campaigns',
        messageContextStep: 'Review',
      })
      setDisplayTestSmsModal(false)
    }
  }, [chain, submittingTestMsg, testMsgSubmitted])

  useEffect(() => {
    if (dataSubmitted && !submitting && !scheduleError && !updateError) {
      setShowScheduler(false)
      setShowSuccessComponent(true)
      EGAnalytic.track(analyticEvents.CAMPAIGN_CREATION_COMPLETED, {
        ...EGAnalytic.mapChain(chain),
        campaignType: toTitleCase(_.get(scheduleData,'type')),
        campaignName: data[setUpFields.NAME],
        segmentName: data[setUpFields.SEGMENT].label,
        stepName: 'Review',
        campaignScheduleTime: data.schedule_date,
        campaignScheduleTimezone: data.timezone ? data.timezone.label : '',
      })
    } else if (scheduleError || updateError) {
      setDataSubmitted(false)
    }
  }, [submitting, chain, data, dataSubmitted, scheduleError, updateError, scheduleData, dispatch])

  useEffect(() => {
    dispatch(actions.getEstimatedRecipients(campaignId))
  }, [dispatch, campaignId])

  const handleEditRecipientsClick = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review',
      stepAbandonType: 'EDIT RECIPIENTS'
    })
    if (onEditRecipientsClick) onEditRecipientsClick()
  }

  const handleEditMessageClick = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review',
      stepAbandonType: 'EDIT MESSAGE'
    })
    if (onEditMessageClick) onEditMessageClick()
  }

  const handleBackClick = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review',
      stepAbandonType: 'Back'
    })
    if (onEditMessageClick) onEditMessageClick()
  }

  const handleTestMessageClick = () => {
    EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Campaigns',
      messageContextStep: 'Review',
    })
    setDisplayTestSmsModal(true)
  }

  const handleTestMessageClose = () => {
    setDisplayTestSmsModal(false)
  }

  const handleTestMessageSubmit = numbers => {
    dispatch(actions.sendTestMessage({
      numbers,
      message: data.message,
      imageUrl: _.get(data, 'mms.imageUrl')
    }))
    setTestMsgSubmitted(true)
  }

  const handleSchedule = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_COMPLETED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review'
    })
    EGAnalytic.track(analyticEvents.CAMPAIGN_SCHEDULE_MODAL_DISPLAYED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review',
    })
    setShowScheduler(true)
  }

  const handleSuccessClose = () => {
    setShowSuccessComponent(false)
    history.push(`${PATHS.SMS_CAMPAIGNS}?tab=scheduled&refresh=1`)
  }

  const handleSubmit = (data) => {
    setScheduleData(data)
    if (onSubmit) {
      onSubmit('review', data)
    }
    setDataSubmitted(true)
  }

  const handleScheduleCancel = () => {
    setShowScheduler(false)
    EGAnalytic.track(analyticEvents.CAMPAIGN_SCHEDULE_MODAL_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Review',
    })
  }

  const renderWarningMessage = () => {
    if (testMsgSent) return
    return (
      <div className='alert'>
        We recommend testing your message before sending by clicking the 'Send test message'
        button inside the message preview below.
      </div>
    )
  }

  const renderUrlWarning = () => {
    if (hasNonShortenedLink(data.message, [data.shortlink])) {
      return (
        <div className='alert'>
          One or more of the links in your message can't be tracked.
          Edit your design to add them as a short link.
        </div>
      )
    }
  }

  return (
    <div className='eg-create-campaign-review'>
      {
        showScheduler && (
          <Schedule
            submitting={ submitting }
            error={ updateError }
            onSubmit={ handleSubmit }
            onCancel={ handleScheduleCancel }
            data={ data }
          />
        )
      }
      {
        showSuccessComponent && (
          // Schedule data to pass type and timezone, we don't store it on backend
          <SuccessMessage onClose={handleSuccessClose} data={{...data, ...scheduleData}} />
        )
      }
      <div className='step-body'>
        <div className='title'>Review your campaign before sending</div>
        <div className='subtitle'>Please check your campaign details</div>
        {renderUrlWarning()}
        {renderWarningMessage()}
        <div className='recipients'>
          {
            !loadingEstimatedRecipients ? (
              <>
                <div className='details'>
                  <div className='title'>Estimated recipients</div>
                  <div className='total'>{!!recipients && recipients.toLocaleString()}</div>
                  <div className='notes'>
                    This campaign will be sent to <strong>{_.get(data, 'campaign_segment.label')}</strong>
                  </div>
                </div>
                <div className='action'>
                  <Button
                    className='btn-edit'
                    type={buttonTypes.SECONDARY}
                    text='EDIT RECIPIENTS'
                    onClick={handleEditRecipientsClick}
                  />
                </div>
              </>
            ) : (
              <div className='loader'>
                <LocalSpinner />
              </div>
            )
          }
        </div>
        <div className='review'>
          <div className='details'>
            <div className='title'>Message</div>
            <div className='msg-preview'>
              <div
                className='message'
                dangerouslySetInnerHTML={{ __html: parsePreview(data.message, chain) }}
              />
              <div className='test-msg-action'>
                <Button
                  className='preview-test-btn'
                  type={buttonTypes.SECONDARY}
                  text='SEND TEST MESSAGE'
                  onClick={handleTestMessageClick}
                />
              </div>
            </div>
          </div>
          <div className='action'>
            <Button
              className='btn-edit'
              type={buttonTypes.SECONDARY}
              text='EDIT MESSAGE'
              onClick={handleEditMessageClick}
            />
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button
          className='action-button'
          text='BACK'
          type={buttonTypes.SECONDARY}
          onClick={handleBackClick}
        />
        <Button
          className='action-button submit'
          text='SCHEDULE OR SEND NOW'
          onClick={handleSchedule}
        />
      </div>
      {
        displayTestSmsModal && (
          <TestMessage
            step='Review'
            flowName='Campaigns'
            submitting={submittingTestMsg}
            onClose={handleTestMessageClose}
            onSubmit={handleTestMessageSubmit}
          />
        )
      }
    </div>
  )
}

ReviewCampaign.propTypes = {
  data: PropTypes.object,
  campaignId: PropTypes.string,
  history: PropTypes.object,
  onEditRecipientsClick: PropTypes.func,
  onEditMessageClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default withRouter(ReviewCampaign)
