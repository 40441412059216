import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FilterOptions from '../../../FilterOptions'
import FilterContent from './FilterContent'
import { filterTypes } from '../../../../services/customers'

import { capitalize } from '../../../../utils/strings'
import './styles.scss'

const defaultActionLabel = 'Total Interactions'

const TotalInteractionsFilter = props => {
  const [ actionLabel, setActionLabel ] = useState(defaultActionLabel)
  const [ selectedItem, setSelectedItem ] = useState(null)
  const [ filterOptions, setFilterOptions ] = useState([
    { key: 'gt', label: 'greater than', value: '' },
    { key: 'eq', label: 'exactly', value: '' },
    { key: 'lt', label: 'less than', value: '' }
  ])

  const handleInputChange = (item, value) => {
    const opts = [ ...filterOptions ]
    const selected = opts.find(opt => opt.label === item.label)
    selected.value = value
    setFilterOptions(opts)
  }

  const handleSelect = item => {
    setSelectedItem(item)
  }

  const handleApply = () => {
    const { label, value } = selectedItem
    const multi = !!value && value > 1
    setActionLabel(`${capitalize(label)} ${value} interaction${multi ? 's' : ''}`)
    if (props.onApply) {
      props.onApply({
        [filterTypes.TOTAL_INTERACTIONS]: selectedItem
      })
    }
  }

  const handleClear = () => {
    setActionLabel(defaultActionLabel)
    setSelectedItem(null)
    if (props.onClear) {
      props.onClear({
        filterType: filterTypes.TOTAL_INTERACTIONS
      })
    }
  }

  return (
    <div data-testid='eg-customers-total-interactions-filter'>
      <FilterOptions
        actionLabel={ actionLabel }
        onApply={ handleApply }
        onClear={ handleClear }
        applyOnClickOut
        applyDisabled={ !selectedItem || !selectedItem.value }
        selectedItems={ selectedItem && selectedItem.value ? [ selectedItem ] : null }
        customItemsComponent={
          <FilterContent
            items={ filterOptions }
            selectedItem={ selectedItem }
            onInputChange={ handleInputChange }
            onSelect={ handleSelect }
          />
        }
      />
    </div>
  )
}

TotalInteractionsFilter.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

export default TotalInteractionsFilter
