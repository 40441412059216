import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { Select } from '../../../../FormComponents'
import { predicateTypes, predicateAttributes, predicateComparison, predicateValues } from '../../../../../services/segments'
import { actions  as journeyActions } from '../../../../../actions/Journey'

import { journeyTypes, journeyTriggers, mapJourneyListForSelectInput } from '../../../../../services/journeys'

import './styles.scss'


const predicateOptions = [
  {
    value: predicateValues.PRIVY,
    selectLabel: 'Choose Popup Id',
    trigger: journeyTriggers.PRIVY,
    label: 'Popup'
  },
  {
    value: predicateValues.WEBHOOK,
    selectLabel: 'Choose Keyword',
    trigger: journeyTriggers.KEYWORD,
    label: 'Keyword'
  },
]

/**
* While reconstructing the query, we get what kind of trigger type do we have
* for this query. is it Privy or webhook/keyword
* @param {Object} triggerTypePredicate TriggerType predicate
* {
*   type: 'sign_up',
*   attribute: 'source_medium',
*   comparison: 'eq',
*   value: 'webhook'
* }
* @returns {Object} selected option to select trigger type. i.e.
* {
*   value: predicateValues.WEBHOOK,
*   selectLabel: 'Choose Keyword',
*   trigger: journeyTriggers.KEYWORD,
*   label: 'Keyword'
* }
*/
const getInitialTriggerType = (triggerTypePredicate) => {
  let initialSelectedType = predicateOptions[0]
  if(triggerTypePredicate && triggerTypePredicate.value === predicateValues.WEBHOOK){
    initialSelectedType = predicateOptions[1]
  }
  return initialSelectedType
}

const TriggerTypePredicate = props => {
  let { triggerTypePredicate, triggerItemPredicate, onChange } = props
  const dispatch = useDispatch()
  let initialSelectedTriggerType = getInitialTriggerType(triggerTypePredicate)
  const [selectedOption , setSelectedOption] = useState(initialSelectedTriggerType)
  const [selectedItem, setSelectedItem] = useState(null)
  const [list, setList] = useState([])
  const { list : popupJourneyList, loading: popupJourneyListLoading }  = useSelector(state => state.Journey.popupJourneyList)
  const { list : keywordJourneyList, loading: keywordJourneyListLoading }  = useSelector(state => state.Journey.keywordJourneyList)
  const mounted = useRef()
  const createdQuery = useRef()
  const loading = popupJourneyListLoading || keywordJourneyListLoading

  const setInitialList = useCallback(() => {
    if(selectedOption.value === predicateValues.PRIVY){
      const listItems = _.get(popupJourneyList, 'items', [])
      const mappedJourney = listItems.map((journey) => {
        return {
          ...journey,
          label: journey.trigger_id,
          value: journey.id
        }
      })
      setList(mappedJourney)
    } else if (selectedOption.value === predicateValues.WEBHOOK){
      const listItems = _.get(keywordJourneyList, 'items', [])
      const mappedJourney = listItems.map((journey) => {
        return {
          ...journey,
          label: journey.trigger_id,
          value: journey.id
        }
      })
      setList(mappedJourney)
    }
  }, [keywordJourneyList, popupJourneyList, selectedOption.value])

  const setInitialItem = useCallback(() => {
    if(triggerItemPredicate){
      let journeyItem = null
      if(selectedOption.value === predicateValues.PRIVY){
        const listItems = _.get(popupJourneyList, 'items', [])
        journeyItem = listItems.find(journey => journey.id === triggerItemPredicate.value)
      } else if (selectedOption.value === predicateValues.WEBHOOK){
        const listItems = _.get(keywordJourneyList, 'items', [])
        journeyItem = listItems.find(journey => journey.id === triggerItemPredicate.value)
      }
      if(journeyItem) {
        journeyItem.label = journeyItem.trigger_id
        setSelectedItem({
          ...journeyItem,
          value: journeyItem.id
        })
      }
    }
  }, [keywordJourneyList, popupJourneyList, selectedOption.value, triggerItemPredicate])

  useEffect(() => {
    const hasPopupAndKeywordJourneyList = _.get(popupJourneyList, 'items.length') &&  _.get(keywordJourneyList, 'items.length')
    if(!createdQuery.current && hasPopupAndKeywordJourneyList) {
      createdQuery.current = true
      setInitialList()
      setInitialItem()
    }
  }, [dispatch, popupJourneyList, keywordJourneyList, selectedOption.value, setInitialList, setInitialItem])

  useEffect(() => {
    // Initial privy/popup id loading
    if (!mounted.current) {
      mounted.current = true
      const payloadOptions = {
        page: null,
        size: null,
        status: journeyTypes.ACTIVE,
        trigger: journeyTriggers.PRIVY
      }
      !_.get(popupJourneyList, 'items') && dispatch(journeyActions.getPopupJourneys(payloadOptions))
      const keywordPayloadOptions = {
        page: null,
        size: null,
        status: journeyTypes.ACTIVE,
        trigger: journeyTriggers.KEYWORD
      }
      !_.get(keywordJourneyList, 'items') && dispatch(journeyActions.getKeywordJourneys(keywordPayloadOptions))
    }
  },[dispatch, keywordJourneyList, popupJourneyList])

  const setItemList = (item) => {
    if(item.value === predicateValues.PRIVY) {
      if(popupJourneyList) {
        const listItems = _.get(popupJourneyList, 'items', [])
        const mappedJourney = mapJourneyListForSelectInput(listItems)
        setList(mappedJourney)
      }
    }else if(item.value === predicateValues.WEBHOOK) {
      if(keywordJourneyList) {
        const listItems = _.get(keywordJourneyList, 'items', [])
        const mappedJourney = mapJourneyListForSelectInput(listItems)
        setList(mappedJourney)
      }
    }
  }

  const onTypeChange = (item) => {
    if(loading) return
    const triggerTypePredicate = {
      type: predicateTypes.SIGN_UP,
      attribute: predicateAttributes.SOURCE_MEDIUM,
      comparison: predicateComparison.EQ,
      value: item.value,
    }
    onChange(triggerTypePredicate)
    // Clear selected option.
    setSelectedItem(null)
    setSelectedOption(item)
    setItemList(item)
  }

  const handleItemChange =(item) => {
    const updatedIdPredicate =  {
      type: predicateTypes.ID,
      attribute: predicateAttributes.JOURNEY_ID,
      comparison: predicateComparison.EQ,
      value: item.id,
    }
    onChange(updatedIdPredicate)
    setSelectedItem(item)
  }

  return (
    <>
      <div className='predicate-cell'>
        <Select
          name='segments-predicates-source-option'
          className='type-select'
          placeholder='Choose activity'
          options={ predicateOptions }
          selectedItem={ selectedOption || null }
          onChange={ onTypeChange }
        />
      </div>
    <div className='text-node'>
      { selectedOption.value === predicateValues.PRIVY ? 'id' : 'is' }
    </div>
    <div className='predicate-cell'>
      <Select
        className='element-selection'
        options={list}
        selectedItem={selectedItem}
        onChange={handleItemChange}
        loading={loading}
        placeholder={
          loading
            ? 'loading'
            : selectedOption.selectLabel
        }
      />
    </div>
    </>
  )
}

TriggerTypePredicate.propTypes = {
  triggerTypePredicate: PropTypes.object,
  triggerItemPredicate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default TriggerTypePredicate
