import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import NumberActivity from './NumberActivity'
import { actions as callActions } from '../../../actions/Call'

const mapStateToProps = state => ({
  callList: state.Call.callList,
  loading: state.Call.loading
})

const mapDispatchToProps = dispatch => ({
  getCallLogs(opts) {
    dispatch(callActions.getCalls(opts))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NumberActivity))
