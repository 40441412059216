import React from 'react'
import PropTypes from 'prop-types'

import CharacterCounter from './CharacterCounter'
import ImagePreview from './ImagePreview'
import Triggers from './Triggers'

import './styles.scss'

/**
 * @description SMS addon outputs for CustomTextarea component
 *
 * @component
 *
 * @prop {string} text - the complete text, this is used by CharacterCounter to count all the chars.
 *                For example, if we want to keep into account some other text that's being added
 *                outside the textarea scope, we should include it in this prop.
 * @prop {string} text - the preview image url, used in ImagePreview component
 * @prop {boolean} uploading - tells if the preview is being uploaded
 * @prop {function} onDelete - function called when the preview is removed
 *
 * @example
 *
 * <SMSAddon.Component
 *   text={ description }
 *   imagePreview={ previewSmsImage }
 *   uploading={ uploadingSmsImg }
 *   onDelete={ handlePreviewDelete }
 * />
 *
*/
const SMS = props => {
  const {
    text = '',
    characterCounter = true,
    uploading = false,
    imagePreview = null,
    onDelete
  } = props

  return (
    <div className='eg-textarea-addon-sms'>
      <div>
        {
          imagePreview && (
            <ImagePreview image={ imagePreview } uploading={ uploading } onDelete={ onDelete } />
          )
        }
      </div>
      <div>
        {
          characterCounter && (
            <CharacterCounter text={ text } isMMS={ !!imagePreview } />
          )
        }
      </div>
    </div>
  )
}

SMS.propTypes = {
  text: PropTypes.string,
  characterCounter: PropTypes.bool,
  imagePreview: PropTypes.string,
  uploading: PropTypes.bool,
  onDelete: PropTypes.func
}

export default {
  Component: SMS,
  Triggers
}
