import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { actions } from '../../../actions/Campaign'
import CampaignCreate from '../../../components/Campaigns/Create'
import LocalSpinner from '../../../components/LocalSpinner'

const CampaignCreateContainer = props => {
  const campaign = useSelector(state => _.get(state, 'Campaign.campaign.data', {}))
  const { loading: segmentOptionLoading } = useSelector(state => state.Campaign.segmentOptions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getSegmentOptions(
      { statuses: ['processed', 'published'] }
    ))
    dispatch(actions.updateCampaignClear())
  }, [dispatch])

  // clear campaign data when component is unmounted
  useEffect(() => {
    return () => {
      dispatch(actions.createCampaignClear())
      dispatch(actions.updateCampaignClear())
    }
  }, [dispatch])

  if (segmentOptionLoading) {
    return <LocalSpinner />
  }

  return <CampaignCreate history={ props.history } campaign={ campaign } />
}

CampaignCreateContainer.propTypes = {
  history: PropTypes.object
}

export default CampaignCreateContainer
