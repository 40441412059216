import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="eg-custom-tooltip">
        <div className='top-row'>
          <div>
            Clicks
          </div>
          <div className="clicks">{payload[0].value}</div>
        </div>
        <p className="label">{payload[0].payload.label}</p>
      </div>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  payload: PropTypes.array,
}

export default CustomTooltip
