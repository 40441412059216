export const types = {
  GET_ORDERS_INTENT: 'GET_ORDERS_INTENT',
  GET_ORDERS_INTENT_RESOLVED: 'GET_ORDERS_INTENT_RESOLVED',
  GET_ORDERS_INTENT_REJECTED: 'GET_ORDERS_INTENT_REJECTED',

  // actions types for single order
  GET_SINGLE_ORDER_INTENT: 'GET_SINGLE_ORDER_INTENT',
  GET_SINGLE_ORDER_INTENT_RESOLVED: 'GET_SINGLE_ORDER_INTENT_RESOLVED',
  GET_SINGLE_ORDER_INTENT_REJECTED: 'GET_SINGLE_ORDER_INTENT_REJECTED',

  CLEAR_ORDERS: 'CLEAR_ORDERS'
}

export const actions = {
  getOrders(opts) {
    return {
      type: types.GET_ORDERS_INTENT,
      opts
    }
  },

  getOrdersResolved(data) {
    return {
      type: types.GET_ORDERS_INTENT_RESOLVED,
      data
    }
  },

  getOrdersRejected() {
    return {
      type: types.GET_ORDERS_INTENT_REJECTED
    }
  },

  getSingleOrder(order) {
    return {
      type: types.GET_SINGLE_ORDER_INTENT,
      data: { order }
    }
  },

  getSingleOrderResolved(data) {
    return {
      type: types.GET_SINGLE_ORDER_INTENT_RESOLVED,
      data
    }
  },

  getSingleOrderRejected() {
    return {
      type: types.GET_SINGLE_ORDER_INTENT_REJECTED
    }
  },

  clearOrders() {
    return {
      type: types.CLEAR_ORDERS
    }
  }
}
