import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import usePrevious from '../../hooks/usePrevious'
import FileProgressBar from '../FileProgressBar'
import UploadUI from './UploadUI'
import './styles.scss'

const Uploader = (props) => {
  const {
    title,
    fileType='',
    fileText='',
    multipleFile,
    onFileSelection,
    onSuccess,
  } = props

  const { uploading, hasDocUploaded } = useSelector(({ Upload }) => Upload)
  const [fileData, setFileData] = useState({})
  const previousHasDocUploaded = usePrevious(hasDocUploaded)
  
  /**
   * This Effect handles the when files gets uploaded to to cloud.
   */
  useEffect(() => {
    const haveFiledUploaded = previousHasDocUploaded !== undefined && !previousHasDocUploaded && hasDocUploaded
    if(haveFiledUploaded) {
      // When documents/files have uploaded successfully
      onSuccess && onSuccess()
    }
  }, [previousHasDocUploaded, hasDocUploaded, onSuccess])
  

  /**
   * This function handles when uploader selects files from local machine
   * @param {*} acceptedFiles 
   * @param {*} errorFiles  files which failed validation(i.e. given file type is .csv 
   * and user is trying to upload something different like .png file.)
   */
  const onSelection = (acceptedFiles, errorFiles) => {
    const mappedAcceptedFiles = attatchProgressFunction(acceptedFiles)
    setInitialStateForProgress(acceptedFiles)
    onFileSelection(mappedAcceptedFiles, errorFiles)
  }

  /**
   * Sets intial upload information data for 
   * each file which is going to be uploaded to s3
   * @param {*} files 
   */
  const setInitialStateForProgress = (files = []) => {
    for(const file of files) {
      setFileData(prevFileData => 
        ({ ...prevFileData, [file.name]: {loaded: 0, total: 100}})
      )
    }
  }

  /**
   * Attaching a callback function to update the file upload percentage in the component.
   * @param {*} files files that are going to be put on s3 bucket. 
   */
  const attatchProgressFunction = (files) => {
    return files.map((file) => {
      file.onUploadProgress = (progressData) => {
        setFileData(prevFileData => ({...prevFileData, [file.name]: progressData }))
      }
      return file
    })
  }


  const renderFileProgressBars = () => {
    const progressBars = Object.keys(fileData).map(
      (fileName, index) => {
        const progress = fileData[fileName]
        let percentage = Math.floor((progress.loaded * 100) / progress.total)
        return (
          <FileProgressBar
            key={index}
            percentCompleted={percentage}
            fileName={fileName}
          />
        )
      }
    )
    return (
      <div className="progress">
        <div className="title">Uploading file(s)</div>
        {progressBars}
      </div>
    )
  }

  const renderUploadPart = () => {
    if(uploading) {
      return renderFileProgressBars()
    }
    return (
      <UploadUI
        onFileUpload={onSelection}
        fileType={fileType}
        fileText={fileText}
        multipleFile={multipleFile}
      />
    )
  }

  return (
    <div className="eg-import-content">
      <h3>{title}</h3>
      {renderUploadPart()}
    </div>
  )
}

Uploader.propTypes = {
  title: PropTypes.string,
  fileText: PropTypes.string,
  fileType: PropTypes.string,
  multipleFile: PropTypes.bool,
  onFileSelection: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default Uploader
