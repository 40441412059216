import { useEffect, useRef } from 'react'

/**
 * a way to keep track of a hook's prop and state's last value
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param {} value 
 */
export default function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
