import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { renderInput } from '../../../../FormComponents/utils'
import { UPLOAD_FILE_TYPE } from '../../../../../services/upload'
import PhonePreview, { HeaderBrowser } from '../../../../PhonePreview'
import PreviewPage from './PreviewPage'
import LogoField from '../../LogoField'

import './styles.scss'

export const fields = {
  HEADLINE: 'headline',
  SUBTEXT: 'subtext',
  DISCOUNT_AMOUNT: 'discount_amount'
}

const LoyaltySignupPage = props => {
  const { validator, visible, store, values, onSwitchTab } = props

  const handleSwitchTabClick = () => {
    if (onSwitchTab) {
      onSwitchTab()
    }
  }

  const disabled = String(values.optimized_page) === 'false'

  const validateField = value => {
    if (disabled || value !== '') return true
    return 'This field is required'
  }

  return (
    <div className={ `eg-loyalty-signup-page ${!visible ? 'hidden' : ''}` } data-testid='loyalty-signup-page'>
      <div className='form-wrapper'>
        <div className='title'>Loyalty Sign Up Page</div>
        {
          disabled && (
            <div className='message' data-testid='loyalty-signup-disabled-msg'>
              Customizations are not available for your native loyalty sign up page.<br />
              Switch to ZSF optimized page if you wish to enhance this page.
              <span onClick={ handleSwitchTabClick }>Switch to zsf optimized page</span>
            </div>
          )
        }
        <div className={ `form ${disabled ? 'disabled' : ''}` }>
          { disabled && <div className='disabled' /> }
          <LogoField logo={ store.ivr_logo } store={ store } logoType={UPLOAD_FILE_TYPE.IVR_LOGO} />
          <div className='field'>
            <label className='label' htmlFor={ fields.HEADLINE }>Headline</label>
            { renderInput(fields.HEADLINE, validator, { validate: validateField }) }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.SUBTEXT }>Subtext</label>
            { renderInput(fields.SUBTEXT, validator, { validate: validateField }) }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.DISCOUNT_AMOUNT }>Discount amount</label>
            { renderInput(fields.DISCOUNT_AMOUNT, validator, { validate: validateField }) }
          </div>
        </div>
      </div>
      <div className='preview'>
        <div className='title'>PREVIEW</div>
        <PhonePreview className='phone'>
          <HeaderBrowser searchbarLabel='zerostorefront.com' />
          <PreviewPage
            source={ _.get(store, 'storeInfo.name') }
            headline={ values[fields.HEADLINE] }
            subtext={ values[fields.SUBTEXT] }
            discount={ values[fields.DISCOUNT_AMOUNT] }
            name='[Customer name]'
            phone='[Customer phone number]'
            disabled={ disabled }
          />
        </PhonePreview>
      </div>
    </div>
  )
}

LoyaltySignupPage.propTypes = {
  headline: PropTypes.string,
  subtext: PropTypes.string,
  discount: PropTypes.string,
  validator: PropTypes.object,
  visible: PropTypes.bool,
  store: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onSwitchTab: PropTypes.func
}

export default LoyaltySignupPage
