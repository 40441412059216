import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * StepsWidget - displays some flow's steps
 *
 * @component
 * @example
 *
 * const steps = [
 *  { key: 'step1', label: 'Start' },
 *  { key: 'step2', label: 'Design' },
 *  { key: 'step3', label: 'End' },
 * ]
 *
 * const handleStepClick = (step) => { ... }
 *
 * return (
 *  <StepsWidget steps={ steps } selectedStep={ currentStep } onClick={ handleStepClick } />
 * )
*/
const StepsWidget = props => {
  const { steps, selectedStep, onClick } = props

  const handleStepClick = (step, index) => {
    if (onClick && (step.completed || isLastAccessible(step, index))) {
      onClick(step)
    }
  }

  const isLastAccessible = (step, index) => {
    const lastStep = steps[index - 1]
    if (index === 0) return true
    return !step.completed && lastStep.completed
  }

  return (
    <div className='eg-steps-widget' data-testid='eg-steps-widget'>
      {
        steps.map((step, i) => {
          const isSelected = selectedStep.key === step.key
          const isPrev = step.completed
          const selectedClass = isSelected ? 'selected' : ''
          const prevClass = isPrev ? 'prev' : ''
          const clickableClass = isPrev || isLastAccessible(step, i) ? 'prev' : ''
          return (
            <div
              className={ `step-item ${selectedClass} ${clickableClass}` }
              key={ `step-widget-item-${i}` }
              data-testid= {`campaign-step-${i}`}
              onClick={ () => handleStepClick(step, i) }
            >
              <div className={ `step-symbol ${prevClass}` }>
                { !isPrev ? i + 1 : <div className='prev-icon' /> }
              </div>
              <div className='step-label'>{ step.label }</div>
              <div className='line' />
            </div>
          )
        })
      }
    </div>
  )
}

StepsWidget.propTypes = {
  steps: PropTypes.array.isRequired,
  selectedStep: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default StepsWidget
