import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import CustomerHeader from '../../components/CustomerHeader'
import CallList from '../../components/Calls/CallList'
import LocalSpinner from '../../components/LocalSpinner'
import StoreFilter from '../../components/Store/StoreFilter'
import DateFilter from '../../components/Reviews/Filters/DateFilter'
import PaginationSection from '../../components/PaginationSection'
import Search from '../../components/Search'
import { DEFAULT_ITEMS_PER_PAGE } from '../../constants'
import './styles.scss'

const filterTypes = {
  DATE: 'date',
  NUMBER: 'number'
}

const initialFiltersState = {
  [filterTypes.DATE]: { actionLabel: 'All Time', filterKeys: [] },
  [filterTypes.NUMBER]: { actionLabel: 'All Number', filterKeys: [] }
}

export default class CallLogs extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    callList: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.number,
    getCallLogs: PropTypes.func.isRequired,
    lastUsedChainUuid: PropTypes.string,
    getStoreList: PropTypes.func.isRequired,
    selectedStores: PropTypes.array,
    clearStoreFilter: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      searchValue: '',
      searching: false,
      filters: initialFiltersState
    }

    // bindings
    this.getCallLogs = this.getCallLogs.bind(this)
    this.getStoreList = this.getStoreList.bind(this)
    this.onStoreFilterChange = this.onStoreFilterChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterApply = this.handleFilterApply.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.doesHaveFilters = this.doesHaveFilters.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
  }

  componentDidMount() {
    this.getCallLogs()
    this.getStoreList()
  }

  getCallLogs() {
    this.props.getCallLogs({
      page: 1,
      size: DEFAULT_ITEMS_PER_PAGE,
      filters: this.state.filters
    })
  }

  onStoreFilterChange() {
    this.props.getCallLogs({
      page: 1,
      size: _.get(this.props.callList, 'size'),
      sort: _.get(this.props.callList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  getStoreList() {
    const { lastUsedChainUuid, getStoreList, restaurant } = this.props
    const restaurantChainUuid = restaurant.restaurant_chain_uuid
    // get store list for filters,  if we haven't retrieved yet
    if(lastUsedChainUuid !== restaurantChainUuid){
      getStoreList()
    }
  }

  handlePageSizeChange(size) {
    this.props.getCallLogs({
      page: 1,
      size,
      sort: _.get(this.props.callList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  handlePageChange(page) {
    this.props.getCallLogs({
      page,
      size: _.get(this.props.callList, 'size'),
      sort: _.get(this.props.callList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  handleSortChange(field) {
    this.props.getCallLogs({
      page: 1,
      size: _.get(this.props.callList, 'size'),
      sort: field,
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  /**
   * handle changes in the filter, updating only the label
   * we don't update the filterKeys until user applies the changes
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterChange(filter, filterType) {
    const filterKeys = this.state.filters[filterType].filterKeys
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: {
          actionLabel: filter.actionLabel,
          filterKeys
        }
      }
    })
  }

  /**
   * handle apply filter, updating the label and also the selected items
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterApply(filter, filterType) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: filter
      }
    }, () => {
      this.props.getCallLogs({
        page: 1,
        size: _.get(this.props.callList, 'size'),
        sort: _.get(this.props.callList, 'sort'),
        filters: this.state.filters,
        search: this.state.searchValue
      })
    })
  }

  clearFilters() {
    this.props.clearStoreFilter()
    this.setState({ filters: initialFiltersState }, () => {
      this.props.getCallLogs({
        page: 1,
        size: _.get(this.props.callList, 'size'),
        sort: _.get(this.props.callList, 'sort'),
        filters: this.state.filters
      })
    })
  }

  /**
   * Checks wether any filter has been applied or not
   */
  doesHaveFilters() {
    const { filters } = this.state
    const { selectedStores = [] } = this.props
    if( selectedStores.length ||
      _.get(filters[filterTypes.DATE], 'filterKeys.length') || 
      _.get(filters[filterTypes.SOURCE], 'filterKeys.length') ||
      _.get(filters[filterTypes.RATING], 'filterKeys.length') ||
      _.get(filters[filterTypes.STATUS], 'filterKeys.length')) {
      return true
    }
    return false
  }

  handleSearchChange(value) {
    this.setState({ searchValue: value }, () => {
      if (!this.state.searching) {
        this.setState({ searching: true }, () => {
          setTimeout(() => {
            this.props.getCallLogs({
              page: 1,
              size: _.get(this.props.callList, 'size'),
              sort: _.get(this.props.callList, 'sort'),
              filters: this.state.filters,
              search: this.state.searchValue
            })
            this.setState({ searching: false })
          }, 1000)
        })
      }
    })
  }

  render() {
    const callList = this.props.callList

    return (
      <>
        <CustomerHeader />
        <div className='eg-call-logs'>
          <div className='body'>
            <h3 className='title'>Call logs</h3>
            <div className='filterbar'>
              <div className='filters'>
                <StoreFilter onFilterChange={this.onStoreFilterChange} className='store' />
                <DateFilter 
                  filter={ this.state.filters[filterTypes.DATE] }
                  onChange={ (filter) => this.handleFilterChange(filter, filterTypes.DATE) } 
                  onApply={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) } 
                  onClear={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) } 
                  actionLabel={ this.state.filters[filterTypes.DATE].actionLabel }
                />
              </div>
              <Search
                className='search'
                value={ this.state.searchValue }
                onChange={ this.handleSearchChange }
              />
            </div>
            <div className='list'>
              {
                callList.items && !!callList.items.length && (
                  <>
                    <div className='totals'>
                      { callList.total } calls
                    </div>
                    <div className='calls'>
                      <CallList calls={ callList } />
                    </div>
                    <div className='pagination'>
                      <PaginationSection
                        total={ callList.total }
                        size={ callList.size }
                        page={ callList.page }
                        onPageChange={ this.handlePageChange }
                        onSizeChange={ this.handlePageSizeChange }
                      />
                    </div>
                  </>
                )
              }
              {
                callList.items && !callList.items.length && !this.props.loading && (
                  <div className='emptyview'>
                    <div className='image' />
                    <div className='title'>
                      No recorded call activity yet
                    </div>
                    <div className='description'>
                      All calls recorded from this number will appear here
                    </div>
                  </div>
                )
              }
              {
                this.props.loading && (
                  <LocalSpinner className='spinner' />
                )
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
