import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class OrderSummary extends React.Component {
  static propTypes = {
    order: PropTypes.object,
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const order = this.props.order
    if (!order) {
      return null
    }

    return (
      <div className={ `${styles.container} ${this.props.className}` }>
        {/* header */}
        <div className={ styles.header }>
          <div className={ styles.qtyField }>Qty</div>
          <div className={ styles.itemField }>Item</div>
          <div className={ styles.priceField }>Price</div>
        </div>

        {/* body */}
        <div className={ styles.body }>
          {
            order.order_items.map(orderItem => (
              <div className={ styles.itemRow } key={ orderItem.restaurant_order_item_id }>
                <div className={ styles.qtyField }>{ orderItem.quantity } x</div>
                <div className={ styles.itemField }>
                  { orderItem.name }<br />
                  { orderItem.customer_instruction }
                  <span>$ { typeof orderItem.price === 'number' ? orderItem.price.toFixed(2) : orderItem.price }</span>
                </div>
                <div className={ styles.priceField }>
                  $ { (orderItem.price * orderItem.quantity).toFixed(2) }
                </div>
              </div>
            ))
          }
        </div>

        {/* summary */}
        <div className={ styles.summaryWrapper }>
          <div className={ styles.summary }>
            <div className={ styles.summaryRow }>
              <div className={ styles.summaryLabel }>Subtotal</div>
              <div className={ styles.summaryValue }>$ { typeof order.subtotal === 'number'? order.subtotal.toFixed(2): order.subtotal }</div>
            </div>
            <div className={ styles.summaryRow }>
              <div className={ styles.summaryLabel }>Delivery Fee</div>
              <div className={ styles.summaryValue }>$ { typeof order.delivery_fee === 'number' ? order.delivery_fee.toFixed(2) : order.delivery_fee }</div>
            </div>
            <div className={ styles.summaryRow }>
              <div className={ styles.summaryLabel }>Sales Tax</div>
              <div className={ styles.summaryValue }>$ { typeof order.tax === 'number'? order.tax.toFixed(2) : order.tax}</div>
            </div>
            <div className={ `${styles.summaryRow} ${styles.total}` }>
              <div className={ styles.summaryLabel }>Total</div>
              <div className={ styles.summaryValue }>$ { typeof order.total === 'number'? order.total.toFixed(2): order.total }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
