import axios from 'axios'
import moment from 'moment'
import { METHODS, HeaderFactory, constructStoreQuery } from './utils'

/**
 * get calls
 */
export const getCalls = (opts, tokens) => {
  const qstring = []
  const { size, page, search, numberUuid } = opts
  if (size) qstring.push(`size=${size}`)
  if (page) qstring.push(`page=${page}`)
  if (search) qstring.push(`search=${search}`)
  if (numberUuid) qstring.push(`phone_number_uuid=${numberUuid}`)
  const { chainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid: chainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const filterQueryParams = constructFilterQueryParams(opts)
  const qparams = [
    ...storeQueryParams,
    ...filterQueryParams,
    ...qstring
  ]
  // const storeQueryParams = constructStoreQuery(storeInfo)

  const baseUrl = `${process.env.REACT_APP_INTEGRATION_URL}/twilio/phone_numbers/call_logs`
  return axios({
    url: `${baseUrl}?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * Constructs api query values for user applied filters, i.e time, rating
 * @param {*} opts
 */
const constructFilterQueryParams = (opts) => {
  const qparams = []
  if (opts.filters) {
    const res = {}
    Object.keys(opts.filters).forEach(k => { res[k] = opts.filters[k].filterKeys })
    if (res.date && res.date.length) {
      const dateFrom= moment(res.date[0]).format('YYYY-MM-DD')
      const dateTo= moment().format('YYYY-MM-DD')
      qparams.push(`date_from=${dateFrom}`)
      qparams.push(`date_to=${dateTo}`)
    }
  }
  return qparams
}
