import { call, takeLatest, put, select } from 'redux-saga/effects'

import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import { NetworkError } from '../services/error'
import { authorizedRequest } from './Auth'
import { types, actions } from '../actions/SMS'
import { uploadImage } from '../api/Sms'

export function* uploadMMSImageHandler({ opts = {} }) {
  const chainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  try {
    const res = yield call(authorizedRequest, uploadImage, {
      ...opts,
      chainUuid
    })
    if (res && res.data) {
      yield put(actions.uploadMMSImageResolved(res.data, opts.id))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${error && error.message}`
    }))
    yield put(actions.uploadMMSImageRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.MMS_UPLOAD_IMAGE, uploadMMSImageHandler)
}

