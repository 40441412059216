import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import useValidator from '../../../hooks/validator'
import StatelessModal from '../../StatelessModal'
import {
  Input,
  Button
} from '../../FormComponents'
import {
  NumberList,
  SectionHeader
} from '../../VirtualNumber'
import { types as buttonTypes } from '../../FormComponents/Button'

import './styles.scss'

const FIELD_LOCATION = 'location'
const FIELD_PURPOSE = 'purpose'
const FIELD_TITLE = 'title'

const CreateNumber = props => {
  const { register, formState, errors, getValues } = useValidator()
  const [ displayModal, setDisplayModal ] = useState(false)
  const [ created, setCreated ] = useState(false)
  const numbers = useSelector(state => state.VirtualNumber.numbers)

  const { virtualNumber, match } = props

  // switch modal state when virtual number is returned
  useEffect(() => {
    if (virtualNumber && created) {
      setDisplayModal(true)
      setCreated(false)
    }
  }, [virtualNumber, created])

  useEffect(() => {
    return () => {
      setCreated(false)
    }
  }, [])

  const submit = () => {
    props.createNumber({
      ...getValues(),
      restaurantUuid
    })
    setCreated(true)
  }

  const handleSuccessClose = () => {
    setDisplayModal(false)
  }

  const handleForwardClick = () => {
    const id = props.virtualNumber.id
    props.history.push(`#numbers/${id}/forward`)
  }

  const handleCancelClick = () => {
    if (numbers && numbers.length) {
      props.history.push(`#numbers/${numbers[0].id}/details`)
    }
  }

  const restaurantUuid = _.get(match, 'params.uuid')
  const submitDisabledClass = !formState.isValid ? '-disabled' : ''

  return (
    <div className='eg-create-number'>
      <div className='numbers'>
        <NumberList restaurantUuid={ restaurantUuid } showNewNumber />
      </div>
      <div className='section-wrapper'>
        <SectionHeader title='New number' />
        <div className='body'>
          <div className='title'>Add a new smart phone number</div>
          <p className='subtitle'>
            How does it work?
            <a href='https://zerostorefront.com/voice/' target='_blank' rel='noopener noreferrer'>Learn more</a>
          </p>

          <div className='form'>
            <div className='row'>
              <div className='label'>Location</div>
              <Input
                name={ FIELD_LOCATION }
                placeholder='Enter location'
                ref={ register({ required: true }) }
              />
              { errors[FIELD_LOCATION] && <div className='error'>{ errors[FIELD_LOCATION].message }</div> }
            </div>

            <div className='row'>
              <div className='label'>Primary purpose</div>
              <Input
                name={ FIELD_PURPOSE }
                placeholder='Enter purpose'
                ref={ register({ required: true }) }
              />
              { errors[FIELD_PURPOSE] && <div className='error'>{ errors[FIELD_PURPOSE].message }</div> }
            </div>

            <div className='row'>
              <div className='label'>Title for this number</div>
              <Input
                name={ FIELD_TITLE }
                placeholder='Enter name / title'
                ref={ register({ required: true }) }
              />
              { errors[FIELD_TITLE] && <div className='error'>{ errors[FIELD_TITLE].message }</div> }
            </div>

            <div className='actions'>
              {
                numbers && !!numbers.length && (
                  <Button
                    type={ buttonTypes.SECONDARY }
                    text='CANCEL'
                    className='button -secondary'
                    onClick={ handleCancelClick }
                  />
                )
              }
              <Button
                type={ buttonTypes.PRIMARY }
                text='CREATE'
                className={ `button -primary ${submitDisabledClass }` }
                disabled={ !formState.isValid }
                loading={ props.posting }
                onClick={ submit }
              />
            </div>
          </div>
        </div>
      </div>
      {
        displayModal && (
          <StatelessModal className='modal' onClose={ handleSuccessClose } closeStyle='modalclose'>
            <div className='title'>Success!</div>
            <div className='subtitle'>Your virtual phone number is ready.</div>
            <div className='phone'>{ props.virtualNumber.number }</div>
            <Button
              className='button -primary'
              text='START FORWARDING CALLS'
              onClick={ handleForwardClick }
            />
          </StatelessModal>
        )
      }
    </div>
  )
}

CreateNumber.propTypes = {
  virtualNumber: PropTypes.object,
  posting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  createNumber: PropTypes.func.isRequired
}

export default CreateNumber
