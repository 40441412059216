import { v4 } from 'uuid'

export const types = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
}

export const actions = {
  /**
   * Shows the notification
   */
  ShowNotification(notification) {
    return {
      type: types.SHOW,
      notification: { 
        ...notification,
        id: v4(),
      }
    }
  },
  /**
   * Hides the notification
   */
  HideNotification() {
    return { type: types.HIDE }
  },
}

export const selectors = {
  GetNotification: state => state.Notification,
}
