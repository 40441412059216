import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PATHS } from '../../constants'
import CustomerHeader from '../../components/CustomerHeader'
import SortingHeaders from '../../components/SortingHeaders'
import StoreList from '../../components/Store/StoreList'
import Button from '../../components/FormComponents/Button'
import { listHeaderFields } from './constant'
import { userRoles } from '../../services/auth'
import AccessControlledComponent from '../../components/AccessControlledComponent'
import './styles.scss'

const Stores = props => {
  const { lastUsedChainUuid, getStoreList, history } = props
  const restaurantChainUuid = useSelector((state => state.User.chain.restaurant_chain_uuid))
  const stores = useSelector((state => state.Store.storeList))
  const totalStores = stores && stores.length ? stores.length : 0

  useEffect(() => {
    if(lastUsedChainUuid !== restaurantChainUuid){
      getStoreList()
    }
  }, [lastUsedChainUuid, restaurantChainUuid, getStoreList])

  const handleSortChange = () => {
    // Will handle sorting later.
  }

  return (
    <>
      <CustomerHeader />
      <div className='eg-stores-container'>
        <div className='title-row'>
          <h2>
            Managed Stores {totalStores ? `(${totalStores})` : ''}
          </h2>
          <AccessControlledComponent roles={ [userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE] }>
            <Button
              className='button'
              text='ADD STORE'
              onClick={() => history.push(PATHS.STORE_CREATE)}
            />
          </AccessControlledComponent>
        </div>
        <SortingHeaders
          className='header-wrapper'
          fields={ listHeaderFields }
          currentSorting={ null }
          onChange={ handleSortChange }
        />
        <StoreList />
      </div>
    </>
  )
}



Stores.propTypes = {
  lastUsedChainUuid: PropTypes.string,
  getStoreList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default Stores
