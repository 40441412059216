import React, {useCallback, useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { actions as channelActions } from '../../actions/Channel'
import { actions as dashboardActions } from '../../actions/Dashboard'
import CustomerHeader from '../../components/CustomerHeader'
import RevenueCard from '../../components/Dashboard/RevenueCard'
import SubscriberCard from '../../components/Dashboard/SubscriberCard'
import CampaignCard from '../../components/Dashboard/CampaignCard'
import RoiCard from '../../components/Dashboard/RoiCard'
import TimeSelector from '../../components/TimeSelector'

import './styles.scss'

const Dashboard = () => {
  const dispatch = useDispatch()
  const [selectedDates, setSelectedDates] = useState(null)

  useEffect(() => {
    return () => {
      // To stop memory leak.
      // https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
      setSelectedDates(null)
    }
  })

  const onDateApply = useCallback((appliedDates) => {
    setSelectedDates(appliedDates)
  }, [])

  const getCardData = (data) => {
    if(selectedDates) {
      dispatch(channelActions.getChannels({
        from: _.get(data, 'dates.from'),
        to: _.get(data, 'dates.to')
      }))
    }
  }

  const getCardDataFromDashboard = (data) => {
    if(selectedDates) {
      dispatch(dashboardActions.getCardInfo(data))
    }
  }

  return (
    <div className='eg-dashboard'>
      <CustomerHeader />
      <div className='container'>
        <div className='header'>
          <div className='title'>Overview</div>
          <TimeSelector onDateApply={onDateApply}/>
        </div>
        <div className='content'>
          <div className='cards'>
            <RevenueCard
              onTimePeriodChange={getCardData}
              selectedDates={selectedDates}
            />
            <RoiCard
              onTimePeriodChange={getCardData}
              selectedDates={selectedDates}
            />
            <SubscriberCard
              onTimePeriodChange={getCardDataFromDashboard}
              selectedDates={selectedDates}
            />
            <CampaignCard
              onTimePeriodChange={getCardData}
              selectedDates={selectedDates}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
