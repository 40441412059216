import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router'
import { HOME_PATH } from '../../constants'
import Sidebar from '../../components/Sidebar'
import Notifications from '../../containers/Notifications'
import EgNotification from '../../components/EgNotification'
import Spinner from '../Spinner'
import Footer from '../Footer'
import Routing from '../../Routing'
import styles from './index.module.scss'

export default class MainLayout extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    showSidebar: PropTypes.bool,
    user: PropTypes.object
  }

  static defaultProps = {
    isAuthenticated: false
  }

  componentDidMount() {
    if (!window.google) {
      const mapsSrc = process.env.REACT_APP_GOOGLE_MAPS_SCRIPT_SRC
      const mapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY
      const googleScript = document.createElement('script')
      googleScript.src = `${mapsSrc}?key=${mapsApiKey}&libraries=places`
      window.document.body.appendChild(googleScript)
    }
  }

  render() {
    return (
      <>
        <EgNotification />
        <Notifications />
        <Route exact path='/' render={ () => (
          <Redirect to={ HOME_PATH(this.props.user) } />
        ) } />
        <div className={ styles.mainContainer }>
          { this.props.isAuthenticated && this.props.showSidebar ? <Sidebar /> : null }
          <div className={ styles.viewWrapper }>
            <Routing />
          </div>
          <Spinner backdrop={true} />
        </div>
        <Footer />
      </>
    )
  }
}
