import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as numberActions } from '../../../actions/VirtualNumber'
import Forwarding from './Forwarding'

const mapStateToProps = state => ({
  posting: state.VirtualNumber.posting,
  loading: state.VirtualNumber.loading,
  virtualNumber: state.VirtualNumber.number,
  virtualNumbers: state.VirtualNumber.numbers,
})

const mapDispatchToProps = dispatch => ({
  forwardNumber(data) {
    dispatch(numberActions.forwardNumber(data))
  },

  getNumber(id, restaurantUuid) {
    dispatch(numberActions.getNumber(id, restaurantUuid))
  },

  clearNumber() {
    dispatch(numberActions.clearNumber())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forwarding))
