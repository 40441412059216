import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Chains from './Chains'
import { actions as sidebarActions } from '../../actions/Sidebar'
import { actions as chainActions } from '../../actions/Chain'
import { actions as userActions } from '../../actions/User'
import { actions as filterActions } from '../../actions/Filter'
import { actions as spinnerActions } from '../../actions/Spinner'
import { actions as orderActions } from '../../actions/Order'
import { PATHS, KEYS } from '../../constants'

const mapStateToProps = state => ({
  user: state.User.user,
  restaurant: state.User.chain,
  chains: state.Chain.chainList.chains,
  loading: state.Spinner.loading,
  searching: state.Chain.searching,
  navigating: state.Chain.navigating
})

const mapDispatchToProps = dispatch => ({
  hideSidebar() {
    dispatch(sidebarActions.hideSidebar())
  },

  showSidebar() {
    dispatch(sidebarActions.showSidebar())
  },

  getChains(page = 1, search, firstRequest = true) {
    if (firstRequest) {
      dispatch(spinnerActions.loading())
      dispatch(chainActions.resetList())
    }
    dispatch(chainActions.getChains({ page, search }))
  },

  switchChain(chain) {
    // TODO: Change this function to updateChainInfo
    dispatch(userActions.UpdateChainInfo([ chain ]))
    dispatch(userActions.setUserRoles(chain))
    // Next line commented to disable IronPlans for now
    // dispatch(userActions.getPlanToken(chain))
    dispatch(orderActions.clearOrders())
    // if stores were selected earlier, let's clear them in the redux store
    dispatch(filterActions.setStoreFilter([]))
    // redirecting to the page where the user was trying to go before login.
    const referredPage = sessionStorage.getItem(KEYS.LOGIN_PAGE_REFERRER)
    if(referredPage){
      // Removing the session data of login redirection
      // we no longer need it after this case.
      sessionStorage.removeItem(KEYS.LOGIN_PAGE_REFERRER)
      dispatch(push(referredPage))
    } else {
      dispatch(push(PATHS.DASHBOARD))
    }
  },

  searchChanged(value) {
    dispatch(chainActions.searchIntent({ search: value }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Chains)
