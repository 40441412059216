import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CustomerSnippet = props => {
  const { customer, subtitle = '' } = props

  if (!customer) return null

  return (
    <div className='eg-customer-snippet'>
      <div className='avatar' />
      <div className='info'>
        { customer.fullName }
        <span>{ subtitle }</span>
      </div>
    </div>
  )
}

CustomerSnippet.propTypes = {
  customer: PropTypes.object,
  subtitle: PropTypes.string
}

export default CustomerSnippet
