import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { timeToAmPm } from '../../../../../services/dates'
import { DATE_FORMAT } from '../../../../../constants'

import mainStyles from '../main.module.scss'
import styles from './info.module.scss'

const Info = props => {
  const store = props.store
  const schedule = _.get(store, 'operationDetails.schedule')
  const specialSchedule = _.get(store, 'operationDetails.specialSchedule')

  const displayScheduleItem = item => {
    return item.state === 'open' ? (
      <div>{ `${timeToAmPm(item.from)} - ${timeToAmPm(item.to)}` }</div>
    ) : (
      <div>Closed</div>
    )
  }

  return (
    <div className={ `${mainStyles.editableSection} ${styles.opDetailsContainer}` }>
      <h3>Operation details</h3>
      <div className={ `${mainStyles.editableSectionRow} ${styles.sectionRow}` }>
        <div className={ mainStyles.editableItemLabel }>Operating hours</div>
        <div className={ styles.itemGroup }>
          {
            schedule.map((schItem, index) => {
              return (
                <div className={ styles.item } key={ `opdetails-schedule-${index}` }>
                  <div className={ styles.dow }>{ schItem.day_of_week }</div>
                  { displayScheduleItem(schItem) }
                </div>
              )
            })
          }
        </div>
      </div>

      {
        !!specialSchedule && !!specialSchedule.length && (
          <div className={ `${mainStyles.editableSectionRow} ${styles.sectionRow}` }>
            <div className={ mainStyles.editableItemLabel }>Special hours</div>
            <div className={ styles.itemGroup }>
              {
                specialSchedule.map((schItem, index) => {
                  return (
                    <div className={ styles.item } key={ `opdetails-schedule-${index}` }>
                      <div className={ styles.dow }>{ moment(schItem.date).format(DATE_FORMAT) }</div>
                      {
                        schItem.state === 'open' ? (
                          <div>{ `${timeToAmPm(schItem.from)} - ${timeToAmPm(schItem.to)}` }</div>
                        ) : (
                          <div>Closed</div>
                        )
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

Info.propTypes = {
  store: PropTypes.object,
  onEditClick: PropTypes.func
}

Info.defaultProps = {}

export default Info
