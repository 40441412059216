export const types = {
  SET_STORE_FILTER: 'SET_STORE_FILTER',
}
  
export const actions = {
  setStoreFilter(data) {
    return {
      type: types.SET_STORE_FILTER,
      data,
    }
  },
}
  