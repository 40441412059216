// integrations which we are connecting through Fivetran.
export const fivetranProviders = {
  FACEBOOK: 'facebook_ad_account', // <-- being deprecated by Fivetran
  FACEBOOK_ADS: 'facebook_ads',
  GOOGLE: 'adwords',
  GOOGLE_ANALYTICS: 'google_analytics',
  MAILCHIMP: 'mailchimp',
  SHOPIFY: 'shopify',
  SQUARE: 'square',
  KLAVIYO: 'klaviyo',
  INSTAGRAM: 'instagram_business',
  GOOGLE_SHEET: 'google_sheets',
  LIGHTSPEED: 'light_speed_retail',
}

// subtitles for the various modals of integrations. i.e Square, Shopify
export const modalSubtitle = {
  [fivetranProviders.MAILCHIMP]: 'Sync your customer contacts information.',
  [fivetranProviders.FACEBOOK]: 'Sync your Facebook ad account data.',
  [fivetranProviders.FACEBOOK_ADS]: 'Sync your Facebook ad data.',
  [fivetranProviders.INSTAGRAM]: 'Sync your Instagram Business account data.',
  [fivetranProviders.GOOGLE]: 'Sync your Google Ads data.',
  [fivetranProviders.SHOPIFY]: 'Sync your Shopify data.',
  [fivetranProviders.SQUARE]: 'Automatically sync inventory, and pull order and sales information from your Sqaure account.',
  [fivetranProviders.KLAVIYO]: 'Sync your Klaviyo data.',
  [fivetranProviders.GOOGLE_SHEET]: 'Sync your Google sheet data.',
  [fivetranProviders.LIGHTSPEED]: 'Sync your Lightspeed data.'
}
