import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { actions } from '../../../actions/Campaign'
import CampaignUpdate from '../../../components/Campaigns/Create'
import LocalSpinner from '../../../components/LocalSpinner'

const CampaignUpdateContainer = props => {
  const { campaignId } = props.match.params
  const dispatch = useDispatch()
  const campaign = useSelector(state => _.get(state, 'Campaign.campaign.data'))
  const { loading: segmentOptionLoading } = useSelector(state => state.Campaign.segmentOptions)

  useEffect(() => {
    dispatch(actions.updateCampaignClear())
    dispatch(actions.getSegmentOptions(
      {
        statuses: ['processed', 'published'],
        campaignId
      }
    ))
    return () => {
      // clear campaign data when component is unmounted
      dispatch(actions.updateCampaignClear())
    }
  }, [dispatch, campaignId])

  if (!campaign || segmentOptionLoading) {
    return <LocalSpinner />
  }

  return <CampaignUpdate history={ props.history } campaign={ campaign } />
}

CampaignUpdateContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default CampaignUpdateContainer

