import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../../FormComponents'

import './styles.scss'

export default function Option(props) {
  const { logoType, title, info, onCreate } = props.data
  return (
    <>
      <div className='eg-segment-option'>
        <div className='logo-container' >
          <div className={logoType} />
        </div>
        <div className='detail' >
          <div className='name'>
            {title}
          </div>
          <div className='info'>
            {info}
          </div>
        </div>
        <Button text='CREATE' onClick={onCreate} className='create-btn' />
      </div>
    </>
  )
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
}
