import { call, takeEvery, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/User'
import { setShowGoogleConnectModalOff } from '../services/modal'
import { authorizedRequest } from './Auth'
import { storeUserData } from '../services/user'
// import { storePlanToken } from '../services/auth'
import { updateUserInfo, updateUserPassword } from '../api/User'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

const getAuthState = (state) => state.User

function* handleGoogleConnectModalOff({ restaurantUuid }) {
  yield put(actions.putInModalOffList(restaurantUuid))
  const authState = yield select(getAuthState)
  yield call(setShowGoogleConnectModalOff, authState) 
}

function* handleUpdateUserInfo({ data }) {
  try {
    const res = yield call(authorizedRequest, updateUserInfo, data)
    if (res && res.data) {
      yield put(actions.updateUserInfoResolved(res.data))
      yield storeUserData(data)
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Account information has been updated!'
      }))
    } else {
      throw new Error(res)
    }
  } catch(error) {
    yield put(actions.updateUserInfoRejected(error))
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: error.message || error
    }))
  }
}

function* handleUpdateUserPassword({ data }) {
  try {
    const res = yield call(authorizedRequest, updateUserPassword, data)
    if (res && res.data) {
      yield put(actions.updateUserPasswordResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Password has been updated!'
      }))
    } else {
      throw new Error(res)
    }
  } catch(error) {
    console.log(error.message)
    yield put(actions.updateUserPasswordRejected(error))
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: error.message || error
    }))
  }
}

// function* getPlanTokenHandler({ chain }) {
//   const userData = yield select(state => state.User)
//   const data = {
//     email: userData.user.email,
//     sub: userData.user.sub,
//     chainUuid: chain.uuid
//   }
//   try {
//     const res = yield call(authorizedRequest, getPlanToken, data)
//     if (res && res.data) {
//       yield put(actions.getPlanTokenResolved(res.data))
//       storePlanToken(res.data)
//     } else {
//       throw new Error(res)
//     }
//   } catch(error) {
//     yield put(actions.getPlanTokenRejected(error))
//     yield put(EgNotificationActions.ShowNotification({
//       type: EgNotificationTypes.ERROR,
//       message: error.message || error
//     }))
//   }
// }

export default function*() {
  yield takeEvery(types.INITIATE_GOOGLE_CONNECT_MODAL_OFF, handleGoogleConnectModalOff)
  yield takeLatest(types.UPDATE_USER_INFO_INTENT, handleUpdateUserInfo)
  yield takeLatest(types.UPDATE_USER_PASSWORD_INTENT, handleUpdateUserPassword)
  // Next line commented to disable Ironplans for now
  // yield takeLatest(types.GET_PLAN_TOKEN_INTENT, getPlanTokenHandler)
}
