import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class CustomerCard extends React.Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
    title: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    className: '',
    title: 'Contact info'
  }

  render() {
    const customer = this.props.customer

    return (
      <div className={ `${styles.container} ${this.props.className}` }>
        { this.props.title && <div className={ styles.title }>{ this.props.title }</div> }
        <div className={ styles.sectionTitle }>Emails</div>
        <div className={ styles.sectionContent }>
          {
            customer.emails && customer.emails.length ? (
              customer.emails.map((email, i) => {
                return (
                  <div key={`email-${i}`}>
                    <a href={`mailto:${email}`}>{email}</a>
                  </div>
                )
              })
            ) : 'N/A'
          }
        </div>
        <div className={ styles.itemSection }>
          <div className={ styles.sectionTitle }>PHONE NUMBERS</div>
          <div className={ styles.sectionContent }>
            {
              customer.phones && customer.phones.length ? (
                customer.phones.map((phone, i) => {
                  return (
                    <div key={ `phone-${i}` }>{phone}</div>
                  )
                })
              ) : 'N/A'
            }
          </div>
        </div>
        <div className={ styles.itemSection }>
          <div className={ styles.sectionTitle }>SAVED ADDRESSES</div>
          <div className={ styles.sectionContent }>
            {
              customer.addresses && customer.addresses.length ? (
                customer.addresses.map((address, i) => {
                  return (
                    <div className={styles.address} key={ `address-${i}` }>{address}</div>
                  )
                })
              ) : 'N/A'
            }
          </div>
        </div>
      </div>
    )
  }
}
