export const types = {
  OAUTH_AUTHORIZE: 'OAUTH_AUTHORIZE',
  OAUTH_AUTHORIZE_REJECTED: 'OAUTH_AUTHORIZE_REJECTED',
  OAUTH_CUSTOMER_CONNECT_INTENT: 'OAUTH_CUSTOMER_CONNECT_INTENT',
  OAUTH_CONNECT_INTENT: 'OAUTH_CONNECT_INTENT',
  OAUTH_CONNECT_INTENT_RESOLVED: 'OAUTH_CONNECT_INTENT_RESOLVED',
  OAUTH_CONNECT_INTENT_REJECTED: 'OAUTH_CONNECT_INTENT_REJECTED',
  OAUTH_ACTIVE_CONNECTIONS_INTENT: 'OAUTH_ACTIVE_CONNECTIONS_INTENT',
  OAUTH_ACTIVE_CONNECTIONS_INTENT_RESOLVED: 'OAUTH_ACTIVE_CONNECTIONS_INTENT_RESOLVED',
  OAUTH_ACTIVE_CONNECTIONS_INTENT_REJECTED: 'OAUTH_ACTIVE_CONNECTIONS_INTENT_REJECTED',

  FIVETRAN_CONNECT_INTENT: 'FIVETRAN_CONNECT_INTENT',
  FIVETRAN_CONNECT_INTENT_RESOLVED: 'FIVETRAN_CONNECT_INTENT_RESOLVED',
  FIVETRAN_CONNECT_INTENT_REJECTED: 'FIVETRAN_CONNECT_INTENT_REJECTED',

  FIVETRAN_CONFIRM_INTENT: 'FIVETRAN_CONFIRM_INTENT',
  FIVETRAN_CONFIRM_INTENT_RESOLVED: 'FIVETRAN_CONFIRM_INTENT_RESOLVED',
  FIVETRAN_CONFIRM_INTENT_REJECTED: 'FIVETRAN_CONFIRM_INTENT_REJECTED',

  FIVETRAN_CONNECTIONS_INTENT: 'FIVETRAN_CONNECTIONS_INTENT',
  FIVETRAN_CONNECTIONS_INTENT_RESOLVED: 'FIVETRAN_CONNECTIONS_INTENT_RESOLVED',
  FIVETRAN_CONNECTIONS_INTENT_REJECTED: 'FIVETRAN_CONNECTIONS_INTENT_REJECTED',

  TOAST_CRED_INTENT: 'TOAST_CRED_INTENT',
  TOAST_CRED_INTENT_RESOLVED: 'TOAST_CRED_INTENT_RESOLVED',
  TOAST_CRED_INTENT_REJECTED: 'TOAST_CRED_INTENT_REJECTED',

}

export const actions = {
  authorize(opts) {
    return {
      type: types.OAUTH_AUTHORIZE,
      opts
    }
  },

  authorizeRejected(error) {
    return {
      type: types.OAUTH_AUTHORIZE_REJECTED,
      error
    }
  },

  /**
   * NOTE: later, this action will be deprecated in
   * favor of `customerConnect()` defined below
  */
  connect(opts) {
    return {
      type: types.OAUTH_CONNECT_INTENT,
      opts
    }
  },

  customerConnect(opts) {
    return {
      type: types.OAUTH_CUSTOMER_CONNECT_INTENT,
      opts
    }
  },

  oauthConnectResolved() {
    return {
      type: types.OAUTH_CONNECT_INTENT_RESOLVED,
    }
  },

  oauthConnectRejected(error) {
    return {
      type: types.OAUTH_CONNECT_INTENT_REJECTED,
      error
    }
  },


  fivetranConnect(opts) {
    return {
      type: types.FIVETRAN_CONNECT_INTENT,
      opts
    }
  },

  fivetranConnectResolved() {
    return {
      type: types.FIVETRAN_CONNECT_INTENT_RESOLVED,
    }
  },

  fivetranConnectRejected(error) {
    return {
      type: types.FIVETRAN_CONNECT_INTENT_REJECTED,
      error
    }
  },

  fivetranConfirm(opts) {
    return {
      type: types.FIVETRAN_CONFIRM_INTENT,
      opts
    }
  },

  fivetranConfirmResolved() {
    return {
      type: types.FIVETRAN_CONFIRM_INTENT_RESOLVED,
    }
  },

  fivetranConfirmRejected(error) {
    return {
      type: types.FIVETRAN_CONFIRM_INTENT_REJECTED,
      error
    }
  },

  getFivetranConnections(opts) {
    return {
      type: types.FIVETRAN_CONNECTIONS_INTENT,
      opts
    }
  },

  getFivetranConnectionsResolved(data) {
    return {
      type: types.FIVETRAN_CONNECTIONS_INTENT_RESOLVED,
      data
    }
  },

  getFivetranConnectionsRejected(error) {
    return {
      type: types.FIVETRAN_CONNECTIONS_INTENT_REJECTED,
      error
    }
  },

  getActiveConnections() {
    return {
      type: types.OAUTH_ACTIVE_CONNECTIONS_INTENT
    }
  },

  getActiveConnectionsResolved(data) {
    return {
      type: types.OAUTH_ACTIVE_CONNECTIONS_INTENT_RESOLVED,
      data
    }
  },

  getActiveConnectionsRejected(error) {
    return {
      type: types.OAUTH_ACTIVE_CONNECTIONS_INTENT_REJECTED,
      error
    }
  },

  sendToastCredIntent(opts) {
    return {
      type: types.TOAST_CRED_INTENT,
      opts
    }
  },

  sendToastCredIntentResolved(data) {
    return {
      type: types.TOAST_CRED_INTENT_RESOLVED,
      data
    }
  },

  sendToastCredIntentRejected(error) {
    return {
      type: types.TOAST_CRED_INTENT_REJECTED,
      error
    }
  }

}
