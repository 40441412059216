import moment from 'moment'

/**
 * Common HTTP Methods used
 */
export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
  PATCH: 'PATCH'
}

/**
 * Generate the headers needed for the requests
 * @param {String} token - Token used on Authentication
 */
export const HeaderFactory = (tokens) => {
  const headers = new Headers()
  if (!tokens) {
    return headers
  }
  const idToken = tokens.idToken.jwtToken
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${idToken}`,
    'x-api-key': process.env.REACT_APP_ONBOARDING_API_KEY
  }
}

/**
 * Handle Gracefully an HTTP Error
 * @param {Error} error - Fetch Error
 */
export function* ErrorHandler(error) {
  //eslint-disable-next-line no-console
  console.error(error)
  const genericResponse = { message: 'Something Went Wrong, try again later' }
  if (error && error.json) {
    try {
      const data = yield error.json()
      return data
    } catch (e) {
      return genericResponse
    }
  } else return genericResponse
}


/**
 * A query builder for multiple store ids
 */
export const constructStoreQuery = (storeInfo) => {
  const { restaurantChainUuid, selectedStores = [], }  = storeInfo
  if(selectedStores.length === 0) {
    //No store is selected, sending chain uuid to get all the orders
    return [`chain_uuid=${restaurantChainUuid}`]
  } else {
    return selectedStores.map((store, index) => `restaurant_uuids[${index}]=${store.uuid}`)
  }
}

/**
 * Constructs api query values for user applied filters, i.e time, rating
 * @param {*} opts 
 */
export const constructFilterQueryParams = (opts) => {
  const qparams = []
  if (opts.sort) {
    qparams.push(`order[0][0]=${opts.sort.key}&order[0][1]=${opts.sort.direction}`)
  }
  if (opts.page) {
    qparams.push(`page=${opts.page}`)
  }
  if (opts.size) {
    qparams.push(`size=${opts.size}`)
  }
  if (opts.search) {
    qparams.push(`search=${opts.search}`)
  }
  if (opts.timeRange) {
    qparams.push(`time_range=${opts.timeRange}`)
  }
  if (opts.filters) {
    const res = {}
    Object.keys(opts.filters).forEach(k => { res[k] = opts.filters[k].filterKeys })
    if (res.date && res.date.length) {
      const dateFrom= moment(res.date[0]).format('YYYY-MM-DD')
      const dateTo= moment().format('YYYY-MM-DD')
      qparams.push(`date_from=${dateFrom}`)
      qparams.push(`date_to=${dateTo}`)
    }

    if (res.source && res.source.length) {
      for (let i = 0; i < res.source.length; i++) {
        qparams.push(`source[${i}]=${res.source[i]}`)
      }
    }

    if (res.rating && res.rating.length) {
      for (let i = 0; i < res.rating.length; i++) {
        qparams.push(`rating[${i}]=${res.rating[i]}`)
      }
    }

    if (res.status && res.status.length) {
      for (let i = 0; i < res.status.length; i++) {
        qparams.push(`status[${i}]=${res.status[i]}`)
      }
    }
  }
  return qparams
}
