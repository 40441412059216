import {
  takeLatest,
  put,
  call,
  select,
  delay
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { types, actions } from '../actions/Segment'
import { authorizedRequest } from './Auth'
import { NetworkError } from '../services/error'
import { mapCampaigns } from '../services/campaigns'
import { mapJourneys } from '../services/journeys'
import {
  createSegment,
  getSegmentList,
  upsertDynamicSegment,
  getDynamicSegment
} from '../api/Segment'
import {
  getCampaignList
} from '../api/Campaign'
import {
  getJourneyList
} from '../api/Journeys'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import {
  mapSegments,
  mapDynamicSegmentIn
} from '../services/segments'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import { PATHS } from '../constants'

function* createSegmentHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, createSegment, {
      chainUuid: chain.restaurant_chain_uuid,
      ...opts.data
    })
    if (res && res.data) {
      yield put(actions.createSegmentResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Manual Segment successfully created'
      }))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.createSegmentRejected(error))
  }
}

function* getSegmentListHandler(opts) {
  const { page = 1, size = 10 } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getSegmentList, {
      page,
      size,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedSegmentList = { ...mapSegments(res.data, chain), page, size}
      yield put(actions.getSegmentsResolved(mappedSegmentList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getSegmentsRejected(error))
  }
}


function* getCampaignListHandler(opts) {
  const { status } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getCampaignList, {
      status,
      size: 999,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedCampaignList = { ...mapCampaigns(res.data, chain), status }
      yield put(actions.getCampaignsForDynamicSegmentResolved(mappedCampaignList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getCampaignsForDynamicSegmentRejected(error))
  }
}

function* getJourneyListHandler(opts) {
  const { status, trigger } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getJourneyList, {
      status,
      trigger,
      chainUuid: chain.restaurant_chain_uuid,
      size: 999
    })
    if (res && res.data) {
      const mappedJourneyList = { ...mapJourneys(res.data, chain), status, trigger}
      yield put(actions.getJourneysForDynamicSegmentResolved(mappedJourneyList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getJourneysForDynamicSegmentRejected(error))
  }
}


function* upsertDynamicSegmentHandler(opts) {
  const { step } = opts
  const chain = yield select(state => state.User.chain)
  if(step === 'design'){
    const predicates = yield select(({Segment}) => _.get(Segment, 'dynamicSegment.data.payload.predicates'))
    opts.predicates = predicates
  }
  let segmentId = opts.data.id
  if (!segmentId) {
    segmentId = yield select(state => _.get(state, 'Segment.dynamicSegment.segmentId'))
  }
  try {
    const res = yield call(authorizedRequest, upsertDynamicSegment, {
      chainUuid: chain.restaurant_chain_uuid,
      segmentId,
      opts
    })
    if (res && res.data) {
      const mappedResponse = {
        ...res.data,
      }
      yield put(actions.upsertDynamicSegmentResolved(mappedResponse))
      // show success notification
      if(step === 'review'){
        let message = 'Dynamic  Segment successfully created.'
        if(window.location.href.includes('edit-dynamic')) {
          message = 'Dynamic  Segment successfully updated.'
        }
        yield put(EgNotificationActions.ShowNotification({
          type: EgNotificationTypes.SUCCESS,
          message
        }))
        yield delay(2000)
        yield put(push(PATHS.SEGMENTS))
      }
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.upsertDynamicSegmentRejected(error))
  }
}

function* getDynamicSegmentHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getDynamicSegment, {
      id: opts.id,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      yield put(actions.getDynamicSegmentResolved(mapDynamicSegmentIn(res.data)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getDynamicSegmentRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.CREATE_SEGMENT_INTENT, createSegmentHandler)
  yield takeLatest(types.GET_SEGMENTS_INTENT, getSegmentListHandler)
  yield takeLatest(types.GET_SEGMENT_CAMPAIGNS_INTENT, getCampaignListHandler)
  yield takeLatest(types.GET_SEGMENT_JOURNEYS_INTENT, getJourneyListHandler)
  yield takeLatest(types.UPSERT_DYNAMIC_SEGMENT_INTENT, upsertDynamicSegmentHandler)
  yield takeLatest(types.GET_DYNAMIC_SEGMENT_INTENT, getDynamicSegmentHandler)
}
