import Modal from './Modal'
import { connect } from 'react-redux'
import { actions } from '../../actions/Modal'

const mapStateToProps = state => ({
  active: state.Modal.active,
})

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(actions.closeModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
