import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import IntegrationCard from '../../Store/StoreIntegration/IntegrationCard'
import GoogleMyBusinessConnect from './GoogleMyBusinessConnect'
import './styles.scss'

const GoogleMyBusinessIntegration = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(GoogleMyBusinessConnect)
    }
  }

  const isConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  return (
    <div className='eg-gBusiness-integration'>
      <IntegrationCard
        title='Google My Business'
        subtitle='Connect reviews and company details'
        logoClassName='gbusiness'
        isConnected={ isConnected('google_business') }
        onClick={ handleClick }
      />
    </div>
  )
}

GoogleMyBusinessIntegration.propTypes = {
  onClick: PropTypes.func
}

export default GoogleMyBusinessIntegration
