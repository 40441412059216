import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import Loader from '../../../components/LocalSpinner'
import BackLink from '../../../components/BackLink'
import CustomerHeader from '../../../components/CustomerHeader'
import Stat from '../../../components/Campaigns/CampaignStat/Stat'
import CampaignGraph from '../../../components/Campaigns/CampaignStat/CampaignGraph'
import CityStat from '../../../components/Campaigns/CampaignStat/CityStat'
import PlatformStat from '../../../components/Campaigns/CampaignStat/PlatformStat'
import StatusBubble from '../../../components/StatusBubble'
import Button, { types as buttonTypes } from '../../../components/FormComponents/Button'
import { htmlize } from '../../../utils/strings'
import { parsePreview } from '../../../services/campaigns'
import { getTimeFormatted } from '../../../services/dates'
import StatelessModal from '../../../components/StatelessModal'
import PhonePreview, { HeaderSMS } from '../../../components/PhonePreview'
import { actions } from '../../../actions/Campaign'

import {PATHS} from '../../../constants'

import './styles.scss'

const CampaignDashboard = (props) => {
  const { campaignId } = props.match.params
  const dispatch = useDispatch()
  const [ showMessage, setShowMessage ] = useState(false)
  const [chartData, setChartData] = useState(null)
  const chain = useSelector(state => state.User.chain)
  const { loading, data: campaignData } = useSelector(state => state.Campaign.campaign)
  const {
    submitting,
    data,
    error
  } = useSelector((state) => state.Campaign.chart)


  useEffect(() => {
    dispatch(actions.getCampaign(campaignId))
    return () => {
      dispatch(actions.getCampaignClear())
      dispatch(actions.getCampaignChartClear())
    }
  }, [campaignId, dispatch])

  useEffect(() => {
    if(campaignData && campaignData.id) {
      dispatch(actions.getCampaignChart(campaignData))
    }
  }, [campaignData, dispatch])

  const trackCampaignBackClick = () => {

  }

  const onViewMessage = () => {
    setShowMessage(true)
  }

  const onMessageModalClose = () => {
    setShowMessage(false)
  }

  useEffect(() => {
    if(data && data.items.length) {
      setChartData(data)
    }
  }, [data])

  if(loading) {
    return (
      <div className='eg-campaign-dashboard'>
        <Loader className='loader'/>
      </div>
    )
  }

  if(!campaignData) {
    return null
  }

  const previewSmsImage = _.get(campaignData, 'mms.imageUrl')

  return (
    <div className='eg-campaign-dashboard'>
      <CustomerHeader />
      <BackLink to={`${PATHS.SMS_CAMPAIGNS}?tab=delivered`} onClick={trackCampaignBackClick}>Campaigns</BackLink>
      <div className='content'>
        <div className='top-row'>
          <div className='left-part'>
            <div className='title'>
              {campaignData.campaign_name}
            </div>
            <StatusBubble label={ 'DELIVERED' } status={ 'active' } className='status-bbl' />
          </div>
          <div className='right-part'>
            <Button
              className='action-button'
              text='View Message'
              type={ buttonTypes.SECONDARY }
              onClick={ onViewMessage }
            />
          </div>
        </div>
        <div className='time'>
          {/* TODO: add | Segment name */}
          Sent {getTimeFormatted(campaignData.schedule_date, 'ddd, MMM DD, YYYY h:mm A')}
        </div>
        <Stat className='stat-row' data={campaignData}/>
        {
          submitting
            ? <Loader className='chart-loader'/>
            : <CampaignGraph
              title='24-Hour Engagement'
              chartAbout='Number Of Clicks'
              className='graph'
              data={chartData}
              error={error}
            />
        }
        <div className='eg-city-platform-stat'>
          <CityStat campaign={campaignData} />
          <PlatformStat campaign={campaignData} />
        </div>
        { showMessage &&
          <StatelessModal className='campaign-message-modal' onClose={onMessageModalClose}>
            <div className='eg-campaign-message'>
              <div className='title'>Message Preview</div>
              <div className='content'>
                <div className='preview'>
                  <PhonePreview className='phone'>
                    <HeaderSMS title={chain.name} />
                    <div className='container'>
                      {
                        !!previewSmsImage && (
                          <div className='message-wrapper-img' data-testid='sms-preview-message-wrapper'>
                            <img src={ previewSmsImage } alt='mms' width={ 135 } />
                          </div>
                        )
                      }
                      <div className='message-wrapper' data-testid='sms-preview-message-wrapper'>
                        <p dangerouslySetInnerHTML={
                          { __html: htmlize(parsePreview(campaignData.originalSms, chain)) }
                        } />
                      </div>
                    </div>
                  </PhonePreview>
                </div>
              </div>
            </div>
          </StatelessModal>
        }
      </div>
    </div>
  )
}

CampaignDashboard.propTypes = {
  match: PropTypes.object.isRequired,
}
export default CampaignDashboard
