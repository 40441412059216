import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { actions as journeyActions } from '../../../actions/Journey'
import { statuses } from '../../../services/journeys'

import CustomerHeader from '../../../components/CustomerHeader'
import BackLink from '../../../components/BackLink'
import LocalSpinner from '../../../components/LocalSpinner'
import ReviewJourney from '../../../components/Journeys/Create/Review'
import ConfirmationModal from '../../../components/ConfirmationModal'
import SuccessMessage from '../../../components/Journeys/SuccessMessage'
import { PATHS } from '../../../constants'

import './styles.scss'

const JourneyViewContainer = props => {
  const { journeyId } = props.match.params
  const { history } = props
  const dispatch = useDispatch()
  const journey = useSelector(state => _.get(state, 'Journey.journey.data'))
  const {
    submitting: submittingStateChange,
    success: stateChangeSuccess
  } = useSelector(state => state.Journey.updateStatus)
  const [readyToSend, setReadyToSend] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [stepToEdit, setStepToEdit] = useState(null)

  useEffect(() => {
    return (() => {
      dispatch(journeyActions.clear())
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(journeyActions.getJourney(journeyId))
  }, [dispatch, journeyId])

  useEffect(() => {
    if (stateChangeSuccess) {
      setReadyToSend(false)
      setShowSuccess(true)
    }
  }, [stateChangeSuccess])

  const handleSubmit = () => {
    setReadyToSend(true)
  }

  const handleActivate = () => {
    dispatch(journeyActions.setJourneyState({
      journeyId,
      state: journey.status === statuses.ACTIVE ? statuses.INACTIVE : statuses.ACTIVE
    }))
  }

  const handleSuccessClose = () => {
    setShowSuccess(false)
    history.replace(PATHS.SMS_JOURNEYS)
  }

  const handleStepEdit = step => {
    setStepToEdit(step)
  }

  const handleStepEdition = () => {
    history.push(`${PATHS.SMS_JOURNEYS}/edit/${journey.id}?step=${stepToEdit}`)
  }

  if (!journey) {
    return <LocalSpinner />
  }

  return (
    <div className='eg-journey-view'>
      <CustomerHeader />
      <BackLink to={PATHS.SMS_JOURNEYS}>Journeys</BackLink>
      <div className='body'>
        <ReviewJourney
          data={ journey }
          editable={ false }
          displayStatus
          hideDeactivateButton={true}
          onEditTriggerClick={ () => handleStepEdit(1) }
          onEditMessageClick={ () => handleStepEdit(2) }
          onSubmit={ handleSubmit }
        />
      </div>
      {
        readyToSend && (
          <ConfirmationModal
            title={ `${journey.status === statuses.ACTIVE ? 'Deactivate' : 'Activate'} journey?` }
            text='This will take effect immediately'
            loading={ submittingStateChange }
            onCancel={ () => setReadyToSend(false) }
            onConfirm={ handleActivate }
          />
        )
      }
      {
        !!stepToEdit && (
          <ConfirmationModal
            title='Edit journey?'
            text='Messages in progress can be affected as well as the stats.'
            confirmButtonLabel='EDIT'
            onCancel={ () => setStepToEdit(null) }
            onConfirm={ handleStepEdition }
          />
        )
      }
      { showSuccess && <SuccessMessage data={ journey } onClose={ handleSuccessClose } />}
    </div>
  )
}

JourneyViewContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default JourneyViewContainer
