import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from '../../../../FormComponents'
import './styles.scss'

const LastInteractionFilterContent = props => {
  const handleItemClick = item => {
    if (props.onChange) {
      props.onChange(item, !item.value)
    }
  }

  const renderItem = item => {
    return (
      <div className='item' key={ item.key } onClick={ () => handleItemClick(item) }>
        <Checkbox checked={ item.value } />
        <div className={ `label ${item.key}` }>{ item.label }</div>
      </div>
    )
  }

  return (
    <div className='eg-last-interaction-filter-content'>
      { props.items.map(item => (renderItem(item))) }
    </div>
  )
}

LastInteractionFilterContent.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func
}

export default LastInteractionFilterContent
