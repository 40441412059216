export const reviewSources = {
  GOOGLE: 'google',
  YELP: 'yelp',
  FACEBOOK: 'facebook',
  TRIP_ADVISOR: 'tripadvisor',
  ZEROSTOREFRONT: 'zerostorefront'
}

export const reviewShakeKeys = {
  google_my_business: 'google_my_business',
  reviewshake_google : 'google',
  reviewshake_yelp: 'yelp',
  reviewshake_facebook: 'facebook',
  reviewshake_tripadvisor: 'tripadvisor',
  zerostorefront: 'zerostorefront'
}


export const getReviewSource = review => {
  let reviewSource
  const states = {
    [reviewSources.GOOGLE]: [ 'google', 'reviewshake_google', 'google_my_business' ],
    [reviewSources.YELP]: [ 'yelp', 'reviewshake_yelp' ],
    [reviewSources.FACEBOOK]: [ 'facebook', 'reviewshake_facebook'],
    [reviewSources.TRIP_ADVISOR]: [ 'tripadvisor', 'reviewshake_tripadvisor'],
    [reviewSources.ZEROSTOREFRONT]: ['zerostorefront'],
  }

  Object.keys(states).forEach(state => {
    if (states[state].includes(review.type)) {
      reviewSource = state
    }
  })

  return reviewSource
}

/**
 * map backend response to an object that can be easly handled by
 * the related components
 *
 * @param {object} review - backend response
 * @returns {object} mapped output
*/
export const mapReview = (review) => {
  const isGMBReview = !review.type.includes('reviewshake')
  return {
    source: getReviewSource(review),
    date: review.comment_date,
    location: review.location,
    name: review.name ? review.name.replace(/\.$/, '') : '',
    rating: review.rating,
    comment: review.text,
    url: review.url,
    profilePicture: review.profile_picture,
    response: review.response,
    storeUuid: review.restaurant_uuid,
    reviewId: review.comment_unique_id,
    googleCommentId: review.comment_id,
    isGMBReview
  }
}

/**
 * map backend response to an object that can be easly handled by
 * the related components
 *
 * @param {object} reviewsInput - backend response
 * @returns {object} mapped output
*/
export const mapReviewList = (reviewsInput, opts) => {
  return {
    page: opts.page,
    size: opts.size,
    sort: opts.sort,
    total: reviewsInput.total,
    reviews: reviewsInput.reviews.map(review => {
      const isGMBReview = !review.type.includes('reviewshake')
      return {
        source: getReviewSource(review),
        date: review.comment_date,
        location: review.location,
        name: review.name ? review.name.replace(/\.$/, '') : '',
        rating: review.rating,
        comment: review.text,
        url: review.url,
        profilePicture: review.profile_picture,
        response: review.response,
        storeUuid: review.restaurant_uuid,
        reviewId: review.comment_unique_id,
        googleCommentId: review.comment_id,
        isGMBReview
      }
    })
  }
}

/**
 * map backend response to our implementation
 *
 * @param {object} reviewsInput - backend response
 * @returns {object} mapped output
*/
export const mapRateSummary = ratingInput => {
  const sources = {}
  Object.keys(ratingInput.sources).forEach(source => {
    sources[reviewShakeKeys[source]] = {}
    sources[reviewShakeKeys[source]].rate = ratingInput.sources[source].rating
    sources[reviewShakeKeys[source]].rate_count = ratingInput.sources[source].rate_count
  })
  return {
    interceptedCount: ratingInput.intercepted_count,
    promotedCount: ratingInput.promoted_count,
    requestedCount: ratingInput.requested_count,
    average: ratingInput.average,
    total: ratingInput.total,
    sources
  }
}

/**
 * map backend response to compatible with lineChart(which uses d3)
 * @param {object} data - backend response
 * @returns {object} mapped output
 * i.e. [{"date":"2020-01-08","Google":3,"Yelp":4,"Facebook":3,"Tripadvisor":1}, ..]
*/
export const mapChartData = (data = []) => {
  // let's build a lookup/dictionary and in which keys will be a date
  // and the value will be a array of all the average rating avialable on that date.
  const dateAndReviewsArray = createDateAndReviewArray(data)
  const mappedStoreData =  dateAndReviewsArray.map((dayData) => {
    return {
      'google': null,
      'yelp': null,
      'facebook': null,
      'tripadvisor': null,
      ...dayData
    }
  })
  return mappedStoreData
}

/**
 * construcs an array with date and review combined in a single object.
 * @param {*} data 
 * @returns {Array} dateAndReviwsArray [{"date":"2020-01-08","google":3,"yelp":4,"facebook":3,"tripadvisor":1}, ...]
 */
const createDateAndReviewArray = data => {
  const dateAndReviewsArray = data.map((review) => {
    const sources = review.sources
    const reviews = Object.keys(sources).reduce((sum, source) => {
      sum[source] = sources[source].rating
      return sum
    }, {})
    return {
      date: review.comment_date,
      ...reviews
    }
  })
  return dateAndReviewsArray
}


/**
 * map backend response to our implementation for review according to store
 *
 * @param {object} data - backend response
 * @returns {object} mapped output
*/
export const mapStoreBreakDownData = (data) => {
  const stores = data.store_breakdown || {}
  const mappedStoreData =  stores.map((store) => {
    return {
      storeUuid: store.restaurant_uuid,
      storeName: store.restaurant_name,
      averageRating: store.rating,
      rateCount: store.rate_count,
    }
  })
  return {
    storeBreakdown: mappedStoreData,
    summary: {
      rateCount: data.summary.rate_count,
      rating: data.summary.rating
    }
  }
}

/**
 * map sorting keys to values that the BE understands
 *
 * @param {object} sort - sort object
 * @param {string} sort.key - field to be sorted
 * @param {string} sort.direction - sort direction ('ASC' or 'DESC')
*/
export const mapSortApiParams = sort => {
  const mapper = {
    source: 'type',
    date: 'comment_date',
    name: 'name',
    location: 'location',
    rating: 'rating'
  }

  return {
    key: mapper[sort.key],
    direction: sort.direction
  }
}
