import { call, takeLatest, select, put } from 'redux-saga/effects'
import moment from 'moment'
import get from 'lodash/get'

import { types } from '../actions/Review'
import {
  getRatingsSummary,
  getChartData,
  getStoreBreakdown,
  getReviewByid,
  sendReviewReply,
  getReviews,
  getReviewCsvUrl, 
} from '../api/Review'
import { authorizedRequest } from './Auth'
import {
  mapReview,
  mapReviewList,
  mapRateSummary,
  mapChartData,
  mapStoreBreakDownData,
  mapSortApiParams
} from '../services/reviews'
import { actions as reviewActions} from '../actions/Review'
import { actions as spinnerActions} from '../actions/Spinner'
import EGAnalytic, { analyticEvents } from '../services/analytics'

import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

// import mockData from '../../src/components/LineChart/MOCK_DATA.json'


/**
 * get rating summary info
*/
function* handleGetRatingsSummary({ opts }) {
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const response = yield call(authorizedRequest, getRatingsSummary, {
      restaurantChainUuid,
      selectedStores,
      ...opts
    })
    yield put(reviewActions.getRatingsSummaryResolved(
      mapRateSummary(response.summary)
    )) 
    yield put(spinnerActions.loadingResolved())
  }
  catch (error) {
    yield put(reviewActions.getRatingsSummaryRejected(error)) 
  }
}

/**
 * get reviews for chart/graph
*/
function* handleGetChartData({ opts }) {
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const response = yield call(authorizedRequest, getChartData, {
      restaurantChainUuid,
      selectedStores,
      ...opts
    })
    if(response && response.data){
      const mappedChartData = mapChartData(response.data)
      yield put(reviewActions.getChartDataResolved(mappedChartData))
    } else {
      throw new Error(response)
    }
  }
  catch (error) {
    yield put(reviewActions.getChartDataRejected(error)) 
  }
}

function* handleStoreBreakdown({ opts }) {
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const response = yield call(authorizedRequest, getStoreBreakdown, {
      restaurantChainUuid,
      selectedStores,
      ...opts,
    })
    const mappedData = mapStoreBreakDownData(response.data)
    yield put(reviewActions.getStoreBreakDownResolved(mappedData)) 
  }
  catch (error) {
    yield put(reviewActions.getStoreBreakDownRejected(error)) 
  }
}


/**
 * get a single review data
*/
function* handleGetReview({ opts }) {
  try {
    const { restaurantUuid, restaurantChainUuid } = yield select(state => {
      if (state.User.chain) {
        return {
          restaurantId: state.User.chain.restaurant_id,
          restaurantUuid: state.User.chain.uuid,
          restaurantChainUuid: state.User.chain.restaurant_chain_uuid,
        }
      }
    })

    const payload = {
      restaurantUuid,
      restaurantChainUuid,
      ...opts
    }

    const response = yield call(authorizedRequest, getReviewByid, payload)
    yield put(reviewActions.getReviewResolved(mapReview(response.data.review))) 
  }
  catch (error) {
    const restaurant = yield select(state => state.User.chain)
    EGAnalytic.track(analyticEvents.GENERAL_WARNING, {
      ...EGAnalytic.mapRestaurant(restaurant),
      warningMessage: `Not able to get review by id, response from backend: ${JSON.stringify(error, null, 2)}`
    })
    yield put(reviewActions.getReviewRejected(error)) 
  }
}

/**
 * sends reply to a review
*/
function* handleReviewReply({ opts }) {
  try {
    const {uuid : restaurantUuid, restaurant_chain_uuid: restaurantChainUuid  }  = yield select(({ User }) => User.chain ) || {}
    const payload = {
      restaurantUuid,
      restaurantChainUuid,
      ...opts
    }

    const response = yield call(authorizedRequest, sendReviewReply, payload)
    yield put(reviewActions.sendReplyResolved(response))
    yield updateReviewRespondStatus(opts)
  }
  catch (error) {
    const restaurant = yield select(state => state.User.chain)
    EGAnalytic.track(analyticEvents.GENERAL_WARNING, {
      ...EGAnalytic.mapRestaurant(restaurant),
      warningMessage: `Not able to send reply to a review: ${JSON.stringify(error, null, 2)}`
    })
    yield put(reviewActions.sendReplyRejected(error)) 
  }
}

/**
 * 
 * @param {*} Update review or review list after responding to google review. 
 */
function* updateReviewRespondStatus(opts) {
  const { reviewId, replyText } = opts
  yield put(reviewActions.sendReplyResolved({googleCommentId: reviewId}))
  const reviews = yield select(state => state.Review)
  // if we retrieve a review from reviewId from the url, otherwise we get the review from the review list.
  const singleReviewData = get(reviews, 'review.data')
  if(singleReviewData && get(singleReviewData, 'googleCommentId') === reviewId) {
    singleReviewData.response = { comment: replyText, date: moment()}
    yield put(reviewActions.getReviewResolved(singleReviewData))
  }
  //update the review in review list if it exist.
  const reviewList = reviews.reviewList
  const reviewsListArray = reviewList.reviews.map((review) => {
    if(get(review, 'googleCommentId') === reviewId) {
      review.response = { comment: replyText, date: moment()}
    }
    return {...review}
  })
  reviewList.reviews = reviewsListArray
  yield put(reviewActions.getReviewsResolved(...reviewList))
}

/**
 * get reviews list
*/
function* handleGetReviews({ opts }) {
  try {
    const { restaurantUuid, restaurantChainUuid } = yield select(state => {
      if (state.User.chain) {
        return {
          restaurantId: state.User.chain.restaurant_id,
          restaurantUuid: state.User.chain.uuid,
          restaurantChainUuid: state.User.chain.restaurant_chain_uuid,
        }
      }
    })
    const { selectedStores } = yield select(({ Filter }) => Filter)

    const payload = {
      restaurantUuid,
      restaurantChainUuid,
      selectedStores,
      ...opts
    }

    if (opts.sort) {
      payload.sort = mapSortApiParams({ ...opts.sort })
    }
    const response = yield call(authorizedRequest, getReviews, payload)
    yield put(reviewActions.getReviewsResolved({
      ...mapReviewList(response.data, opts)
    })) 
  }
  catch (error) {
    const restaurant = yield select(state => state.User.chain)
    EGAnalytic.track(analyticEvents.GENERAL_WARNING, {
      ...EGAnalytic.mapRestaurant(restaurant),
      warningMessage: `Not able to get reviews, response from backend: ${JSON.stringify(error, null, 2)}`
    })
    yield put(reviewActions.getReviewsRejected(error)) 
  }
}


/**
 * get signed url to download reviews in a csv file
*/
function* handleGetExportReview({ opts }) {
  yield put(spinnerActions.loading())
  try {
    const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const response = yield call(authorizedRequest, getReviewCsvUrl, {
      restaurantChainUuid,
      selectedStores,
      ...opts
    })
    if(response && response.status === 200) {
      const url = get(response, 'data.url')
      window.open(url, '_blank')
    } else {
      throw new Error(response)
    }
  }
  catch (error) {
    console.error('Failed to export csv file for reviews.', error)
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: 'We are Sorry. Something went wrong. Could you please try again?'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
  }
  yield put(spinnerActions.loadingResolved())
}


export default function* () {
  yield takeLatest(types.GET_RATINGS_SUMMARY_INTENT, handleGetRatingsSummary)
  yield takeLatest(types.GET_CHART_DATA_INTENT, handleGetChartData)
  yield takeLatest(types.GET_STORE_BREAKDOWN_INTENT, handleStoreBreakdown)
  yield takeLatest(types.GET_REVIEW_INTENT, handleGetReview)
  yield takeLatest(types.SEND_REPLY_INTENT, handleReviewReply)
  yield takeLatest(types.GET_REVIEWS_INTENT, handleGetReviews)
  yield takeLatest(types.GET_EXPORT_REVIEWS_INTENT, handleGetExportReview)
}
