import React from 'react'
import PropTypes from 'prop-types'

import { GEO_LOCATION_ERROR_CODES } from './constants'
// import { types } from '../../Notification/Notification'

import styles from './index.module.scss'
import './google-address-styles.scss'

const LOADING_MSG = 'Looking for current location…'
const MANUAL_INPUT_MSG = 'Please try manually inputting your address.'
const ADDRESS_NOT_DETECTED_ERROR_MSG = `Sorry, your current location can't be detected. ${MANUAL_INPUT_MSG}`
const LOCATION_ACCESS_DENIED_ERROR_MSG = `You have denied the access to use your current location. ${MANUAL_INPUT_MSG}`

/**
 * Verifies if the given Google Place objects are equals.
 * @param {GooglePlace} add1 - Google place object containing the address information
 * @param {GooglePlace} add2 - Google place object containing the address information
 *
 * @returns {bool} true if addresses are equal
 */
const addressesAreEqual = (add1, add2) =>
  (!add1 && !add2) ||
  (add1 && add2 && add1.formatted_address === add2.formatted_address)



/*
 * Google Address Text Input - React Component. Allows the user to select a suggested address based on the input string.
 * 
 * Props:
 * id {string}                         - The HTML id of the element. Must be unique
 * showLabal {bool}                    - Flag that determines if the input label is shown.
 * label {string}                      - The label displayed above the input. Default: 'Address'. Ignored when showLabel is set to false.
 * name {string}                       - The name of the input
 * placeholder {string}                - The placeholder to be rendered inside the input.
 * disabled {bool}                     - Flag to set the input to disabled. Default false.
 * onChange {function}                 - Function triggered when the input changes. Sent params (GooglePlaceAddress, hasBeenCleaned)
 * onLocationSearchStarted {function}  - Triggered when loading a location.
 * onLocationSearchFinished {function} - Triggered when loading a location.
 * showNotification {function}         - Allows to display a notification toaster.
 * customClass {string}                - Allows to provide a class name for custom styling.
 * address {GooglePlaceObject}         - The value to initialize the component.
 * googleScriptStatus {string}         - Determines the status of the script [ loaded | loading | not_loaded ] for intialization.
 */
export default class GoogleAddressInput extends React.Component {

  constructor(props) {
    super(props)

    const address = this.getAddressState(props.address)

    this.state = {
      ...address,
      predictions: [],
      loadingLocation: false
    }

    // Bindings
    this.findLocation = this.findLocation.bind(this)
    this.removeLoadingState = this.removeLoadingState.bind(this)
    this.geolocate = this.geolocate.bind(this)
    this.setAddress = this.setAddress.bind(this)
    this.cleanAddress = this.cleanAddress.bind(this)
    this.displaySuggestions = this.displaySuggestions.bind(this)
    this.onScriptLoad = this.onScriptLoad.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onLocationSearchStarted: PropTypes.func,
    onLocationSearchFinished: PropTypes.func,
    // showNotification: PropTypes.func.isRequired,
    customClass: PropTypes.string,
    address: PropTypes.any,
    // address: PropTypes.shape({
    //   formatted_address: PropTypes.string.isRequired,
    //   address_components: PropTypes.array.isRequired
    // }),
    // googleScriptStatus: PropTypes.string.isRequired,
    error: PropTypes.string
  }

  static defaultProps = {
    showLabel: true,
    disabled: false,
    label: 'Address',
    placeholder: 'House / unit no, Building name, Street name, City, State, Zipcode',
  }

  componentDidMount() {
    setTimeout(this.onScriptLoad, 2000)
  }

  componentWillUnmount() {
    this.googleMaps && this.googleMaps.event.removeListener(this.autocompleteListener)
    this.googleMaps && this.googleMaps.event.clearInstanceListeners(this.autocomplete)
    const pacContainer = document.querySelector('.pac-container')
    pacContainer && pacContainer.remove()
  }

  componentDidUpdate(prevProps, prevState) {
    // If address was cleand up, focus the input
    if (prevState.selectedAddress && !this.state.selectedAddress) {
      const input = document.getElementById(this.props.id)
      input.focus()
    // If Address has been changed from outside contemplate it and update based on those changes.
    } else if (!addressesAreEqual(prevProps.address, this.props.address)) {
      this.setState(this.getAddressState(this.props.address))
    }
  }

  getAddressState(address) {
    let addressStr
    let selectedAddress
    if (address) {
      if (typeof address === 'string') {
        addressStr = address
      } else {
        addressStr = address.formatted_address
        selectedAddress = address
      }
    } else {
      addressStr = ''
    }

    return {
      address: addressStr,
      selectedAddress
    }
  }

  /**
   * Callback executed when the Google Maps Script is fully loaded.
   */
  onScriptLoad() {
    const addressInput = document.getElementById(this.props.id)
    this.googleMaps = window.google.maps
    this.googleMapsPlaces = this.googleMaps.places
    // Service for manually triggering places' suggestions
    this.googleMapAutocompleteService = new this.googleMapsPlaces.AutocompleteService()

    if (typeof this.props.address === 'string') {
      // this.googleMapAutocompleteService.getQueryPredictions({ input: this.props.address }, this.displaySuggestions)
    }

    // Options for the autocomplete
    const options = {
      // Restrict the types of suggestions
      // types: ['address']
    }
    // Create the autocomplete object
    this.autocomplete = new this.googleMapsPlaces.Autocomplete(addressInput, options)
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocompleteListener = this.autocomplete.addListener('place_changed', this.setAddress)
  }

  /**
   * Tries to find the user's location and sets the address with the obtained value if found.
   */
  findLocation() {
    /* HTML5 Geolocation */
    this.setLoadingState()
    navigator.geolocation.getCurrentPosition(
      // Success callback
      (position) => {

        /* Current Coordinate */
        var lat = position.coords.latitude
        var lng = position.coords.longitude
        var googleMapsPosition = new this.googleMaps.LatLng(lat, lng)

        /* Use Geocoder to get address */
        var googleMapsDecoder = new this.googleMaps.Geocoder()
        googleMapsDecoder.geocode(
          { 'latLng': googleMapsPosition },
          (results, status) => {
            this.removeLoadingState()
            if (status === this.googleMaps.GeocoderStatus.OK && results && results[0]) {
              const place = results[0]
              this.setState({ address: place.formatted_address, selectedAddress: place })
              if (this.props.onChange) this.props.onChange(place)
            } else this.notifyError(ADDRESS_NOT_DETECTED_ERROR_MSG)
          }
        )
      },
      // Fail Callback
      (error) => {
        this.removeLoadingState()
        switch(error.code) {
          case GEO_LOCATION_ERROR_CODES.PERMISSION_DENIED: return this.notifyError(LOCATION_ACCESS_DENIED_ERROR_MSG)
          case GEO_LOCATION_ERROR_CODES.TIMEOUT: // $FALL_THROUGH
          case GEO_LOCATION_ERROR_CODES.POSITION_UNAVAILABLE: // $FALL_THROUGH
          default: return this.notifyError(ADDRESS_NOT_DETECTED_ERROR_MSG)
        }
      }
    )
  }

  /**
   * Sets a loading state for the location search.
   */
  setLoadingState() {
    this.setState({ loadingLocation: true })
    if (this.props.onLocationSearchStarted) this.props.onLocationSearchStarted()
  }

  /**
   * Should be triggered when the location search has been finished.
   */
  removeLoadingState() {
    this.setState({ loadingLocation: false })
    if (this.props.onLocationSearchFinished) this.props.onLocationSearchFinished()
  }

  /**
   * Pops up an error notification toaster.
   * @param {string} errorMessage 
   */
  notifyError(errorMessage) {
    // this.props.showNotification({
    //   type: types.ERROR,
    //   message: errorMessage
    // })
  }

  /**
   * Configures recommendations to prioritize those closer to the user.
   */
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        var circle = new window.google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        })
        this.autocomplete.setBounds(circle.getBounds())
      })
    }
  }

  /**
   * Sets the obtained predictions.
   * @param {array} predictions - Google predictions
   * @param {object} status - Google service status
   */
  displaySuggestions(predictions, status) {
    if (status !== this.googleMapsPlaces.PlacesServiceStatus.OK) {
      this.setState({ address: '' })
      return
    }
    // this.setState({ predictions })

    // TODO use this block as guidance for the custom suggestions.
    // predictions.forEach(function(prediction) {
    //   this.setState({ predictions: prediction })
    //   var li = document.createElement('li');
    //   li.appendChild(document.createTextNode(prediction.description));
    //   document.getElementById('results').appendChild(li);
    // });

  }

  /**
   * Triggered when an Address has been selected from Google Places predictions, sets the state and triggers the provided onChange.
   */
  setAddress() {
    const place = this.autocomplete.getPlace()
    const formattedAddress = place.formatted_address
    // If the formatted_address is missing it means it is an incorrect address.
    if (!formattedAddress) return
    this.setState({ address: formattedAddress, selectedAddress: place })
    if (this.props.onChange) this.props.onChange(place)
  }

  /**
   * Cleans the address state.
   */
  cleanAddress() {
    this.setState({ address: '', selectedAddress: null })
    const addressHasBeenCleaned = true
    this.props.onChange(null, addressHasBeenCleaned)
  }

  /**
   * Updates state with fields information
   *
   * @param event {object} input event
   */
  handleInputChange = (event) => {
    // Remove event from pooling. Ref: https://reactjs.org/docs/events.html#event-pooling
    event.persist()
    const value = event.target.value
    const newState = { address: value }
    if (this.state.selectedAddress && this.state.selectedAddress.formatted_address !== value) {
      newState.selectedAddress = null
      this.props.onChange(null)
    } 
    this.setState(newState)
    
    // new  this.googleMapAutocompleteService.getPlacePredictions({ input: searchInput, type: 'street_address' }, this.displaySuggestions)
  }

  handleFocus() {
    this.setState({ focused: true })
  }

  handleBlur() {
    this.setState({ focused: false })
    if (this.props.onBlur) {
      this.props.onBlur(this.state.selectedAddress)
    }
  }

  render() {
    return (
      <div className={styles.addressInputContainer} >
        {this.props.showLabel ? <div className={styles.label}>{this.props.label}</div> : null}

        <div className={`${styles.iconsInputWrapper} ${this.props.customClass || ''} ${this.props.error ? styles.error : ''} ${this.state.focused ? styles.focus : ''}`}>
          <div
            className={`${styles.icon} ${styles.locationIcon} ${this.state.loadingLocation ? styles.loading : ''}`}
            onClick={this.findLocation}
            title="Get your current location"
          />
          <div className={styles.splitter} />
          <input
            id={this.props.id}
            className={styles.input}
            type="text"
            name={this.props.name}
            placeholder={this.state.loadingLocation ? LOADING_MSG : (this.props.placeholder ? this.props.placeholder : '')}
            value={this.state.address || ''}
            disabled={this.state.loadingLocation || this.props.disabled}
            onChange={this.handleInputChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            // Enable for the feature when user clicks on the location icon.
            // onFocus={this.props.googleScriptStatus === GOOGLE_SCRIPT_STATUSES.LOADED ? this.geolocate : null }
          />
          {this.state.address 
            ? <div
              className={`${styles.icon} ${styles.clearIcon} ${this.props.disabled ? styles.disabled : ''}`}
              title="clear"
              onClick={this.cleanAddress}
            />
            : null}
        </div>
        {this.props.error
          ? <div className={styles.errorMessage}>{this.props.error}</div>
          : null}
      </div>
    )
  }
}
