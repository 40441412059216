import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FilterOptions from '../../../FilterOptions'
import StatusWidget, { statuses } from '../../../StatusWidget'

const defaultActionLabel = 'All statuses'

const SourceFilter = props => {
  const [ selectedItems, setSelectedItems ] = useState([])

  const statusItems = [
    { key: 'pending', content: () => <StatusWidget label='Pending response' status={ statuses.PENDING} /> },
    { key: 'responded', content: () => <StatusWidget label='Responded' status={ statuses.COMPLETED } /> }
  ]

  const handleFilterChange = (items, cb) => {
    setSelectedItems(items)
    let actionLabel = defaultActionLabel
    if (items && items.length && items.length !== statusItems.length) {
      const itemStr = `${items[0][0].toUpperCase()}${items[0].slice(1, items[0].length)}`
      actionLabel = `${itemStr} status`
    }

    if (cb) {
      cb({ filterKeys: items, actionLabel })
    }
  }

  const handleClear = () => {
    setSelectedItems([])
    if (props.onChange) {
      props.onClear({
        filterKeys: [],
        actionLabel: defaultActionLabel
      })
    }
  }

  // update internal state when prop changes
  useEffect(() => {
    setSelectedItems(props.filter.filterKeys)
  }, [props.filter.filterKeys])

  return (
    <div>
      <FilterOptions
        actionLabel={ props.actionLabel }
        onChange={ (items) => handleFilterChange(items, props.onChange) }
        onApply={ (items) => handleFilterChange(items, props.onApply) }
        onClear={ handleClear }
        items={ statusItems }
        selectedItems={ selectedItems }
        multiple
      />
    </div>
  )
}

SourceFilter.propTypes = {
  filter: PropTypes.object,
  actionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

SourceFilter.defaultProps = {
  actionLabel: defaultActionLabel
}

export default SourceFilter
