import moment from 'moment'
import _ from 'lodash'

export const segmentTypes = {
  ALL_SEGMENT_TYPE: 'all_segment_type',
  DYNAMIC: 'dynamic',
  MANUAL: 'manual'
}

export const segmentStatus = {
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  FAILED: 'failed'
}

export const steps = [
  { label: 'Setup', key: 'setup', completed: false },
  { label: 'Segment', key: 'design', completed: false },
  { label: 'Review', key: 'review', completed: false }
]

export const mainPredicates = [
  { label: 'Made a purchase', value: 'purchase' },
  { label: 'Signed up', value: 'signed_up' },
  { label: 'Clicked a shortlik', value: 'clicked' },
]

export const predicateTypes = {
  PURCHASE: 'purchase',
  SIGN_UP: 'sign_up',
  SHORTLINK_CLICK: 'shortlink_click',
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
  OR: 'or',
  AND: 'and',
  ID: 'id'
}

export const predicateAttributes = {
  SOURCE: 'source',
  SOURCE_MEDIUM: 'source_medium',
  ACTIVITY_DATE: 'activity_date',
  TOTAL: 'total',
  TRIGGER_ID: 'trigger_id',
  JOURNEY_ID: 'journey._id',
  CAMPAIGN_ID: 'campaign._id'
}

export const predicateComparison = {
  // TODO: let's describe these operations with single line comments
  ANY: 'any',
  EQ: 'eq',
  GTE: 'gte',
  LTE: 'lte',
  LT: 'lt',
  NE: 'ne'
}

export const predicateValues = {
  PRIVY: 'privy',
  TOAD: 'toad',
  WEBHOOK: 'webhook',
  ANY: 'any'
}

/**
 * Mapping segments to use in select box in campaign creation process. i.e while * selecting segment
 * @param {Array} segmentList  - segments gathered from the endpoint
 * @returns {Array} - mapped segment list for segment selection
 */
export const mapSegmentOptions = (segmentList) => {
  return segmentList.map((segment) => {
    return { value: segment.id, label: segment.name }
  })
}

export const mapSegments = (data, chain) => {
  return {
    items: data.segments,
    total: data.total,
  }
}

/**
 * A function to return a string to tell information about how long it's been from now, compare to given data. i.e 5 minutes ago, 3 hours ago, 5 days ago.
 * @param {*} time UTC date string
 * @returns
 */
export const getTimeDifference = (time) => {
  const days = moment().diff(moment(time), 'days')
  if(days === 1 ) return 'a day ago'
  if(days === 0) {
    const hours = moment().diff(moment(time), 'hours')
    if(hours) return `${hours} hours ago`
    const minutes =  moment().diff(moment(time), 'minutes')
    return `${minutes} minutes ago`
  }
  return `${days} days ago`
}

export const mapDynamicSegmentIn = input => {
  return input
}

/**
 * get steps with the `completed` flag set depending on which fields are completed
 *
 * @param {Object} segment
 *
 * @returns {Array} - list of steps
*/
export const getSteps = segment => {
  if (!segment) {
    return steps
  }
  const res = [ ...steps ]
  if (segment.name) {
    res[0].completed = true
  }

  if (!_.isEmpty(_.get(segment, 'payload.predicates'))) {
    res[1].completed = true
  }
  return res
}
