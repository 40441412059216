import React, {useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { predicateAttributes, predicateComparison, predicateTypes } from '../../../../../services/segments'
import AmountPredicate from '../../Predicates/AmountPredicate'
import NumberPredicate from '../../Predicates/NumberPredicate'
import DatePredicate from '../../Predicates/DatePredicate'
import { Select } from '../../../../FormComponents'
import './styles.scss'

const providerOptions = [
  { value: 'any', label: 'Any',},
  { value: 'square', label: 'Square', },
  { value: 'toast', label: 'Toast', },
  // { value: 'olo', label: 'OLO' }
]
const DEFAULT_NUMBER_AMOUNT_PREDICATE =  {
  type: predicateTypes.PURCHASE,
  attribute: predicateAttributes.SOURCE,
  comparison: predicateComparison.EQ,
}

const PurchaseTemplate = props => {
  const { predicates, onChange, onDateChange, onClearPredicates } = props

  const datePredicates = predicates.filter((item) => item.type === predicateTypes.DATE )
  const purchasePredicate = predicates.find((item) => item.type === predicateTypes.PURCHASE )
  const numberPredicate = predicates.find((item) => item.type === predicateTypes.NUMBER )
  // does purchase predicate have source, i.e toast/square
  let initialselectedSource = providerOptions.find(op => op.value === _.get(purchasePredicate, 'value'))
  if(purchasePredicate.comparison === 'any') {
    initialselectedSource = providerOptions[0]
  }

  const [ selectedSource, setSelectedSource ] = useState(initialselectedSource)

  const handleChange = item => {
    const updatedPurchasePredicate =  {
      type: predicateTypes.PURCHASE,
      attribute: predicateAttributes.SOURCE,
      comparison: predicateComparison.EQ,
      value: item.value,
    }
    if(item.value === 'any') {
      updatedPurchasePredicate.comparison = item.value
      // we don't send value when the comparision is any.
      delete updatedPurchasePredicate.value
    }
    onChange(updatedPurchasePredicate)
    setSelectedSource(item)
  }

  return (
    <>
      <div className='text-node'>
        on
      </div>
      <div className='predicate-cell'>
        <Select
          name='segments-predicates-provider'
          className='eg-segments-purchase-providers'
          options={ providerOptions }
          selectedItem={ selectedSource || null }
          onChange={ handleChange }
        />
      </div>
      {selectedSource &&
      <>
        <div className='predicate-cell'>
          <AmountPredicate
            predicate={purchasePredicate || DEFAULT_NUMBER_AMOUNT_PREDICATE}
            onChange={onChange}
          />
        </div>
       <div className='text-node'>
         {_.get(purchasePredicate, 'amount.value') > 1 ?  'times' : 'time' }
       </div>
      </>
      }
      {selectedSource &&
        <div className='predicate-cell'>
          <DatePredicate
            onDateChange={onDateChange}
            datePredicates={datePredicates}
            onClearPredicates={onClearPredicates}
          />
        </div>
      } {
        selectedSource && (
          <>
          <div className='text-node'>
            where the order total is
          </div>
          <div className='predicate-cell'>
            <NumberPredicate
              predicate={numberPredicate}
              onChange={onChange}
              isCurrency={true}
            />
          </div>
        </>
        )
      }
    </>
  )
}

PurchaseTemplate.propTypes = {
  predicates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onClearPredicates: PropTypes.func.isRequired,
}

export default PurchaseTemplate
