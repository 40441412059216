import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CloseButton = props => {
  const { className = '', onClick } = props

  return (
    <div className={ `eg-close-button ${className}` } onClick={ onClick }>
      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.02654 7L0.513105 1.70711C0.106309 1.31658 0.106309 0.683418 0.513105 0.292893C0.919901 -0.0976311 1.57945 -0.0976311 1.98624 0.292893L7.49967 5.58579L13.0131 0.292893C13.4199 -0.0976311 14.0794 -0.0976311 14.4862 0.292893C14.893 0.683418 14.893 1.31658 14.4862 1.70711L8.97281 7L14.4862 12.2929C14.893 12.6834 14.893 13.3166 14.4862 13.7071C14.0794 14.0976 13.4199 14.0976 13.0131 13.7071L7.49967 8.41421L1.98624 13.7071C1.57945 14.0976 0.919901 14.0976 0.513105 13.7071C0.106309 13.3166 0.106309 12.6834 0.513105 12.2929L6.02654 7Z" fill="#A4AEB6"/>
      </svg>
    </div>
  )
}

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default CloseButton
