import React from 'react'
import PropTypes from 'prop-types'

import EditableCard from '../../../EditableCard'
import StoreProfileCard from '../StoreProfileCard'

import styles from './index.module.scss'

const StoreSection = props => {
  const store = props.store
  const isEditable = props.isEditable
  const sectionComponent = props.component

  const handleEditClick = section => {
    if (props.onEditClick) {
      props.onEditClick(section)
    }
  }

  return (
    <div className={styles.container}>
      <EditableCard
        className={ styles.editableCard }
        isEditable={ isEditable }
        onEditClick={ () => handleEditClick(sectionComponent) }
      >
        {
          isEditable ? (
            <sectionComponent.info
              store={ store }
              onEditClick = { () => handleEditClick(sectionComponent) }
            />
          ) : (
            <StoreProfileCard
              title={ props.title }
              description={ props.description }
              addItemLabel={ props.addItemLabel }
              onEdit={ () => handleEditClick(sectionComponent) }
            />
          )
        }
      </EditableCard>
    </div>
  )
}

StoreSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  addItemLabel: PropTypes.string,
  store: PropTypes.object,
  component: PropTypes.object,
  section: PropTypes.string,
  isEditable: PropTypes.bool,
  onEditClick: PropTypes.func
}

StoreSection.defaultProps = {}

export default StoreSection
