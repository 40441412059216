import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import TimeOptions, { timeIntervals } from './Popup'
import './styles.scss'

const initialTimePeriod = timeIntervals[9]

function TimeSelector(props) {
  const { onDateApply } = props
  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(initialTimePeriod)
  const [selectedDayRange, setSelectedDayRange] = useState(initialTimePeriod.range)


  const applySelectedDates = useCallback( _.debounce((data) => {
    if(data.from && data.to) {
      onDateApply(data, selectedOption)
    }
  }, 500, {trailing: true}), [onDateApply, selectedOption])

  useEffect(() => {
    if(selectedDayRange && selectedDayRange.from && selectedDayRange.to) {
      const fromObject = selectedDayRange.from
      const toObject = selectedDayRange.to
      const from = moment(new Date(`${fromObject.year}/${fromObject.month}/${fromObject.day}`)).format('YYYY-MM-DD')
      const to = moment(new Date(`${toObject.year}/${toObject.month}/${toObject.day}`)).format('YYYY-MM-DD')
      const range = { from, to }
      applySelectedDates(range)
    }
  }, [applySelectedDates, selectedDayRange])

  const onModalOpen = () => {
    setShowModal(true)
  }

  const onModalClose = () => {
    setShowModal(false)
  }

  const onItemChange = (item) => {
    setSelectedOption(item)
    setSelectedDayRange(item.range)
  }

  const containerClasses = `eg-time-selector ${showModal ? 'selected' : ''}`

  return (
    <div className={containerClasses} onClick={onModalOpen} data-testid='time-selector' >
      <div data-testid='calendar-button-text'>{selectedOption.label}</div>
      {
        showModal &&
        <TimeOptions
          onClose={onModalClose}
          onItemChange={onItemChange}
          selectedItem={selectedOption}
          selectedDayRange={selectedDayRange}
        />
      }
    </div>
  )
}


TimeSelector.propTypes = {
  onDateApply: PropTypes.func,
}

export default TimeSelector
