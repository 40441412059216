import { useEffect } from 'react'

/**
 * handles the case when a user clicks outside
 * an element, defined by `ref` parameter
*/
export const useOutsideClick =(ref, cb) => {
  useEffect(() => {
    // Handler
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (cb) cb()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, cb])
}
