import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { actions as userActions } from '../../actions/User'
import { userHasAccess } from '../../services/auth'

const AccessControlledComponent = props => {
  const user = useSelector(state => state.User.user)
  const dispatch = useDispatch()
  if (user && (!user.roles || !user.roles.length)) {
    // if no user or no roles, we just kick him out
    dispatch(userActions.Logout())
  }
  return userHasAccess(user, props.roles) ? (
    <>{ props.children }</>
  ) : null
}

AccessControlledComponent.propTypes = {
  roles: PropTypes.array.isRequired,
  children: PropTypes.any
}

export default AccessControlledComponent
