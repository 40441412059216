import React from 'react'
import PropTypes from 'prop-types'

import { sourceMediums, sourceMediumLabels, filterTypes } from '../../../../services/customers'
import MultipleSelectionFilter from '../../../CommonFilters/MultipleSelectionFilter'
import FilterContent from './FilterContent'

const defaultActionLabel = 'Source Medium'

const filterMediums = Object.values(sourceMediums)

const MediumFilter = props => {
  const handleApply = (filter) => {
    if (props.onApply) {
      props.onApply(filter)
    }
  }

  const handleClear = (filter) => {
    if (props.onClear) {
      props.onClear(filter)
    }
  }

  const filterItems = filterMediums.map(medium => {
    return {
      key: medium,
      label: sourceMediumLabels[medium],
      value: false
    }
  })

  return (
    <div data-testid='eg-source-medium-filter-content'>
      <MultipleSelectionFilter
        defaultActionLabel={ defaultActionLabel }
        filterItems={ filterItems }
        filterType={ filterTypes.SOURCE_MEDIUM }
        FilterContent={ FilterContent }
        onApply={ handleApply }
        onClear={ handleClear }
      />
    </div>
  )
}

MediumFilter.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

export default MediumFilter
