import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import querystring from 'query-string'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import AccessControlledComponent from '../../components/AccessControlledComponent'
import CustomerHeader from '../../components/CustomerHeader'
import { Button } from '../../components/FormComponents'
import PaginationSection from '../../components/PaginationSection'
import TabSelector from '../../components/TabSelector'
import CampaignList from '../../components/Campaigns/CampaignList'
import Schedule from '../../components/Campaigns/Schedule'
import { PATHS } from '../../constants'
import { actions as campaignActions } from '../../actions/Campaign'
import { campaignTypes, mapCampaignToFormData } from '../../services/campaigns'
import EGAnalytic, { analyticEvents } from '../../services/analytics'
import ConfirmationModal from '../../components/ConfirmationModal'
import './styles.scss'

const tabs = [
  {
    label: 'Delivered',
    key: campaignTypes.DELIVERED
  },
  {
    label: 'Scheduled',
    key: campaignTypes.SCHEDULED
  },
  {
    label: 'Drafts',
    key: campaignTypes.DRAFT
  },
  {
    label: 'Canceled',
    key: campaignTypes.CANCELED
  },
]

const DEFAULT_PAGE_SIZE = 25
let scheduleSubmitted = false
let cancelSubmitted = false

const getTabFromUrl = () => {
  const hashRouteTab = tabs.find((tab) => window.location.href && window.location.href.includes(tab.key))
  if(hashRouteTab) return hashRouteTab
  return null
}


const Campaigns = props => {
  const { history } = props
  const [ campaignToEdit, setCampaignToEdit ] = useState(null)
  const [ campaignToCancel, setCampaignToCancel ] = useState(null)
  const dispatch = useDispatch()
  const { campaignCancel, campaignUpdate, campaignList } = useSelector(state => state.Campaign)
  const chain = useSelector(state => state.User.chain)
  const tabFromUrl = getTabFromUrl()
  const qs = querystring.parse(props.location.search)
  const refreshPage = qs.refresh && parseInt(qs.refresh)
  const initialTab =  tabFromUrl || tabs[0]
  const [ currentTab, setCurrentTab ]  = useState(initialTab)
  const pageSize = get(campaignList, 'campaigns.size') || DEFAULT_PAGE_SIZE

  useEffect(() => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_LIST_PAGE_VIEWED, {
      ...EGAnalytic.mapChain(chain),
      segmentPageName_: window.location.pathname
    })
  },[chain])

  useEffect(() => {
    // If there is no tab selected in the query parameters
    // we are always going to get campaign list on page load
    const hasNoTabinUrl = !tabFromUrl
    if(  hasNoTabinUrl || currentTab.key !== get(campaignList, 'status') || refreshPage){
      dispatch(campaignActions.getCampaigns({ page: 1, size: DEFAULT_PAGE_SIZE, status: currentTab.key }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentTab.key, refreshPage])

  // effects for update campaign schedule submission
  useEffect(() => {
    if (campaignUpdate.submitting) {
      scheduleSubmitted = true
    } else if (scheduleSubmitted && !campaignUpdate.error) {
      setCampaignToEdit(null)
      dispatch(campaignActions.updateCampaignClear())
      dispatch(campaignActions.getCampaigns({ page: 1, size: DEFAULT_PAGE_SIZE, status: currentTab.key }))
      scheduleSubmitted = false
    } else if (campaignUpdate.error) {
      scheduleSubmitted = false
    }
  }, [campaignUpdate.submitting, campaignUpdate.error, currentTab.key, dispatch])

  // effects for cancel campaign submission
  useEffect(() => {
    if (campaignCancel.submitting) {
      cancelSubmitted = true
    } else if (cancelSubmitted) {
      setCampaignToCancel(null)
      dispatch(campaignActions.getCampaigns({ page: 1, size: DEFAULT_PAGE_SIZE, status: currentTab.key }))
    }
  }, [campaignCancel.submitting, currentTab.key, dispatch])

  const handlePageChange = (page) => {
    dispatch(campaignActions.getCampaigns({ page, size: pageSize, status: currentTab.key }))
  }

  const handlePageSizeChange = (size) => {
    dispatch(campaignActions.getCampaigns({ page: 1, size, status: currentTab.key }))
  }

  const handleCreateClick = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_CREATION_STARTED, EGAnalytic.mapChain(chain))
    const preUrl =  `${history.location.pathname}${history.location.search}`
    history.push(PATHS.SMS_CAMPAIGNS_CREATE, {preUrl})
  }

  const changeTabOption = (item) => {
    if(item.key !== currentTab.key) {
      EGAnalytic.track(analyticEvents.CAMPAIGN_LIST_PAGE_TAB_INTERACTION, {
        ...EGAnalytic.mapChain(chain),
        campaignTabName: item.label
      })
      setCurrentTab(item)
      history.push({
        search: `?tab=${item.key}`
      })
    }
  }

  const handleEditSchedule = campaign => {
    setCampaignToEdit(mapCampaignToFormData(campaign, chain))
  }

  const handleCancel = campaign => {
    setCampaignToCancel(campaign)
  }

  const handleCancelConfirm = () => {
    dispatch(campaignActions.cancelCampaign(campaignToCancel))
  }

  const handleScheduleSubmit = updatedSchedule => {
    dispatch(campaignActions.updateCampaign({
      campaignId: campaignToEdit.id,
      schedule_date: updatedSchedule.schedule_date
    }))
  }

  const handleItemClick = (item) => {
    const preUrl =  `${history.location.pathname}${history.location.search}`
    if(currentTab.key === campaignTypes.DELIVERED) {
      // TODO: to check whether we have the statistics/campagin chart data for campaign or not.
      item.statistic && history.push(
        `${PATHS.SMS_CAMPAIGN}/${item.id}`,
        { preUrl }
      )
    } else {
      history.push(
        `${PATHS.SMS_CAMPAIGNS}/edit/${item.id}`,
        { preUrl }
      )
    }
  }

  return (
    <div className='eg-campaigns'>
      <CustomerHeader />
      <div className='content'>
        <div className='top'>
          <h3>Campaigns</h3>
          {/* sunset - superadmin access only */}
          <AccessControlledComponent roles={ [] }>
            <Button text='CREATE NEW CAMPAIGN' onClick={ handleCreateClick } />
          </AccessControlledComponent>
        </div>
        <TabSelector
          items={ tabs }
          className= 'tab'
          tabItemClass = 'item'
          selectedTabItemClass = 'selected'
          selectedItem={ currentTab }
          onChange={ changeTabOption }
        />
        <CampaignList
          data={campaignList}
          type={currentTab.key}
          onEditSchedule={ handleEditSchedule }
          onCancel={ handleCancel }
          onItemClick={handleItemClick}
        />
        {
          campaignList.campaigns &&
          <div className='pagination'>
            <PaginationSection
              total={ get(campaignList, 'total') }
              size={ get(campaignList, 'size') }
              page={ get(campaignList, 'page') }
              onPageChange={ handlePageChange }
              onSizeChange={ handlePageSizeChange }
            />
          </div>
        }
      </div>
      {
        campaignToEdit && (
          <Schedule
            submitting={ campaignUpdate.submitting }
            error={ campaignUpdate.error }
            onSubmit={ handleScheduleSubmit }
            onCancel={ () => setCampaignToEdit(null) }
            data={ campaignToEdit }
          />
        )
      }
      {
        !!campaignToCancel && (
          <ConfirmationModal
            title='Cancel campaign?'
            text='Are you sure you want to cancel this campaign?'
            loading={ campaignCancel.submitting }
            onCancel={ () => setCampaignToCancel(null) }
            onConfirm={ handleCancelConfirm }
          />
        )
      }
    </div>
  )
}

Campaigns.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default Campaigns
