import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ResetPassword from './ResetPassword'
import { actions } from '../../actions/User'

const mapStateToProps = state => ({
  posting: state.User.posting,
  errorCode: state.User.errorCode
})

const mapDispatchToProps = dispatch => ({
  onSubmit(email) {
    dispatch(actions.resetPassword(email))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
