import React from 'react'
import PropTypes from 'prop-types'

import { renderInput } from '../../../../FormComponents/utils'
import { TextArea, RadioButton } from '../../../../FormComponents'
import PhonePreview, { HeaderSMS } from '../../../../PhonePreview'
import './styles.scss'

export const fields = {
  SENDER_NAME: 'sender',
  SMS_TEXT: 'sms_text',
  LOYALTY_LINK: 'loyalty_link',
  OPTIMIZED_PAGE: 'optimized_page'
}

const AutomationSms = props => {
  const { validator, visible, values } = props
  const { register, errors } = validator

  const enableOptimizedPage = () => {
    validator.setValue(fields.OPTIMIZED_PAGE, true)
  }

  const disableOptimizedPage = () => {
    validator.setValue(fields.OPTIMIZED_PAGE, false)
  }

  const optimizedPage = String(values[fields.OPTIMIZED_PAGE]) === 'true'

  return (
    <div className={ `eg-automation-sms ${!visible ? 'hidden' : ''}` } data-testid='loyalty-automation-sms'>
      <div className='form-wrapper'>
        <div className='title'>SMS</div>
        <div className='form'>
          <div className='field'>
            <label className='label' htmlFor={ fields.SENDER_NAME }>Sender's name</label>
            { renderInput(fields.SENDER_NAME, validator, { required: false }, 'Enter Sender\'s name (Optional)') }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.SMS_TEXT }>SMS text</label>
            <TextArea
              id={ fields.SMS_TEXT }
              name={ fields.SMS_TEXT }
              className={ `textarea ${errors[fields.SMS_TEXT] ? 'error' : ''}` }
              forwardedRef={ register({ required: true }) }
            />
            { errors[fields.SMS_TEXT] && <div className='error-msg'>{ errors[fields.SMS_TEXT].message }</div> }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.LOYALTY_LINK }>Link to loyalty signup page</label>
            <div className='optimized-opts'>
              <RadioButton
                className='radio'
                name={ fields.OPTIMIZED_PAGE }
                selected={ optimizedPage }
                label=''
                onClick={ enableOptimizedPage }
              >
                Optimized loyalty page
              </RadioButton>
              <RadioButton
                className='radio'
                name={ fields.OPTIMIZED_PAGE }
                selected={ !optimizedPage }
                label=''
                onClick={ disableOptimizedPage }
              >
                Other
              </RadioButton>
              <input
                type='hidden'
                id={ fields.OPTIMIZED_PAGE }
                name={ fields.OPTIMIZED_PAGE }
                ref={ register() }
              />
            </div>
            { renderInput(fields.LOYALTY_LINK, validator) }
            {
              optimizedPage && (
                <div className='note'>
                  NOTE: Changing this may cause redirect issues to the loyalty page so make sure to
                  input a correct and valid link before publishing
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className='preview'>
        <div className='title'>PREVIEW</div>
        <PhonePreview className='phone'>
          <HeaderSMS title={ values[fields.SENDER_NAME] || '<Sender name here>' } />
          <div className='iphone-font-mini'>
            Text message<br />
            Thursday 11:45 AM
          </div>
          <div className='message-wrapper' data-testid='sms-preview-message-wrapper'>
            <p>
              { values[fields.SMS_TEXT] || '<SMS message here>' }
            </p>
            <p className='link'>
              { values[fields.LOYALTY_LINK] || '<Loyalty link here>' }
            </p>
            <p>
              Powered by ZeroStorefront<br />
              Reply STOP to opt-out
            </p>
          </div>
        </PhonePreview>
      </div>
    </div>
  )
}

AutomationSms.propTypes = {
  validator: PropTypes.object,
  visible: PropTypes.bool,
  values: PropTypes.object
}

export default AutomationSms
