import React from 'react'
import PropTypes from 'prop-types'

import { googleConnectInit } from '../../../../../services/googleConnect'
import GoogleConnectButton from '../../../../GoogleConnectButton'
import Spinner from '../../../../LocalSpinner'
import PopOverMenu from '../../../../PopOverMenu'

import styles from './index.module.scss'

export default class CalendarConnect extends React.Component {
  static propTypes = {
    restaurantUuid: PropTypes.string.isRequired,
    googleConnectPosting: PropTypes.bool,
    googleConnectError: PropTypes.bool,
    onCalendarConnect: PropTypes.func,
    isCalendarConnected: PropTypes.bool,
    integrationEmail: PropTypes.string.isRequired,
  }

  constructor (props) {
    super(props)

    // bindings
    this.connectCalendar = this.connectCalendar.bind(this)
    this.renderGoogleConnectButton = this.renderGoogleConnectButton.bind(this)
    this.renderUserInfo = this.renderUserInfo.bind(this)
    this.renderCalendarInfo = this.renderCalendarInfo.bind(this)
  }

  componentDidMount () {
    googleConnectInit()
  }

  connectCalendar () {
    const { restaurantUuid } = this.props
    this.props.onCalendarConnect({restaurantUuid})
  }

  renderGoogleConnectButton() {
    const { isCalendarConnected } = this.props
    return <>
      <GoogleConnectButton
        className={ styles.googleConnectButton }
        text={isCalendarConnected ? 'Connect different account': 'Connect with Google Calendar'}
        loading={ this.props.googleConnectPosting }
        onClick={ this.connectCalendar }
      />
      {this.props.googleConnectError &&
        <div className={ styles.googleConnectError }>
          An error ocurred while trying to connect with Google.
          Please try again later
        </div>
      }
    </>
  }

  renderUserInfo() {
    return (
      <>
        <div className={styles.userInfo}>
          <div className={styles.syncWrapper}>
            <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
          </div>
          <div className={styles.userDetails}>
            {  this.props.googleConnectPosting 
              ? <Spinner />
              : this.props.integrationEmail || 'Your Calendar has been successfully connected.'
            }
            {/* TODO: Handle Gmail last check from backend
            Wait for restaurant.gmail_integration_last_check to be available
            <span className={styles.smallInfo}>Last synced {this.props.restaurant.gmail_integration_last_check}</span> */}
          </div>
          <div className={styles.actionContainer}>
            <PopOverMenu className={styles.popOverStyle}>
              <div className="eg-menu-title">
                <button className={styles.dropDownMenu}></button>
              </div>
              <div className="eg-menu-items">
                <div onClick={ this.connectCalendar }>Change account</div>
              </div>
            </PopOverMenu>
          </div>
        </div>
      </>
    )
  }

  renderCalendarInfo() {
    return (
      <>
        <ul className={styles.infoDescription}>
          <li>
            <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
            Eatgeek will automatically create event reminders to your calendar
            for all your orders and booked events.
          </li>
          <li>
            <span className={`${styles.listIcon} ${styles.iconCalAccount}`}></span>
            Make sure to connect the email account you’re currently using to receive orders.
          </li>
          <li>
            <span className={`${styles.listIcon} ${styles.iconSecure}`}></span>
            Your personal emails, credentials and contacts will not be shared with Eatgeek.
          </li>
        </ul>
        <div className={ styles.gConnectButtonWrapper }>
          { this.renderGoogleConnectButton() }
        </div>
      </>
    )
  }

  /**
   * Gets JSX of Google Connect with some user/general details.
   */
  render () {
    const { isCalendarConnected } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          { isCalendarConnected && <div className={styles.check}></div> }
        </div>
        <div className={styles.infoTitle}>
          { isCalendarConnected ? 'Calendar Connected' : 'Connect Google Calendar' }
        </div>
        {
          isCalendarConnected
            ? this.renderUserInfo()
            : this.renderCalendarInfo()
        }
      </div>
    )
  }
}
