import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { userHasAccess } from '../../services/auth'
import { PATHS, HOME_PATH } from '../../constants'

class OnlyLoggedIn extends React.Component {
  static propTypes = {
    component: PropTypes.any,
    user: PropTypes.object,
    roles: PropTypes.array,
    location: PropTypes.any
  }

  render() {
    const {
      component: Component,
      user: User,
      roles,
      ...rest
    } = this.props

    let isAuthorized = false
    // if no roles specified, then it should be allowed
    if (!roles) {
      isAuthorized = true
    } else if (User && User.user) {
      isAuthorized = userHasAccess(User.user, roles || [])
    }

    return (
      <Route
        { ...rest }
        render={ props => {
          return (
            User.isAuthenticated ? (
              isAuthorized
                ? <Component {...props} />
                : <Redirect to={{ pathname: HOME_PATH(User.user) }} />
            ) : (
              <Redirect
                to={{
                  pathname: PATHS.LOGIN,
                  state: { from: props.location }
                }}
              />
            ))
        } }
      />
    )
  }
}

export default OnlyLoggedIn
