import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { actions as userActions } from '../../../actions/User'
import useValidator, { validationPatterns, errorMessages } from '../../../hooks/validator'
import { Input, Button } from '../../FormComponents'
import './styles.scss'

const fields = {
  CURRENT_PASSWORD: 'old_password',
  NEW_PASSWORD: 'new_password',
  CONFIRM_PASSWORD: 'confirm_password'
}

const UpdateUserPassword = props => {
  const { onChange, onSubmit } = props
  const { register, formState, errors, getValues, watch, reset } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.CURRENT_PASSWORD]: '',
      [fields.NEW_PASSWORD]: '',
      [fields.CONFIRM_PASSWORD]: ''
    }
  })
  const posting = useSelector(state => state.User.posting)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(userActions.updateUserPassword(getValues()))
    reset({
      [fields.CURRENT_PASSWORD]: '',
      [fields.NEW_PASSWORD]: '',
      [fields.CONFIRM_PASSWORD]: ''
    })
    if (onSubmit) {
      onSubmit()
    }
  }

  const handleChange = () => {
    if (onChange) {
      onChange()
    }
  }

  const renderInput = (name, placeholder, validationRules, customError) => {
    return (
      <div>
        <Input
          id={ name }
          name={ name }
          type='password'
          className={ errors[name] ? 'error' : '' }
          placeholder={ placeholder }
          ref={ register(validationRules) }
          onChange={ handleChange }
        />
        { errors[name] && <div className='error-msg'>{ customError || errors[name].message }</div> }
      </div>
    )
  }

  return (
    <div data-testid='update-user-password' className='eg-update-user-password'>
      <div className='fields'>
        <div className='field'>
          <label className='label' htmlFor={ fields.CURRENT_PASSWORD }>Current Password</label>
          { renderInput(fields.CURRENT_PASSWORD, 'Current password', { required: true }) }
        </div>
        <div className='field'>
          <label className='label' htmlFor={ fields.NEW_PASSWORD }>New Password</label>
          {
            renderInput(fields.NEW_PASSWORD, 'New password', {
              required: true,
              validate: value => !!value.match(validationPatterns.PASSWORD)
            }, errorMessages.PASSWORD_INVALID_FORMAT)
          }
        </div>
        <div className='field'>
          <label className='label' htmlFor={ fields.CONFIRM_PASSWORD }>Confirm Password</label>
          {
            renderInput(fields.CONFIRM_PASSWORD, 'Confirm password', {
              required: true,
              validate: value => value === watch(fields.NEW_PASSWORD, '')
            }, errorMessages.PASSWORDS_DONT_MATCH)
          }
        </div>
      </div>
      <div className='actions'>
        <Button
          text='Save changes'
          className='button'
          disabled={ !formState.isValid }
          loading={ posting }
          onClick={ handleSubmit }
        />
      </div>
    </div>
  )
}

UpdateUserPassword.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default UpdateUserPassword

