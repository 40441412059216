import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import CustomerHeader from '../../components/CustomerHeader'
import { userHasAccess } from '../../services/auth'
import { statuses } from '../../services/journeys'
import AccessControlledComponent from '../../components/AccessControlledComponent'
import PaginationSection from '../../components/PaginationSection'
import TabSelector from '../../components/TabSelector'
import { Button } from '../../components/FormComponents'
import { types as buttonTypes } from '../../components/FormComponents/Button'
import JourneyList from '../../components/Journeys/JourneyList'
import StatelessModal from '../../components/StatelessModal'
import { actions as journeyActions } from '../../actions/Journey'
import EGAnalytic, { analyticEvents } from '../../services/analytics'
import { journeyTypes } from '../../services/journeys'
import { PATHS } from '../../constants'
import './styles.scss'

const tabs = [
  {
    label: 'Active',
    key: journeyTypes.ACTIVE
  },
  {
    label: 'Inactive',
    key: journeyTypes.INACTIVE
  },
  {
    label: 'Drafts',
    key: journeyTypes.DRAFT
  }
]

const DEFAULT_PAGE_SIZE = 25


const getTabFromUrl = () => {
  const hashRouteTab = tabs.find((tab) => window.location.href && window.location.href.includes(tab.key))
  if(hashRouteTab) return hashRouteTab
  return null
}

const Journeys = props => {
  const { history } = props
  const dispatch = useDispatch()
  const journeyList = useSelector(state => state.Journey.journeyList)
  const chain = useSelector(state => state.User.chain)
  const user = useSelector(state => state.User.user)
  const initialTab = getTabFromUrl() || tabs[0]
  const [ currentTab, setCurrentTab ]  = useState(initialTab)
  const {
    submitting: statusChangeSubmitting,
    success: statusChangeSuccess
  } = useSelector(state => state.Journey.updateStatus)
  const pageSize = get(journeyList, 'list.size') || DEFAULT_PAGE_SIZE
  const [changedStatus, setChangedStatus] = useState(null)

  useEffect(() => {
    if (statusChangeSuccess) {
      const { item, status } = changedStatus || {}
      if (status === statuses.ACTIVE) {
        EGAnalytic.track(analyticEvents.JOURNEY_ACTIVATED, {
          ...EGAnalytic.mapChain(chain),
          triggerName: item.triggerName,
          triggerId: item.trigger_id,
          journeyName: item.name
        })
      } else if (status === statuses.INACTIVE) {
        EGAnalytic.track(analyticEvents.JOURNEY_DEACTIVATED, {
          ...EGAnalytic.mapChain(chain),
          triggerName: item.triggerName,
          triggerId: item.trigger_id,
          journeyName: item.name
        })
      }
      setChangedStatus(null)
      dispatch(journeyActions.getJourneys({ page: 1, size: DEFAULT_PAGE_SIZE, status: currentTab.key }))
    }
  }, [statusChangeSuccess, dispatch, changedStatus, chain, currentTab.key])

  useEffect(() => {
    EGAnalytic.track(analyticEvents.JOURNEY_LIST_PAGE_VIEWED, {
      ...EGAnalytic.mapChain(chain),
      segmentPageName_: window.location.pathname
    })
    dispatch(journeyActions.getJourneys({ page: 1, size: DEFAULT_PAGE_SIZE,  status: currentTab.key  }))
  }, [chain, currentTab.key, dispatch])

  const changeTabOption = (item) => {
    if(item.key !== currentTab.key) {
      // EGAnalytic.track(analyticEvents.JOURNEY_LIST_PAGE_TAB_INTERACTION, {
      //   ...EGAnalytic.mapChain(chain),
      //   campaignTabName: item.label
      // })
      setCurrentTab(item)
      history.push({
        search: `?tab=${item.key}`
      })
    }
  }

  const handlePageChange = (page) => {
    dispatch(journeyActions.getJourneys({ page, size: pageSize,  status: currentTab.key  }))
  }

  const handlePageSizeChange = (size) => {
    dispatch(journeyActions.getJourneys({ page: 1, size,  status: currentTab.key  }))
  }

  const handleCreateClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_CREATION_STARTED, EGAnalytic.mapChain(chain))
    history.push(PATHS.SMS_JOURNEYS_CREATE)
  }

  const handleStatusChange = (item, status) => {
    const statusLabel = status === statuses.ACTIVE ? 'Activate' : 'Deactivate'
    setChangedStatus({ item, status, statusLabel })
    if (status === statuses.ACTIVE) {
      EGAnalytic.track(analyticEvents.JOURNEY_ACTIVATION_MODAL_DISPLAYED, EGAnalytic.mapChain(chain))
    } else {
      EGAnalytic.track(analyticEvents.JOURNEY_DEACTIVATED_MODAL_DISPLAYED, {
        ...EGAnalytic.mapChain(chain),
        triggerName: item.triggerName,
        triggerId: item.trigger_id,
        journeyName: item.name
      })
    }
  }

  const handleStatusChangeSubmit = () => {
    dispatch(journeyActions.setJourneyState({
      journeyId: changedStatus.item.id,
      state: changedStatus.status
    }))
  }

  const handleItemClick = (item) => {
    if (item.status === statuses.DRAFT && userHasAccess(user, [])) {
      history.push(`${PATHS.SMS_JOURNEYS}/edit/${item.id}`)
    } else {
      history.push(`${PATHS.SMS_JOURNEYS}/view/${item.id}`)
    }
  }

  const handleStatusModalClose = () => {
    const { item, status } = changedStatus
    if (status === statuses.ACTIVE) {
      EGAnalytic.track(analyticEvents.JOURNEY_ACTIVATION_MODAL_ABANDONED, EGAnalytic.mapChain(chain))
    } else {
      EGAnalytic.track(analyticEvents.JOURNEY_DEACTIVATED_MODAL_ABANDONED, {
        ...EGAnalytic.mapChain(chain),
        triggerName: item.triggerName,
        triggerId: item.trigger_id,
        journeyName: item.name
      })
    }
    setChangedStatus(null)
  }
  return (
    <div className='eg-journeys'>
      <CustomerHeader />
      <div className='content'>
        <div className='top'>
          <h3>Journeys</h3>
          <AccessControlledComponent roles={[]}>
            <Button text='CREATE NEW JOURNEY' onClick={handleCreateClick} />
          </AccessControlledComponent>
        </div>
        <TabSelector
          items={ tabs }
          className= 'tab'
          tabItemClass = 'item'
          selectedTabItemClass = 'selected'
          selectedItem={ currentTab }
          onChange={ changeTabOption }
        />
        <JourneyList
          data={journeyList}
          type={currentTab.key}
          onStatusChange={handleStatusChange}
          onItemClick={handleItemClick}
        />
        {
          journeyList.list &&
          <div className='pagination'>
            <PaginationSection
              total={get(journeyList, 'list.total')}
              size={get(journeyList, 'list.size')}
              page={get(journeyList, 'list.page')}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
            />
          </div>
        }
      </div>
      {
        !!changedStatus && (
          <StatelessModal onClose={handleStatusModalClose} className='status-alert-modal'>
            <div className='content'>
              <div className='title'><span>{changedStatus.statusLabel}</span> journey?</div>
              <div className='text'>
                This will take effect immediately
              </div>
            </div>
            <div className='actions'>
              <Button
                className='action-button'
                text='CANCEL'
                type={buttonTypes.SECONDARY}
                onClick={handleStatusModalClose}
              />
              <Button
                className='action-button submit'
                text={changedStatus.statusLabel.toUpperCase()}
                loading={statusChangeSubmitting}
                onClick={handleStatusChangeSubmit}
              />
            </div>
          </StatelessModal>
        )
      }
    </div>
  )
}

Journeys.propTypes = {
  history: PropTypes.object
}

export default Journeys
