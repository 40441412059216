import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import logo from '../../asset/zsf.svg'
import CustomerMessage from '../../components/CustomerMessage'
import { types as msgTypes } from '../../components/CustomerMessage/CustomerMessage'
import LoginForm from '../../components/LoginForm'
import styles from './index.module.scss'

export default class Login extends React.Component {
  static propTypes = {
    posting: PropTypes.bool,
    authError: PropTypes.bool,
    onLogin: PropTypes.func,
    history: PropTypes.object.isRequired,
    clearMessages: PropTypes.func
  }

  static defaultProps = {
    authError: false
  }

  componentWillUnmount() {
    this.props.clearMessages()
  }

  render() {
    const pageReferrer = get(this.props, 'location.state.from.pathname')
    return (
      <div className={ styles.container }>
        <img className={styles.zsfLogo} src={logo} alt='Zero Store Front'/>
        <div className={ styles.loginWrapper }>
          <h2>Dashboard Sign In</h2>
          <p>
            Grow your business with your existing data.
          </p>
          <CustomerMessage
            type={ msgTypes.MSG_WARNING }
            show={ this.props.authError }
          >
            The email or password did not match
          </CustomerMessage>
          <LoginForm
            className={ styles.formContainer }
            posting={ this.props.posting }
            onSubmit={this.props.onLogin}
            pageReferrer={pageReferrer}
          />
        </div>
      </div>
    )
  }
}
