export const types = {
  GET_CALLS_INTENT: 'GET_CALLS_INTENT',
  GET_CALLS_INTENT_RESOLVED: 'GET_CALLS_INTENT_RESOLVED',
  GET_CALLS_INTENT_REJECTED: 'GET_CALLS_INTENT_REJECTED'
}

export const actions = {
  getCalls(opts) {
    return {
      type: types.GET_CALLS_INTENT,
      opts
    }
  },

  getCallsResolved(data) {
    return {
      type: types.GET_CALLS_INTENT_RESOLVED,
      data
    }
  },

  getCallsRejected(error) {
    return {
      type: types.GET_CALLS_INTENT_REJECTED,
      error
    }
  }
}
