import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

import Button from '../FormComponents/Button/'

/**
 * React Confirm modal component.
 */
const ConfirmModal = (props) => {
  const { 
    onContinue,
    onCancel,
    title,
    text,
    continueText='Continue',
    cancelText='Cancel'
  } = props
  
  const onModalContentClick = (event) => {
    event && event.stopPropagation()
  }

  return (
    <div className={styles.container} onClick={onContinue}>
      <div className={styles.modal} onClick={onModalContentClick}>
        <div className={styles.title}>
          { title ? title : 'Are you sure?' }
        </div>
        <div className={styles.text}>
          { text }
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.continueButton}
            text={continueText}
            onClick={onContinue}
          />
          <Button
            className={styles.leaveButton}
            text={cancelText}
            onClick={onCancel} />
        </div>
      </div>
    </div>
  )
}

ConfirmModal.propTypes = {
  onContinue: PropTypes.func.isRequired,
  // onContinue - Function triggered when user clicks the background or the close button.
  onCancel: PropTypes.func.isRequired,
  // onCancel - Function triggered when user confirms the confirmation and want to move to next step.
  title: PropTypes.string,
  text: PropTypes.string,
  continueText: PropTypes.string,
  cancelText: PropTypes.string
}

export default ConfirmModal
