// Generated by Avo VERSION 84.3.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* eslint-disable */

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function() {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function(obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items) {
    var iterator = {
      next: function() {
        var value = items.shift();
        return {done: value === undefined, value: value}
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function() {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers) {
    this.map = {};

    if (headers instanceof Headers) {
      headers.forEach(function(value, name) {
        this.append(name, value);
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function(header) {
        this.append(header[0], header[1]);
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function(name) {
        this.append(name, headers[name]);
      }, this);
    }
  }

  Headers.prototype.append = function(name, value) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function(name) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function(name) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function(name) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function(name, value) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function(callback, thisArg) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function() {
    var items = [];
    this.forEach(function(value) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function() {
    var items = [];
    this.forEach(function(value, name) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body) {
    if (body.bodyUsed) {
      return Promise.reject(new TypeError('Already read'))
    }
    body.bodyUsed = true;
  }

  function fileReaderReady(reader) {
    return new Promise(function(resolve, reject) {
      reader.onload = function() {
        resolve(reader.result);
      };
      reader.onerror = function() {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]);
    }
    return chars.join('')
  }

  function bufferClone(buf) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    this.bodyUsed = false;

    this._initBody = function(body) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      this.blob = function() {
        var rejected = consumed(this);
        if (rejected) {
          return rejected
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob)
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob')
        } else {
          return Promise.resolve(new Blob([this._bodyText]))
        }
      };

      this.arrayBuffer = function() {
        if (this._bodyArrayBuffer) {
          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
        } else {
          return this.blob().then(readBlobAsArrayBuffer)
        }
      };
    }

    this.text = function() {
      var rejected = consumed(this);
      if (rejected) {
        return rejected
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob)
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text')
      } else {
        return Promise.resolve(this._bodyText)
      }
    };

    if (support.formData) {
      this.formData = function() {
        return this.text().then(decode)
      };
    }

    this.json = function() {
      return this.text().then(JSON.parse)
    };

    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input, options) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if (input.bodyUsed) {
        throw new TypeError('Already read')
      }
      this.url = input.url;
      this.credentials = input.credentials;
      if (!options.headers) {
        this.headers = new Headers(input.headers);
      }
      this.method = input.method;
      this.mode = input.mode;
      this.signal = input.signal;
      if (!body && input._bodyInit != null) {
        body = input._bodyInit;
        input.bodyUsed = true;
      }
    } else {
      this.url = String(input);
    }

    this.credentials = options.credentials || this.credentials || 'same-origin';
    if (options.headers || !this.headers) {
      this.headers = new Headers(options.headers);
    }
    this.method = normalizeMethod(options.method || this.method || 'GET');
    this.mode = options.mode || this.mode || null;
    this.signal = options.signal || this.signal;
    this.referrer = null;

    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    this._initBody(body);
  }

  Request.prototype.clone = function() {
    return new Request(this, {body: this._bodyInit})
  };

  function decode(body) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function(bytes) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders) {
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit, options) {
    if (!options) {
      options = {};
    }

    this.type = 'default';
    this.status = options.status === undefined ? 200 : options.status;
    this.ok = this.status >= 200 && this.status < 300;
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    this.headers = new Headers(options.headers);
    this.url = options.url || '';
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function() {
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function() {
    var response = new Response(null, {status: 0, statusText: ''});
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function(url, status) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    return new Response(null, {status: status, headers: {location: url}})
  };

  self.DOMException = self.DOMException;
  try {
    new self.DOMException();
  } catch (err) {
    self.DOMException = function(message, name) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    self.DOMException.prototype = Object.create(Error.prototype);
    self.DOMException.prototype.constructor = self.DOMException;
  }

  function fetch(input, init) {
    return new Promise(function(resolve, reject) {
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new self.DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function() {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, options));
      };

      xhr.onerror = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function() {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function() {
        reject(new self.DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function(value, name) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function() {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    self.fetch = fetch;
    self.Headers = Headers;
    self.Request = Request;
    self.Response = Response;
  }
})();

(function(exports) {
  var __AVO_DEV__ = false;
  var __AVO_NOOP__ = false;
  var __AVO_LOGGER__ = null;
  var __AVO_ENV__ = null;
  var __REPORT_FAILURE_AS__ = null;
  var __STRICT__ = null;

  var __WEB_DEBUGGER__ = true;
  const avoInspectorApiKey = "1YXIaEacWBUdNX2dBIJm";
  var __INSPECTOR__ = null;

  // polyfill Array.isArray
  if (!Array.isArray) {
    Array.isArray = function(arg) {
      return Object.prototype.toString.call(arg) === '[object Array]';
    };
  }

  // polyfill Object.assign
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
  if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;

      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        var result = [], prop, i;

        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }

  // polyfill Array.indexOf
  if (!Array.prototype.indexOf)  Array.prototype.indexOf = (function(Object, max, min){
    "use strict";
    return function indexOf(member, fromIndex) {
      if(this===null||this===undefined)throw TypeError("Array.prototype.indexOf called on null or undefined");

      var that = Object(this), Len = that.length >>> 0, i = min(fromIndex | 0, Len);
      if (i < 0) i = max(0, Len+i); else if (i >= Len) return -1;

      if(member===void 0){ for(; i !== Len; ++i) if(that[i]===void 0 && i in that) return i; // undefined
      }else if(member !== member){   for(; i !== Len; ++i) if(that[i] !== that[i]) return i; // NaN
      }else                           for(; i !== Len; ++i) if(that[i] === member) return i; // all else

      return -1; // if the value was not found, then return -1
    };
  })(Object, Math.max, Math.min);

  var array_difference, AvoAssert, InternalAvoLogger;
  array_difference = function array_difference(a1, a2) {
    var result = [];
    for (var i = 0; i < a1.length; i++) {
      if (a2.indexOf(a1[i]) === -1) {
        result.push(a1[i]);
      }
    }
    return result;
  }

  AvoAssert = {
    assertObject: function assertObject(propertyId, propName, obj) {
      if (typeof obj !== 'object') {
        var message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
        return [{tag: 'expectedObjectType', propertyId: propertyId, message: message, actualType: typeof obj}];
      } else {
        return [];
      }
    },

    assertString: function assertString(propertyId, propName, str) {
      if (typeof str !== 'string') {
        var message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
        return [{tag: 'expectedStringType', propertyId: propertyId, message: message, actualType: typeof str}];
      } else {
        return [];
      }
    },

    assertInt: function assertInt(propertyId, propName, int) {
      if (typeof int === 'number' && int !== Math.round(int)) {
        var message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof int !== 'number') {
        var message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
        return [{tag: 'expectedIntType', propertyId: propertyId, message: message, actualType: typeof int}];
      } else {
        return [];
      }
    },

    assertLong: function assertLong(propertyId, propName, long) {
      if (typeof long === 'number' && long !== Math.round(long)) {
        var message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, actualType: 'float'}];
      } else if (typeof long !== 'number') {
        var message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
        return [{tag: 'expectedLongType', propertyId: propertyId, message: message, actualType: typeof long}];
      } else {
        return [];
      }
    },

    assertFloat: function assertFloat(propertyId, propName, float) {
      if (typeof float !== 'number') {
        var message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
        return [{tag: 'expectedFloatType', propertyId: propertyId, message: message, actualType: typeof float}];
      } else {
        return [];
      }
    },

    assertBool: function assertBool(propertyId, propName, bool) {
      if (typeof bool !== 'boolean') {
        var message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
        return [{tag: 'expectedBoolType', propertyId: propertyId, message: message, actualType: typeof bool}];
      } else {
        return [];
      }
    },

    assertMax: function assertMax(propertyId, propName, max, value) {
      if (value > max) {
        var message = propName +
          ' has a maximum value of ' +
          max +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMax', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertMin: function assertMin(propertyId, propName, min, value) {
      if (value < min) {
        var message = propName +
          ' has a minimum value of ' +
          min +
          ' but you provided the value ' +
          JSON.stringify(value);
        return [{tag: 'expectedMin', propertyId: propertyId, message: message}];
      } else {
        return [];
      }
    },

    assertList: function assertList(propertyId, propName, value) {
      if (!Array.isArray(value)) {
        var message = propName + ' should be of type list but you provided type ' + typeof value;
        return [{tag: 'expectedList', propertyId: propertyId, message: message, actualType: typeof value}];
      } else {
        return [];
      }
    },

    assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    },

    assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName, input, spec) {
      var additionalKeys = array_difference(input, spec);
      if (additionalKeys.length) {
        var message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
        return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
      } else {
        return [];
      }
    }
  };

  InternalAvoLogger = {
    logEventSent: function logEventSent(eventName, eventProperties, userProperties) {
      const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
    },

    log: function log(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.log("[avo] " + message);
    },

    warn: function warn(message) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
        return
      }
      typeof console !== 'undefined' && console.warn("[avo] " + message);
    },

    error: function error(message, error) {
      if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
        return
      }
      typeof console !== 'undefined' && console.error("[avo] " + message, error);
    }
  };

  var _avo_invoke, _avo_invoke_meta;
var _avo_sampling_rate = 1.0;
function _avo_invoke_payload(body) {
  if (typeof window === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(body)
      }).then(function(res) { return res.json(); }).then(function(data) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke = function _avo_invoke(env, eventId, hash, messages, origin) {
  _avo_invoke_payload({
    "ac": "pr4O33WdTioM7QiuaIGX",
    "br": "master",
    "en": env,
    "ev": eventId,
    "ha": hash,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "50tzCiMip",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}

_avo_invoke_meta = function _avo_invoke_meta(env, type, messages, origin) {
  _avo_invoke_payload({
    "ac": "pr4O33WdTioM7QiuaIGX",
    "br": "master",
    "en": env,
    "ty": type,
    "sc": "ZMwnebQikC6pCSNEAgJH",
    "se": (new Date()).toISOString(),
    "so": "50tzCiMip",
    "va": messages.length === 0,
    "me": messages,
    "or": origin
  });
}


  var _avo_debugger_log;
var _avo_debugger_events_during_boot = [];
var _avo_debugger_ready = false;

if (typeof window !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    var iframe = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      var message = {
        type_: "avo-debugger-boot-events",
        schemaId: "ZMwnebQikC6pCSNEAgJH",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId, eventName, messages, eventProperties, userProperties) {
  if (typeof window === 'undefined') { return; }
  var event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties: eventProperties,
    userProperties: userProperties
  };

  if (_avo_debugger_ready) {
    var message = {type_: "avo-debugger-events", events: [event]};
    document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}


var SegmentPartnerDashboard = {
  make: function make(apiKey, options) {
    if (typeof window === 'undefined') { InternalAvoLogger.warn('window.analytics is not available in Node.js'); return; }
    var analytics = window.analytics = window.analytics || [];
    if (analytics.initialize) return;
    if (analytics.invoked) {
      if (InternalAvoLogger && InternalAvoLogger.error) {
        InternalAvoLogger.error('Segment snippet included twice.');
      }
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware'
    ];
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function(key, options){
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/'
        + key + '/analytics.min.js';
      var first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics._writeKey = apiKey;
    analytics.SNIPPET_VERSION = '4.13.2';

    analytics.load(apiKey, options);
  },

  /* Segment does not have revenue api, @see https://segment.com/docs/spec/track/#properties */
  logEvent: function logEvent(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.track(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },

  setUserProperties: function setUserProperties(userProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userProperties, Object.assign({}, {integrations: integrations}));
  },

  identify: function identify(userId, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(userId, {}, Object.assign({}, {integrations: integrations}));
  },

  unidentify: function unidentify(integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.identify(null, {}, Object.assign({}, {integrations: integrations}));
  },

  page: function page(eventName, eventProperties, integrations) {
    if (typeof window === 'undefined') { return; }
    window.analytics.page(eventName, eventProperties, Object.assign({}, {integrations: integrations}));
  },
};

  var SegmentType = {
    DYNAMIC_SEGMENT: "Dynamic Segment",
    MANUALLY_UPLOADED_SEGMENT: "Manually uploaded segment",
  };

  var UploadButtonContext = {
    CREATE_A_MANUAL_SEGMENT: "Create a manual segment",
  };

  var MessageContext = {
    CAMPAIGNS: "Campaigns",
    JOURNEYS: "Journeys",
  };

  var MessageContextStep = {
    SETUP: "Setup",
    DESIGN: "Design",
    REVIEW: "Review",
  };

  var TriggerName = {
    TOAST_ORDER: "Toast Order",
    SQUARE_ORDER: "Square Order",
    KEYWORD: "Keyword",
    POPUP: "Popup",
  };

  var OrderListViewType = {
    LIST_VIEW: "List View",
    CALENDAR_VIEW: "Calendar View",
  };

  var CampaignType = {
    IMMEDIATE: "Immediate",
    SCHEDULE: "Schedule",
  };

  var ExternalButtonSource = {
    VIEW: "View",
    RESPOND: "Respond",
  };

  var ShortLinkButtonContext = {
    ADD_SHORT_LINK: "Add short link",
    EDIT_SHORT_LINK: "Edit short link",
  };

  var StepName = {
    SETUP: "Setup",
    DESIGN: "Design",
    REVIEW: "Review",
  };

  function assertOptionalOrderPartner(orderPartner, label_) {
    var messages = [];
    if (orderPartner !== undefined && orderPartner !== null) {
      messages = messages.concat(AvoAssert.assertString("2j3JJ-9Sxe", label_ ? "order_partner" + ": " + label_ : "order_partner", orderPartner));
    }
    return messages;
  }

  function assertOptionalReviewRating(reviewRating, label_) {
    var messages = [];
    if (reviewRating !== undefined && reviewRating !== null) {
      messages = messages.concat(AvoAssert.assertString("3tgVh8SlHb", label_ ? "review_rating" + ": " + label_ : "review_rating", reviewRating));
    }
    return messages;
  }

  function assertOptionalEmail(email, label_) {
    var messages = [];
    if (email !== undefined && email !== null) {
      messages = messages.concat(AvoAssert.assertString("4gZe94EQ2N", label_ ? "email" + ": " + label_ : "email", email));
    }
    return messages;
  }

  function assertOptionalRestaurantCity(restaurantCity, label_) {
    var messages = [];
    if (restaurantCity !== undefined && restaurantCity !== null) {
      messages = messages.concat(AvoAssert.assertString("631NrPNXjO", label_ ? "restaurant_city" + ": " + label_ : "restaurant_city", restaurantCity));
    }
    return messages;
  }

  function assertOptionalCampaignScheduleTime(campaignScheduleTime, label_) {
    var messages = [];
    if (campaignScheduleTime !== undefined && campaignScheduleTime !== null) {
      messages = messages.concat(AvoAssert.assertString("6Yi6WmywT6t", label_ ? "campaign_schedule_time" + ": " + label_ : "campaign_schedule_time", campaignScheduleTime));
    }
    return messages;
  }

  function assertSegmentType(segmentType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("7bg4qUUsInJ", label_ ? "segment_type" + ": " + label_ : "segment_type", segmentType));
    if ("Dynamic Segment" !== segmentType &&
          "Manually uploaded segment" !== segmentType) {
      var message = (label_ ? "segment_type" + ": " + label_ : "segment_type") + " should match one of the following values [ Dynamic Segment | Manually uploaded segment ] but you provided the value " + segmentType
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "7bg4qUUsInJ", message: message}]);
    }
    return messages;
  }

  function assertOptionalRestaurantAddress2(restaurantAddress2, label_) {
    var messages = [];
    if (restaurantAddress2 !== undefined && restaurantAddress2 !== null) {
      messages = messages.concat(AvoAssert.assertString("7l9N_GknKM", label_ ? "restaurant_address2" + ": " + label_ : "restaurant_address2", restaurantAddress2));
    }
    return messages;
  }

  function assertRestaurantAddress1(restaurantAddress1, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("8AlBCHZAwU", label_ ? "restaurant_address1" + ": " + label_ : "restaurant_address1", restaurantAddress1));
    return messages;
  }

  function assertSegmentName(segmentName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("8YHgraAssre", label_ ? "segment_name" + ": " + label_ : "segment_name", segmentName));
    return messages;
  }

  function assertOptionalOrderStatus(orderStatus, label_) {
    var messages = [];
    if (orderStatus !== undefined && orderStatus !== null) {
      messages = messages.concat(AvoAssert.assertString("8mqRd1XAFu", label_ ? "order_status" + ": " + label_ : "order_status", orderStatus));
    }
    return messages;
  }

  function assertOptionalManualSegmentFilename(manualSegmentFilename, label_) {
    var messages = [];
    if (manualSegmentFilename !== undefined && manualSegmentFilename !== null) {
      messages = messages.concat(AvoAssert.assertString("BFdk99MbJ1H", label_ ? "manual_segment_filename" + ": " + label_ : "manual_segment_filename", manualSegmentFilename));
    }
    return messages;
  }

  function assertManualSegmentFilename(manualSegmentFilename, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("BFdk99MbJ1H", label_ ? "manual_segment_filename" + ": " + label_ : "manual_segment_filename", manualSegmentFilename));
    return messages;
  }

  function assertOptionalRestaurantZipCode(restaurantZipCode, label_) {
    var messages = [];
    if (restaurantZipCode !== undefined && restaurantZipCode !== null) {
      messages = messages.concat(AvoAssert.assertInt("C5KRc35a1", label_ ? "restaurant_zip_code" + ": " + label_ : "restaurant_zip_code", restaurantZipCode));
    }
    return messages;
  }

  function assertCampaignName(campaignName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("D4NsFtG8PT", label_ ? "campaign_name" + ": " + label_ : "campaign_name", campaignName));
    return messages;
  }

  function assertOptionalReviewName(reviewName, label_) {
    var messages = [];
    if (reviewName !== undefined && reviewName !== null) {
      messages = messages.concat(AvoAssert.assertString("F8VJm9t-Rn", label_ ? "review_name" + ": " + label_ : "review_name", reviewName));
    }
    return messages;
  }

  function assertOptionalRestaurantRatingQuantity(restaurantRatingQuantity,
    label_) {
    var messages = [];
    if (restaurantRatingQuantity !== undefined && restaurantRatingQuantity !== null) {
      messages = messages.concat(AvoAssert.assertInt("FZPLEMITE", label_ ? "restaurant_rating_quantity" + ": " + label_ : "restaurant_rating_quantity", restaurantRatingQuantity));
      messages = messages.concat(AvoAssert.assertMin("FZPLEMITE", label_ ? "restaurant_rating_quantity" + ": " + label_ : "restaurant_rating_quantity", 0, restaurantRatingQuantity));
    }
    return messages;
  }

  function assertOptionalReviewSource(reviewSource, label_) {
    var messages = [];
    if (reviewSource !== undefined && reviewSource !== null) {
      messages = messages.concat(AvoAssert.assertString("JcnZnWw6JT", label_ ? "review_source" + ": " + label_ : "review_source", reviewSource));
    }
    return messages;
  }

  function assertWarningMessage(warningMessage, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("KlAALJgD9Q", label_ ? "warning_message" + ": " + label_ : "warning_message", warningMessage));
    return messages;
  }

  function assertOptionalUuid(uuid, label_) {
    var messages = [];
    if (uuid !== undefined && uuid !== null) {
      messages = messages.concat(AvoAssert.assertString("M88P5ipH9r", label_ ? "uuid" + ": " + label_ : "uuid", uuid));
    }
    return messages;
  }

  function assertRestaurantId(restaurantId, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("Mu5DLdMPk", label_ ? "restaurant_id" + ": " + label_ : "restaurant_id", restaurantId));
    return messages;
  }

  function assertPage(page, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("N6aDd07Es", label_ ? "page" + ": " + label_ : "page", page));
    return messages;
  }

  function assertOptionalOrderNumber(orderNumber, label_) {
    var messages = [];
    if (orderNumber !== undefined && orderNumber !== null) {
      messages = messages.concat(AvoAssert.assertInt("Qj-EZJFG_W", label_ ? "order_number" + ": " + label_ : "order_number", orderNumber));
    }
    return messages;
  }

  function assertRestaurantName(restaurantName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("SQtWvmbck", label_ ? "restaurant_name" + ": " + label_ : "restaurant_name", restaurantName));
    return messages;
  }

  function assertOptionalRestaurantState(restaurantState, label_) {
    var messages = [];
    if (restaurantState !== undefined && restaurantState !== null) {
      messages = messages.concat(AvoAssert.assertString("SbedXsDy7", label_ ? "restaurant_state" + ": " + label_ : "restaurant_state", restaurantState));
    }
    return messages;
  }

  function assertOptionalSection(section, label_) {
    var messages = [];
    if (section !== undefined && section !== null) {
      messages = messages.concat(AvoAssert.assertString("TApVZrNlc", label_ ? "section" + ": " + label_ : "section", section));
    }
    return messages;
  }

  function assertOptionalReviewDate(reviewDate, label_) {
    var messages = [];
    if (reviewDate !== undefined && reviewDate !== null) {
      messages = messages.concat(AvoAssert.assertString("UXqYJT58WX", label_ ? "review_date" + ": " + label_ : "review_date", reviewDate));
    }
    return messages;
  }

  function assertOptionalRestaurantRating(restaurantRating, label_) {
    var messages = [];
    if (restaurantRating !== undefined && restaurantRating !== null) {
      messages = messages.concat(AvoAssert.assertFloat("VrPYTwbhh", label_ ? "restaurant_rating" + ": " + label_ : "restaurant_rating", restaurantRating));
      messages = messages.concat(AvoAssert.assertMin("VrPYTwbhh", label_ ? "restaurant_rating" + ": " + label_ : "restaurant_rating", 0.000000, restaurantRating));
      messages = messages.concat(AvoAssert.assertMax("VrPYTwbhh", label_ ? "restaurant_rating" + ": " + label_ : "restaurant_rating", 5.000000, restaurantRating));
    }
    return messages;
  }

  function assertOptionalIntegrationTabName(integrationTabName, label_) {
    var messages = [];
    if (integrationTabName !== undefined && integrationTabName !== null) {
      messages = messages.concat(AvoAssert.assertString("WMn8Ym9Jyh", label_ ? "integration_tab_name" + ": " + label_ : "integration_tab_name", integrationTabName));
    }
    return messages;
  }

  function assertOptionalMessageDelays(messageDelays, label_) {
    var messages = [];
    if (messageDelays !== undefined && messageDelays !== null) {
      messages = messages.concat(AvoAssert.assertList("XH0ZlR1BVT0", "message_delays", messageDelays));
      messageDelays.forEach(function (value, index) {
      });
    }
    return messages;
  }

  function assertUploadButtonContext(uploadButtonContext, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("ZMqKbOWbwf8", label_ ? "upload_button_context" + ": " + label_ : "upload_button_context", uploadButtonContext));
    if ("Create a manual segment" !== uploadButtonContext) {
      var message = (label_ ? "upload_button_context" + ": " + label_ : "upload_button_context") + " should match one of the following values [ Create a manual segment ] but you provided the value " + uploadButtonContext
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "ZMqKbOWbwf8", message: message}]);
    }
    return messages;
  }

  function assertOptionalOrderDate(orderDate, label_) {
    var messages = [];
    if (orderDate !== undefined && orderDate !== null) {
      messages = messages.concat(AvoAssert.assertString("_I2CSqb6CR", label_ ? "order_date" + ": " + label_ : "order_date", orderDate));
    }
    return messages;
  }

  function assertOptionalMessageCount(messageCount, label_) {
    var messages = [];
    if (messageCount !== undefined && messageCount !== null) {
      messages = messages.concat(AvoAssert.assertInt("_gQCzAhBa2n", label_ ? "message_count" + ": " + label_ : "message_count", messageCount));
    }
    return messages;
  }

  function assertSegmentPageName_(segmentPageName_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-page-segment-name", label_ ? "Segment: Page Name" + ": " + label_ : "Segment: Page Name", segmentPageName_));
    return messages;
  }

  function assertUserId_(userId_, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("avo-enriched-type-user-id", label_ ? "User Id" + ": " + label_ : "User Id", userId_));
    return messages;
  }

  function assertOptionalPartnerCatererType(partnerCatererType, label_) {
    var messages = [];
    if (partnerCatererType !== undefined && partnerCatererType !== null) {
      messages = messages.concat(AvoAssert.assertString("bQk-tfqKOt", label_ ? "partner_caterer_type" + ": " + label_ : "partner_caterer_type", partnerCatererType));
    }
    return messages;
  }

  function assertOptionalName(name, label_) {
    var messages = [];
    if (name !== undefined && name !== null) {
      messages = messages.concat(AvoAssert.assertString("cV_37w1G7r", label_ ? "name" + ": " + label_ : "name", name));
    }
    return messages;
  }

  function assertMessageContext(messageContext, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("d9iNIRmfEHj", label_ ? "message_context" + ": " + label_ : "message_context", messageContext));
    if ("Campaigns" !== messageContext && "Journeys" !== messageContext) {
      var message = (label_ ? "message_context" + ": " + label_ : "message_context") + " should match one of the following values [ Campaigns | Journeys ] but you provided the value " + messageContext
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "d9iNIRmfEHj", message: message}]);
    }
    return messages;
  }

  function assertCampaignTabName(campaignTabName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("dmsrh4Kq3q5", label_ ? "campaign_tab_name" + ": " + label_ : "campaign_tab_name", campaignTabName));
    return messages;
  }

  function assertStepAbandonType(stepAbandonType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("fOLJIRTYl-J", label_ ? "step_abandon_type" + ": " + label_ : "step_abandon_type", stepAbandonType));
    return messages;
  }

  function assertMessageContextStep(messageContextStep, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("gMxsbG6rXi2", label_ ? "message_context_step" + ": " + label_ : "message_context_step", messageContextStep));
    if ("Setup" !== messageContextStep && "Design" !== messageContextStep &&
          "Review" !== messageContextStep) {
      var message = (label_ ? "message_context_step" + ": " + label_ : "message_context_step") + " should match one of the following values [ Setup | Design | Review ] but you provided the value " + messageContextStep
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "gMxsbG6rXi2", message: message}]);
    }
    return messages;
  }

  function assertTriggerName(triggerName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("hh__GVjude", label_ ? "trigger_name" + ": " + label_ : "trigger_name", triggerName));
    if ("Toast Order" !== triggerName && "Square Order" !== triggerName &&
          "Keyword" !== triggerName && "Popup" !== triggerName) {
      var message = (label_ ? "trigger_name" + ": " + label_ : "trigger_name") + " should match one of the following values [ Toast Order | Square Order | Keyword | Popup ] but you provided the value " + triggerName
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "hh__GVjude", message: message}]);
    }
    return messages;
  }

  function assertOptionalOrderListViewType(orderListViewType, label_) {
    var messages = [];
    if (orderListViewType !== undefined && orderListViewType !== null) {
      messages = messages.concat(AvoAssert.assertString("jBREsQCOAF", label_ ? "order_list_view_type" + ": " + label_ : "order_list_view_type", orderListViewType));
      if ("List View" !== orderListViewType &&
            "Calendar View" !== orderListViewType) {
        var message = (label_ ? "order_list_view_type" + ": " + label_ : "order_list_view_type") + " should match one of the following values [ List View | Calendar View ] but you provided the value " + orderListViewType
        messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "jBREsQCOAF", message: message}]);
      }
    }
    return messages;
  }

  function assertOptionalSegmentDescription(segmentDescription, label_) {
    var messages = [];
    if (segmentDescription !== undefined && segmentDescription !== null) {
      messages = messages.concat(AvoAssert.assertString("jWOdudosWz", label_ ? "segment_description" + ": " + label_ : "segment_description", segmentDescription));
    }
    return messages;
  }

  function assertSegmentDescription(segmentDescription, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("jWOdudosWz", label_ ? "segment_description" + ": " + label_ : "segment_description", segmentDescription));
    return messages;
  }

  function assertOptionalOrderTotal(orderTotal, label_) {
    var messages = [];
    if (orderTotal !== undefined && orderTotal !== null) {
      messages = messages.concat(AvoAssert.assertFloat("k2b1UHU8jd", label_ ? "order_total" + ": " + label_ : "order_total", orderTotal));
    }
    return messages;
  }

  function assertCampaignType(campaignType, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("k6eJxW4eLNj", label_ ? "campaign_type" + ": " + label_ : "campaign_type", campaignType));
    if ("Immediate" !== campaignType && "Schedule" !== campaignType) {
      var message = (label_ ? "campaign_type" + ": " + label_ : "campaign_type") + " should match one of the following values [ Immediate | Schedule ] but you provided the value " + campaignType
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "k6eJxW4eLNj", message: message}]);
    }
    return messages;
  }

  function assertErrorMessage(errorMessage, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("lOtFaeodbv", label_ ? "error_message" + ": " + label_ : "error_message", errorMessage));
    return messages;
  }

  function assertJourneyName(journeyName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("mNB94dg0tQ", label_ ? "journey_name" + ": " + label_ : "journey_name", journeyName));
    return messages;
  }

  function assertExternalButtonSource(externalButtonSource, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("o3gwhsbb7O", label_ ? "external_button_source" + ": " + label_ : "external_button_source", externalButtonSource));
    if ("View" !== externalButtonSource &&
          "Respond" !== externalButtonSource) {
      var message = (label_ ? "external_button_source" + ": " + label_ : "external_button_source") + " should match one of the following values [ View | Respond ] but you provided the value " + externalButtonSource
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "o3gwhsbb7O", message: message}]);
    }
    return messages;
  }

  function assertOptionalCampaignScheduleTimezone(campaignScheduleTimezone,
    label_) {
    var messages = [];
    if (campaignScheduleTimezone !== undefined && campaignScheduleTimezone !== null) {
      messages = messages.concat(AvoAssert.assertString("pVt9dGZ-z-s", label_ ? "campaign_schedule_timezone" + ": " + label_ : "campaign_schedule_timezone", campaignScheduleTimezone));
    }
    return messages;
  }

  function assertOptionalTriggerId(triggerId, label_) {
    var messages = [];
    if (triggerId !== undefined && triggerId !== null) {
      messages = messages.concat(AvoAssert.assertString("rRv5pt-x-C", label_ ? "trigger_id" + ": " + label_ : "trigger_id", triggerId));
    }
    return messages;
  }

  function assertShortLinkButtonContext(shortLinkButtonContext, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("w-Gebb2yfQo", label_ ? "short_link_button_context" + ": " + label_ : "short_link_button_context", shortLinkButtonContext));
    if ("Add short link" !== shortLinkButtonContext &&
          "Edit short link" !== shortLinkButtonContext) {
      var message = (label_ ? "short_link_button_context" + ": " + label_ : "short_link_button_context") + " should match one of the following values [ Add short link | Edit short link ] but you provided the value " + shortLinkButtonContext
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "w-Gebb2yfQo", message: message}]);
    }
    return messages;
  }

  function assertStepName(stepName, label_) {
    var messages = [];
    messages = messages.concat(AvoAssert.assertString("zBBgp7svQDF", label_ ? "step_name" + ": " + label_ : "step_name", stepName));
    if ("Setup" !== stepName && "Design" !== stepName &&
          "Review" !== stepName) {
      var message = (label_ ? "step_name" + ": " + label_ : "step_name") + " should match one of the following values [ Setup | Design | Review ] but you provided the value " + stepName
      messages = messages.concat([{tag: 'expectedStringMatch', propertyId: "zBBgp7svQDF", message: message}]);
    }
    return messages;
  }

  function setAvoLogger(avoLogger) {
    __AVO_LOGGER__ = avoLogger;
  }

  function setup_(options, systemProperties, destinationOptions) {
    __WEB_DEBUGGER__ = false;
    if (options.validateProperties === true) {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (!__AVO_NOOP__) {
      if (options.useProductionKey) {
        SegmentPartnerDashboard.make(
          "YzgFxvJqu9g73JKCd12ennM73V4uq7je",
          destinationOptions.segmentPartnerDashboard
        );
      } else {
        SegmentPartnerDashboard.make(
          "GYf6xPWG5tyeiR9eXyQMA0CYNAShHyQF",
          destinationOptions.segmentPartnerDashboard
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'setup', [], 'setup');
      }
    }
  }

  function initAvo(options, systemProperties, destinationOptions) {
    if (__AVO_ENV__ !== null) {
      return;
    }
    __AVO_ENV__ = options.env.toLowerCase();
    if (options.avoLogger !== undefined) {
      __AVO_LOGGER__ = options.avoLogger;
    }
    if (options.noop === true) {
      __AVO_NOOP__ = true;
    }
    if (__AVO_NOOP__ && __AVO_ENV__ == 'prod') {
      InternalAvoLogger.warn("****************************************************");
      InternalAvoLogger.warn("WARNING Avo cannot be initialized in noop mode in production:");
      InternalAvoLogger.warn("- Overwriting configuration with noop=false.");
      InternalAvoLogger.warn("- Please reach out if you want to be able to run Avo in production mode with noop=true");
      InternalAvoLogger.warn("****************************************************");
      __AVO_NOOP__ = false;
    }
    if (__AVO_NOOP__) {
      InternalAvoLogger.log("****************************************************");
      InternalAvoLogger.log("Avo is now initialized in noop mode. This means:");
      InternalAvoLogger.log("- No events will be sent");
      InternalAvoLogger.log("- No network requests are made");
      InternalAvoLogger.log("****************************************************");
    }
    if (options.strict !== undefined) {
      __STRICT__ = options.strict !== false;
    }
    if (options.reportFailureAs !== undefined) {
      __REPORT_FAILURE_AS__ = options.reportFailureAs;
    }
    __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && window.location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== 'prod'));
    if (!__AVO_NOOP__ && options.inspector !== undefined) {
      __INSPECTOR__ = options.inspector;
    } else if (__AVO_ENV__ !== 'prod') {
      InternalAvoLogger.warn("Avo Inspector not provided in initAvo() call");
    }
    if (__AVO_ENV__ !== 'prod') {
      __AVO_DEV__ = true;
    }

    destinationOptions = destinationOptions || {};

    if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
(function() {
  if (typeof window === 'undefined') { return; }
  var init = function() {
    if (document.getElementById("avo-debugger") === null) {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.id = "avo-debugger";
      iframe.src = "https://www.avo.app/_debugger";
      iframe.style = "display: none;";
    }
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

    }
    if (!__AVO_NOOP__) {
      if (__AVO_ENV__ === 'prod') {
        SegmentPartnerDashboard.make(
          "YzgFxvJqu9g73JKCd12ennM73V4uq7je",
          destinationOptions.segmentPartnerDashboard
        );
      }
      if (__AVO_ENV__ === 'dev') {
        SegmentPartnerDashboard.make(
          "GYf6xPWG5tyeiR9eXyQMA0CYNAShHyQF",
          destinationOptions.segmentPartnerDashboard
        );
      }
      if (__AVO_DEV__) {
        // debug console in Avo
        _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
      }
    }
  }

  /**
   * General Warning: Event sent when the user performs an action that blocks them

e.g. not having all required fields present in the Cart when hitting Checkout
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   * @param {string} properties.warningMessage - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/hoJAdaIsMn}
   */
  function generalWarning(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(assertWarningMessage(properties.warningMessage));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("General Warning", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2",
        "warningMessage"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "hoJAdaIsMn", "117fac969472d7667127da95dae0b5ae9dea64aa431a490c1be7cf870f49219e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("General Warning", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "warning_message": properties.warningMessage,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("hoJAdaIsMn", "General Warning", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "KlAALJgD9Q", name: "warning_message", value: properties.warningMessage},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'General Warning': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("General Warning", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          "warning_message": properties.warningMessage,
          }, "hoJAdaIsMn", "117fac969472d7667127da95dae0b5ae9dea64aa431a490c1be7cf870f49219e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("General Warning", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "warning_message": properties.warningMessage,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * General Error: Event sent whenever there is an error that occurs in the application
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   * @param {string} properties.errorMessage - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/99IClDtkHa}
   */
  function generalError(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(assertErrorMessage(properties.errorMessage));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("General Error", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2",
        "errorMessage"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "99IClDtkHa", "e84b5f8e33e258bd404cc7b2946c111278235a086ae87d037837d28b98b7f6d3", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("General Error", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "error_message": properties.errorMessage,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("99IClDtkHa", "General Error", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "lOtFaeodbv", name: "error_message", value: properties.errorMessage},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'General Error': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("General Error", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          "error_message": properties.errorMessage,
          }, "99IClDtkHa", "e84b5f8e33e258bd404cc7b2946c111278235a086ae87d037837d28b98b7f6d3");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("General Error", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "error_message": properties.errorMessage,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Login Started: Event sent when a users starts typing in one of the input fields to login to the site
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/rQOv0DOD_i}
   */
  function loginStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Login Started", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "rQOv0DOD_i", "21a9851fb2d0b2c212a7e937e6b7fa496f1c1f855e3fcf3160629e99390ec104", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Login Started", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("rQOv0DOD_i", "Login Started", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Login Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Login Started", {
          "page": properties.page,
          "section": properties.section,
          }, "rQOv0DOD_i", "21a9851fb2d0b2c212a7e937e6b7fa496f1c1f855e3fcf3160629e99390ec104");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Login Started", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Login Completed: Event sent when a users successfully logs in to the website
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.userId_ - The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
   * @param {string} properties.name - no description
   * @param {string} properties.email - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/v-uKCGlp8q}
   */
  function loginCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertUserId_(properties.userId_));
      messages = messages.concat(assertOptionalName(properties.name));
      messages = messages.concat(assertOptionalEmail(properties.email));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Login Completed", Object.keys(properties), [
        "userId_",
        "name",
        "email"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "v-uKCGlp8q", "3ec682b018fcf5a8bd4decab77a28fa1f5804176791e4581e5d836f2864a7824", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Login Completed", {}, {
        "name": properties.name,
        "email": properties.email,
        });
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("v-uKCGlp8q", "Login Completed", messages, [], [
        {id:"4gZe94EQ2N", name: "email", value: properties.email},
        {id:"cV_37w1G7r", name: "name", value: properties.name},
        ]);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Login Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Login Completed", {}, "v-uKCGlp8q", "3ec682b018fcf5a8bd4decab77a28fa1f5804176791e4581e5d836f2864a7824");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.identify(properties.userId_, {});
      SegmentPartnerDashboard.setUserProperties({
        "name": properties.name,
        "email": properties.email,
        }, {});
      SegmentPartnerDashboard.logEvent("Login Completed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Signout Completed: Event sent when a users successfully logs out from the website
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/BsFUjDzGMg}
   */
  function signoutCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Signout Completed", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "BsFUjDzGMg", "52a1447479796eb09f9749da4619451f878b637c2a4df2fb7d297517f241a71a", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Signout Completed", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("BsFUjDzGMg", "Signout Completed", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Signout Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Signout Completed", {
          "page": properties.page,
          "section": properties.section,
          }, "BsFUjDzGMg", "52a1447479796eb09f9749da4619451f878b637c2a4df2fb7d297517f241a71a");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Signout Completed", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      SegmentPartnerDashboard.unidentify({});
    } else {
      // do nothing
    }
  }

  /**
   * Password Reset Started: Event sent when a users starts typing in the input field to reset their password
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/gZObb5wSOF}
   */
  function passwordResetStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Password Reset Started", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "gZObb5wSOF", "32493aa0a51dd3a1035ab2506853028d426318b7fcbd5a4ebfead93f7bfdef08", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Password Reset Started", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("gZObb5wSOF", "Password Reset Started", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Password Reset Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Password Reset Started", {}, "gZObb5wSOF", "32493aa0a51dd3a1035ab2506853028d426318b7fcbd5a4ebfead93f7bfdef08");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Password Reset Started", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Password Reset Completed: Event sent when a users successfully resets their password on the website
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/tBoKNyxZ5-}
   */
  function passwordResetCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Password Reset Completed", Object.keys(properties), [
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "tBoKNyxZ5-", "5273d3b76a620cc3a6b734480ad05ea309e17b61e3898024ef3d1f075fc18c6c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Password Reset Completed", {}, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("tBoKNyxZ5-", "Password Reset Completed", messages, [], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Password Reset Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Password Reset Completed", {}, "tBoKNyxZ5-", "5273d3b76a620cc3a6b734480ad05ea309e17b61e3898024ef3d1f075fc18c6c");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Password Reset Completed", {}, {});
    } else {
      // do nothing
    }
  }

  /**
   * Intercom Chat Initiated: Event sent when user clicks and initiates the Intercom Chat window
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/wem4ldTN68}
   */
  function intercomChatInitiated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Intercom Chat Initiated", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "wem4ldTN68", "bf975fc792a401601f0aea1d56896ba8c317edf0181455ecf1b3977f9dc00b0e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Intercom Chat Initiated", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("wem4ldTN68", "Intercom Chat Initiated", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Intercom Chat Initiated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Intercom Chat Initiated", {
          "page": properties.page,
          "section": properties.section,
          }, "wem4ldTN68", "bf975fc792a401601f0aea1d56896ba8c317edf0181455ecf1b3977f9dc00b0e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Intercom Chat Initiated", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Intercom Chat Abandoned: Event sent when user dismisses the Intercom Chat window
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/rYHQ6njeIn}
   */
  function intercomChatAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Intercom Chat Abandoned", Object.keys(properties), [
        "page",
        "section"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "rYHQ6njeIn", "0f2c683ca577dca8b6fbea0f052c2d148e3564c86f36c53e268fcc82186dd998", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Intercom Chat Abandoned", {
        "page": properties.page,
        "section": properties.section,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("rYHQ6njeIn", "Intercom Chat Abandoned", messages, [
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Intercom Chat Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Intercom Chat Abandoned", {
          "page": properties.page,
          "section": properties.section,
          }, "rYHQ6njeIn", "0f2c683ca577dca8b6fbea0f052c2d148e3564c86f36c53e268fcc82186dd998");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Intercom Chat Abandoned", {
        "page": properties.page,
        "section": properties.section,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Reviews Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/ospwf06js}
   */
  function reviewsPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Reviews Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "ospwf06js", "a60cad39f179ff44364d8fe7d866746194690910a3df53ce1eae0acbcaf8bf23", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Reviews Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("ospwf06js", "Reviews Page Viewed", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Reviews Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Reviews Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          }, "ospwf06js", "a60cad39f179ff44364d8fe7d866746194690910a3df53ce1eae0acbcaf8bf23");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Reviews Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Review Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.reviewSource - no description
   * @param {string} properties.reviewDate - no description
   * @param {string} properties.reviewName - no description
   * @param {string} properties.reviewRating - no description
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3KdMGP3OCL}
   */
  function reviewModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalReviewSource(properties.reviewSource));
      messages = messages.concat(assertOptionalReviewDate(properties.reviewDate));
      messages = messages.concat(assertOptionalReviewName(properties.reviewName));
      messages = messages.concat(assertOptionalReviewRating(properties.reviewRating));
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Review Modal Displayed", Object.keys(properties), [
        "reviewSource",
        "reviewDate",
        "reviewName",
        "reviewRating",
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3KdMGP3OCL", "c0c892586c69b19585a5ced7fe5f544776ea86bd7d41e87049b9e7829050958c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Review Modal Displayed", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3KdMGP3OCL", "Review Modal Displayed", messages, [
        {id: "3tgVh8SlHb", name: "review_rating", value: properties.reviewRating},
        {id: "F8VJm9t-Rn", name: "review_name", value: properties.reviewName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "JcnZnWw6JT", name: "review_source", value: properties.reviewSource},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "UXqYJT58WX", name: "review_date", value: properties.reviewDate},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Review Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Review Modal Displayed", {
          "review_source": properties.reviewSource,
          "review_date": properties.reviewDate,
          "review_name": properties.reviewName,
          "review_rating": properties.reviewRating,
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "3KdMGP3OCL", "c0c892586c69b19585a5ced7fe5f544776ea86bd7d41e87049b9e7829050958c");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Review Modal Displayed", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Review External URL Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.reviewSource - no description
   * @param {string} properties.reviewDate - no description
   * @param {string} properties.reviewName - no description
   * @param {string} properties.reviewRating - no description
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.page - no description
   * @param {string} properties.section - no description
   * @param {string} properties.externalButtonSource - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/PpBMhJXC8k}
   */
  function reviewExternalUrlInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalReviewSource(properties.reviewSource));
      messages = messages.concat(assertOptionalReviewDate(properties.reviewDate));
      messages = messages.concat(assertOptionalReviewName(properties.reviewName));
      messages = messages.concat(assertOptionalReviewRating(properties.reviewRating));
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertPage(properties.page));
      messages = messages.concat(assertOptionalSection(properties.section));
      messages = messages.concat(assertExternalButtonSource(properties.externalButtonSource));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Review External URL Interaction", Object.keys(properties), [
        "reviewSource",
        "reviewDate",
        "reviewName",
        "reviewRating",
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "page",
        "section",
        "externalButtonSource"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "PpBMhJXC8k", "fda90f02a5712f7f56cfacfc563425174d6a3d5b06e3eefc56893bc28504b028", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Review External URL Interaction", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "page": properties.page,
        "section": properties.section,
        "external_button_source": properties.externalButtonSource,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("PpBMhJXC8k", "Review External URL Interaction", messages, [
        {id: "3tgVh8SlHb", name: "review_rating", value: properties.reviewRating},
        {id: "F8VJm9t-Rn", name: "review_name", value: properties.reviewName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "JcnZnWw6JT", name: "review_source", value: properties.reviewSource},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "N6aDd07Es", name: "page", value: properties.page},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "TApVZrNlc", name: "section", value: properties.section},
        {id: "UXqYJT58WX", name: "review_date", value: properties.reviewDate},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "o3gwhsbb7O", name: "external_button_source", value: properties.externalButtonSource},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Review External URL Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Review External URL Interaction", {
          "review_source": properties.reviewSource,
          "review_date": properties.reviewDate,
          "review_name": properties.reviewName,
          "review_rating": properties.reviewRating,
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "page": properties.page,
          "section": properties.section,
          "external_button_source": properties.externalButtonSource,
          }, "PpBMhJXC8k", "fda90f02a5712f7f56cfacfc563425174d6a3d5b06e3eefc56893bc28504b028");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Review External URL Interaction", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "page": properties.page,
        "section": properties.section,
        "external_button_source": properties.externalButtonSource,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Review Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.reviewSource - no description
   * @param {string} properties.reviewDate - no description
   * @param {string} properties.reviewName - no description
   * @param {string} properties.reviewRating - no description
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/vszxvWNoW}
   */
  function reviewModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertOptionalReviewSource(properties.reviewSource));
      messages = messages.concat(assertOptionalReviewDate(properties.reviewDate));
      messages = messages.concat(assertOptionalReviewName(properties.reviewName));
      messages = messages.concat(assertOptionalReviewRating(properties.reviewRating));
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Review Modal Abandoned", Object.keys(properties), [
        "reviewSource",
        "reviewDate",
        "reviewName",
        "reviewRating",
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "vszxvWNoW", "880c01f3142e936f32c17b457075d6af84323aa7a6696ae94350ce9b17f705f4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Review Modal Abandoned", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("vszxvWNoW", "Review Modal Abandoned", messages, [
        {id: "3tgVh8SlHb", name: "review_rating", value: properties.reviewRating},
        {id: "F8VJm9t-Rn", name: "review_name", value: properties.reviewName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "JcnZnWw6JT", name: "review_source", value: properties.reviewSource},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "UXqYJT58WX", name: "review_date", value: properties.reviewDate},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Review Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Review Modal Abandoned", {
          "review_source": properties.reviewSource,
          "review_date": properties.reviewDate,
          "review_name": properties.reviewName,
          "review_rating": properties.reviewRating,
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "vszxvWNoW", "880c01f3142e936f32c17b457075d6af84323aa7a6696ae94350ce9b17f705f4");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Review Modal Abandoned", {
        "review_source": properties.reviewSource,
        "review_date": properties.reviewDate,
        "review_name": properties.reviewName,
        "review_rating": properties.reviewRating,
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Customers Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/fgLqeI6KXt}
   */
  function customersPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Customers Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "fgLqeI6KXt", "1345e4ffe322453524e5af62590a99926affced491f0955c56e640449ee4ac01", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Customers Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("fgLqeI6KXt", "Customers Page Viewed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Customers Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Customers Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "fgLqeI6KXt", "1345e4ffe322453524e5af62590a99926affced491f0955c56e640449ee4ac01");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Customers Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order List Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/hz2Xwk4Z7P}
   */
  function orderListPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order List Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "hz2Xwk4Z7P", "8405de28e538b21084422c2aed31e8b0fbe968e5fc3f55f30245676eb43b4a0f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("hz2Xwk4Z7P", "Order List Page Viewed", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order List Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order List Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          }, "hz2Xwk4Z7P", "8405de28e538b21084422c2aed31e8b0fbe968e5fc3f55f30245676eb43b4a0f");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Order List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Integrations Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3H4m9y9eFd}
   */
  function integrationsPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Integrations Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3H4m9y9eFd", "0c7a11880a42b5aed4cca14e1fffb070d2ef62a1ab029b464e58b36c1a52cb9e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Integrations Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3H4m9y9eFd", "Integrations Page Viewed", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Integrations Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Integrations Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          }, "3H4m9y9eFd", "0c7a11880a42b5aed4cca14e1fffb070d2ef62a1ab029b464e58b36c1a52cb9e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Integrations Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Integrations Tab Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.integrationTabName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/GpKX-TIlrK}
   */
  function integrationsTabInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalIntegrationTabName(properties.integrationTabName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Integrations Tab Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "integrationTabName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "GpKX-TIlrK", "d64ed15a110488dc1e1d4fb7deb3720b98fa4fbfed2f6be0cd0923521fca3a23", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Integrations Tab Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("GpKX-TIlrK", "Integrations Tab Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "WMn8Ym9Jyh", name: "integration_tab_name", value: properties.integrationTabName},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Integrations Tab Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Integrations Tab Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "integration_tab_name": properties.integrationTabName,
          }, "GpKX-TIlrK", "d64ed15a110488dc1e1d4fb7deb3720b98fa4fbfed2f6be0cd0923521fca3a23");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Integrations Tab Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Integration Process Started: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.integrationTabName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/fT_EZhkPUO}
   */
  function integrationProcessStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalIntegrationTabName(properties.integrationTabName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Integration Process Started", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "integrationTabName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "fT_EZhkPUO", "d7ea5f456bb5ab7c0cea1f633cd0b7caa477023d92d8462a346c54c41f11af0b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Integration Process Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("fT_EZhkPUO", "Integration Process Started", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "WMn8Ym9Jyh", name: "integration_tab_name", value: properties.integrationTabName},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Integration Process Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Integration Process Started", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "integration_tab_name": properties.integrationTabName,
          }, "fT_EZhkPUO", "d7ea5f456bb5ab7c0cea1f633cd0b7caa477023d92d8462a346c54c41f11af0b");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Integration Process Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Integration Process Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.integrationTabName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/f0Rkljo_2C}
   */
  function integrationProcessAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalIntegrationTabName(properties.integrationTabName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Integration Process Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "integrationTabName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "f0Rkljo_2C", "bcf96f6d6fc8f1c927f5cd22536bdf314ec30b8feff4baa70ef4df81d9d3dca7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Integration Process Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("f0Rkljo_2C", "Integration Process Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "WMn8Ym9Jyh", name: "integration_tab_name", value: properties.integrationTabName},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Integration Process Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Integration Process Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "integration_tab_name": properties.integrationTabName,
          }, "f0Rkljo_2C", "bcf96f6d6fc8f1c927f5cd22536bdf314ec30b8feff4baa70ef4df81d9d3dca7");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Integration Process Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "integration_tab_name": properties.integrationTabName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Integration Process Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/XfHKqyCXik}
   */
  function integrationProcessCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Integration Process Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "XfHKqyCXik", "2564e1bbe2e0a4e6d52048fda7f0d57579eaa8161e07ae4ff5eac4040750f6f3", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Integration Process Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("XfHKqyCXik", "Integration Process Completed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Integration Process Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Integration Process Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "XfHKqyCXik", "2564e1bbe2e0a4e6d52048fda7f0d57579eaa8161e07ae4ff5eac4040750f6f3");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Integration Process Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order Details Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {int} properties.orderNumber - no description
   * @param {string} properties.orderDate - no description
   * @param {float} properties.orderTotal - no description
   * @param {string} properties.orderStatus - no description
   * @param {string} properties.orderPartner - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/CXLC_q4F56}
   */
  function orderDetailsPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalOrderNumber(properties.orderNumber));
      messages = messages.concat(assertOptionalOrderDate(properties.orderDate));
      messages = messages.concat(assertOptionalOrderTotal(properties.orderTotal));
      messages = messages.concat(assertOptionalOrderStatus(properties.orderStatus));
      messages = messages.concat(assertOptionalOrderPartner(properties.orderPartner));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order Details Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "orderNumber",
        "orderDate",
        "orderTotal",
        "orderStatus",
        "orderPartner"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "CXLC_q4F56", "ef84aa95eebeead78c5f9fc6a77c4a1d62da2f3f4a3b4539740331087ca110ce", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order Details Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "order_number": properties.orderNumber,
        "order_date": properties.orderDate,
        "order_total": properties.orderTotal,
        "order_status": properties.orderStatus,
        "order_partner": properties.orderPartner,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("CXLC_q4F56", "Order Details Page Viewed", messages, [
        {id: "2j3JJ-9Sxe", name: "order_partner", value: properties.orderPartner},
        {id: "8mqRd1XAFu", name: "order_status", value: properties.orderStatus},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "Qj-EZJFG_W", name: "order_number", value: properties.orderNumber},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "_I2CSqb6CR", name: "order_date", value: properties.orderDate},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "k2b1UHU8jd", name: "order_total", value: properties.orderTotal},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order Details Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Details Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "order_number": properties.orderNumber,
          "order_date": properties.orderDate,
          "order_total": properties.orderTotal,
          "order_status": properties.orderStatus,
          "order_partner": properties.orderPartner,
          }, "CXLC_q4F56", "ef84aa95eebeead78c5f9fc6a77c4a1d62da2f3f4a3b4539740331087ca110ce");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Order Details Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "order_number": properties.orderNumber,
        "order_date": properties.orderDate,
        "order_total": properties.orderTotal,
        "order_status": properties.orderStatus,
        "order_partner": properties.orderPartner,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order Details Page Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {int} properties.orderNumber - no description
   * @param {string} properties.orderDate - no description
   * @param {float} properties.orderTotal - no description
   * @param {string} properties.orderStatus - no description
   * @param {string} properties.orderPartner - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/P4OiDFLfl8}
   */
  function orderDetailsPageAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalOrderNumber(properties.orderNumber));
      messages = messages.concat(assertOptionalOrderDate(properties.orderDate));
      messages = messages.concat(assertOptionalOrderTotal(properties.orderTotal));
      messages = messages.concat(assertOptionalOrderStatus(properties.orderStatus));
      messages = messages.concat(assertOptionalOrderPartner(properties.orderPartner));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order Details Page Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "orderNumber",
        "orderDate",
        "orderTotal",
        "orderStatus",
        "orderPartner"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "P4OiDFLfl8", "1b25c18e650cdde0b65104e08ae6a26e99604e100dc2d5f57c92888853d66cfe", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order Details Page Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "order_number": properties.orderNumber,
        "order_date": properties.orderDate,
        "order_total": properties.orderTotal,
        "order_status": properties.orderStatus,
        "order_partner": properties.orderPartner,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("P4OiDFLfl8", "Order Details Page Abandoned", messages, [
        {id: "2j3JJ-9Sxe", name: "order_partner", value: properties.orderPartner},
        {id: "8mqRd1XAFu", name: "order_status", value: properties.orderStatus},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "Qj-EZJFG_W", name: "order_number", value: properties.orderNumber},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "_I2CSqb6CR", name: "order_date", value: properties.orderDate},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "k2b1UHU8jd", name: "order_total", value: properties.orderTotal},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order Details Page Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order Details Page Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "order_number": properties.orderNumber,
          "order_date": properties.orderDate,
          "order_total": properties.orderTotal,
          "order_status": properties.orderStatus,
          "order_partner": properties.orderPartner,
          }, "P4OiDFLfl8", "1b25c18e650cdde0b65104e08ae6a26e99604e100dc2d5f57c92888853d66cfe");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Order Details Page Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "order_number": properties.orderNumber,
        "order_date": properties.orderDate,
        "order_total": properties.orderTotal,
        "order_status": properties.orderStatus,
        "order_partner": properties.orderPartner,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Order List View Changed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   * @param {string} properties.orderListViewType - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/opfobulrGT}
   */
  function orderListViewChanged(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(assertOptionalOrderListViewType(properties.orderListViewType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Order List View Changed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2",
        "orderListViewType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "opfobulrGT", "de661c59218cb5686de4dfe9a3c8dfceac59729c18bf8ab1277701e8de47457d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Order List View Changed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "order_list_view_type": properties.orderListViewType,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("opfobulrGT", "Order List View Changed", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "jBREsQCOAF", name: "order_list_view_type", value: properties.orderListViewType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Order List View Changed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Order List View Changed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          "order_list_view_type": properties.orderListViewType,
          }, "opfobulrGT", "de661c59218cb5686de4dfe9a3c8dfceac59729c18bf8ab1277701e8de47457d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Order List View Changed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        "order_list_view_type": properties.orderListViewType,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Reviews Overview Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/6GapJSFHj2}
   */
  function reviewsOverviewPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Reviews Overview Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "6GapJSFHj2", "db85ff6a997ed8564a3771b7b746d12e37c812382961a8eeec9d2f5c8b141a15", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Reviews Overview Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("6GapJSFHj2", "Reviews Overview Page Viewed", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Reviews Overview Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Reviews Overview Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          }, "6GapJSFHj2", "db85ff6a997ed8564a3771b7b746d12e37c812382961a8eeec9d2f5c8b141a15");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Reviews Overview Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign List Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Aqrj4Y4C-S}
   */
  function campaignListPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign List Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Aqrj4Y4C-S", "f0403df2e811469ffa559afaeb717b80301b9e8af857105504491225e2743129", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Aqrj4Y4C-S", "Campaign List Page Viewed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign List Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign List Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "Aqrj4Y4C-S", "f0403df2e811469ffa559afaeb717b80301b9e8af857105504491225e2743129");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.page(properties.segmentPageName_, {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      SegmentPartnerDashboard.logEvent("Campaign List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Creation Started: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/vE5Z8-pn8t}
   */
  function campaignCreationStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Creation Started", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "vE5Z8-pn8t", "41bb99ba3a7986e8f650c3515a39b9c624879eb34fb5953fd691a9e7eb6ddf5d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Creation Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("vE5Z8-pn8t", "Campaign Creation Started", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Creation Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Creation Started", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "vE5Z8-pn8t", "41bb99ba3a7986e8f650c3515a39b9c624879eb34fb5953fd691a9e7eb6ddf5d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Creation Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Segment Dropdown Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/dvMkpBXSs-_}
   */
  function campaignSegmentDropdownInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Segment Dropdown Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "dvMkpBXSs-_", "b1cc7244f73d8e26dffa98d9cfcbbcf027a738cdf33d4f8a835fd0bc632e1312", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Segment Dropdown Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("dvMkpBXSs-_", "Campaign Segment Dropdown Interaction", messages, [
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Segment Dropdown Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Segment Dropdown Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          }, "dvMkpBXSs-_", "b1cc7244f73d8e26dffa98d9cfcbbcf027a738cdf33d4f8a835fd0bc632e1312");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Segment Dropdown Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Creation Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/WTKSGWLWMT_}
   */
  function campaignCreationAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Creation Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "WTKSGWLWMT_", "0e5eac0ec5e58b6ec8d2483c8ce2b4200faf021b4757a5625b707423dd3e284c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Creation Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("WTKSGWLWMT_", "Campaign Creation Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Creation Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Creation Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "WTKSGWLWMT_", "0e5eac0ec5e58b6ec8d2483c8ce2b4200faf021b4757a5625b707423dd3e284c");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Creation Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Navigation Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.restaurantState - The US State code associated with this event. Exacmple CA, FL etc.
   * @param {int} properties.restaurantZipCode - The ZIP code associated with this event
   * @param {string} properties.restaurantAddress1 - no description
   * @param {string} properties.restaurantCity - no description
   * @param {string} properties.restaurantAddress2 - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/EltDyg8ZJr1}
   */
  function campaignNavigationInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertOptionalRestaurantState(properties.restaurantState));
      messages = messages.concat(assertOptionalRestaurantZipCode(properties.restaurantZipCode));
      messages = messages.concat(assertRestaurantAddress1(properties.restaurantAddress1));
      messages = messages.concat(assertOptionalRestaurantCity(properties.restaurantCity));
      messages = messages.concat(assertOptionalRestaurantAddress2(properties.restaurantAddress2));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Navigation Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "restaurantState",
        "restaurantZipCode",
        "restaurantAddress1",
        "restaurantCity",
        "restaurantAddress2"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "EltDyg8ZJr1", "5b28521c8faa24513f21898002c2d7494112b024d2e598348dfd77da80824d63", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Navigation Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "restaurant_state": properties.restaurantState,
        "restaurant_zip_code": properties.restaurantZipCode,
        "restaurant_address1": properties.restaurantAddress1,
        "restaurant_city": properties.restaurantCity,
        "restaurant_address2": properties.restaurantAddress2,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("EltDyg8ZJr1", "Campaign Navigation Interaction", messages, [
        {id: "631NrPNXjO", name: "restaurant_city", value: properties.restaurantCity},
        {id: "7l9N_GknKM", name: "restaurant_address2", value: properties.restaurantAddress2},
        {id: "8AlBCHZAwU", name: "restaurant_address1", value: properties.restaurantAddress1},
        {id: "C5KRc35a1", name: "restaurant_zip_code", value: properties.restaurantZipCode},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "SbedXsDy7", name: "restaurant_state", value: properties.restaurantState},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Navigation Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Navigation Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "restaurant_state": properties.restaurantState,
          "restaurant_zip_code": properties.restaurantZipCode,
          "restaurant_address1": properties.restaurantAddress1,
          "restaurant_city": properties.restaurantCity,
          "restaurant_address2": properties.restaurantAddress2,
          }, "EltDyg8ZJr1", "5b28521c8faa24513f21898002c2d7494112b024d2e598348dfd77da80824d63");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Send Test Message Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3YyrUfDd711}
   */
  function sendTestMessageInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Send Test Message Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3YyrUfDd711", "e676c0212e334e0d60280fbc86380f63c85ac18c8f10ceeef641ea992a6d5f7d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Send Test Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3YyrUfDd711", "Send Test Message Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Send Test Message Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Send Test Message Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "3YyrUfDd711", "e676c0212e334e0d60280fbc86380f63c85ac18c8f10ceeef641ea992a6d5f7d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Send Test Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Send Test Message Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/chLHyaeTIP_}
   */
  function sendTestMessageModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Send Test Message Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "chLHyaeTIP_", "87c88d16650e42535eeca8a36914baee36e978a83dc722e79330fdea697dd32c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Send Test Message Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("chLHyaeTIP_", "Send Test Message Modal Displayed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Send Test Message Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Send Test Message Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "chLHyaeTIP_", "87c88d16650e42535eeca8a36914baee36e978a83dc722e79330fdea697dd32c");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Send Test Message Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Send Test Message Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/PkzUe08Ig3v}
   */
  function sendTestMessageModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Send Test Message Modal Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "PkzUe08Ig3v", "7f136f498b45f353cd886e250da7ef32d95ffdbe93895acf3406e7389e1e440e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Send Test Message Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("PkzUe08Ig3v", "Send Test Message Modal Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Send Test Message Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Send Test Message Modal Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "PkzUe08Ig3v", "7f136f498b45f353cd886e250da7ef32d95ffdbe93895acf3406e7389e1e440e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Send Test Message Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Test Message Sent: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/FSjU7CaKMhp}
   */
  function testMessageSent(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Test Message Sent", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FSjU7CaKMhp", "8eaa6465e66258f8efb436be20ad93c4250ea01e37c8e30817c8b313befe3f4e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Test Message Sent", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FSjU7CaKMhp", "Test Message Sent", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Test Message Sent': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Test Message Sent", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "FSjU7CaKMhp", "8eaa6465e66258f8efb436be20ad93c4250ea01e37c8e30817c8b313befe3f4e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Test Message Sent", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Step Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/U3KkN5RXGua}
   */
  function campaignStepCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Step Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "U3KkN5RXGua", "214674a5ee47d8468a45d3efc6baddd500d59ea0d1e4e437972dec98d4b6312c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Step Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("U3KkN5RXGua", "Campaign Step Completed", messages, [
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Step Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Step Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          }, "U3KkN5RXGua", "214674a5ee47d8468a45d3efc6baddd500d59ea0d1e4e437972dec98d4b6312c");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Step Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Step Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   * @param {string} properties.stepAbandonType - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/xLBiy25L-At}
   */
  function campaignStepAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(assertStepAbandonType(properties.stepAbandonType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Step Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName",
        "stepAbandonType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "xLBiy25L-At", "0c745998e0b85d17be35df97a713100cfcdc3857ea1c118f454daaf407fb81b0", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Step Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "step_abandon_type": properties.stepAbandonType,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("xLBiy25L-At", "Campaign Step Abandoned", messages, [
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "fOLJIRTYl-J", name: "step_abandon_type", value: properties.stepAbandonType},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Step Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Step Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          "step_abandon_type": properties.stepAbandonType,
          }, "xLBiy25L-At", "0c745998e0b85d17be35df97a713100cfcdc3857ea1c118f454daaf407fb81b0");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Step Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "step_abandon_type": properties.stepAbandonType,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Creation Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   * @param {string} properties.campaignScheduleTime - no description
   * @param {string} properties.campaignScheduleTimezone - no description
   * @param {string} properties.campaignType - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/oZ9qNxVEQra}
   */
  function campaignCreationCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(assertOptionalCampaignScheduleTime(properties.campaignScheduleTime));
      messages = messages.concat(assertOptionalCampaignScheduleTimezone(properties.campaignScheduleTimezone));
      messages = messages.concat(assertCampaignType(properties.campaignType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Creation Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName",
        "campaignScheduleTime",
        "campaignScheduleTimezone",
        "campaignType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "oZ9qNxVEQra", "9d98b19f3a6d1de2882e159e5d2b78f031117456127f939477f1f25dba51678b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Creation Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "campaign_schedule_time": properties.campaignScheduleTime,
        "campaign_schedule_timezone": properties.campaignScheduleTimezone,
        "campaign_type": properties.campaignType,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("oZ9qNxVEQra", "Campaign Creation Completed", messages, [
        {id: "6Yi6WmywT6t", name: "campaign_schedule_time", value: properties.campaignScheduleTime},
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "k6eJxW4eLNj", name: "campaign_type", value: properties.campaignType},
        {id: "pVt9dGZ-z-s", name: "campaign_schedule_timezone", value: properties.campaignScheduleTimezone},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Creation Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Creation Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          "campaign_schedule_time": properties.campaignScheduleTime,
          "campaign_schedule_timezone": properties.campaignScheduleTimezone,
          "campaign_type": properties.campaignType,
          }, "oZ9qNxVEQra", "9d98b19f3a6d1de2882e159e5d2b78f031117456127f939477f1f25dba51678b");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Creation Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "campaign_schedule_time": properties.campaignScheduleTime,
        "campaign_schedule_timezone": properties.campaignScheduleTimezone,
        "campaign_type": properties.campaignType,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign List Page Tab Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignTabName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/EGfeJw1lSPI}
   */
  function campaignListPageTabInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignTabName(properties.campaignTabName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign List Page Tab Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignTabName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "EGfeJw1lSPI", "2fcb068c0ed790f9f455dc8fe96e909fb5f2cabf147dee2527931128043b7b26", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign List Page Tab Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_tab_name": properties.campaignTabName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("EGfeJw1lSPI", "Campaign List Page Tab Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "dmsrh4Kq3q5", name: "campaign_tab_name", value: properties.campaignTabName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign List Page Tab Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign List Page Tab Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_tab_name": properties.campaignTabName,
          }, "EGfeJw1lSPI", "2fcb068c0ed790f9f455dc8fe96e909fb5f2cabf147dee2527931128043b7b26");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign List Page Tab Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_tab_name": properties.campaignTabName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey List Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentPageName_ - Name of the page.
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Ugy_aXprpR2}
   */
  function journeyListPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentPageName_(properties.segmentPageName_));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey List Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentPageName_"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Ugy_aXprpR2", "45784304287aab510461ad61a2ce1028e55ddb9faaa61ccdbdbdbbe7cea11428", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Ugy_aXprpR2", "Journey List Page Viewed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey List Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey List Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "Ugy_aXprpR2", "45784304287aab510461ad61a2ce1028e55ddb9faaa61ccdbdbdbbe7cea11428");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.page(properties.segmentPageName_, {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      SegmentPartnerDashboard.logEvent("Journey List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Creation Started: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/k4EM2MDd7vO}
   */
  function journeyCreationStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Creation Started", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "k4EM2MDd7vO", "6bf0711d7dddee1feb5d4bc3b2c3c17777cb50064262af19afd92a874b91c76b", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Creation Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("k4EM2MDd7vO", "Journey Creation Started", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Creation Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Creation Started", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "k4EM2MDd7vO", "6bf0711d7dddee1feb5d4bc3b2c3c17777cb50064262af19afd92a874b91c76b");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Creation Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Creation Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/9yptxdZF8wt}
   */
  function journeyCreationAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Creation Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "9yptxdZF8wt", "a27074702b12243717360c1cf872ecb87fb60a50ec17a6b09f8b840e105e61fa", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Creation Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("9yptxdZF8wt", "Journey Creation Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Creation Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Creation Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "9yptxdZF8wt", "a27074702b12243717360c1cf872ecb87fb60a50ec17a6b09f8b840e105e61fa");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Creation Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Step Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   * @param {string} properties.stepAbandonType - no description
   * @param {string} properties.stepName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/uDHotTNgnY5}
   */
  function journeyStepAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(assertStepAbandonType(properties.stepAbandonType));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Step Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId",
        "stepAbandonType",
        "stepName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "uDHotTNgnY5", "eee5b54dad4f9644caade4680b4224ba171ab63db8041bd632ba151d6a3edfd3", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Step Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "step_abandon_type": properties.stepAbandonType,
        "step_name": properties.stepName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("uDHotTNgnY5", "Journey Step Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "fOLJIRTYl-J", name: "step_abandon_type", value: properties.stepAbandonType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Step Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Step Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          "step_abandon_type": properties.stepAbandonType,
          "step_name": properties.stepName,
          }, "uDHotTNgnY5", "eee5b54dad4f9644caade4680b4224ba171ab63db8041bd632ba151d6a3edfd3");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Step Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "step_abandon_type": properties.stepAbandonType,
        "step_name": properties.stepName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Step Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   * @param {int} properties.messageCount - no description
   * @param {string} properties.messageDelays - no description
   * @param {string} properties.stepName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/eiPKq06eEvR}
   */
  function journeyStepCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(assertOptionalMessageCount(properties.messageCount));
      messages = messages.concat(assertOptionalMessageDelays(properties.messageDelays));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Step Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId",
        "messageCount",
        "messageDelays",
        "stepName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "eiPKq06eEvR", "58e2b9774fd34640e0a01137c48d9cc84a1b46194abb9439b60ee23dae6c55a9", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Step Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        "step_name": properties.stepName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("eiPKq06eEvR", "Journey Step Completed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "XH0ZlR1BVT0", name: "message_delays", value: properties.messageDelays},
        {id: "_gQCzAhBa2n", name: "message_count", value: properties.messageCount},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Step Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Step Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          "message_count": properties.messageCount,
          "message_delays": properties.messageDelays,
          "step_name": properties.stepName,
          }, "eiPKq06eEvR", "58e2b9774fd34640e0a01137c48d9cc84a1b46194abb9439b60ee23dae6c55a9");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Step Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        "step_name": properties.stepName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Trigger Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/MFdUj5MRvsS}
   */
  function journeyTriggerInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Trigger Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "MFdUj5MRvsS", "b5bc07c090b0560704e7ae3035b536a824da1109ba464f18af086223b1afa531", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Trigger Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("MFdUj5MRvsS", "Journey Trigger Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Trigger Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Trigger Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          }, "MFdUj5MRvsS", "b5bc07c090b0560704e7ae3035b536a824da1109ba464f18af086223b1afa531");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Trigger Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Trigger Filters Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/mEz2RD-ir3u}
   */
  function journeyTriggerFiltersInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Trigger Filters Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "mEz2RD-ir3u", "725bbcb0c841623d1062fd21eec0d992faaa3aad0ccf8b5d2a1d1dcd5fc7bfb4", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Trigger Filters Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("mEz2RD-ir3u", "Journey Trigger Filters Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Trigger Filters Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Trigger Filters Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "mEz2RD-ir3u", "725bbcb0c841623d1062fd21eec0d992faaa3aad0ccf8b5d2a1d1dcd5fc7bfb4");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Journey User Filter Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Aubnj_Xb27O}
   */
  function journeyUserFilterInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey User Filter Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Aubnj_Xb27O", "f51788a68ae0977aa8c290fa634f0ba5cba67142ff1efebc9333c1b5c3014352", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey User Filter Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Aubnj_Xb27O", "Journey User Filter Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey User Filter Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey User Filter Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "Aubnj_Xb27O", "f51788a68ae0977aa8c290fa634f0ba5cba67142ff1efebc9333c1b5c3014352");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Journey Add Message Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   * @param {int} properties.messageCount - no description
   * @param {string} properties.messageDelays - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/yr3B8lGBEAF}
   */
  function journeyAddMessageInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(assertOptionalMessageCount(properties.messageCount));
      messages = messages.concat(assertOptionalMessageDelays(properties.messageDelays));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Add Message Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId",
        "messageCount",
        "messageDelays"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "yr3B8lGBEAF", "feb0851468acdd395b63b5fea379437d7a9f9faea480dcfb0edd9c10b46c96d7", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Add Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("yr3B8lGBEAF", "Journey Add Message Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "XH0ZlR1BVT0", name: "message_delays", value: properties.messageDelays},
        {id: "_gQCzAhBa2n", name: "message_count", value: properties.messageCount},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Add Message Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Add Message Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          "message_count": properties.messageCount,
          "message_delays": properties.messageDelays,
          }, "yr3B8lGBEAF", "feb0851468acdd395b63b5fea379437d7a9f9faea480dcfb0edd9c10b46c96d7");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Add Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Creation Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/LKrjW_2_wM0}
   */
  function journeyCreationCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Creation Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "LKrjW_2_wM0", "062c4896c4f0fccc42623fc4a478643ad59456499b82fe537e66142e82cf2661", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Creation Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("LKrjW_2_wM0", "Journey Creation Completed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Creation Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Creation Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "LKrjW_2_wM0", "062c4896c4f0fccc42623fc4a478643ad59456499b82fe537e66142e82cf2661");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Creation Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Activation Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/DdH8E0z42Z3}
   */
  function journeyActivationModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Activation Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "DdH8E0z42Z3", "759030ce53431876cb242569b26e9c51e409b983ce1f6f3a7904b0ccbf559620", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Activation Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("DdH8E0z42Z3", "Journey Activation Modal Displayed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Activation Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Activation Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "DdH8E0z42Z3", "759030ce53431876cb242569b26e9c51e409b983ce1f6f3a7904b0ccbf559620");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Activation Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Activation Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/FrGkCxUzqz4}
   */
  function journeyActivationModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Activation Modal Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "FrGkCxUzqz4", "44601847b0f4e6943c46ad6584c2da544cb498b22b06badf4867229d2895b50a", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Activation Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("FrGkCxUzqz4", "Journey Activation Modal Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Activation Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Activation Modal Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "FrGkCxUzqz4", "44601847b0f4e6943c46ad6584c2da544cb498b22b06badf4867229d2895b50a");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Activation Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Deactivation Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/6Y0XQt3nm}
   */
  function journeyDeactivationModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Deactivation Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "6Y0XQt3nm", "d1d980d2a1a77b6c2d1e61570e9392dca713a6d6e8399803d79a63ec2b13bdec", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Deactivation Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("6Y0XQt3nm", "Journey Deactivation Modal Displayed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Deactivation Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Deactivation Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          }, "6Y0XQt3nm", "d1d980d2a1a77b6c2d1e61570e9392dca713a6d6e8399803d79a63ec2b13bdec");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Deactivation Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Deactivation Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/7lcL9PpbYer}
   */
  function journeyDeactivationModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Deactivation Modal Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "7lcL9PpbYer", "e2163fb282f284d2bf65ff8dff8c42b6b9daecadc0bef423557284947a85ab16", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Deactivation Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("7lcL9PpbYer", "Journey Deactivation Modal Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Deactivation Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Deactivation Modal Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          }, "7lcL9PpbYer", "e2163fb282f284d2bf65ff8dff8c42b6b9daecadc0bef423557284947a85ab16");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Deactivation Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Activated: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Rgtu4PugfrH}
   */
  function journeyActivated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Activated", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Rgtu4PugfrH", "e956c8f1bacb4239c3ffeb276f8c0c341adbd5f8ea19a1e1b26131e9d8b4a5e2", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Activated", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Rgtu4PugfrH", "Journey Activated", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Activated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Activated", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          }, "Rgtu4PugfrH", "e956c8f1bacb4239c3ffeb276f8c0c341adbd5f8ea19a1e1b26131e9d8b4a5e2");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Activated", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Deactivated: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/USRSczXUnxe}
   */
  function journeyDeactivated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Deactivated", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "USRSczXUnxe", "cd5fec4d229bb4b854cd600bec4dd0db7d36fa5d5675afe7d3c03c62bc3f7e1d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Deactivated", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("USRSczXUnxe", "Journey Deactivated", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Deactivated': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Deactivated", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          }, "USRSczXUnxe", "cd5fec4d229bb4b854cd600bec4dd0db7d36fa5d5675afe7d3c03c62bc3f7e1d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Deactivated", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Journey Remove Message Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.journeyName - no description
   * @param {string} properties.triggerName - no description
   * @param {string} properties.triggerId - no description
   * @param {int} properties.messageCount - no description
   * @param {string} properties.messageDelays - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/at76uRa-55C}
   */
  function journeyRemoveMessageInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertJourneyName(properties.journeyName));
      messages = messages.concat(assertTriggerName(properties.triggerName));
      messages = messages.concat(assertOptionalTriggerId(properties.triggerId));
      messages = messages.concat(assertOptionalMessageCount(properties.messageCount));
      messages = messages.concat(assertOptionalMessageDelays(properties.messageDelays));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Journey Remove Message Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "journeyName",
        "triggerName",
        "triggerId",
        "messageCount",
        "messageDelays"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "at76uRa-55C", "e934c966e7bc6d87e045695dec579aa6ec3097f6eaf594ded45e007ca45b982f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Journey Remove Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("at76uRa-55C", "Journey Remove Message Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "XH0ZlR1BVT0", name: "message_delays", value: properties.messageDelays},
        {id: "_gQCzAhBa2n", name: "message_count", value: properties.messageCount},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "hh__GVjude", name: "trigger_name", value: properties.triggerName},
        {id: "mNB94dg0tQ", name: "journey_name", value: properties.journeyName},
        {id: "rRv5pt-x-C", name: "trigger_id", value: properties.triggerId},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Journey Remove Message Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Journey Remove Message Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "journey_name": properties.journeyName,
          "trigger_name": properties.triggerName,
          "trigger_id": properties.triggerId,
          "message_count": properties.messageCount,
          "message_delays": properties.messageDelays,
          }, "at76uRa-55C", "e934c966e7bc6d87e045695dec579aa6ec3097f6eaf594ded45e007ca45b982f");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Journey Remove Message Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "journey_name": properties.journeyName,
        "trigger_name": properties.triggerName,
        "trigger_id": properties.triggerId,
        "message_count": properties.messageCount,
        "message_delays": properties.messageDelays,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Test Message Warning Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/peCgOcX2Q-3}
   */
  function testMessageWarningDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Test Message Warning Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "peCgOcX2Q-3", "2498382b9b726d842ae195909a8c2c17bfa285510fe063e8b19e44796b0d8c8d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Test Message Warning Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("peCgOcX2Q-3", "Test Message Warning Displayed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Test Message Warning Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Test Message Warning Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "peCgOcX2Q-3", "2498382b9b726d842ae195909a8c2c17bfa285510fe063e8b19e44796b0d8c8d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Test Message Warning Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Schedule Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/nqTPh3JaYlm}
   */
  function campaignScheduleModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Schedule Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "nqTPh3JaYlm", "bd8843c2fdf27ed3b227048423b1358328743be45b3df740c9ca7eb056258bcb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Schedule Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("nqTPh3JaYlm", "Campaign Schedule Modal Displayed", messages, [
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Schedule Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Schedule Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          }, "nqTPh3JaYlm", "bd8843c2fdf27ed3b227048423b1358328743be45b3df740c9ca7eb056258bcb");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Schedule Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Campaign Schedule Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.campaignName - no description
   * @param {string} properties.segmentName - no description
   * @param {string} properties.stepName - no description
   * @param {string} properties.campaignScheduleTime - no description
   * @param {string} properties.campaignScheduleTimezone - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/D_Cy-cZd3Wi}
   */
  function campaignScheduleModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertCampaignName(properties.campaignName));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(assertStepName(properties.stepName));
      messages = messages.concat(assertOptionalCampaignScheduleTime(properties.campaignScheduleTime));
      messages = messages.concat(assertOptionalCampaignScheduleTimezone(properties.campaignScheduleTimezone));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Campaign Schedule Modal Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "campaignName",
        "segmentName",
        "stepName",
        "campaignScheduleTime",
        "campaignScheduleTimezone"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "D_Cy-cZd3Wi", "726d7364ed03a0f8ce5dfc65e0955fa17c1a92325297725e63df11e4be71745d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Campaign Schedule Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "campaign_schedule_time": properties.campaignScheduleTime,
        "campaign_schedule_timezone": properties.campaignScheduleTimezone,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("D_Cy-cZd3Wi", "Campaign Schedule Modal Abandoned", messages, [
        {id: "6Yi6WmywT6t", name: "campaign_schedule_time", value: properties.campaignScheduleTime},
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "D4NsFtG8PT", name: "campaign_name", value: properties.campaignName},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "pVt9dGZ-z-s", name: "campaign_schedule_timezone", value: properties.campaignScheduleTimezone},
        {id: "zBBgp7svQDF", name: "step_name", value: properties.stepName},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Campaign Schedule Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Campaign Schedule Modal Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "campaign_name": properties.campaignName,
          "segment_name": properties.segmentName,
          "step_name": properties.stepName,
          "campaign_schedule_time": properties.campaignScheduleTime,
          "campaign_schedule_timezone": properties.campaignScheduleTimezone,
          }, "D_Cy-cZd3Wi", "726d7364ed03a0f8ce5dfc65e0955fa17c1a92325297725e63df11e4be71745d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Campaign Schedule Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "campaign_name": properties.campaignName,
        "segment_name": properties.segmentName,
        "step_name": properties.stepName,
        "campaign_schedule_time": properties.campaignScheduleTime,
        "campaign_schedule_timezone": properties.campaignScheduleTimezone,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Short Link Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   * @param {string} properties.shortLinkButtonContext - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/9o8Q-BKMwAg}
   */
  function shortLinkInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(assertShortLinkButtonContext(properties.shortLinkButtonContext));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Short Link Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep",
        "shortLinkButtonContext"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "9o8Q-BKMwAg", "aee2e42c0353477cffa7ad05e840cc2631e426b30f6ea8dcf5d37f71c0de377e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Short Link Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        "short_link_button_context": properties.shortLinkButtonContext,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("9o8Q-BKMwAg", "Short Link Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        {id: "w-Gebb2yfQo", name: "short_link_button_context", value: properties.shortLinkButtonContext},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Short Link Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Short Link Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          "short_link_button_context": properties.shortLinkButtonContext,
          }, "9o8Q-BKMwAg", "aee2e42c0353477cffa7ad05e840cc2631e426b30f6ea8dcf5d37f71c0de377e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Short Link Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        "short_link_button_context": properties.shortLinkButtonContext,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Short Link Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/Joqlo1dZ01S}
   */
  function shortLinkModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Short Link Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "Joqlo1dZ01S", "d78fdf88680ad26923cfec02ecc79fdedfeb8c779f5ce062f6bc2c535627b95d", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Short Link Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("Joqlo1dZ01S", "Short Link Modal Displayed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Short Link Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Short Link Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "Joqlo1dZ01S", "d78fdf88680ad26923cfec02ecc79fdedfeb8c779f5ce062f6bc2c535627b95d");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Short Link Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Short Link Modal Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/zn0nVudrzSG}
   */
  function shortLinkModalAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Short Link Modal Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "zn0nVudrzSG", "ee24b901a63b732f8d2cf364eb3e09e3f13bf035b25fb6a3d6c4c76003b7ca0e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Short Link Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("zn0nVudrzSG", "Short Link Modal Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Short Link Modal Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Short Link Modal Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "zn0nVudrzSG", "ee24b901a63b732f8d2cf364eb3e09e3f13bf035b25fb6a3d6c4c76003b7ca0e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Short Link Modal Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Short Link Created: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.messageContext - no description
   * @param {string} properties.messageContextStep - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/7KI0qlQciFQ}
   */
  function shortLinkCreated(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertMessageContext(properties.messageContext));
      messages = messages.concat(assertMessageContextStep(properties.messageContextStep));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Short Link Created", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "messageContext",
        "messageContextStep"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "7KI0qlQciFQ", "aca96ee07a162538c7204cc60966732beb37daa4f979eef3b53bf91a96eaf89e", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Short Link Created", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("7KI0qlQciFQ", "Short Link Created", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "d9iNIRmfEHj", name: "message_context", value: properties.messageContext},
        {id: "gMxsbG6rXi2", name: "message_context_step", value: properties.messageContextStep},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Short Link Created': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Short Link Created", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "message_context": properties.messageContext,
          "message_context_step": properties.messageContextStep,
          }, "7KI0qlQciFQ", "aca96ee07a162538c7204cc60966732beb37daa4f979eef3b53bf91a96eaf89e");
      }
      // destination SegmentPartnerDashboard
      SegmentPartnerDashboard.logEvent("Short Link Created", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "message_context": properties.messageContext,
        "message_context_step": properties.messageContextStep,
        }, {});
    } else {
      // do nothing
    }
  }

  /**
   * Segment List Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/NBXfSXKNx7H}
   */
  function segmentListPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment List Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "NBXfSXKNx7H", "35602e099666f17735d9563b508935950672d93dbadc6b6b20c56f22498a6deb", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment List Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("NBXfSXKNx7H", "Segment List Page Viewed", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment List Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment List Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "NBXfSXKNx7H", "35602e099666f17735d9563b508935950672d93dbadc6b6b20c56f22498a6deb");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Segment Creation Started: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/DaYJWIIiwKz}
   */
  function segmentCreationStarted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment Creation Started", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "DaYJWIIiwKz", "ffe528028a1ac23da87b0dccfe027feedfccefa67761354d8a9718d32a343c26", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment Creation Started", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("DaYJWIIiwKz", "Segment Creation Started", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment Creation Started': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment Creation Started", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "DaYJWIIiwKz", "ffe528028a1ac23da87b0dccfe027feedfccefa67761354d8a9718d32a343c26");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Segment Type Choice Selected: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentType - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/NI97N7OxPvJ}
   */
  function segmentTypeChoiceSelected(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentType(properties.segmentType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment Type Choice Selected", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "NI97N7OxPvJ", "13926a7c90b3a7b188f3340be894cf3a8390684c11c61fb1174692131a04cd6c", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment Type Choice Selected", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "segment_type": properties.segmentType,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("NI97N7OxPvJ", "Segment Type Choice Selected", messages, [
        {id: "7bg4qUUsInJ", name: "segment_type", value: properties.segmentType},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment Type Choice Selected': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment Type Choice Selected", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "segment_type": properties.segmentType,
          }, "NI97N7OxPvJ", "13926a7c90b3a7b188f3340be894cf3a8390684c11c61fb1174692131a04cd6c");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Manual Segment Creation Modal Displayed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentType - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/opMhJ_90C-1}
   */
  function manualSegmentCreationModalDisplayed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentType(properties.segmentType));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Manual Segment Creation Modal Displayed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentType"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "opMhJ_90C-1", "543c93c96ff9205b123c26a562545b21e02b4eecb00e2419c45184e7e44da4e0", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Manual Segment Creation Modal Displayed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "segment_type": properties.segmentType,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("opMhJ_90C-1", "Manual Segment Creation Modal Displayed", messages, [
        {id: "7bg4qUUsInJ", name: "segment_type", value: properties.segmentType},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Manual Segment Creation Modal Displayed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manual Segment Creation Modal Displayed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "segment_type": properties.segmentType,
          }, "opMhJ_90C-1", "543c93c96ff9205b123c26a562545b21e02b4eecb00e2419c45184e7e44da4e0");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Upload Button Interaction: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.uploadButtonContext - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3LQDE5nlnVq}
   */
  function uploadButtonInteraction(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertUploadButtonContext(properties.uploadButtonContext));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Upload Button Interaction", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "uploadButtonContext"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3LQDE5nlnVq", "3cca0c1889afdb3f87d43641ea3d0beda512467fcd4bf6d8f6268ba24eef5759", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Upload Button Interaction", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "upload_button_context": properties.uploadButtonContext,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3LQDE5nlnVq", "Upload Button Interaction", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "ZMqKbOWbwf8", name: "upload_button_context", value: properties.uploadButtonContext},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Upload Button Interaction': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Upload Button Interaction", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "upload_button_context": properties.uploadButtonContext,
          }, "3LQDE5nlnVq", "3cca0c1889afdb3f87d43641ea3d0beda512467fcd4bf6d8f6268ba24eef5759");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Segment Creation Abandoned: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/3UJcggO7e-0}
   */
  function segmentCreationAbandoned(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment Creation Abandoned", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "3UJcggO7e-0", "9b62d0dd59008cf81d37af9ff6929356d4d5169d67a13b104519f932bb1573b1", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment Creation Abandoned", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("3UJcggO7e-0", "Segment Creation Abandoned", messages, [
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment Creation Abandoned': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment Creation Abandoned", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          }, "3UJcggO7e-0", "9b62d0dd59008cf81d37af9ff6929356d4d5169d67a13b104519f932bb1573b1");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Segment Creation Completed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentType - no description
   * @param {string} properties.segmentDescription - no description
   * @param {string} properties.manualSegmentFilename - no description
   * @param {string} properties.segmentName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/eSGpAPoB-nH}
   */
  function segmentCreationCompleted(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentType(properties.segmentType));
      messages = messages.concat(assertOptionalSegmentDescription(properties.segmentDescription));
      messages = messages.concat(assertOptionalManualSegmentFilename(properties.manualSegmentFilename));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment Creation Completed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentType",
        "segmentDescription",
        "manualSegmentFilename",
        "segmentName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "eSGpAPoB-nH", "403ee44935c7116dbeefbcf487e99672bc71fb07bf0ebe19a5faf49b973cbbc1", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment Creation Completed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "segment_type": properties.segmentType,
        "segment_description": properties.segmentDescription,
        "manual_segment_filename": properties.manualSegmentFilename,
        "segment_name": properties.segmentName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("eSGpAPoB-nH", "Segment Creation Completed", messages, [
        {id: "7bg4qUUsInJ", name: "segment_type", value: properties.segmentType},
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "BFdk99MbJ1H", name: "manual_segment_filename", value: properties.manualSegmentFilename},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "jWOdudosWz", name: "segment_description", value: properties.segmentDescription},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment Creation Completed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment Creation Completed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "segment_type": properties.segmentType,
          "segment_description": properties.segmentDescription,
          "manual_segment_filename": properties.manualSegmentFilename,
          "segment_name": properties.segmentName,
          }, "eSGpAPoB-nH", "403ee44935c7116dbeefbcf487e99672bc71fb07bf0ebe19a5faf49b973cbbc1");
      }
    } else {
      // do nothing
    }
  }

  /**
   * Segment Details Page Viewed: No description
   *
   * @param {object} properties - the properties associatied with this event
   * @param {string} properties.restaurantId - The ID of the restaurant associated with this event
   * @param {string} properties.restaurantName - The name of the restaurant associated with this event
   * @param {float} properties.restaurantRating - The rating of the restaurant associated with this event
   * @param {int} properties.restaurantRatingQuantity - The number of ratings for the restaurant associated with this event
   * @param {string} properties.partnerCatererType - no description
   * @param {string} properties.uuid - no description
   * @param {string} properties.segmentType - no description
   * @param {string} properties.segmentDescription - no description
   * @param {string} properties.manualSegmentFilename - no description
   * @param {string} properties.segmentName - no description
   *
   * @see {@link https://www.avo.app/schemas/ZMwnebQikC6pCSNEAgJH/events/pZnVahLkyiL}
   */
  function segmentDetailsPageViewed(properties) {
    properties = properties || {};
    if (__AVO_DEV__ || __WEB_DEBUGGER__) {
      // assert properties
      var messages = [];
      messages = messages.concat(assertRestaurantId(properties.restaurantId));
      messages = messages.concat(assertRestaurantName(properties.restaurantName));
      messages = messages.concat(assertOptionalRestaurantRating(properties.restaurantRating));
      messages = messages.concat(assertOptionalRestaurantRatingQuantity(properties.restaurantRatingQuantity));
      messages = messages.concat(assertOptionalPartnerCatererType(properties.partnerCatererType));
      messages = messages.concat(assertOptionalUuid(properties.uuid));
      messages = messages.concat(assertSegmentType(properties.segmentType));
      messages = messages.concat(assertSegmentDescription(properties.segmentDescription));
      messages = messages.concat(assertManualSegmentFilename(properties.manualSegmentFilename));
      messages = messages.concat(assertSegmentName(properties.segmentName));
      messages = messages.concat(AvoAssert.assertNoAdditionalProperties("Segment Details Page Viewed", Object.keys(properties), [
        "restaurantId",
        "restaurantName",
        "restaurantRating",
        "restaurantRatingQuantity",
        "partnerCatererType",
        "uuid",
        "segmentType",
        "segmentDescription",
        "manualSegmentFilename",
        "segmentName"
      ]));
      // debug console in Avo
      if (!__AVO_NOOP__) {
        _avo_invoke(__AVO_ENV__, "pZnVahLkyiL", "c577302ee59f3a45a97fbef0331d963c33776acbffdb8d095b55e3d1ba99a90f", messages.map(function(m) { return Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType}); }), 'event');
      }

      InternalAvoLogger.logEventSent("Segment Details Page Viewed", {
        "restaurant_id": properties.restaurantId,
        "restaurant_name": properties.restaurantName,
        "restaurant_rating": properties.restaurantRating,
        "restaurant_rating_quantity": properties.restaurantRatingQuantity,
        "partner_caterer_type": properties.partnerCatererType,
        "uuid": properties.uuid,
        "segment_type": properties.segmentType,
        "segment_description": properties.segmentDescription,
        "manual_segment_filename": properties.manualSegmentFilename,
        "segment_name": properties.segmentName,
        }, {});
      if (__WEB_DEBUGGER__) {
        // Avo web debugger
        _avo_debugger_log("pZnVahLkyiL", "Segment Details Page Viewed", messages, [
        {id: "7bg4qUUsInJ", name: "segment_type", value: properties.segmentType},
        {id: "8YHgraAssre", name: "segment_name", value: properties.segmentName},
        {id: "BFdk99MbJ1H", name: "manual_segment_filename", value: properties.manualSegmentFilename},
        {id: "FZPLEMITE", name: "restaurant_rating_quantity", value: properties.restaurantRatingQuantity},
        {id: "M88P5ipH9r", name: "uuid", value: properties.uuid},
        {id: "Mu5DLdMPk", name: "restaurant_id", value: properties.restaurantId},
        {id: "SQtWvmbck", name: "restaurant_name", value: properties.restaurantName},
        {id: "VrPYTwbhh", name: "restaurant_rating", value: properties.restaurantRating},
        {id: "bQk-tfqKOt", name: "partner_caterer_type", value: properties.partnerCatererType},
        {id: "jWOdudosWz", name: "segment_description", value: properties.segmentDescription},
        ], []);
      }
      if (__AVO_DEV__ && (__STRICT__ === null || __STRICT__)) {
        // throw exception if messages is not empty
        if (messages.length !== 0) {
          throw new Error("Error sending event 'Segment Details Page Viewed': " + messages[0].message)
        }
      } else {
        messages.forEach(function(m) {
          console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
        });
      }
    }

    if (!__AVO_NOOP__) {
      if (__INSPECTOR__ != null) {
        __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Segment Details Page Viewed", {
          "restaurant_id": properties.restaurantId,
          "restaurant_name": properties.restaurantName,
          "restaurant_rating": properties.restaurantRating,
          "restaurant_rating_quantity": properties.restaurantRatingQuantity,
          "partner_caterer_type": properties.partnerCatererType,
          "uuid": properties.uuid,
          "segment_type": properties.segmentType,
          "segment_description": properties.segmentDescription,
          "manual_segment_filename": properties.manualSegmentFilename,
          "segment_name": properties.segmentName,
          }, "pZnVahLkyiL", "c577302ee59f3a45a97fbef0331d963c33776acbffdb8d095b55e3d1ba99a90f");
      }
    } else {
      // do nothing
    }
  }

  exports.SegmentType = SegmentType;
  exports.UploadButtonContext = UploadButtonContext;
  exports.MessageContext = MessageContext;
  exports.MessageContextStep = MessageContextStep;
  exports.TriggerName = TriggerName;
  exports.OrderListViewType = OrderListViewType;
  exports.CampaignType = CampaignType;
  exports.ExternalButtonSource = ExternalButtonSource;
  exports.ShortLinkButtonContext = ShortLinkButtonContext;
  exports.StepName = StepName;
  exports.setAvoLogger = setAvoLogger;
  exports.generalWarning = generalWarning;
  exports.generalError = generalError;
  exports.loginStarted = loginStarted;
  exports.loginCompleted = loginCompleted;
  exports.signoutCompleted = signoutCompleted;
  exports.passwordResetStarted = passwordResetStarted;
  exports.passwordResetCompleted = passwordResetCompleted;
  exports.intercomChatInitiated = intercomChatInitiated;
  exports.intercomChatAbandoned = intercomChatAbandoned;
  exports.reviewsPageViewed = reviewsPageViewed;
  exports.reviewModalDisplayed = reviewModalDisplayed;
  exports.reviewExternalUrlInteraction = reviewExternalUrlInteraction;
  exports.reviewModalAbandoned = reviewModalAbandoned;
  exports.customersPageViewed = customersPageViewed;
  exports.orderListPageViewed = orderListPageViewed;
  exports.integrationsPageViewed = integrationsPageViewed;
  exports.integrationsTabInteraction = integrationsTabInteraction;
  exports.integrationProcessStarted = integrationProcessStarted;
  exports.integrationProcessAbandoned = integrationProcessAbandoned;
  exports.integrationProcessCompleted = integrationProcessCompleted;
  exports.orderDetailsPageViewed = orderDetailsPageViewed;
  exports.orderDetailsPageAbandoned = orderDetailsPageAbandoned;
  exports.orderListViewChanged = orderListViewChanged;
  exports.reviewsOverviewPageViewed = reviewsOverviewPageViewed;
  exports.campaignListPageViewed = campaignListPageViewed;
  exports.campaignCreationStarted = campaignCreationStarted;
  exports.campaignSegmentDropdownInteraction = campaignSegmentDropdownInteraction;
  exports.campaignCreationAbandoned = campaignCreationAbandoned;
  exports.campaignNavigationInteraction = campaignNavigationInteraction;
  exports.sendTestMessageInteraction = sendTestMessageInteraction;
  exports.sendTestMessageModalDisplayed = sendTestMessageModalDisplayed;
  exports.sendTestMessageModalAbandoned = sendTestMessageModalAbandoned;
  exports.testMessageSent = testMessageSent;
  exports.campaignStepCompleted = campaignStepCompleted;
  exports.campaignStepAbandoned = campaignStepAbandoned;
  exports.campaignCreationCompleted = campaignCreationCompleted;
  exports.campaignListPageTabInteraction = campaignListPageTabInteraction;
  exports.journeyListPageViewed = journeyListPageViewed;
  exports.journeyCreationStarted = journeyCreationStarted;
  exports.journeyCreationAbandoned = journeyCreationAbandoned;
  exports.journeyStepAbandoned = journeyStepAbandoned;
  exports.journeyStepCompleted = journeyStepCompleted;
  exports.journeyTriggerInteraction = journeyTriggerInteraction;
  exports.journeyTriggerFiltersInteraction = journeyTriggerFiltersInteraction;
  exports.journeyUserFilterInteraction = journeyUserFilterInteraction;
  exports.journeyAddMessageInteraction = journeyAddMessageInteraction;
  exports.journeyCreationCompleted = journeyCreationCompleted;
  exports.journeyActivationModalDisplayed = journeyActivationModalDisplayed;
  exports.journeyActivationModalAbandoned = journeyActivationModalAbandoned;
  exports.journeyDeactivationModalDisplayed = journeyDeactivationModalDisplayed;
  exports.journeyDeactivationModalAbandoned = journeyDeactivationModalAbandoned;
  exports.journeyActivated = journeyActivated;
  exports.journeyDeactivated = journeyDeactivated;
  exports.journeyRemoveMessageInteraction = journeyRemoveMessageInteraction;
  exports.testMessageWarningDisplayed = testMessageWarningDisplayed;
  exports.campaignScheduleModalDisplayed = campaignScheduleModalDisplayed;
  exports.campaignScheduleModalAbandoned = campaignScheduleModalAbandoned;
  exports.shortLinkInteraction = shortLinkInteraction;
  exports.shortLinkModalDisplayed = shortLinkModalDisplayed;
  exports.shortLinkModalAbandoned = shortLinkModalAbandoned;
  exports.shortLinkCreated = shortLinkCreated;
  exports.segmentListPageViewed = segmentListPageViewed;
  exports.segmentCreationStarted = segmentCreationStarted;
  exports.segmentTypeChoiceSelected = segmentTypeChoiceSelected;
  exports.manualSegmentCreationModalDisplayed = manualSegmentCreationModalDisplayed;
  exports.uploadButtonInteraction = uploadButtonInteraction;
  exports.segmentCreationAbandoned = segmentCreationAbandoned;
  exports.segmentCreationCompleted = segmentCreationCompleted;
  exports.segmentDetailsPageViewed = segmentDetailsPageViewed;
  exports.setup_ = setup_;
  exports.initAvo = initAvo;
  exports.avoInspectorApiKey = avoInspectorApiKey;
}(typeof exports === 'undefined' ? this.Avo = {} : exports));

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["generalWarning","generalError","loginStarted","loginCompleted","signoutCompleted","passwordResetStarted","passwordResetCompleted","intercomChatInitiated","intercomChatAbandoned","reviewsPageViewed","reviewModalDisplayed","reviewExternalUrlInteraction","reviewModalAbandoned","customersPageViewed","orderListPageViewed","integrationsPageViewed","integrationsTabInteraction","integrationProcessStarted","integrationProcessAbandoned","integrationProcessCompleted","orderDetailsPageViewed","orderDetailsPageAbandoned","orderListViewChanged","reviewsOverviewPageViewed","campaignListPageViewed","campaignCreationStarted","campaignSegmentDropdownInteraction","campaignCreationAbandoned","campaignNavigationInteraction","sendTestMessageInteraction","sendTestMessageModalDisplayed","sendTestMessageModalAbandoned","testMessageSent","campaignStepCompleted","campaignStepAbandoned","campaignCreationCompleted","campaignListPageTabInteraction","journeyListPageViewed","journeyCreationStarted","journeyCreationAbandoned","journeyStepAbandoned","journeyStepCompleted","journeyTriggerInteraction","journeyTriggerFiltersInteraction","journeyUserFilterInteraction","journeyAddMessageInteraction","journeyCreationCompleted","journeyActivationModalDisplayed","journeyActivationModalAbandoned","journeyDeactivationModalDisplayed","journeyDeactivationModalAbandoned","journeyActivated","journeyDeactivated","journeyRemoveMessageInteraction","testMessageWarningDisplayed","campaignScheduleModalDisplayed","campaignScheduleModalAbandoned","shortLinkInteraction","shortLinkModalDisplayed","shortLinkModalAbandoned","shortLinkCreated","segmentListPageViewed","segmentCreationStarted","segmentTypeChoiceSelected","manualSegmentCreationModalDisplayed","uploadButtonInteraction","segmentCreationAbandoned","segmentCreationCompleted","segmentDetailsPageViewed"]
