import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Stores from './Stores'
import { actions as storeActions } from '../../actions/Store'

const mapStateToProps = state => ({
  lastUsedChainUuid: state.Store.lastUsedChainUuid
})

const mapDispatchToProps = dispatch => ({
  getStoreList() {
    dispatch(storeActions.getStoreList())
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stores))
