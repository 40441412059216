import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import LocalSpinner from '../../LocalSpinner'
import ReportItem from './ReportItem'
import './styles.scss'


/**
 * Report list component to render reports for chain
 *
 * @component
 * @example
 *
 * const data = {
 *   loading: false,
 *   error: null,
 *   reports: {
 *      list: [],
 *      loading: false,
 *      error: null,
 *   }
 * }
 *
 * return (
 *  <ReportList data={reportList} />
 * )
*/
const ReportList = ({ data }) => {
  const { loading, error, list = {} } = data
  const items = get(list, 'reports', [])

  if(loading) {
    return (
      <div className='eg-report-list'>
        <LocalSpinner className='loader' />
      </div>
    )
  }

  if(error) {
    return (
      <div className='eg-report-list error-area'>
        <div className='title'>Something went wrong.</div>
        <div className='no-result'>
          Please refresh the page. If the issue persist please contact us.
        </div>
      </div>
    )
  }

  if(items.length === 0) {
    return (
      <div className='eg-report-list error-area'>
        <div className='title'>No reports</div>
        <div className='no-result'>
        This is where you will see all generated reports from your data
        </div>
      </div>
    )
  }

  return (
    <div className='eg-report-list'>
      <div className='table'>
        <div className='column'>
          <div className='name'>NAME</div>
          <div className='descri'>DESCRIPTION</div>
          <div className='date'>DATE</div>
        </div>
        {
          items.map((item, index) => (
            <ReportItem
              item={item}
              key={index}
            />
          ))
        }
      </div>
    </div>
  )
}

ReportList.propTypes = {
  data: PropTypes.object,
}

export default ReportList
