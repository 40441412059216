import _ from 'lodash'
import { userRoles } from './services/auth'

// Paths
export const PATHS = {
  ORDERS: '/orders',
  DASHBOARD: '/dashboard',
  CUSTOMERS: '/customers',
  CUSTOMER_DETAIL: '/customers/:id',
  CHAINS: '/chains',
  REVIEWS: '/reviews',
  REVIEWS_OVERVIEW: '/reviews/overview',
  REVIEWS_LIST: '/reviews/list',
  STORES: '/stores',
  STORE: '/stores/edit/:uuid',
  STORE_CREATE: '/stores/create',
  CALLS: '/calls',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_NEW_PASSWORD: '/confirm-password',
  ACCOUNT_SETTINGS: '/account-settings',
  SHOPIFY_OAUTH_CALLBACK: '/auth/callback/shopify',
  FACEBOOK_OAUTH_CALLBACK: '/auth/callback/facebook',
  CONSTANT_CONTACT_OAUTH_CALLBACK: '/auth/callback/constant_contact',
  CLOVER_OAUTH_CALLBACK: '/auth/callback/clover',
  ATTENTIVE_OAUTH_CALLBACK: '/auth/callback/attentive',
  MAILCHIMP_OAUTH_CALLBACK: '/auth/callback/mailchimp',
  SQUARE_OAUTH_CALLBACK: '/auth/callback/square',
  FIVETRAN_OAUTH_CALLBACK: '/auth/callback/fivetran',
  GOOGLE_BUSINESS_OAUTH_CALLBACK: '/auth/callback/google_business',
  INTEGRATIONS: '/integrations',
  CHANNELS: '/channels',
  SMS_CAMPAIGNS: '/campaigns',
  SMS_CAMPAIGN: '/campaign',
  SMS_CAMPAIGNS_CREATE: '/campaigns/create',
  SMS_JOURNEYS: '/journeys',
  SMS_JOURNEYS_CREATE: '/journeys/create',
  DYNAMIC_SEGMENTS_CREATE: '/segments/create-dynamic',
  SEGMENTS: '/segments',
  REPORTS: '/reports',
  USER_PLAN: '/user-plan',
  SETTINGS: '/settings'
}

export const HOME_PATH = function(user) {
  const roles = _.get(user, 'roles', [])
  if (_.isEmpty(roles) || _.includes(roles, userRoles.SMS_CAMPAIGNS_READ) || _.includes(roles, userRoles.RESTAURANT_ADMIN)) return PATHS.DASHBOARD
  return PATHS.REVIEWS_OVERVIEW
}

// export const defaultRolePaths = {
//   [userRoles.REVIEW_MANAGER]: PATHS.REVIEWS_OVERVIEW
// }

// Formats
export const DATE_FORMAT = 'MMM D, YYYY'
export const DATE_FORMAT_WITH_DAY = 'MMM D, YYYY (ddd)'
export const TIME_FORMAT = 'hh:mm A'

// db(database) date format to send in the api query.
export const DB_DATE_FORMAT = 'YYYY-MM-DD'

// Misc
// Time (in miliseconds) to wait while user is typing
// and a request is made to the proper endpoint.
export const SEARCH_INPUT_INTERVAL = 500

export const DEFAULT_ITEMS_PER_PAGE = 25

export const GOOGLE_CONNECT_STATUSES = {
  TOKEN_OK: 'token_ok',
  TOKEN_ERROR: 'token_error',
  TOKEN_MISSING: 'token_missing',
}

export const AUTH_KEY = '__AUTH__'

// Key constants to set and retrieve values i.e sessionStorage or localStorage
export const KEYS = {
  LOGIN_PAGE_REFERRER: 'loginPageReferrer'
}

// date stuff
export const DAYS_OF_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export const DAYS_OF_WEEK_SHORT = [
  'S', 'M', 'T', 'W', 'T', 'F', 'S'
]
