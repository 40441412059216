
import { types } from '../actions/SquareConnect'

const initialState = {
  redirection:{
    requesting: false,
    data: null,
    error: null,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SQUARE_CONNECT_INTENT:
      return {
        ...state,
        redirection: {
          requesting: true,
          data: null,
          error: null,
        }
      }

    case types.SQUARE_CONNECT_INTENT_RESOLVED:
      return {
        ...state,
        redirection: {
          requesting: false,
          data: action.data,
          error: null,
        }
      }

    case types.SQUARE_CONNECT_INTENT_FAILED:
      return {
        ...state,
        redirection: {
          requesting: false,
          data: null,
          error: null,
        }
      }
      
    default:
      return state
  }
}
