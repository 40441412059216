export const statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

export const mapDataIn = input => {
  return {
    id: input.uuid,
    location: input.location,
    purpose: input.purpose,
    number: input.phone,
    formattedNumber: input.phone_format_2,
    title: input.title,
    forwardNumbers: input.forward_number,
    status: statuses.ACTIVE
  }
}

export const formatNumber = number => {
  return number.replace(/^(\+1)?(\d{3})(\d{3})(\d{4})/, '($2) $3-$4')
}
