import React from 'react'
import { Input } from './'

export const renderInput = (name, validator, validationRules = { required: true }, placeholder = '') => {
  const { errors, register } = validator
  return (
    <div>
      <Input
        id={ name }
        name={ name }
        className={ errors[name] ? 'error' : '' }
        ref={ register(validationRules) }
        placeholder={ placeholder }
      />
      { errors[name] && <div className='error-msg'>{ errors[name].message }</div> }
    </div>
  )
}
