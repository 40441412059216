import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const EgRadioButton = (props) => {
  const { selected, onClick, label, children, className='' } = props

  return (
    <div className={`eg-radio-button ${className}`} onClick={onClick}>
      <div className={selected ? 'selectedLogo' : 'unSelectedLogo'} />
      <div className='content'>
        <div className='title'>
          {label}
        </div>
        {
          children && <div className='description'>
            {children}
          </div>
        }
      </div>
    </div>
  )
}

EgRadioButton.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default EgRadioButton
