import { connect } from 'react-redux'
import ReviewOverview from './ReviewOverview'
import { actions as reviewActions } from '../../actions/Review'
import { actions as storeActions } from '../../actions/Store'
import { actions as filterActions } from '../../actions/Filter'

const mapStateToProps = state => ({
  restaurant: state.User.chain,
  ratings: state.Review.ratings,
  ratingsLoading: state.Review.ratingsLoading,
  storeBreakdown: state.Review.storeBreakdown,
  lastUsedChainUuid: state.Store.lastUsedChainUuid,
  selectedStores: state.Filter.selectedStores,
})

const mapDispatchToProps = dispatch => ({
  getRatingsSummary(opts) {
    dispatch(reviewActions.getRatingsSummary(opts))
  },

  getStoreBreakDown(opts) {
    dispatch(reviewActions.getStoreBreakDown(opts))
  },

  getStoreList() {
    dispatch(storeActions.getStoreList())
  },

  clearStoreFilter() {
    dispatch(filterActions.setStoreFilter([]))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOverview)
