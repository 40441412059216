import axios from 'axios'
import { METHODS, HeaderFactory, constructStoreQuery } from './utils'

/**
 * get list of events of user
 *
 * @param {number} page - page number to request
 * @param {number} qty - how many elements per page
 * @param {object} tokens - authentication tokens
 */
export const getEvents = (opts, tokens) => {
  let {
    restaurantChainUuid,
    selectedStores,
    page,
    status,
    customerId,
  } = opts
  if (!page) {
    page = 1
  }
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  
  //TODO: Add size(limit) by getting the `qty` parameter from opts/payload
  const qparams = [
    ...storeQueryParams,
    `page=${page}`
  ]
  if (status) {
    qparams.push(`status=${status}`)
  }
  return axios({
    url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers/activities?customer_id=${customerId}&${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}


/**
 * Gets information about all events of a customer
 * @param {*} param0 
 * @param {*} tokens 
 */
export const getEventSummary = (opts, tokens) => {
  let {
    restaurantChainUuid,
    selectedStores,
    customerId,
  } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  
  const qparams = [
    ...storeQueryParams,
  ]
  return axios({
    url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers?customer_id=${customerId}&${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
