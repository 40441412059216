import { useForm } from 'react-hook-form'

// TODO: implement these constants within a custom resolver
export const validationPatterns = {
  PHONE: /^((\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})?$/,
  FLOAT_NUMBER: /^(\d+(\.\d)?\d*)?$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
  URL: /^((https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)?$/,
  EMAIL: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
}

const defaultOptions = {
  mode: 'onChange'
}

export const errorMessages = {
  REQUIRED: 'This field is required',
  PASSWORD_INVALID_FORMAT: 'Password must have at least 8 characters, one number and one letter',
  PASSWORDS_DONT_MATCH: 'Passwords do not match',
  PHONE_INVALID_FORMAT: 'Please use a valid US phone number format: i.e 5555555555, 555-555-5555, +1 5555555555'
}

/**
 * wrapper around react-hook-form to handle form validations
*/
export default options => {
  const {
    register,
    formState,
    errors,
    getValues,
    handleSubmit,
    watch,
    trigger,
    reset,
    setValue,
    control
  } = useForm({ ...defaultOptions, ...options })

  const errorTypes = {
    required: errorMessages.REQUIRED
  }

  Object.keys(errors).forEach(key => {
    const error = errors[key]
    if (errorTypes[error.type]) {
      error.message = errorTypes[error.type]
    }
  })

  return {
    register,
    formState,
    errors,
    getValues,
    handleSubmit,
    watch,
    trigger,
    reset,
    setValue,
    control
  }
}
