import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * get report list
 *
 * @param opts {Object} - options
 * @param opts.chainUuid {string} - chain UUID
 * @param opts.page {number} - page number
 * @param opts.size {number} - reports per page
 * @param tokens {Object} - user auth tokens
 *
 * @returns {Promise} - request promise
*/
export const getReportList = (opts, tokens) => {
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/report`
  const { chainUuid } = opts
  const queryParameters = [
    `chain_uuid=${chainUuid}`,
  ]

  if(opts.page) {
    queryParameters.push(`page=${opts.page}`)
  }
  if(opts.size) {
    queryParameters.push(`size=${opts.size}`)
  }

  return axios({
    url: `${baseUrl}?${queryParameters.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}
