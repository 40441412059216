import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import {actions as EgNotificationActions} from '../../actions/EgNotification'
import { types as EgNotificationTypes } from '../EgNotification/EgNotification'
import './styles.scss'

CopyContent.propTypes = {
  textToCopy: PropTypes.string,
  notificationOnCopy: PropTypes.string,
  children: PropTypes.node,
}

function CopyContent(props) {
  const dispatch = useDispatch()
  const { textToCopy, children, notificationOnCopy } = props

  const showNotification = () => {
    dispatch(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.SUCCESS,
      message: notificationOnCopy || 'Copied to clipboard.'
    }))
  }

  const copyToClipboard = () => {
    // if textToCopy empty or undefined, we won't copy anything
    if(!textToCopy) return
    // Copying text/coupon code on clipboard.
    // reference https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    let input = document.createElement('textarea')
    input.innerHTML = textToCopy
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    showNotification()
  }

  return (
    <div className='eg-copy-container' onClick={copyToClipboard}>
      {children}
      <div className={`copy-logo ${textToCopy ? '' : 'copy-gray'}`} />
    </div>
  )
}

export default CopyContent
