import React, { useState, useEffect } from 'react'
import {
  useSelector
} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Input, Select, Button } from '../../../FormComponents'
import { types as buttonTypes } from '../../../FormComponents/Button'
import useValidator from '../../../../hooks/validator'
import { Controller } from 'react-hook-form'

import EGAnalytic, { analyticEvents } from '../../../../services/analytics'
import './styles.scss'

export const fields = {
  NAME: 'campaign_name',
  SEGMENT: 'campaign_segment'
}

/**
 * SetupCampaign - Step 1 of create SMS campaign flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return (
 *  <SetupCampaign data={ data } onSubmit={ handleSubmit } />
 * )
*/
const SetupCampaign = props => {
  const { data, submitting, onCancel, onSubmit } = props
  const campaignSegment = _.get(data, 'campaign_segment')
  const [selectedSegment, setSelectedSegment] = useState(campaignSegment)
  const chain = useSelector(state => state.User.chain)
  const segmentOptions = useSelector(state => state.Campaign.segmentOptions)
  const { loading, list } = segmentOptions
  const { register, errors, formState, control, setValue, getValues } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.NAME]: data[fields.NAME] || '',
      [fields.SEGMENT]: selectedSegment
    }
  })


  useEffect(() => {
    if(list) {
      const campaignSegment = list.find(s => s.value === data.target)
      if(campaignSegment) {
        setSelectedSegment(campaignSegment)
      } else {
        setSelectedSegment(list[0])
      }
    }
  }, [data.target, list])

  useEffect(() => {
    // Update form value for segment whenever selectedSegment gets updated.
    setValue(fields.SEGMENT, selectedSegment)
  }, [selectedSegment, setValue])

  const formIsValid = () => {
    return formState.isValid
  }

  const handleSegmentChange = segment => {
    const data = getValues()
    EGAnalytic.track(analyticEvents.CAMPAIGN_SEGMENT_DROPDOWN_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[fields.NAME],
      segmentName: segment.label,
      stepName: 'Setup'
    })
    setSelectedSegment(segment)
  }

  const handleCancelClick = () => {
    if (onCancel) onCancel()
  }

  const handleSubmit = () => {
    const data = getValues()

    if (onSubmit) {
      onSubmit('setup', {
        [fields.NAME]: data[fields.NAME],
        [fields.SEGMENT]: data[fields.SEGMENT]
      })
      EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_COMPLETED, {
        ...EGAnalytic.mapChain(chain),
        campaignName: data[fields.NAME],
        segmentName: data[fields.SEGMENT].label,
        stepName: 'Setup'
      })
    }
  }

  return (
    <div className='eg-campaigns-create-setup'>
      <div className='step-body'>
        <div className='title'>Setup campaign</div>
        <div className='subtitle'>
          Give your campaign a name and choose who to send it to.
        </div>
        <div className='form'>
          <label htmlFor={fields.NAME}>Campaign name</label>
          <div className='row'>
            <Input
              id={fields.NAME}
              name={fields.NAME}
              placeholder='Enter campaign name'
              className={`${errors[fields.NAME] ? 'error' : ''}`}
              ref={register({ required: true })}
            />
            {errors[fields.NAME] && <div className='error-msg'>{errors[fields.NAME].message}</div>}
          </div>
          <div className='row'>
            <label htmlFor={fields.SEGMENT}>Who will you send this Campaign to?</label>
            <Controller
              control={control}
              name={fields.SEGMENT}
              render={() => (
                <Select
                  id={fields.SEGMENT}
                  className='segment'
                  options={list}
                  selectedItem={selectedSegment}
                  onChange={handleSegmentChange}
                  loading={loading}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button
          className='action-button'
          text='CANCEL'
          type={buttonTypes.SECONDARY}
          onClick={handleCancelClick}
        />
        <Button
          className='action-button submit'
          text='CONTINUE'
          disabled={!formIsValid()}
          loading={submitting}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

SetupCampaign.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default SetupCampaign
