/**
 * Makes a new array with a new value 
 * @param {Array} modalOffRestors  existing array
 * @param {string} restaurantUuid element to be inserted in the array
 */
export const mapRestaurantsToArray = (modalOffRestors, restaurantUuid) => {
  if (modalOffRestors){
    return [...modalOffRestors, restaurantUuid]
  }   
  return [restaurantUuid]
}
