import React from 'react'
import PropTypes from 'prop-types'
import PlaidLink from 'react-plaid-link'

import LocalSpinner from '../../../../LocalSpinner'
import PopOverMenu from '../../../../PopOverMenu'
import EGAnalytic, { analyticEvents } from '../../../../../services/analytics'

import styles from './index.module.scss'

// Scopes for plaid Auth
const INFORMATION_PRODUCTS = [
  'auth',
  'transactions',
  'identity',
  'assets' 
]

export default class BankAccount extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    plaid: PropTypes.object,
    startTokenExchange: PropTypes.func,
    isConnected: PropTypes.bool.isRequired,
  }

  constructor (props) {
    super(props)

    this.trackIntegrationStart = this.trackIntegrationStart.bind(this)
    this.handleOnExit = this.handleOnExit.bind(this)
    this.handleOnSuccess = this.handleOnSuccess.bind(this)
    this.renderAccountDetail = this.renderAccountDetail.bind(this)
    this.renderPlaidLinkButton = this.renderPlaidLinkButton.bind(this)
    this.renderBody = this.renderBody.bind(this)
  }

  /**
   * Track plaid integration start process
   */
  trackIntegrationStart() {
    EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_STARTED, {
      ...EGAnalytic.mapIntegrationTabDetail(this.props.restaurant),
      integrationTabName: 'Bank integration'
    })
  }

  /**
   * Handles when plaid error while completing the integration on client side
   * @param {*} error 
   * @param {*} metaData Metadata for plaid error while getting user error.
   */
  handleOnExit(error, metaData){
    if(error !== null) {
      console.error('Plaid connection failed because of the error: ', error)
    }
    console.error('Metadata for plaid linking error: ', metaData)
    EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_ABANDONED,  {
      ...EGAnalytic.mapIntegrationTabDetail(this.props.restaurant), 
      integrationTabName: 'Bank integration'
    })
  }

  /**
   * Handles the user public token
   * which we get after user completes plaid flow on client side
   * @param {*} publicToken 
   */
  handleOnSuccess(publicToken) {
    const restaurantUuid = this.props.restaurant.uuid
    this.props.startTokenExchange({ publicToken, restaurantUuid })
  }

  renderAccountDetail() {
    return (
      <>
        <div className={styles.userInfo}>
          <div className={styles.syncWrapper}>
            <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
          </div>
          <div className={styles.userDetails}>
            {/* TODO: Replace with original data 
            or with a better placeholder */}
            {/* <div>The Halal Guys</div>
            <div className={styles.cardNumber}>{`.... ${3465}`}</div> */}
            {this.props.plaid.posting 
              ? <LocalSpinner/>
              : <div>
                Your Bank Account has been successfully connected.
              </div>
            }
          </div>
          <div className={styles.actionContainer}>
            <PopOverMenu className={styles.popOverStyle}>
              <div className="eg-menu-title">
                <button className={styles.dropDownMenu}></button>
              </div>
              <div className="eg-menu-items">
                {this.getPlaidLink('Change account', styles.differentBankLink)}
              </div>
            </PopOverMenu>
          </div>
        </div>
      </>
    )
  }

  getPlaidLink(buttonText, className){
    const uuid = this.props.restaurant.uuid
    return (
      <PlaidLink
        clientName="Eatgeek"
        env={process.env.REACT_APP_PLAID_ENV}
        product={INFORMATION_PRODUCTS}
        publicKey={process.env.REACT_APP_PLAID_KEY}
        onExit={this.handleOnExit}
        onSuccess={this.handleOnSuccess}
        className={className}
        onClick={this.trackIntegrationStart}
        webhook={ `${process.env.REACT_APP_INTEGRATION_URL}/webhooks/plaid_assets/${uuid}` }
      >
        {buttonText}
      </PlaidLink> 
    )
  }
  renderPlaidLinkButton() {
    return (
      <>
        <ul className={styles.infoDescription}>
          <li>
            <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
            Fast, direct, and secure way to receive your earnings each week. No more delays.
          </li>
          <li>
            <span className={`${styles.listIcon} ${styles.iconSecure}`}></span>
            Your bank credentials, balance and transactions will not be shared with Eatgeek.
          </li>
        </ul>
        {
          this.props.plaid.posting 
            ? <LocalSpinner/>
            : this.getPlaidLink('Connect bank', styles.plaidLinkButton)
        }     
      </>
    )
  }

  renderBody() {
    return this.props.isConnected  
      ? this.renderAccountDetail()
      : this.renderPlaidLinkButton()
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.logo} >
          {this.props.isConnected && <div className={styles.check}></div>}
        </div>
        <div className={styles.infoTitle}>
          {this.props.isConnected ? 'Bank account connected' : 'Connect bank account' }
        </div>
        { this.renderBody()}
        <div className={styles.plaidFooter}>
          <div className={styles.footerText}>
            Powered by<div className={styles.logoWrapper}> <div className={styles.plaidLogo}></div> </div>
          </div> 
        </div>
      </div>
    )
  }
}
