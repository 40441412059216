import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from './Card'
import CustomerForm from './CustomerForm'
import SuccessCard from './SuccessCard'
import './styles.scss'

const LoyaltyIVR = props => {
  const [successCard, toggleSuccessCard] = useState(false)
  const {
    source,
    headline,
    subtext,
    discount,
    name,
    phone,
    disabled
  } = props

  const sourceLabel = source ? source.replace(/-/g, ' ') : ''

  const renderForm = () => {
    return (
      <>
        <div className='title'>
          { headline || '<Headline here>' }
        </div>

        <div className='subtitle'>
          { subtext || '<Subtext here>' }
        </div>

        <div className='card'>
          <Card title={ `$${discount || '0'} off` } name={name} phone={phone} />
        </div>

        <div className='customer-info'>
          <CustomerForm 
            toggleSuccessCard={toggleSuccessCard}
            data={ { phone } }
          />
        </div>

        <div className='service-info'>
          <p>
            **the 25 dollar off discount will be deducted on your next purchase with
            <span>{ ` ${sourceLabel}` }</span>.
          </p>
          <p>
            By joining this rewards program I give my express written consent to receive marketing
            text or native mobile messages via automated technology (e.g., automatic telephone
            dialing systems) from <span>{ sourceLabel }</span> at the mobile number provided. Up to eight
            messages per month. Consent to receive messages is not a condition of purchasing any property,
            goods or services. Text STOP to end to 244547. MSG & data rates may apply.
            I also agree to the Privacy Policy & Terms & Conditions.
          </p>
        </div>
      </>
    )
  }
 
  return (
    <div className='eg-loyalty-ivr-container'>
      { disabled && <div className='disabled' /> }
      <div className='eg-loyalty-ivr' data-testid='signup-preview-page'>
        <div className={ `fp-logo ${source}` } />
        {successCard
          ? <SuccessCard sourceLabel={ sourceLabel } />
          : renderForm()
        }     
      </div>
    </div>
  )
}

LoyaltyIVR.propTypes = {
  source: PropTypes.string,
  headline: PropTypes.string,
  subtext: PropTypes.string,
  discount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  phone: PropTypes.string,
  disabled: PropTypes.bool
}

export default LoyaltyIVR
