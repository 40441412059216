import _ from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Order'
import { actions as spinnerActions } from '../actions/Spinner'

import { DEFAULT_ITEMS_PER_PAGE } from '../constants'
import { authorizedRequest } from './Auth'
import { getOrders, getOrderById } from '../api/Order'
import EGAnalytic, { analyticEvents } from '../services/analytics'

/**
 * Get user restaurant orders
 *
 * @param {object} payload
 *    - page: page number
 *    - qty: elements to display
 */
function* getOrdersHandler({ type, opts }) {
  const { restaurantId, restaurantUuid, restaurantChainUuid } = yield select(state => {
    if (state.User.chain) {
      return { 
        restaurantId: state.User.chain.restaurant_id,
        restaurantUuid: state.User.chain.uuid,
        restaurantChainUuid: state.User.chain.restaurant_chain_uuid,
      }
    }
  })
  const { selectedStores } = yield select(({ Filter }) => Filter)
  const res = yield call(authorizedRequest, getOrders, {
    restaurantId,
    restaurantUuid,
    restaurantChainUuid,
    selectedStores,
    page: _.get(opts, 'page') || 1,
    qty: _.get(opts, 'qty') || DEFAULT_ITEMS_PER_PAGE,
    status: _.get(opts, 'status') || 'all',
    customerId: _.get(opts, 'customerId'),
    search: _.get(opts, 'search'),
    startDate: _.get(opts, 'startDate'),
    endDate: _.get(opts, 'endDate'),
    sortColumnData: _.get(opts, 'sortColumnData'),
  })
  if (res && res.data) {
    yield put(actions.getOrdersResolved(res.data))
    yield put(spinnerActions.loadingResolved())
  } else {
    const restaurant = yield select(state => state.User.chain)
    EGAnalytic.track(analyticEvents.GENERAL_WARNING, {
      ...EGAnalytic.mapRestaurant(restaurant),
      warningMessage: `Not able to get restaurant orders response from back-end: ${JSON.stringify(res, null, 2)}`
    })
    yield put(actions.getOrdersRejected(res))
    yield put(spinnerActions.loadingResolved())
  }
}

/**
 * Get order by id
 *
 * @param {object} payload
 *    - orderId
*/
function* getOrderByIdHandler({ data }) {
  yield put(spinnerActions.loading())
  const res = yield call(authorizedRequest, getOrderById, {
    order: data.order
  })
  if (res && res.data) {
    yield put(actions.getSingleOrderResolved(res.data))
  } else {
    yield put(actions.getSingleOrderRejected(res))
  }
  yield put(spinnerActions.loadingResolved())
}


export default function* () {
  yield takeLatest(types.GET_ORDERS_INTENT, getOrdersHandler)
  yield takeLatest(types.GET_SINGLE_ORDER_INTENT, getOrderByIdHandler)
}
