import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'

import './styles.scss'

const customStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (styles) => ({
    ...styles,
    height: 48,
    minHeight: 32,
    border: '1px solid #e1e5e9',
    boxShadow: 'none'
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 16,
    fontSize: 16
  }),
  placeholder: styles => ({
    ...styles,
    color: '#c1c9d1'
  }),
  loadingIndicator: styles => ({
    ...styles,
    color: '#22998f',
    paddingLeft: '16px',
  }),
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator { ...props }>
      <div className='ddindicator' />
    </components.DropdownIndicator>
  )
}

const Select = ({ options, placeholder, className = '', onChange, menuPlacement = 'bottom', selectedItem, id, loading = false }) => {
  const handleChange = e => {
    if (onChange) {
      onChange(e)
    }
  }

  return (
    <div className={ `eg-select ${className}` }>
      <ReactSelect
        inputId={ id }
        options={ options }
        components={{ DropdownIndicator }}
        styles={ customStyles }
        className={ `select ${className}` }
        classNamePrefix='eg-select'
        placeholder={ placeholder }
        defaultValue={ options ? options[0] : null}
        value={ selectedItem }
        onChange={ handleChange }
        menuPlacement={ menuPlacement }
        isLoading={loading }
      />
    </div>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  menuPlacement: PropTypes.string,
  selectedItem: PropTypes.any,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
}

export default Select
