import _ from 'lodash'

export const MAX_CHARS_GSM7 = 160
export const MAX_CHARS_UCS2 = 70

const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

/**
 * Checks if all the chacacters within the input string conform a valid GSM-7 alphabet
 *
 * @param str {string} - string to be evaluated
 * @returns {boolean} - whether the input is GSM-7 or not
*/
export const isGSM7Encoded = str => {
  const regexp = new RegExp('^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$')
  return regexp.test(str)
}

/**
 * For test messages, do the custom field replacements using
 * logged in user data
 *
 * @param text {string} - text to process
 * @param user {Object} - user to get data from
 *
 * @returns {string} - updated text
*/
export const replaceTestCustomFields = (text, user) => {
  return text
    .replace(/\{first_name\}/g, _.get(user, 'given_name', ''))
    .replace(/\{last_name\}/g, _.get(user, 'family_name', ''))
    .replace(/\{email\}/g, _.get(user, 'email', ''))
}

/**
 * Checks if the text has some link that doesn't match with some
 * shortened url.
 *
 * @param text {string} - text to process
 * @param shortlinks {Array} - list of available shortlinks
 *
 * @returns {boolean} - describes if the text contains at least one non-shortened link or not
*/
export const hasNonShortenedLink = (text, shortlinks) => {
  const links = shortlinks.map(s => s && s.long_url)
  const urlsInText = text && text.match(URL_REGEX)
  if (!urlsInText || !urlsInText.length) {
    return false
  }
  for (const url of urlsInText) {
    if (!links.includes(url)) {
      return true
    }
  }
  return false
}

/**
 * Get urls within a text
 *
 * @param text {string} - test to process
 *
 * @returns {Array} - list of urls found
*/
export const getUrlsFromText = text => {
  return text.match(URL_REGEX) || []
}

/**
 * Updates a message with a shorlink. If some url
 * within the text is part of some given shortlink, we replace that link
 * for the shortened version. If the short url was already there don't do nothing.
 * If there was no matches, insert the shortened url at the end of the text.
 *
 * @param text {string} - text to process
 * @param shortlink {Object} - shortlink to compare with
 *
 * @returns {string} - updated text
*/
export const upsertShortlinkInMessage = (text, shortlink) => {
  let message = text
  const short = _.get(shortlink, 'long_url')
  const originalUrl = _.get(shortlink, 'url')
  if (getUrlsFromText(text).includes(originalUrl)) {
    message = text.replace(new RegExp(originalUrl, 'im'), short)
  } else if (!getUrlsFromText(text).includes(short)) {
    message = `${text} ${short}`
  }
  return message
}
