import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import TextArea from '../../../FormComponents/TextArea'
import { actions } from '../../../../actions/Review'
import EmojiPicker from '../../../EmojiPicker'
import Loader from '../../../LocalSpinner'
import styles from './index.module.scss'

const ReviewResponse = props => {
  const dispatch = useDispatch()
  const replyRequest = useSelector(state => state.Review.reply)
  const [reply , setReply] = useState('')
  const [ response, setResponse ] = useState(props.review.response)

  useEffect(() => {
    if(reply && replyRequest.data) {
      setResponse({
        name: 'Manager Replied',
        comment: reply,
        // date
      })
    }

  }, [reply, replyRequest])

  const onReplyChange = (event) => {
    event.preventDefault()
    setReply(event.target.value)
  }

  const sendReply = () => {
    if(!reply) return
    dispatch(actions.sendReply({
      reviewId: props.review.googleCommentId,
      source: 'google',
      replyText: reply
    }))
  }

  const handleEmojiPick  = (emojiObject) => {
    setReply(`${reply} ${emojiObject.emoji
    }`)
  }

  if(!response && props.review.source === 'google' && props.review.isGMBReview) {
    if(replyRequest.sending) {
      return <Loader className={styles.loader}/>
    }
    return (
      <div className={styles.responseForm}>
        <TextArea className={styles.textArea} value={reply} placeholder='Type reply here' onChange={onReplyChange}/>
        <EmojiPicker onPick={handleEmojiPick} />
        <div className={styles.formBottomRow}>
          <div className={styles.replyInfo}>Reply will be automatically posted in Google, and notify reviewer.</div>
          <div className={styles.post} onClick={sendReply}>POST</div>
        </div>

      </div>
    )
  }
  if(!response) {
    return null
  }

  const responseDate = moment(response.date).format('MM/DD/YYYY')
  return (
    <div className={ styles.responseContainer }>
      <div className={ styles.responseHeader }>
        <div className={ styles.responseName }>
          { response.name }
        </div>
        <div className={ styles.responseInfo }>
          Reply posted on <span className={ styles.source }>{ props.review.source }</span>
          <span className={ styles.responseDate }>{ responseDate }</span>
        </div>
      </div>
      <div className={ styles.responseComment }>
        { response.comment }
      </div>
    </div>
  )
}

ReviewResponse.propTypes = {
  review: PropTypes.object
}

export default ReviewResponse
