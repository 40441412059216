import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { METHODS, HeaderFactory } from './utils'

export const getInteractions = async (opts, tokens) => {
  const { chainUuid } = opts
  const qparams = []

  if (opts) {
    if (opts.sort) {
      const field = opts.sort.direction === 'ASC' ? opts.sort.key : `-${opts.sort.key}`
      qparams.push(`sort=${field}`)
    }
    if (opts.page) {
      qparams.push(`page=${opts.page}`)
    }
    if (opts.size) {
      qparams.push(`size=${opts.size}`)
    }
    if (opts.search) {
      qparams.push(`search=${opts.search}`)
    }
    if (opts.sources && opts.sources.length) {
      const src = opts.sources.map((source, i) => `sources[${i}]=${source}`)
      qparams.push(src.join('&'))
    }
    if (opts.sourceTypes && opts.sourceTypes.length) {
      const types = opts.sourceTypes.map((type, i) => `activity_types[${i}]=${type}`)
      qparams.push(types.join('&'))
    }
    if (opts.selectedStores && opts.selectedStores.length) {
      const stores = opts.selectedStores.map((store, i) => `store_uuids[${i}]=${store.uuid}`)
      qparams.push(stores.join('&'))
    }

    if (opts.filters) {
      const date = _.get(opts, 'filters.date.filterKeys[0]')
      if (date) {
        const dateFrom= moment(date).format('YYYY-MM-DD')
        const dateTo= moment().format('YYYY-MM-DD')
        qparams.push(`activity_date[from]=${dateFrom}`)
        qparams.push(`activity_date[to]=${dateTo}`)
      }
    }
  }

  const strParams = `chain_uuid=${chainUuid}&${qparams.join('&')}`

  const res = await axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/activity?${strParams}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })

  return res
}
