import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick } from '../../hooks/shared'
import './styles.scss'

/**
 * Displays menu options for a given list item
 *
 * @component
 * @example
 *
 * const items = [
 *   {
 *     label: 'Some label to display',
 *     onClick: () => { ... }
 *   }
 * ]
 *
 * return (
 *   <ListItemOptions
 *     items={ items }
 *   />
 * )
*/
const ListItemOptions = props => {
  const { options } = props
  const [ active, setActive ] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    setActive(false)
  })

  const handleTriggerClick = (event) => {
    event && event.stopPropagation && event.stopPropagation()
    setActive(!active)
  }

  const handleItemClick = (item) => {
    item.onClick()
    setActive(false)
  }

  return (
    <div className='eg-list-item-options' data-testid='eg-list-item-options' ref={ wrapperRef }>
      <div data-testid='list-item-options-trigger' className='trigger' onClick={ handleTriggerClick } />
      {
        active && (
          <div className='menu'>
            {
              options.map((option, i) => {
                return (
                  <div className='item'
                    key={ `menu-option-${i}` }
                    onClick={(event) =>{
                      event && event.stopPropagation && event.stopPropagation()
                      handleItemClick(option)
                    }}
                  >
                    { option.label }
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}

ListItemOptions.propTypes = {
  options: PropTypes.array.isRequired
}

export default ListItemOptions
