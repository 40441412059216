import { call, takeLatest, put } from 'redux-saga/effects'
import { types, actions } from '../actions/LoyaltyIvr'
import { authorizedRequest } from './Auth'
import { saveSmsLoyalty, saveSmsRatingSetting } from '../api/LoyaltyIvr'
import { mapIvrOut, mapRatingPayload } from '../services/loyaltyIvr'
import { getStore } from '../api/Store'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

function* saveSmsLoyaltyHandler({ data }) {
  try {
    const res = yield call(authorizedRequest, saveSmsLoyalty, {
      data: mapIvrOut(data),
      store: data.store
    })
    if (res && res.data) {
      yield put(actions.saveSmsLoyaltyResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Automation updated successfully'
      }))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    yield put(actions.saveSmsLoyaltyRejected(error))
  }
}

function* saveSmsRatingSettingHandler({ data }) {
  try {
    const res = yield call(authorizedRequest, saveSmsRatingSetting, {
      data: mapRatingPayload(data),
      store: data.store
    })
    if (res && res.data) {
      yield put(actions.saveSmsRatingSettingResolved(res.data))
      yield put(EgNotificationActions.ShowNotification({
        type: EgNotificationTypes.SUCCESS,
        message: 'Automation updated successfully'
      }))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    yield put(actions.saveSmsRatingSettingRejected(error))
  }
}

function* getSavedStoreHandler({ data }) {
  try {
    const res = yield call(authorizedRequest, getStore, {
      restaurantUuid: data.store.uuid
    })
    if (res && res.data) {
      yield put(actions.getSavedStoreResolved(res.data))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    yield put(actions.getSavedStoreRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.SAVE_SMS_LOYALTY_INTENT, saveSmsLoyaltyHandler)
  yield takeLatest(types.SAVE_SMS_RATING_INTENT, saveSmsRatingSettingHandler)
  yield takeLatest(types.GET_SAVED_STORE_INTENT, getSavedStoreHandler)
}
