import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import StarRatings from 'react-star-ratings'

import './styles.scss'


/**
 * A component to ratings form
 * @param {*} props 
 */
const Review = (props) => {
  const {
    headline, 
    subtext,
    logoUrl
  } = props
  const updatedLogo = useSelector(state => get(state, 'LoyaltyIvr.store'))
  const displayLogoUrl = get(updatedLogo, 'rating_logo') || logoUrl

  return (
    <div className='zsf-review'>
      { displayLogoUrl && <img src={displayLogoUrl} className='rating-logo' alt='rating' /> }
      <div className='title'>
        {headline}
      </div>
      <div className='subtitle'>
        {subtext}
      </div>
      <StarRatings
        rating={0}
        starRatedColor="#fac723"
        changeRating={() => {}}
        numberOfStars={5}
        name='rating'
      />
      {/* <div className={`submit ${!rating && 'disabled' }` } onClick={onRatingSubmit}>SUBMIT</div>     */}
      <div className='submit disabled' >SUBMIT</div>    
      <div className='powered-by'>Powered by <span> ZeroStorefront </span></div>
    </div>
  )
}

Review.propTypes = {
  headline: PropTypes.string, 
  subtext: PropTypes.string,
  logoUrl: PropTypes.string
}

export default Review
