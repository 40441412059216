import _ from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Event'


import { DEFAULT_ITEMS_PER_PAGE } from '../constants'
import { authorizedRequest } from './Auth'
import { mapDateArrayAndEvents } from '../services/event'
import { getEvents, getEventSummary } from '../api/Event'


/**
 * Get customer events
 *
 * @param {object} payload
 *    - page: page number
 *    - qty: elements to display
 */
function* getEventsHandler({ type, opts }) {
  const restaurantChainUuid = yield select(state => state.User.chain.uuid)
  const { selectedStores } = yield select(({ Filter }) => Filter)

  const customerId =  _.get(opts, 'customerId')
  const res = yield call(authorizedRequest, getEvents, {
    restaurantChainUuid,
    selectedStores,
    customerId,
    page: _.get(opts, 'page') || 1,
    qty: _.get(opts, 'qty') || DEFAULT_ITEMS_PER_PAGE,
    status: _.get(opts, 'status') || 'all',
  })
  if (res && res.data) {
    const reduxEvent = yield select((state) => state.Event)
    const mapedDateAndEvents = mapDateArrayAndEvents(reduxEvent, res.data.activities)
    yield put(actions.getEventsResolved({ customerId, ...mapedDateAndEvents, ...res.data}))
  } else {
    yield put(actions.getEventsRejected(res))
  }
}


/**
 * Get customer events summary(i.e total events, first event and last event)
 * @param {*} param0 
 */
function* getEventSummaryHandler({ data }) {
  const restaurantChainUuid = yield select(state => state.User.chain.uuid)
  const { selectedStores } = yield select(({ Filter }) => Filter)
  const res = yield call(authorizedRequest, getEventSummary, {
    restaurantChainUuid,
    selectedStores,
    customerId: data.customerId
  })
  if (res && res.data) {
    yield put(actions.getEventSummaryResolved(res.data))
  } else {
    yield put(actions.getEventSummaryRejected(res))
  }
}


export default function* () {
  yield takeLatest(types.GET_EVENTS_INTENT, getEventsHandler)
  yield takeLatest(types.GET_EVENT_SUMMARY_INTENT, getEventSummaryHandler)
}
