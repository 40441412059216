import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { interactionTypes, interactionTypeLabels, filterTypes } from '../../../../services/customers'
import MultipleSelectionFilter from '../../../CommonFilters/MultipleSelectionFilter'
import FilterContent from './FilterContent'

const defaultActionLabel = 'Interactions'

const filterInteractionTypes = Object.values(interactionTypes)

const InteractionsFilter = props => {
  const handleApply = (filter) => {
    if (props.onApply) {
      props.onApply(filter)
    }
  }

  const handleClear = (filter) => {
    if (props.onClear) {
      props.onClear(filter)
    }
  }

  let filterItems = []
  Object.keys(filterInteractionTypes).forEach(source => {
    const types = Object.values(filterInteractionTypes[source])
    types.forEach(type => {
      filterItems.push({
        key: type,
        label: interactionTypeLabels[type],
        value: false
      })
    })
  })

  filterItems = _.uniqBy(filterItems, 'key')

  return (
    <div className=''>
      <MultipleSelectionFilter
        defaultActionLabel={ defaultActionLabel }
        filterItems={ filterItems }
        filterType={ filterTypes.INTERACTION_TYPES }
        FilterContent={ FilterContent }
        onApply={ handleApply }
        onClear={ handleClear }
      />
    </div>
  )
}

InteractionsFilter.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

export default InteractionsFilter
