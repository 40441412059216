import { call, takeLatest, select, put, delay } from 'redux-saga/effects'

import { types, actions as plaidActions } from '../actions/Plaid'
import { actions as storeActions } from '../actions/Store'
import { exchangePlaidToken } from '../api/Plaid'
import { authorizedRequest } from './Auth'
import EGAnalytic, { analyticEvents } from '../services/analytics'

import { showErrorNotification, showSuccessNotification } from './util'


/**
 * handle Plaid connect to retreive an authCode
 * and then send it to our backend
*/
function* connectPlaidAccount(payload) {
  const { publicToken, restaurantUuid } = payload.opts
  try {
    let response = yield call(authorizedRequest, exchangePlaidToken, {
      restaurantUuid,
      publicToken,
    })
    yield handleResponse(response) 
  }
  catch (error) {
    yield handleError(error)
  }
}

function* handleResponse(res) {
  if(res && res.status === 200) {
    const restaurant = yield select(({ Store }) =>  Store.store.storeInfo)
    // Plaid account succesfully connnected, let's update the connection
    // status by calling the integration status api.
    yield put(storeActions.getIntegrationStatus({ restaurantUuid: restaurant.uuid }))
    yield delay(500)
    yield showSuccessNotification('Bank account succesfully connected')
    yield put(plaidActions.tokenExchangeResolved(res))
    EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_COMPLETED,  {
      ...EGAnalytic.mapIntegrationTabDetail(restaurant)
    })
   
  } else if(res && res.status === 403) {
    // Forbidden, normally in case where admin account tries to connect 
    // plaid account for a restaurant which the Admin/user doesn't own
    yield showErrorNotification('Restaurant does not belong to current user.')
    console.error('Restaurant does not belong to current user.', res)
    yield put(plaidActions.tokenExchangeFailed(res))
  } else if(res && res.status === 404) {
    console.error('Restaurant not in eg-database. ', res)
    yield put(plaidActions.tokenExchangeFailed(res))
    yield showErrorNotification('Restaurant not in Plaid database, Please contact us.')
  } else {
    yield handleError(res)
  }
}

function* handleError(error) {
  console.error(error)
  const restaurant = yield select(({ Store }) =>  Store.store.storeInfo)
  EGAnalytic.track(analyticEvents.GENERAL_ERROR, {
    ...EGAnalytic.mapRestaurant(restaurant),
    errorMessage: `Error in plaid Integarion process: ${JSON.stringify(error, null, 2)}`
  })
  yield put(plaidActions.tokenExchangeFailed(error))
  yield showErrorNotification('Something went wrong with the request. Please try again or contact us for support.')
}

export default function* () {
  yield takeLatest(types.INITIATE_TOKEN_EXCHANGE, connectPlaidAccount)
}
