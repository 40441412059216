import React from 'react'
import PropTypes from 'prop-types'

import { MAX_CHARS_GSM7, MAX_CHARS_UCS2, isGSM7Encoded } from '../../../../../../services/sms'

import './styles.scss'

/**
 * SMS Character counter addon. It counts backwards depending on the given charset.
 * If all the characters within the text input are GSM-7, then the max length of each
 * segment will be MAX_CHARS_GSM7. If at least one character does not belong to GSM-7
 * alphabet, each segment will have a max length of MAX_CHARS_UCS2.
 *
 * For more information read https://www.twilio.com/docs/glossary/what-is-gsm-7-character-encoding
 *
 * @component
 * @example
 *
 * return (
 *   <CharacterCounter text='some text here...' />
 * )
*/
const CharacterCounter = props => {
  const { text, isMMS = false } = props
  const maxChars = isGSM7Encoded(text) ? MAX_CHARS_GSM7 : MAX_CHARS_UCS2
  const remaining = maxChars - (text.length % maxChars || maxChars)
  const smsQty = Math.ceil(text.length / maxChars)
  let label = ''

  if (isMMS) {
    if (smsQty > 1) {
      label = `${remaining} | 1 MMS + ${smsQty - 1} SMS`
    } else {
      label = `${remaining} | 1 MMS`
    }
  } else {
    label = `${remaining} | ${smsQty} SMS`
  }


  return (
    <div className='eg-character-counter'>
      { label }
      <div className='help-icon' data-testid='help-hover'/>
      <div className='info-text' data-testid='info-text'>The number of MMS/SMS messages you’ll be billed for is determined by a number of factors like adding an image  or using emojis.</div>
    </div>
  )
}

CharacterCounter.propTypes = {
  text: PropTypes.string,
  isMMS: PropTypes.bool
}

export default CharacterCounter
