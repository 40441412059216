export const types = {
  SQUARE_CONNECT_INTENT: 'SQUARE_CONNECT_INTENT',
  SQUARE_CONNECT_INTENT_RESOLVED: 'SQUARE_CONNECT_INTENT_RESOLVED',
  SQUARE_CONNECT_INTENT_FAILED: 'SQUARE_CONNECT_INTENT_FAILED',
}

export const actions = {
  squareConnect(opts) {
    return {
      type: types.SQUARE_CONNECT_INTENT,
      opts
    }
  },
  squareConnectResolved() {
    return {
      type: types.SQUARE_CONNECT_INTENT_RESOLVED
    }
  },
  squareConnectRejected(error) {
    return {
      type: types.SQUARE_CONNECT_INTENT_FAILED,
      data: error
    }
  },
}
