import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * uploads an MMS image
 *
 * @param opts {Object} - request options
 * @param opts.chainUuid {string} - chain UUID
 * @param opts.resource {string} - resource in which the image will be uploaded
 * @param opts.resourceId {string} - resource identifier
 * @param opts.file {File} - object representing the file to be uploaded
 *
 * @returns {Promise} - response promise
*/
export const uploadImage = async (opts, tokens) => {
  const {
    chainUuid,
    resource,
    resourceId,
    file
  } = opts

  const signedUrlResponse = await axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/${resource}/${resourceId}/mms?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify({
      filename: file.name,
      content_type: file.type
    })
  })

  await axios.request({
    method: 'put',
    url: signedUrlResponse.data.upload_url,
    data: file,
    headers: {
      'Content-Type': file.type
    },
  })

  return signedUrlResponse
}
