import React from 'react'
import PropTypes from 'prop-types'

import { predicateTypes } from '../../../../../services/segments'
import DatePredicate from '../../Predicates/DatePredicate'
import TriggerTypePredicate from '../../Predicates/TriggerTypePredicate'
import './styles.scss'


const SignUpTemplate = props => {
  const { predicates, onDateChange, onChange, onClearPredicates } = props

  const datePredicates = predicates.filter((item) => item.type === predicateTypes.DATE )
  const triggerTypePredicate = predicates.find((item) => item.type === predicateTypes.SIGN_UP )
  const triggerItemPredicate = predicates.find((item) => item.type === predicateTypes.ID)

  return (
    <>
      <div className='text-node'> with </div>
      <TriggerTypePredicate
        triggerTypePredicate={triggerTypePredicate}
        triggerItemPredicate={triggerItemPredicate}
        onChange={onChange}
      />
      <div className='predicate-cell'>
        <DatePredicate
          onDateChange={onDateChange}
          datePredicates={datePredicates}
          onClearPredicates={onClearPredicates}
        />
      </div>
    </>
  )
}

SignUpTemplate.propTypes = {
  predicates: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearPredicates: PropTypes.func.isRequired,
}

export default SignUpTemplate
