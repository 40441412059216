import EGAnalytic, { analyticEvents } from '../services/analytics'

export const toHTML = string => {
  if (!string) {
    return { __html: '' }
  }
  let result = ''
  string = stripXmlTags(string)
  const parts = string.split(/\n/)
  if (!parts.length) {
    return string
  }
  parts.forEach(part => {
    result = `${result}\n<p>${part}</p>`
  })
  return { __html: result }
}

export const stripXmlTags = string => {
  if (!string) return ''
  return string.replace(/<[^>]+>/g, '')
}

export const getAbsoluteUrl = url => {
  if (url.match(/^https?:\/\//)) {
    return url
  }
  return `http://${url}`
}

export const trackIntercomEvents = () => {
  let interval = null
  let setIntercomTrackCallbacks = () => {
    if(window.Intercom  && !window.intercomEventsTracked ) {
      //Tracking event for Intercom open and close
      window.Intercom('onShow', () =>  {  
        EGAnalytic.track(analyticEvents.INTERCOM_CHAT_INITIATED, {
          page: window.location.pathname
        })
      })
      window.Intercom('onHide', () =>  {  
        EGAnalytic.track(analyticEvents.INTERCOM_CHAT_ABANDONED, {
          page: window.location.pathname
        })
      })
      window.intercomEventsTracked = true
      clearInterval(interval)
    }
  }
  interval =  setInterval(setIntercomTrackCallbacks, 2000)
}

export const groupBy = (array, key) => {
  return array.reduce((sum, item) => {
    (sum[item[key]] = sum[item[key]] || []).push(item)
    return sum
  }, {})
}
