import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { reviewSources } from '../../../../services/reviews'
import FilterOptions from '../../../FilterOptions'
import RatingSource from '../../RatingSource'

const defaultActionLabel = 'All sources'

const SourceFilter = props => {
  const [ selectedItems, setSelectedItems ] = useState([])

  const handleFilterChange = (items, cb) => {
    setSelectedItems(items)
    const srcString = items.length === 1 ? 'source' : 'sources'
    const actionLabel = !items.length ? defaultActionLabel : `${items.length} ${srcString}`
    if (cb) {
      cb({ filterKeys: items, actionLabel })
    }
  }

  const handleClear = () => {
    setSelectedItems([])
    if (props.onChange) {
      props.onClear({
        filterKeys: [],
        actionLabel: defaultActionLabel
      })
    }
  }

  const items = [
    { key: reviewSources.GOOGLE, content: () => <RatingSource source={ reviewSources.GOOGLE } /> },
    { key: reviewSources.YELP, content: () => <RatingSource source={ reviewSources.YELP } /> },
    { key: reviewSources.FACEBOOK, content: () => <RatingSource source={ reviewSources.FACEBOOK } /> },
    { key: reviewSources.TRIP_ADVISOR, content: () => <RatingSource source={ reviewSources.TRIP_ADVISOR } /> },
    { key: reviewSources.ZEROSTOREFRONT, content: () => <RatingSource source={reviewSources.ZEROSTOREFRONT} /> }
  ]

  // update internal state when prop changes
  useEffect(() => {
    setSelectedItems(props.filter.filterKeys)
  }, [props.filter.filterKeys])

  return (
    <FilterOptions
      actionLabel={ props.actionLabel }
      onChange={ (items) => handleFilterChange(items, props.onChange) }
      onApply={ (items) => handleFilterChange(items, props.onApply) }
      onClear={ handleClear }
      items={ items }
      selectedItems={ selectedItems }
      multiple
    />
  )
}

SourceFilter.propTypes = {
  filter: PropTypes.object,
  actionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

SourceFilter.defaultProps = {
  actionLabel: defaultActionLabel
}

export default SourceFilter
