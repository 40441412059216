import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { actions } from '../../../actions/Dashboard'
import CopyComponent from '../../CopyComponent'
import LocalSpinner from '../../../components/LocalSpinner'
import { getFormattedPhoneNumber } from '../../../utils/number'

import './styles.scss'

function ChainInfo(props) {
  const dispatch = useDispatch()
  const { loading, data = {} } = useSelector(({ Dashboard }) => _.get(Dashboard, 'chainInfo', {}))

  const phoneNumber = getFormattedPhoneNumber(_.get(data, 'phone_number'))
  const headTag = _.get(data, 'google_tag.head_tag')
  const bodyTag = _.get(data, 'google_tag.body_tag')
  const qrCodeUrl = _.get(data, 'qr_code')
  const signupURL = _.get(data, 'mobile_signup_url')

  useEffect(() => {
    dispatch(actions.getChainInfo())
  }, [dispatch])

  if(loading) {
    return (
      <div className='eg-chain-info'>
        <LocalSpinner />
      </div>
    )
  }

  return (
    <div className='eg-chain-info'>
      <div className='field-title'>PHONE NUMBER</div>
      <CopyComponent textToCopy={phoneNumber} notificationOnCopy='Phone number copied to clipboard'>
        {
          phoneNumber
            ? <div className='field-value'>{phoneNumber}</div>
            : <div className='field-placeholder'>+1 (000) 000 000</div>
        }
      </CopyComponent>
      <div className='border' />
      <div className='field-title'>Google Tag Manager Code</div>
      <div className='field-subtitle'>Head tag</div>
      <CopyComponent textToCopy={headTag} notificationOnCopy='Head tag copied to clipboard'>
        {headTag
          ? <div className='field-value'>{headTag}</div>
          : <div className='field-placeholder'>No tag</div>
        }
      </CopyComponent>
      <div className='field-subtitle'>Body tag</div>
      <CopyComponent textToCopy={bodyTag} notificationOnCopy='Body tag copied to clipboard'>
        {bodyTag
          ? <div className='field-value'>{bodyTag}</div>
          : <div className='field-placeholder'>No tag</div>
        }
      </CopyComponent>
      <div className='field-title'>Qr code</div>
      <div className={`qr-image ${qrCodeUrl ? '' : 'qr-placeholder'}`}>
        { qrCodeUrl && <img src={qrCodeUrl} alt='qr code' className='qr-code'/>}
        <a href={qrCodeUrl} className={`download-logo ${qrCodeUrl ? '' : 'no-download'}`} download alt='download' target='_blank' rel="noopener noreferrer"> </a>
      </div>
      <div className='border' />
      <div className='field-title'>Mobile Signup URL</div>
      <CopyComponent textToCopy={signupURL} notificationOnCopy='Mobile signup URL copied to clipboard'>
        {signupURL
          ? <div className='field-value'>{signupURL}</div>
          : <div className='field-placeholder'>https://</div>
        }
      </CopyComponent>
    </div>
  )
}

ChainInfo.propTypes = {
  data: PropTypes.object,
}

export default ChainInfo
