import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import './styles.scss'

/**
 * Notification types
 */
export const types = {
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
}

export const templates = {
  ERROR_SUPPORT: (message) => {
    // add a dot at the end of the sentence if it doesn't have one
    // (leave it alone if it already had one)
    const dottedMessage = message.replace(/([^\.])$/, '$1.')
    return (
      <div>
        { dottedMessage }{ ' ' }
        Please try again or <a href="mailto:info@eatgeek.com">contact support</a>.
      </div>
    )
  }
}

/**
 * Notification toaster - React component
 */
export default class Notification extends React.Component {

  constructor() {
    super()
    // Timeout
    this.timeout = null

    // Bindings
    this.onClose = this.onClose.bind(this)
    this.setAutoDismiss = this.setAutoDismiss.bind(this)
    this.removeAutoDismiss = this.removeAutoDismiss.bind(this)
  }

  static propTypes = {
    notification: PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string,
      id: PropTypes.string,
      template: PropTypes.func
    }),
    autoDismiss: PropTypes.bool,
    onClose: PropTypes.func.isRequired
  }

  static defaultProps = {
    autoDismiss: true,
    id: shortid.generate()
  }

  /**
   * Removes the auto-dismiss and triggers the provided onClose.
   */
  onClose() {
    this.removeAutoDismiss()
    this.props.onClose()
  }

  setAutoDismiss() {
    // Start the timeout to close the notification after 5 seconds.
    this.timeout = setTimeout(() => {
      this.props.onClose()
      this.timeout = null
    }, 5000)
  }

  /**
   * Clears the auto dismiss timeout.
   */
  removeAutoDismiss() {
    clearTimeout(this.timeout)
    this.timeout = null
  }

  /**
   * Returns the classes based on the notification type. SUCCESS type is default.
   */
  getNotificationClasses() {
    switch(this.props.notification.type) {
      case types.WARNING: return 'notification warning'
      case types.ERROR: return 'notification error'
      case types.SUCCESS: return 'notification success'
      case types.INFO: return 'notification info'
      default: return 'notification success'
    }
  }

  /**
   * Renders the notification if provided.
   */
  render() {
    if (!this.props.notification) return null
    if (this.props.autoDismiss) {
      if (this.timeout) this.removeAutoDismiss()
      this.setAutoDismiss()
    }

    const message = this.props.notification.message || 'Unknown error'

    return (
      <div
        className='eg-notification'
        onMouseEnter={this.removeAutoDismiss}
        onMouseLeave={this.setAutoDismiss}
      >
        <div className={this.getNotificationClasses()}>
          {/**
            * to implement later, with new designs
              <div className={styles.icon} />
            */}
          <div className='message'>
            {
              this.props.notification.template ? (
                <>{ this.props.notification.template(message) }</>
              ) : (
                <>{ message }</>
              )
            }
          </div>
          <i className='zmdi zmdi-close close-btn' onClick={ this.onClose }></i>
        </div>
      </div>
    )
  }
}
