import { takeLatest, put, call } from 'redux-saga/effects'

import { authorizedRequest } from './Auth'
import { updateSessionData } from '../services/user'
import { getChains } from '../api/Restaurant'
import { types, actions } from '../actions/Chain'
import { actions as spinnerActions } from '../actions/Spinner'
import { mapChainListData } from '../services/chain'
import { NetworkError } from '../services/error'

/**
 * Get restaurant list for authenticated customer.
 * If the user is an admin, all restaurants will be received
 *
 */
function* getChainsHandler(opts) {
  try {
    const res = yield call(authorizedRequest, getChains, opts)
    if (res && res.data) {
      const chainlistData =  mapChainListData(res.data)
      yield put(actions.getChainsResolved(chainlistData))
      yield put(spinnerActions.loadingResolved())

      // update session related info with chains
      yield updateSessionData({
        hasMultipleChains: chainlistData.chains.length > 1
      })
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getChainsRejected(error))
    yield put(spinnerActions.loadingResolved())
  }
}

/**
 * Get chain list for authenticated customer.
 * If the user is an admin, all chains will be received
 *
 */
function* searchChainsHandler(opts) {
  try {
    const res = yield call(authorizedRequest, getChains, opts)
    if (res && res.data) {
      const chainlistData =  mapChainListData(res.data)
      yield put(actions.searchIntentResolved(chainlistData))
      yield put(spinnerActions.loadingResolved())
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.searchIntentRejected(error))
    yield put(spinnerActions.loadingResolved())
  }
}

export default function* () {
  yield takeLatest(types.GET_CHAINS_INTENT, getChainsHandler)
  yield takeLatest(types.SEARCH_CHAINS_INTENT, searchChainsHandler)
}
