import React from 'react'
import PropTypes from 'prop-types'

import { googleConnectInit } from '../../../../../services/googleConnect'
import GoogleConnectButton from '../../../../GoogleConnectButton'
import Spinner from '../../../../LocalSpinner'
import PopOverMenu from '../../../../PopOverMenu'

import styles from './index.module.scss'

export default class GoogleConnect extends React.Component {
  static propTypes = {
    restaurantUuid: PropTypes.string,
    googleConnectPosting: PropTypes.bool,
    googleConnectError: PropTypes.bool,
    getGoogleAuthCode: PropTypes.func,
    isGmailConnected: PropTypes.bool,
    integrationEmail: PropTypes.string,
  }

  constructor (props) {
    super(props)

    // bindings
    this.connectGmail = this.connectGmail.bind(this)
    this.renderGoogleConnectButton = this.renderGoogleConnectButton.bind(this)
    this.renderUserInfo = this.renderUserInfo.bind(this)
    this.renderInboxIntegrationInfo = this.renderInboxIntegrationInfo.bind(this)
  }

  componentDidMount () {
    googleConnectInit()
  }

  connectGmail () {
    const { restaurantUuid } = this.props
    this.props.getGoogleAuthCode({restaurantUuid})
  }

  renderGoogleConnectButton() {
    const { isGmailConnected } = this.props
    return <>
      <GoogleConnectButton
        className={ styles.googleConnectButton }
        text={isGmailConnected ? 'Connect different account': 'Connect with Google'}
        loading={ this.props.googleConnectPosting }
        onClick={ this.connectGmail }
      />
      {this.props.googleConnectError &&
        <div className={ styles.googleConnectError }>
          An error ocurred while trying to connect with Google.
          Please try again later
        </div>
      }
    </>
  }

  renderUserInfo() {
    const { integrationEmail }  = this.props
    return (
      <>
        <div className={styles.userInfo}>
          <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
          <div className={styles.userDetails}>
            {  this.props.googleConnectPosting 
              ? <Spinner />
              : integrationEmail || 'Your email is connected.'
            }
            {/* TODO: Handle Gmail last check from backend
            Wait for restaurant.gmail_integration_last_check to be available
            <span className={styles.smallInfo}>Last synced {this.props.restaurant.gmail_integration_last_check}</span> */}
          </div>
          <div className={styles.actionContainer}>
            <PopOverMenu className={styles.popOverStyle}>
              <div className="eg-menu-title">
                <button className={styles.dropDownMenu}></button>
              </div>
              <div className="eg-menu-items">
                <div onClick={ this.connectGmail }>Change account</div>
              </div>
            </PopOverMenu>
          </div>
        </div>
      </>
    )
  }

  renderInboxIntegrationInfo() {
    return (
      <>
        <ul className={styles.infoDescription}>
          {/* TODO: Review text from mockup. */}
          <li>
            <span className={`${styles.listIcon} ${styles.iconSync}`}></span>
            Eatgeek will import and parse past orders from your email to help you manage your customer contacts.
          </li>
          <li>
            <span className={`${styles.listIcon} ${styles.iconBoard}`}></span>
            Make sure to connect the email account you’re currently using to receive orders.
          </li>
          <li>
            <span className={`${styles.listIcon} ${styles.iconSecure}`}></span>
            Your personal emails, credentials and contacts will not be shared with Eatgeek.
          </li>
        </ul>
        <div className={styles.gConnectButton}>
          {this.renderGoogleConnectButton()}
        </div>
      </>
    )
  }

  /**
   * Gets JSX of Google Connect with some user/general details.
   */
  render () {
    const { isGmailConnected } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          {isGmailConnected && <div className={styles.check}></div>}
        </div>
        <div className={styles.infoTitle}>
          {isGmailConnected ? 'Gmail Inbox Connected' : 'Connect your Gmail Inbox'}
        </div>
        {
          isGmailConnected
            ? this.renderUserInfo()
            : this.renderInboxIntegrationInfo()
        }
      </div>
    )
  }

}
