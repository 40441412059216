import { types } from '../actions/Interactions'
import { DEFAULT_ITEMS_PER_PAGE } from '../constants'

export const initialState = {
  interactionList: {
    items: null,
    page: 1,
    size: DEFAULT_ITEMS_PER_PAGE
  },
  loading: false,
  searching: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INTERACTIONS_INTENT:
      return {
        ...initialState,
        loading: true,
        error: null
      }

    case types.GET_INTERACTIONS_INTENT_RESOLVED:
      return {
        ...state,
        interactionList: action.data,
        loading: false,
        error: null
      }

    case types.GET_INTERACTIONS_INTENT_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default:
      return state
  }
}
