import _ from 'lodash'
import { types } from '../actions/Journey'

export const initialState = {
  journeyList: {
    loading: false,
    error: null,
    list: null
  },
  popupJourneyList: {
    loading: false,
    error: null,
    list: null
  },
  keywordJourneyList: {
    loading: false,
    error: null,
    list: null
  },
  journey: {
    loading: true,
    error: null,
    data: null
  },
  create: {
    submitting: false,
    success: false,
    error: null,
    journeyId: null,
  },
  updateStatus: {
    submitting: false,
    success: false,
    error: null
  },
  shortLink: {
    isNew: false,
    submitting: false,
    success: false,
    data: null,
    error: null
  }
}

const createJourneyLink = (state, data) => {
  return {
    data: {
      ...state.journey.data,
      shortlinks: [
        ...state.journey.data.shortlinks,
        data
      ]
    },
  }
}

const updateShortlinkState = (state, action) => {
  return {
    ...state.shortLink,
    submitting: false,
    success: true,
    data: {
      ...action.data,
      message: {
        ...action.data.message,
        text: `${_.get(action, 'data.message.text')} ${action.data.long_url}`,
        autoTrigger: true
      }
    },
    error: null
  }
}

const updateJourneyLink = (state, data) => {
  return {
    data: {
      ...state.journey.data,
      shortlinks: state.journey.data.shortlinks.map(s => {
        if (s.slug === data.slug) {
          return data
        }
        return s
      })
    },
  }
}

export default (state = initialState, action) => {
  switch(action.type) {
    // Get journeys
    case types.GET_JOURNEYS_INTENT:
      return {
        ...state,
        journeyList: {
          loading: true,
          error: null,
          list: null
        },
        updateStatus: {
          submitting: false,
          success: false,
          error: null
        }
      }
    case types.GET_JOURNEYS_INTENT_RESOLVED:
      return {
        ...state,
        journeyList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_JOURNEYS_INTENT_REJECTED:
      return {
        ...state,
        journeyList: {
          loading: false,
          error: action.error,
          list: null
        },
      }

      // Get popup journeys
    case types.GET_POPUP_JOURNEYS_INTENT:
      return {
        ...state,
        popupJourneyList: {
          loading: true,
          error: null,
          list: null
        }
      }
    case types.GET_POPUP_JOURNEYS_INTENT_RESOLVED:
      return {
        ...state,
        popupJourneyList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_POPUP_JOURNEYS_INTENT_REJECTED:
      return {
        ...state,
        popupJourneyList: {
          loading: false,
          error: action.error,
          list: null
        },
      }

      // Get keyword journeys
    case types.GET_KEYWORD_JOURNEYS_INTENT:
      return {
        ...state,
        keywordJourneyList: {
          loading: true,
          error: null,
          list: null
        }
      }
    case types.GET_KEYWORD_JOURNEYS_INTENT_RESOLVED:
      return {
        ...state,
        keywordJourneyList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_KEYWORD_JOURNEYS_INTENT_REJECTED:
      return {
        ...state,
        keywordJourneyList: {
          loading: false,
          error: action.error,
          list: null
        },
      }


    // Get single journey
    case types.GET_JOURNEY_INTENT:
      return {
        ...state,
        journey: {
          loading: true,
          error: null,
          data: null
        }
      }
    case types.GET_JOURNEY_INTENT_RESOLVED:
      return {
        ...state,
        journey: {
          loading: false,
          error: null,
          data: action.data
        }
      }
    case types.GET_JOURNEY_INTENT_REJECTED:
      return {
        ...state,
        journey: {
          loading: false,
          error: action.error,
          data: null
        }
      }

    // Create journey
    case types.UPSERT_JOURNEY_INTENT:
      return {
        ...state,
        create: {
          ...state.create,
          submitting: true,
          success: false,
          error: null
        }
      }
    case types.UPSERT_JOURNEY_INTENT_RESOLVED:
      return {
        ...state,
        create: {
          ...state.create,
          submitting: false,
          success: true,
          journeyId: _.get(action, 'data.id'),
          error: null
        },
        journey: {
          ...state.journey,
          data: action.data
        }
      }
    case types.UPSERT_JOURNEY_INTENT_REJECTED:
      return {
        ...state,
        create: {
          ...state.create,
          submitting: false,
          success: false,
          error: action.error
        }
      }

    // Set journey state
    case types.SET_JOURNEY_STATE_INTENT:
      return {
        ...state,
        updateStatus: {
          ...state.create,
          submitting: true,
          success: false,
          error: null
        }
      }
    case types.SET_JOURNEY_STATE_INTENT_RESOLVED:
      return {
        ...state,
        updateStatus: {
          ...state.create,
          submitting: false,
          success: true,
          error: null
        }
      }
    case types.SET_JOURNEY_STATE_INTENT_REJECTED:
      return {
        ...state,
        updateStatus: {
          ...state.create,
          submitting: false,
          success: false,
          error: action.error
        }
      }

    case types.JOURNEY_SHORTLINK_CREATE_INTENT:
      return {
        ...state,
        shortLink: {
          ...state.shortLink,
          isNew: true,
          submitting: true,
          success: false,
          data: null,
          error: null
        }
      }

    case types.JOURNEY_SHORTLINK_CREATE_INTENT_RESOLVED:
      return {
        ...state,
        journey: createJourneyLink(state, action.data),
        shortLink: updateShortlinkState(state, action)
      }

    case types.JOURNEY_SHORTLINK_CREATE_INTENT_REJECTED:
      return {
        ...state,
        shortLink: {
          ...state.shortLink,
          submitting: false,
          success: false,
          data: null,
          error: action.error
        }
      }

    case types.JOURNEY_SHORTLINK_UPDATE_INTENT:
      return {
        ...state,
        shortLink: {
          ...state.shortLink,
          isNew: false,
          submitting: true,
          success: false,
          error: null
        }
      }

    case types.JOURNEY_SHORTLINK_UPDATE_INTENT_RESOLVED:
      return {
        ...state,
        journey: updateJourneyLink(state, action.data),
        shortLink: {
          ...state.shortLink,
          submitting: false,
          success: true,
          error: null
        }
      }

    case types.JOURNEY_SHORTLINK_UPDATE_INTENT_REJECTED:
      return {
        ...state,
        shortLink: {
          ...state.shortLink,
          submitting: false,
          success: false,
          data: null,
          error: action.error
        }
      }

    case types.JOURNEY_SHORTLINK_CLEAR:
      return {
        ...state,
        shortLink: {
          ...initialState.shortLink
        }
      }

    // clear redux state
    case types.CLEAR_JOURNEYS:
      return initialState

    default:
      return state
  }
}
