/**
 * A function to create a string about user applied filters
 * i.e. `3 stores, Last 30 days, 3 sources, All statuses`
 * @param {*} selectedStore  selected stores array
 * @param {*} filters user selected filers i.e time, sources, status
 */
export const constructSelectedFilterTextInfo = (selectedStore=[], filters=[], search) => {
  // Construct text about how many stores has been selected by the user
  const selectedStoreText = selectedStore.length ? `${selectedStore.length} stores` : 'All stores'
  const filterKeysArray = Object.keys(filters)
  //Construct a string which includes information about date, source and status filter info.
  const appliedFilters = filterKeysArray.reduce((sum, filter, index) => {
    const isLastFilter = index === filterKeysArray.length - 1
    if(isLastFilter) {
      return `${sum}, and ${filters[filter].actionLabel}`
    }
    return `${sum}, ${filters[filter].actionLabel}`
  }, '')
  // Construct information about both store fitler and other filters.
  let selectedFilterText = `${selectedStoreText}${appliedFilters}`
  if(search) {
    selectedFilterText = `${selectedFilterText} with search '${search}'.`
  } else {
    selectedFilterText = `${selectedFilterText}.`
  }
  return selectedFilterText
}
