import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import querystring from 'query-string'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import { actions as customerActions } from '../../actions/Customer'
import Search from '../../components/Search'
import PaginationSection from '../../components/PaginationSection'
import CustomerHeader from '../../components/CustomerHeader'
import { CustomerList, ExportCustomer } from '../../components/Customers'
import {
  TotalInteractionsFilter,
  LastInteractionFilter,
  MediumFilter,
  InteractionsFilter
} from '../../components/Customers/Filters'
import { DateFilter } from '../../components/CommonFilters'

import { getNumberWithCommas } from '../../utils/number'

import './styles.scss'


const Customers = props => {
  const dispatch = useDispatch()
  const customerState = useSelector(state => state.Customer)
  const { customerList, loadingCustomers } = customerState
  const [searchString, setSearchString] = useState('')
  const filters = _.get(customerList, 'filters') || {}

  const getSearchResult = useCallback( _.debounce((value) => {
    const opts = {
      page: 1,
      size: _.get(customerList, 'size'),
      sort: _.get(customerList, 'sort'),
      filters,
      search: value
    }
    dispatch(customerActions.getCustomers(opts))
  }, 500), [])

  useEffect(() => {
    // let's get page query from the url, if it exist.
    const queryParameter = props.location.search
    const lastVisitedCustomerPage = _.get(customerList, 'page')
    const queryParameterObj = querystring.parse(queryParameter)
    if(!queryParameterObj.page) {
      const opts = {
        page: 1
      }
      dispatch(customerActions.getCustomers(opts))
    }else if(queryParameterObj.page && parseInt(lastVisitedCustomerPage) !== parseInt(queryParameterObj.page) ) {
      //Only going to get the customers for the page query if we don't have customers in redux store for page query data.
      const opts = {
        page: parseInt(queryParameterObj.page)
      }
      dispatch(customerActions.getCustomers(opts))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const handlePageChange = (page) => {
    const opts = {
      page: parseInt(page),
      size: _.get(customerList, 'size'),
      sort: _.get(customerList, 'sort'),
      filters,
      search: searchString
    }
    dispatch(customerActions.getCustomers(opts))
  }

  const handlePageSizeChange = (size) => {
    const opts = {
      page: 1,
      size,
      sort: _.get(customerList, 'sort'),
      filters,
      search: searchString
    }
    dispatch(customerActions.getCustomers(opts))
  }

  const handleSearch = value => {
    setSearchString(value)
    getSearchResult(value)
  }

  const handleSortChange = field => {
    const opts = {
      page: 1,
      size: _.get(customerList, 'size'),
      sort: field,
      filters,
      search: searchString
    }
    dispatch(customerActions.getCustomers(opts))
  }

  const handleFilterApply = filter => {
    const filters = _.get(customerList, 'filters') || {}
    dispatch(customerActions.getCustomers({
      page: 1,
      size: _.get(customerList, 'size'),
      sort: _.get(customerList, 'sort'),
      filters: { ...filters, ...filter },
      search: searchString
    }))
  }

  const handleFilterClear = filter => {
    const filters = { ..._.get(customerList, 'filters') } || {}
    delete(filters[filter.filterType])
    dispatch(customerActions.getCustomers({
      page: 1,
      size: _.get(customerList, 'size'),
      sort: _.get(customerList, 'sort'),
      filters,
      search: searchString
    }))
  }

  const hasSomeCustomers = !!(!loadingCustomers && _.get(customerList, 'customers.length'))

  return (
    <div className='eg-customers'>
      <CustomerHeader />
      <div className='container'>
        <h3>Customers</h3>
        <div className='top-row'>
          <div className='filters'>
            <DateFilter
              selectedItem={ filters.date_filter }
              onApply={ handleFilterApply }
              onClear={ handleFilterClear }
            />
            <TotalInteractionsFilter
              onApply={ handleFilterApply }
              onClear={ handleFilterClear }
            />
            <LastInteractionFilter
              onApply={ handleFilterApply }
              onClear={ handleFilterClear }
            />
            <MediumFilter
              onApply={ handleFilterApply }
              onClear={ handleFilterClear }
            />
            <InteractionsFilter
              onApply={ handleFilterApply }
              onClear={ handleFilterClear }
            />
          </div>
          <Search value={searchString} onChange={ handleSearch } className='search' />
          <ExportCustomer filters={{...filters, search: searchString}} />
        </div>
        <div className='total'>
          {  hasSomeCustomers && <div>{getNumberWithCommas(customerList.total)} Customers</div> }
        </div>
        <CustomerList
          list={ customerList }
          loading={ loadingCustomers }
          onSortChange={ handleSortChange }
          onPageChange = { handlePageChange }
        />
        {
          customerList.customers && !!customerList.customers.length && (
            <div className='pagination'>
              <PaginationSection
                total={ customerList.total }
                size={ customerList.size }
                page={ customerList.page }
                onPageChange={ handlePageChange }
                onSizeChange={ handlePageSizeChange }
              />
            </div>
          )
        }
      </div>
    </div>
  )
}

Customers.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withRouter(Customers)
