import { types } from '../actions/VirtualNumber'

const initialState = {
  numbers: null,
  number: null,
  posting: false,
  loading: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_NUMBER_INTENT:
      return {
        ...state,
        number: null,
        posting: true,
        loading: false
      }

    case types.CREATE_NUMBER_INTENT_RESOLVED:
      return {
        ...state,
        number: action.data,
        posting: false,
        error: false
      }

    case types.CREATE_NUMBER_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        error: true
      }

    case types.GET_NUMBER_INTENT:
      return {
        ...state,
        error: false,
        posting: false,
        loading: true
      }

    case types.GET_NUMBER_INTENT_RESOLVED:
      return {
        ...state,
        number: action.data,
        loading: false,
        error: false
      }

    case types.GET_NUMBER_INTENT_REJECTED:
      return {
        ...state,
        loading: false,
        error: true
      }

    case types.GET_NUMBERS_INTENT:
      return {
        ...state,
        posting: false,
        error: false,
        loading: true
      }

    case types.GET_NUMBERS_INTENT_RESOLVED:
      return {
        ...state,
        numbers: action.data,
        posting: false,
        loading: false,
        error: false
      }

    case types.GET_NUMBERS_INTENT_REJECTED:
      return {
        ...state,
        loading: false,
        posting: false,
        error: true
      }

    case types.FORWARD_NUMBER_INTENT:
      return {
        ...state,
        posting: true,
        error: false,
        loading: false
      }

    case types.FORWARD_NUMBER_INTENT_RESOLVED:
      return {
        ...state,
        number: action.data,
        posting: false,
        loading: false,
        error: false
      }

    case types.FORWARD_NUMBER_INTENT_REJECTED:
      return {
        ...state,
        loading: false,
        posting: false,
        error: true
      }

    case types.CLEAR_NUMBER:
      return {
        ...state,
        number: null
      }

    case types.CLEAR_NUMBER_LIST:
      return {
        ...state,
        numbers: null
      }

    default:
      return state
  }
}
