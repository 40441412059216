import _ from 'lodash'
import { interactionSources } from '../services/customers'

export const channelTypes = {
  EMAIL: 'Email',
  MESSAGING: 'Messaging',
  RESERVATIONS: 'Reservations',
  SEARCH: 'Search',
  SOCIAL: 'Social',
  IN_STORE: 'In Store',
  UNKNOWN: 'Unknown'
}

const sourcesWithType = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  GRUBHUB: 'grubhub',
  MAILCHIMP: 'mailchimp',
  CAMPAIGNER: 'campaigner',
  TEXT: 'text',
  OPEN_TABLE: 'open_table'
}

export const channelEmailProviders = [ 'mailchimp', 'campaigner' ]

// We don't have google, facebook and grubhub as interaction sources, we might have later.
// We do have these sources in channel so combining them together.
const channelSources = {
  ...interactionSources,
  ...sourcesWithType
}

// TODO: check this general implementation later
// const getUnkownTypeSources = () => {
//   const result = {}
//   _.keys(channelSources).forEach(src => {
//     const value = channelSources[src]
//     if (!sourcesWithType[src]) {
//       result[value] = { source: channelSources[src], name: value, type: channelTypes.UNKNOWN }
//     }
//   })
//   return result
// }

export const channelTypeMappings = {
  // Email Channels
  [channelSources.MAILCHIMP]: { source: channelSources.MAILCHIMP, name: 'Mailchimp', type: channelTypes.EMAIL },
  [channelSources.CAMPAIGNER]: { source: channelSources.CAMPAIGNER, name: 'Campaigner', type: channelTypes.EMAIL },

  // Messagging channels
  [channelSources.TEXT]: { source: channelSources.TEXT, name: 'SMS', type: channelTypes.MESSAGING },

  // Reservations channels
  [channelSources.OPEN_TABLE]: { source: channelSources.OPEN_TABLE, name: 'OpenTable', type: channelTypes.RESERVATIONS },

  // Search channels
  [channelSources.GOOGLE]: { source: channelSources.GOOGLE, name: 'Google', type: channelTypes.SEARCH },

  // Social channels
  [channelSources.FACEBOOK]: { source: channelSources.FACEBOOK, name: 'Facebook', type: channelTypes.SOCIAL },

  // In store channels
  [channelSources.VOICE]: { source: channelSources.VOICE, name: 'Phone', type: channelTypes.IN_STORE },
  [channelSources.ZENREACH]: { source: channelSources.ZENREACH, name: 'Zenreach', type: channelTypes.IN_STORE },
  [channelSources.CLOVER]:  { source: channelSources.CLOVER, name: 'Clover', type: channelTypes.IN_STORE },

  // Third party channels
  // ...getUnkownTypeSources()
  // [channelSources.DOORDASH]: { source: channelSources.DOORDASH, name: 'Doordash', type: channelTypes.UNKNOWN },
  // [channelSources.GRUBHUB]: { source: channelSources.GRUBHUB, name: 'Grubhub', type: channelTypes.UNKNOWN },
  [channelSources.TOAST]: { source: channelSources.TOAST, name: 'Toast', type: channelTypes.UNKNOWN },
  // [channelSources.OLO]: { source: channelSources.OLO, name: 'OLO', type: channelTypes.UNKNOWN },
  // [channelSources.PAYTRONIX]: { source: channelSources.PAYTRONIX, name: 'Paytronix', type: channelTypes.UNKNOWN },
  // [channelSources.CONSTANT_CONTACT]: { source: channelSources.CONSTANT_CONTACT, name: 'Constant Contact', type: channelTypes.UNKNOWN },
  // [channelSources.SQUARE]: { source: channelSources.SQUARE, name: 'Square', type: channelTypes.UNKNOWN },
  // [channelSources.REVEL]: { source: channelSources.REVEL, name: 'Revel', type: channelTypes.UNKNOWN },
}

export const mapChannelsTotalIn = (channel, isSubchannel = false) => {
  if (!channel) {
    return {
      spend: 0,
      income: 0,
      avg_ticket: 0,
      roi: 0
    }
  }

  const roi = channel.roi && parseFloat((channel.roi).toFixed(2))
  const spend =  channel.campaign_cost ? parseFloat((channel.campaign_cost).toFixed(2)): 0
  const income = channel.gross_profit ? parseFloat((channel.gross_profit).toFixed(2)) : 0
  const avgTicket = channel.avg_ticket ? parseFloat((channel.avg_ticket).toFixed(2)) : 0
  const subscribersAmount = channel.subscribers_amount ? parseInt(channel.subscribers_amount) : 0
  return {
    spend,
    income,
    avg_ticket: avgTicket,
    roi,
    subscribersAmount
  }
}

export const mapChannelIn = (channel) => {
  const mappedChannel = {
    source: channel.source,
    ...mapChannelsTotalIn(channel)
  }
  if (!_.isEmpty(channel.subchannels)) {
    mappedChannel.subchannels = channel.subchannels.map(subchannel => {
      return {
        name: subchannel.name,
        date: subchannel.date,
        sourceId: subchannel.source_id,
        link: subchannel.link,
        ...mapChannelsTotalIn(subchannel, true)
      }
    })
  }
  const matchingChannel = channelTypeMappings[channel.source]
  if (matchingChannel) {
    mappedChannel.type = matchingChannel.type
    mappedChannel.name = matchingChannel.name
    mappedChannel.source = matchingChannel.source
  }

  return mappedChannel
}

export const getOverview = (channelList) => {
  const positiveSubchannels = [...channelList.channels]
    .filter(s => s.roi > 0)
    .sort((channelA, channelB) => channelB.roi - channelA.roi)
  const negativeSubchannels = [...channelList.channels]
    .filter(s => s.roi > 0)
    .sort((channelA, channelB) => channelA.roi - channelB.roi)

  return {
    positiveRoiChannels: positiveSubchannels.filter(c => c.roi >= 1),
    negativeRoiChannels: negativeSubchannels.filter(c => c.roi < 1)
  }
  /*
   * ****** the above code gets the rois based on the subchannels ******
  let positiveRoiChannels = []
  let negativeRoiChannels = []
  channelList.channels.forEach(channel => {
    const subchannels = _.get(channel, 'subchannels')
    if (!_.isEmpty(subchannels)) {
      const positiveSubchannels = [...subchannels].filter(s => s.roi > 0).sort((channelA, channelB) => channelB.roi - channelA.roi)
      const negativeSubchannels = [...subchannels].filter(s => s.roi > 0).sort((channelA, channelB) => channelA.roi - channelB.roi)
      positiveRoiChannels.push({
        ...positiveSubchannels[0],
        source: channel.source
      })
      negativeRoiChannels.push({
        ...negativeSubchannels[0],
        source: channel.source
      })
    }
  })
  positiveRoiChannels.sort((a, b) => b.roi - a.roi)
  negativeRoiChannels.sort((a, b) => a.roi - b.roi)
  return {
    positiveRoiChannels: positiveRoiChannels.filter(c => c.roi >= 1),
    negativeRoiChannels: negativeRoiChannels.filter(c => c.roi < 1)
  }
  */
}

/**
 * @description insights information about best and worst campaign ROIs
 * For each channel, check the higher and lower ROIs, and compare with
 * saved values (i.e. best and worst objects), so finally we have stored
 * the highest and lowest values for all subchannels.
 *
 * @param {Object} channelList - channel list information
 * @param {Array} channelList.channels - list of channels
 *
 * @returns {Object} - best and worst subchannels
*/
export const getInsights = (channelList) => {
  let best = {}
  let worst = {}

  channelList.channels.forEach(channel => {
    if (!_.isEmpty(_.get(channel, 'subchannels'))) {
      const subchannels = channel.subchannels.filter(s => s.roi > 0)

      if (!_.isEmpty(subchannels)) {
        // sort subchannels in descendant way, so first elements have the higher rois
        const sortedSubchannels = subchannels.sort((s1, s2) => s2.roi - s1.roi)
        if (best.roi === undefined || best.roi < sortedSubchannels[0].roi) {
          best = sortedSubchannels[0]
          best.source = channel.source
        }

        // check the last element (i.e. lower roi)
        if (worst.roi === undefined || parseFloat(worst.roi) > parseFloat(sortedSubchannels[sortedSubchannels.length - 1].roi)) {
          worst = sortedSubchannels[sortedSubchannels.length - 1]
          worst.source = channel.source
        }
      }
    }
  })
  if (_.isEmpty(best) && _.isEmpty(!worst)) return
  const result = {}
  if (best) {
    result.best = best
  }
  if (worst) {
    result.worst = worst
  }

  return result
}

export const mapChannelOverview = (data) => {
  return data
}

/**
 * @description sort subchannels list by specified field
 *
 * @param {Array} channels - list of channels that should have subchannels sorted
 * @param {Object} sortingField - sorting field data
 * @param {string} sortingField.key - sorting field identifier
 * @param {string} sortingField.direction - sorting direction ('ASC' or 'DESC')
 *
 * @returns {Array} - channels with subchannels sorted
*/
export const sortSubchannelsBy = (channels, sortingField) => {
  const sValues = {
    ASC: -1,
    DESC: 1
  }
  const channelsToSort = _.cloneDeep(channels)
  if (!channels || !channels.length) return []
  if (!sortingField) return channelsToSort
  const dir = sortingField.direction

  // Callback function which is called by Array.sort method
  // To sort in descendant way, if a < b, the function should return -1 (otherwise 1)
  // To sort in ascendant way, if a < b, the function should return 1 (otherwise -1)
  // So, given the desired direction (dir), we'll have -1 or 1 for a < b condition
  // and for the "else" part, we just multiply by -1 (e.g. 1 * -1 = -1 and -1 * -1 = 1),
  // obtaining the oposite sign.
  const sortField = (a, b) => a < b ? sValues[dir] : -1 * sValues[dir]
  let sortFunction
  switch (sortingField.key) {
    case 'channels': sortFunction = (a, b) => sortField(a.name, b.name); break
    case 'spend': sortFunction = (a, b) => sortField(a.spend, b.spend); break
    case 'income': sortFunction = (a, b) => sortField(a.income, b.income); break
    case 'avgTicket': sortFunction = (a, b) => sortField(a.avg_ticket, b.avg_ticket); break
    case 'roi': sortFunction = (a, b) => sortField(a.roi, b.roi); break
    default:
      return channelsToSort
  }
  channelsToSort.forEach(channel => {
    channel.subchannels = channel.subchannels ? channel.subchannels.sort(sortFunction) : []
  })
  return channelsToSort
}
