import React from 'react'
import PropTypes from 'prop-types'

import closeLogo from './close.svg'
import styles from './index.module.scss'

export default class Modal extends React.Component {
    static propTypes = {
      active: PropTypes.bool.isRequired,
      children: PropTypes.any,
      customStyle: PropTypes.string,
      closeModal: PropTypes.func,
      onClose: PropTypes.func,
    }

    closeModal = () => {
      this.props.closeModal()
      if(typeof this.props.onClose === 'function'){
        this.props.onClose() // handling parent on close function on modal close
      }
    }

    render(){
      return (this.props.active
        ? (<div className= {styles.ModalContainer}>
          <div className={`${styles.Modal} ${this.props.customStyle}`}>
            <div onClick={this.closeModal}>
              <img src={closeLogo} alt='Close logo' className={styles.closelImage}/>
            </div>
            {this.props.children}
          </div>
        </div>)
        : null  
      )
    }
}
