import moment from 'moment'
import _ from 'lodash'
import { DATE_FORMAT, TIME_FORMAT } from '../constants'

export const statusMappings = {
  completed: 'answered',
  missed: 'missed',
  'no-answer': 'missed',
  queued: 'queued',
  'in-progress': 'in-progress',
  busy: 'busy'
}

export const statusLabels = {
  completed: 'Answered',
  missed: 'Missed',
  'no-answer': 'No answer',
  queued: 'Queued',
  'in-progress': 'In progress'
}

export const mapCallsIn = (calls) => {
  return calls.map((row) => {
    const customerName = _.get(row, 'customer.fullname')
    return {
      id: row._id,
      caller: customerName ? customerName: row.from_formatted,
      receivingLine: row.phone.title,
      duration: row.duration,
      status: statusMappings[row.status],
      statusLabel: statusLabels[row.status],
      date: moment(row.start_time).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
    }
  })
}
