import React from 'react'
import PropTypes from 'prop-types'

import { predicateTypes, predicateAttributes } from '../../../../services/segments'
import SourcePredicate from '../Predicates/SourcePredicate'
import PurchaseTemplate from './PurchaseTemplate'
import SignupTemplate from './SignupTemplate'
import ShortLinkTemplate from './ShortLinkTemplate'

import './styles.scss'

const PredicateTemplate = props => {
  const { onChange, predicates = [] } = props

  /**
   * clear predicates of given type
   * @param {*} predicateType
   */
  const onClearPredicates = (predicateType) => {
    const predicateDataWithoutDates = predicates.filter((item) => item.type !== predicateType )
    const updatedPredicates = predicateDataWithoutDates
    onChange(updatedPredicates)
  }

  /**
   * Updates the predicate date or inserts if it doesn't exist
   * @param {*} predicateData , predicate date object i.e { type: 'date', comparision: 'eq', value: dateString }
   * @param {*} comparison string value of type of comparision i.e `eq`, `lte`..
   */
  const onDateChange = (predicateData, comparison) => {
    const onDateExistIndex = predicates.findIndex((item) => item.type === predicateTypes.DATE && item.comparison === comparison)
    let updatePredicates = [...predicates]
    if(onDateExistIndex >= 0) {
      updatePredicates[onDateExistIndex] =  predicateData
    } else {
      updatePredicates = [
        ...predicates,
        {
          ...predicateData
        }
      ]
    }
    onChange(updatePredicates)
  }

  /**
   * Updates the predicate depending on type or creates if it doesn't exist
   * @param {*} predicateData , predicate  object i.e { type: 'purchase', attribute: 'source', comparision: 'eq', value: 'toast' }
   */
  const onPredicateChange = (predicateData) => {
    const predicateExistIndex = predicates.findIndex((item) => item.type === predicateData.type )
    let updatedPredicates = [...predicates]
    if(predicateExistIndex >= 0) {
      updatedPredicates[predicateExistIndex] =  predicateData
    } else {
      updatedPredicates = [
        ...updatedPredicates,
        {
          ...predicateData
        }
      ]
    }
    onChange(updatedPredicates)
  }

  const mainPredicate = predicates.find((predicate) =>{
    return (predicate.attribute === predicateAttributes.SOURCE_MEDIUM) ||
      (predicate.attribute === predicateAttributes.SOURCE) ||
      (predicate.type === predicateTypes.SHORTLINK_CLICK) // TODO: check a types enum instead
  }) || {}

  return (
    <div className='eg-dynamic-segment-predicate-template'>
      <div className='text-node'>
        Has
      </div>
      <div className='predicate-cell'>
        <SourcePredicate
          predicates={predicates}
          onChange={onChange}
        />
      </div>
      {
        mainPredicate.type === predicateTypes.PURCHASE && (
          <PurchaseTemplate
            predicates={predicates}
            onChange={onPredicateChange}
            onClearPredicates={onClearPredicates}
            onDateChange={onDateChange}
          />
        )
      }
      {
        mainPredicate.type === predicateTypes.SIGN_UP && (
          <SignupTemplate
            predicates={predicates}
            onClearPredicates={onClearPredicates}
            onDateChange={onDateChange}
            onChange={onPredicateChange}
          />
        )
      }
      {
        mainPredicate.type === predicateTypes.SHORTLINK_CLICK && (
          <ShortLinkTemplate
            predicates={predicates}
            onClearPredicates={onClearPredicates}
            onDateChange={onDateChange}
            onChange={onPredicateChange}
          />
        )
      }
    </div>
  )
}

PredicateTemplate.propTypes = {
  predicates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PredicateTemplate
