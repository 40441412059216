import React from 'react'
import PropTypes from 'prop-types'
import Rating from 'react-rating'

import styles from './index.module.scss'

const RatingStars = (props) => {
  return (
    <div className={ `${styles.container} ${props.className}` }>
      <Rating
        emptySymbol={ `zmdi zmdi-star zmdi-hc-fw ${ props.emptyClass }` }
        fullSymbol={ `zmdi zmdi-star zmdi-hc-fw ${ props.fullClass }` }
        fractions={ props.fractions }
        initialRating={ props.rate }
        readonly
      />
    </div>
  )
}

RatingStars.propTypes = {
  rate: PropTypes.number,
  fractions: PropTypes.number,
  emptyClass: PropTypes.string,
  fullClass: PropTypes.string,
  className: PropTypes.string
}

RatingStars.defaultProps = {
  fractions: 2,
  emptyClass: styles.starDark,
  fullClass: styles.starLight,
  className: ''
}

export default RatingStars
