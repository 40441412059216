import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { scheduleStatuses } from '../../../../../../services/stores'
import { DAYS_OF_WEEK } from '../../../../../../constants'

import ScheduleTimeRange from '../ScheduleTimeRange'

import styles from './form.module.scss'

const ScheduleForm = props => {
  const store = props.store
  const schedules = props.schedules
  const [autocompleted, setAutocompleted] = useState(false)

  const autocompleteWithField = (schedules) => {
    // if already had schedules, we should not auto-complete
    if(store.hasSchedule || autocompleted) {
      return null
    }

    // get fields filled with both "from" and "to" values
    const completedFields = schedules.filter(s => {
      return s.from && s.to
    })

    // check that we have only one field completed and it's monday
    if (completedFields.length !== 1 || completedFields[0].day_of_week !== DAYS_OF_WEEK[0]) {
      return null
    }

    setAutocompleted(true)
    return completedFields[0]
  }

  const handleTimeChange = (index, time, type) => {
    const updatedSchedules = [ ...schedules ]
    updatedSchedules[index] = {
      ...updatedSchedules[index],
      [type]: time
    }
    // first, check if we should auto-complete the other fields
    const templateSchedule = autocompleteWithField(updatedSchedules)
    if (templateSchedule) {
      updatedSchedules.forEach(s => {
        s.from = templateSchedule.from
        s.to = templateSchedule.to
        s.state = scheduleStatuses.OPEN
      })
    }
    props.onChange(updatedSchedules)
  }

  const handleClosedClick = index => {
    const updatedSchedules = [ ...schedules ]
    const state = updatedSchedules[index].state === scheduleStatuses.CLOSED
      ? scheduleStatuses.OPEN
      : scheduleStatuses.CLOSED

    updatedSchedules[index] = {
      ...updatedSchedules[index],
      state
    }
    props.onChange(updatedSchedules)
  }

  return (
    <div className={ styles.container }>
      {
        schedules.map((schedule, i) => {
          const closeIsDisabled = !schedule.from && !schedule.to && schedule.state === null

          return (
            <div className={ styles.itemRow } key={ `time-item-${i}` }>
              <div className={ styles.itemLabel }>{ schedule.day_of_week }</div>
              <div className={ styles.itemInputWrapper }>
                <ScheduleTimeRange
                  index={ i }
                  schedule={ schedule }
                  closeIsDisabled={ closeIsDisabled }
                  onTimeChange={ handleTimeChange }
                  onClosedClick={ handleClosedClick }
                />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

ScheduleForm.propTypes = {
  store: PropTypes.object,
  schedules: PropTypes.array,
  onChange: PropTypes.func
}

ScheduleForm.defaultProps = {}

export default ScheduleForm
