import _ from 'lodash'
import moment from 'moment'

const defaultSteps = [
  { label: 'Setup', key: 'setup', completed: false },
  { label: 'Design', key: 'design', completed: false },
  { label: 'Review & Send', key: 'review', completed: false }
]

export const getStepIndex = step => {
  let index = 0
  defaultSteps.forEach((s, i) => { if (s.key === step.key) index = i })
  return index
}

export const getSteps = (campaign) => {
  const steps = _.cloneDeep(defaultSteps)
  if(!campaign) return steps
  if(campaign.campaign_name && campaign.campaign_segment) {
    steps[0].completed = true
  }
  if(campaign.message) {
    steps[1].completed = true
    steps[2].completed = true
  }
  return steps
}

export const zsfSmsFooter = () => {
  return `
    Powered by ZeroStorefront
    Reply STOP to opt-out
  `
}

export const scheduleTypes = {
  SCHEDULE: 'schedule',
  INSTANT: 'immediate'
}

export const campaignTypes = {
  DELIVERED: 'delivered',
  SCHEDULED: 'scheduled',
  DRAFT: 'draft',
  CANCELED: 'canceled'
}

export const dateTypes = {
  [campaignTypes.SCHEDULED]: 'schedule_date',
  [campaignTypes.DELIVERED]: 'send_date',
  [campaignTypes.DRAFT]: 'created_at',
  [campaignTypes.CANCELED]: 'updated_at'
}

export const defaultSegments = [
  { value: 'all_opted_in_customers', label: 'All subscribers' },
]

/**
 * parse text for preview, replacing newlines by <br />,
 * link strings to html link and also add the chain name to the message
 *
 * @param {string} str - string to parse
 * @param {Object} chain - chain needed to display the name
*/
export const parsePreview = (str, chain) => {
  if (!str) str = ''
  // if no chain name in the message, let's add it.
  if(!str.includes(`${chain.name}:`)) {
    str = `${chain.name}: ${str}`
  }
  str = str.replace(/\n/g, '<br />')
  str = str.replace(/(https?:\/\/\w+(\.\w+)+[\/\w-_]*)/img, '<a href="#">$1</a>')
  return str
}

/**
 * maps campaigns from the backend object to our frontend structure
 *
 * @param {Object} data - data from the backend
 * @param {Object} chain - campaign chain
 * @returns {Object} mapped object with the list and total
*/
export const mapCampaigns = (data, chain) => {
  const campaigns = data.campaigns.map((item) => {
    item.chainName = chain.name
    return item
  })

  return {
    campaigns,
    total: data.total
  }
}

/**
 * if more than cities than after 10 cities, add remaining customer click * counts with the city name 'Others'
 * @param {Array} sortedCityArray statistics data for cities and platforms
 * @returns {Array} sorted cities array with maximum 11 items
 */
const mapCities = (sortedCityArray) => {
  let cities
  if(sortedCityArray.length > 10) {
    const top10Cities = sortedCityArray.slice(0,10)
    const remainingCities = sortedCityArray.slice(10)
    const otherCityClicks = remainingCities.reduce((sum, city) => sum + city.customer_click_count, 0)
    cities = [
      ...top10Cities,
      {
        key: 'Other',
        customer_click_count: otherCityClicks
      }
    ]
  } else {
    cities = sortedCityArray
  }
  return cities
}

/**
 * Sort Cities and platforms by customer click count
 * @param {Object} statistics data for cities and platforms
 * @returns {Object} sorted cities and platform data
 */
const mapStatisticData = (statiscs) => {
  if(!statiscs) return statiscs
  statiscs.platforms = statiscs.platforms && statiscs.platforms.sort((itemA, itemB) => itemB.customer_click_count - itemA.customer_click_count)
  const sortedCityArray = statiscs.cities && statiscs.cities.sort((cityA, cityB) => cityB.customer_click_count - cityA.customer_click_count)
  // if cities more than 10, then get the 10 cities data and
  // put the remaing one with the city label/key as Others
  let cities = mapCities(sortedCityArray)
  statiscs.cities = cities
  return statiscs
}

/**
 * maps campaign to the structure used by the forms
 *
 * @param {Object} campaign - campaign to map
 * @returns {Object} mapped campaign
*/
export const mapCampaignToFormData = (campaign, chain, segmentList = []) => {
  let message = campaign.text
  if (campaign.text) {
    const chainNameRegex = new RegExp(`^\s*${chain.name}:\s*(.*)$`, 'im')
    message = campaign.text.replace(chainNameRegex, '$1').trim()
  }
  const campaignSegment = segmentList.find(s => s.value === campaign.target)
  const statistic = mapStatisticData(campaign.statistic)
  let activeRange = campaign.active_range
  if (!activeRange) {
    activeRange = {
      start_time: '09:00',
      end_time: '23:59'
    }
  }
  return {
    id: campaign.id,
    schedule_date: campaign.schedule_date,
    send_date: campaign.send_date,
    campaign_name: campaign.name,
    campaign_segment: campaignSegment,
    message,
    originalSms: campaign.text,
    statistic,
    shortlink: campaign.shortlink,
    target: campaign.target,
    mms: {
      image: _.get(campaign, 'mms.image'),
      imageUrl: _.get(campaign, 'mms.image_url'),
    },
    activeRange: activeRange
  }
}

/**
 * transforms the campaign to match BE model
 *
 * @param campaign {Object} - campaign to map
 * @returns {Object} - mapped campaign
*/
export const mapCampaignOut = campaign => {
  const payload = {}
  const segment = _.get(campaign, 'campaign_segment.value')
  if (campaign.campaign_name) payload.name = campaign.campaign_name
  if (segment) payload.target = segment
  if (campaign.message) payload.text = campaign.message
  if (campaign.schedule_date) payload.schedule_date = campaign.schedule_date
  if (campaign.mms) payload.mms = campaign.mms
  return payload
}

/**
 * make a dummy chart data point with zero clicks based on given time
 * @param {*} pointTime first actual data point for the chart from our backend
 * @returns a dummy item for the chart with few properties
 */
const getDummyPoint = (pointTime) => {
  return {
    xLabel: `${moment(pointTime).format('h:mm A')}  ${moment(pointTime).format('M/D')}`,
    label: `${moment(pointTime).format('h:mm A')}  ${moment(pointTime).format('M/D')}`,
    clicks: 0
  }
}


/**
 * if data values more than 8 items in the array, we will hide the
 * xAxis labels. we only show values for the every third xAxis label, first and last.
 * @param {*} items
 * @returns
 */
const getXLabels = (items) => {
  if(items.length > 8) {
    items = items.map((item, index) => {
      const isFirstItem = index === 0
      const isLastItem = index === (items.length - 1)
      // we are going to keep labels of first item and the last item.
      if(isFirstItem || isLastItem) {
        return item
      }else if(index % 3 === 0) {
        return item
      } else {
        item.xLabel = null
        return item
      }
    })
    // We are keeping the last item's label, In case we have second
    // last item label, let's make it null
    items[items.length - 2].xLabel = ''
  }
  return items
}

const mapChartItem = (item) => {
  item.xLabel = `${moment(item.date).format('h:mm A')}  ${moment(item.date).format('M/D')}`
  item.label = `${moment(item.date).format('h:mm A')}  ${moment(item.date).format('M/D')}`
  item.clicks = item.customer_clicks
  return item
}

/**
 * maps delivered campaign chart data from the backend object to our frontend structure
 *
 * @param {Object} data - data from the backend
 * @returns {Object} mapped object with the list of chart data
*/
export const mapCampaignChartData = (data) => {
  if(data && data.length === 0) return { items: [] }
  let items = data.map(mapChartItem)
  // Add dummy first and last data point for 24 hour time period
  // Depending on the date of first real chart data point from the backend
  const firstPointTime = data[0].date
  // first dummy point time, 30 minutes before the next point
  const firstDummyPointTime = moment(firstPointTime).subtract(30, 'minutes')
  // last dummy point in the chart closing 24 hours from the first point
  const lastDummyPointTime = moment(firstDummyPointTime).add(24, 'hours')
  const firstDummyItem = getDummyPoint(firstDummyPointTime)
  const lastDummyItem  = getDummyPoint(lastDummyPointTime)
  items = [firstDummyItem, ...items, lastDummyItem]
  items = getXLabels(items)
  return {
    items
  }
}
