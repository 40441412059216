import React, { useState } from 'react'
import CustomerHeader from '../../components/CustomerHeader'
import TabSelector from '../../components/TabSelector'
import { UpdateUserInfo, UpdateUserPassword } from '../../components/AccountSettings'
import StatelessModal from '../../components/StatelessModal'
import { Button } from '../../components/FormComponents'
import { types as buttonTypes } from '../../components/FormComponents/Button'

import './styles.scss'

export const tabKeys = {
  PERSONAL_INFO: 'personal_info',
  PASSWORD: 'password'
}

export const tabs = [
  { label: 'PERSONAL INFO', key: tabKeys.PERSONAL_INFO },
  { label: 'PASSWORD', key: tabKeys.PASSWORD }
]

const AccountSettings = props => {
  const [ selectedTab, setSelectedTab ] = useState(tabs[0])
  const [ tabToSwitch, setTabToSwitch ] = useState(null)
  const [ displaySwitchModal, setDisplaySwitchModal ] = useState(false)
  const [ fieldChanged, setFieldChanged ] = useState(false)

  const handleModalClose = () => {
    setDisplaySwitchModal(false)
  }

  const handleFieldChange = () => {
    setFieldChanged(true)
  }

  const handleTabChange = (item) => {
    if(!fieldChanged) {
      setSelectedTab(item)
    } else {
      setTabToSwitch(item)
      setDisplaySwitchModal(true)
    }
  }

  const handleModalConfirm = () => {
    if (tabToSwitch) {
      setSelectedTab(tabToSwitch)
    }
    setFieldChanged(false)
    setTabToSwitch(null)
    setDisplaySwitchModal(false)
  }

  const handleSubmit = () => {
    setFieldChanged(false)
    setTabToSwitch(null)
    setDisplaySwitchModal(false)
  }

  return (
    <div className='eg-account-settings'>
      <CustomerHeader />
      <div className='body'>
        <h2>Account settings</h2>
        <div className='card'>
          <div className='head'>
            <TabSelector
              items={ tabs }
              className='tabs'
              selectedItem={ selectedTab }
              onChange={ (item) => handleTabChange(item) }
            />
          </div>
          <div className='content'>
            {
              selectedTab.key === tabKeys.PERSONAL_INFO && (
                <UpdateUserInfo onChange={ handleFieldChange } onSubmit={ handleSubmit } />
              )
            }
            {
              selectedTab.key === tabKeys.PASSWORD && (
                <UpdateUserPassword onChange={ handleFieldChange } onSubmit={ handleSubmit } />
              )
            }
          </div>
        </div>
      </div>
      {
        displaySwitchModal && (
          <StatelessModal
            className='switch-modal'
            closeStyle='switch-modal-close'
            onClose={ handleModalClose }
          >
            <div className='content'>
              <h4 className='title'>Discard changes?</h4>
              <p className='description'>
                You have unsave changes. These will be lost if you close leave the tab
              </p>
            </div>
            <div className='actions'>
              <Button
                className='button'
                text='Go back'
                type={ buttonTypes.SECONDARY }
                onClick={ handleModalClose }
              />
              <Button
                className='button'
                text='Discard'
                onClick={ handleModalConfirm }
              />
            </div>
          </StatelessModal>
        )
      }
    </div>
  )
}

export default AccountSettings
