import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Checkbox from '../../../../Checkbox'
import TextField from '../../../../FormComponents/TextField'
import PhoneInput from '../../../../FormComponents/PhoneInput'
import Button, { types as buttonTypes } from '../../../../FormComponents/Button'
import GoogleAddressInput from '../../../../FormComponents/GoogleAddressInput'
import FormValidator from '../../../../../validators/FormValidator'
import {
  required,
  phone as phoneValidator
} from '../../../../../validators/customValidations'

import styles from './form.module.scss'

const STORE_NAME = 'name'
const STORE_ADDRESS = 'address'
const STORE_PHONE = 'phone'
const STORE_CATEGORIES = 'categories'

let validation
const validator = new FormValidator([
  ...required(STORE_NAME),
  ...phoneValidator(STORE_PHONE, false)
])

/**
 * Gets route,city, state and postal code from the address.
 * @param {object} addressData a object containing data from google maps sdk
 */
const getAddressOfStore = (addressData) => {
  const location = addressData.addressObject.geometry.location
  const addressComponents = _.get(addressData, 'addressObject.address_components') || []
  const streetAddress = {}
  const address = {}
  // Calculating street address, city, latitude and longitude.
  // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
  addressComponents.forEach((component) => {
    if(component.types.includes('floor')) {
      address.suite = component.long_name
    }
    if(component.types.includes('street_number')) {
      streetAddress.street_number = component.long_name
    }
    if(component.types.includes('route')) {
      streetAddress.route = component.long_name
    }
    if(component.types.includes('street_address')) {
      address.street_address = component.long_name
    }
    if(component.types.includes('locality')) {
      address.city = component.long_name
    }
    if(component.types.includes('administrative_area_level_1')) {
      address.state = component.long_name
    }
    if(component.types.includes('postal_code')) {
      address.zipcode = component.long_name
    }
  })

  address.latitude = location.lat()
  address.longitude = location.lng()
  address.formatted_address = _.get(addressData, 'addressObject.formatted_address')

  // We are referring address1 as street address here.
  const address1 = `${streetAddress.street_number ? streetAddress.street_number : ''} ${streetAddress.route}`.trim()
  return  {
    address1,
    ...address
  }
}

/**
 * Gets Category elments from their names.
 * @param {*} store store info
 * @param {*} categories total available categories to choose
 */
const mapCategoryName = (store, categories) => {
  const storeCategoriesNameArray = _.get(store, 'storeInfo.categories') || []
  const storeCategories = categories ? storeCategoriesNameArray.map((categoryName) => {
    return categories.find(category => {
      return categoryName === category.name
    })
  }) : []

  return storeCategories
}

const StoreInfo = props => {
  const { store, categories } = props
  const storeName = _.get(store, 'storeInfo.name')
  const storeAddress = _.get(store, 'storeInfo.address')
  const storePhone = _.get(store, 'storeInfo.phone')
  const storeCategories = mapCategoryName(store, categories)

  const [ formFields, setFormFields ] = useState(() => {
    return {
      [STORE_NAME]: { value: storeName || '', dirty: false },
      [STORE_ADDRESS]: {
        value: _.get(storeAddress, 'formatted_address') || '',
        addressObject: storeAddress, dirty: false
      },
      [STORE_PHONE]: { value: storePhone || '', dirty: false },
      [STORE_CATEGORIES]: { values: storeCategories || [], dirty: false }
    }
  })

  const getFieldError = (fieldName) => {
    if (formFields[fieldName].dirty && !validation[fieldName].isValid) {
      return validation[fieldName].message
    }
    return ''
  }

  const handleInputChange = (event) => {
    event.persist()
    setFormFields(prevState => {
      if (!event.target) {
        return prevState
      }
      return {
        ...prevState,
        [event.target.name]: {
          value: event.target.value,
          dirty: true
        }
      }
    })
  }

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleSaveClick = () => {
    // Getting names of categories.
    const selectedCategories =  categories.filter((category) => {
      return formFields[STORE_CATEGORIES].values.find(c => {
        return c.caterer_type_id === category.caterer_type_id
      })
    }).map((category) => category.name)

    const addressData = getAddressOfStore(formFields[STORE_ADDRESS])
    if (props.onSave) {
      props.onSave({
        name: formFields[STORE_NAME].value,
        address: addressData,
        phone: formFields[STORE_PHONE].value,
        categories: selectedCategories
      })
    }
  }

  const handleAddressChange = address => {
    setFormFields(prevState => {
      return {
        ...prevState,
        [STORE_ADDRESS]: {
          value: address ? address.formatted_address : '',
          addressObject: address,
          dirty: true
        }
      }
    })
  }

  const handleCategoryClick = category => {
    let updatedCategories = [ ...formFields[STORE_CATEGORIES].values ]
    const existing = formFields[STORE_CATEGORIES].values.find(c => {
      return c.caterer_type_id === category.caterer_type_id
    })

    if (existing) {
      updatedCategories = updatedCategories.filter(c => {
        return c.caterer_type_id !== category.caterer_type_id
      })
    } else {
      updatedCategories = [
        ...updatedCategories,
        category
      ]
    }

    setFormFields(prevState => {
      return {
        ...prevState,
        [STORE_CATEGORIES]: {
          values: updatedCategories,
          dirty: true
        }
      }
    })
  }

  validation = validator.validate(formFields)
  const textForModification = store && store.uuid ? 'Edit' : 'Add'
  return (
    <div className={styles.container}>
      <div className={ styles.header }>
        <h3>{textForModification} store info</h3>
      </div>
      <div className={ styles.body }>
        {renderStoreId(store)}
        <div className={ styles.formRow }>
          <div className={ styles.label }>Store name</div>
          <div className={ styles.field }>
            <TextField
              name={ STORE_NAME }
              label=''
              placeholder='The Cafe'
              value={ formFields[STORE_NAME].value }
              className={ styles.inputField }
              errorMessage={ getFieldError(STORE_NAME) }
              error={!validation[STORE_NAME].isValid && formFields[STORE_NAME].dirty}
              onChange={ handleInputChange }
            />
          </div>
        </div>

        <div className={ styles.formRow }>
          <div className={ styles.label }>Category</div>
          <div className={ `${styles.field} ${styles.categoryWrapper}` }>
            {
              categories.map(category => (
                <div
                  className={ styles.categoryCheck }
                  key={ category.caterer_type_id }
                  onClick={ () => handleCategoryClick(category) }
                >
                  <Checkbox
                    checked={
                      !!formFields[STORE_CATEGORIES].values.find(c => {
                        return c.caterer_type_id === category.caterer_type_id
                      })
                    }
                  />
                  <span>{ category.name }</span>
                </div>
              ))
            }
          </div>
        </div>

        <div className={ styles.formRow }>
          <div className={ styles.label }>Address</div>
          <div className={ styles.field }>
            <GoogleAddressInput
              id={ STORE_ADDRESS }
              name={ STORE_ADDRESS }
              label=''
              address={ formFields[STORE_ADDRESS].addressObject || {} }
              onChange={ handleAddressChange }
            />
          </div>
        </div>

        <div className={ styles.formRow }>
          <div className={ styles.label }>Contact number</div>
          <div className={ styles.field }>
            <PhoneInput
              name={ STORE_PHONE }
              label=''
              placeholder='Enter phone number'
              value={ formFields[STORE_PHONE].value }
              className={ styles.inputField }
              errorMessage={ getFieldError(STORE_PHONE) }
              error={!validation[STORE_PHONE].isValid && formFields[STORE_PHONE].dirty}
              onChange={ handleInputChange }
            />
          </div>
        </div>
      </div>

      <div className={ styles.actionsWrapper }>
        <Button
          text='CANCEL'
          type={ buttonTypes.SECONDARY }
          onClick={ handleCancelClick }
        />
        <Button
          text={ store ? 'SAVE' : 'CREATE' }
          type={ buttonTypes.PRIMARY }
          disabled={ !validation.isValid }
          onClick={ handleSaveClick }
        />
      </div>
    </div>
  )
}

const renderStoreId = (store) => {
  if(!store || !store.uuid) return null
  return (
    <div className={ styles.formRow }>
      <div className={ styles.label }>Store ID</div>
      <div className={ styles.field }>
        { store.uuid }
      </div>
    </div>
  )
}

StoreInfo.propTypes = {
  store: PropTypes.object,
  categories: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default StoreInfo
