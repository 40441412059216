import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import AWS from 'aws-sdk'
import _ from 'lodash'

import { getRoleInformation } from '../api/User'

AWS.config.region = process.env.REACT_APP_COGNITO_REGION

export const getUserPool = () => {
  return new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  })
}

export const getSession = () => {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool()
    const currentUser = userPool.getCurrentUser()
    if (!currentUser) {
      return reject(new Error('invalid session'))
    }
    currentUser.getSession((err, result) => {
      if (err) {
        return reject(err)
      }
      return resolve(result)
    })
  })
}

export const authenticateUser = async (email, password) => {
  return new Promise((resolve, reject) => {
    const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password
    })
    const user = new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: getUserPool()
    })

    user.authenticateUser(authDetails, {
      onSuccess: async (result) => {
        const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider()
        const params = {
          AccessToken: result.accessToken.jwtToken
        }

        try {
          const user = await cognitoIdentityServiceProvider.getUser(params).promise()
          const authData = await getRoleInformation(result.idToken.jwtToken, result.accessToken.jwtToken)
          resolve({
            status: authData.status,
            data: {
              ...result,
              user,
              authorizationData: _.get(authData, 'data.authorizationData')
            }
          })
        } catch (error) {
          return reject(error)
        }
      },

      onFailure: (error) => {
        return reject(error)
      }
    })
  })
}

export const logout = async () => {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool()
    const currentUser = userPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession((err, result) => {
        if (err) {
          return reject(err)
        }
        currentUser.globalSignOut({
          onSuccess: (r) => {
            localStorage.clear()
            return resolve(r)
          },
          onFailure: (e) => { reject(e) }
        })
      })
    }
  })
}
