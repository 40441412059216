import Login from './Login'
import { connect } from 'react-redux'
import { actions } from '../../actions/User'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  authError: state.User.authError,
  posting: state.User.posting
})

const mapDispatchToProps = dispatch => ({
  onLogin({ email, password, pageReferrer }) {
    dispatch(actions.Authenticate(email, password, pageReferrer))
  },

  clearMessages() {
    dispatch(actions.clearMessages())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
