import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { initSegment } from '../../services/analytics'

import store, { history } from '../../store'
import MainLayout from '../../components/MainLayout'
import LogRocket from '../../components/LogRocket'
import { trackIntercomEvents } from '../../utils/index'
import { addKeyboardNavigation, removeKeyboardNavigation } from '../../utils/a11y'

class App extends React.Component {
  constructor(props){
    super(props)
    initSegment()
  }

  componentDidMount() {
    trackIntercomEvents()
    addKeyboardNavigation()
  }

  componentWillUnmount() {
    removeKeyboardNavigation()
  }

  render() {
    return (
      <LogRocket>
        <Provider store={ store() }>
          <ConnectedRouter history={ history }>
            <MainLayout />
          </ConnectedRouter>
        </Provider>
      </LogRocket>
    )
  }
}

export default App
