import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

import styles from './index.module.scss'

export default class Pagination extends React.Component {
  static propTypes = {
    pageCount: PropTypes.number,
    marginPages: PropTypes.number,
    pageRange: PropTypes.number,
    currentPage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    className: PropTypes.string,
    onPageClick: PropTypes.func.isRequired
  }

  static defaultProps = {
    marginPages: 2,
    pageRange: 4,
    currentPage: 1,
    className: ''
  }

  constructor() {
    super()

    //bindings
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  handlePageClick(page) {
    this.props.onPageClick(page.selected + 1)
  }

  render() {
    return (
      <div className={ `${styles.container} ${this.props.className}` }>
        <span
          className={ `${styles.controlBtn} ${styles.first}` }
          onClick={ () => this.handlePageClick({ selected: 0 }) }
        />
        <ReactPaginate
          forcePage={ this.props.currentPage - 1 }
          previousLabel={ '' }
          nextLabel={ '' }
          breakLabel={ '...' }
          breakClassName={ styles.breakMe }
          pageCount={ this.props.pageCount }
          marginPagesDisplayed={ this.props.marginPages }
          pageRangeDisplayed={ this.props.pageRange }
          onPageChange={ this.handlePageClick }
          containerClassName={ styles.paginationContainer }
          subContainerClassName={ styles.pages }
          activeClassName={ styles.active }
          previousClassName={ styles.previous }
          nextClassName={ styles.next }
        />
        <span
          className={ `${styles.controlBtn} ${styles.last}` }
          onClick={ () => this.handlePageClick({ selected: this.props.pageCount - 1 }) }
        />
      </div>
    )
  }
}
