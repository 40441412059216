import { connect } from 'react-redux'

import { actions } from '../../actions/Order'
import { actions as gcActions } from '../../actions/GoogleConnect'
import { actions as userActions } from '../../actions/User'
import { actions as ModalActions } from '../../actions/Modal'
import { actions as storeActions } from '../../actions/Store'
import Orders from './Orders'

const mapStateToProps = state => ({
  restaurant: state.User.chain,
  orders: state.Order.orders,
  page: state.Order.page,
  total: state.Order.total,
  size: state.Order.size,
  loading: state.Spinner.loading,
  searching: state.Order.searching,
  googleConnectPosting: state.GoogleConnect.posting,
  googleConnectError: state.GoogleConnect.error,
  googleConnectModalOffRestros: state.User.googleConnectModalOffRestros,
  lastUsedChainUuid: state.Store.lastUsedChainUuid
})

const mapDispatchToProps = dispatch => ({
  getOrders(opts) {
    dispatch(actions.getOrders(opts))
  },

  getGoogleAuthCode() {
    dispatch(gcActions.gmailConnect())
  },

  disableGoogleConnectModal(restaurantUuid){
    dispatch(userActions.initiateGoogleConnectModalDisabling(restaurantUuid))
  },

  openModal(){
    dispatch(ModalActions.openModal())
  },

  closeModal() {
    dispatch(ModalActions.closeModal())
  },

  getStoreList() {
    dispatch(storeActions.getStoreList())
  },
  
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
