import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useOutsideClick } from '../../../../../../../hooks/shared'

import './styles.scss'

export const fields = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
}

const CustomFieldsTrigger = props => {
  const { className = '', onChange } = props
  const [ active, setActive ] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    setActive(false)
  })

  const handleTriggerClick = () => {
    setActive(!active)
  }

  const handleChange = field => {
    onChange(field)
    setActive(false)
  }

  return (
    <div className='eg-textarea-addon-trigger-customfields'>
      <div className={ className } onClick={ handleTriggerClick } data-testid='eg-textarea-addon-trigger-customfield' />
      {
        active && (
          <ul className='menu' ref={ wrapperRef }>
            <li onClick={ () => handleChange(fields.FIRST_NAME) }>First name</li>
            <li onClick={ () => handleChange(fields.LAST_NAME) }>Last name</li>
            <li onClick={ () => handleChange(fields.EMAIL) }>Email</li>
          </ul>
        )
      }
    </div>
  )
}

CustomFieldsTrigger.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default CustomFieldsTrigger
