import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { getOrderStatus } from '../../services/orders'
import Calendar, { viewTypes } from '../Calendar'
import styles from './index.module.scss'

export default class OrderCalendar extends React.Component {
  static propTypes = {
    orders: PropTypes.array,
    view: PropTypes.oneOf(Object.values(viewTypes)),
    date: PropTypes.string,
    onDatesRender: PropTypes.func,
    onEventClick: PropTypes.func
  }

  constructor() {
    super()

    // bindings
    this.getEvents = this.getEvents.bind(this)
    this.handleEventClick = this.handleEventClick.bind(this)
  }

  getEvents() {
    if (!this.props.orders) {
      return []
    }
    return this.props.orders.map(order => {
      const title = `#${order.restaurant_order_id}`
      const status = getOrderStatus(order)
      return {
        title,
        start: order.order_date,
        className: `${styles.orderEvents} ${styles[status]}`,
        extendedProps: { order }
      }
    })
  }

  handleEventClick(info) {
    this.props.onEventClick(_.get(info, 'event.extendedProps.order'))
  }

  render() {
    return (
      <Calendar
        { ...this.props }
        events={ this.getEvents() }
        view={ this.props.view }
        date={ this.props.date }
        onDatesRender={ this.props.onDatesRender }
        onEventClick={ this.handleEventClick }
      />
    )
  }
}
