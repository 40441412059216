import _ from 'lodash'
import { push } from 'connected-react-router'
import { recoverPassword, confirmPassword } from '../api/User'
import { ErrorHandler } from '../api/utils'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { actions, types } from '../actions/User'
import { actions as appActions } from '../actions/App'
import { actions as spinnerActions } from '../actions/Spinner'
import { actions as notificationActions } from '../actions/Notifications'
import { mapCognitoUserData, setUserRoles } from '../services/auth'
import EGAnalytic, { analyticEvents } from '../services/analytics'
import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import { authenticateUser, logout } from '../services/cognito'

import { PATHS, HOME_PATH, KEYS } from '../constants'

const { LOGIN_PAGE_REFERRER } = KEYS

/**
 * Handles Login intent
 * @param {object} action - Action Object
 */
function* loginHandler({ data }) {
  try {
    // const res = yield call(Authenticate, data.email, data.password)
    const res = yield call(authenticateUser, data.email, data.password)
    // Check if res.status ~ 200
    if (res.status === 200) {
      // Store authentication info
      const userData = mapCognitoUserData(res.data)
      yield put(actions.UpdateAuthInfo(res.data))
      yield put(actions.AuthenticateResolved())
      const userId = _.get(userData, 'user.sub')
      const user = _.get(userData, 'user')
      EGAnalytic.track(analyticEvents.LOGIN_COMPLETED, {
        userId_ : userId,
        name: `${user.given_name || ''} ${user.family_name || ''}`,
        email: user.email,
      })

      if(data.pageReferrer){
        sessionStorage.setItem(LOGIN_PAGE_REFERRER, data.pageReferrer)
      }
      yield put(push(PATHS.CHAINS))
      const notification = {
        type: EgNotificationTypes.SUCCESS,
        message: 'Successfully Logged In!'
      }
      yield put(EgNotificationActions.ShowNotification(notification))
    } else throw res
  } catch (e) {
    const response = yield ErrorHandler(e)
    yield put(actions.AuthenticateRejected(e, response))
  }
}

function* logoutHandler() {
  yield logout()
  //Track logout event
  EGAnalytic.track(analyticEvents.SIGNOUT_COMPLETED,{
    page: window.location.pathname
  })
  yield put(spinnerActions.loadingResolved())
  yield put(push(PATHS.LOGIN))
  yield put(appActions.clearAppState())
}

/**
 * Send email, and if it exists, an authorization code
 * will be sent to that email. This will be used in the
 * next step
 *
 * @param {object} data - { email: string } email address
*/
function* resetPasswordHandler({ data }) {
  try {
    yield call(recoverPassword, data.email)
    yield put(actions.resetPasswordResolved())
    yield put(push(`${PATHS.CONFIRM_NEW_PASSWORD}?email=${data.email}`))
  } catch (e) {
    const errorCode = _.get(e, 'response.data.code')
    yield put(actions.resetPasswordRejected(errorCode))
  }
}

/**
 * send the validation code received by email and new password
 * to update it
 *
 * @param {object} data - { email: string, verificationCode: string, password: string }
*/
function* confirmPasswordHandler({ data }) {
  try {
    yield call(confirmPassword, data)
    yield put(actions.confirmPasswordResolved())
    EGAnalytic.track(analyticEvents.PASSWORD_RESET_COMPLETED)
    yield put(push(PATHS.LOGIN))
    yield put(notificationActions.OpenNotification('Password updated successfully'))
  } catch (e) {
    const errorCode = _.get(e, 'response.data.code')
    yield put(actions.confirmPasswordRejected(errorCode))
  }
}

/**
 * set roles for the user when some chain is selected
 *
 * @param {object} chain - selected chain
*/
function* setUserRolesHandler({ chain }) {
  const authorizationData = yield select(state => state.User)
  const updatedState = setUserRoles(authorizationData, chain)
  yield put(actions.setUserRolesResolved(updatedState))

  // after setting the roles, redirect to default path for the role if exists
  yield put(push(HOME_PATH(updatedState.user)))
}

export default function*() {
  yield takeLatest(types.AUTHENTICATE_INTENT, loginHandler)
  yield takeLatest(types.LOGOUT_INTENT, logoutHandler)
  yield takeLatest(types.RESET_PASSWORD_INTENT, resetPasswordHandler)
  yield takeLatest(types.CONFIRM_PASSWORD_INTENT, confirmPasswordHandler)
  yield takeLatest(types.SET_USER_ROLES, setUserRolesHandler)
}
