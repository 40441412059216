import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useOutsideClick } from '../../hooks/shared'

import styles from './index.module.scss'

const Dropdown = props => {
  const [active, setActive] = useState(false)
  const [selectedItem, setSelectedItem] = useState(props.selectedItem)
  const wrapperRef = useRef(null)

  const handleDropdownClick = () => {
    setActive(!active)
  }

  const handleClick = (item) => {
    setActive(false)
    setSelectedItem(item)
    if (props.onChange) {
      props.onChange(item)
    }
  }

  const displayableClass = active ? '' : styles.hiddenItems

  // closes dropdown when user clicks outside the component
  useOutsideClick(wrapperRef, () => {
    setActive(false)
  })

  return (
    <div className={ `${styles.dropdownContainer} ${props.className}` } ref={ wrapperRef }>
      <div className={ styles.currentSelection } onClick={ handleDropdownClick }>
        <div>{ selectedItem.label }</div>
        {
          active
            ? <i className="zmdi zmdi-chevron-up"></i>
            : <i className="zmdi zmdi-chevron-down"></i>
        }
      </div>
      <div className={ `${styles.itemsWrapper} ${displayableClass}` }>
        {
          props.items.map(item => (
            <div key={ item.key } onClick={ () => handleClick(item) }>
              { item.label }
            </div>
          ))
        }
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.object.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func
}

Dropdown.defaultProps = {
  className: ''
}

export default Dropdown
