import React from 'react'
import PropTypes from 'prop-types'

import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

import CustomizedAxisTick from './CustomizedAxisTick'
import CustomTooltip from './CustomTooltip'
import './styles.scss'


const CampaignGraph = (props) => {
  const {title, chartAbout, data, error, className=''} = props

  if(error) {
    return (
      <div className={`eg-campaign-graph campaign-chart-error ${className}`}>
        Something went wrong, couldn't get chart data.
      </div>
    )
  }

  if(!data || data.items.length === 0) {
    return (
      <div className={`eg-campaign-graph campaign-chart-error  ${className}`}>
        No chart data available.
      </div>
    )
  }

  return (
    <div className={`eg-campaign-graph ${className}`}>
      <div className='top-row'>
        <div className='title'>
          {title}
        </div>
        <div className='number-click'>
          <div className='circle' />
          {chartAbout}
        </div>
      </div>
      <div className='chart'>
        <ResponsiveContainer width={'100%'} height={250}>
          <LineChart data={data.items} margin={{ top: 20, right:42, bottom: 20, left: -16 }} >
            <Line type="monotone" dataKey="clicks" stroke="#2BBFB3" dot={{ stroke: '#3AB5AB', strokeWidth: 7 }} strokeWidth={2} animationDuration={2000}/>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" vertical={false}/>
            <XAxis
              dataKey="xLabel"
              interval={0}
              tick={<CustomizedAxisTick/>}
              tickLine={false}
              padding={{ left: 32, right: 32 }}
            />
            <YAxis
              axisLine={false}
              allowDecimals={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

CampaignGraph.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  chartAbout: PropTypes.string,
  data: PropTypes.object,
  error: PropTypes.object,
}

export default CampaignGraph
