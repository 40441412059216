import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import CustomerHeader from '../../components/CustomerHeader'
import { FivetranIntegration, CloverIntegration, GoogleMyBusinessIntegration, ToastIntegration, AttentiveIntegration } from '../../components/Integrations'
import StatelessModal from '../../components/StatelessModal'
import { actions as oauthActions } from '../../actions/Oauth'
import { hasIntegrationHappened } from '../../services/oauth'
import { fivetranProviders } from '../../services/integration'
import './styles.scss'

const Integrations = () => {
  const dispatch = useDispatch()
  const [ SelectedComponent, setSelectedComponent ] = useState(null)

  const onSuccessfulIntegration = useCallback(
    (storageEvent) => {
      if(hasIntegrationHappened(storageEvent)) {
        dispatch(oauthActions.getActiveConnections())
      }
    }, [dispatch])

  useEffect(() => {
    dispatch(oauthActions.getActiveConnections())
    dispatch(oauthActions.getFivetranConnections())
    window.addEventListener('storage', onSuccessfulIntegration)
    return () => {
      window.removeEventListener('storage', onSuccessfulIntegration)
    }
  }, [dispatch, onSuccessfulIntegration])

  const handleIntegrationClick = (component) => {
    setSelectedComponent(() => component)
  }

  const handleModalClose = () => {
    setSelectedComponent(null)
  }

  return (
    <div className='eg-integrations-container'>
      <CustomerHeader />
      <div className='body'>
        <h1>Integrations</h1>
        <div className='integrations'>
          <CloverIntegration
            onClick={ handleIntegrationClick }
            onAuthorize= { () => setSelectedComponent(null) }
          />

          <AttentiveIntegration
            onClick={ handleIntegrationClick }
            onAuthorize= { () => setSelectedComponent(null) }
          />

          <FivetranIntegration
            title='Facebook Ads'
            subtitle='Connect your ads data'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.FACEBOOK_ADS}
            providerName={'Facebook Ads'}
            onAuthorize={() => setSelectedComponent(null)}
            isBeta={true}
          />

          <GoogleMyBusinessIntegration
            onClick={handleIntegrationClick}
            onAuthorize={() => setSelectedComponent(null)}
          />

          <FivetranIntegration
            title='Google Ads'
            subtitle='Connect your ads data'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.GOOGLE}
            providerName={'Google Ads'}
            onAuthorize={() => setSelectedComponent(null)}
            isBeta={true}
          />

          <FivetranIntegration
            title='Google Sheet'
            subtitle='Export Google Sheet data'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.GOOGLE_SHEET}
            providerName={'Google Sheet'}
            onAuthorize={() => setSelectedComponent(null)}
          />

          <FivetranIntegration
            title='Instagram Business'
            subtitle='Connect your profile data'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.INSTAGRAM}
            providerName={'Instagram Business'}
            onAuthorize={() => setSelectedComponent(null)}
            isBeta={true}
          />

          <FivetranIntegration
            title='Klaviyo'
            subtitle='Connect your contacts and email'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.KLAVIYO}
            providerName={'Klaviyo'}
            onAuthorize={() => setSelectedComponent(null)}
          />

          <FivetranIntegration
            title='Lightspeed'
            subtitle='Export Lightspeed POS data'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.LIGHTSPEED}
            providerName={'Lightspeed'}
            onAuthorize={() => setSelectedComponent(null)}
          />


          <FivetranIntegration
            title ='Mailchimp'
            subtitle = 'Connect your contacts and email'
            onClick={ handleIntegrationClick }
            provider={fivetranProviders.MAILCHIMP}
            providerName={'Mailchimp'}
            onAuthorize= { () => setSelectedComponent(null) }
          />

          <FivetranIntegration
            title='Shopify'
            subtitle='Connect your contacts and orders'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.SHOPIFY}
            providerName={'Shopify'}
            onAuthorize={() => setSelectedComponent(null)}
          />

          <FivetranIntegration
            title='Square'
            subtitle='Connect your contacts and orders'
            onClick={handleIntegrationClick}
            provider={fivetranProviders.SQUARE}
            providerName={'Square'}
            onAuthorize={() => setSelectedComponent(null)}
          />

          <ToastIntegration
            onClick={ handleIntegrationClick }
            onAuthorize= { () => setSelectedComponent(null) }
          />
          {/*
          <FivetranIntegration
            title ='Facebook Ad Account'
            subtitle = 'Export your Facebook ad account data'
            onClick={ handleIntegrationClick }
            provider={fivetranProviders.FACEBOOK}
            providerName={'Facebook Ad Account'}
            onAuthorize= { () => setSelectedComponent(null) }
          />
          */}
        </div>
      </div>
      {
        SelectedComponent && (
          <StatelessModal onClose={ handleModalClose } className='eg-integration-model-content'>
            <SelectedComponent
              isConnected={ false }
              onAuthorize={ handleModalClose }
            />
          </StatelessModal>
        )
      }
    </div>
  )
}

export default Integrations
