import React from 'react'
import PropTypes from 'prop-types'
import PopOverMenu from '../PopOverMenu'

import { PATHS } from '../../constants'
import './styles.scss'

export default class CustomerHeader extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    includeSettings: PropTypes.bool,
    logout: PropTypes.func.isRequired
  }

  static defaultProps = {
    // we use this prop in places we need to skip options that links
    // to a section within the app after selecting a chain. This is set
    // for example in the chain selection.
    includeSettings: true
  }

  constructor() {
    super()

    // initialState
    this.state = {
      isMenuOpen: false
    }

    // bindings
    this.onUserClick = this.onUserClick.bind(this)
    this.onSettings = this.onSettings.bind(this)
    this.onLogout = this.onLogout.bind(this)
  }

  onUserClick() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  onLogout() {
    this.props.logout()
  }

  onSettings() {
    this.props.history.push(PATHS.ACCOUNT_SETTINGS)
  }

  render() {
    const { given_name, family_name } = this.props.user
    return (
      <div className='eg-customer-header'>
        <PopOverMenu>
          <div className="eg-menu-title">
            <div className='trigger'>
              { `${given_name} ${family_name}` }
              {
                this.props.user.isAdmin ? (
                  <span>Admin</span>
                ) : null
              }
            </div>
          </div>
          <div className="eg-menu-items">
            {
              this.props.includeSettings && (
                <div className='item -settings' onClick={ this.onSettings }>Account settings</div>
              )
            }
            <div className='item -logout' onClick={ this.onLogout }>Log Out</div>
          </div>
        </PopOverMenu>
      </div>
    )
  }
}
