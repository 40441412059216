import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * Requests backend to connect to square
 *
 * @param {object} tokens - authentication tokens
 */
export const connectSquare = (opts, tokens) => {
  const { restaurantUuid } = opts
  const response = axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${restaurantUuid}/square/connect`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
    .then((response) => response)
    .catch((error) => error.response)
  return response
}

