import React from 'react'
import PropTypes from 'prop-types'

import { formatNumber } from '../../../services/virtualNumbers'

import './styles.scss'

const NumberSettings = props => {
  return (
    <div className='eg-number-settings'>
      <div className='title'>Call Forwarding</div>
      <div className='description'>All incoming calls are diverted to these phone numbers</div>
      {
        props.number.forwardNumbers.map(fwdNumber => {
          if (!fwdNumber) return null
          return (
            <div className='fwd-number' key={ fwdNumber.uuid }>
              { formatNumber(fwdNumber.phone_number) }
            </div>
          )
        })
      }
      <div className='separator' />
      <div className='title'>Status</div>
      <div className='description'>Actively receiving and recording calls</div>
    </div>
  )
}

NumberSettings.propTypes = {
  number: PropTypes.object.isRequired
}

export default NumberSettings
