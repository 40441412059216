import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const MAXIMUM_IMAGE_TO_SHOW = 8

const ImageCollage = (props) => {
  let { images } = props
  // If images are more than eight(MAXIMUM_IMAGE_TO_SHOW)
  // then we will slice only show 8 images
  const etcImage = images.length - MAXIMUM_IMAGE_TO_SHOW
  if(etcImage > 0) {
    images = images.slice(0, 8)
  }

  const renderFadedImageText = (isFadedImage) => {
    if(!isFadedImage) return null
    return (
      <div className={styles.etcContentText}>
        + {`${etcImage} more`}
      </div>
    )
  }

  const imageCollage =  images.map((photo, index) => {
    const isFadedImage = etcImage > 0 && index ===  MAXIMUM_IMAGE_TO_SHOW -1
    const className = isFadedImage
      ? `${styles.thumbnail} ${styles.lastImage}`
      : styles.thumbnail
    return (
      <div
        className={className}
        key={index}
        onClick={() => props.onImageClick(index)}>
        {renderFadedImageText(isFadedImage)}
        <img
          alt="Restaruant photos"
          src={`${photo}`}
        />
      </div>
    )
  })

  return (
    <div className={styles.collageWrapper}>
      {imageCollage}
    </div>
  )
}

ImageCollage.propTypes = {
  images: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
}

export default ImageCollage
