import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const HeaderSMS = props => {
  const { title } = props

  return (
    <div className='eg-phone-preview-header-sms' data-testid='eg-header-sms'>
      <div className='back' />
      <span>
        { title }
      </span>
    </div>
  )
}

HeaderSMS.propTypes = {
  title: PropTypes.string
}

export default HeaderSMS
