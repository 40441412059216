import _ from 'lodash'
import moment from 'moment'
import { DATE_FORMAT, TIME_FORMAT } from '../constants'
import { interactionSources } from '../services/customers'
import { formatNumber } from '../services/virtualNumbers'

const mapVoiceIn = (interaction) => {
  // mapping interaction types to call logs attributes
  // which applies also to the UI details
  const statusMappings = {
    incoming_call: 'answered',
    call_finished: 'answered',
    completed: 'answered',
    missed: 'missed',
    'no-answer': 'missed',
    queued: 'queued',
    'in-progress': 'answered',
    busy: 'busy'
  }

  const statusLabelMappings = {
    answered: 'Answered',
    missed: 'Missed',
    queued: 'Queued',
    busy: 'Busy'
  }

  const caller = _.get(interaction, 'customer.fullname') || formatNumber(interaction.source_id)
  const receivingLine = _.get(interaction, 'phone_title') || interaction.to_formatted
  const status = statusMappings[interaction.status || interaction.type]
  const statusLabel = statusLabelMappings[status]

  return {
    id: interaction.id,
    caller,
    receivingLine,
    duration: interaction.duration,
    status,
    statusLabel,
    date: moment(interaction.activity_date).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
  }
}

/**
 * @description maps interaction received from the endpoint.
 * The mapping depends on the source received, so if the source doesn't match
 * we simply return the original interaction without modifications
 *
 * @param {object} interaction - the interaction to be mapped
 * @returns {object} mapped interaction
*/
export const mapInteractionIn = (interaction) => {
  switch (interaction.source) {
    case interactionSources.VOICE:
      return mapVoiceIn(interaction)
    default:
      return interaction
  }
}
