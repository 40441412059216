import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

/**
 * Button Types
 */
export const types = {
  PRIMARY: 'primary',
  PRIMARY_AQUA: 'primary-aqua',
  PRIMARY_DARK: 'primary-dark',
  PRIMARY_RED: 'primary-red',
  SECONDARY: 'secondary',
  SECONDARY_ORANGE: 'secondary-orange',
  SECONDARY_DARK: 'secondary-dark'
}

/**
 * Button sizes
 */
export const sizes = {
  SMALL: 'small',
  XSMALL: 'xsmall'
}

/**
 * Button component, shared across the app.
 * 
 * Props:
 * - type {string}        - Gives the styles of the button.
 * - size {string}        - Gives size (padding of the button).
 * - customStyle {object} - Custom styles for the button.
 * - className {string}   - Custom class to add to the button.
 * - text {string}        - The text that will be displayed inside the button.
 * - onClick {func}       - The function to be triggerd on the click event.
 */
export default class Button extends React.Component {

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    customStyle: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    type: types.PRIMARY,
    size: sizes.NORMAL,
    loading: false,
    disabled: false
  }

  /**
   * Triggers the click action if a function was provided.
   */
  onClick() {
    if (this.props.onClick) this.props.onClick()
  }

  getTypeClass() {
    switch(this.props.type) {
      case types.PRIMARY: return styles.primary
      case types.PRIMARY_AQUA: return styles.primaryAqua
      case types.PRIMARY_RED: return styles.primaryRed
      case types.PRIMARY_DARK: return styles.primaryDark
      case types.SECONDARY: return styles.secondary
      case types.SECONDARY_ORANGE: return styles.secondaryOrange
      case types.SECONDARY_DARK: return styles.secondaryDark
      default: return styles.primary
    }
  }

  getSizeClass() {
    switch(this.props.size) {
      case sizes.SMALL: return styles.small
      case sizes.XSMALL: return styles.xsmall
      case sizes.NORMAL: // $FALL-THROUGH
      default: return ''
    }
  }

  render() {

    return (
      <button
        className={`
          ${styles.button}
          ${this.getTypeClass()}
          ${this.getSizeClass()}
          ${this.props.className || ''}
          ${this.props.disabled ? styles.disabled : ''}
          ${this.props.loading ? styles.loading : ''}
        `}
        style={this.props.customStyle ? this.props.customStyle : {} }
        onClick={ this.onClick }
        id={this.props.id}
        data-testid={this.props.id}
        disabled={ this.props.loading || this.props.disabled }
      >
        {this.props.loading ? <div className={styles.spinner} /> : this.props.text}
      </button>
    )
  }
}
