/**
 * converts a given number to currency amount with dollar sign and comma
 * @param {Number} value
 * @returns {String}  formateed currency amount with commas
 */
export const toCurrency = value => {
  if(typeof(value) === 'number'){
    return Number(value).toLocaleString('en', { style: 'currency', currency: 'USD'})
  }
  return value
}

/**
 * returns a string of a number separated with commas
 * @param {*} number
 */
export function getNumberWithCommas(number) {
  if(typeof number === 'number') {
    return number.toLocaleString()
  }
  return number
}

/**
 * returns a float number with two decimal
 * @param {*} number float
 */
export function parse2DecimalFloat(number) {
  if(typeof number === 'number') {
    return parseFloat(number.toFixed(2))
  }
  return number
}


/**
 * converts a phone number to US phone number format
 * @param {String} phoneNumberString phone number string `+18556593412`
 * @returns {String} i.e `+1 (855) 659-3412`
 */
export function getFormattedPhoneNumber(phoneNumberString) {
  var cleaned = (`${  phoneNumberString}`).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}
