import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { actions } from '../../actions/Route'
import Sidebar from './Sidebar'

const mapStateToProps = state => ({
  user: state.User.user,
  restaurant: state.User.chain,
  isAuthenticated: state.User.isAuthenticated,
  activePath: state.Route.activePath,
  stores: state.Store.storeList,
  hasMultipleChains: state.User.hasMultipleChains
})

const mapDispatchToProps = dispatch => ({
  sectionChanged(path) {
    dispatch(actions.routeChange(path))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
