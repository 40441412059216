import { call, takeLatest, put, delay, select } from 'redux-saga/effects'

import { types, actions } from '../actions/Upload'
import { actions as storeActions } from '../actions/Store'
import { actions as ivrActions } from '../actions/LoyaltyIvr'
import { authorizedRequest } from './Auth'
import { getS3SignedUrl, uploadDocs } from '../api/Upload'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

function* uploadDocHandler({ opts }) {
  const {  fileData, storeUuid, reloadStore = true } = opts
  const { files, type } = fileData
  const images = files.map(f => ({
    fileName: f.name,
    contentType: f.type,
    type,
  }))
  const data = { images }
  const res = yield call(authorizedRequest, getS3SignedUrl, {
    restaurantUuid: storeUuid,
    data,
  })
  try{
    if(res.status === 200) {
      yield call(uploadDocs, { files, data: res.data })
      // Putting a delay so s3 uploades can be synced with our database
      yield delay(3000)
      if(reloadStore) {
        // Getting current store details with updated docs/photos/images
        yield put(storeActions.getStore({restaurantUuid: storeUuid}))
      } else {
        const store = yield select(state => state.Store.store)
        yield put(ivrActions.getSavedStore({ store }))
      }
      yield put(actions.uploadDocResolved())
    } else {
      throw Error(res)
    }
  } catch(error){
    console.error('Something went wrong while uploading doc.', error)
    yield put(actions.uploadDocRejected(error))
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: 'Something went wrong while uploading. Please try again.'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
  }
}


export default function* () {
  yield takeLatest(types.UPLOAD_DOC_INTENT, uploadDocHandler)
}
