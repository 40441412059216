import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {parse2DecimalFloat} from '../../../../utils/number'
import PercentBar from '../../../PercentBar'
import './styles.scss'

PlatformStat.propTypes = {
  campaign: PropTypes.object,
}

const PLATFORMS = {
  'ios': 'ios',
  'android': 'android',
  'desktop': 'desktop',
}

const getClassName = (platform) => {
  // default platform will be desktop
  if(typeof platform === 'string') {
    return PLATFORMS[platform.toLowerCase()] || PLATFORMS.desktop
  }
  return PLATFORMS.desktop
}

function PlatformStat(props) {
  const { campaign = {} } = props
  const platforms = _.get(campaign, 'statistic.platforms')
  const totalClicks = _.get(campaign, 'statistic.customer_click_count')
  if(!platforms || platforms.length === 0) {
    return null
  }
  return (
    <div className='eg-platform-stat-container'>
      <div className='title'>
        Platform
      </div>
      {
        platforms.map((platform, key) => {
          const percentage = parse2DecimalFloat((platform.customer_click_count/totalClicks) * 100)
          return (
            <div className='eg-platform-stat' key={key}>
              <div className='top-row'>
                <div className={`platform ${getClassName(platform.key)}`}/>
                <div className='label'>
                  {platform.key}
                </div>
              </div>
              <PercentBar
                quantity={platform.customer_click_count}
                value={percentage}
                displayValues={true} className='percent-bar'/>
            </div>
          )
        })
      }
    </div>
  )
}

export default PlatformStat