import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { Select } from '../../../../FormComponents'
import { predicateTypes, predicateAttributes, predicateComparison } from '../../../../../services/segments'
import { actions as segmentActions } from '../../../../../actions/Segment'

import { campaignTypes } from '../../../../../services/campaigns'
import { journeyTypes } from '../../../../../services/journeys'

import './styles.scss'


const predicateOptions = [
  { value: predicateAttributes.CAMPAIGN_ID, label: 'Campaign' },
  { value: predicateAttributes.JOURNEY_ID, label: 'Journey' },
]

/**
* While reconstructing the query, we get what kind of type(journey or campaign) do we have
* for this query. is it Campaign or Journey attribue type
* @param {Object} typePredicate Type predicate
* {
*   type: 'id',
*   attribute: 'campaign.__id',
*   comparison: 'eq',
*   value: 'jojo242429sfsfs'
* }
* @returns {Object} selected option to select type. i.e.
* {
*   value: predicateAttributes.CAMPAIGN_ID,
*   label: 'Campaign'
*  }
*/
const getInitialType = (typePredicate) => {
  let initialSelectedType = predicateOptions[0]
  if(typePredicate && typePredicate.attribute === predicateAttributes.JOURNEY_ID){
    initialSelectedType = predicateOptions[1]
  }
  return initialSelectedType
}

const TypePredicate = props => {
  let { typePredicate, onChange, onClearPredicates } = props
  const dispatch = useDispatch()
  // const selectedOption = predicateOptions.find(op => op.value === _.get(predicate, '[0].type'))
  let initialSelectedType = getInitialType(typePredicate)
  const [selectedOption , setSelectedOption] = useState(initialSelectedType)
  const [selectedItem, setSelectedItem] = useState(null)
  const [list, setList] = useState([])
  const { campaigns, loading: campaignLoading } = useSelector(state => state.Segment.dynamicCampaignList)
  const { loading: journeyLoading, list : journeyList }  = useSelector(state => state.Segment.dynamicJourneyList)
  const mounted = useRef()
  const createdQuery = useRef()

  const loading  = campaignLoading || journeyLoading

  useEffect(() => {
    // Initial privy/popup id loading
    if (!mounted.current) {
      mounted.current = true
      !campaigns && dispatch(segmentActions.getCampaignsForDynamicSegment({
        status: campaignTypes.DELIVERED
      }))
      !_.get(journeyList, 'items') && dispatch(segmentActions.getJourneysForDynamicSegment({
        status: journeyTypes.ACTIVE
      }))
    }
  },[campaigns, dispatch, journeyList])

  const setInitialList = useCallback(() => {
    if(selectedOption.value === predicateAttributes.CAMPAIGN_ID){
      const mappedJourney = campaigns.map((campaign) => {
        return {
          ...campaign,
          label: campaign.name,
          value: campaign.id
        }
      })
      setList(mappedJourney)
    } else if(selectedOption.value === predicateAttributes.JOURNEY_ID){
      const listItems = _.get(journeyList, 'items', [])
      const mappedJourney = listItems.map((journey) => {
        return {
          ...journey,
          label: journey.name,
          value: journey.id
        }
      })
      setList(mappedJourney)
    }
  }, [campaigns, journeyList, selectedOption.value])

  const setInitialItem = useCallback(() => {
    if(typePredicate){
      let selectedItem = null
      if(selectedOption.value === predicateAttributes.CAMPAIGN_ID){
        selectedItem = campaigns.find(campaign => campaign.id === typePredicate.value)
      } else if (selectedOption.value === predicateAttributes.JOURNEY_ID){
        const listItems = _.get(journeyList, 'items', [])
        selectedItem = listItems.find(journey => journey.id === typePredicate.value)
      }
      if(selectedItem) {
        selectedItem.label = selectedItem.name
        setSelectedItem({
          ...selectedItem,
          value: selectedItem.id
        })
      }
    }
  }, [campaigns, journeyList, selectedOption.value, typePredicate])

  useEffect(() => {
    const hasCampaignsAndJourneyList = campaigns &&  _.get(journeyList, 'items.length')
    if(!createdQuery.current && hasCampaignsAndJourneyList) {
      createdQuery.current = true
      setInitialList()
      setInitialItem()
    }
  }, [campaigns, dispatch, journeyList, selectedOption.value, setInitialItem, setInitialList])


  const getCampaignList = () => {
    if(campaigns) {
      const mappedCampaigns = campaigns.map((campaign) => {
        return {
          ...campaign,
          label: campaign.name
        }
      })
      setList(mappedCampaigns)
    }
  }

  const getJourneyList = () => {
    if(journeyList) {
      const listItems = _.get(journeyList, 'items', [])
      const mappedJourney = listItems.map((journey) => {
        return {
          ...journey,
          label: journey.name
        }
      })
      setList(mappedJourney)
    }
  }



  const onTypeChange = (item) => {
    onClearPredicates(predicateTypes.ID)
    setList([])
    setSelectedItem(null)
    setSelectedOption(item)
    if(item.value === predicateAttributes.CAMPAIGN_ID){
      getCampaignList()
    } else {
      getJourneyList()
    }
  }

  const handleItemChange =(item) => {
    const updatedIdPredficate =  {
      type: predicateTypes.ID,
      attribute: selectedOption.value,
      comparison: predicateComparison.EQ,
      value: item.id,
    }
    onChange(updatedIdPredficate)
    setSelectedItem(item)
  }

  return (
    <>
      <div className='predicate-cell'>
        <Select
          name='segments-predicates-source-option'
          className='type-select'
          placeholder='Typegit '
          options={ predicateOptions }
          selectedItem={ loading  ? '' : selectedOption || null }
          onChange={ onTypeChange }
          loading={loading}
        />
      </div>
    <div className='text-node'>
        is
    </div>
    <div className='predicate-cell'>
      <Select
        className='element-selection'
        options={list}
        selectedItem={selectedItem}
        onChange={handleItemChange}
        loading={loading}
        placeholder={
          loading
            ? 'loading'
            : `Choose ${selectedOption.label.toLowerCase()}`
        }
      />
    </div>
    </>
  )
}

TypePredicate.propTypes = {
  typePredicate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onClearPredicates: PropTypes.func.isRequired,
}

export default TypePredicate
