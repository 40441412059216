import loadjs from 'loadjs'

const googleServices = {
  gmail: {
    discoveryDocs: 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest',
    scope: 'https://www.googleapis.com/auth/gmail.readonly'
  },
  calendar: {
    discoveryDocs: null,
    scope: 'https://www.googleapis.com/auth/calendar'
  }
}

export const googleConnectInit = () => {
  loadjs('https://apis.google.com/js/client.js', () => {
    let gapi = window.gapi
    /**
       *  On load, call to load the auth2 library and API client library.
       */
    gapi.load('client:auth2', () => {
      console.log('gapi ready!')
    })
  })
}

export const getGoogleApiToken = (serviceName) => {
  if (!googleServices[serviceName]) {
    throw new Error(`Unable to handle service ${serviceName}`)
  }
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const discoveryDocs = googleServices[serviceName].discoveryDocs
  const scope = googleServices[serviceName].scope
  const gapi = window.gapi

  gapi.client.init({ discoveryDocs, clientId, scope })

  let GoogleAuth = gapi.auth2.getAuthInstance()

  return new Promise((resolve, reject) => {
    GoogleAuth.grantOfflineAccess()
      .then(resp => {
        const authCode = resp.code
        resolve(authCode)
      })
      .catch(error => {
        reject(error)
      })
  })
}
