import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FileUploader from '../../../../../../FileUploader'
import StatelessModal from '../../../../../../StatelessModal'
import Button, { types as buttonTypes } from '../../../../../Button'

import './styles.scss'

const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'image/gif']
const MAX_FILE_SIZE = 4194304 // 4MB

const ImageTrigger = props => {
  const { className = '', onFileSelection } = props
  const [ displayUploader, setDisplayUploader ] = useState(false)
  const [ uploadError, setUploadError ] = useState(null)

  useEffect(() => {
    // Hide the error message when the modal is off
    if (!displayUploader) {
      setUploadError(null)
    }
  }, [displayUploader])

  const handleImageClick = () => {
    setDisplayUploader(value => !value)
  }

  const handleFileSelection = (files) => {
    if (files && files.length) {
      onFileSelection(files[0])
    }
  }

  const handleUploadError = (msg) => {
    setUploadError(msg)
  }

  return (
    <div className='eg-textarea-addon-trigger-image'>
      <div className={ className } onClick={ handleImageClick } data-testid='eg-textarea-addon-trigger-image' />
      {
        displayUploader && (
          <StatelessModal onClose={ () => setDisplayUploader(false) } className='uploader-modal'>
            <div className='title'>
              Upload image or GIF
            </div>
            <FileUploader
              multiple={ false }
              onFileUpload={ handleFileSelection }
              fileType={ ACCEPTED_TYPES.join(',') }
              maxSize={ MAX_FILE_SIZE }
              onError={ handleUploadError }
            >
              <div className='uploader-body' data-testid='mms-image-uploader'>
                <div className='img' />
                <div className='strong'>
                  Drag & drop image here
                </div>
                <div className='description'>
                  Upload a PNG, JPG or GIF file under 4MB.
                </div>
                {
                  uploadError && (
                    <div className='error'>{ uploadError }</div>
                  )
                }
                <Button className='button' type={ buttonTypes.PRIMARY } text='CHOOSE FILE' />
              </div>
            </FileUploader>
          </StatelessModal>
        )
      }
    </div>
  )
}

ImageTrigger.propTypes = {
  className: PropTypes.string,
  onFileSelection: PropTypes.func
}

export default ImageTrigger
