import React from 'react'
import PropTypes from 'prop-types'
import ReactDevicePreview from 'react-device-preview'
import HeaderSMS from './HeaderSMS'
import HeaderBrowser from './HeaderBrowser'

import './styles.scss'

export { HeaderSMS, HeaderBrowser }

const PhonePreview = props => {
  const {
    children,
    className = '',
    scale = '0.7'
  } = props

  return (
    <div className={ `eg-phone-preview ${className}` }>
      <ReactDevicePreview
        device="iphonex"
        scale={ scale }
      >
        <div className='icons'>
          <div className='time'>1:20</div>
          <div className='ios-top-icons' />
        </div>
        { children }
      </ReactDevicePreview>
    </div>
  )
}

PhonePreview.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  scale: PropTypes.string
}

export default PhonePreview
