import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import useValidator, { validationPatterns } from '../../../hooks/validator'
import SectionHeader from '../SectionHeader'
import StatelessModal from '../../StatelessModal'
import { NumberList } from '../../VirtualNumber'
import {
  Input,
  Button
} from '../../FormComponents'
import { types as buttonTypes } from '../../FormComponents/Button'
import LocalSpinner from '../../LocalSpinner'
import { formatNumber } from '../../../services/virtualNumbers'

import './styles.scss'

const FIELD_FWD_NUMBER = 'forward_number'

const Forwarding = props => {
  const { register, formState, errors, getValues } = useValidator()
  const [ displayModal, setDisplayModal ] = useState(false)
  const [ submitted, setSubmitted ] = useState(false)
  const {
    numberId,
    getNumber,
    clearNumber,
    virtualNumber,
    posting
  } = props
  const restaurantUuid = _.get(props, 'match.params.uuid')

  useEffect(() => {
    if (numberId) {
      getNumber(numberId, restaurantUuid)
    }
    return () => {
      clearNumber()
    }
  }, [getNumber, clearNumber, numberId, restaurantUuid])

  useEffect(() => {
    if (!posting && submitted) {
      setDisplayModal(true)
    }
  }, [posting, submitted])

  const submit = () => {
    props.forwardNumber({
      ...getValues(),
      phoneUuid: numberId,
      restaurantUuid
    })
    setSubmitted(true)
  }

  const handleDoneClick = () => {
    props.history.replace(`#numbers/${virtualNumber.id}/details`)
  }

  const submitDisabledClass = !formState.isValid ? '-disabled' : ''

  // TODO: find a way to make this generic
  const phoneErrors = errors[FIELD_FWD_NUMBER]

  return (
    <div className='eg-forwarding'>
      <div className='numbers'>
        <NumberList />
      </div>
      {
        (!props.virtualNumber || props.loading) ? (
          <div className='section-wrapper'>
            <LocalSpinner />
          </div>
        ) : (
          <div className='section-wrapper'>
            <SectionHeader
              title={ props.virtualNumber.title }
              rightContent={ () => <div className='header-number'>{ props.virtualNumber.formattedNumber }</div> }
            />
            <div className='body'>
              <div className='title'>Setup call forwarding</div>
              <p className='subtitle'>
                Divert incoming calls to your preferred business or personal numbers.
              </p>

              <div className='twocols'>
                <div className='left'>
                  <div className='phoneicon' />
                  <div className='vertical-line'>
                    <div className='vertical-indicator'>
                      <span />
                    </div>
                  </div>
                </div>

                <div className='form'>
                  <div className='description'>Smart number</div>
                  <div className='smartnumber'>{ props.virtualNumber.formattedNumber }</div>
                  <div className='row'>
                    <div className='description label'>
                      Enter phone number where you want to divert calls
                    </div>
                    <Input
                      name={ FIELD_FWD_NUMBER }
                      placeholder=''
                      className='phone-input'
                      ref={ register({ required: true, pattern: validationPatterns.PHONE }) }
                    />
                    {
                      phoneErrors && (
                        <div className='error'>
                          { phoneErrors.type === 'required' ? phoneErrors.message : 'Invalid phone number' }
                        </div>
                      )
                    }
                  </div>

                  <div className='actions'>
                    <Button
                      type={ buttonTypes.PRIMARY }
                      text='INITIATE'
                      className={ `button -primary ${submitDisabledClass }` }
                      disabled={ !formState.isValid }
                      loading={ props.posting }
                      onClick={ submit }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        displayModal && (
          <StatelessModal className='modal' closeStyle='modalclose'>
            <div className='title'>Call forwading is now active!</div>
            <div className='subtitle'>
              All incoming calls to { virtualNumber.formattedNumber } will now be
              diverted to { formatNumber(virtualNumber.forwardNumbers[0].phone_number) }
            </div>
            <Button className='button -primary' text='DONE' onClick={ handleDoneClick } />
          </StatelessModal>
        )
      }
    </div>
  )
}

Forwarding.propTypes = {
  virtualNumber: PropTypes.object,
  numberId: PropTypes.string,
  posting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object,
  getNumber: PropTypes.func.isRequired,
  forwardNumber: PropTypes.func.isRequired,
  clearNumber: PropTypes.func.isRequired
}

export default Forwarding
