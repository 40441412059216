import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const RemoveButton = props => {
  const { className, onClick } = props
  const classes = classNames([{
    'eg-action-buttons-remove': true
  }], className)

  return (
    <div data-testid='eg-action-buttons-remove' className={ classes } onClick={ onClick } />
  )
}

RemoveButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default RemoveButton
