import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'


import LocalSpinner from '../../LocalSpinner'
import StatelessModal from '../../StatelessModal'

import { storeSections as sections } from '../../../services/stores'

import StoreSection from './StoreSection'
import StoreMainInfo from './StoreMainInfo'
// profile sections
import { StoreLinksForm, StoreLinksInfo } from './ProfileSections/Links'
import { OpDetailsForm, OpDetailsInfo } from './ProfileSections/OperationDetails'
import { BusinessDetailsForm, BusinessDetailsInfo } from './ProfileSections/BusinessDetails'
import { MediaProfileForm, MediaProfileInfo } from './ProfileSections/MediaProfileDetails'
import { PhotoForm, PhotoInfo } from './ProfileSections/PhotoDetails'
import { StoreInfoForm } from './ProfileSections/StoreInfo'

import styles from './index.module.scss'

const sectionComponents = {
  [sections.LINKS]: { form: StoreLinksForm, info: StoreLinksInfo },
  [sections.OPERATION_DETAILS]: { form: OpDetailsForm, info: OpDetailsInfo },
  [sections.BUSINESS_DETAILS]: { form: BusinessDetailsForm, info: BusinessDetailsInfo },
  [sections.MEDIA_PROFILES]: { form: MediaProfileForm, info: MediaProfileInfo },
  [sections.PHOTOS]: { form: PhotoForm, info: PhotoInfo },
  [sections.STORE_INFO]: { form: StoreInfoForm, info: null }
}

const StoreProfile = props => {
  const [ cardsState, setCardsState ] = useState({ selectedComponent: null, section: null })
  const { store } = props
  const components = [
    { name: sections.LINKS, editable: hasUrl(store, 'links') },
    { name: sections.OPERATION_DETAILS, editable: store && store.hasSchedule },
    { name: sections.BUSINESS_DETAILS, editable: hasBusinessDetails(store) },
    { name: sections.MEDIA_PROFILES, editable: hasUrl(store, 'mediaProfiles' ) },
    { name: sections.PHOTOS, editable: !!_.get(store, 'imageLinks.length')},
    { name: sections.STORE_INFO, editable: true }
  ]

  const isEditable = component => {
    const componentState = components.find(c => c.name === component)
    return componentState.editable
  }

  const handleEditClick = (component, section) => {
    if (!component || !component.form) return
    setCardsState(prevState => ({ ...prevState, selectedComponent: component.form, section }))
  }

  const handleModalClose = () => {
    setCardsState(prevState => ({ ...prevState, selectedComponent: null, section: null }))
  }

  const handleModalSave = (data) => {
    props.updateStore({
      ...store,
      [cardsState.section]: data
    }, cardsState.section)
    setCardsState(prevState => ({ ...prevState, selectedComponent: null, section: null }))
  }

  const sectionComponent = section => {
    return sectionComponents[section]
  }

  return (
    <div>
      <div className={ styles.storeDetailsContainer }>
        <div className={ styles.mainWrapper }>
          {
            props.loadingStore || props.savingStore || !props.store ? (
              <LocalSpinner className={ styles.spinner } />
            ) : (
              <>
                <div className={ styles.leftPanel }>
                  <StoreMainInfo
                    store={ props.store }
                    onEditClick={ () => 
                      handleEditClick(sectionComponent(sections.STORE_INFO), sections.STORE_INFO)
                    }
                    component={ sectionComponent(sections.STORE_INFO) }
                    categories={ props.categories }
                  />
                </div>
                <div className={ styles.rightPanel }>
                  {/* Links */}
                  <StoreSection
                    title='Website and links'
                    description='Add links to your website, online delivery, reservation, and catering.'
                    addItemLabel='Add links'
                    store={ store }
                    section={ sections.LINKS }
                    component={ sectionComponent(sections.LINKS) }
                    isEditable={ isEditable(sections.LINKS) }
                    onEditClick={ (component) => handleEditClick(component, sections.LINKS) }
                  />

                  {/* Operation details */}
                  <StoreSection
                    title='Operation details'
                    description='Indicate your store operating hours'
                    addItemLabel='Add operation details'
                    store={ store }
                    section={ sections.OPERATION_DETAILS }
                    component={ sectionComponent(sections.OPERATION_DETAILS) }
                    isEditable={ isEditable(sections.OPERATION_DETAILS) }
                    onEditClick={ (component) => handleEditClick(component, sections.OPERATION_DETAILS) }
                  />

                  {/* Business Details  */}
                  <StoreSection
                    title='Business details'
                    description='Give your store a description and add details about the manager.'
                    addItemLabel='Add business details'
                    store={ store }
                    section={ sections.BUSINESS_DETAILS }
                    component={ sectionComponent(sections.BUSINESS_DETAILS) }
                    isEditable={ isEditable(sections.BUSINESS_DETAILS) }
                    onEditClick={ component => handleEditClick(component, sections.BUSINESS_DETAILS) }
                  />

                  {/* Social media profiles  */}
                  <StoreSection
                    title='Social media profiles'
                    description='Add links to your store social media pages.'
                    addItemLabel='Add social media links'
                    store={ store }
                    section={ sections.MEDIA_PROFILES }
                    component={ sectionComponent(sections.MEDIA_PROFILES) }
                    isEditable={ isEditable(sections.MEDIA_PROFILES) }
                    onEditClick={ (component) => handleEditClick(component, sections.MEDIA_PROFILES) }
                  />

                  {/* Store Photos  */}
                  <StoreSection
                    title='Photos'
                    description='Spice up your business pages with some amazing photos.'
                    addItemLabel='Add photos'
                    store={ store }
                    section={ sections.PHOTOS }
                    component={ sectionComponent(sections.PHOTOS) }
                    isEditable={ isEditable(sections.PHOTOS) }
                    onEditClick={ (component) => handleEditClick(component, sections.PHOTOS) }
                  />

                </div>
              </>
            )
          }
        </div>
        {
          cardsState.selectedComponent ? (
            <StatelessModal className={ styles.modal } onClose={ handleModalClose }>
              <cardsState.selectedComponent
                store={ store }
                categories={ props.categories }
                onCancel={ handleModalClose }
                onSave={ handleModalSave }
              />
            </StatelessModal>
          ) : null
        }
      </div>
    </div>
  )
}

/**
 * Checks if object has a array as a property for given key
 * and atleast has one element which has a property called `url`
 * @param {*} obj 
 * @param {*} key 
 */
const hasUrl = (obj, key) => {
  return !!_.get(obj, `${key}.length`) && !!obj[key].find(lnk => !!lnk.url)
}

/**
 * Checks if there is some business details/manager details in store or not
 * @param {} store 
 */
const hasBusinessDetails = (store) => {
  if(!store) return false
  const description = _.get(store, 'description')
  const manager = _.get(store, 'businessDetails.manager') || {}
  return !!description || !!manager.name || !!manager.email || !!manager.phone
}

StoreProfile.propTypes = {
  store: PropTypes.object,
  categories: PropTypes.array,
  loadingStore: PropTypes.bool.isRequired,
  loadingCategories: PropTypes.bool.isRequired,
  savingStore: PropTypes.bool.isRequired,
  getStore: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  updateStore: PropTypes.func.isRequired,
  match: PropTypes.object,
}

export default StoreProfile
