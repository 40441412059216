// import React,  { useState, useEffect, useCallback } from 'react'
import React from 'react'
import PropTypes from  'prop-types'
// import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SmsLoyaltySettings from './SmsLoyaltySettings'
import SmsRatingSettings from './SmsRatingSettings'
import MainAutomationCard from './MainAutomationCard'

// import { actions as storeActions } from '../../../actions/Store'
import './styles.scss'

const automationPaths = {
  SMS_LOYALTY_SETTINGS: '#automations/sms-loyalty-settings',
  SMS_REVIEW_SETTINGS: '#automations/sms-review-settings'
}


export const handleInternalRoute = (hash, store) => {
  switch(hash) {
    case automationPaths.SMS_LOYALTY_SETTINGS:
      return <SmsLoyaltySettings store={ store } />
    case automationPaths.SMS_REVIEW_SETTINGS:
      return <SmsRatingSettings store={ store } />
    default:
      return (
        <div className='eg-store-automation'>
          <MainAutomationCard
            store= {store}
            title= 'SMS Loyalty Sign Up'
            subtitle=  'Allow your customers to sign up for your loyalty program via SMS.'
            type= 'loyalty'
            isTurnedOn= {!!store.ivr}
            path={automationPaths.SMS_LOYALTY_SETTINGS}
          />
          <MainAutomationCard
            store= {store}
            title= 'SMS Review Builder'
            subtitle= 'Request feedback after each phone order via SMS.'
            type= 'review'
            isTurnedOn={!!store.rating}
            path={automationPaths.SMS_REVIEW_SETTINGS}
          />
        </div>
      )
  }
}

const StoreAutomation = (props) => {
  const { store, location } = props
  return handleInternalRoute(location.hash, store)
}

StoreAutomation.propTypes = {
  store: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default withRouter(StoreAutomation)
