import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class GoogleConnectBanner extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    restaurantEmail: PropTypes.string,
  }

  render() {
    return (
      <div className={styles.bannerContainer}>
        <div className={styles.textContent}>
          <div className={styles.title}>
            Oh no. Your inbox is no longer connected with Eatgeek.
          </div>
          <div className={styles.description}>
            The integration with your inbox <span className={styles.boldText}>{this.props.restaurantEmail}</span> has expired.
            Reconnect it or connect a new account if you wish to continue importing orders from your inbox. Make sure to integrate the email addresss where you currently receive your caterings orders.
          </div>
        </div>
        <div className={styles.googleSyncButton}>
          {this.props.children} {/*Google Connect Button.*/}
        </div>
      </div>
    )
  }
}
