import React from 'react'
import { useSelector } from 'react-redux'

import Spinner from '../../LocalSpinner'
import StoreRow from './StoreRow'
import './styles.scss'

const StoreList = () => {
  const { loadingList, storeList } = useSelector(({ Store }) => Store)

  if(loadingList) {
    return (
      <div className='eg-store-list'>
        <Spinner className='spinner'/>
      </div>
    )
  }

  return (
    <div className='eg-store-list'>
      {
        storeList.map((store, index) =>  <StoreRow store={store} index={index} key={index} />)
      }
    </div>
  )
}

export default StoreList
