import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick } from '../../../hooks/shared'
import { statuses } from '../../../services/journeys'
import './styles.scss'

/**
 * Display journey options
 *
 * @component
 * @example
 * 
 * const handleStatusClick = () => { ... }
 * const journey = {
 *   'id': '608bffdaf58d4e0009121b65',
 *   'chain': {
 *     'uuid': '1f77a64c-7933-48fe-af05-34f29621d3d3'
 *     'name': 'Halal guys'
 *   },
 *   'name': 'Some message',
 *   'trigger': 'SignUp',
 *   'status: 'active'
 *   'stats': {
 *     delivered:  5
 *   }
 * }
 *
 * return (
 *   <JourneyOptions
 *     journey={ journey }
 *     onStatusClick={ handleStatusClick }
 *   />
 * )
*/
const JourneyOptions = props => {
  const { journey, onStatusClick, onEditClick } = props
  const [ active, setActive ] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    setActive(false)
  })

  const handleTriggerClick = () => {
    setActive(!active)
  }

  const handleStatusClick = () => {
    const newStatus = journey.status === statuses.ACTIVE ? statuses.INACTIVE : statuses.ACTIVE
    onStatusClick(newStatus)
    setActive(false)
  }

  return (
    <div className='eg-journey-options' ref={ wrapperRef }>
      <div data-testid='journey-options-trigger' className='trigger' onClick={ handleTriggerClick } />
      {
        active && (
          <div className='menu'>
            <div className='item' onClick={ handleStatusClick }>
              { journey.status === statuses.ACTIVE ? 'Deactivate' : 'Activate' }
            </div>
            <div className='item' onClick={ onEditClick }>
              Edit
            </div>
          </div>
        )
      }
    </div>
  )
}

JourneyOptions.propTypes = {
  journey: PropTypes.object.isRequired,
  onStatusClick: PropTypes.func,
  onEditClick: PropTypes.func
}

export default JourneyOptions
