import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import TextField from '../../../../FormComponents/TextField'
import TextArea from '../../../../FormComponents/TextArea'
import PhoneInput from '../../../../FormComponents/PhoneInput'
import Button, { types as buttonTypes } from '../../../../FormComponents/Button'
import FormValidator from '../../../../../validators/FormValidator'
import {
  phone as phoneNumberValidator,
  email as emailValidator,
} from '../../../../../validators/customValidations'

import styles from './index.module.scss'
import { BUSINESS_DETAILS } from '../../../../../services/stores'

let validation
const getValidatorRules = () => {
  let validatorsRules = [
    ...phoneNumberValidator(BUSINESS_DETAILS.MANAGER_NUMBER.key, false),
    ...emailValidator(BUSINESS_DETAILS.MANAGER_EMAIL.key, false),
  ]
  return new FormValidator(validatorsRules)
}

const validator = getValidatorRules()

const BusinessDetails = props => {
  const store = props.store
  const [ formFields, setFormFields ] = useState(() => {
    return getInitialState(store)
  })

  const getFieldError = (fieldName) => {
    if (formFields[fieldName].dirty && !validation[fieldName].isValid) {
      return validation[fieldName].message
    }
    return ''
  }

  const handleInputChange = (event) => {
    event.persist()
    setFormFields(prevState => {
      if (!event.target) {
        return prevState
      }
      return {
        ...prevState,
        [event.target.name]: {
          value: event.target.value,
          dirty: true
        }
      }
    })
  }

  const handleCancelClick = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleSaveClick = () => {
    if (props.onSave) {
      const payload = {}
      Object.keys(formFields).forEach(key => {
        payload[key] =  formFields[key].value
      })
      props.onSave(payload)
    }
  }

  validation = validator.validate(formFields)

  return (
    <div className={ styles.linksContainer }>
      <h3>Business details</h3>
      <p>Give your store a description and add details about the manager.</p>
      <div className={ styles.linksFormContainer }>
        <div className={ styles.linksFormRow}>
          <div className={styles.label}>Description</div>
          <TextArea
            name={ BUSINESS_DETAILS.ABOUT.key }
            label=''
            placeholder='Tell us about your store'
            value={ formFields[BUSINESS_DETAILS.ABOUT.key].value }
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.linksFormRow}>
          <div className={styles.label}>Manager name</div>
          <TextField
            name={ BUSINESS_DETAILS.MANAGER_NAME.key }
            label=''
            placeholder='Enter name'
            value={ formFields[BUSINESS_DETAILS.MANAGER_NAME.key].value }
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.linksFormRow}>
          <div className={styles.label}>Manager email</div>
          <TextField
            name={ BUSINESS_DETAILS.MANAGER_EMAIL.key }
            label=''
            placeholder='Enter email address'
            errorMessage={ getFieldError(BUSINESS_DETAILS.MANAGER_EMAIL.key) }
            error={!validation[BUSINESS_DETAILS.MANAGER_EMAIL.key].isValid && formFields[BUSINESS_DETAILS.MANAGER_EMAIL.key].dirty}
            value={ formFields[BUSINESS_DETAILS.MANAGER_EMAIL.key].value }
            onChange={ handleInputChange }
          />
        </div>
        <div className={ styles.phoneRow}>
          <div className={styles.label}>Manager contact number</div>
          <PhoneInput
            name={ BUSINESS_DETAILS.MANAGER_NUMBER.key }
            label=''
            placeholder='Enter your phone number'
            errorMessage={ getFieldError(BUSINESS_DETAILS.MANAGER_NUMBER.key) }
            error={!validation[BUSINESS_DETAILS.MANAGER_NUMBER.key].isValid && formFields[BUSINESS_DETAILS.MANAGER_NUMBER.key].dirty}
            value={ formFields[BUSINESS_DETAILS.MANAGER_NUMBER.key].value }
            className={styles.inputWrapper}
            onChange={ handleInputChange }
          />
        </div>
      </div>
      <div className={ styles.actionsWrapper }>
        <Button
          text='CANCEL'
          type={ buttonTypes.SECONDARY }
          onClick={ handleCancelClick }
        />
        <Button
          text='SAVE'
          type={ buttonTypes.PRIMARY }
          disabled={ !validation.isValid }
          onClick={ handleSaveClick }
        />
      </div>
    </div>
  )
}

/**
 * Sets initial state for the from, i.e getting all the existing field values
 * @param {Object} store restaurant store info
 */
const getInitialState = (store) => {
  const description = get(store, 'businessDetails.description') || ''
  const manager = get(store, 'businessDetails.manager') || {}
  const initialFormState = {
    [BUSINESS_DETAILS.ABOUT.key]: {
      value : description,
      dirty: false,
    },
    [BUSINESS_DETAILS.MANAGER_NAME.key]: {
      value: manager.name || '',
      dirty: false
    },
    [BUSINESS_DETAILS.MANAGER_EMAIL.key]: {
      value: manager.email || '',
      dirty: false
    },
    [BUSINESS_DETAILS.MANAGER_NUMBER.key]: {
      value: manager.phone || '',
      dirty: false
    }
  }

  return initialFormState
}


BusinessDetails.propTypes = {
  store: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default BusinessDetails
