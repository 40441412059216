import Joi from 'joi'
import _ from 'lodash'
import { interactionSources as sources, sourceMediums } from '../../../services/customers'
import {
  predicateTypes,
  predicateAttributes,
  predicateComparison
} from '../../../services/segments'

const amountComparison = {
  EQ: 'eq',
  GTE: 'gte',
  GT: 'gt',
  LT: 'lt',
  LTE: 'lte'
}

const amountPredicate = Joi.object({
  comparison: Joi.string().valid(..._.values(amountComparison)).default(amountComparison.GTE),
  value: Joi.number().default(1)
})

const parentPredicate = Joi.alternatives().try(
  Joi.object({
    type: Joi.string().valid(predicateTypes.PURCHASE).required(),
    attribute: Joi.string().valid(predicateAttributes.SOURCE).required(),
    comparison: Joi.string().valid(
      predicateComparison.EQ,
      predicateComparison.NE,
      predicateComparison.ANY
    ).required(),
    value: Joi.string()
      .when('comparison', { is: predicateComparison.EQ, then: Joi.valid(sources.SQUARE, sources.TOAST).required() })
      .when('comparison', { is: predicateComparison.NE, then: Joi.valid(sources.SQUARE, sources.TOAST).required() }),
    amount: amountPredicate.default()
  }),

  Joi.object({
    type: Joi.string().valid(predicateTypes.SIGN_UP).required(),
    attribute: Joi.string().valid(predicateAttributes.SOURCE_MEDIUM).required(),
    comparison: Joi.string().valid(
      predicateComparison.EQ,
      predicateComparison.NE,
      predicateComparison.ANY
    ).required(),
    value: Joi.string()
      .when('comparison', { is: predicateComparison.EQ, then: Joi.valid(sourceMediums.PRIVY, sourceMediums.WEBHOOK).required() })
      .when('comparison', { is: predicateComparison.NE, then: Joi.valid(sourceMediums.PRIVY, sourceMediums.WEBHOOK).required() })
  }),

  Joi.object({
    type: Joi.string().valid(predicateTypes.SHORTLINK_CLICK).required(),
    amount: amountPredicate.default()
  })
).match('one')

const andSchema = Joi.object({
  type: Joi.string().valid(predicateTypes.AND).required(),
  predicates: Joi.array().ordered(parentPredicate.required()).items(
    Joi.object({
      type: Joi.string().valid(predicateTypes.DATE).required(),
      attribute: Joi.string().valid(predicateAttributes.ACTIVITY_DATE).required(),
      comparison: Joi.string().valid(
        predicateComparison.EQ,
        predicateComparison.NE,
        predicateComparison.GTE,
        predicateComparison.LTE
      ).required(),
      value: Joi.date().iso().required()
    }),

    Joi.object({
      type: Joi.string().valid(predicateTypes.NUMBER).required(),
      attribute: Joi.string().valid(predicateAttributes.TOTAL).required(),
      comparison: Joi.string().valid(
        predicateComparison.EQ,
        predicateComparison.NE,
        predicateComparison.GTE,
        predicateComparison.LTE
      ).required(),
      value: Joi.number().required()
    }),

    Joi.object({
      type: Joi.string().valid(predicateTypes.ID).required(),
      attribute: Joi.string().valid(predicateAttributes.CAMPAIGN_ID, predicateAttributes.JOURNEY_ID).required(),
      comparison: Joi.string().valid(
        predicateComparison.EQ,
        predicateComparison.NE,
        predicateComparison.ANY
      ).required(),
      value: Joi.string()
        .when('comparison', { is: predicateComparison.EQ, then: Joi.required() })
        .when('comparison', { is: predicateComparison.NE, then: Joi.required() })
    })
  ).min(1).unique().required()
})

const orSchema = Joi.object({
  type: Joi.string().valid(predicateTypes.OR).required(),
  predicates: Joi.array().items(andSchema).min(1).required()
})

export default Joi.array().items(orSchema).min(1).required()
