import { types } from '../actions/Store'

const initialState = {
  store: null,
  categories: [],
  error: null,
  loadingStore: false,
  loadingCategories: false,
  savingStore: false,
  uploadError: null,
  uploadingImage: false,
  lastUsedChainUuid: null,
  storeList: [],
  storeListLookup: {},
  loadingList: false,
  listError: false,
  integration: {},
  integrationLoading: false,
  integrationError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STORE_LIST_INTENT:
      return {
        ...state,
        storeList: [],
        lastUsedChainUuid: null,
        loadingList: true,
        listError: false,
      }

    case types.GET_STORE_LIST_INTENT_RESOLVED:
      return {
        ...state,
        ...action.data,
        lastUsedChainUuid: action.data.restaurantChainUuid,
        loadingList: false,
        listError: false,
      }

    case types.GET_STORE_LIST_INTENT_REJECTED:
      return {
        ...state,
        lastUsedChainUuid: null,
        storeList: [],
        loadingList: false,
        listError: action.error,
      }

    case types.CREATE_STORE_INTENT:
      return {
        ...state,
        error: null,
        loadingStore: true
      }

    case types.CREATE_STORE_INTENT_RESOLVED:
      return {
        ...state,
        store: action.data,
        error: null,
        loadingStore: false,
        lastUsedChainUuid: null,
      }

    case types.CREATE_STORE_INTENT_REJECTED:
      return {
        ...state,
        store: null,
        error: action.error,
        loadingStore: false
      }
    
    case types.GET_STORE_INTENT:
      return {
        ...state,
        error: null,
        loadingStore: true
      }

    case types.GET_STORE_INTENT_RESOLVED:
      return {
        ...state,
        store: action.data,
        error: null,
        loadingStore: false
      }

    case types.GET_STORE_INTENT_REJECTED:
      return {
        ...state,
        store: null,
        error: action.error,
        loadingStore: false
      }

    case types.UPDATE_STORE_INTENT:
      return {
        ...state,
        error: null,
        savingStore: true
      }

    case types.UPDATE_STORE_INTENT_RESOLVED:
      return {
        ...state,
        error: null,
        savingStore: false,
        lastUsedChainUuid: null,
      }

    case types.UPDATE_STORE_INTENT_REJECTED:
      return {
        ...state,
        error: action.error,
        savingStore: false
      }

    case types.GET_CATEGORIES_INTENT:
      return {
        ...state,
        loadingCategories: true
      }

    case types.GET_CATEGORIES_INTENT_RESOLVED:
      return {
        ...state,
        categories: action.data,
        loadingCategories: false
      }

    case types.GET_CATEGORIES_INTENT_REJECTED:
      return {
        ...state,
        categories: [],
        loadingCategories: false
      }
    
    case types.GET_INTEGRATION_STATUS:
      return {
        ...state,
        integration: {},
        integrationLoading: true,
        integrationError: false,
      }
    
    case types.GET_INTEGRATION_STATUS_RESOLVED:
      return {
        ...state,
        integration: action.data,
        integrationLoading: false,
        integrationError: null,
      }
    
    case types.GET_INTEGRATION_STATUS_FAILED:
      return {
        ...state,
        integration: {},
        integrationLoading: false,
        integrationError: action.data
      }
    
    default:
      return state
  }
}
