import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import  { getTimeFormatted }from '../../../../services/dates.js'
import './styles.scss'

const ListItem = props => {
  const { customer = {} } = props
  const lastInteraction = customer.lastInteraction
  const fullName = customer.fullName
  const phone = get(customer, 'phones[0]', '')
  const email = get(customer, 'emails[0]', '')
  const lastInteractionDate = lastInteraction.date ? getTimeFormatted(lastInteraction.date, 'MMM DD, yyyy'): ''
  const lastInteractionTime = lastInteraction.date ? getTimeFormatted(lastInteraction.date, 'hh:mm A') : ''

  const handleItemClick = () => {
    if (props.onClick) {
      props.onClick(customer)
    }
  }

  return (
    <div className='eg-customer-list-item' onClick={handleItemClick}>
      <div className='user'>
        <div className='avatar' />
        <div className='info'>
          { Boolean(fullName) && <div data-testid='customer-name'>{fullName}</div> }
          { Boolean(phone) && <div data-testid='customer-phone' >{phone}</div> }
          { Boolean(email) && <div data-testid='customer-email' >{email}</div> }
        </div>
      </div>
      {/* <div className='store' >
        {get(customer, 'store')}
      </div> */}
      <label className='mobile'>TOTAL INTERACTIONS</label>
      <div className='total' data-testid='total-interaction'>
        <div>{customer.totalInteraction}</div>
      </div>
      <label className='mobile'>LAST INTERACTION</label>
      <div className='last-interaction' >
        <div className={customer.sourceClass && customer.sourceClass.toLowerCase()} />
        <div className='info'>
          <div className='source' data-testid='interaction-source'>
            {lastInteraction.label ? lastInteraction.label.replace('_', ' ') : ''} 
          </div>
          <div className='date' data-testid='interaction-date'>
            {`${lastInteractionDate} ${lastInteractionTime}`}
          </div>
        </div>
      </div>
      
    </div>
  )
}

ListItem.propTypes = {
  customer: PropTypes.object,
  onClick:  PropTypes.func.isRequired,
}

export default ListItem
