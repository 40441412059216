import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import ListItemOptions from '../../../ListItemOptions'
import { parse2DecimalFloat } from '../../../../utils/number'
import { campaignTypes } from '../../../../services/campaigns'
import { getTimeFormatted } from '../../../../services/dates'
import { userHasAccess } from '../../../../services/auth'
import './styles.scss'

/**
 * Campaign list row item view
 *
 * @component
 * @example
 *
 *
 * const type = 'delivered' or 'scheduled'
 *
 * const item = {
 *    'id': '608bffdaf58d4e0009121b65',
 *    'chain': {
 *      'uuid': '1f77a64c-7933-48fe-af05-34f29621d3d3'
 *    },
 *    'from_phone_number': '+14422758913',
 *    'name': 'My first sjfmss',
 *    'text': 'This is a test message\n\nhttp://example.com',
 *    'target': 'all_opted_in_customers',
 *    'send_date': moment().subtract(parseInt(Math.random()* 100), 'days')
 *  },
 *
 * return (
 *  <RowItem item={item} type={type}/>
 * )
*/
const RowItem = (props) => {
  const { user } = useSelector(state => state.User)
  const { item, onEditSchedule, onCancel, onItemClick } = props
  let clickable = item.status === campaignTypes.DRAFT || item.status === campaignTypes.DELIVERED
  const isDraft = item.status === campaignTypes.DRAFT
  const isDelivered = item.status === campaignTypes.DELIVERED

  if (!userHasAccess(user, []) && item.status !== campaignTypes.DELIVERED) {
    clickable = false
  }

  const options = [
    {
      label: 'Edit',
      onClick: () => onItemClick(item)
    },
    {
      label: 'Edit Schedule',
      onClick: () => onEditSchedule(item)
    },
    {
      label: 'Cancel Campaign',
      onClick: () => onCancel(item)
    },
  ]

  const draftOptions = [
    {
      label: 'Edit',
      onClick: () => onItemClick(item)
    }
  ]

  const renderEventTime = () => {
    if( item.send_date ) {
      return (
        <div className='date' >
          Sent {getTimeFormatted(item.send_date, 'ddd, MMM DD, YYYY h:mm A')}
        </div>
      )
    }

    if (item.schedule_date) {
      return (
        <div className='date' >
          Scheduled on {getTimeFormatted(item.schedule_date, 'ddd, MMM DD, YYYY h:mm A')}
        </div>
      )
    }

    if(isDraft) {
      return (
        <div className='date' >
          Created on {getTimeFormatted(item.created_at, 'ddd, MMM DD, YYYY h:mm A')}
        </div>
      )
    }
  }

  const handleItemClick = () => {
    if (clickable) {
      onItemClick(item)
    }
  }

  const totalDelivered = _.get(item, 'statistic.deliver_count', '-')
  const totalClicked = _.get(item, 'statistic.customer_click_count', '-')
  const percentage = _.isNumber(totalDelivered) && _.isNumber(totalClicked)
    ? parse2DecimalFloat((totalClicked/totalDelivered) * 100)
    : ''

  return (
    <div className={`eg-campaign-item ${clickable ? 'clickable' : ''}`}
      data-testid='eg-campaign-list-item' onClick={ handleItemClick } >
      <div className='name' >
        <div className='icon'/>
        <div className='details'>
          <div className='title'>{item.name}</div>
          { renderEventTime() }
        </div>
      </div>
      <div className='type'>
        <div className={ `status ${item.status}` } />{item.status}
      </div>
      {
        isDelivered ? (
          <>
          <div className='delivered' data-testid='item-delivered'>
            {totalDelivered}
          </div>
          <div className='clicked' data-testid='item-clicked'>
            <div>{totalClicked}</div>
            <div className='percentage'>{percentage && percentage.toFixed(2)}%</div>
          </div>
        </>
        ) : ''

      }
      {/* sunset - superadmin access only */}
      <div className='options'>
        {
          userHasAccess(user, []) && item.status === campaignTypes.SCHEDULED && (
            <ListItemOptions options={ options } />
          )
        }
        {
          userHasAccess(user, []) && item.status === campaignTypes.DRAFT && (
            <ListItemOptions options={ draftOptions } />
          )
        }
      </div>
    </div>
  )
}

RowItem.propTypes = {
  item: PropTypes.object,
  onEditSchedule: PropTypes.func,
  onCancel: PropTypes.func,
  onItemClick: PropTypes.func
}

export default RowItem
