import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

/**
 * Checkbox React component. Renders a custom checkbox input with the given label if provided.
 * 
 * Properties
 * - name {string}          - The input's name.
 * - label {string}         - The label to be displayed next to the checkbox
 * - checked {bool}         - Checked by default if true.
 * - onChange {func}        - Function triggered on the value change.
 */
export default class Checkbox extends React.Component {

  constructor(props) {
    super(props)

    // Initial State
    this.state = {
      checked: props.checked
    }

    // Bindings
    this.onChange = this.onChange.bind(this)
  }

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    error: PropTypes.bool,
    withBorder: PropTypes.bool,
    labelClassName: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    checked: false,
    withBorder: false,
    labelClassName: ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== this.state.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  /**
   * Sets the value to the state and calls the onChange prop if provided.
   * @param {string} value 
   */
  onChange(checked) {
    this.setState({ checked })
    if (this.props.onChange) this.props.onChange(checked)
  }

  render = () => {
    return (
      <div className={`${styles.container}`} >
        <div className={`${styles.optionContainer} ${this.props.withBorder ? styles.withBorder : ''}`} onClick={this.onChange.bind(null, !this.state.checked)}>
          <input
            name={this.props.name}
            type="checkbox"
            checked={this.state.checked}
            onChange={this.onChange}
          />
          <div className={`${styles.checkmark} ${this.props.error ? styles.error : ''}`} />
          {
            this.props.label
              ? <div className={`${styles.label} ${this.props.labelClassName}`}>{this.props.label}</div>
              : null
          }
        </div>
      </div>
    )
  }
}
