import React from 'react'
import PropTypes from 'prop-types'

import {
  getFormattedDate,
  getFormattedTime
} from '../../../../services/dates'

import styles from './index.module.scss'

export default class OrderCard extends React.Component {
  static propTypes = {
    order: PropTypes.object
  }

  render() {
    const order = this.props.order
    return (
      <div className={ styles.container }>
        <div className={ styles.title }>Order details</div>
        <div className={ styles.sectionTitle }>Delivery Address</div>
        <p>{ order.customer_address ? order.customer_address : 'N/A' }</p>
        <section>
          <div className={ styles.sectionTitle }>Delivery Schedule</div>
          <p>
            { getFormattedDate(order.order_date) }<br />
            { getFormattedTime(order.order_date) }
          </p>
        </section>
        <section>
          <div className={ styles.sectionTitle }>Head Count</div>
          <p>{ order.head_count ? order.head_count : 'N/A'  }</p>
        </section>
        <section>
          <div className={ styles.sectionTitle }>Company</div>
          <p>{ order.customer_company ? order.customer_company : 'N/A'}</p>
        </section>
      </div>
    )
  }
}
