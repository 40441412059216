import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CustomerHeader from '../../components/CustomerHeader'
import LocalSpinner from '../../components/LocalSpinner'
import ConfirmModal from '../../components/ConfirmModal'


import { StoreInfoForm } from '../../components/Store/StoreProfile/ProfileSections/StoreInfo'

import { PATHS } from '../../constants'
import styles from './index.module.scss'

const StoreCreate = props => {
  const [ showConfirmModal, toggleConfirmModal ] = useState(false)
  const { 
    getCategories,
    createStore,
    loadingStore,
    history,
  } = props

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const renderConfirmModal = () => {
    toggleConfirmModal(true)
  }

  const onStoreCreate = (data) => {
    createStore(data)
  }


  const closeConfirmModal = () => {
    toggleConfirmModal(false)
  }

  const goBackToStoreList = () => {
    history.push(PATHS.STORES)
  }

  return (
    <>
      <CustomerHeader />
      <div className={ styles.storeDetailsContainer }>
        <div className={ styles.mainWrapper }>
          {
            loadingStore
              ? <LocalSpinner className={ styles.spinner } />
              : (
                <StoreInfoForm
                  categories={ props.categories || []}
                  onCancel={ renderConfirmModal }
                  onSave={ onStoreCreate }
                />
              )
          }
        </div>
        {
          showConfirmModal && 
            <ConfirmModal 
              text='You are about to abandon creating store process. You will be taken back to store list page.'
              continueText='Continue Store Creation'
              cancelText='Back to Store List'
              onContinue={closeConfirmModal}
              onCancel={goBackToStoreList}
            />
        }
      </div>
    </>
  )
}

StoreCreate.propTypes = {
  categories: PropTypes.array,
  loadingStore: PropTypes.bool.isRequired,
  loadingCategories: PropTypes.bool.isRequired,
  getCategories: PropTypes.func.isRequired,
  createStore: PropTypes.func.isRequired,
  history: PropTypes.object,
}

export default StoreCreate
