import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import StoreNumbers from './StoreNumbers'
import { actions as numberActions } from '../../../actions/VirtualNumber'

const mapStateToProps = state => ({
  virtualNumber: state.VirtualNumber.number
})

const mapDispatchToProps = dispatch => ({
  getNumber(id, restaurantUuid) {
    dispatch(numberActions.getNumber(id, restaurantUuid))
  },

  clearNumber() {
    dispatch(numberActions.clearNumber())
  },

  redirectToDefault(restaurantUuid) {
    dispatch(numberActions.redirectToDefault(restaurantUuid))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreNumbers))
