import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button } from '../../../FormComponents'
import { actions } from '../../../../actions/Segment'
import { types as buttonTypes } from '../../../FormComponents/Button'
import FileUploader from '../../../FileUploader'
import FileProgressBar from '../../../FileProgressBar'
import './styles.scss'


export default function UploadCSVSegment(props) {
  const { onCloseModal, onSegmentCreation } = props
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [segmentFile, setSegmentFile] = useState(null)
  const [progressValue, setProgressValue] = useState(0)
  const { loading, data } = useSelector(state => state.Segment.segment)

  useEffect(() => {
    if (data) {
      // Show notification for successful creation
      onSegmentCreation()
      onCloseModal()
    }
    return () => {
      dispatch(actions.createSegmentClear())
    }
  }, [data, dispatch, onCloseModal, onSegmentCreation])

  const onFileUpload = (files) => {
    setSegmentFile(files[0])
  }

  const onUploadProgress = (progressData) => {
    let percentage = Math.floor((progressData.loaded * 100) / progressData.total)
    setProgressValue(percentage)
  }

  const isUploadFormValid = () => {
    return name && segmentFile
  }

  const handleSubmit = () => {
    dispatch(actions.createSegment(
      {
        name,
        description,
        segmentFile,
        onUploadProgress
      }
    ))
  }

  const renderFileUploader = () => {
    if (progressValue) {
      return (
        <div className='file-upload'>
          <FileProgressBar
            className='progress-bar'
            percentCompleted={progressValue}
            fileName={segmentFile.name}
          />
        </div>
      )
    }
    return (
      <FileUploader onFileUpload={onFileUpload} fileType='text/csv,.csv' multiple={false}>
        <div className='file-upload'>
          {!segmentFile && <label>Drag & drop file here</label>}
          <div className='info'>
            {segmentFile
              ? `Selected file ${segmentFile.name}`
              : 'Only acceptable file types .csv'
            }
          </div>
          <Button
            className='upload'
            text={segmentFile ? 'CHANGE FILE' : 'UPLOAD'}
          />
        </div>
      </FileUploader>
    )
  }

  return (
    <div className='eg-uploadcsvsegment'>
      <div className='title'>
        Create a manual segment
      </div>
      <div className='form'>
        <div className='label'>NAME </div>
        <Input value={name} onChange={(event) => setName(event.target.value)} />
        <div className='label'> DESCRIPTION (OPTIONAL) </div>
        <Input value={description} onChange={(event) => setDescription(event.target.value)} />
        <div className='label'> .CSV FILE </div>
        {renderFileUploader()}
        <div className='action-wrapper'>
          <Button
            className='action-button'
            text='CANCEL'
            type={buttonTypes.SECONDARY}
            onClick={onCloseModal}
          />
          <Button
            className='action-button submit'
            text='CREATE SEGMENT'
            disabled={!isUploadFormValid()}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

UploadCSVSegment.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onSegmentCreation: PropTypes.func.isRequired,
}
