import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import StoreDetail from './StoreDetail'
import { actions as storeActions } from '../../actions/Store'

const mapStateToProps = state => ({
  store: state.Store.store,
  categories: state.Store.categories,
  loadingStore: state.Store.loadingStore,
  loadingCategories: state.Store.loadingCategories,
  savingStore: state.Store.savingStore,
})

const mapDispatchToProps = dispatch => ({
  getStore(opts) {
    dispatch(storeActions.getStore(opts))
  },

  getCategories() {
    dispatch(storeActions.getCategories())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreDetail))
