import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import StatelessModal from '../../StatelessModal'
import Option from './Option'
import UploadCSVSegment from './UploadCSVSegment'
import { PATHS } from '../../../constants'
import './styles.scss'

const segmentOptions = {
  DYNAMIC_SEGMENT: 'dynamically_segment',
  MANUALLY_UPLOAD: 'manually_upload',
}

const SegmentCreateOptions = ({ onClose, onSegmentCreation, history }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  // const isAdmin = useSelector(({ User }) => User.user.isAdmin)

  // let options = [
  //   {
  //     title: 'Manually uploaded segment',
  //     logoType: 'upload',
  //     info: 'A static list of subscribers created by uploading a .CSV file.',
  //     onCreate: () => {
  //       setSelectedOption(segmentOptions.MANUALLY_UPLOAD)
  //     }
  //   },
  // ]

  const options = [
    {
      title: 'Dynamic  Segment',
      logoType: 'dynamic',
      info: 'A dynamic segment of subscribers created within ZeroStoreFront that updates in real time.',
      onCreate: () => {
        history.push(PATHS.DYNAMIC_SEGMENTS_CREATE)
      }
    },
    {
      title: 'Manually uploaded segment',
      logoType: 'upload',
      info: 'A static list of subscribers created by uploading a .CSV file.',
      onCreate: () => {
        setSelectedOption(segmentOptions.MANUALLY_UPLOAD)
      }
    },
  ]

  const renderSegmentOptions = () => {
    return (
      <div className='eg-segment-create-option'>
        <div className='title'> Choose segment type </div>
        {
          options.map((option, i) => {
            return <Option key={ `segment-option-${i}` } data={option} />
          })
        }
      </div>
    )
  }

  return (
    <div className='eg-segment-content'>
      <StatelessModal onClose={onClose} className='eg-segment-modal'>
        { selectedOption
          ? <UploadCSVSegment
            onCloseModal={onClose}
            onSegmentCreation={onSegmentCreation}
          />
          : renderSegmentOptions()
        }
      </StatelessModal>
    </div>
  )
}

SegmentCreateOptions.propTypes = {
  history: PropTypes.any,
  onClose: PropTypes.func,
  onSegmentCreation: PropTypes.func,
}

export default withRouter(SegmentCreateOptions)
