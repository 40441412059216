import { connect } from 'react-redux'

import MainLayout from './MainLayout'

const mapStateToProps = state => ({
  isAuthenticated: state.User.isAuthenticated,
  showSidebar: state.Sidebar.showSidebar,
  user: state.User.user
})

export default connect(mapStateToProps)(MainLayout)
