import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Controller } from 'react-hook-form'

import {
  Input,
  Select,
  CustomTextarea
} from '../../../../../FormComponents'
import { RemoveButton } from '../../../../../ActionButtons'
import useValidator from '../../../../../../hooks/validator'
import { getMessageShortlink, zsfSmsFooter } from '../../../../../../services/journeys'
import SMSAddon from '../../../../../FormComponents/CustomTextarea/Addons/SMS'
import { actions as smsActions } from '../../../../../../actions/SMS'
import { userHasAccess } from '../../../../../../services/auth'
import './styles.scss'

const fields = {
  TIME: 'time',
  UNIT: 'unit',
  TEXT: 'text',
  INCLUDE_REPLY_FOOTER: 'include_reply_footer'
}

const units = [
  { value: 'minute', label: 'Minutes' },
  { value: 'hour', label: 'Hours' }
]

/**
 * Journey creation: Node representation of a trigger unit
 *
 * @component
 * @example
 *
 * const message = {
 *   formIsValid: true
 *   id: "i1QoljKuR"
 *   text: "some input text"
 *   time: "1"
 *   unit: {value: "hours", label: "Hours"}
 * }
 *
 * const handleChange = (msg) => {
 *   // handle changed msg
 * }
 *
 * const handleRemove = (msg) => {
 *   // handle removed msg
 * }
 *
 * const removable = true // or some condition
 *
 * return (
 *   <SMSNode
 *     removable={ removable }
 *     message={ trigger }
 *     onRemove={ handleRemove }
 *     onChange={ handleChange }
 *   />
 * )
*/
const SMSNode = props => {
  const {
    message = {},
    journey,
    removable = false,
    autoTrigger = false,
    index,
    onChange,
    onRemove,
    onShortlinkClick
  } = props
  const {
    register,
    errors,
    control,
    watch,
    formState,
    setValue,
    getValues,
    trigger
  } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.TIME]: message.time,
      [fields.UNIT]: message.unit || units[0],
      [fields.TEXT]: message.text,
      [fields.INCLUDE_REPLY_FOOTER]: !!message[fields.INCLUDE_REPLY_FOOTER]
    }
  })
  const { chain, user } = useSelector(state => state.User)
  const { mmsImage } = useSelector(state => state.SMS)
  const [previewSmsImage, setPreviewSmsImage] = useState(_.get(message, 'mms.imageUrl'))
  const [uploadingSmsImg, setUploadingSmsImg] = useState(false)
  const [smsImage, setSmsImage] = useState(_.get(message, 'mms'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (autoTrigger) {
      trigger()
    }
  }, [trigger, autoTrigger])

  // here we handle the text change, particulary when the parent updates a message text
  // e.g. adding a shortlink.
  useEffect(() => {
    const shouldValidate = !!formState.touched.text
    setValue(fields.TEXT, message.text, { shouldValidate })
  }, [ message.text, setValue, formState.touched.text ])

  const values = watch()

  useEffect(() => {
    const values = getValues()
    onChange({
      ...values,
      id: message.id,
      mms: _.get(message, 'mms', null),
      formIsValid: formState.isValid
    })
  // TODO: find a way to include onChange dependency without running into
  // infinite loop issues
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isValid, getValues, message.id])

  // MMS image upload effect
  useEffect(() => {
    if (mmsImage.success && mmsImage.id === message.id) {
      dispatch(smsActions.clearMMSImageState())
      setUploadingSmsImg(false)
      setSmsImage(_.get(mmsImage, 'data') || null)

      const values = getValues()
      onChange({
        ...values,
        id: message.id,
        mms: {
          image: _.get(mmsImage, 'data.image', null),
          imageUrl: _.get(mmsImage, 'data.imageUrl', null),
          uploading: false
        },
        formIsValid: formState.isValid
      })
    }
  }, [ mmsImage, dispatch, formState.isValid, getValues, message.id, onChange ])

  const updateParent = () => {
    const values = getValues()
    if (onChange) onChange({
      ...values,
      id: message.id,
      mms: smsImage,
      formIsValid: formState.isValid
    })
  }

  const handleUnitChange = value => {
    setValue(fields.UNIT, value)
    updateParent()
  }

  const handleRemoveClick = () => {
    if (onRemove) onRemove(message)
  }

  const handleShortlinkClick = () => {
    onShortlinkClick(message)
  }

  const handleEmojiPick = emojiObject => {
    setValue(fields.TEXT, `${message.text} ${emojiObject.emoji}`)
    updateParent()
  }

  const handleSmsFileSelection = file => {
    // give an aux url of the image, so we can display
    // the preview with the loading animation
    setPreviewSmsImage(URL.createObjectURL(file))
    setUploadingSmsImg(true)
    dispatch(smsActions.uploadMMSImage({
      file,
      resource: 'journey',
      resourceId: journey.id,
      id: message.id
    }))

    // don't allow the form to be submitted until the image is fully uploaded
    onChange({
      ...message,
      uploading: true,
      formIsValid: false
    })
  }

  const handlePreviewDelete = () => {
    setPreviewSmsImage(null)
    setSmsImage(null)
    onChange({
      ...message,
      mms: {}
    })
  }

  const handleCustomFieldChange = (field) => {
    setValue(fields.TEXT, `${message.text.trim()} {${field}}`, { shouldValidate: true })
    updateParent()
  }

  const finalMessageText = !!message[fields.INCLUDE_REPLY_FOOTER]
    ? `${chain.name}: ${message.text}\n${zsfSmsFooter()}`
    : `${chain.name}: ${message.text}`

  if (errors[fields.TIME] && errors[fields.TIME].type === 'min') {
    const minValue = index === 0 ? 0 : 1
    errors[fields.TIME].message = `Minimum allowed value is ${minValue}`
  }

  const customfieldsEnabled = userHasAccess(user, [])
  const mmsEnabled = userHasAccess(user, [])

  return (
    <div className='eg-msg-sms-node'>
      <div className='content'>
        <div className='side-icon'>
          <div className='vline' />
        </div>
        <div className='text'>
          <label htmlFor={ `${fields.TIME}-${message.id}` } className='name'>Wait</label>
          <div className='top-fields'>
            <div>
              <Input
                id={ `${fields.TIME}-${message.id}` }
                name={ fields.TIME }
                type='number'
                className={ `time ${errors[fields.TIME] ? 'error' : ''}` }
                onChange={ updateParent }
                ref={ register({
                  required: true,
                  min: index === 0 ? 0 : 1
                }) }
              />
              { errors[fields.TIME] && <div className='error-msg'>{ errors[fields.TIME].message }</div> }
            </div>
            <Controller
              control={ control }
              name={ fields.UNIT }
              render={ () => (
                <Select
                  className='unit'
                  options={ units }
                  selectedItem={ values[fields.UNIT] }
                  onChange={ handleUnitChange }
                />
              )}
            />
            { removable && <RemoveButton className='remove-btn' onClick={ handleRemoveClick } /> }
          </div>
          <label htmlFor={ `${fields.TEXT}-${message.id}` } className='name'>Message</label>
          <CustomTextarea
            id={ `${fields.TEXT}-${message.id}` }
            name={ fields.TEXT }
            className={ `${errors[fields.TEXT] ? 'error' : ''}` }
            shortLink={ _.get(getMessageShortlink(journey, message), 'long_url') }
            hasShortLink
            hasEmojis
            hasImage
            ref={ register({ required: true }) }
            addons={[
              {
                component: () => (
                  <SMSAddon.Component
                    text={ finalMessageText }
                    imagePreview={ previewSmsImage }
                    uploading={ uploadingSmsImg }
                    onDelete={ handlePreviewDelete }
                  />
                ),
                triggers: (key) => (
                  <SMSAddon.Triggers
                    mmsImage={ mmsEnabled }
                    customFields={ customfieldsEnabled }
                    key={ key }
                    onFileSelection={ handleSmsFileSelection }
                    onCustomFieldChange={ handleCustomFieldChange }
                  />
                )
              }
            ]}
            onShortLinkClick={ handleShortlinkClick }
            onEmojiPick={ handleEmojiPick }
            onChange={ updateParent }
          />
          { errors[fields.TEXT] && <div className='error-msg'>{ errors[fields.TEXT].message }</div> }
          <div className='unsubscribe-msg-check'>
            <label htmlFor={ `${fields.INCLUDE_REPLY_FOOTER}-${message.id}` }>
              <input
                id={ `${fields.INCLUDE_REPLY_FOOTER}-${message.id}` }
                name={ fields.INCLUDE_REPLY_FOOTER }
                type='checkbox'
                ref={ register }
                onChange={ updateParent }
              />
              <div htmlFor={ `${fields.INCLUDE_REPLY_FOOTER}-${message.id}` } className='checkmark'></div>
              <span>Add "Reply STOP to opt-out"</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

SMSNode.propTypes = {
  trigger: PropTypes.object,
  message: PropTypes.object,
  journey: PropTypes.object,
  removable: PropTypes.bool,
  autoTrigger: PropTypes.bool,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onShortlinkClick: PropTypes.func
}

export default SMSNode
