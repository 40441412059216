import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import querystring from 'query-string'

import { actions } from '../../../actions/Segment'
import { getSteps } from '../../../services/segments'
import LocalSpinner from '../../../components/LocalSpinner'
import Segment from '../../../components/Segments/DynamicSegmentCreate'

const SegmentUpdateContainer = props => {
  const { segmentId } = props.match.params
  const dispatch = useDispatch()
  const gettingDynamicSegment = useSelector(state => _.get(state, 'Segment.gettingDynamicSegment'))
  const segment = useSelector(state => _.get(state, 'Segment.dynamicSegment.data'))
  const qs = querystring.parse(props.location.search)
  const stepNumber = _.get(qs, 'step', '1')
  const steps = getSteps(segment)
  const step = steps[stepNumber - 1]

  useEffect(() => {
    return () => {
      // clear dynamicSegment state before the component unmounts
      dispatch(actions.clearDynamicSegment())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.getDynamicSegment(segmentId))
  }, [dispatch, segmentId])

  if (gettingDynamicSegment) {
    return <LocalSpinner />
  }

  if (stepNumber && stepNumber > 1) {
    for (let i = 0; i < stepNumber; i++) {
      steps[i].completed = true
    }
  }

  return <Segment history={ props.history } step={ step } />
}

SegmentUpdateContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
}

export default SegmentUpdateContainer
