import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import querystring from 'query-string'
import { useDispatch, useSelector } from 'react-redux'

import LocalSpinner from '../../components/LocalSpinner'
import { actions as oauthActions } from '../../actions/Oauth'
import { actions as sidebarActions } from '../../actions/Sidebar'
import { oauthProviders } from '../../services/auth'
// import { PATHS } from '../../constants'
import EGAnalytic, { analyticEvents } from '../../services/analytics'
import  { setStatusOfSuccessfulIntegration } from '../../services/oauth'
import './styles.scss'


export const status = {
  SUCCESS: 'success',
  FAILED: 'fail'
}

const OauthCallback = (props) => {
  const qs = querystring.parse(props.location.search)
  const dispatch = useDispatch()
  const { connecting = true, connected, error } = useSelector(state => state.OauthConnect)

  const {
    code,
    state
  } = qs

  useEffect(() => {
    dispatch(sidebarActions.hideSidebar())
    dispatch(oauthActions.customerConnect({
      code,
      chainUuid: state,
      provider: oauthProviders.SQUARE,
    }))
  }, [dispatch, code, state])

  useEffect(() => {
    if (!connecting && connected) {
      EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_COMPLETED,  {
        ...EGAnalytic.mapIntegrationTabDetail(state)
      })
      setTimeout(() => {
        setStatusOfSuccessfulIntegration()
        window.close()
      }, 2000)
    }
  }, [connecting, connected, state])

  if (error || !code) {
    return (
      <div className='eg-oauth-callback'>
        <div className='square'/>
        <div className='title'> Oops! Something went wrong.:(</div>
        <div className='description'>Please try again or reach out to us.</div>
      </div>
    )
  }

  if(connecting || !connected) {
    return  <LocalSpinner />
  }

  return (
    <div className='eg-oauth-callback'>
      <div className='square'/>
      <div className='title'> Your Square account is connected.</div>
      <div className='description'>Redirecting to integration page...</div>
    </div>
  )
}

OauthCallback.propTypes = {
  location: PropTypes.object
}

export default withRouter(OauthCallback)
