import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'
import _ from 'lodash'

import { zsfSmsFooter } from '../services/journeys'

/**
 * get journey list
 *
 * @param opts {Object} - options
 * @param opts.chainUuid {string} - chain UUID related with the journeys
 * @param opts.page {number} - page number
 * @param opts.size {number} - journeys per page
 * @param tokens {Object} - user auth tokens
 *
 * @returns {Promise} - request promise
*/
export const getJourneyList = (opts, tokens) => {
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/journey`
  const { chainUuid, page, size, status, trigger } = opts
  const queryParameters = [
    `chain_uuid=${chainUuid}`,
  ]
  if(page) {
    queryParameters.push(`page=${page}`)
  }
  if(size) {
    queryParameters.push(`size=${size}`)
  }
  if(status) {
    queryParameters.push(`status[0]=${status}`)
  }
  if(trigger) {
    queryParameters.push(`trigger[0]=${trigger}`)
  }
  return axios({
    url: `${baseUrl}?${queryParameters.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

export const getJourney = (opts, tokens) => {
  const { chainUuid, id } = opts
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/journey/${id}`
  return axios({
    url: `${baseUrl}?chain_uuid=${chainUuid}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * create / update a journey depending on the id existence
 *
 * @param opts {Object} - options
 * @param opts.step {string} - step to be saved
 * @params opts.data {Object} - journey data
 * @params.opts.data.journey_name {string} - journey name
 * @params.opts.data.journey_trigger {Object} - journey trigger object
 * @params.opts.data.journey_trigger.value {string} - journey trigger value
 * @params.opts.data.journey_trigger_id {string} - journey id value
 * @param tokens {Object} - user auth tokens
 *
 * @returns {Promise} - request promise
*/
export const upsertJourney = ({ opts, chainUuid, journeyId }, tokens) => {
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}`
  const payload = {
    name: _.get(opts, 'data.journey_name'),
    trigger: _.get(opts, 'data.journey_trigger.value'),
    trigger_id: _.get(opts, 'data.journey_trigger_id')
  }
  if (opts.step === 'setup' && !journeyId) {
    return axios({
      url: `${baseUrl}/chain/${chainUuid}/journey?chain_uuid=${chainUuid}`,
      method: METHODS.POST,
      headers: HeaderFactory(tokens),
      data: payload
    })
  } else if (journeyId) {
    if (!_.isEmpty(opts.data.journey_messages) && opts.step === 'design') {
      payload.messages = opts.data.journey_messages.map(msg => {
        let text = msg.text
        if (msg.include_reply_footer) {
          text = `${msg.text}
          ${zsfSmsFooter()}`
        }

        return {
          delay: msg.time,
          unit: msg.unit.value,
          mms: {
            image: _.get(msg, 'mms.image', null)
          },
          content: text
        }
      })
    }
    return axios({
      url: `${baseUrl}/journey/${journeyId}?chain_uuid=${chainUuid}`,
      method: METHODS.PATCH,
      headers: HeaderFactory(tokens),
      data: payload
    })
  }
}

/**
 * Update journey status
 *
 * @param param - contains data needed to handle the request
 * @param param.opts.data {Object} - contains data to be sent
 * @param param.opts.data.state {string} - state we want to change to
 * @param param.chainUuid {string} - chain UUID associated with the journey
 * @param tokens {Object} auth tokens
 *
 * @returns {Promise} - request promise
*/
export const setJourneyState = ({ opts, chainUuid }, tokens) => {
  const journeyId = _.get(opts, 'data.journeyId')
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}`
  const payload = {
    status: _.get(opts, 'data.state')
  }

  return axios({
    url: `${baseUrl}/journey/${journeyId}/status?chain_uuid=${chainUuid}`,
    method: METHODS.PATCH,
    headers: HeaderFactory(tokens),
    data: payload
  })
}

/**
 * creates a new shortlink for a given journey
 *
 * @param opts {Object} - options
 * @param opts.data {Object} - data to be processed
 * @param opts.data.journeyId {string} - journeyId in which we want ton create the shortlink
 * @param opts.data.url {string} - url to be shortened
 * @param opts.chainUuid {string} - chain UUID associated with the journey
 * @param tokens {Object} auth tokens
 *
 * @returns {Promise} - request promise
*/
export const createShortLink = ({ opts, chainUuid }, tokens) => {
  const { journeyId, url } = opts.data
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/journey/${journeyId}/shortlink?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: { url }
  })
}

/**
 * updates a shortlink for a given journey
 *
 * @param opts {Object} - options
 * @param opts.data {Object} - data to be processed
 * @param opts.data.journeyId {string} - journeyId in which we want ton create the shortlink
 * @param opts.data.shortlink {Object} - shortlink to be edited
 * @param opts.data.shortlink.slug {Object} - slug which identifies the shortlink
 * @param opts.data.url {string} - url to be shortened
 * @param opts.chainUuid {string} - chain UUID associated with the journey
 * @param tokens {Object} auth tokens
 *
 * @returns {Promise} - request promise
*/
export const updateShortLink = ({ opts, chainUuid }, tokens) => {
  const { journeyId, shortlink, url } = opts.data
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/journey/${journeyId}/shortlink/${shortlink.slug}?chain_uuid=${chainUuid}`,
    method: METHODS.PATCH,
    headers: HeaderFactory(tokens),
    data: { url }
  })
}
