import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PercentBar from '../../PercentBar'
import RatingSource from '../RatingSource'
import styles from './index.module.scss'

const ratePercentage = (rateCount, total) => {
  return parseFloat(((parseFloat(rateCount) / parseFloat(total)) * 100).toFixed(2))
}

const RatingSummary = props => {
  const { ratings = {} } = props

  const displaySourceRate = (source, logoClassName) => {
    const srcObject = props.ratings.sources[source]
    if (!srcObject) return null

    const sourcePercentage = ratePercentage(srcObject.rate_count, props.ratings.total)
    return (
      <div className={ styles.sourcesRow }>
        <RatingSource source={ source } displayLabel={ false } className={styles.sourceName}/>
        <div className={styles.leftPart}>
          <div className={ styles.sourceStarWrapper }>
            { props.ratings.sources[source].rate.toFixed(1) }
            <span className={ `zmdi zmdi-star zmdi-hc-fw ${styles.yellowStar}` } />
          </div>
          <div className={ styles.percentBarWrapper }>
            <PercentBar
              value={ sourcePercentage }
              quantity={ srcObject.rate_count }
              className={ styles.percentBar }
            />
          </div>
        </div>
      </div>
    )
  }

  if (!ratings || !ratings.sources ||  !ratings.total) {
    return (
      <div className={ styles.container }>
        <div className={styles.title}>SOURCE BREAKDOWN</div>
        <div className={styles.noResult}> No Result Found. </div>
      </div>
    )
  }

  return (
    <div className={ styles.container }>
      <div className={styles.title}>SOURCE BREAKDOWN</div>
      <div className={ styles.sourcesWrapper }>
        { _.get(props.ratings, 'sources.google_my_business') ? displaySourceRate('google_my_business', styles.logoGoogle) : null }
        { _.get(props.ratings, 'sources.google') ? displaySourceRate('google', styles.logoGoogle) : null }
        { _.get(props.ratings, 'sources.yelp') ? displaySourceRate('yelp', styles.logoYelp) : null }
        { _.get(props.ratings, 'sources.facebook') ? displaySourceRate('facebook', styles.logoGoogle) : null }
        { _.get(props.ratings, 'sources.tripadvisor') ? displaySourceRate('tripadvisor', styles.logoYelp) : null }
        { _.get(props.ratings, 'sources.zerostorefront') ? displaySourceRate('zerostorefront', styles.logoZsf) : null }
      </div>
    </div>
  )
}

RatingSummary.propTypes = {
  ratings: PropTypes.shape({
    average: PropTypes.number,
    total: PropTypes.number,
    sources: PropTypes.shape({
      google: PropTypes.shape({
        rate: PropTypes.number,
        rate_count: PropTypes.number
      }),
      yelp: PropTypes.shape({
        rate: PropTypes.number,
        rate_count: PropTypes.number
      }),
    })
  })
}

export default RatingSummary
