import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import RatingStars from '../../../RatingStars'
import RatingSource from '../../RatingSource'
import { stripXmlTags } from '../../../../utils'
import EGAnalytic, { analyticEvents, eventPropertyValues } from '../../../../services/analytics'

import StatusWidget, { statuses } from '../../../StatusWidget'
import styles from './index.module.scss'

const renderRate = rate => {
  return (
    <RatingStars rate={ rate } className={ styles.starsWrapper } />
  )
}

const ReviewItem = props => {
  const { review = {} } = props
  const itemRef = useRef(null)
  const { storeListLookup = {} } = useSelector(({ Store }) => Store)
  const store = storeListLookup[review.storeUuid] || {}
  const storeName = store.name ? store.name : '-'

  const handleClick = (e) => {
    // dismiss in case we click on a link inside the item
    if (e.target && e.target.tagName !== 'A' && props.onClick) {
      props.onClick(review)
    }
  }

  const onRespondClick = () => {
    if(!review.url) {
      props.onClick(review)
    }
  }

  const trackExternalLinkClick = (buttonName) => {
    EGAnalytic.track(analyticEvents.REVIEW_EXTERNAL_URL_INTERACTION, { 
      ...EGAnalytic.mapReviewDetial(store, review),
      page: review.url, // TODO: what does page means here, the review link address or soemthing else.
      externalButtonSource: buttonName
    })
  }

  return (
    <div className={ styles.reviewItem } onClick={ handleClick } ref={ itemRef }>
      <div className={ styles.mSection }>
        <div className={ styles.reviewSource }>
          <RatingSource source={ review.source } />
        </div>
        <div className={ styles.reviewDate }>
          { moment(review.date).format('MM/DD/YYYY') }
        </div>
      </div>
      <div className={styles.reviewStore}>
        { storeName }
      </div>
      <div className={ styles.reviewUser }>
        <strong>{ review.name }</strong>
      </div>
      <div className={ styles.reviewRate }>
        { renderRate(review.rating) }
        <div className={ styles.reviewRateComment }>
          { stripXmlTags(review.comment) }
        </div>
      </div>
      <div className={ styles.mRightSection }>
        <div className={ styles.reviewStatus }>
          <StatusWidget
            label={ review.response ? 'Responded' : 'Pending' }
            status={ review.response ? statuses.COMPLETED : statuses.PENDING }
          />
        </div>
        <div className={ styles.reviewActions } onClick={onRespondClick}>
          <a href={ review.url }
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => trackExternalLinkClick(eventPropertyValues.REVIEW_EXTERNAL_RESPOND)}
            className={review.response ? styles.responded : styles.notResponded}
          >
            RESPOND
          </a>
          {
            !!review.url && (
              <a
                className={styles.viewOn}
                href={ review.url }
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => trackExternalLinkClick(eventPropertyValues.REVIEW_EXTERNAL_VIEW)}
              >
                View on <span>{ review.source }</span>
              </a>
            )
          }
        </div>
      </div>
    </div>
  )
}

ReviewItem.propTypes = {
  review: PropTypes.object,
  onClick: PropTypes.func
}

export default ReviewItem
