import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import { NetworkError } from '../services/error'
import { getSession } from '../services/cognito'
import { actions as userActions } from '../actions/User'

const httpStatuses = {
  UNAUTHORIZED: [ 401 ],
  FORBIDDEN: [ 403 ],
  OPERATION_FAILED: [ 400, 404, 500, 502 ],
  SUCCESS: [ 200, 201, 202 ]
}

const nonRefreshUnauthorizedCodes = [
  'InvalidParameterException',
  'LimitExceededException',
  'NotAuthorizedException'
]

export const authorizedRequest = function* (fn, data, retryOnFail = true) {
  let session
  try {
    session = yield getSession()
  } catch (error) {
    // if we are not able to get the session, we must kick off the user.
    // this is useful, for already logged in users after the deploy of
    // this functionality, they won't be stucked with an unknown error.
    yield put(userActions.Logout())
    return
  }
  try {
    const response = yield call(fn, data, session)
    if (httpStatuses.SUCCESS.includes(response.status)) {
      return response
    } else {
      throw new NetworkError(response)
    }
  } catch (error) {
    if (error.response) {
      if (httpStatuses.UNAUTHORIZED.includes(_.get(error, 'response.status'))) {
        if (nonRefreshUnauthorizedCodes.includes(_.get(error, 'response.data.code'))) {
          // in this case we receive a 401, but it doesn't mean we need a refresh call
          // an example use case would be an error when updating the password
          throw new NetworkError({
            message: _.get(error, 'response.data.message'),
            ...error.response
          })
        }
        yield put(userActions.Logout())
        return error.response
      }
      throw new NetworkError({
        message: _.get(error, 'response.data.message'),
        ...error.response
      })
    } else if (error.request) {
      throw new NetworkError({
        message: _.get(error, 'request.data.message'),
        ...error.request
      })
    } else {
      // This is the case where the request fails with undefined status code,
      // Since sometimes the request may fail because of a timeout, we'll retry
      // one more time
      if (retryOnFail) {
        yield authorizedRequest(fn, data, false)
      } else {
        throw new NetworkError(error)
      }
    }
  }
}
