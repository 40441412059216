import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
// import moment from 'moment'

import CustomerHeader from '../../components/CustomerHeader'
import TabSelector from '../../components/TabSelector'
import { actions as customerActions } from '../../actions/Customer'
import CustomerInteractions from '../../components/Customers/CustomerInteractions'
import CustomerSnippet from '../../components/CustomerSnippet'
import { getLocationArea } from '../../services/customers'
import CustomerCard from '../../components/Customers/CustomerCard'
import { PATHS } from '../../constants'

import './styles.scss'

const TAB_EVENTS = 'events'
const TAB_CONTACT_INFO = 'contact_info'
const MOBILE_MENUES_THRESHOLD = 1280

const tabOptions = [
  { label: 'CONTACT INFO', key: TAB_CONTACT_INFO, visible: false },
  { label: 'EVENTS', key: TAB_EVENTS, visible: true }
]

const getTab = key => tabOptions.find(t => t.key === key)
const defaultTab = getTab(TAB_EVENTS)
const defaultVisibleTabs = tabOptions.filter(t => t.visible)

const CustomerDetails = props => {
  const [ selectedTab, setSelectedTab ] = useState(defaultTab)
  const [ visibleTabs, setVisibleTabs ] = useState(defaultVisibleTabs)
  const customerInteractions = useSelector(state => state.Customer.customerInteractions)
  const loadingInteractions = useSelector(state => state.Customer.loadingInteractions)
  const firstPageLoading = useSelector(state => state.Spinner.loading)
  const dispatch = useDispatch()
  const customerAddress = _.get(customerInteractions, 'customer.addresses[0]')
  const customerId = _.get(props, 'match.params.id')

  const tabIsSelected = tabKey => selectedTab.key === tabKey

  const resizeHandler = () => {
    // display or hide the "Personal info" tab depending on the screen width
    if (window.innerWidth < MOBILE_MENUES_THRESHOLD) {
      setVisibleTabs(tabOptions)
    } else {
      setVisibleTabs(defaultVisibleTabs)

      // if info is the selected tab and the screen width changes, hiding it
      // we should switch to the interactions tab, to be consistent
      setSelectedTab(getTab(TAB_EVENTS))
    }
  }

  useEffect(() => {
    dispatch(customerActions.getCustomerInteractions({ customerId }))
    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [dispatch, customerId])

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
  }

  const handleInteractionsPageChange = (page, size) => {
    dispatch(customerActions.getCustomerInteractions({
      customerId,
      page,
      size
    }))
  }

  const goBackToCustomerList = () => {
    // Going back to the customer page, from where we came on the customer activity page.
    if(_.get(props.location, 'state.referrerCustomerPageQuery')) { 
      props.history.push(`${PATHS.CUSTOMERS}${props.location.state.referrerCustomerPageQuery}`)
    } else {
      props.history.push(PATHS.CUSTOMERS)
    }
  }

  if(firstPageLoading) {
    // Will show main loader, and will hide unnecessary tabs and 
    // text before the starting customer data gets rendered.
    return null
  }

  
  // const recentActivityDate = moment(_.get(customerInteractions, 'interactions[0].activity_date'))
  // fallback to created_at if the registration date is not available.
  // const customerRegistrationDate = moment(_.get(customerInteractions, 'customer.registrationDate') || moment(_.get(customerInteractions, 'customer.createdAt')))
  // const lifeTime = recentActivityDate.diff(customerRegistrationDate, 'days')

  return (
    <div className='eg-customer-details'>
      <CustomerHeader />
      <div className='wrapper'>
        <div className='left-panel'>
          <div onClick={goBackToCustomerList} className='link'>
            <div className='arrow' />
            <div className='label'>CUSTOMERS</div>
          </div>
          <div className='body'>
            <CustomerSnippet
              customer={ customerInteractions.customer }
              subtitle={ getLocationArea(customerAddress) }
            />
            <TabSelector
              items={ visibleTabs }
              className='tabs'
              tabItemClass='tab-item'
              selectedTabItemClass='tab-selected'
              selectedItem={ selectedTab }
              onChange={ handleTabChange }
            />
            {
              tabIsSelected(TAB_EVENTS) && (
                <div className='interactions'>
                  {
                    !_.isEmpty(customerInteractions.interactions) ? (
                          <>
                            <CustomerInteractions
                              customerInteractions={ customerInteractions }
                              onPageChange={ handleInteractionsPageChange }
                              loading={loadingInteractions}
                            />
                            <div className='details'>
                              <div className='section'>
                                <div className='title'>Total interactions</div>
                                {/* Adding +1 to total, because we are adding one dummy event called `Account Created` on the front-end side.
                                TODO: Remove +1 if we start getting `Account Created` event from the backend. */}
                                <div className='value'>{ customerInteractions.total + 1  }</div>
                              </div>
                              {/*
                              <div className='section'>
                                <div className='title'>LIFETIME</div>
                                <div className='value'>{ `${lifeTime ? lifeTime : '1'} days` }</div>
                              </div>
                              */}
                            </div>
                          </>
                    ) : (
                      <div className='emptyview'>
                        <div className='title'>No interactions found</div>
                        <div className='description'>
                                There are still no interactions for this customer.
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
            {
              tabIsSelected(TAB_CONTACT_INFO) && (
                <div className='info'>
                  <CustomerCard
                    className='mobile-info'
                    title=''
                    customer={ customerInteractions.customer }
                  />
                </div>
              )
            }
          </div>
        </div>
        <div className='right-panel'>
          {
            customerInteractions.customer && (
              <CustomerCard customer={ customerInteractions.customer } />
            )
          }
        </div>
      </div>


    </div>
  )
}

CustomerDetails.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(CustomerDetails)
