import { types } from '../actions/Review'

const initialState = {
  ratingsLoading: false,
  reviewsLoading: false,
  ratings: {},
  review: {
    loading: false, 
    error: false,
    data: null,
  },
  reply: {
    sending: false, 
    error: false,
    data: null,
  },
  reviewList: {
    page: 1,
    reviews: null
  },
  chartData: {
    loading: false,
    error: null,
    list: []
  },
  storeBreakdown: {
    loading: false,
    error: null,
    list: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RATINGS_SUMMARY_INTENT:
      return {
        ...state,
        ratingsLoading: true
      }

    case types.GET_RATINGS_SUMMARY_INTENT_RESOLVED:
      return {
        ...state,
        ratingsLoading: false,
        ratings: action.data
      }

    case types.GET_RATINGS_SUMMARY_INTENT_REJECTED:
      return {
        ...state,
        ratingsLoading: false,
        ratings: {},
        error: action.error
      }

    case types.GET_CHART_DATA_INTENT:
      return {
        ...state,
        chartData: {
          loading: true,
          error: null,
          list: []
        }
      }
    
    case types.GET_CHART_DATA_INTENT_RESOLVED:
      return {
        ...state,
        chartData: {
          loading: false,
          error: null,
          list: action.data
        }
      }
    
    case types.GET_CHART_DATA_INTENT_REJECTED:
      return {
        ...state,
        chartData: {
          loading: false,
          error: action.error,
          list: []
        }
      }
    
    case types.GET_STORE_BREAKDOWN_INTENT:
      return {
        ...state,
        storeBreakdown: {
          loading: true,
          error: null,
          list: []
        }
      }
  
    case types.GET_STORE_BREAKDOWN_INTENT_RESOLVED:
      return {
        ...state,
        storeBreakdown: {
          loading: false,
          error: null,
          list: action.data.storeBreakdown,
          summary: action.data.summary
        }
      }
  
    case types.GET_STORE_BREAKDOWN_INTENT_REJECTED:
      return {
        ...state,
        storeBreakdown: {
          loading: false,
          error: action.error,
          list: []
        }
      }

    case types.GET_REVIEW_INTENT:
      return {
        ...state,
        review: {
          loading: true, 
          error: false,
          data: null,
        },
      }
  
    case types.GET_REVIEW_INTENT_RESOLVED:
      return {
        ...state,
        review: {
          loading: false, 
          error: false,
          data: action.data,
        },
      }
  
    case types.GET_REVIEW_INTENT_REJECTED:
      return {
        ...initialState,
        review: {
          loading: false, 
          error: action.error,
          data: null,
        },
      }
    
    case types.SEND_REPLY_INTENT:
      return {
        ...state,
        reply: {
          sending: true, 
          error: false,
          data: null,
        },
      }
  
    case types.SEND_REPLY_INTENT_RESOLVED:
      return {
        ...state,
        reply: {
          sending: false, 
          error: false,
          data: action.data,
        },
      }
  
    case types.SEND_REPLY_INTENT_REJECTED:
      return {
        ...state,
        reply: {
          sending: false, 
          error: action.error,
          data: null,
        }
      }
    
    case types.GET_REVIEWS_INTENT:
      return {
        ...state,
        reviewsLoading: true
      }

    case types.GET_REVIEWS_INTENT_RESOLVED:
      return {
        ...state,
        reviewsLoading: false,
        reviewList: action.data
      }

    case types.GET_REVIEWS_INTENT_REJECTED:
      return {
        ...initialState,
        reviewsLoading: false,
        reviewList: {
          page: 1,
          reviews: []
        },
        error: action.error
      }

    default:
      return state
  }
}
