import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CustomerHeader from './CustomerHeader'
import { actions } from '../../actions/User'

const mapStateToProps = state => ({
  user: state.User.user
})

const mapDispatchToProps = dispatch => ({
  logout() {
    dispatch(actions.Logout())
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerHeader))
