import { types } from '../actions/Event'

const initialState = {
  error: false,
  loadingEvents: false,
  loadingEventSummary: false,
  dates: [], // a date array to reprsent dates on Which events/activites/orders happened.
  dateEvents: {},// a array which is mapped between the date(as key) on which they happened to the array of all events
}

// dates array will look like this(This structure is helpful in rendering the cutomer event timeline) -> 
// dates: [
//   'Jun 7, 2018',
//   'Mar 29, 2018'
// ]
// corresponding dateEvents array will look like this which is associated with the above date array.
// dateEvents: {
//   'Jun 7, 2018': [
//     {
//       id: 1088,
//       source: 'partner_orders',
//       sub_source: 'EZCater',
//       ...
//     },
//     {
//       id: 1090,
//       source: 'partner_orders',
//       sub_source: 'EZCater',
//       ...
//     }
//   ],
//   'Mar 29, 2018': [
//     {
//       id: 1089,
//       source: 'partner_orders',
//       sub_source: 'EZCater',
//       ...
//     },
//     {
//       id: 1091,
//       source: 'partner_orders',
//       sub_source: 'EZCater',
      
//     }
//   ]
// }
// The process which we are using, we collect all the dates in one array( in the order of time)
// and same time also put events in a way that we can easily access the events of particular day by using date as key.

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVENTS_INTENT:
      return {
        ...state,
        loadingEvents: true,
        searching: true
      }
    case types.GET_EVENTS_INTENT_RESOLVED:
      return {
        ...state,
        ...action.data,
        dates: action.data.dates, // a date array to reprsent dates for total events
        dateEvents: action.data.dateEvents, // a date array to reprsent dates for total events,
        loadingEvents: false,
        searching: false,
        error: false
      }

    case types.GET_EVENTS_INTENT_REJECTED:
      return {
        ...state,
        loadingEvents: false,
        searching: false,
        error: true
      }

    case types.GET_EVENT_SUMMARY_INTENT:
      return {
        ...state,
        loadingEventSummary: true
      }

    case types.GET_EVENT_SUMMARY_INTENT_RESOLVED:
      return {
        ...state,
        eventSummary: action.data,
        loadingEventSummary: false
      }

    case types.GET_EVENT_SUMMARY_INTENT_REJECTED:
      return {
        ...state,
        eventSummary: null,
        loadingEventSummary: false
      }

    case types.CLEAR_EVENTS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
