import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

export const saveSmsLoyalty = (opts, tokens) => {
  const { store, data } = opts
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${store.uuid}/loyalty_ivr`,
    method: METHODS.PATCH,
    headers: HeaderFactory(tokens),
    data: { ivr: data }
  })
}


export const saveSmsRatingSetting = (opts, tokens) => {
  const { store, data } = opts
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${store.uuid}/rating_request`,
    method: METHODS.PATCH,
    headers: HeaderFactory(tokens),
    data
  })
}
