import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import ImageTrigger from './ImageTrigger'
import CustomFieldsTrigger from './CustomFieldsTrigger'

import './styles.scss'

/**
 * @description List of components which actually triggers addons features
 *
 * @component
 *
 * @prop {boolean} mmsImage - tells if mmsImage addon should be enabled
 * @prop {boolean} customFields - tells if customFields should be enabled
 * @prop {function} onFileSelection - for mmsImage, this is called when a file is selected
 * @prop {function} onCustomFieldChange - for customFields, this is called when we add a custom field
 *
 * @example
 *
 * <SMSAddon.Triggers
 *   mmsImage={ true }
 *   customFields={ customfieldsEnabled }
 *   onFileSelection={ handleSmsFileSelection }
 *   onCustomFieldChange={ handleCustomFieldChange }
 * />
*/
const Triggers = props => {
  const triggers = []
  const {
    mmsImage,
    customFields,
    onFileSelection,
    onCustomFieldChange
  } = props

  if (mmsImage) {
    triggers.push(
      <ImageTrigger
        className='item image'
        key={ shortid.generate() }
        onFileSelection={ (file) => onFileSelection(file) }
      />
    )
  }

  if (customFields) {
    triggers.push(
      <CustomFieldsTrigger
        className='item customfields'
        key={ shortid.generate() }
        onChange={ (field) => onCustomFieldChange(field) }
      />
    )
  }

  return (
    <div className='eg-addons-triggers-sms'>
      { triggers.map(trigger => trigger) }
    </div>
  )
}

Triggers.propTypes = {
  mmsImage: PropTypes.bool,
  customFields: PropTypes.bool,
  onFileSelection: PropTypes.func,
  onCustomFieldChange: PropTypes.func
}

export default Triggers
