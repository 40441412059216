import React from 'react'
import PropTypes from 'prop-types'

import logo from '../../asset/zsf.svg'

import styles from './index.module.scss'

class Appbar extends React.Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    onMenuClick: PropTypes.func
  }

  constructor() {
    super()

    // bindings
    this.menuClick = this.menuClick.bind(this)
  }

  menuClick() {
    this.props.onMenuClick()
  }

  render() {
    return (
      <div className={ styles.container }>
        <img src={ logo } alt="Zero Store Front" />
      </div>
    )
  }
}

export default Appbar
