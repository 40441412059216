export const getAddressesFromString = addr => {
  return new Promise((resolve, reject) => {
    const googleMaps = window.google.maps
    const geocoder = new googleMaps.Geocoder()
    geocoder.geocode({ address: addr }, (result, status) => {
      const { OK, ZERO_RESULTS } = googleMaps.GeocoderStatus
      if ([OK, ZERO_RESULTS].includes(status)) {
        resolve(result)
      } else {
        reject(result, status)
      }
    })
  })
}


export const getCoordinates = (address) => {
  let coords = undefined
  if (address) {
    const location = address.geometry.location
    // Address.geometry.location coords sometimes is provided as a function.
    coords =
      typeof location.lat === 'function'
        ? { latitude: location.lat(), longitude: location.lng() }
        : { latitude: location.lat, longitude: location.lng }
  }
  return coords
}
