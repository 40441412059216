import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import querystring from 'query-string'

import ReviewItem from './ReviewItem'
import styles from './index.module.scss'

import ReviewDetailModal from '../ReviewDetailModal'

const ReviewList = props => {
  const [selectedReviewId, setSelectedReviewId] = useState(null)
  const [selectedReview, setSelectedReview] = useState(null)
  const { history } = props
  // get review id from the search query parameters.
  const { reviewId } = querystring.parse(props.history.location.search)

  useEffect(() => {
    if(reviewId) {
      setSelectedReviewId(reviewId)
    }
  }, [reviewId])

  if (!props.reviews) {
    return (
      <div className={ styles.reviewError }>
        <div>No reviews found for current restaurant</div>
      </div>
    )
  }

  const handleItemClick = review => {
    history.push({ search: `?reviewId=${review.reviewId}` })
    setSelectedReviewId(review.reviewId)
    setSelectedReview(review)
  }

  const handleModalClose = () => {
    history.push({ search: '' })
    setSelectedReviewId(null)
  }
  
  const reviews =  props.reviews || []
  return (
    <>
      <div className={ `${styles.reviewList} ${props.className}` }>
        {
          reviews.map((review, index) => 
            <ReviewItem
              review={ review }
              key={ index }
              onClick={ handleItemClick }
            />
          )
        }
      </div>
      <div>
        {
          selectedReviewId ? (
            <ReviewDetailModal
              restaurant={props.restaurant}
              reviewId={ selectedReviewId }
              onClose={ handleModalClose }
              selectedReviewFromList={selectedReview}
            />
          ) : null
        }
      </div>
    </>
  )
}

ReviewList.propTypes = {
  restaurant: PropTypes.object,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      date: PropTypes.string,
      location: PropTypes.string,
      name: PropTypes.string,
      rating: PropTypes.number,
      comment: PropTypes.string
    })
  ),
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
}

ReviewList.defaultProps = {
  className: ''
}

export default withRouter(ReviewList)
