import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions as oauthActions } from '../../../../../actions/Oauth'
import { oauthProviders } from '../../../../../services/auth'
import { Input, Button } from '../../../../FormComponents'

import './styles.scss'

const ShopifyConnect = props => {
  const [domain, setDomain] = useState(null)
  const dispatch = useDispatch()

  const handleInputChange = async (event) => {
    setDomain(event.target.value.replace(/https?:\/\//, ''))
  }

  const handleConnectClick = async () => {
    dispatch(oauthActions.authorize({
      clientId: process.env.REACT_APP_SHOPIFY_API_KEY,
      url: `https://${domain}/admin/oauth/authorize`,
      scope: 'read_orders,read_themes,write_themes,read_script_tags,write_script_tags,read_customers',
      restaurantUuid: props.restaurantUuid,
      provider: oauthProviders.SHOPIFY
    }))
    if (props.onAuthorize) {
      props.onAuthorize()
    }
  }

  return (
    <div className="eg-shopify-connect">
      <div className='title'>
        Please enter your store domain
      </div>
      <div className='description'>
        For example, <span>{ 'my-awesome-store.myshopify.com' }</span>
      </div>
      <Input
        name='subdomain'
        id='subdomain'
        type='text'
        placeholder='shopify domain'
        onChange={ handleInputChange }
      />

      <Button
        className='submit'
        text='Connect with Shopify'
        onClick={ handleConnectClick }
        disabled={ !domain }
      />
    </div>
  )
}

ShopifyConnect.propTypes = {
  restaurantUuid: PropTypes.string,
  onAuthorize: PropTypes.func
}

export default ShopifyConnect
