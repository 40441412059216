import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import PredicateGroup from '../../PredicateGroup'
import { Button } from '../../../FormComponents'
import { actions } from '../../../../actions/Segment'
import { types as buttonTypes } from '../../../FormComponents/Button'
import predicateSchema from '../../schemas/predicates'
import './styles.scss'

/**
 * DesignSegment - Step 2 of create dynamic segment flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  name: 'My new segment'
 * }
 *
 * return (
 *  <DesignSegment data={ data } onSubmit={ handleSubmit } />
 * )
*/
const DesignSegment = props => {
  const dispatch = useDispatch()
  const {
    // data,
    submitting,
    onBackClick,
    onSubmit
  } = props

  const predicates = useSelector(({Segment}) => _.get(Segment, 'dynamicSegment.data.payload.predicates', []))
  // const conditions = useRef(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formIsValid = () => {
    const valid = predicateSchema.validate(predicates)
    return !valid.error
  }

  const handleBackClick = () => {
    if (onBackClick) onBackClick()
  }

  const handleSubmit = () => {
    if (onSubmit) {
      // onSubmit('design', { conditions })
      onSubmit('design')
    }
  }

  const onOrRowAdd = (orBoxIndex) => {
    const updatedPredicatesParent = [...predicates]
    updatedPredicatesParent[orBoxIndex].predicates.push({
      type: 'and',
      predicates: []
    })
    dispatch(actions.setPredicates(updatedPredicatesParent))
  }

  const onAndClick = () => {
    const updatedPredicatesParent = [...predicates]
    updatedPredicatesParent.push({
      type: 'or',
      predicates: [
        {
          type: 'and',
          predicates: []
        }
      ]
    })
    dispatch(actions.setPredicates(updatedPredicatesParent))
  }

  const onPredicateChange = (updatePredicateData, orBoxIndex) => {
    const {
      updatedPredicates,
      predicatesIndex
    } = updatePredicateData
    const updatedPredicatesParent = [...predicates]
    updatedPredicatesParent[orBoxIndex].predicates[predicatesIndex].predicates = updatedPredicates
    dispatch(actions.setPredicates(updatedPredicatesParent))
  }

  const handlePredicateRemove = (predicateIndex, groupIndex) => {
    const updatedPredicates = [ ...predicates ]
    updatedPredicates[groupIndex].predicates = predicates[groupIndex].predicates.filter((p, i) => i !== predicateIndex)
    dispatch(actions.setPredicates(updatedPredicates))
  }

  const handleGroupRemove = groupIndex => {
    dispatch(actions.setPredicates(predicates.filter((p, i) => i !== groupIndex)))
  }

  return (
    <div className='eg-create-segment-design'>
      <div className='step-body'>
        <div className='panel inputs'>
          <div className='title'>Define your segment</div>
          <div className='subtitle'>
            Choose conditions to define your segment.
            Use the and/or connectors to narrow or widen your segment.
          </div>
          {predicates.map((predicateObject, index) => {
            return (
              <div key={index} className='predicate-group-container'>
                {index !== 0 && <div className='and-divider-label'> and </div>}
                <div className='predicate-group' >
                  <PredicateGroup
                    predicates={predicateObject.predicates}
                    onPredicateChange={(predicateData) => onPredicateChange(predicateData, index)}
                    onPredicateRemove={ (predicateIndex) => handlePredicateRemove(predicateIndex, index) }
                    onGroupRemove={ index > 0 ? () => handleGroupRemove(index) : undefined }
                    onOrAddClick={() => onOrRowAdd(index)}
                  />
                </div>
              </div>
            )
          })}
          <div className='and-add' onClick={onAndClick}>AND</div>
          <div className='actions'>
            <Button
              className='action-button'
              text='BACK'
              type={ buttonTypes.SECONDARY }
              onClick={ handleBackClick }
            />
            <div>
              <Button
                className='action-button submit'
                text='CONTINUE'
                loading={submitting}
                disabled={!formIsValid()}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DesignSegment.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default DesignSegment
