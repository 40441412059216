import Avo from '../Avo'
import _ from 'lodash'

import { getFormattedDate } from './dates'

export const initSegment = () => {
  if (window.analytics.initialized) return
  window.analytics.load(process.env.REACT_APP_SEGMENT_KEY)
}

export const analyticEvents = {
  GENERAL_ERROR: 'generalError',
  GENERAL_WARNING: 'generalWarning',

  CUSTOMERS_PAGE_VIEWED: 'customersPageViewed',

  INTEGRATIONS_PAGE_VIEWED: 'integrationsPageViewed',
  INTEGRATIONS_TAB_INTERACTION: 'integrationsTabInteraction',
  INTEGRATION_PROCESS_STARTED: 'integrationProcessStarted',
  INTEGRATION_PROCESS_COMPLETED: 'integrationProcessCompleted',
  INTEGRATION_PROCESS_ABANDONED: 'integrationProcessAbandoned', // Handle for point of sale process

  ORDER_LIST_PAGE_VIEWED: 'orderListPageViewed',
  ORDER_LIST_VIEW_CHANGED: 'orderListViewChanged',
  ORDER_DETAILS_PAGE_VIEWED: 'orderDetailsPageViewed',
  ORDER_DETAILS_PAGE_ABANDONED: 'orderDetailsPageAbandoned',

  INTERCOM_CHAT_INITIATED: 'intercomChatInitiated',
  INTERCOM_CHAT_ABANDONED: 'intercomChatAbandoned',

  LOGIN_STARTED: 'loginStarted',
  LOGIN_COMPLETED: 'loginCompleted',

  SIGNOUT_COMPLETED: 'signoutCompleted',


  PASSWORD_RESET_STARTED: 'passwordResetStarted',
  PASSWORD_RESET_COMPLETED: 'passwordResetCompleted',

  REVIEWS_PAGE_VIEWED: 'reviewsPageViewed',
  REVIEW_MODAL_DISPLAYED: 'reviewModalDisplayed',
  REVIEW_MODAL_ABANDONED: 'reviewModalAbandoned',
  REVIEW_EXTERNAL_URL_INTERACTION: 'reviewExternalUrlInteraction',
  REVIEWS_OVERVIEW_PAGE_VIEWED: 'reviewsOverviewPageViewed',

  CAMPAIGN_LIST_PAGE_VIEWED: 'campaignListPageViewed',
  CAMPAIGN_LIST_PAGE_TAB_INTERACTION: 'campaignListPageTabInteraction',
  CAMPAIGN_CREATION_STARTED: 'campaignCreationStarted',
  CAMPAIGN_CREATION_ABANDONED: 'campaignCreationAbandoned',
  CAMPAIGN_SEGMENT_DROPDOWN_INTERACTION: 'campaignSegmentDropdownInteraction',
  CAMPAIGN_STEP_COMPLETED: 'campaignStepCompleted',
  CAMPAIGN_STEP_ABANDONED: 'campaignStepAbandoned',
  CAMPAIGN_CREATION_COMPLETED: 'campaignCreationCompleted',
  CAMPAIGN_SCHEDULE_MODAL_DISPLAYED: 'campaignScheduleModalDisplayed',
  CAMPAIGN_SCHEDULE_MODAL_ABANDONED: 'campaignScheduleModalAbandoned',

  JOURNEY_LIST_PAGE_VIEWED: 'journeyListPageViewed',
  JOURNEY_CREATION_STARTED: 'journeyCreationStarted',
  JOURNEY_CREATION_COMPLETE: 'journeyCreationCompleted',
  JOURNEY_CREATION_ABANDONED: 'journeyCreationAbandoned',
  JOURNEY_ACTIVATED: 'journeyActivated',
  JOURNEY_ACTIVATION_MODAL_ABANDONED: 'journeyActivationModalAbandoned',
  JOURNEY_ACTIVATION_MODAL_DISPLAYED: 'journeyActivationModalDisplayed',
  JOURNEY_DEACTIVATED: 'journeyDeactivated',
  JOURNEY_DEACTIVATED_MODAL_DISPLAYED: 'journeyDeactivationModalDisplayed',
  JOURNEY_DEACTIVATED_MODAL_ABANDONED: 'journeyDeactivationModalAbandoned',
  JOURNEY_STEP_COMPLETED: 'journeyStepCompleted',
  JOURNEY_STEP_ABANDONED: 'journeyStepAbandoned',
  JOURNEY_ADD_MESSAGE_INTERACTION: 'journeyAddMessageInteraction',
  JOURNEY_REMOVE_MESSAGE_INTERACTION: 'journeyRemoveMessageInteraction',
  JOURNEY_TRIGGER_INTERACTIONS: 'journeyTriggerInteraction',


  // Common for journey and campaign flow
  SEND_TEST_MESSAGE_INTERACTION: 'sendTestMessageInteraction',
  SEND_TEST_MESSAGE_MODAL_ABANDONED: 'sendTestMessageModalAbandoned',
  SEND_TEST_MESSAGE_MODAL_DISPLAYED: 'sendTestMessageModalDisplayed',
  TEST_MESSAGE_WARNING_DISPLAYED: 'testMessageWarningDisplayed',
  TEST_MESSAGE_SENT: 'testMessageSent',

  // Common for URL shortner
  SHORT_LINK_CREATED: 'shortLinkCreated',
  SHORT_LINK_INTERACTION: 'shortLinkInteraction',
  SHORT_LINK_MODAL_DISPLAYED: 'shortLinkModalDisplayed',
  SHORT_LINK_MODAL_ABANDONED: 'shortLinkModalAbandoned',

}

export const eventPropertyValues = {
  REVIEW_EXTERNAL_RESPOND: 'Respond',
  REVIEW_EXTERNAL_VIEW: 'View',
}

class EGAnalytic {
  constructor() {
    this.Avo = Avo

    if (typeof window !== 'undefined') {
      if (process.env.REACT_APP_AVO_ENV === 'production') {
        this.Avo.initAvo({ env: 'prod' })
      } else if (process.env.REACT_APP_AVO_ENV === 'development') {
        this.Avo.initAvo({ env: 'dev' })
      }
    }
  }

  track(key, opts) {
    try {
      this.Avo[key](opts)
    } catch (error) {
      console.error('Error while tracking event ->', key, opts)
      console.error('Avo error detail :', error)
    }
  }

  mapRestaurant(restaurant) {
    if (!restaurant) return {}
    let eventDetail = {
      restaurantId: `${restaurant.restaurant_id}`,
      restaurantName: restaurant.name,
      restaurantAddress1: restaurant.address1 || '',
    }
    if (restaurant.rate) eventDetail.restaurantRating = restaurant.rate
    if (restaurant.rate_count || restaurant.numberOfRates) {
      eventDetail.restaurantRatingQuantity = restaurant.rate_count || restaurant.numberOfRates
    }
    if (restaurant.uuid || restaurant.restaurantUuid) {
      eventDetail.uuid = restaurant.uuid || restaurant.restaurantUuid
    }
    return eventDetail
  }

  mapChain(chain) {
    if (!chain) return {}
    let eventDetail = {
      restaurantId: `${chain._id}`,
      restaurantName: chain.name,
    }
    if (chain.rate) eventDetail.restaurantRating = chain.rate
    if (chain.rate_count || chain.numberOfRates) {
      eventDetail.restaurantRatingQuantity = chain.rate_count || chain.numberOfRates
    }
    if (chain.uuid || chain.restaurantUuid) {
      eventDetail.uuid = chain.uuid || chain.restaurantUuid
    }
    return eventDetail
  }

  mapOrderDetail(restaurant, order) {
    if (!order || !restaurant) return {}
    const orderSource = _.get(order, 'restaurant_partner.name')
    let eventDetail = {
      restaurantId: `${order.restaurant_id}`,
      restaurantName: restaurant.name,
      orderNumber: order.restaurant_order_id,
      orderDate: getFormattedDate(order.order_date, false), // this is the format of the date, order_date: "2018-11-21T19:15:00.000Z" should I change it? :thinking face
      orderTotal: order.total,
      orderPartner: orderSource || '', // change event name from orderPartner to orderSource
    }
    if (restaurant.rate) eventDetail.restaurantRating = restaurant.rate
    if (restaurant.rate_count || restaurant.numberOfRates)
      eventDetail.restaurantRatingQuantity = restaurant.rate_count || restaurant.numberOfRates
    if (restaurant.uuid || restaurant.restaurantUuid) {
      eventDetail.uuid = restaurant.uuid || restaurant.restaurantUuid
    }
    return eventDetail
  }

  mapReviewDetial(restaurant, review) {
    if (!review || !restaurant) return {}
    let eventDetail = {
      restaurantId: `${restaurant.restaurant_id}`,
      restaurantName: restaurant.name,
      reviewSource: review.source,
      reviewDate: getFormattedDate(review.date, false),
      reviewName: review.name,
      reviewRating: `${review.rating}`,
    }
    if (restaurant.rate) eventDetail.restaurantRating = restaurant.rate
    if (restaurant.rate_count || restaurant.numberOfRates)
      eventDetail.restaurantRatingQuantity = restaurant.rate_count || restaurant.numberOfRates
    if (restaurant.uuid || restaurant.restaurantUuid) {
      eventDetail.uuid = restaurant.uuid || restaurant.restaurantUuid
    }
    return eventDetail
  }

  mapIntegrationTabDetail(restaurant) {
    try {
      const restaurantId = `${restaurant.restaurant_id ? restaurant.restaurant_id : restaurant.restaurantId}`
      let eventDetail = {
        restaurantId,
        restaurantName: restaurant.name,
      }
      if (restaurant.rate) eventDetail.restaurantRating = restaurant.rate
      if (restaurant.rate_count || restaurant.numberOfRates)
        eventDetail.restaurantRatingQuantity = restaurant.rate_count || restaurant.numberOfRates
      if (restaurant.uuid || restaurant.restaurantUuid) {
        eventDetail.uuid = restaurant.uuid || restaurant.restaurantUuid
      }
      return eventDetail
    }
    catch (e) {
      return {}
    }
  }
}

//For Singleton principle, creating only one instance of EgAnalytic
//  throughout the application.
export default new EGAnalytic()
