import { types } from '../actions/Route'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ROUTE_CHANGE:
      return {
        ...state,
        activePath: action.path
      }

    default:
      return state
  }
}
