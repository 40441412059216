import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { timeToAmPm, timeTo24 } from '../../../../services/dates'

import styles from './index.module.scss'

const TIME_INTERVAL = 30
const TIME_MIN = '00:00'
const TIME_MAX = '23:59'

const strToTime = str => {
  const [ h, m ] = str.split(':')
  return [ parseInt(h), parseInt(m) ]
}

const getTimeList = (min = TIME_MIN, max = TIME_MAX) => {
  let [hMin, mMin] = strToTime(min)
  let [hMax, mMax] = strToTime(max)
  const list = []
  for(let h = hMin; h <= hMax; h++) {
    const mStart = h === hMin ? mMin : 0
    for (let m = mStart; m <= 59; m += TIME_INTERVAL) {
      if (h < hMax || (h === hMax && m <= mMax)) {
        list.push(timeToAmPm(`${h}:${m}`))
      }
    }
  }
  return list
}

const TimeDropdown = props => {
  const wrapperRef = useRef(shortid.generate())
  const triggerRef = props.triggerRef
  const onClose = props.onClose

  let dropdownTimes = []
  if (props.min < props.max) {
    dropdownTimes = getTimeList(props.min, props.max)
  } else {
    // if min >= max, then we should go from min to 23:59
    // and then, from 23:59 to max
    dropdownTimes = [
      ...getTimeList(props.min, '23:59'),
      ...getTimeList('00:00', props.max)
    ]
  }

  const handleItemClick = (event, time) => {
    if (props.onChange) {
      props.onChange(timeTo24(time))
    }
  }

  useEffect(() => {
    // Handler
    const handleClickOutside = (event) => {
      if (triggerRef.current.contains(event.target)) {
        return
      }
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if(onClose) {
          onClose()
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose, triggerRef])

  return (
    <div className={ styles.container } ref={ wrapperRef }>
      {
        dropdownTimes.map((time, index) => (
          <div
            className={ styles.item }
            key={ `ddlist-${index}` }
            onClick={ (e) => handleItemClick(e, time) }
          >
            { time }
          </div>
        ))
      }
    </div>
  )
}

TimeDropdown.propTypes = {
  show: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  triggerRef: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func
}

TimeDropdown.defaultProps = {
  show: false,
}

export default TimeDropdown
