import React from 'react'

import './styles.scss'
import { links } from './links'

export default function Footer (props) {
  return (
    <footer className="eg-footer" {...props}>
      <ul className="list">
        { links.map((link) => (
          <li className="item" key={link.label}>
            <a className="link" href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
          </li>
        )) }
      </ul>
      <span className="copyright">&copy; ZeroStoreFront {new Date().getFullYear()}</span>
    </footer>
  )
}
