import { connect } from 'react-redux'

import GoogleAddressInput from './GoogleAddressInput'
// import { actions as notificationActions } from '../../../core/actions/Notification'

const mapDispatchToProps = dispatch => {
  return {
    // showNotification: (notification) => dispatch(notificationActions.ShowNotification(notification)),
  }
}

export default connect(null, mapDispatchToProps)(GoogleAddressInput)
