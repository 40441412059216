import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import EGAnalytic, { analyticEvents } from '../../services/analytics'

import CustomerHeader from '../../components/CustomerHeader'
import ReviewList from '../../components/Reviews/ReviewList'
import ExportReview from '../../components/Reviews/ExportReview'
import LocalSpinner from '../../components/LocalSpinner'
import Pagination from '../../components/Pagination'
import Dropdown from '../../components/Dropdown'
import SortingHeaders from '../../components/SortingHeaders'
import Search from '../../components/Search'
import StoreFilter from '../../components/Store/StoreFilter'
import styles from './index.module.scss'

// filters
import SourceFilter from '../../components/Reviews/Filters/SourceFilter'
import RatingFilter from '../../components/Reviews/Filters/RatingFilter'
import DateFilter from '../../components/Reviews/Filters/DateFilter'
import StatusFilter from '../../components/Reviews/Filters/StatusFilter'

import { getNumberWithCommas } from '../../utils/number'

const pageSizes = [ 15, 50, 100 ].map(i => ({ key: i, label: `${i} reviews` }))

const filterTypes = {
  DATE: 'date',
  SOURCE: 'source',
  RATING: 'rating',
  STATUS: 'status'
}

const sortingFields = [
  { label: 'SOURCE', key: 'source', className: styles.reviewSource },
  { label: 'DATE', key: 'date', className: styles.reviewDate },
  { label: 'STORE', key: 'store', className: styles.reviewStore, sortable: false},
  { label: 'REVIEWER', key: 'name', className: styles.reviewUser },
  { label: 'REVIEW', key: 'rating', className: styles.reviewRate },
  { label: 'STATUS', key: 'status', className: styles.reviewStatus, sortable: false },
  { label: 'ACTION', key: 'actions', className: styles.reviewActions, sortable: false }
]

// const defaultDateFilter = moment().subtract(30, 'days').utc().startOf('day').format()
const initialFiltersState = {
  [filterTypes.DATE]: { actionLabel: 'All Time', filterKeys: [] },
  [filterTypes.SOURCE]: { actionLabel: 'All Sources', filterKeys: [] },
  [filterTypes.RATING]: { actionLabel: 'All Ratings', filterKeys: [] },
  [filterTypes.STATUS]: { actionLabel: 'All Statuses', filterKeys: [] }
}

export default class Reviews extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    reviewList: PropTypes.object.isRequired,
    reviewsLoading: PropTypes.bool.isRequired,
    size: PropTypes.number,
    getReviews: PropTypes.func.isRequired,
    lastUsedChainUuid: PropTypes.string,
    getStoreList: PropTypes.func.isRequired,
    selectedStores: PropTypes.array.isRequired,
    clearStoreFilter: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      searchValue: '',
      searching: false,
      filters: initialFiltersState
    }

    // bindings
    this.getReviews = this.getReviews.bind(this)
    this.getStoreList = this.getStoreList.bind(this)
    this.onStoreFilterChange = this.onStoreFilterChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.showEmptyView = this.showEmptyView.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterApply = this.handleFilterApply.bind(this)
    this.renderFilters = this.renderFilters.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.getReviewsWithFilter = this.getReviewsWithFilter.bind(this)
    this.doesHaveFilters = this.doesHaveFilters.bind(this)
    this.renderClearFilterButton = this.renderClearFilterButton.bind(this)
  }

  componentDidMount() {
    EGAnalytic.track(analyticEvents.REVIEWS_PAGE_VIEWED, {...EGAnalytic.mapRestaurant(this.props.restaurant)})
    this.getReviews()
    this.getStoreList()
  }

  getReviews() {
    this.props.getReviews({
      page: 1,
      size: pageSizes[0].key,
      filters: initialFiltersState
    })
  }

  onStoreFilterChange() {
    this.getReviewsWithFilter()
  }

  getStoreList() {
    const { lastUsedChainUuid, getStoreList, restaurant } = this.props
    const restaurantChainUuid = restaurant.restaurant_chain_uuid
    // get store list for filters,  if we havne't retrieved yet
    if(lastUsedChainUuid !== restaurantChainUuid){
      getStoreList()
    }
  }

  handlePageSizeChange(size) {
    this.props.getReviews({
      page: 1,
      size: size.key,
      sort: _.get(this.props.reviewList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  handlePageChange(page) {
    this.props.getReviews({
      page,
      size: _.get(this.props.reviewList, 'size'),
      sort: _.get(this.props.reviewList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  handleSortChange(field) {
    this.props.getReviews({
      page: 1,
      size: _.get(this.props.reviewList, 'size'),
      sort: field,
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  handleSearchChange(value) {
    this.setState({ searchValue: value }, () => {
      if (!this.state.searching) {
        this.setState({ searching: true }, () => {
          setTimeout(() => {
            this.props.getReviews({
              page: 1,
              size: _.get(this.props.reviewList, 'size'),
              sort: _.get(this.props.reviewList, 'sort'),
              filters: this.state.filters,
              search: this.state.searchValue
            })
            this.setState({ searching: false })
          }, 1000)
        })
      }
    })
  }

  showEmptyView(message) {
    return (
      <div className={ styles.emptyView }>
        <div className={ styles.emptyViewContainer }>
          <div className={ styles.emptyViewTitle }>
            No reviews to show
          </div>
          <p>{ message }</p>
        </div>
      </div>
    )
  }

  /**
   * handle changes in the filter, updating only the label
   * we don't update the filterKeys until user applies the changes
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterChange(filter, filterType) {
    const filterKeys = this.state.filters[filterType].filterKeys
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: {
          actionLabel: filter.actionLabel,
          filterKeys
        }
      }
    })
  }

  /**
   * handle apply filter, updating the label and also the selected items
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterApply(filter, filterType) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: filter
      }
    }, this.getReviewsWithFilter)
  }

  getReviewsWithFilter() {
    this.props.getReviews({
      page: 1,
      size: _.get(this.props.reviewList, 'size'),
      sort: _.get(this.props.reviewList, 'sort'),
      filters: this.state.filters,
      search: this.state.searchValue
    })
  }

  clearFilters() {
    this.props.clearStoreFilter()
    this.setState({ filters: initialFiltersState }, () => {
      this.props.getReviews({
        page: 1,
        size: _.get(this.props.reviewList, 'size'),
        sort: _.get(this.props.reviewList, 'sort'),
        filters: this.state.filters,
        search: this.state.searchValue
      })
    })
  }

  /**
   * Checks wether any filter has been applied or not
   */
  doesHaveFilters() {
    const { filters } = this.state
    const { selectedStores = [] } = this.props
    if( selectedStores.length ||
      _.get(filters[filterTypes.DATE], 'filterKeys.length') ||
      _.get(filters[filterTypes.SOURCE], 'filterKeys.length') ||
      _.get(filters[filterTypes.RATING], 'filterKeys.length') ||
      _.get(filters[filterTypes.STATUS], 'filterKeys.length')) {
      return true
    }
    return false
  }

  renderClearFilterButton() {
    if(!this.doesHaveFilters()) return null
    return (
      <div className={ styles.clearAllFilters } onClick={ this.clearFilters }>
        CLEAR ALL
      </div>
    )
  }

  renderFilters() {
    return (
      <>
        <StoreFilter onFilterChange={this.onStoreFilterChange} className={styles.storeFilter}/>
        <DateFilter
          filter={ this.state.filters[filterTypes.DATE] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.DATE) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) }
          actionLabel={ this.state.filters[filterTypes.DATE].actionLabel }
        />

        <SourceFilter
          filter={ this.state.filters[filterTypes.SOURCE] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.SOURCE) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.SOURCE) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.SOURCE) }
          actionLabel={ this.state.filters[filterTypes.SOURCE].actionLabel }
        />

        <RatingFilter
          filter={ this.state.filters[filterTypes.RATING] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.RATING) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.RATING) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.RATING) }
          actionLabel={ this.state.filters[filterTypes.RATING].actionLabel }
        />

        <StatusFilter
          filter={ this.state.filters[filterTypes.STATUS] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.STATUS) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.STATUS) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.STATUS) }
          actionLabel={ this.state.filters[filterTypes.STATUS].actionLabel }
        />

        {this.renderClearFilterButton()}
      </>
    )
  }

  render() {
    const page = _.get(this.props.reviewList, 'page')
    const size = _.get(this.props.reviewList, 'size')
    const total = _.get(this.props.reviewList, 'total')
    const reviews = _.get(this.props.reviewList, 'reviews')
    const selectedSize = pageSizes.find(s => s.key === size) || pageSizes[0]

    return (
      <>
        <CustomerHeader />
        <div className={ styles.container }>
          <div className={ styles.reviewsWrapper }>
            <h2>Reviews</h2>
            <div className={ styles.filtersWrapper }>
              <div className= {styles.reviewFilters}>
                { this.renderFilters() }
              </div>
              <Search
                className={ styles.searchInput }
                value={ this.state.searchValue }
                onChange={ this.handleSearchChange }
              />
            </div>

            {
              this.props.reviewsLoading ? (
                <LocalSpinner className={ styles.spinner } />
              ) : (
                <>
                  <div className={styles.exportRow}>
                    <div className={styles.totalReviews}> {getNumberWithCommas(total)} Reviews </div>
                    <ExportReview filters={this.state.filters} search={this.state.searchValue} />
                  </div>
                  <div className={ styles.sortingWrapper }>
                    <SortingHeaders
                      fields={ sortingFields }
                      currentSorting={ this.props.reviewList.sort }
                      onChange={ this.handleSortChange }
                      indicatorsClassName={styles.sortingArrow}
                    />
                  </div>
                  {/* Empty view */}
                  {
                    (!this.props.reviewsLoading && reviews && !reviews.length) ? (
                      <div>
                        { this.showEmptyView('No results found') }
                      </div>
                    ) : null
                  }
                  <ReviewList
                    restaurant={this.props.restaurant}
                    reviews={ reviews }
                    page={ page }
                    total={ total }
                    className={ styles.reviewList }
                  />
                  <div className={ styles.paginationWrapper }>
                    <div className={ styles.pageSizeWrapper }>
                      Showing
                      <Dropdown
                        className={ styles.pageSizesDropdown }
                        items={ pageSizes }
                        selectedItem={ selectedSize }
                        onChange={ this.handlePageSizeChange }
                      />
                    </div>
                    <Pagination
                      className={ styles.pagination }
                      currentPage={ _.get(this.props.reviewList, 'page') }
                      pageCount={ Math.ceil(total / size) }
                      onPageClick={ this.handlePageChange }
                      marginPages={ 1 }
                      pageRange={ 1 }
                    />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </>
    )
  }
}
