import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FilterOptions from '../../../FilterOptions'
import RatingStars from '../../../RatingStars'

import styles from './index.module.scss'

const defaultActionLabel = 'All ratings'

const RatingFilter = props => {
  const [ selectedItems, setSelectedItems ] = useState([])

  const handleFilterChange = (items, cb) => {
    setSelectedItems(items)
    const rtngString = items.length === 1 ? 'Rating' : 'Ratings'
    const actionLabel = !items.length ? defaultActionLabel : `${rtngString} ${items.join(', ')}`
    if (cb) {
      cb({ filterKeys: items, actionLabel })
    }
  }

  const handleClear = () => {
    setSelectedItems([])
    if (props.onChange) {
      props.onClear({
        filterKeys: [],
        actionLabel: defaultActionLabel
      })
    }
  }

  const items = [1, 2, 3, 4, 5].map(i => ({
    key: i,
    content: () => (
      <div className={ styles.itemWrapper }>
        <span>{ i }</span>
        <RatingStars rate={ i } className={ styles.starsWrapper } />
      </div>
    )
  }))

  // update internal state when prop changes
  useEffect(() => {
    setSelectedItems(props.filter.filterKeys)
  }, [props.filter.filterKeys])

  return (
    <div className={ styles.ratingFilterContainer }>
      <FilterOptions
        actionLabel={ props.actionLabel }
        onChange={ (items) => handleFilterChange(items, props.onChange) }
        onApply={ (items) => handleFilterChange(items, props.onApply) }
        onClear={ handleClear }
        items={ items }
        selectedItems={ selectedItems }
        multiple
      />
    </div>
  )
}

RatingFilter.propTypes = {
  filter: PropTypes.object,
  actionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

RatingFilter.defaultProps = {
  actionLabel: defaultActionLabel
}

export default RatingFilter
