import { put } from 'redux-saga/effects'
import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

export function* showErrorNotification(errorMessage) {
  const notification = {
    type: EgNotificationTypes.ERROR,
    message: errorMessage
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}

export function* showSuccessNotification(successMessage) {
  const notification = {
    type: EgNotificationTypes.SUCCESS,
    message: successMessage
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}
