import React, { useState, useRef } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import shortid from 'shortid'
import get from 'lodash/get'

import StatelessModal from '../../StatelessModal'
import { Input, Select, Button } from '../../FormComponents'
import DateInput from '../../FormComponents/DateInput'
import TimeDropdown  from '../../FormComponents/TimeRangeInput/TimeDropdown'
import CustomerMessage from '../../CustomerMessage'
import { types as msgTypes } from '../../CustomerMessage/CustomerMessage'

import { types as buttonTypes } from '../../FormComponents/Button'
import { timeToAmPm, timeToTimezone } from '../../../services/dates'
import { scheduleTypes } from '../../../services/campaigns'

import './styles.scss'

const scheduleOptions = [
  {
    type: scheduleTypes.SCHEDULE,
    label: 'Schedule',
    subLabel: 'Send on a future date',
  },
  {
    type: scheduleTypes.INSTANT,
    label: 'Send now',
    subLabel: 'Send immediately',
  },
]

const fields = {
  date: 'date',
  timezone: 'timezone'
}

export const timeZones = [
  { value: 'edt', label: 'EDT',  timezone: 'America/New_York' },
  { value: 'pdt', label: 'PDT', timezone: 'America/Los_Angeles' }
]

/**
 * Schedule - Modal to schedule campaign
 *
 * @component
 * @example
 *
 * const handleSubmit = (data) => {
 *    const { type } = data // i.e type could have value like 'instant'
 * }
 *
 * const onCancel = () => {
 * ... On cancel button click in schedule modal
 * }
 *
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return return (
 * <Schedule onSubmit={ handleSubmit } onCancel={ onCancel } data={data}/>
 * )
*/
const Schedule = props => {
  const { submitting, data, error, onSubmit, onCancel } = props
  const timeRef = useRef(shortid.generate())
  // TODO: when we implement the timezone in the model, we should change this two lines below accordingly
  const savedDate = get(data, 'schedule_date') ? new Date(moment.tz(get(data, 'schedule_date'), timeZones[0].timezone).format('ddd, MMM DD, YYYY h:mm A')) : null
  const savedTime = get(data, 'schedule_date') ? moment.tz(get(data, 'schedule_date'), timeZones[0].timezone).format('HH:mm') : null
  const [ scheduleDate, setScheduleDate ] = useState(savedDate)
  const [ selectedOption, setSelectedOption ] = useState(scheduleOptions[0])
  const [ selectedTimeZone, setSelectedTimeZone ] = useState(timeZones[0])
  const [ showTimeOption, toggleTimeOption ] = useState(false)
  const [ selectedTimeSlot, setSelectedTimeSlot ] = useState(savedTime)
  const { recipients } = useSelector(state => state.Campaign)

  const isScheduleFormValid = () => {
    return !(scheduleDate && selectedTimeSlot)
  }

  const onCloseModal = () =>{
    onCancel && onCancel()
  }

  const handleSubmit = () => {
    if(onSubmit) {
      if(selectedOption.type === scheduleTypes.SCHEDULE) {
        const date = moment(scheduleDate).format('YYYY-MM-DD')
        const scheduleTime = moment.tz(`${date} ${selectedTimeSlot}`, selectedTimeZone.timezone).utc()
        const schedulePayload = { type: scheduleTypes.SCHEDULE, schedule_date: scheduleTime.toISOString(), timezone: selectedTimeZone }
        onSubmit(schedulePayload)
      } else {
        // Setting seconds and milliseconds to zero, so it helps in testing
        const scheduleTime = moment().seconds(0).milliseconds(0).add(10, 'minutes').utc()
        const sendNowPayload = { type: scheduleTypes.INSTANT, schedule_date: scheduleTime.toISOString() }
        onSubmit(sendNowPayload)
      }
    }
  }

  const handleTimeChange = (time, type) => {
    setSelectedTimeSlot(time)
    toggleTimeOption(false)
  }

  const handleTimeZoneChange = (timeZone) => {
    setSelectedTimeZone(timeZone)
  }

  const onDateChange = (date) => {
    setScheduleDate(date)
  }

  const renderMainContent = () => {
    if (selectedOption.type === scheduleTypes.SCHEDULE) {
      return (
        <div className='form'>
          <div className='date-container'>
            <div className='label'> SEND DATE </div>
            <DateInput className='date' value={scheduleDate} onChange={onDateChange}/>
          </div>

          <div className='time-container'>
            <div
              ref={ timeRef }
              className='time'
            >
              <div className='label'> SEND TIME</div>
              <Input value={timeToAmPm(selectedTimeSlot) || ''} placeholder='Select a time'  onClick={ () => toggleTimeOption(true) } readOnly data-testid='time-input'/>
              {
                showTimeOption && (
                  <TimeDropdown
                    min={ timeToTimezone(data.activeRange.start_time, selectedTimeZone.timezone) }
                    max={ timeToTimezone(data.activeRange.end_time, selectedTimeZone.timezone) }
                    triggerRef={ timeRef }
                    onClose={ () => toggleTimeOption(false) }
                    onChange={ (selectedTime) => handleTimeChange(selectedTime) }
                  />
                )
              }
            </div>
            <div className='time-zone'>
              <Select
                className={'timezone-input'}
                name={ fields.timezone }
                defaultValue={selectedTimeZone}
                options={ timeZones }
                onChange={ handleTimeZoneChange }
              />
            </div>
          </div>
        </div>
      )
    } else if(selectedOption.type === scheduleTypes.INSTANT) {
      return (
        <div className='sendnow-content'>
          <div>
            You’re about to send this campaign to <span>{recipients}</span> subscribers
            from <span>{get(data,'campaign_segment.label')}</span>
          </div>

          <div className='info'>
            If you send now, we will place your message in our send queue.
            {/* You still have 10 minutes to pause it if you need to edit. */}
          </div>
        </div>
      )
    }
  }

  return (
    <StatelessModal className='schedule-modal' onClose={ onCloseModal }>
      <div className='eg-campaign-schedule'>
        <div className='title'>Ready to send?</div>
        <CustomerMessage
          show={ !_.isEmpty(error) && _.get(error, 'errorCode') === 'quiet_time_schedule' }
          type={ msgTypes.MSG_WARNING }
          className='warning'
        >
          { _.get(error, 'message') }
        </CustomerMessage>
        <div className='options'>
          {scheduleOptions.map((item, index) => {
            const isSelected = item.type === selectedOption.type
            return (
              <div className={`option ${isSelected && 'selected'}`} onClick={()=> setSelectedOption(item)} key={index} data-testid={`option-${item.type}`}>
                <div className='icon-container'>
                  <div className={item.type}/>
                </div>
                <div>
                  <div>{item.label} </div>
                  <div className='sublabel'> {item.subLabel}</div>
                </div>
              </div>
            )
          })}
        </div>
        {renderMainContent()}
        <div className='action-wrapper'>
          <Button
            className='action-button'
            text='CANCEL'
            type={ buttonTypes.SECONDARY }
            onClick={ onCloseModal }
          />
          {
            selectedOption.type === scheduleTypes.SCHEDULE
              ? <Button
                className='action-button submit'
                text='SCHEDULE'
                disabled={ isScheduleFormValid()}
                loading={ submitting }
                onClick={ handleSubmit }
              />
              : <Button
                className='action-button submit'
                text='SEND NOW'
                loading={ submitting }
                onClick={ handleSubmit }
              />
          }
        </div>
      </div>
    </StatelessModal>
  )
}

Schedule.propTypes = {
  submitting: PropTypes.bool,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default Schedule
