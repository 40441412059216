import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import querystring from 'query-string'
import { useDispatch, useSelector } from 'react-redux'

import LocalSpinner from '../../components/LocalSpinner'
import { actions as oauthActions } from '../../actions/Oauth'
import { actions as sidebarActions } from '../../actions/Sidebar'
import { PATHS } from '../../constants'
import  { setStatusOfSuccessfulIntegration } from '../../services/oauth'
import './styles.scss'


export const status = {
  SUCCESS: 'success',
  FAILED: 'fail'
}

const OauthCallback = (props) => {
  const { history } = props
  const qs = querystring.parse(props.location.search)
  const dispatch = useDispatch()
  const { sending = true, connected, error } = useSelector(state => state.OauthConnect.confirmFivetran)
  const {
    id,
  } = qs

  useEffect(() => {
    dispatch(sidebarActions.hideSidebar())
    dispatch(oauthActions.fivetranConfirm({
      connector_id: id
    }))
  }, [dispatch, id])

  useEffect(() => {
    if (!sending && connected) {
      setTimeout(() => {
        setStatusOfSuccessfulIntegration()
        window.location.assign(PATHS.INTEGRATIONS)
      }, 2000)
    }
  }, [connected, history, sending])

  if (error || !id) {
    return (
      <div className='eg-oauth-callback'>
        <div className='mailchimp'/>
        <div className='title'> Oops! Something went wrong.:(</div>
        <div className='description'>Please try again or reach out to us.</div>
      </div>
    )
  }

  if(sending || !connected) {
    return  <LocalSpinner />
  }

  return (
    <div className='eg-oauth-callback'>
      <div className='mailchimp'/>
      <div className='title'> Your account is connected.</div>
      <div className='description'>Redirecting to integration page...</div>
    </div>
  )
}

OauthCallback.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default withRouter(OauthCallback)
