import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import MuiChipInput from 'material-ui-chip-input'

// import deleteIcon from './assets/close.svg'
import './styles.scss'

const chipClasses = {
  root: 'chip-root',
  chipContainer: 'chip-container',
  chip: 'chip'
}

const deleteIcon = (
  <svg className='delete-icon' width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.35167 4.99992L0.676054 1.47132C0.404857 1.21097 0.404857 0.788864 0.676054 0.528514C0.947252 0.268165 1.38695 0.268165 1.65815 0.528514L5.33377 4.05711L9.00939 0.528514C9.28058 0.268165 9.72028 0.268165 9.99148 0.528514C10.2627 0.788864 10.2627 1.21097 9.99148 1.47132L6.31586 4.99992L9.99148 8.52851C10.2627 8.78886 10.2627 9.21097 9.99148 9.47132C9.72028 9.73167 9.28058 9.73167 9.00939 9.47132L5.33377 5.94273L1.65815 9.47132C1.38695 9.73167 0.947252 9.73167 0.676054 9.47132C0.404857 9.21097 0.404857 8.78886 0.676054 8.52851L4.35167 4.99992Z" fill="#A4AEB6"/>
  </svg>
)

/**
 * ChipInput component, allows to input removable items within an input
 *
 * @component
 * @example
 *
 * return (
 *  <ChipInput
 *    value={ values }
 *    onAdd={ handlePhonesChange }
 *    onDelete={ handlePhonesDelete }
 *  />
 * )
 *
*/
const ChipInput = props => {
  const {
    id,
    value,
    onAdd,
    onDelete,
    onInputChange
  } = props

  const handleAdd = value => {
    if (onAdd) onAdd(value)
  }

  const handleDelete = value => {
    if (onDelete) onDelete(value)
  }

  const handleInputChange = event => {
    if (onInputChange) onInputChange(event)
  }

  const chipRenderer = (opts, key) => {
    const { chip, className, handleClick, handleDelete } = opts
    return (
      <Chip
        className={className}
        key={key}
        label={chip}
        deleteIcon={ deleteIcon }
        onClick={handleClick}
        onDelete={handleDelete}
        size="small"
      />
    )
  }

  return (
    <div className='eg-chip-input'>
      <MuiChipInput
        id={ id }
        value={ value }
        classes={ chipClasses }
        disableUnderline
        newChipKeys={ [ ' ', 'Enter' ] }
        chipRenderer={ chipRenderer }
        onDelete={ handleDelete }
        onAdd={ handleAdd }
        onUpdateInput={ handleInputChange }
      />
    </div>
  )
}

ChipInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.array,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onInputChange: PropTypes.func
}

export default ChipInput
