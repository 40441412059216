import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropType from 'prop-types'

import LocalSpinner from '../../../LocalSpinner'
import Search from '../../../Search'

import StoreOption from './StoreOption'
import './styles.scss'

const StoreOptions = ({ selectedStores = [], onStoreOptionClick }) => {
  const { loadingList, storeList = [] } = useSelector(({ Store }) => Store)
  const [ currentStoreList, setCurrentStoreList ]  = useState(storeList)
  const [ searchValue, setSearchValue ] = useState('')
  const [ selectedStoresLookup, setSelectedStoresLookup ] = useState({})

  useEffect(() => {
    // Let's transform the selected store list into an object
    // So we can look up the the specific store that it is selected or not in a
    // constant time( it will help in renderning selected checkboxes fast.)
    //i.e isSelected = selectedStore[uuid] <= O(1) complexity
    let mappedSelectedStores = {}
    selectedStores.forEach(store => {
      mappedSelectedStores[store.uuid] = store
    })
    setSelectedStoresLookup(mappedSelectedStores)
  }, [selectedStores, setSelectedStoresLookup])

  useEffect(() => {
    // When user enters a search string we will filter the new storelist here.
    const newStoreList = storeList.filter((store) => {
      const storeName = store.name && store.name.toLowerCase()
      const searchString = searchValue && searchValue.toLowerCase()
      return storeName.includes(searchString)
    })
    setCurrentStoreList(newStoreList)
  }, [storeList, searchValue, setCurrentStoreList])

  const onSearchValueChange = (value) => {
    setSearchValue(value)
  }

  const storeCheckBoxes = currentStoreList.map((store, index) => {
    const isStoreSelected = !!selectedStoresLookup[store.uuid]
    return (
      <StoreOption
        key={index}
        index={index}
        store={store}
        checked={isStoreSelected}
        onClick={onStoreOptionClick}
      />
    )
  })

  if(loadingList) {
    return (
      <div className='eg-store-option'>
        <LocalSpinner  className='spinner'/>
      </div>
    )
  }

  if(storeList.length === 0) {
    return (
      <div className='eg-store-option'>
        Do not have any stores to select.
      </div>
    )
  }

  return (
    <div className='eg-store-options'>
      <Search
        value={searchValue}
        onChange={onSearchValueChange}
        placeholder={`Search ${storeList.length} stores`}
        className='search'
      />
      <div className='content'>
        { storeCheckBoxes }
      </div>
    </div>
  )
}

StoreOptions.propTypes = {
  selectedStores: PropType.array.isRequired,
  onStoreOptionClick: PropType.func.isRequired,
}

export default StoreOptions
