import React from 'react'
import PropTypes from 'prop-types'
import { formatDate, parseDate } from 'react-day-picker/moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import './date-input.scss'
import styles from './index.module.scss'

import { DAYS_OF_WEEK_SHORT, DATE_FORMAT } from '../../../constants'

/**
 * DateInput - allows the user to select a specific date
 * through a calendar
 *
 * Props:
 * label {string}         - Field label
 * value {string}         - Field value (default value will be current date)
 * placeholder {string}   - text displayed when field is empty
 * error {string}         - validation error string
 * onChange {function}    - callback triggered when component changes
 * onBlur {function}      - callback triggered when component is blured
 */
export default class DateInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    placeholder: PropTypes.string,
    disablePrevDays: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    value: new Date(),
    placeholder: 'Select a date',
    disablePrevDays: true,
    className: ''
  }

  constructor(props) {
    super(props)

    // bindings
    this.handleCalendarChange = this.handleCalendarChange.bind(this)

    // initial state
    this.state = {
      selectedDay: new Date()
    }
  }

  /**
   * handle calendar change
   *
   * @param day {Date} - selected day
   */
  handleCalendarChange(day) {
    this.setState({
      selectedDay: day
    })
    if (this.props.onChange) {
      this.props.onChange(day)
    }
  }

  /**
   * handle calendar input blur event
   *
   * @param event {object}  - element event
   */
  handleBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  render() {
    const today = new Date()
    const disabledDaysOps = this.props.disablePrevDays ? { before: today } : null
    return (
      <div className={ `datePicker ${styles.container} ${this.props.className}` } data-testid='date-picker'>
        { this.props.label ? <div className={ styles.label }>{ this.props.label }</div> : null }
        <DayPickerInput
          value={ this.props.value }
          formatDate={ formatDate }
          parseDate={ parseDate }
          format={ DATE_FORMAT }
          placeholder={ 'Date' }
          dayPickerProps={{
            weekdaysShort: DAYS_OF_WEEK_SHORT,     // display custom weekdays
            showOutsideDays: true,                 // display days of other months
            selectedDays: this.state.selectedDay,  // selected day
            disabledDays: disabledDaysOps,         // do not allow to select previous days
            onDayClick: this.handleCalendarChange, // tiriggered when a day is clicked
          }}
          inputProps={{
            readOnly: true
          }}
          onBlur={ this.handleBlur }
        />
        {this.props.error ? <div className={styles.errorMessage}>{this.props.error}</div> : null}
      </div>
    )
  }
}
