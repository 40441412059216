import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'

import Interaction from './Interaction'
import LocalSpinner from '../../LocalSpinner'

import './styles.scss'

const EVENTS_PER_PAGE = 10

const CustomerInteractions = props => {
  const {
    loading = false,
    customerInteractions = {}
  } = props

  const { interactions = [], rawActivites = [] } = customerInteractions

  const getMore = page => {
    if (props.onPageChange) {
      props.onPageChange(page, EVENTS_PER_PAGE)
    }
  }

  const hasMorePages = () => {
    if (loading) return false
    const total = customerInteractions.total
    return total > rawActivites.length
  }

  return (
    <div className='eg-customer-interactions' data-testid='customer-interactions'>
      <div className='timeline'>
        <InfiniteScroll
          pageStart={ 1 }
          loadMore={ getMore }
          hasMore={ hasMorePages() }
          className="eg-event-timeline"
          threshold= { 300 }
        >
          {
            interactions.map((activityItem, index) => {
              return <Interaction item={ activityItem }  key={ index } />
            })
          }
          { loading && <LocalSpinner className='spinner' /> }
        </InfiniteScroll>
        {/* this.renderEmptyList() */}
      </div>
      {/* <EventSummary /> */}
    </div>
  )
}

CustomerInteractions.propTypes = {
  customerInteractions: PropTypes.object,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func
}

export default CustomerInteractions
