import _ from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'

import { NetworkError } from '../services/error'
import { authorizedRequest } from './Auth'
import { types, actions } from '../actions/Channel'
import { getChannels } from '../api/Channel'
import { mapChannelIn, mapChannelsTotalIn } from '../services/channels'

export function* getChannelsHandler({ opts = {} }) {
  const chainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  const { selectedStores } = yield select(({ Filter }) => Filter)
  try {
    const res = yield call(authorizedRequest, getChannels, {
      ...opts,
      chainUuid,
      selectedStores
    })
    if (res && res.data) {
      const mappedChannelsData = {
        ...res.data,
        total: mapChannelsTotalIn(_.get(res, 'data.total')),
        channels: res.data.channels.map(c => mapChannelIn(c)),
        has_channels: true, // TODO remove this line when we have the data
        filters: opts.filters
      }
      yield put(actions.getChannelsResolved(mappedChannelsData))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getChannelsRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.GET_CHANNELS_INTENT, getChannelsHandler)
}
