export const INTEGRATION_STATUS = {
  CONNECTED: 'CONNECTED',
  NOT_CONNECTED: 'NOT_CONNECTED'
}

/**
 * Keys that we get in the response from backend,
 * along with the status of the integration, wether it is connected or not. 
 * i.e { plaid_status: 'NOT_CONNECTED', ...}
 */
export const INTEGRATION_KEYS = {
  PLAID: 'plaid_status',
  SQUARE: 'square_status',
  SHOPIFY: 'shopify_status',
  FACEBOOK: 'facebook_status',
  CONSTANT_CONTACT: 'constant_contact_status',
}
