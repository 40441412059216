import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { actions as oauthActions } from '../../../../actions/Oauth'
import { oauthProviders } from '../../../../services/auth'
import { Button } from '../../../FormComponents'
import './styles.scss'

const CloverConnect = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)
  const dispatch = useDispatch()
  const chain = useSelector(state => state.User.chain)

  const handleSubmitClick = () => {
    dispatch(oauthActions.authorize({
      clientId: process.env.REACT_APP_CLOVER_APP_ID,
      url: `${process.env.REACT_APP_CLOVER_BASE_URL}/oauth/authorize`,
      chainUuid: chain.uuid,
      provider: oauthProviders.CLOVER
    }))

    if (props.onAuthorize) {
      props.onAuthorize()
    }
  }

  const isConnected = _.get(connections, 'length') && !!connections.find(c => c.provider === 'clover')

  return (
    <div className='eg-clover-connect'>
      <div className='logo'>
        { isConnected && <div className='check'></div> }
      </div>
      <div className='info-title'>
        { isConnected ? 'Connected' : 'Clover Connect' }
      </div>
      <div className='info'>
        <ul className='description'>
          <li>
            <span className='list-icon icon-sync'></span>
            Sync your customer contacts information
          </li>
        </ul>
        <div className='action'>
          <Button
            id='clover-connect'
            className='connect-btn'
            text={ isConnected ? 'Connect with a different account' : 'Connect with Clover' }
            onClick={ handleSubmitClick }
          />
        </div>
      </div>
    </div>
  )
}

CloverConnect.propTypes = {
  isConnected: PropTypes.bool,
  onAuthorize: PropTypes.func
}

export default CloverConnect
