import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const placeholders = {
  firstName: '',
  lastName: '',
  email: 'Enter email address',
  phone: '',
  birthday: 'YYYY-MM-DD'
}

const CustomerForm = () => {
  const renderInput = (fieldName, label) => {
    return (
      <div className='row'>
        <label htmlFor={ fieldName }>{ label }</label>
        <div className={fieldName} >
          <input
            name={ fieldName }
            id={ fieldName }
            placeholder={ placeholders[fieldName]}
            disabled
          />
        </div>
      </div>
    )
  }

  return (
    <div className='eg-loyalty-customer-form'>
      <div className='title'>Edit member details</div>
      <div className='form'>
        { renderInput('firstName', 'First name') }
        { renderInput('lastName', 'Last name') }
        { renderInput('email', 'Email') }
        { renderInput('phone', 'Phone number') }
        { renderInput('birthday', 'Birthdate (OPTIONAL)') }
        <div className='actions'>
          <button
            type='submit'
            className='submit'
          >JOIN NOW </button>
        </div>
      </div>
    </div>
  )
}

CustomerForm.propTypes = {
  onSubmit: PropTypes.func,
  toggleSuccessCard: PropTypes.func,
  phone: PropTypes.string
}

export default CustomerForm
