import React from 'react'
import PropTypes from 'prop-types'

import FileUploader from '../../FileUploader'
import './styles.scss'

const UploadUI = ({ onFileUpload, fileType='', multipleFile, fileText='' }) => {
  return (
    <FileUploader 
      onFileUpload={onFileUpload}
      className="upload-container" 
      fileType={fileType}
      multiple={multipleFile}
    >
      <div className="doc-logo"><div className="doc" /></div>
      <div className="title">Drag & drop file here</div>
      <div className="paragraph">{fileText}</div>
      <div className="browse-button">BROWSE</div>
    </FileUploader>
  )
}

UploadUI.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  fileType: PropTypes.string,
  fileText: PropTypes.string,
  multipleFile: PropTypes.bool,
}

export default UploadUI
