import React from 'react'
import PropTypes from 'prop-types'

import { userRoles } from '../../../../services/auth'
import AccessControlledComponent from '../../../AccessControlledComponent'
import AddItem from '../../../AddItem'
import styles from './index.module.scss'

const StoreProfileCard = props => {
  const handleClick = () => {
    props.onEdit()
  }

  return (
    <div className={ styles.profileCardContainer }>
      <h3>{ props.title }</h3>
      <p>{ props.description }</p>
      <AccessControlledComponent roles={ [userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE] }>
        <div className={ styles.addItemWrapper } onClick={ handleClick }>
          <AddItem label={ props.addItemLabel } />
        </div>
      </AccessControlledComponent>
    </div>
  )
}

StoreProfileCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  addItemLabel: PropTypes.string,
  onEdit: PropTypes.func
}

export default StoreProfileCard
