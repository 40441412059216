import axios from 'axios'
import { METHODS, HeaderFactory, constructStoreQuery } from './utils'

const DEFAULT_SORT_ORDER = { sortKey: 'order_date', order: 'desc'}

/**
 * get list of orders
 *
 * @param {number} page - page number to request
 * @param {number} qty - how many elements per page
 * @param {object} tokens - authentication tokens
 */
export const getOrders = (opts, tokens) => {
  let {
    // restaurantId,
    // restaurantUuid,
    restaurantChainUuid,
    selectedStores,
    page,
    status,
    search,
    customerId,
    startDate,
    endDate,
    sortColumnData,
  } = opts
  if (!page) {
    page = 1
  }

  const storeInfo = {restaurantChainUuid, selectedStores}
  // To support legacy api calls. we might remove the restaurant_id in the future from query parameters.
  // if it's not being used anywhere
  const storeParams = [...constructStoreQuery(storeInfo)]
  
  const sortColumnDataQueryParameters = sortColumnData ? [`order[0][0]=${sortColumnData.sortKey}`,
    `order[0][1]=${sortColumnData.order}`,] : [`order[0][0]=${DEFAULT_SORT_ORDER.sortKey}`, `order[0][1]=${DEFAULT_SORT_ORDER.order}`]
  
  const qparams = [
    ...sortColumnDataQueryParameters,
    ...storeParams,
    `page=${page}`
  ]
  if (status) {
    qparams.push(`status=${status}`)
  }
  if (search) {
    qparams.push(`search=${search}`)
  }
  if (startDate && endDate) {
    qparams.push(`paginated=false&start_date=${startDate}&end_date=${endDate}`)
  }
  if (customerId) {
    qparams.push(`customer_id=${customerId}`)
    return axios({
      url: `${process.env.REACT_APP_CUSTOMER_ACTIVITY_URL}/customers/activities?sources[0]=square&sources[1]=partner_orders&${qparams.join('&')}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens)
    })
  }
  return axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/orders?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get Order by id
 *
 * @param {object} order
 */
export const getOrderById = ({ order }, tokens) => {
  const { source_id, restaurant={} } = order
  const qparams = [`restaurant_uuid=${restaurant.uuid}`]
  return axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/orders/${source_id}?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
