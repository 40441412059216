import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Oauth'
import { authorizedRequest } from './Auth'
import { actions as oauthActions } from '../actions/Oauth'
import { NetworkError } from '../services/error'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import {
  authorize,
  connect,
  fivetranConnect,
  confirmFivetranConnect,
  getFivetranConnections,
  customerConnect,
  getActiveConnections,
  saveToastCredential
} from '../api/Oauth'

function* oauthAuthorize({ opts }) {
  try {
    yield call(authorize, opts)
    yield put(oauthActions.getActiveConnections())
  } catch (error) {
    yield put(actions.authorizeRejected(new NetworkError(error)))
  }
}

/**
 * NOTE: later, when we migrate other integrations,  this method
 * will be deprecated in favor of * `oauthCustomerConnect()`
 * defined below.
*/
function* oauthConnect({ opts }) {
  try {
    yield call(authorizedRequest, connect, opts)
    yield put(actions.oauthConnectResolved())
  } catch(e) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: e.message || 'Something went wrong. Please try again.'
    }
    console.error(e)
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.oauthConnectRejected(e))
  }
}

function* handleFivetranConnect({ opts }) {
  try {
    const response = yield call(authorizedRequest, fivetranConnect, opts)
    if(response && response.data) {
      window.location.href = response.data.redirect_uri
    }
  } catch(e) {
    const notification = {
      type: EgNotificationTypes.WARNING,
      message: 'Your connection is not configured yet, please contact your administrator.'
    }
    console.error(e)
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.fivetranConnectRejected(e))
  }
}

function* handleFivetranConfirm({ opts }) {
  const chainUuid = yield select(state => state.User.chain.uuid)
  try {
    const response = yield call(authorizedRequest, confirmFivetranConnect,
      {
        ...opts,
        chainUuid
      })
    if(response && response.data) {
      yield put(actions.fivetranConfirmResolved(response.data))
    }
  } catch(e) {
    yield put(actions.fivetranConfirmRejected(e))
  }
}

function* handleGetFivetranConnections({ opts }) {
  const chainUuid = yield select(state => state.User.chain.uuid)
  try {
    const response = yield call(authorizedRequest, getFivetranConnections,
      {
        chainUuid
      })
    if(response && response.data) {
      yield put(actions.getFivetranConnectionsResolved(response.data))
    }
  } catch(e) {
    yield put(actions.getFivetranConnectionsRejected(e))
  }
}

function* oauthCustomerConnect({ opts }) {
  try {
    yield call(authorizedRequest, customerConnect, opts)
    yield put(actions.oauthConnectResolved())
  } catch(e) {
    yield put(actions.oauthConnectRejected(e))
  }
}

function* oauthGetActiveConnections() {
  try {
    const chainUuid = yield select(state => state.User.chain.uuid)
    const result = yield call(authorizedRequest, getActiveConnections, { chainUuid })
    yield put(actions.getActiveConnectionsResolved(result.data))
  } catch(e) {
    yield put(actions.getActiveConnectionsRejected(e))
  }
}

function* handleSaveToastCredential({ opts }) {
  try {
    const result = yield call(authorizedRequest, saveToastCredential, opts)
    yield put(actions.sendToastCredIntentResolved(result.data))
    yield put(oauthActions.getActiveConnections())
  } catch(error) {
    yield put(actions.sendToastCredIntentRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.OAUTH_AUTHORIZE, oauthAuthorize)
  yield takeLatest(types.OAUTH_CONNECT_INTENT, oauthConnect)
  yield takeLatest(types.FIVETRAN_CONNECT_INTENT, handleFivetranConnect)
  yield takeLatest(types.FIVETRAN_CONFIRM_INTENT, handleFivetranConfirm)
  yield takeLatest(types.FIVETRAN_CONNECTIONS_INTENT, handleGetFivetranConnections)
  yield takeLatest(types.OAUTH_CUSTOMER_CONNECT_INTENT, oauthCustomerConnect)
  yield takeLatest(types.OAUTH_ACTIVE_CONNECTIONS_INTENT, oauthGetActiveConnections)
  yield takeLatest(types.TOAST_CRED_INTENT, handleSaveToastCredential)
}
