import React from 'react'
import PropTypes from 'prop-types'

import { TextArea } from '../../../../FormComponents'
import { renderInput } from '../../../../FormComponents/utils'
import PhonePreview, { HeaderBrowser } from '../../../../PhonePreview'
import { UPLOAD_FILE_TYPE } from '../../../../../services/upload'
import LogoField from '../../LogoField'
import Review from '../RatingPreviews/Review'
import './styles.scss'

export const fields = {
  HEADLINE: 'headline',
  SUBTEXT: 'subtext'
}

const RatingPage = props => {
  const { validator, store, values, visible } = props  
  const { register, errors } = validator

  return (
    <div className={`eg-rating-page ${visible ? '' : 'hidden'}`}>
      <div className='form-wrapper'>
        <div className='title'>Rating Page</div>
        <div className='form'>
          <LogoField logo={ store.rating_logo } store={ store } logoType={UPLOAD_FILE_TYPE.RATING_LOGO}/>
          <div className='field'>
            <label className='label' htmlFor={ fields.HEADLINE }  data-testid='rating-headline'>Headline</label>
            {
              renderInput(fields.HEADLINE, validator)
            }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.SUBTEXT } data-testid='rating-subtext'>Subtext</label>
            <TextArea
              id={ fields.SUBTEXT }
              name={ fields.SUBTEXT }
              className={ `textarea ${errors[fields.SUBTEXT] ? 'error' : ''}` }
              forwardedRef={ register({ required: true }) }
            />
            { errors[fields.SMS_TEXT] && <div className='error-msg'>{ errors[fields.SUBTEXT].message }</div> }
          </div>
        </div>
      </div>
      <div className='preview' data-testid='rating-preview-page'>
        <div className='title'>PREVIEW</div>
        <PhonePreview className='phone'>
          <HeaderBrowser searchbarLabel='zerostorefront.com' />
          <Review
            logoUrl= {store.rating_logo}
            headline= {values[fields.HEADLINE]}
            subtext= {values[fields.SUBTEXT]}
          />
        </PhonePreview>
      </div>
    </div>
  )
}

RatingPage.propTypes = {
  visible: PropTypes.bool.isRequired,
  validator: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
}

export default RatingPage
