import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Button, { types as buttonTypes } from '../../../FormComponents/Button'
import { actions } from '../../../../actions/Campaign'
import { parsePreview } from '../../../../services/campaigns'
import { zsfSmsFooter, statuses } from '../../../../services/journeys'
import { userHasAccess } from '../../../../services/auth'
import EGAnalytic, { analyticEvents } from '../../../../services/analytics'
import TestMessage from '../../../Campaigns/TestMessage'
import AccessControlledComponent from '../../../AccessControlledComponent'
import StatusBubble from '../../../StatusBubble'
import { fields as setupFields } from '../Setup'

import './styles.scss'

const composeMessage = msg => {
  if (msg.include_reply_footer) {
    return `${msg.text}
    ${zsfSmsFooter()}`
  }
  return msg.text
}

/**
 * ReviewJourney - Step 3 of create journey flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const submitting = ... // some submitting condition
 * const data = {
 *  journey_name: 'My journey'
 * }
 *
 * return (
 *  <ReviewJourney
 *    data={ data }
 *    submitting={ submitting }
 *    onEditMessageClick={ () => switchToMessageStep() }
 *    onEditTriggerClick={ () => switchToTriggerStep() }
 *    onSubmit={ handleSubmit }
 *  />
 * )
*/
const ReviewJourney = props => {
  const {
    data,
    submitting = false,
    editable = true,
    displayStatus = false,
    hideDeactivateButton=false,
    onEditMessageClick,
    onEditTriggerClick,
    onSubmit
  } = props
  const {
    submittingTestMsg,
    testMsgSubmitted: testMsgSent
  } = useSelector(state => state.Campaign)
  const chain = useSelector(state => state.User.chain)
  const user = useSelector(state => state.User.user)
  const dispatch = useDispatch()
  const [displayTestSmsModal, setDisplayTestSmsModal] = useState(false)
  const [testMsgSubmitted, setTestMsgSubmitted] = useState(false)
  const [selectedTestMessage, setSelectedTestMessage] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!testMsgSent) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_WARNING_DISPLAYED, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Journeys',
        messageContextStep: 'Review',
      })
    }
  }, [chain, testMsgSent])

  useEffect(() => {
    if (testMsgSubmitted && !submittingTestMsg) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_SENT, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Journeys',
        messageContextStep: 'Review',
      })
      setDisplayTestSmsModal(false)
    }
  }, [chain, submittingTestMsg, testMsgSubmitted])

  const handleEditTriggerClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Review',
      stepAbandonType: 'EDIT TRIGGER'
    })
    if (onEditTriggerClick) onEditTriggerClick()
  }

  const handleEditMessageClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Review',
      stepAbandonType: 'EDIT MESSAGE'
    })
    if (onEditMessageClick) onEditMessageClick()
  }

  const handleBackClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Review',
      stepAbandonType: 'BACK'
    })
    if (onEditMessageClick) onEditMessageClick()
  }

  const handleTestMessageClick = (message) => {
    EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Journeys',
      messageContextStep: 'Review',
    })
    setDisplayTestSmsModal(true)
    setSelectedTestMessage(message)
  }

  const handleTestMessageClose = () => {
    setDisplayTestSmsModal(false)
  }

  const handleTestMessageSubmit = numbers => {
    if (!selectedTestMessage) return
    const message = composeMessage(selectedTestMessage)
    dispatch(actions.sendTestMessage({
      numbers,
      message,
      imageUrl: _.get(selectedTestMessage, 'mms.imageUrl')
    }))
    setTestMsgSubmitted(true)
    setSelectedTestMessage(undefined)
  }

  const handleSubmit = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_COMPLETED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Review',
      messageCount: data['journey_messages'].length,
      messageDelays: data['journey_messages'].map((message) => message.time),
    })
    if (onSubmit) {
      onSubmit('review', data)
    }
  }

  const renderWarningMessage = () => {
    if (testMsgSent) return
    return (
      <div className='alert'>
        We recommend testing your message before sending by clicking the 'Send test message'
        button inside the message preview below.
      </div>
    )
  }

  const renderActionButton = () => {
    if (!userHasAccess(user, [])) {
      return null
    }

    return  (
      <Button
        className='action-button'
        text={ `${data.status === statuses.ACTIVE ? 'DEACTIVATE' : 'ACTIVATE NOW'}` }
        type={ data.status === statuses.ACTIVE ? buttonTypes.SECONDARY : buttonTypes.PRIMARY }
        loading={submitting}
        onClick={handleSubmit}
      />

    )
  }

  return (
    <div className='eg-create-journey-review'>
      <div className={ `step-body ${!editable ? 'readonly' : ''}` }>
        <div className='title'>
          { editable ? 'Review your journey before launching' : `${chain.name} | ${data.journey_name}` }
          {
            displayStatus && !!data.status && (
              <StatusBubble label={ data.status } status={ data.status } className='status-bbl' />
            )
          }
        </div>
        <div className='subtitle'>Please review your journey details before sending</div>
        {renderWarningMessage()}
        <div className='trigger'>
          <div className='details'>
            <div className='title'>Journey trigger</div>
            <div className='trigger'>
              {_.get(data, 'journey_trigger.label')}
              <div className='trigger-id'>{ data.journey_trigger_id }</div>
            </div>
          </div>
          <AccessControlledComponent roles={[]}>
            <div className='action'>
              <Button
                className='btn-edit'
                type={buttonTypes.SECONDARY}
                text='EDIT TRIGGER'
                onClick={ handleEditTriggerClick }
              />
            </div>
          </AccessControlledComponent>
        </div>
        <div className='review'>
          <div className='details'>
            <div className='title'>Journey message</div>
            <div className='msgs-container'>
              {
                !!data.journey_messages && data.journey_messages.map((message, i) => {
                  const prevMsg = composeMessage(message)
                  let label = `${message.time} ${_.get(message, 'unit.label', '').toLowerCase()}`
                  if (parseInt(message.time) === 0) {
                    label = 'Immediately'
                  }
                  if (parseInt(message.time) === 1) {
                    label = label.trim().replace(/s$/, '')
                  }
                  return (
                    <div key={`msg-preview-${i}`}>
                      <span className='msg-time'>
                        { parseInt(message.time) !== 0 ? i === 0 ? 'Wait ' : 'Then ' : '' }
                        { label }
                      </span>
                      <div className='msg-preview'>
                        <div
                          className='message'
                          dangerouslySetInnerHTML={{ __html: parsePreview(prevMsg, chain) }}
                        />
                        <div className='test-msg-action'>
                          <Button
                            className='preview-test-btn'
                            type={buttonTypes.SECONDARY}
                            text='SEND TEST MESSAGE'
                            onClick={() => handleTestMessageClick(message)}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <AccessControlledComponent roles={[]}>
            <div className='action'>
              <Button
                className='btn-edit'
                type={buttonTypes.SECONDARY}
                text='EDIT MESSAGE'
                onClick={ handleEditMessageClick }
              />
            </div>
          </AccessControlledComponent>
        </div>
      </div>
      <div className='actions'>
        {
          editable && (
            <Button
              className='action-button'
              text='BACK'
              type={buttonTypes.SECONDARY}
              onClick={handleBackClick}
            />
          )
        }
        { hideDeactivateButton
          ? ( data.status === statuses.INACTIVE && renderActionButton() )
          : renderActionButton()
        }
      </div>
      {
        displayTestSmsModal && (
          <TestMessage
            submitting={submittingTestMsg}
            step='Review'
            flowName='Journeys'
            onClose={handleTestMessageClose}
            onSubmit={handleTestMessageSubmit}
          />
        )
      }
    </div>
  )
}

ReviewJourney.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  editable: PropTypes.bool,
  displayStatus: PropTypes.bool,
  hideDeactivateButton: PropTypes.bool,
  onEditTriggerClick: PropTypes.func,
  onEditMessageClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ReviewJourney
