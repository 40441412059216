import React, { useState, useEffect } from 'react'
import PropType from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import FilterModal from '../../FilterModal'
import { actions } from '../../../actions/Filter'

import StoreOptions from './StoreOptions'
import FilterFooter from './FilterFooter'
import './styles.scss'

const StoreFilter = ({ onFilterChange, className='' }) => {
  const dispatch = useDispatch()
  const [showModal, toggleModal] = useState(false)
  const storedSelectedStore = useSelector(({ Filter }) => Filter.selectedStores)
  const [selectedStores, setSelectedStores] = useState(storedSelectedStore)

  // Update selected stores in the filter component,
  // when it gets updated in redux store.
  useEffect(() => {
    setSelectedStores(storedSelectedStore)
  }, [storedSelectedStore])

  const onStoreOptionClick = (store) => {
    if (selectedStores.find(s => s.uuid === store.uuid)) {
      setSelectedStores(selectedStores.filter(ss => ss.uuid !== store.uuid))
    } else {
      setSelectedStores([ ...selectedStores, store ])
    }
  }

  const openFilterModal = () => {
    toggleModal(true)
  }

  const handleCallback = () => {
    onFilterChange()
  }

  const onFilterApply = () => {
    dispatch(actions.setStoreFilter(selectedStores))
    toggleModal(false)
    handleCallback()
  }

  const onFilterClear = () => {
    dispatch(actions.setStoreFilter([]))
    toggleModal(false)
    handleCallback()
  }

  const totalSelectedStore = selectedStores.length
  const containerClass = showModal || totalSelectedStore
    ? `eg-store-filter ${className} eg-selected`
    : `eg-store-filter ${className}`

  const renderFilterModal = () => {
    if(showModal) {
      return (
        <FilterModal className='filter' onClose={onFilterApply}>
          <StoreOptions
            selectedStores={selectedStores}
            onStoreOptionClick={onStoreOptionClick}
          />
          <FilterFooter
            showClear={!!totalSelectedStore}
            onClear={onFilterClear}
            onApply={onFilterApply}
          />
        </FilterModal>
      )
    }
    return null
  }

  return (
    <div className={containerClass} onClick={openFilterModal} data-testid='store-filter'>
      <div className='button'>
        <div className='logo' />
        {totalSelectedStore ? 'Stores' : 'All Stores'}
        {totalSelectedStore ? <div className='count' data-testid='store-count'>{ totalSelectedStore }</div> : '' }
      </div >
      {renderFilterModal()}
    </div>
  )
}

StoreFilter.propTypes = {
  onFilterChange: PropType.func.isRequired,
  className: PropType.string.isRequired,
}

export default StoreFilter
