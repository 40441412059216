import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import IntegrationCard from '../../Store/StoreIntegration/IntegrationCard'
import ToastConnect from './ToastConnect'
import './styles.scss'

const ToastIntegration = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(ToastConnect)
    }
  }

  const isConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  return (
    <div className='eg-toast-integration'>
      <IntegrationCard
        title='Toast'
        subtitle='Connect your contacts and orders'
        logoClassName='toast'
        isConnected={ isConnected('toast') }
        onClick={ handleClick }
      />
    </div>
  )
}

ToastIntegration.propTypes = {
  onClick: PropTypes.func
}

export default ToastIntegration
