import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const ImagePreview = props => {
  const { image, uploading = false, onDelete } = props

  return (
    <div className={ 'eg-addons-image-preview' }>
      <div className='delete' onClick={ () => onDelete() }>
        <div /><div />
      </div>
      <div className={ `dark-background ${uploading ? 'uploading' : ''}` } />
      {
        uploading && (
          <div className='loader' />
        )
      }
      <div className='img' style={{ backgroundImage: `url(${image})` }} />
    </div>
  )
}

ImagePreview.propTypes = {
  image: PropTypes.string,
  uploading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
}

export default ImagePreview
