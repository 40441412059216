import React from 'react'
import PropTypes from  'prop-types'
import { withRouter } from 'react-router-dom'

import './styles.scss'


const MainAutomationCard = (props) => {
  const { title, type, subtitle, path, isTurnedOn } = props

  const onAddClick = () => {
    window.location.hash = path
  }

  return (
    <div className='eg-mainautomation-card'  onClick={onAddClick}>
      <div className='wrapper'>
        <div className={type} />
      </div>
      <footer className='footer'>
        <div className='row'>
          <div className='title'>{ title }</div>
          <div className='subtitle'> { subtitle }</div>
        </div>
        <div className='add'>
          {isTurnedOn ? 'Update' : 'Add'}
        </div>
      </footer>
    </div>
  )
}

MainAutomationCard.propTypes = {
  isTurnedOn: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default withRouter(MainAutomationCard)
