import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Call'

import { authorizedRequest } from './Auth'
import { getCalls } from '../api/Call'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

import { mapCallsIn } from '../services/calls'

/**
 * Get calls
 *
*/
function* getCallsHandler({ opts }) {
  try {
    const { chainUuid } = yield select(state => {
      if (state.User.chain) {
        return {
          chainUuid: state.User.chain.uuid
        }
      }
    })
    const { selectedStores } = yield select(({ Filter }) => Filter)

    const res = yield call(authorizedRequest, getCalls, {
      ...opts,
      chainUuid,
      selectedStores
    })

    if (res && res.data) {
      const calls = mapCallsIn(res.data.logs)
      const { page, size, total } = res.data
      yield put(actions.getCallsResolved({
        page,
        size,
        total,
        items: calls
      }))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Unknown error'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getCallsRejected(error)) 
  }
}

export default function* () {
  yield takeLatest(types.GET_CALLS_INTENT, getCallsHandler)
}
