import { journeyTypes } from '../services/journeys'
import { campaignTypes } from '../services/campaigns'

export const types = {
  CREATE_SEGMENT_INTENT: 'CREATE_SEGMENT_INTENT',
  CREATE_SEGMENT_INTENT_RESOLVED: 'CREATE_SEGMENT_INTENT_RESOLVED',
  CREATE_SEGMENT_INTENT_REJECTED: 'CREATE_SEGMENT_INTENT_REJECTED',
  CREATE_SEGMENT_CLEAR: 'CREATE_SEGMENT_CLEAR',

  GET_SEGMENTS_INTENT: 'GET_SEGMENTS_INTENT',
  GET_SEGMENTS_INTENT_RESOLVED: 'GET_SEGMENTS_INTENT_RESOLVED',
  GET_SEGMENTS_INTENT_REJECTED: 'GET_SEGMENTS_INTENT_REJECTED',

  SET_PREDICATES: 'SET_PREDICATES',

  GET_SEGMENT_CAMPAIGNS_INTENT: 'GET_SEGMENT_CAMPAIGNS_INTENT',
  GET_SEGMENT_CAMPAIGNS_INTENT_RESOLVED: 'GET_SEGMENT_CAMPAIGNS_INTENT_RESOLVED',
  GET_SEGMENT_CAMPAIGNS_INTENT_REJECTED: 'GET_SEGMENT_CAMPAIGNS_INTENT_REJECTED',

  GET_SEGMENT_JOURNEYS_INTENT: 'GET_SEGMENT_JOURNEYS_INTENT',
  GET_SEGMENT_JOURNEYS_INTENT_RESOLVED: 'GET_SEGMENT_JOURNEYS_INTENT_RESOLVED',
  GET_SEGMENT_JOURNEYS_INTENT_REJECTED: 'GET_SEGMENT_JOURNEYS_INTENT_REJECTED',

  // dynamic segments
  UPSERT_DYNAMIC_SEGMENT_INTENT: 'UPSERT_DYNAMIC_SEGMENT_INTENT',
  UPSERT_DYNAMIC_SEGMENT_INTENT_RESOLVED: 'UPSERT_DYNAMIC_SEGMENT_INTENT_RESOLVED',
  UPSERT_DYNAMIC_SEGMENT_INTENT_REJECTED: 'UPSERT_DYNAMIC_SEGMENT_INTENT_REJECTED',

  GET_DYNAMIC_SEGMENT_INTENT: 'GET_DYNAMIC_SEGMENT_INTENT',
  GET_DYNAMIC_SEGMENT_INTENT_RESOLVED: 'GET_DYNAMIC_SEGMENT_INTENT_RESOLVED',
  GET_DYNAMIC_SEGMENT_INTENT_REJECTED: 'GET_DYNAMIC_SEGMENT_INTENT_REJECTED',

  CLEAR_DYNAMIC_SEGMENT: 'CLEAR_DYNAMIC_SEGMENT',
}

export const actions = {
  createSegment(data) {
    return {
      type: types.CREATE_SEGMENT_INTENT,
      data
    }
  },

  createSegmentResolved(data) {
    return {
      type: types.CREATE_SEGMENT_INTENT_RESOLVED,
      data
    }
  },

  createSegmentRejected(error) {
    return {
      type: types.CREATE_SEGMENT_INTENT_REJECTED,
      error
    }
  },

  createSegmentClear() {
    return {
      type: types.CREATE_SEGMENT_CLEAR
    }
  },

  getSegments(data) {
    return {
      type: types.GET_SEGMENTS_INTENT,
      data
    }
  },

  getSegmentsResolved(data) {
    return {
      type: types.GET_SEGMENTS_INTENT_RESOLVED,
      data
    }
  },

  getSegmentsRejected(error) {
    return {
      type: types.GET_SEGMENTS_INTENT_REJECTED,
      error
    }
  },

  getCampaignsForDynamicSegment(data) {
    return {
      type: types.GET_SEGMENT_CAMPAIGNS_INTENT,
      data: {
        ...data,
        status: campaignTypes.DELIVERED,
        size: 999
      }
    }
  },

  getCampaignsForDynamicSegmentResolved(data) {
    return {
      type: types.GET_SEGMENT_CAMPAIGNS_INTENT_RESOLVED,
      data
    }
  },

  getCampaignsForDynamicSegmentRejected(error) {
    return {
      type: types.GET_SEGMENT_CAMPAIGNS_INTENT_REJECTED,
      error
    }
  },

  getJourneysForDynamicSegment(data) {
    return {
      type: types.GET_SEGMENT_JOURNEYS_INTENT,
      data: {
        ...data,
        status: journeyTypes.ACTIVE,
        size: 999
      }
    }
  },

  getJourneysForDynamicSegmentResolved(data) {
    return {
      type: types.GET_SEGMENT_JOURNEYS_INTENT_RESOLVED,
      data
    }
  },

  getJourneysForDynamicSegmentRejected(error) {
    return {
      type: types.GET_SEGMENT_JOURNEYS_INTENT_REJECTED,
      error
    }
  },

  setPredicates(data) {
    return {
      type: types.SET_PREDICATES,
      data
    }
  },

  // dynamic segments
  upsertDynamicSegment(data, step) {
    return {
      type: types.UPSERT_DYNAMIC_SEGMENT_INTENT,
      data,
      step
    }
  },

  upsertDynamicSegmentResolved(data) {
    return {
      type: types.UPSERT_DYNAMIC_SEGMENT_INTENT_RESOLVED,
      data
    }
  },

  upsertDynamicSegmentRejected(error) {
    return {
      type: types.UPSERT_DYNAMIC_SEGMENT_INTENT_REJECTED,
      error
    }
  },

  getDynamicSegment(id) {
    return {
      type: types.GET_DYNAMIC_SEGMENT_INTENT,
      id
    }
  },

  getDynamicSegmentResolved(data) {
    return {
      type: types.GET_DYNAMIC_SEGMENT_INTENT_RESOLVED,
      data
    }
  },

  getDynamicSegmentRejected(error) {
    return {
      type: types.GET_DYNAMIC_SEGMENT_INTENT_REJECTED,
      error
    }
  },

  clearDynamicSegment() {
    return {
      type: types.CLEAR_DYNAMIC_SEGMENT
    }
  }
}
