import { types } from '../actions/Filter'

const initialState = {
  selectedStores: []
}

export default (state = initialState, action) => {
  switch (action.type) {
      
    case types.SET_STORE_FILTER:
      return {
        ...state,
        selectedStores: action.data
      }

    default:
      return state
  }
}
