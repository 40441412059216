import { fork, all } from 'redux-saga/effects'
import Login from './Login'
import Notifications from './Notifications'
import Dashboard from './Dashboard'
import Order from './Order'
import Customer from './Customer'
import Call from './Call'
import VirtualNumber from './VirtualNumber'
import Chain from './Chain'
import GoogleConnect from './GoogleConnect'
import SquareConnect from './SquareConnect'
import Review from './Review'
import User from './User'
import Plaid from './Plaid'
import Event from './Event'
import Store from './Store'
import Upload from './Upload'
import Oauth from './Oauth'
import Channel from './Channel'
import Interaction from './Interaction'
import LoyaltyIvr from './LoyaltyIvr'
import Campaign from './Campaign'
import Journey from './Journey'
import Segment from './Segment'
import SMS from './SMS'
import Report from './Report'

export default function* () {
  yield all([
    fork(Login),
    fork(Notifications),
    fork(Dashboard),
    fork(Order),
    fork(Customer),
    fork(Call),
    fork(VirtualNumber),
    fork(Chain),
    fork(GoogleConnect),
    fork(User),
    fork(SquareConnect),
    fork(Review),
    fork(Plaid),
    fork(Event),
    fork(Store),
    fork(Upload),
    fork(Oauth),
    fork(Channel),
    fork(Interaction),
    fork(LoyaltyIvr),
    fork(Campaign),
    fork(Journey),
    fork(Segment),
    fork(SMS),
    fork(Report),
  ])
}
