import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

// import { getMockChainInfoData, getMockCardData } from '../test/mocks/dashboard

/**
 * get chain info for dashboarddata
 */
export const getChainInfo = (opts, tokens) => {
  const { chainUuid } = opts
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/chain/${chainUuid}/dashboard`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get card info for subscriber, journey and campaigns
 * @param {Object} opts
 * @param {String} opts.type which card information we are getting.
 * i.e: 'journey', 'campaign', 'subscriber
 * @param {*} tokens auth tokens
 * @returns
 */
export const getCardData = (opts, tokens) => {
  const { type, chainUuid, dates  } = opts
  const queryParams = [
    `from_date=${dates.from}`,
    `to_date=${dates.to}`,
    `type=${type}`
  ]
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/chain/${chainUuid}/dashboard/card?${queryParams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
