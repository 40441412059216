/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { actions as oauthActions } from '../../../../../actions/Oauth'
import { oauthProviders } from '../../../../../services/auth'

import Spinner from '../../../../LocalSpinner'

import './styles.scss'
export const FACEBOOK_OAUTH_URL = 'https://www.facebook.com/v8.0/dialog/oauth'
export const FACEBOOK_OAUTH_SCOPES = 'public_profile,email,ads_management'

const Facebook  = (props) => {
  const { isFacebookConnected } = props
  const connecting = useSelector(state => state.OauthConnect.connecting)
  const dispatch = useDispatch()

  const handleConnectClick = async () => {
    dispatch(oauthActions.authorize({
      clientId: process.env.REACT_APP_FACEBOOK_API_KEY,
      url: FACEBOOK_OAUTH_URL,
      scope: FACEBOOK_OAUTH_SCOPES,
      restaurantUuid: props.restaurantUuid,
      provider: oauthProviders.FACEBOOK
    }))
    if (props.onAuthorize) {
      props.onAuthorize()
    }
  }


  const renderFacebookConnectButton = () => {
    if (connecting) {
      return <Spinner />
    }
    return (
      <div
        type="button"
        onClick={handleConnectClick}
        className="facebook-btn"
        data-testid='facebook-btn'
      >
        {isFacebookConnected ? 'CONNECT WITH DIFFERENT ACCOUNT' : 'CONNECT WITH FACEBOOK' }
      </div>
    )
  }

  return (
    <div className='container'>
      <div className='logo'>
        {isFacebookConnected && <div className='check'></div>}
      </div>
      <div className='title'>
        { isFacebookConnected ? 'Your Facebook ad account is connected.' : 'Connect your Facebook Ad account' }
      </div>
      { renderFacebookConnectButton() }
    </div>
  )

}

Facebook.propTypes = {
  isFacebookConnected: PropTypes.bool,
  restaurantUuid: PropTypes.string.isRequired,
  onAuthorize: PropTypes.func
}

export default Facebook
