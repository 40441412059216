import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../../sass/objects/a11y.scss'
import './styles.scss'

export default function Spinner ({ loading = false, backdrop = false, className = '' }) {
  const classes = classNames([{
    'eg-spinner': true,
    '-with-backdrop': backdrop
  }, className])

  return loading && (
    <div className={classes} role="status">
      <span className="eg-a11y -sr-only">Loading…</span>
    </div>
  )
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  backdrop: PropTypes.bool,
  className: PropTypes.string
}
