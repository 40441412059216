import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'
import { PATHS } from '../constants'

export const authorize = (opts) => {
  const {
    url,
    restaurantUuid,
    chainUuid,
    clientId,
    scope,
    provider,
    session,
    locale,
    passRedirect = true,
    prompt
  } = opts
  const { host, protocol } = window.location
  const state = restaurantUuid || chainUuid

  return new Promise((resolve) => {
    const params = [
      `client_id=${clientId}`,
      `state=${state}`
    ]
    if(passRedirect) {
      params.push(`redirect_uri=${protocol.replace(':', '')}%3A%2F%2F${host}%2Fauth%2Fcallback%2F${provider}`,)
    }
    if (opts.responseType) {
      params.push(`response_type=${opts.responseType}`)
    }
    if(opts.accessType) {
      params.push(`access_type=${opts.accessType}`)
    }
    if (scope) {
      params.push(`scope=${scope}`)
    }
    if(prompt) {
      params.push(`prompt=${prompt}`)
    }
    if(session) {
      params.push(`session=${session}`)
    }
    if(locale) {
      params.push(`locale=${locale}`)
    }

    let popup

    if (opts.openInTab) {
      popup = window.open(`${url}?${params.join('&')}`, '_blank')
    } else {
      popup = window.open(`${url}?${params.join('&')}`, 'Authorization', 'width=500,height=500', '_blank')
    }

    popup.onbeforeunload = () => {
      resolve({ status: 200, data: {} })
    }
  })
}


/**
 * connect to the service using Oauth2 flow.
 * NOTE: this method will be deprecated in the future in favor of
 * `customerConnect()` method defined below
 *
 * @param {Object} opts - connect options
 * @param {string} opts.chainUuid - associated Chain uuid
 * @param {string} opts.code - authorization code sent by the 3rd party service
 * @param {string} opts.provider - 3rd party service provider name
 *
 * @param {Object} tokens - application access tokens
*/
export const connect = (opts, tokens) => {
  const bodyData = {
    code: opts.code,
    provider: opts.provider,
    ...opts
  }
  const params = []
  if(opts.restaurantUuid) {
    params.push(`restaurant_uuid=${opts.restaurantUuid}`)
    bodyData.restaurantUuid && delete(bodyData.restaurantUuid)
  }
  if(opts.chainUuid) {
    params.push(`chain_uuid=${opts.chainUuid}`)
    bodyData.chainUuid && delete(bodyData.chainUuid)
  }
  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/oauth/connect?${params.join('&')}`
  return axios({
    url,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(bodyData)
  })
}

/**
 * connect to the service using Oauth2 flow.
 *
 * @param {Object} opts - connect options
 * @param {string} opts.chainUuid - associated Chain uuid
 * @param {string} opts.code - authorization code sent by the 3rd party service
 * @param {string} opts.provider - 3rd party service provider name
 *
 * @param {Object} tokens - application access tokens
*/
export const customerConnect = (opts, tokens) => {
  const {
    provider,
    restaurantUuid,
    chainUuid
  } = opts
  const args = []
  if (chainUuid) {
    args.push(`chain_uuid=${chainUuid}`)
  }
  if (restaurantUuid) {
    args.push(`restaurant_uuid=${restaurantUuid}`)
  }

  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/oauth/connect?${args.join('&')}`
  let payload = {
    provider: provider,
    code: opts.code,
  }
  if (opts.payload) {
    payload = {
      ...payload,
      ...opts.payload
    }
  }
  return axios({
    url,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}

export const getActiveConnections = async (opts, tokens) => {
  const customerStackUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/oauth/list?chain_uuid=${opts.chainUuid}`

  const result = await axios({
    url: customerStackUrl,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })

  const allIntegrationStatus = {
    ...result,
    data: result.data,
  }

  return allIntegrationStatus
}


export const saveToastCredential = (opts, tokens) => {
  const bodyData = {
    'provider': 'toast',
    's3_username': opts.user_name,
    's3_access_key': opts.access_key,
    's3_secret_key': opts.secret_key,
    's3_region': 'us-west-1'
  }
  const params = []
  if(opts.chainUuid) {
    params.push(`chain_uuid=${opts.chainUuid}`)
  }
  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/oauth/connect?${params.join('&')}`
  return axios({
    url,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(bodyData)
  })
}

export const fivetranConnect = (opts, tokens) => {
  const { passRedirect, provider } = opts
  const { host, protocol } = window.location
  const bodyData = {
    provider
  }
  const params = []
  if(opts.chainUuid) {
    params.push(`chain_uuid=${opts.chainUuid}`)
  }
  if(passRedirect) {
    bodyData.redirect_uri = `${protocol.replace(':', '')}%3A%2F%2F${host}${PATHS.FIVETRAN_OAUTH_CALLBACK}`
  }
  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/fivetran/connect?${params.join('&')}`
  return axios({
    url,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(bodyData)
  })
}

export const confirmFivetranConnect = (opts, tokens) => {
  const { connector_id } = opts
  const bodyData = {
    connector_id
  }
  const params = []
  if(opts.chainUuid) {
    params.push(`chain_uuid=${opts.chainUuid}`)
  }
  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/fivetran/connect/confirm?${params.join('&')}`
  return axios({
    url,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(bodyData)
  })
}

export const getFivetranConnections = (opts, tokens) => {
  const params = []
  if(opts.chainUuid) {
    params.push(`chain_uuid=${opts.chainUuid}`)
  }
  const url = `${process.env.REACT_APP_CUSTOMERS_URL}/fivetran/connect?${params.join('&')}`
  return axios({
    url,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
