import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'

import { METHODS, HeaderFactory } from './utils'

/**
 * A query builder for multiple store ids
 */
export const constructStoreQuery = (storeInfo) => {
  const { restaurantChainUuid, selectedStores = [], }  = storeInfo
  if(selectedStores.length === 0) {
    //No store is selected, sending chain uuid to get all the orders
    return [`chain_uuid=${restaurantChainUuid}`]
  } else {
    const selectedStoreUuids = selectedStores.map((store, index) => `store_uuids[${index}]=${store.uuid}`)
    return [`chain_uuid=${restaurantChainUuid}`, ...selectedStoreUuids]
  }
}

/**
 * Get channel list
 *
 * @param {Object} opts - options
 * @param {string} chainUuid - Chain UUID
 * @param {Object} filters - applied filters
 * @param {Object} filters.date_filter - date filter
*/
export const getChannels = (opts, tokens) => {
  const { chainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid: chainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const qparams = [
    ...storeQueryParams,
  ]

  let from = _.get(opts, 'from')
  let to = _.get(opts, 'to')
  if (!from) {
    // for "All time" filter, give a long time ago date
    from = moment('1970-01-01').utc().startOf('day').format()
  }
  else {
    from = moment(from).utc().startOf('day').format()
  }
  if (!to) {
    to = moment().utc().subtract(1, 'hour').format()
  }
  else {
    if (to === moment().format('YYYY-MM-DD')) {
      to = moment().utc().subtract(1, 'hour').format()
    }
    else {
      to = moment(to).utc().endOf('day').format()
    }
  }
  qparams.push(`from=${from}`)
  qparams.push(`to=${to}`)

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/channel?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}
