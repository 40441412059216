import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Button } from '../../FormComponents'
import useValidator from '../../../hooks/validator'
import { actions as userActions } from '../../../actions/User'

import './styles.scss'

export const fields = {
  FIRST_NAME: 'given_name',
  LAST_NAME: 'family_name',
  PHONE: 'phone_number'
}

const UpdateUserInfo = props => {
  const { onChange, onSubmit } = props
  const user = useSelector(state => state.User.user)
  const { register, formState, errors, getValues } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.FIRST_NAME]: user.given_name,
      [fields.LAST_NAME]: user.family_name,
      [fields.PHONE]: user.phone_number
    }
  })
  const posting = useSelector(state => state.User.posting)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (formState.isValid) {
      dispatch(userActions.updateUserInfo(getValues()))
      if (onSubmit) {
        onSubmit()
      }
    }
  }

  const handleChange = () => {
    if (onChange) {
      onChange()
    }
  }

  const renderInput = (name, validationRules) => {
    return (
      <div>
        <Input
          id={ name }
          name={ name }
          className={ errors[name] ? 'error' : '' }
          ref={ register(validationRules) }
          onChange={ handleChange }
        />
        { errors[name] && <div className='error-msg'>{ errors[name].message }</div> }
      </div>
    )
  }

  return (
    <div data-testid='update-user-info' className='eg-update-user-info'>
      <div className='fields'>
        <div className='row'>
          <div className='field'>
            <label className='label' htmlFor={ fields.FIRST_NAME }>First name</label>
            { renderInput(fields.FIRST_NAME, { required: true }) }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.LAST_NAME }>Last name</label>
            { renderInput(fields.LAST_NAME, { required: true }) }
          </div>
        </div>
        <div className='row'>
          <div className='field'>
            <label className='label' htmlFor='email'>Email</label>
            <Input id='email' value={ user.email } disabled />
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.PHONE }>Phone number</label>
            <Input id={ fields.PHONE } value={ user.phone_number } disabled />
            {/*
              // TODO: uncomment when we are able to edit phone numbers in cognito
              renderInput(fields.PHONE, user.phone_number, {
                required: true,
                validate: value => !!value.match(validationPatterns.PHONE)
              }, errorMessages.PHONE_INVALID_FORMAT)
              */}
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button
          text='Save changes'
          className='button'
          disabled={ !formState.isValid }
          loading={ posting }
          onClick={ handleSubmit }
        />
      </div>
    </div>
  )
}

UpdateUserInfo.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default UpdateUserInfo
