export const types = {
  INITIATE_TOKEN_EXCHANGE: 'INITIATE_TOKEN_EXCHANGE',
  TOKEN_EXCHANGE_SUCESSFULL: 'TOKEN_EXCHANGE_SUCESSFULL',
  TOKEN_EXCHANGE_FAILED: 'TOKEN_EXCHANGE_FAILED',
}

export const actions = {
  startTokenExchange(opts) {
    return {
      type: types.INITIATE_TOKEN_EXCHANGE,
      opts
    }
  },

  tokenExchangeResolved(data) {
    return {
      type: types.TOKEN_EXCHANGE_SUCESSFULL,
      data
    }
  },

  tokenExchangeFailed(data) {
    return {
      type: types.TOKEN_EXCHANGE_FAILED,
      error: data
    }
  },
}
