import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const AddItem = props => {
  return (
    <div className={ `${styles.addItemContainer} ${props.className}` }>
      <div className={ styles.plus } />
      <div>
        { props.label }
      </div>
    </div>
  )
}

AddItem.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
}

AddItem.defaultProps = {
  className: ''
}

export default AddItem
