import React, { useState } from 'react'
import {
  useSelector
} from 'react-redux'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { Input, Select, Button } from '../../../FormComponents'
import { types as buttonTypes } from '../../../FormComponents/Button'
import useValidator from '../../../../hooks/validator'
import { getTriggers } from '../../../../services/journeys'
import EGAnalytic, { analyticEvents } from '../../../../services/analytics'

import './styles.scss'

export const fields = {
  NAME: 'journey_name',
  TRIGGER: 'journey_trigger',
  TRIGGER_ID: 'journey_trigger_id'
}

/**
 * SetupJourney - Step 1 of create SMS Journey flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  journey_name: 'My journey' -> to be updated by this component
 * }
 *
 * return (
 *  <SetupJourney data={ data } onSubmit={ handleSubmit } />
 * )
*/
const SetupJourney = props => {
  const { data, submitting, onCancel, onSubmit } = props
  const user = useSelector(state => state.User.user)
  const triggers = getTriggers(user)
  const [selectedTrigger, setSelectedTrigger] = useState(data[fields.TRIGGER] || triggers[0])
  const chain = useSelector(state => state.User.chain)
  const { register, errors, formState, control, getValues, setValue, trigger } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.NAME]: data[fields.NAME] || '',
      [fields.TRIGGER]: selectedTrigger,
      [fields.TRIGGER_ID]: data[fields.TRIGGER_ID]
    }
  })

  const formIsValid = () => {
    return formState.isValid
  }

  const handleTriggerChange = trigger => {
    const data = getValues()
    EGAnalytic.track(analyticEvents.JOURNEY_TRIGGER_INTERACTIONS, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[fields.NAME],
      triggerName: trigger.label,
      triggerId: data[fields.TRIGGER_ID],
    })
    setValue(fields.TRIGGER, trigger)
    setSelectedTrigger(trigger)
  }

  const handleCancelClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_CREATION_ABANDONED, EGAnalytic.mapChain(chain))
    onCancel()
  }

  const checkFields = () => {
    if (!formIsValid()) {
      trigger()
    }
  }

  const handleSubmit = () => {
    const data = getValues()
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_COMPLETED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[fields.NAME],
      triggerName: selectedTrigger.label,
      triggerId: data[fields.TRIGGER_ID],
      stepName: 'Setup'
    })
    onSubmit('setup', {
      [fields.NAME]: data[fields.NAME],
      [fields.TRIGGER]: data[fields.TRIGGER],
      [fields.TRIGGER_ID]: data[fields.TRIGGER_ID]
    })
  }

  return (
    <div className='eg-journey-create-setup'>
      <div className='step-body'>
        <div className='title'>Setup Journey</div>
        <div className='subtitle'>
          Give your journey a name and set tigger properties
        </div>
        <div className='form'>
          <label htmlFor={fields.NAME}>Journey Name</label>
          <div className='row'>
            <Input
              id={fields.NAME}
              name={fields.NAME}
              placeholder='Enter journey name'
              className={`${errors[fields.NAME] ? 'error' : ''}`}
              ref={register({ required: true })}
            />
            {errors[fields.NAME] && <div className='error-msg'>{errors[fields.NAME].message}</div>}
          </div>
          <div className='row'>
            <label htmlFor={fields.TRIGGER}>Journey Trigger</label>
            <Controller
              control={control}
              name={fields.TRIGGER}
              render={() => (
                <Select
                  id={fields.TRIGGER}
                  className='trigger'
                  options={triggers}
                  selectedItem={selectedTrigger}
                  onChange={handleTriggerChange}
                />
              )}
            />
          </div>
          {
            !!selectedTrigger.paramLabel && (
              <div className='row'>
                <label htmlFor={fields.TRIGGER_ID}>{selectedTrigger.paramLabel}</label>
                <Input
                  id={fields.TRIGGER_ID}
                  name={fields.TRIGGER_ID}
                  placeholder={selectedTrigger.paramPlaceholder}
                  className={`${errors[fields.TRIGGER_ID] ? 'error' : ''}`}
                  ref={register({ required: true })}
                />
                {
                  errors[fields.TRIGGER_ID] && (
                    <div className='error-msg'>{errors[fields.TRIGGER_ID].message}</div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      <div className='actions'>
        <Button
          className='action-button'
          text='CANCEL'
          type={buttonTypes.SECONDARY}
          onClick={handleCancelClick}
        />
        <div onClick={checkFields}>
          <Button
            className='action-button submit'
            text='CONTINUE'
            disabled={!formIsValid()}
            loading={submitting}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

SetupJourney.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default SetupJourney
