import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * get store list for a chain or restaurant
 *
 * @param {number} restaurantId
 * @param {object} opts
 *
 * @returns {Promise}
 */
export const getStoreList = ({ restaurantChainUuid }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/chain/${restaurantChainUuid}/store?size=40`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get store
 *
 * @param {number} restaurantId
 * @param {object} opts
 * @returns {Promise}
 */
export const getStore = async ({ restaurantUuid }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/store/${restaurantUuid}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

/**
 *
 * @param {object} param0
 * @param {*} tokens
 */
export const createStore = ({ restaurantChainUuid, payload }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/chains/${restaurantChainUuid}/restaurants`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}

/**
 * update store
 * @param {number} restaurantId
 * @param {object} data
 *
 * @returns {Promise}
*/
export const updateStore = async ({ restaurantUuid, data }, tokens) => {
  return await axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/store/${restaurantUuid}`,
    method: METHODS.PUT,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(data)
  })
}

export const getCategories = async (opts, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/caterer_types`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

export const getIntegrationStatus = async (opts, tokens) => {
  const { restaurantUuid } = opts
  const results = await Promise.all([
    axios({
      url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${restaurantUuid}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens),
    }),
    axios({
      url: `${process.env.REACT_APP_INTEGRATION_URL}/oauth?restaurant_uuid=${restaurantUuid}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens),
    })
  ])

  const mixedData = {
    ...results[0].data,
  }
  results[1].data
    .map(p => p.provider)
    .forEach(k => {
      mixedData[`${k}_status`] = 'CONNECTED'
    })

  return {
    status: 200,
    data: mixedData
  }
}
