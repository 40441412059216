export const types = {
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
  HIDE_SIDEBAR: 'HIDE_SIDEBAR'
}

export const actions = {
  showSidebar() {
    return {
      type: types.SHOW_SIDEBAR
    }
  },

  hideSidebar() {
    return {
      type: types.HIDE_SIDEBAR
    }
  }
}
