import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { actions as segmentActions } from '../../../actions/Segment'

import CustomerHeader from '../../../components/CustomerHeader'
import BackLink from '../../../components/BackLink'
import LocalSpinner from '../../../components/LocalSpinner'
import ReviewSegment from '../../../components/Segments/DynamicSegmentCreate/Review'
import { PATHS } from '../../../constants'

import './styles.scss'

const DynamicSegmentViewContainer = props => {
  const { segmentId } = props.match.params
  const { history } = props
  const dispatch = useDispatch()
  const segment = useSelector(state => _.get(state, 'Segment.dynamicSegment.data'))

  useEffect(() => {
    return (() => {
      dispatch(segmentActions.clearDynamicSegment())
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(segmentActions.getDynamicSegment(segmentId))
  }, [dispatch, segmentId])

  const handleStepEdition = () => {
    history.push(`${PATHS.SEGMENTS}/edit/${segment.id}?step=1`)
  }

  if (!segment) {
    return <LocalSpinner />
  }

  return (
    <div className='eg-segment-view'>
      <CustomerHeader />
      <BackLink to={PATHS.SEGMENTS}>Segments</BackLink>
      <div className='body'>
        <ReviewSegment
          data={ segment }
          editable={ false }
          onEditClick={ handleStepEdition }
        />
      </div>
    </div>
  )
}

DynamicSegmentViewContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default DynamicSegmentViewContainer
