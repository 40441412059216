import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const SectionHeader = props => {
  return (
    <div className='eg-section-header'>
      <div className='title'>{ props.title }</div>
      <div className='right'>
        { props.rightContent && props.rightContent() }
      </div>
    </div>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  rightContent: PropTypes.func
}

export default SectionHeader
