import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { userHasAccess, userRoles } from '../../services/auth'

import styles from './index.module.scss'

const EditableCard = props => {
  const user = useSelector(state => state.User.user)
  const handleEditClick = (e) => {
    if (props.onEditClick) {
      props.onEditClick(e)
    }
  }

  const userIsAllowed = userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE])

  return (
    <div className={ `${styles.editableCardContainer} ${props.className}` }>
      {
        props.isEditable && userIsAllowed && (
          <div
            className={ `${styles.editAction} ${props.editClassName}` }
            onClick={ handleEditClick }
          >Edit</div>
        )
      }
      <div className={ styles.cardBody }>
        { props.children }
      </div>
    </div>
  )
}

EditableCard.propTypes = {
  children: PropTypes.any,
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  editClassName: PropTypes.string,
  onEditClick: PropTypes.func
}

EditableCard.defaultProps = {
  isEditable: false,
  className: '',
  editClassName: ''
}

export default EditableCard
