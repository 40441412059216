import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export const statuses = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  CANCELLED: 'cancelled'
}

const StatusWidget = props => {
  const { status } = props
  const [ className, setClassName ] = useState(status)

  useEffect(() => {
    setClassName(status)
  }, [status])

  return (
    <div className={ `${styles.statusContainer} ${styles[className]}` }>
      { props.label }
    </div>
  )
}

StatusWidget.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(statuses)).isRequired
}

export default StatusWidget
