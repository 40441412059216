import React from 'react'
import PropTypes from 'prop-types'
import caretLight from './caret-light.svg'
import caretUp from './caret-up.svg'
import caretDown from './caret-down.svg'


import styles from './index.module.scss'

export default class ListHeader extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    onItemClicked: PropTypes.func,
    applySorting: PropTypes.func,
    selectedColumnInfo: PropTypes.object,
  }

  static defaultProps = {
    className: ''
  }

  constructor() {
    super()

    // bindings
    this.columnClicked = this.columnClicked.bind(this)
  }

  columnClicked(item) {
    if (this.props.onItemClicked) {
      this.props.onItemClicked(item)
    }
    if(item.isSortable){
      this.props.applySorting({sortKey: item.sortKey}) // To apply sorting on column click
    }
  }

  render() {
    return (
      <div className={ `${styles.container} ${this.props.className}` }>
        {
          this.props.items.map(item => {
            const alignStyle = item.alignment ? styles[item.alignment] : ''
            return (
              <div
                key={ item.key }
                className={ `${styles.columnName} ${alignStyle}` }
                onClick={ () => this.columnClicked(item) }
              >
                { item.label }
                {
                  this.props.selectedColumnInfo
                    ?(<div className={styles.ArrowContainer} >
                      <img src={this.props.selectedColumnInfo.sortKey === item.sortKey?  (this.props.selectedColumnInfo.order==='desc'?  caretDown: caretUp) : caretLight} alt='Arrow Sign'/> 
                    </div>)
                    : null
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}
