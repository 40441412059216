import _ from 'lodash'
import moment from 'moment'
import { DAYS_OF_WEEK } from '../constants'
import { getAddressesFromString } from './geolocation'

export const storeSections = {
  LINKS: 'links',
  OPERATION_DETAILS: 'operationDetails',
  BUSINESS_DETAILS: 'businessDetails',
  MEDIA_PROFILES: 'mediaProfiles',
  PHOTOS: 'photos',
  STORE_INFO: 'storeInfo'
}

export const linkTypes = {
  WEBSITE: 'website',
  RESERVATION_URL: 'reservation_url',
  MENU_URL: 'menu_url',
  ORDER_ONLINE_URL: 'order_online_url',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  YELP: 'yelp',
  INSTAGRAM: 'instagram',
  TRIPADVISOR: 'tripadvisor',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  PHOTOS: 'photos',
  LOGO: 'logo',
}

export const linkSections = {
  WEBSITE_LINK: 'website_link',
  MEDIA_PROFILE: 'media_profile',
  LOGO_LINK: 'logo'
}

const linkLabels = {
  website: 'Website',
  reservation_url: 'Reservation URL',
  menu_url: 'Menu URL',
  order_online_url: 'Order online URL',
  facebook: 'Facebook',
  google: 'Google',
  yelp: 'Yelp',
  instagram: 'Instagram',
  tripadvisor: 'Tripadvisor',
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  logo: 'logo',
}

export const scheduleStatuses = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const BUSINESS_DETAILS = {
  ABOUT: {
    label: 'About',
    key: 'about'
  },
  MANAGER_NAME: {
    label: 'Manager name',
    key: 'contact_name',
  },
  MANAGER_EMAIL: {
    label: 'Manager email',
    key: 'email'
  },
  MANAGER_NUMBER: {
    label: 'Manager number',
    key: 'manager_phone_number'
  }
}

export const gmbCategories = [
  { name: 'Restaurant', id: 'gcid:restaurant' },
  { name: 'Bakery', id: 'gcid:bakery' },
  { name: 'Caterer', id: 'gcid:catering_service' },
  { name: 'Takeout restaurant', id: 'gcid:meal_takeaway' }
]

export const getLinkLabel = (id) => {
  return linkLabels[id]
}

const scheduleIsValid = schedule => {
  return !!schedule.from && !!schedule.to
}

export const mapAppToStore = (input, section) => {
  // map links and media profiles
  let links = [
    ...input.mediaProfiles.map(m => _.pick(m, ['type', 'subtype', 'url'])),
    ...input.links.map(m => _.pick(m, ['type', 'subtype', 'url']))
  ]
  links = links.filter((link) => link.url)

  // map categories
  const categories = input.storeInfo.categories.map(inputCategory => {
    const gmbCategory = gmbCategories.find(c => c.name === inputCategory)
    return {
      display_name: inputCategory,
      gmb_category_id: gmbCategory.id
    }
  })

  // map schedules
  const inputSchedule = _.get(input, 'operationDetails.schedule')
  const schedule = []
  const businessDetails = input.businessDetails || {}
  const businessDetailsPayload = {
    [BUSINESS_DETAILS.ABOUT.key]: businessDetails[BUSINESS_DETAILS.ABOUT.key],
    [BUSINESS_DETAILS.MANAGER_NAME.key]: businessDetails[BUSINESS_DETAILS.MANAGER_NAME.key],
    [BUSINESS_DETAILS.MANAGER_EMAIL.key]: businessDetails[BUSINESS_DETAILS.MANAGER_EMAIL.key],
    [BUSINESS_DETAILS.MANAGER_NUMBER.key]: businessDetails[BUSINESS_DETAILS.MANAGER_NUMBER.key],
  }

  DAYS_OF_WEEK.forEach(dayName => {
    const s = inputSchedule.find(i => i.day_of_week === dayName)
    const daySchedule = s.state === scheduleStatuses.OPEN ? {
      from_day_of_week: dayName,
      to_day_of_week: dayName,
      from_time: s.from,
      to_time: s.to
    } : null
    daySchedule && schedule.push(daySchedule)
  })

  // map special schedules
  const inputSpecialSchedule = _.get(input, 'operationDetails.specialSchedule')
  let specialSchedule = []
  let dayOffs = []
  if (inputSpecialSchedule) {
    specialSchedule = inputSpecialSchedule
      .filter(s => {
        return s.state !== scheduleStatuses.CLOSED && !s.deleted && scheduleIsValid(s)
      })
      .map(s => ({
        from_time: s.from,
        to_time: s.to,
        from_day: moment(s.date).utc().startOf('day').format('YYYY-MM-DD'),
        to_day: moment(s.date).utc().startOf('day').format('YYYY-MM-DD'),
      }))
    dayOffs = inputSpecialSchedule
      .filter(s => {
        return s.state === scheduleStatuses.CLOSED && !s.deleted && scheduleIsValid(s)
      })
      .map(s => {
        const date = moment(s.date).format('YYYY-MM-DD')
        return {
          from_day: date,
          from_time: s.from,
          to_day: date,
          to_time: s.to,
        }
      })
  }

  // join all pieces
  const mappedStore = {
    [storeSections.LINKS]: { links },
    [storeSections.OPERATION_DETAILS]: {
      operation_details: schedule,
      special_schedules: specialSchedule,
      day_offs: dayOffs
    },
    [storeSections.BUSINESS_DETAILS]: {...businessDetailsPayload},
    [storeSections.MEDIA_PROFILES]: { links },
    [storeSections.PHOTOS]: {},
    [storeSections.STORE_INFO]: {
      name: input.storeInfo.name,
      phone_number: input.storeInfo.phone,
      categories: categories ? categories : [],
      address: input.storeInfo.address,
    }
  }

  return mappedStore[section]
}

/**
 * Get all the links for the store. (i.e urls and social media links)
 * @param {*} store
 */
const getAllLinks = (store) => {
  const hasLinks = _.get(store, 'links.length')
  if(!hasLinks) return []
  return store.links.map(link => ({ ...link, label: getLinkLabel(link.subtype) }))
}

const mapLink = (allLinks, type, subtype) => {
  return allLinks.find(l => l.subtype === subtype) || {
    type,
    subtype,
    url: '',
    label: getLinkLabel(subtype)
  }
}

export const mapStoreToApp = async (input) => {
  const allLinks = getAllLinks(input)
  const scheduleData = input.operation_details
  const hasSchedule = !!scheduleData.length
  const categories = []

  // map categories
  const inputCategories = input.categories.filter(c => {
    return !!gmbCategories.find(gc => gc.id === c.gmb_category_id)
  })
  inputCategories.forEach((inputCategory) => {
    categories.push(inputCategory.display_name)
  })

  // operation section
  // check for closed days
  const schedule = []
  DAYS_OF_WEEK.forEach(dow => {
    const scheduleDay = scheduleData.find(s => s.from_day_of_week === dow)
    const state = scheduleDay ? scheduleStatuses.OPEN : scheduleStatuses.CLOSED
    if (!scheduleData || !scheduleData.length) {
      schedule.push({
        day_of_week: dow,
        state: null
      })
    } else if (scheduleDay) {
      const mappedScheduleDay = {
        day_of_week: scheduleDay.from_day_of_week,
        from: `${scheduleDay.from_time || '00'}`,
        to: `${scheduleDay.to_time || '00'}`,
        state
      }
      schedule.push(mappedScheduleDay)
    } else {
      schedule.push({
        day_of_week: dow,
        state
      })
    }
  })

  const operationDetails = {
    schedule,
    specialSchedule: [
      ...input.special_schedules.map(s => {
        return {
          id: s.restaurant_special_schedule_id,
          from: s.from_time,
          to: s.to_time,
          date: new Date(moment(s.from_day)),
          state: scheduleStatuses.OPEN,
          deleted: false
        }
      }),
      ...input.day_offs.map(t => {
        return {
          id: t.restaurant_time_off_id,
          from: t.from_time,
          to: t.to_time,
          date: moment(t.from_day).toDate(),
          state: scheduleStatuses.CLOSED,
          deleted: false
        }
      })
    ].sort((t1, t2) => {
      return moment(t1.date).valueOf() < moment(t2.date).valueOf() ? -1 : 1
    })
  }

  // address
  let addressObject = {}
  if (_.get(input, 'address.address1')) {
    try{
      const { address } = input
      const addrList = await getAddressesFromString(`${address.address1}, ${address.city} ${address.zipcode}`)
      if (addrList && addrList.length) {
        addressObject = addrList[0]
      }
    }catch(error){
      addressObject.formatted_address = _.get(input, 'address.address1')
    }
  }


  input.phone_number = input.phone_number ? input.phone_number.replace('+1', '').replace(/^\+/, '') : ''
  const managerPhoneNumber = input.manager_phone_number ? input.manager_phone_number.replace('+1', '').replace(/^\+/, '') : ''

  // business details section
  const businessDetails = {
    description: input.about,
    manager: {
      name: input.contact_name,
      email: input.email,
      phone: managerPhoneNumber
    }
  }

  const imageLinks = input.social_media || []
  const logo = input.profile_photo

  const links= getLinks(allLinks)
  const mediaProfiles = getMediaProfiles(allLinks)

  const storeInfo = {
    name: input.name,
    address: addressObject,
    phone: input.phone_number,
    categories,
    profileCompletion: input.profileCompletion,
    uuid: input.uuid,
    restaurantChainUuid: input.restaurant_chain_uuid,
    restaurantId: input.restaurant_id,
    rate: input.rate,
    numberOfRates: input.rate_count,
    logo,
  }

  return {
    links,
    mediaProfiles,
    operationDetails,
    businessDetails,
    imageLinks,
    storeInfo,
    hasSchedule,
    uuid: input.uuid,
    google_connect_status: input.google_connect_status,
    gmail_integration_email: input.gmail_integration_email,
    calendar_connect_status: input.calendar_connect_status,
    ivr_logo: input.ivr_logo,
    rating_logo: input.rating_logo,
    rating: input.rating,
    ivr: input.ivr
  }
}

/**
 * Get links for a store (i.e ordering website, restaurant website)
 * @param {*} allLinks
 */
const getLinks = (allLinks) => {
  return [
    mapLink(allLinks, linkSections.WEBSITE_LINK, linkTypes.WEBSITE),
    mapLink(allLinks, linkSections.WEBSITE_LINK, linkTypes.RESERVATION_URL),
    mapLink(allLinks, linkSections.WEBSITE_LINK, linkTypes.MENU_URL),
    mapLink(allLinks, linkSections.WEBSITE_LINK, linkTypes.ORDER_ONLINE_URL)
  ]
}

/**
 * Get social media links for a store.
 * @param {*} allLinks
 */
const getMediaProfiles = (allLinks) => {
  return [
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.FACEBOOK),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.GOOGLE),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.YELP),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.INSTAGRAM),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.TRIPADVISOR),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.LINKEDIN),
    mapLink(allLinks, linkSections.MEDIA_PROFILE, linkTypes.TWITTER),
  ]
}

/**
 * A Function which takes a store data
 * and returns all the links that doesn't have any url
 * available.
 * @param {object} store
 */
export const getEmptyLinks = (store = {}) => {
  const storeLinks = store.links || []
  const mediaProfiles = store.mediaProfiles || []
  const allLinks = [...storeLinks, ...mediaProfiles]
  const linksWithoutUrls = allLinks.filter((link) => !link.url)
  return linksWithoutUrls || []
}


const getProfileCompletePercentage = (data) => {
  const sections = 5
  let completed = 0
  const links = data.links.map(link => link.type === 'website_link')
  const mediaProfiles = data.links.map(link => link.type === 'media_profile')
  const hasPhotos = data.profile_photo || (data.social_media && data.social_media.length)
  // links section
  if (links.length > 0) completed += 1 / sections
  // operation details section
  if (data.operation_details && data.operation_details.length > 0) completed += 1 / sections
  // business details section
  if (
    (data.about && data.about.length > 0) ||
    (data.contact_name && data.contact_name.length > 0) ||
    (data.email && data.email.length > 0) ||
    (data.phone && data.phone.length > 0)
  ) completed += 1 / sections
  // media profiles
  if (mediaProfiles.length > 0) completed += 1 / sections
  // photos
  if (hasPhotos) completed += 1 / sections
  return completed
}

export const mapStoreList = (stores) => {
  const storeList = stores.map((store) => {
    const profileCompletion  = getProfileCompletePercentage(store)
    const allLinks = getAllLinks(store)
    const links = getLinks(allLinks)
    const mediaProfiles = getMediaProfiles(allLinks)
    const phone = store.phone ? store.phone.replace('+1', '').replace(/^\+/, '') : ''
    return {
      name: store.name,
      address: _.get(store, 'address.address1'),
      phone,
      profileCompletion,
      restaurantChainUuid: store.restaurant_chain_uuid,
      managerName: store.contact_name,
      managerEmail: store.email,
      links,
      mediaProfiles,
      uuid: store.uuid,
    }
  })

  // Creating a lookup table to get city and state of the stor on require places
  const storeListLookup = stores.reduce((sum, store) => {
    sum[store.uuid]= store
    return sum
  }, {})

  return { storeList, storeListLookup }
}
