import _ from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'
import { types, actions } from '../actions/Interactions'
import { getInteractions } from '../api/Interaction'
import { mapInteractionIn } from '../services/interactions'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

import { authorizedRequest } from './Auth'

function* getInteractionsHandler({ opts }) {
  try {
    const user = yield select(state => state.User)
    const chainUuid  = _.get(user, 'chain.uuid')
    const { selectedStores } = yield select(({ Filter }) => Filter)
    const res = yield call(authorizedRequest, getInteractions, {
      ...opts,
      chainUuid,
      selectedStores
    })

    if (res && res.data) {
      const { total } = res.data
      yield put(actions.getInteractionsResolved({
        page: opts.page,
        size: opts.size,
        total,
        items: res.data.activities.map(interaction => mapInteractionIn(interaction))
      }))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Unknown error'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getInteractionsRejected(error)) 
  }
}

export default function* () {
  yield takeLatest(types.GET_INTERACTIONS_INTENT, getInteractionsHandler)
}
