import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { actions as oauthActions } from '../../../../../actions/Oauth'
import { oauthProviders } from '../../../../../services/auth'
import { Button } from '../../../../FormComponents'

import './styles.scss'

const ConstantContact = props => {
  const { restaurantUuid, isConnected = false } = props
  const dispatch = useDispatch()

  const handleSubmitClick = () => {
    dispatch(oauthActions.authorize({
      clientId: process.env.REACT_APP_CONSTANT_CONTACT_API_KEY,
      url: `${process.env.REACT_APP_CONSTANT_CONTACT_BASE_URL}/idfed`,
      scope: 'contact_data+campaign_data',
      restaurantUuid,
      provider: oauthProviders.CONSTANT_CONTACT,
      responseType: 'code'
    }))

    if (props.onAuthorize) {
      props.onAuthorize()
    }
  }

  return (
    <div className='eg-constant-contact'>
      <div className='logo'>
        { isConnected && <div className='check'></div> }
      </div>
      <div className='info-title'>
        { isConnected ? 'Connected' : 'Connect Constant Contact' }
      </div>
      <div className='info'>
        <ul className='description'>
          <li>
            <span className='list-icon icon-sync'></span>
            Sync your customer contacts information
          </li>
        </ul>
        <div className='action'>
          <Button
            className='connect-btn'
            text={ isConnected ? 'Connect with a different account' : 'Connect with Constant Contact' }
            onClick={ handleSubmitClick }
          />
        </div>
      </div>
    </div>
  )
}

ConstantContact.propTypes = {
  restaurantUuid: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  onAuthorize: PropTypes.func
}

export default ConstantContact
