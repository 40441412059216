import { connect } from 'react-redux'

import { actions as gcActions } from '../../../../../actions/GoogleConnect'
import CalendarConnect from './CalendarConnect'


const mapStateToProps = state => ({
  googleConnectPosting: state.GoogleConnect.posting,
  googleConnectError: state.GoogleConnect.error,
})

const mapDispatchToProps = dispatch => ({
  onCalendarConnect(opts) {
    dispatch(gcActions.calendarConnect(opts))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarConnect)
