import React from 'react'
import PropTypes from 'prop-types'

import LocalSpinner from '../../LocalSpinner'
import './styles.scss'
import BreakDownRow from './BreakdownRow'

const StoreBreakDown = ({ data }) => {
  if(data.loading) {
    return (
      <div className='eg-store-breakdown'>
        <LocalSpinner />
      </div>
    )
  }


  if(data.list && data.list.length === 0) {
    return (
      <div className='eg-store-breakdown'>
        <div className='title'>STORE BREAKDOWN</div>
        <div className='no-result'>
        No Result Found.
        </div>
      </div>
    )
  }

  return (
    <div className='eg-store-breakdown'>
      <div className='title'>STORE BREAKDOWN</div>
      <div className='table'>
        <div className='column-name'>
          <div className='store'>STORE</div>
          <div className='avg'>AVG. RATING</div>
          <div className='review'>REVIEWS</div>
        </div>
        <BreakDownRow storeList={data.list} />
      </div>
    </div>
  )
}

StoreBreakDown.propTypes = {
  data: PropTypes.object,
}

export default StoreBreakDown
