import React from 'react'
import PropTypes from 'prop-types'

import  { getTimeFormatted }from '../../../../services/dates.js'
import { sourceToLabelMap } from '../../../../services/customers'
import './styles.scss'

const Interaction = props => {
  const { item } = props

  return (
    <div className='eg-interaction'>
      <div className='item'>
        <div className='time-wrapper'>
          <div className='date'>
            { getTimeFormatted(item.activity_date, 'MMM DD, YYYY') }
          </div>
          <div className='time'>
            { getTimeFormatted(item.activity_date, 'hh:mm A') }
          </div>
        </div>
        <div className='logowrapper'>
          <div className={ `logo ${item.sourceClass}` } />
        </div>
        <div className='info'>
          <div className='title'>{ item.label }</div>
          <div className='description'>{ sourceToLabelMap[item.source] || item.source }</div>
        </div>
      </div>
    </div>
  )
}

Interaction.propTypes = {
  item: PropTypes.object.isRequired,
}

export default Interaction
