import _ from 'lodash'
import { types } from '../actions/SMS'

export const initialState = {
  mmsImage: {
    submitting: false,
    success: false,
    data: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.MMS_UPLOAD_IMAGE:
      return {
        ...state,
        mmsImage: {
          ...state.mmsImage,
          id: _.get(action, 'opts.id'),
          submitting: true,
          success: false
        }
      }

    case types.MMS_UPLOAD_IMAGE_RESOLVED:
      return {
        ...state,
        mmsImage: {
          ...state.mmsImage,
          submitting: false,
          success: true,
          id: _.get(action, 'id'),
          data: {
            image: _.get(action, 'data.file'),
            imageUrl: _.get(action, 'data.get_url')
          }
        }
      }

    case types.MMS_UPLOAD_IMAGE_REJECTED:
      return {
        ...state,
        mmsImage: {
          ...state.mmsImage,
          submitting: false,
          success: false,
          data: null,
          error: action.error
        }
      }

    case types.MMS_UPLOAD_CLEAR:
      return {
        ...state,
        mmsImage: {
          ...initialState.mmsImage
        }
      }

    default:
      return state
  }
}
