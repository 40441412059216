import { types } from '../actions/Sidebar'

const initialState = {
  showSidebar: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: true
      }

    case types.HIDE_SIDEBAR:
      return {
        ...state,
        showSidebar: false
      }

    default:
      return state
  }
}
