import { types } from '../actions/Dashboard'
import { cardTypes } from '../services/dashborad'

export const initialState = {
  chainInfo: {
    data: null,
    loading: false,
    error: null,
  },
  cardInfo: {
    [cardTypes.SUBSCRIBER]: {
      data: null,
      loading: true,
      error: null,
    },
    [cardTypes.CAMPAIGN]: {
      data: null,
      loading: true,
      error: null,
    },
    [cardTypes.JOURNEY]: {
      data: null,
      loading: true,
      error: null,
    },
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_GET_CHAIN_INFO:
      return {
        ...state,
        chainInfo: {
          data: null,
          loading: true,
          error: false
        }
      }

    case types.DASHBOARD_GET_CHAIN_INFO_RESOLVED:
      return {
        ...state,
        chainInfo: {
          data: action.data,
          loading: false,
          error: false
        }
      }

    case types.DASHBOARD_GET_CHAIN_INFO_REJECTED:
      return {
        ...state,
        chainInfo: {
          data: null,
          loading: false,
          error: true
        }
      }

    case types.DASHBOARD_GET_CARD_INFO:
      return {
        ...state,
        cardInfo: {
          ...state.cardInfo,
          [action.data.type]: {
            data: null,
            loading: true,
            error: null,
          },
        }
      }

    case types.DASHBOARD_GET_CARD_INFO_RESOLVED:
      return {
        ...state,
        cardInfo: {
          ...state.cardInfo,
          ...action.data
        }
      }


    case types.DASHBOARD_GET_CARD_INFO_REJECTED:
      return {
        ...state,
        cardInfo: {
          ...state.cardInfo,
          ...action.error
        }
      }

    default:
      return state
  }
}
