import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../FormComponents'
import { types as buttonTypes } from '../FormComponents/Button'
import StatelessModal from '../StatelessModal'

import './styles.scss'

const ConfirmationModal = props => {
  const {
    title = '',
    text = '',
    className = '',
    loading = false,
    cancelButtonLabel = 'CANCEL',
    confirmButtonLabel = 'CONFIRM',
    onCancel,
    onConfirm
  } = props

  return (
    <StatelessModal className={ `eg-confirmation-modal ${className}` } onClose={ onCancel }>
      <div className='modal-content'>
        <div className='title'>{ title }</div>
        <div className='text'>{ text }</div>
      </div>
      <div className='confirmation-actions'>
        <Button
          className='action-button'
          text={ cancelButtonLabel }
          type={ buttonTypes.SECONDARY }
          onClick={ onCancel }
        />
        <Button
          className='action-button submit'
          text={ confirmButtonLabel }
          loading={ loading }
          onClick={ onConfirm }
        />
      </div>
    </StatelessModal>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default ConfirmationModal
