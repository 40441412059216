import { types } from '../actions/GoogleConnect'

const initialState = {
  posting: false,
  error: false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case types.GMAIL_CONNECT_INTENT:
      return {
        ...state,
        posting: true,
        error: false
      }

    case types.GMAIL_CONNECT_INTENT_RESOLVED:
      return {
        ...state,
        posting: false,
        error: false
      }

    case types.GMAIL_CONNECT_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        error: true
      }


    case types.CALENDAR_CONNECT_INTENT:
      return {
        ...state,
        posting: true,
        error: false
      }

    case types.CALENDAR_CONNECT_INTENT_RESOLVED:
      return {
        ...state,
        posting: false,
        error: false
      }

    case types.CALENDAR_CONNECT_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        error: true
      }

    default:
      return state
  }
}
