import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import LocalSpinner from '../../LocalSpinner'
import { campaignTypes } from '../../../services/campaigns'
import { getNumberWithCommas } from '../../../utils/number'
import RowItem from './RowItem'
import './styles.scss'


/**
 * Campaign list component to render delivered or scheduled campaigns
 *
 * @component
 * @example
 *
 *
 * const type = 'delivered' or 'scheduled'
 *
 * const data = {
 *   loading: false,
 *   error: null,
 *   campaigns: {
 *     delivered: [...]
 *     scheduled: [...]
 *   }
 * }
 *
 * return (
 *  <CampaignList data={campaignList} type={currentTab.key}/>
 * )
*/
const CampaignList = ({ data, type, onEditSchedule, onCancel, onItemClick }) => {
  const { loading, error, campaigns } = data
  const list = campaigns || []

  const handleEditSchedule = (schedule) => {
    onEditSchedule(schedule)
  }

  const handleCancel = (schedule) => {
    onCancel(schedule)
  }

  if(loading) {
    return (
      <div className='eg-campaign-list'>
        <LocalSpinner className='loader' />
      </div>
    )
  }

  if(error) {
    return (
      <div className='eg-campaign-list'>
        <div className='title'>Something went wrong.</div>
        <div className='no-result'>
          Please refresh the page. If the issue persist please contact us.
        </div>
      </div>
    )
  }

  if(list && list.length === 0) {
    return (
      <div className='eg-campaign-list'>
        <div className='title'>No {type} campaigns</div>
        <div className='no-result'>
        This is where you will see all {type} campaigns
        </div>
      </div>
    )
  }

  const isDeliverType = type === campaignTypes.DELIVERED
  const total= _.get(data, 'total')

  return (
    <div className='eg-campaign-list'>
      <div className='total'> {getNumberWithCommas(total)} {type} Campaigns </div>
      <div className='table'>
        <div className='column'>
          <div className='name'>NAME</div>
          <div className='status'>STATUS</div>
          {isDeliverType && (
            <>
              <div className='delivered'> DELIVERED </div>
              <div className='clicked'> CLICKS </div>
            </>
          )}
        </div>
        {
          list.map((item, index) => (
            <RowItem
              item={item}
              key={index}
              onEditSchedule={ handleEditSchedule }
              onCancel={ handleCancel }
              onItemClick={ () => onItemClick(item) }
            />
          ))
        }
      </div>
    </div>
  )
}

CampaignList.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  onEditSchedule: PropTypes.func,
  onCancel: PropTypes.func,
  onItemClick: PropTypes.func
}

export default CampaignList
