import { call, takeLatest, select, put } from 'redux-saga/effects'
import get from 'lodash/get'

import { types } from '../actions/SquareConnect'
import { connectSquare } from '../api/Square'
import { authorizedRequest } from './Auth'
import { actions as squareActions } from '../actions/SquareConnect'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'
import EGAnalytic, { analyticEvents } from '../services/analytics'

/**
 * handle Square connect to retreive an authCode
 * and then send it to our backend
*/
function* handleSquareConnect(data) {
  const storeUuid =  get(data, 'opts.restaurantUuid')
  const restaurant = yield select(({ Store }) =>  Store.store.storeInfo)
  trackIntegrationStart(restaurant)
  try {
    const response = yield call(authorizedRequest, connectSquare, {
      restaurantUuid: storeUuid,
    })
    yield handleResponse(response) 
  }
  catch (error) {
    yield handleError(error, restaurant)
  }
}

const trackIntegrationStart = (restaurant) => {
  EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_STARTED, {
    ...EGAnalytic.mapIntegrationTabDetail(restaurant),
    integrationTabName: 'Square'
  })
}

function* handleResponse(res) {
  if(res && res.status === 200) {
    const squareOauthUrl = res.data.location
    // Redirecting to Square oauth page
    window.location.href = squareOauthUrl
  } else if(res && res.status === 403) {
    // Forbidden, normally in case where admin account tries to connect square for a restaurant which the Admin/user doesn't own
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: 'Restaurant does not belong to current user.'
    }
    yield put(squareActions.squareConnectResolved(res))
    yield put(EgNotificationActions.ShowNotification(notification))
  } else {
    console.error('Something is not right.', res)
  }
}

function* handleError(error, restaurant) {
  trackIntegrationFail(restaurant, error)
  console.error(error)
  yield put(squareActions.squareConnectRejected(error))
  const notification = {
    type: EgNotificationTypes.ERROR,
    message: 'Something went wrong with the request. Please try again or contact us for support.'
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}

const trackIntegrationFail = (restaurant, error) => {
  EGAnalytic.track(analyticEvents.GENERAL_ERROR, {
    ...EGAnalytic.mapRestaurant(restaurant),
    errorMessage: `Error in square Integarion process: ${JSON.stringify(error, null, 2)}`
  })
}

export default function* () {
  yield takeLatest(types.SQUARE_CONNECT_INTENT, handleSquareConnect)
}
