import { types } from '../actions/Order'

const initialState = {
  error: false,
  loadingOrders: false,
  searching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS_INTENT:
      return {
        ...state,
        loadingOrders: true,
        searching: true
      }
    case types.GET_ORDERS_INTENT_RESOLVED:
      return {
        ...state,
        ...action.data,
        loadingOrders: false,
        searching: false,
        error: false
      }

    case types.GET_ORDERS_INTENT_REJECTED:
      return {
        ...state,
        loadingOrders: false,
        searching: false,
        error: true
      }
    
    case types.GET_SINGLE_ORDER_INTENT:
      return {
        ...state,
        orderData: null,
        orderError: false,
      }

    case types.GET_SINGLE_ORDER_INTENT_RESOLVED:
      return {
        ...state,
        orderData: action.data,
        orderError: false
      }

    case types.GET_SINGLE_ORDER_INTENT_REJECTED:
      return {
        ...state,
        orderData: null,
        orderError: true
      }

    case types.CLEAR_ORDERS:
      return {}

    default:
      return state
  }
}
