import React, {useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

import { statuses } from '../../../services/journeys'
import StatelessModal from '../../StatelessModal'

import './styles.scss'

/**
 * Success model content, when campaign is successfully scheduled.
 *
 * @component
 * @example
 *
 *
 * const handleClose = () => {
 * ... closes the success modal
 * }
 *
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return (
 *  <SuccessMessage onClose={ handleClose } data={data} />
 * )
*/
const SuccessMessage = props => {
  const { data, onClose } = props
  const { chain } = useSelector(state => state.User)

  const handleModalClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    // Automatically close success modal after 2 seconds
    setTimeout(() => handleModalClose(), 2000)
  }, [handleModalClose])


  return (
    <StatelessModal className='eg-campaign-success-modal' onClose={ handleModalClose }>
      <div className='eg-campaign-success-message'>
        <div className='success-logo'>
        </div>
        <div className='title'>Journey { data.status === statuses.ACTIVE ? 'Deactivated' : 'Activated' }</div>
        <div className='sublabel'>
          { chain.name } | { data.journey_name }
        </div>
        <div className='content'>
          { moment().tz('America/New_York').format('LLL') } EDT
        </div>
      </div>
    </StatelessModal>
  )
}

SuccessMessage.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
}

export default SuccessMessage
