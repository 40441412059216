import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../../../../FormComponents'
import { types as buttonTypes } from '../../../../../FormComponents/Button'

import './styles.scss'

const AddMessageNode = props => {
  const { onAddClick } = props

  const handleAddClick = () => {
    if (onAddClick) onAddClick()
  }

  return (
    <div className='eg-msg-new-node'>
      <div className='icon' />
      <Button
        className='btn-add'
        type={ buttonTypes.SECONDARY }
        text='ADD MESSAGE'
        onClick={ handleAddClick }
      />
    </div>
  )
}

AddMessageNode.propTypes = {
  onAddClick: PropTypes.func
}

export default AddMessageNode
