import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const FilterModal = (props) => {
  const modalRef = useRef(null)
  const { className='', onClose, children} = props

  useEffect(() => {
    const handleClick = event => {
      event && event.stopPropagation()
      event && event.preventDefault()
      if (modalRef.current.contains(event.target)) {
        return
      }
      if (onClose) {
        onClose()
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [onClose])

  return (
    <>
      <div className='eg-filter-background'></div>
      <div className= {`eg-filter-modal ${className}`} ref={ modalRef }>
        { children }
      </div>
    </>
  )
}

FilterModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default FilterModal
