import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { actions } from '../../../../actions/Campaign'
import { actions as smsActions } from '../../../../actions/SMS'
import { Button, CustomTextarea } from '../../../FormComponents'
import { types as buttonTypes } from '../../../FormComponents/Button'
import PhonePreview, { HeaderSMS } from '../../../PhonePreview'
import TestMessage from '../../TestMessage'
import UrlShortnerModal from '../../../Modals/UrlShortnerModal'
import useValidator from '../../../../hooks/validator'
import { htmlize } from '../../../../utils/strings'
import { parsePreview } from '../../../../services/campaigns'
import EGAnalytic, { analyticEvents } from '../../../../services/analytics'
import { hasNonShortenedLink, getUrlsFromText, upsertShortlinkInMessage } from '../../../../services/sms'
import { fields as setUpFields } from '../Setup'
import SMSAddon from '../../../FormComponents/CustomTextarea/Addons/SMS'
import { userHasAccess } from '../../../../services/auth'
import ConfirmationModal from '../../../ConfirmationModal'
import './styles.scss'

const fields = {
  MESSAGE: 'message'
}

/**
 * DesignCampaign - Step 2 of create SMS campaign flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  campaign_name: 'My campaign'
 * }
 *
 * return (
 *  <DesignCampaign data={ data } onSubmit={ handleSubmit } />
 * )
*/
const DesignCampaign = props => {
  const { submitting, onBackClick, onSubmit } = props
  const [data, setData] = useState(props.data)
  const [displayTestSmsModal, setDisplayTestSmsModal] = useState(false)
  const [testMsgSubmitted, setTestMsgSubmitted] = useState(false)
  const [displayUrlShortner, setDisplayUrlShortner] = useState(false)
  const [uploadingSmsImg, setUploadingSmsImg] = useState(false)
  const [previewSmsImage, setPreviewSmsImage] = useState(_.get(data, 'mms.imageUrl'))
  const [smsImage, setSmsImage] = useState(_.get(data, 'mms'))
  const [displayUrlWarning, setDisplayUrlWarning] = useState(false)
  const [submissionStarted, setSubmissionStarted] = useState(false)
  const { register, watch, formState, errors, setValue, getValues } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.MESSAGE]: data[fields.MESSAGE] || '',
    }
  })
  const { submittingTestMsg, shortLink } = useSelector(state => state.Campaign)
  const { mmsImage } = useSelector(state => state.SMS)
  const { chain, user } = useSelector(state => state.User)
  const dispatch = useDispatch()
  const values = watch()

  useEffect(() => {
    setData(props.data)
  }, [dispatch, props.data])

  // Test message effect
  useEffect(() => {
    if (testMsgSubmitted && !submittingTestMsg) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_SENT, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Campaigns',
        messageContextStep: 'Design',
      })
      setDisplayTestSmsModal(false)
    }
  }, [chain, submittingTestMsg, testMsgSubmitted])

  // Shortlink effect
  useEffect(() => {
    const { success, data } = shortLink
    if (success && data) {
      const formData = values
      const message = upsertShortlinkInMessage(formData[fields.MESSAGE], _.get(shortLink, 'data.shortlink'))
      setValue(fields.MESSAGE, message)
      setDisplayUrlShortner(false)
      EGAnalytic.track(analyticEvents.SHORT_LINK_CREATED, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Campaigns',
        messageContextStep: 'Design',
      })
      if (submissionStarted) {
        handleSubmit()
        setSubmissionStarted(false)
      }
    } else {
      setSubmissionStarted(false)
    }
    return () => {
      // clear shortlink data request redux storage, so it doesn't persist
      dispatch(actions.createShortLinkClear())
      setSubmissionStarted(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shortLink.data, shortLink.success])

  // MMS image upload effect
  useEffect(() => {
    if (mmsImage.success) {
      dispatch(smsActions.clearMMSImageState())
      setUploadingSmsImg(false)
      setSmsImage(_.get(mmsImage, 'data') || null)
    }
  }, [ mmsImage, dispatch ])

  const formIsValid = () => {
    return formState.isValid && !uploadingSmsImg
  }

  const handleTestMessageClick = () => {
    EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Campaigns',
      messageContextStep: 'Design',
    })
    setDisplayTestSmsModal(true)
  }

  const handleTestMessageClose = () => {
    setDisplayTestSmsModal(false)
  }

  const handleTestMessageSubmit = numbers => {
    const data = getValues()
    dispatch(actions.sendTestMessage({
      numbers,
      message: data[fields.MESSAGE],
      imageUrl: _.get(smsImage, 'imageUrl')
    }))
    setTestMsgSubmitted(true)
  }

  const handleBackClick = () => {
    EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      campaignName: data[setUpFields.NAME],
      segmentName: data[setUpFields.SEGMENT].label,
      stepName: 'Design',
      stepAbandonType: 'Back'
    })
    if (onBackClick) onBackClick()
  }

  const handleSubmit = () => {
    const designData = getValues()
    if (onSubmit) {
      EGAnalytic.track(analyticEvents.CAMPAIGN_STEP_COMPLETED, {
        ...EGAnalytic.mapChain(chain),
        campaignName: data[setUpFields.NAME],
        segmentName: data[setUpFields.SEGMENT].label,
        stepName: 'Design'
      })
      onSubmit('design', {
        ...designData,
        mms: {
          image: _.get(smsImage, 'image', null)
        }
      })
    }
  }

  const onUrlSubmit = ({url}) => {
    dispatch(actions.createShortLink({
      campaignId: data.id,
      url
    }))
  }

  const onShortLinkClick = () => {
    const interactionText = _.get(data, 'shortlink.long_url') ? 'Edit short link': 'Add short link'
    EGAnalytic.track(analyticEvents.SHORT_LINK_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Campaigns',
      messageContextStep: 'Design',
      shortLinkButtonContext: interactionText,
    })
    setDisplayUrlShortner(true)
    EGAnalytic.track(analyticEvents.SHORT_LINK_MODAL_DISPLAYED, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Campaigns',
      messageContextStep: 'Design',
    })
  }

  const onShortUrlModalClose = () => {
    EGAnalytic.track(analyticEvents.SHORT_LINK_MODAL_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Campaigns',
      messageContextStep: 'Design',
    })
    setDisplayUrlShortner(false)
  }

  const handleEmojiPick = emojiObject => {
    setValue(fields.MESSAGE, `${values[fields.MESSAGE]} ${emojiObject.emoji}`)
  }

  const handleSmsFileSelection = async (file) => {
    // give an aux url of the image, so we can display
    // the preview with the loading animation
    setPreviewSmsImage(URL.createObjectURL(file))
    setUploadingSmsImg(true)
    dispatch(smsActions.uploadMMSImage({ file, resource: 'campaign', resourceId: data.id }))
  }

  const handlePreviewDelete = () => {
    setPreviewSmsImage(null)
    setSmsImage(null)
  }

  const handleCustomFieldChange = (field) => {
    const formData = values
    const message = `${formData[fields.MESSAGE].trim()} {${field}}`
    setValue(fields.MESSAGE, message)
  }

  const validateBeforeSubmit = () => {
    const designData = getValues()
    if (hasNonShortenedLink(designData[fields.MESSAGE], [data.shortlink])) {
      setDisplayUrlWarning(true)
      return
    }
    handleSubmit()
  }

  const handleSubmitWithShortlink = () => {
    setSubmissionStarted(true)
    const designData = getValues()
    const urls = getUrlsFromText(designData[fields.MESSAGE])
    if (!urls || !urls.length) return
    dispatch(actions.createShortLink({
      campaignId: data.id,
      url: urls[0]
    }))
  }

  const customfieldsEnabled = userHasAccess(user, [])
  const mmsEnabled = userHasAccess(user, [])
  const finalMessageText = `${chain.name}: ${values[fields.MESSAGE]}`

  return (
    <div className='eg-create-campaign-design'>
      <div className='step-body'>
        <div className='panel'>
          <div className='title'>Design your campaign</div>
          <div className='subtitle'>Compose your message below.</div>
          <div className='form'>
            <label htmlFor={fields.MESSAGE}>Message</label>
            <CustomTextarea
              id={fields.MESSAGE}
              name={fields.MESSAGE}
              className={`textarea ${errors[fields.MESSAGE] ? 'error' : ''}`}
              placeholder='Message'
              ref={register({ required: true })}
              addons={[
                {
                  component: () => (
                    <SMSAddon.Component
                      text={ finalMessageText }
                      imagePreview={ previewSmsImage }
                      uploading={ uploadingSmsImg }
                      onDelete={ handlePreviewDelete }
                    />
                  ),
                  triggers: (key) => (
                    <SMSAddon.Triggers
                      mmsImage={ mmsEnabled }
                      customFields={ customfieldsEnabled }
                      key={ key }
                      onFileSelection={ handleSmsFileSelection }
                      onCustomFieldChange={ handleCustomFieldChange }
                    />
                  )
                }
              ]}
              hasShortLink={true}
              hasEmojis
              onShortLinkClick={onShortLinkClick}
              onEmojiPick={ handleEmojiPick }
              shortLink={_.get(data, 'shortlink.long_url')}
            />
            {errors[fields.MESSAGE] && <div className='error-msg'>{errors[fields.MESSAGE].message}</div>}
          </div>
          <div className='actions'>
            <Button
              className='action-button'
              text='BACK'
              type={buttonTypes.SECONDARY}
              onClick={handleBackClick}
            />
            <Button
              className='action-button submit'
              text='CONTINUE'
              loading={ submitting || submissionStarted }
              disabled={!formIsValid()}
              onClick={ validateBeforeSubmit }
            />
          </div>
        </div>
        <div className='panel preview'>
          <PhonePreview className='phone'>
            <HeaderSMS title='' />
            <div className='container'>
              {
                !!previewSmsImage && (
                  <div className='message-wrapper-img' data-testid='sms-preview-message-wrapper'>
                    <img src={ previewSmsImage } alt='mms' width={ 135 } />
                  </div>
                )
              }
              <div className='message-wrapper' data-testid='sms-preview-message-wrapper'>
                <p dangerouslySetInnerHTML={
                  { __html: htmlize(parsePreview(values[fields.MESSAGE], chain) || '<SMS message here>') }
                } />
              </div>
              <div className='preview-test-btn-wrapper'>
                <Button
                  className='preview-test-btn'
                  text='SEND TEST MESSAGE'
                  type={buttonTypes.SECONDARY}
                  disabled={ uploadingSmsImg }
                  onClick={handleTestMessageClick}
                />
              </div>
            </div>
          </PhonePreview>
        </div>
      </div>
      {
        displayTestSmsModal && (
          <TestMessage
            flowName={'Campaigns'}
            step={'Design'}
            submitting={submittingTestMsg}
            onClose={handleTestMessageClose}
            onSubmit={handleTestMessageSubmit}
          />
        )
      }
      { displayUrlShortner && (
        <UrlShortnerModal
          url={_.get(data, 'shortlink.url')}
          onClose={onShortUrlModalClose}
          onSubmit={onUrlSubmit}
          loading={shortLink.submitting}
        />
      )
      }
      {
        displayUrlWarning && (
          <ConfirmationModal
            title="Link can't be tracked"
            text="One or more of the links in your message can't be tracked. Edit your design to add them as a short link."
            className="url-warning-modal"
            confirmButtonLabel="ADD TRACKING"
            cancelButtonLabel="DON'T TRACK"
            onCancel={ () => { setDisplayUrlWarning(false); handleSubmit() } }
            onConfirm={ () => { setDisplayUrlWarning(false); handleSubmitWithShortlink() } }
          />
        )
      }
    </div>
  )
}

DesignCampaign.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default DesignCampaign
