import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { predicateComparison } from '../../../../../../services/segments'

/**
 * constructs date string from date predicates
 * @param {*} props.datePredicates array of date predicates
 * @returns constructed string for date value. i.e ` from Aug 10, 2021 to Aug 25, 2021`
 */
const DateStrings = ({datePredicates}) => {
  if(!datePredicates) return null
  const onDate = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.EQ)
  const fromDate = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.GTE)
  const toDate = datePredicates.find((dateEntity) => dateEntity.comparison === predicateComparison.LTE)
  if(fromDate && toDate) {
    // we have between date( from and to )
    return (
      <span className='predicate-string'>
        {` from ${moment(fromDate.value).format('MMM DD, YYYY')} to ${moment(toDate.value).format('MMM DD, YYYY')}` }
      </span>
    )
  } else if( onDate ) {
    // we have on date.
    return (
      <span className='predicate-string'>
        {` on ${moment(onDate.value).format('MMM DD, YYYY')}`}
      </span>
    )
  } else if( fromDate ) {
    //  we have from date/after date
    return (
      <span className='predicate-string'>
        {` after ${moment(fromDate.value).format('MMM DD, YYYY')}`}
      </span>
    )
  } else if( toDate ) {
    //  we have to date/before date
    return (
      <span className='predicate-string'>
        {` before ${moment(toDate.value).format('MMM DD, YYYY')}`}
      </span>
    )
  } else {
    return null
  }

}

DateStrings.propTypes = {
  datePredicates: PropTypes.any,
}

export default DateStrings
