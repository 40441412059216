import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const backgroundColorsForLogo = {
  0: 'eg-yellow',
  1: 'eg-aqua',
  2: 'eg-blue',
  3: 'eg-medium-orange',
  4: 'eg-light-moss-green'
}

const StoreRow = ({ index, className='' }) => {

  const logoBackround = `eg-store-logo ${backgroundColorsForLogo[index%5]} ${className}`
 
  return (
    <div className={logoBackround}>
      <div/>
    </div>
  )
}

StoreRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
}

export default StoreRow
