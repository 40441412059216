import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { Button } from '../../../FormComponents'
import LocalSpinner from '../../../LocalSpinner'
import { actions as oauthActions } from '../../../../actions/Oauth'
import './styles.scss'
import { modalSubtitle } from '../../../../services/integration'

const FivetranConnect = props => {
  const {  provider, providerName } = props
  const fivetranConnections = useSelector(state => _.get(state, 'OauthConnect.fivetranConnections.list', []))
  const dispatch = useDispatch()
  const chain = useSelector(state => state.User.chain)
  const { sending } = useSelector(state => state.OauthConnect.fivetranConnect)


  const handleSubmitClick = () => {
    dispatch(oauthActions.fivetranConnect({
      chainUuid: chain.uuid,
      provider: provider,
      passRedirect: true,
    }))
  }

  const isProvidedConnected = service => {
    if(!fivetranConnections) return false
    return !!fivetranConnections.find(c => c.service === service && c.status === 'connected')
  }

  const isConnected = isProvidedConnected(provider)

  return (
    <div className='eg-fivetran-connect'>
      <div className={`logo ${provider}`}>
        { isConnected && <div className='check'></div> }
      </div>
      <div className='info-title'>
        { isConnected ? 'Connected' : `${providerName} Connect` }
      </div>
      <div className='info'>
        <ul className='description'>
          <li>
            <span className='list-icon icon-sync'></span>
            <span className='list-title'>
              { isConnected
                ? `Your ${providerName} account is connected.`
                : modalSubtitle[provider]
              }
            </span>
          </li>
        </ul>
        <div className='action'>
          {
            sending
              ? <LocalSpinner />
              : (
                <Button
                  id='fivetran-connect'
                  className='connect-btn'
                  text={ isConnected ? 'Connect with a different account' : `Connect with ${providerName}` }
                  onClick={ handleSubmitClick }
                />
              )
          }

        </div>
      </div>
    </div>
  )
}

FivetranConnect.propTypes = {
  provider: PropTypes.string,
  providerName: PropTypes.string,
}

export default FivetranConnect
