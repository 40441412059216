import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const RatingSummaryCard = props => {
  return (
    <div className={ `${styles.container} ${props.className}` }>
      <div className={ styles.title }>{ props.title }</div>
      <div className={ props.contentClassName }>
        { props.children }
      </div>
    </div>
  )
}

RatingSummaryCard.propTypes = {
  title: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
}

RatingSummaryCard.defaultProps = {
  className: ''
}

export default RatingSummaryCard
