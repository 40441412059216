export const types = {
  GET_INTERACTIONS_INTENT: 'GET_INTERACTIONS_INTENT',
  GET_INTERACTIONS_INTENT_RESOLVED: 'GET_INTERACTIONS_INTENT_RESOLVED',
  GET_INTERACTIONS_INTENT_REJECTED: 'GET_INTERACTIONS_INTENT_REJECTED'
}

export const actions = {
  getInteractions(opts) {
    return {
      type: types.GET_INTERACTIONS_INTENT,
      opts
    }
  },

  getInteractionsResolved(data) {
    return {
      type: types.GET_INTERACTIONS_INTENT_RESOLVED,
      data
    }
  },

  getInteractionsRejected(error) {
    return {
      type: types.GET_INTERACTIONS_INTENT_REJECTED,
      error
    }
  }
}
