import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import IntegrationCard from '../../Store/StoreIntegration/IntegrationCard'
import CloverConnect from './CloverConnect'

import './styles.scss'

const CloverIntegration = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)


  const handleClick = () => {
    if (props.onClick) {
      props.onClick(CloverConnect)
    }
  }

  const isConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  return (
    <div className='eg-clover-integration'>
      <IntegrationCard
        title='Clover'
        subtitle='Connect your contacts and orders'
        logoClassName='clover'
        isConnected={ isConnected('clover') }
        onClick={ handleClick }
      />
    </div>
  )
}

CloverIntegration.propTypes = {
  onClick: PropTypes.func
}

export default CloverIntegration
