import { types } from '../actions/Call'
import { DEFAULT_ITEMS_PER_PAGE } from '../constants'

export const initialState = {
  callList: {
    items: null,
    page: 1,
    size: DEFAULT_ITEMS_PER_PAGE
  },
  loading: false,
  searching: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CALLS_INTENT:
      return {
        ...initialState,
        loading: true,
        error: false
      }

    case types.GET_CALLS_INTENT_RESOLVED:
      return {
        ...state,
        callList: action.data,
        loading: false,
        error: false
      }

    case types.GET_CALLS_INTENT_REJECTED:
      return {
        ...state,
        loading: false,
        error: true
      }

    default:
      return state
  }
}
