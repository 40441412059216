export const types = {
  // Get Store List
  GET_STORE_LIST_INTENT: 'GET_STORE_LIST_INTENT',
  GET_STORE_LIST_INTENT_RESOLVED: 'GET_STORE_LIST_INTENT_RESOLVED',
  GET_STORE_LIST_INTENT_REJECTED: 'GET_STORE_LIST_INTENT_REJECTED',

  // Create store
  CREATE_STORE_INTENT: 'CREATE_STORE_INTENT',
  CREATE_STORE_INTENT_RESOLVED: 'CREATE_STORE_INTENT_RESOLVED',
  CREATE_STORE_INTENT_REJECTED: 'CREATE_STORE_INTENT_REJECTED',

  // Get store
  GET_STORE_INTENT: 'GET_STORE_INTENT',
  GET_STORE_INTENT_RESOLVED: 'GET_STORE_INTENT_RESOLVED',
  GET_STORE_INTENT_REJECTED: 'GET_STORE_INTENT_REJECTED',

  // Update store
  UPDATE_STORE_INTENT: 'UPDATE_STORE_INTENT',
  UPDATE_STORE_INTENT_RESOLVED: 'UPDATE_STORE_INTENT_RESOLVED',
  UPDATE_STORE_INTENT_REJECTED: 'UPDATE_STORE_INTENT_REJECTED',

  // categories
  GET_CATEGORIES_INTENT: 'GET_CATEGORIES_INTENT',
  GET_CATEGORIES_INTENT_RESOLVED: 'GET_CATEGORIES_INTENT_RESOLVED',
  GET_CATEGORIES_INTENT_REJECTED: 'GET_CATEGORIES_INTENT_REJECTED',

  // Get Integration status update i.e plaid/Square is connected or not
  GET_INTEGRATION_STATUS: 'GET_INTEGRATION_STATUS',
  GET_INTEGRATION_STATUS_FAILED: 'GET_INTEGRATION_STATUS_FAILED',
  GET_INTEGRATION_STATUS_RESOLVED: 'GET_INTEGRATION_STATUS_RESOLVED',
}

export const actions = {
  getStoreList() {
    return {
      type: types.GET_STORE_LIST_INTENT
    }
  },

  getStoreListResolved(data) {
    return {
      type: types.GET_STORE_LIST_INTENT_RESOLVED,
      data
    }
  },
  
  getStoreListRejected(error) {
    return {
      type: types.GET_STORE_LIST_INTENT_REJECTED,
      error
    }
  },

  createStore(data) {
    return {
      type: types.CREATE_STORE_INTENT,
      data
    }
  },

  createStoreResolved(data) {
    return {
      type: types.CREATE_STORE_INTENT_RESOLVED,
      data
    }
  },

  createStoreRejected(error) {
    return {
      type: types.CREATE_STORE_INTENT_REJECTED,
      error
    }
  },

  getStore(opts) {
    return {
      type: types.GET_STORE_INTENT,
      data: opts
    }
  },

  getStoreResolved(data) {
    return {
      type: types.GET_STORE_INTENT_RESOLVED,
      data
    }
  },

  getStoreRejected(error) {
    return {
      type: types.GET_STORE_INTENT_REJECTED,
      error
    }
  },

  updateStore(data, section) {
    return {
      type: types.UPDATE_STORE_INTENT,
      data,
      section
    }
  },

  updateStoreResolved(data) {
    return {
      type: types.UPDATE_STORE_INTENT_RESOLVED,
      data
    }
  },

  updateStoreRejected(error) {
    return {
      type: types.UPDATE_STORE_INTENT_REJECTED,
      error
    }
  },

  // categories
  getCategories() {
    return {
      type: types.GET_CATEGORIES_INTENT
    }
  },

  getCategoriesResolved(data) {
    return {
      type: types.GET_CATEGORIES_INTENT_RESOLVED,
      data
    }
  },

  getCategoriesRejected(error) {
    return {
      type: types.GET_CATEGORIES_INTENT_REJECTED,
      error
    }
  },

  getIntegrationStatus(data) {
    return {
      type: types.GET_INTEGRATION_STATUS,
      data,
    }
  },

  getIntegrationStatusResolved(data) {
    return {
      type: types.GET_INTEGRATION_STATUS_RESOLVED,
      data
    }
  },

  getIntegrationStatusFailed(error) {
    return {
      type: types.GET_INTEGRATION_STATUS_FAILED,
      data: error
    }
  }
}
