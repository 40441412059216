import React from 'react'
import PropTypes from 'prop-types'

import { userRoles } from '../../../../../services/auth'
import { getEmptyLinks } from '../../../../../services/stores'
import PercentBar from '../../../../PercentBar'
import AccessControlledComponent from '../../../../AccessControlledComponent'
import './styles.scss'

const ProfileComplete = ({ store }) => {
  const completedPercentage = store.profileCompletion * 100
  const percentBarColor = completedPercentage > 50 ? 'fill-green' : 'fill-yellow'
  const emptyLinks = getEmptyLinks(store)

  /**
   * Renders 2 empty links section lables
   */
  const renderAddLinkPart = () => {
    // Going to render only 2 starting empty links 
    return emptyLinks.slice(0,2).map((link, index) => {
      // Some label contains URL name in their label
      // Let's remove that
      const linkLabel = link.label ? link.label.replace('URL', '') : link.label
      return  <div key={index}>Add {linkLabel} Link</div>
    })
  }

  /**
   * Renders a text to tell user about remaining empty data/link for store
   */
  const renderTextForRemainingLink = () => {
    const otherLinkCount = emptyLinks.length - 2
    if(otherLinkCount < 0) return null
    return (
      <div>
        +{otherLinkCount} more
      </div>
    ) 
  }

  return (
    <div className='eg-profile-complete'>
      <div className='title'>
        {`${typeof completedPercentage === 'number' && parseInt(completedPercentage)}% complete`}
      </div>
      <PercentBar
        className='percent-bar'
        barClassName={percentBarColor}
        value={completedPercentage}
        displayValues={false}
      />
      <AccessControlledComponent roles={ [userRoles.RESTAURANT_ADMIN] }>
        <div className='add-section'>
          { renderAddLinkPart() }
          { renderTextForRemainingLink() }
        </div>
      </AccessControlledComponent>
    </div>
  )
}

ProfileComplete.propTypes = {
  store: PropTypes.object.isRequired,
}

export default ProfileComplete
