export const errorMessages = {
  INVALID_EMAIL: 'Invalid email address'
}

const _email = (fieldName) => [
  {
    fieldName,
    method: 'isEmail',
    validWhen: true,
    message: errorMessages.INVALID_EMAIL
  }
]

// Phone reg exp matches against strings with the following format `555-555-5555`.
// reference |> https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
const phoneRegExp = /^((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})?$/
const _phone = (fieldName) => [
  {
    fieldName,
    method: 'matches',
    args: [ phoneRegExp ],
    validWhen: true,
    message: 'Please use a valid US phone number format: i.e 5555555555, 555-555-5555, +1 5555555555'
  }
]

// Float reg exp allows numbers with (or without) decimals separated by a dot. E.g `63.58`
const floatRegExp = /^(\d+(\.\d)?\d*)?$/
const _numeric = (fieldName) => [
  {
    fieldName,
    method: 'matches',
    args: [ floatRegExp ],
    validWhen: true,
    message: 'Only numbers allowed: 63.58'
  }
]

/* Password reg exp verifies that the password string contains a minimum of 8 chars,
   at least one number and one letter */
const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
const _password = (fieldName) => [
  {
    fieldName,
    method: 'matches',
    args: [ passwordRegExp ],
    validWhen: true,
    message: 'Password must have at least 8 characters, one number and one letter'
  },
]

export const required = (fieldName) => [
  {
    fieldName,
    method: 'isEmpty',
    validWhen: false,
    message: 'This field is required'
  }
]

const _matchingFields = (fieldName, fieldToCompare, errorMessage) => [
  {
    fieldName,
    fieldToCompare,
    method: 'equals',
    validWhen: true,
    message: errorMessage ? errorMessage : `${fieldName} and ${fieldToCompare} should match.`
  }
]

const _customRegex = (fieldName, regex, message) => [
  {
    fieldName,
    method: 'matches',
    args: [ regex ],
    validWhen: true,
    message
  }
]

const URL_REGEX = /^((https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)?$/
const _url = fieldName => [
  {
    fieldName,
    method: 'matches',
    args: [ URL_REGEX ],
    validWhen: true,
    message: 'Hmm, this doesn\'t look like a correct URL'
  }
]

/**
 * Validates that the email has the expected format. Required by default.
 * @param {string} fieldName 
 * @param {boolean} isRequired 
 */
export const email = (fieldName, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._email(fieldName)
] : [ ..._email(fieldName) ]

/**
 * Validates that the phone follows the expected pattern. Required by default.
 * @param {string} fieldName 
 * @param {boolean} isRequired 
 */
export const phone = (fieldName, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._phone(fieldName)
] : [ ..._phone(fieldName) ]

/**
 * Validates that the password matches the stablished rules. Required by default.
 * @param {string} fieldName 
 * @param {boolean} isRequired 
 */
export const password = (fieldName, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._password(fieldName)
] : [ ..._password(fieldName) ]

/**
 * Validates if a string is a number. Required by default.
 * @param {string} fieldName 
 * @param {boolean} isRequired 
 */
export const numeric = (fieldName, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._numeric(fieldName)
] : [ ..._numeric(fieldName) ]

/**
 * Tries to match the given field with the field to compare. Alternatively an error message can be provided.
 *
 * @param {string} fieldName
 * @param {string} fieldCompare 
 * @param {string} errorMessage 
 * @param {boolean} isRequired 
 */
export const matchingFields = (fieldName, fieldCompare, errorMessage, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._matchingFields(fieldName, fieldCompare, errorMessage)
] : [ ..._matchingFields(fieldName, fieldCompare, errorMessage) ]

/**
 * Validates a custom regex. Required by default.
 * @param {string} fieldName 
 * @param {string} regex
 * @param {string} message
 * @param {boolean} isRequired 
 */
export const customRegex = (fieldName, regex, errorMessage, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._customRegex(fieldName, regex, errorMessage)
] : [ ..._customRegex(fieldName, regex, errorMessage) ]

/**
 * validates a url
 *
 * @param {string} fieldName    - field name to validate
 * @param {boolean} isRequired  - indicates if the field is required
 *
 * @returns {array} - array of validators
*/
export const url = (fieldName, isRequired = true) => isRequired ? [
  ...required(fieldName),
  ..._url(fieldName)
] : [ ..._url(fieldName) ]
