import _ from 'lodash'
import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

export const getRoleInformation = async (token, accessToken) => {
  return axios({
    url: `${process.env.REACT_APP_API_URL}/users/auth_data`,
    method: METHODS.GET,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'AuthAccessToken': accessToken
    },
  })
}

export const recoverPassword = async (email) => {
  return axios({
    url: `${process.env.REACT_APP_API_URL}/users/forgot_password`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_AUTH_API_KEY
    },
    crossDomain: true,
    data: JSON.stringify({ email })
  })
}

export const confirmPassword = async ({ email, verificationCode, password }) => {
  return axios({
    url: `${process.env.REACT_APP_API_URL}/users/confirm_password`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_AUTH_API_KEY
    },
    crossDomain: true,
    data: JSON.stringify({ email, verificationCode, password })
  })
}

//TODO: I think we are not using it anywhere, Perhaps we should delete it.
export const getRestaurants = (tokens) => {
  return axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/restaurants`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

export const updateUserInfo = (data, tokens) => {
  const accessToken = _.get(tokens, 'accessToken.jwtToken')
  // TODO: uncomment when we can edit phone in cognito
  // let phone = data.phone_number
  // if (!data.phone_number.match(/^\+/)) {
  //   phone = `+1${data.phone_number.replace(/-/g, '')}`
  // }

  return axios({
    url: `${process.env.REACT_APP_API_URL}/users`,
    method: 'PUT',
    headers: {
      ...HeaderFactory(tokens),
      AuthAccessToken: accessToken
    },
    crossDomain: true,
    data: JSON.stringify({
      given_name: data.given_name,
      family_name: data.family_name
      // phone_number: phone <-- TODO: uncomment when we can edit phone in cognito
    })
  })
}

export const updateUserPassword = (data, tokens) => {
  const accessToken = _.get(tokens, 'accessToken.jwtToken')

  return axios({
    url: `${process.env.REACT_APP_API_URL}/users/password`,
    method: 'PUT',
    headers: {
      ...HeaderFactory(tokens),
      AuthAccessToken: accessToken
    },
    crossDomain: true,
    data: JSON.stringify({
      old_password: data.old_password,
      new_password: data.new_password
    })
  })
}

export const getPlanToken = (data, tokens) => {
  const { email, sub, chainUuid } = data
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/plan/token?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify({
      customer_email: email,
      customer_source_id: sub
    })
  })
}
