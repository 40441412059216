import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { channelTypes, sortSubchannelsBy } from '../../../services/channels'
import { toCurrency } from '../../../utils/number'
import ChannelSection from './ChannelSection'
import ConnectCard from '../ConnectCard'
import SortingHeaders from '../../SortingHeaders'
import './styles.scss'

const listHeaders = [
  { label: 'CHANNELS', key: 'channels', className: 'title', sortable: true },
  { label: 'SPEND', key: 'spend', className: 'spend', sortable: true },
  { label: 'GROSS PROFIT', key: 'income', className: 'income', sortable: true },
  { label: 'AVG. TICKET', key: 'avgTicket', className: 'avg-ticket', sortable: true },
  { label: 'ROI', key: 'roi', className: 'roi', sortable: true }
]

const ChannelList = props => {
  const [ currentSorting, setCurrentSorting ] = useState()
  const { channels, hasChannels, totals } = props

  const groupChannels = type => {
    const sectionChannels = channels.filter(c => c.type === type)
    return sortSubchannelsBy(sectionChannels, currentSorting)
  }

  const groupUnknownChannels = _ => {
    const sectionChannels = channels.filter(c => c.source === 'unknown')
    return sortSubchannelsBy(sectionChannels, currentSorting)
  }

  const handleSortChange = field => {
    setCurrentSorting(field)
  }

  return (
    <div className='eg-channel-list'>
      <div className='mobile-sort-title'>Sort by</div>
      <SortingHeaders
        fields={ listHeaders }
        className='column-names'
        currentSorting={ currentSorting }
        onChange={ handleSortChange }
      />
      <div className='totals'>
        <div className='title'>All</div>
        <div className='spend mobile-label'>SPEND</div>
        <div className='income mobile-label'>GROSS PROFIT</div>
        <div className='avg-ticket mobile-label'>AVG. TICKET</div>
        <div className='roi mobile-label'>ROI</div>
        <div className='spend'>{ toCurrency(totals.spend) }</div>
        <div className='income'>{ toCurrency(totals.income) }</div>
        <div className='avg-ticket'>{ toCurrency(totals.avg_ticket) }</div>
        <div className='roi'>{totals.roi ? `${totals.roi.toFixed(2)}x` : '-'}</div>
      </div>
      {
        hasChannels ? (
          <div>
            <ChannelSection channels={ groupChannels(channelTypes.EMAIL) } type={ channelTypes.EMAIL } />
            <ChannelSection channels={ groupChannels(channelTypes.MESSAGING) } type={ channelTypes.MESSAGING } />
            <ChannelSection channels={ groupChannels(channelTypes.RESERVATIONS) } type={ channelTypes.RESERVATIONS } />
            <ChannelSection channels={ groupChannels(channelTypes.SEARCH) } type={ channelTypes.SEARCH } />
            <ChannelSection channels={ groupChannels(channelTypes.SOCIAL) } type={ channelTypes.SOCIAL } />
            <ChannelSection channels={ groupChannels(channelTypes.IN_STORE) } type={ channelTypes.IN_STORE } />
            <ChannelSection channels={ groupUnknownChannels() } type={ channelTypes.UNKNOWN } isExpandable={false}/>
          </div>
        ) : (
          <div className='connect-wrapper'>
            <ConnectCard />
          </div>
        )
      }
    </div>
  )
}

ChannelList.propTypes = {
  channels: PropTypes.array,
  hasChannels: PropTypes.bool,
  totals: PropTypes.object
}

export default ChannelList
