import { types } from '../actions/Customer'
import { DEFAULT_ITEMS_PER_PAGE } from '../constants'

export const initialState = {
  customerList: {
    page: 0,
    size: DEFAULT_ITEMS_PER_PAGE,
    total: 0
  },
  customerInteractions: {
    customer: null,
    interactions: [],
    // Activities as it is, we get from the backend.
    rawActivites: []
  },
  loadingCustomer: false,
  loadingOrders: false,
  loadingCustomers: false,
  loadingOrderSummary: false,
  loadingInteractions: false,
  searching: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_INTENT:
      return {
        ...state,
        loadingCustomer: true
      }

    case types.GET_CUSTOMER_INTENT_RESOLVED:
      return {
        ...state,
        selectedCustomer: action.data,
        loadingCustomer: false,
        error: false
      }

    case types.GET_CUSTOMER_INTENT_REJECTED:
      return {
        ...state,
        loadingCustomer: false,
        error: true
      }

    case types.GET_CUSTOMERS_INTENT:
      return {
        ...state,
        loadingCustomers: true
      }

    case types.GET_CUSTOMERS_INTENT_RESOLVED:
      return {
        ...state,
        customerList: action.data,
        loadingCustomers: false
      }

    case types.GET_CUSTOMERS_INTENT_REJECTED:
      return {
        ...state,
        customerList: {
          ...initialState.customerList,
          ...action.data
        },
        loadingCustomers: false
      }

    case types.GET_CUSTOMER_INTERACTIONS_INTENT:
      return {
        ...state,
        loadingInteractions: true,
        error: null
      }

    case types.GET_CUSTOMER_INTERACTIONS_INTENT_RESOLVED:
      return {
        ...state,
        customerInteractions: action.data,
        loadingInteractions: false,
        error: null
      }

    case types.GET_CUSTOMER_INTERACTIONS_INTENT_REJECTED:
      return {
        ...state,
        loadingInteractions: false,
        error: action.error
      }

    case types.CLEAR_CUSTOMERS:
      return { ...initialState }

    default:
      return state
  }
}
