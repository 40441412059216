import React from 'react'
import PropTypes from 'prop-types'

/**
 * To render custom X axis label for campaign chart.
 * @param {*} props information about the label.
 * @returns An SVG g tag to render as label
 */
const CustomizedAxisTick = (props) => {
  const {x, y, payload, fill} = props
  const [time, date] = payload.value.split('  ')
   	return (
    	<g transform={`translate(${x},${y})`} fill={fill}>
      <text x={0} y={0} dy={0}>
        <tspan  fontSize={12} x="0" dx="-20" dy="20">{time}</tspan>
        <tspan fontSize={12}  x="0" dx="-20" dy="16">{date}</tspan>
      </text>
    </g>
  )
}


CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  fill: PropTypes.string,
}

export default CustomizedAxisTick