import React from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import _ from 'lodash'

import EGAnalytic, { analyticEvents } from '../../services/analytics'

import CustomerHeader from '../../components/CustomerHeader'
import ExportReview from '../../components/Reviews/ExportReview'
import RatingSummary from '../../components/Reviews/RatingSummary'
import StoreBreakdown from '../../components/Reviews/StoreBreakdown'
import RatingSummaryCard from '../../components/Reviews/RatingSummary/RatingSummaryCard'
import RatingStars from '../../components/RatingStars'
import LocalSpinner from '../../components/LocalSpinner'
import StoreFilter from '../../components/Store/StoreFilter'
import './styles.scss'

// filters
import SourceFilter from '../../components/Reviews/Filters/SourceFilter'
import RatingFilter from '../../components/Reviews/Filters/RatingFilter'
import DateFilter from '../../components/Reviews/Filters/DateFilter'
import StatusFilter from '../../components/Reviews/Filters/StatusFilter'

import { getNumberWithCommas } from '../../utils/number'

const filterTypes = {
  DATE: 'date',
  SOURCE: 'source',
  RATING: 'rating',
  STATUS: 'status'
}
const initialFiltersState = {
  [filterTypes.DATE]: { actionLabel: 'All Time', filterKeys: [] },
  [filterTypes.SOURCE]: { actionLabel: 'All Sources', filterKeys: [] },
  [filterTypes.RATING]: { actionLabel: 'All Ratings', filterKeys: [] },
  [filterTypes.STATUS]: { actionLabel: 'All Statuses', filterKeys: [] }
}

export default class Reviews extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    ratings: PropTypes.object.isRequired,
    ratingsLoading: PropTypes.bool.isRequired,
    getRatingsSummary: PropTypes.func.isRequired,
    getStoreBreakDown: PropTypes.func.isRequired,
    storeBreakdown: PropTypes.object.isRequired,
    lastUsedChainUuid: PropTypes.string,
    getStoreList: PropTypes.func.isRequired,
    selectedStores: PropTypes.array.isRequired,
    clearStoreFilter: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      filters: initialFiltersState,
      // dates: initialSelectedDate,
    }

    // bindings
    this.getStoreList = this.getStoreList.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterApply = this.handleFilterApply.bind(this)
    this.renderFilters = this.renderFilters.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.doesHaveFilters = this.doesHaveFilters.bind(this)
    this.renderClearFilterButton = this.renderClearFilterButton.bind(this)
  }

  componentDidMount() {
    EGAnalytic.track(analyticEvents.REVIEWS_OVERVIEW_PAGE_VIEWED, {...EGAnalytic.mapRestaurant(this.props.restaurant)})
    this.getStoreList()
    this.props.getRatingsSummary({})
    this.props.getStoreBreakDown({})
  }


  onFilterChange() {
    const opts = {filters: this.state.filters}
    this.props.getRatingsSummary(opts)
    this.props.getStoreBreakDown(opts)
  }

  getStoreList() {
    const { lastUsedChainUuid, getStoreList, restaurant } = this.props
    const restaurantChainUuid = restaurant.restaurant_chain_uuid
    // get store list for filters,  if we havne't retrieved yet
    if(lastUsedChainUuid !== restaurantChainUuid){
      getStoreList()
    }
  }



  /**
   * handle changes in the filter, updating only the label
   * we don't update the filterKeys until user applies the changes
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterChange(filter, filterType) {
    const filterKeys = this.state.filters[filterType].filterKeys
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: {
          actionLabel: filter.actionLabel,
          filterKeys
        }
      }
    })
  }

  /**
   * handle apply filter, updating the label and also the selected items
   *
   * @param {object} filter - filter object
   * @param {string} filterType - one of the types defined in `filterTypes`
  */
  handleFilterApply(filter, filterType) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterType]: filter
      }
    }, this.onFilterChange)
  }


  clearFilters() {
    this.props.clearStoreFilter()
    this.setState({ filters: initialFiltersState }, this.onFilterChange)

  }

  /**
   * Checks wether any filter has been applied or not
   */
  doesHaveFilters() {
    const { filters } = this.state
    const { selectedStores = [] } = this.props
    if( selectedStores.length ||
      _.get(filters[filterTypes.DATE], 'filterKeys.length') ||
      _.get(filters[filterTypes.SOURCE], 'filterKeys.length') ||
      _.get(filters[filterTypes.RATING], 'filterKeys.length') ||
      _.get(filters[filterTypes.STATUS], 'filterKeys.length')) {
      return true
    }
    return false
  }

  renderClearFilterButton() {
    if(!this.doesHaveFilters()) return null
    return (
      <div className='clear' onClick={ this.clearFilters }>
        CLEAR ALL
      </div>
    )
  }

  renderFilters() {
    return (
      <div className='filter'>
        <StoreFilter onFilterChange={this.onFilterChange} className='storeFilter'/>
        <SourceFilter
          filter={ this.state.filters[filterTypes.SOURCE] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.SOURCE) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.SOURCE) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.SOURCE) }
          actionLabel={ this.state.filters[filterTypes.SOURCE].actionLabel }
        />
        <DateFilter
          filter={ this.state.filters[filterTypes.DATE] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.DATE) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.DATE) }
          actionLabel={ this.state.filters[filterTypes.DATE].actionLabel }
        />

        <RatingFilter
          filter={ this.state.filters[filterTypes.RATING] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.RATING) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.RATING) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.RATING) }
          actionLabel={ this.state.filters[filterTypes.RATING].actionLabel }
        />

        <StatusFilter
          filter={ this.state.filters[filterTypes.STATUS] }
          onChange={ (filter) => this.handleFilterChange(filter, filterTypes.STATUS) }
          onApply={ (filter) => this.handleFilterApply(filter, filterTypes.STATUS) }
          onClear={ (filter) => this.handleFilterApply(filter, filterTypes.STATUS) }
          actionLabel={ this.state.filters[filterTypes.STATUS].actionLabel }
        />

        {this.renderClearFilterButton()}
      </div>
    )
  }

  render() {
    const {ratings = {}} = this.props
    const ratingAverage = _.get(this.props, 'storeBreakdown.summary.rating')
    const ratingTotal = _.get(this.props, 'storeBreakdown.summary.rateCount')
    // const interceptedCount = _.get(this.props, 'ratings.interceptedCount')
    // const promotedCount = _.get(this.props, 'ratings.promotedCount')
    // const requestedCount = _.get(this.props, 'ratings.requestedCount')

    return (
      <>
      <CustomerHeader />
      <div className='eg-review-overview'>
        <h2>Ratings</h2>
        <div className='filter-row'>
          {this.renderFilters()}
          <ExportReview filters={this.state.filters} />
        </div>
        {
          !this.props.ratingsLoading ? (
            <>
              <div className='overview'>
                <RatingSummaryCard title='Rating average' className='averageCard'>
                  <div className='averageWrapper'>
                    <div className='averageValue'>{ ratingAverage && ratingAverage.toFixed(1) }</div>
                    <RatingStars rate={ ratingAverage } className='starsWrapper' />
                  </div>
                </RatingSummaryCard>

                <RatingSummaryCard title='Total reviews' className='totalWrapper'>
                  <div className='averageValue'>{ getNumberWithCommas(ratingTotal) }</div>
                </RatingSummaryCard>

                {/* <RatingSummaryCard title='Ratings requested' className='totalWrapper'>
                  <div className='averageValue'>{ getNumberWithCommas(requestedCount) }</div>
                </RatingSummaryCard> */}
                {/*
                <RatingSummaryCard title='Reviews promoted' className='totalWrapper'>
                  <div className='averageValue'>{ getNumberWithCommas(promotedCount) }</div>
                </RatingSummaryCard>

                <RatingSummaryCard title='Reviews intercepted' className='totalWrapper'>
                  <div className='averageValue'>{ getNumberWithCommas(interceptedCount) }</div>
                </RatingSummaryCard> */}
              </div>
              {/*
                <ReviewChart filters={this.state.filters}/>
              */}
              <div className='breakDown'>
                <RatingSummary
                  ratings={ ratings}
                />
                <StoreBreakdown
                  data={ this.props.storeBreakdown }
                />
              </div>
            </>
          ) : (
            <LocalSpinner className= 'spinner' />
          )
        }
      </div>
      </>
    )
  }
}
