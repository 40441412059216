function isUsingMouse () {
  document.body.classList.remove('eg-a11y-keyboard-navigation')
  document.body.classList.remove('eg-a11y-touch-navigation')
}

function isUsingKeyboard (event) {
  const TAB_KEYCODE = 9
  if (event.keyCode === TAB_KEYCODE) {
    document.body.classList.add('eg-a11y-keyboard-navigation')
    document.body.classList.remove('eg-a11y-touch-navigation')
  }
}

function isUsingTouch () {
  document.body.classList.add('eg-a11y-touch-navigation')
  document.body.classList.remove('eg-a11y-keyboard-navigation')
}

export function addKeyboardNavigation () {
  document.body.addEventListener('mousedown', isUsingMouse)
  document.body.addEventListener('keydown', isUsingKeyboard)
  document.body.addEventListener('touchstart', isUsingTouch)
}

export function removeKeyboardNavigation () {
  document.body.removeEventListener('mousedown', isUsingMouse)
  document.body.removeEventListener('keydown', isUsingKeyboard)
  document.body.removeEventListener('touchstart', isUsingTouch)
}
