export const types = {
  SAVE_SMS_LOYALTY_INTENT: 'SAVE_SMS_LOYALTY_INTENT',
  SAVE_SMS_LOYALTY_INTENT_RESOLVED: 'SAVE_SMS_LOYALTY_INTENT_RESOLVED',
  SAVE_SMS_LOYALTY_INTENT_REJECTED: 'SAVE_SMS_LOYALTY_INTENT_REJECTED',

  SAVE_SMS_RATING_INTENT: 'SAVE_SMS_RATING_INTENT',
  SAVE_SMS_RATING_INTENT_RESOLVED: 'SAVE_SMS_RATING_INTENT_RESOLVED',
  SAVE_SMS_RATING_INTENT_REJECTED: 'SAVE_SMS_RATING_INTENT_REJECTED',

  GET_SAVED_STORE_INTENT: 'GET_SAVED_STORE_INTENT',
  GET_SAVED_STORE_INTENT_RESOLVED: 'GET_SAVED_STORE_INTENT_RESOLVED',
  GET_SAVED_STORE_INTENT_REJECTED: 'GET_SAVED_STORE_INTENT_REJECTED',

  RESET_SAVED: 'RESET_SAVED'
}

export const actions = {
  saveSmsLoyalty(data) {
    return {
      type: types.SAVE_SMS_LOYALTY_INTENT,
      data
    }
  },

  saveSmsLoyaltyResolved(data) {
    return {
      type: types.SAVE_SMS_LOYALTY_INTENT_RESOLVED,
      data
    }
  },

  saveSmsLoyaltyRejected(error) {
    return {
      type: types.SAVE_SMS_LOYALTY_INTENT_REJECTED,
      error
    }
  },

  saveSmsRatingSetting(data) {
    return {
      type: types.SAVE_SMS_RATING_INTENT,
      data
    }
  },

  saveSmsRatingSettingResolved(data) {
    return {
      type: types.SAVE_SMS_RATING_INTENT_RESOLVED,
      data
    }
  },

  saveSmsRatingSettingRejected(error) {
    return {
      type: types.SAVE_SMS_RATING_INTENT_REJECTED,
      error
    }
  },

  getSavedStore(store) {
    return {
      type: types.GET_SAVED_STORE_INTENT,
      data: store
    }
  },

  getSavedStoreResolved(data) {
    return {
      type: types.GET_SAVED_STORE_INTENT_RESOLVED,
      data
    }
  },

  getSavedStoreRejected(error) {
    return {
      type: types.GET_SAVED_STORE_INTENT_REJECTED,
      error
    }
  },
  
  resetSaved() {
    return {
      type: types.RESET_SAVED
    }
  }
}
