import { types } from '../actions/Upload'

const initialState = {
  error: null,
  uploading: false,
  hasDocUploaded: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_DOC_INTENT:
      return {
        ...state,
        error: null,
        uploading: true,
        hasDocUploaded: false,
      }

    case types.UPLOAD_DOC_RESOLVED:
      return {
        ...state,
        error: null,
        uploading: false,
        hasDocUploaded: true,
      }

    case types.UPLOAD_DOC_REJECTED:
      return {
        ...state,
        error: action.error,
        uploading: false,
        hasDocUploaded: false,
      }

    default:
      return state
  }
}
