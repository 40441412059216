import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { actions } from '../../../actions/Review'
import StatelessModal from '../../StatelessModal'

import RadioButton from '../../FormComponents/RadioButton'
import { constructSelectedFilterTextInfo } from './util.js'
import  './styles.scss'

const ExportReview = (props) => {
  const { filters, search } = props
  const dispatch = useDispatch()
  const [exportModal, setExportModal] = useState(false)
  const [downloadSelectedData, setDownloadSelectedData] = useState(true)
  const selectedStore = useSelector(({ Filter }) => Filter.selectedStores)

  // const isAdmin = useSelector(({ User }) => User.user.isAdmin)
  // // If user is not admin, then we are not going to render anything related with export feature
  // if(!isAdmin) {
  //   return null
  // }

  const onExportModalOpen = () => {
    setExportModal(true)
  }

  const onExportModalClose = () => {
    setExportModal(false)
  }

  const renderCsvModal = () => {
    if(exportModal) {
      const selectedFilterText = constructSelectedFilterTextInfo(selectedStore, filters, search)
      return (
        <StatelessModal
          onClose={ onExportModalClose }
          className='eg-export-madal'
          backGroundClassName='eg-export-background'>
          <div className='content'>
            <div className='title'>Export reviews</div>
            <RadioButton
              selected={downloadSelectedData}
              onClick={() => setDownloadSelectedData(true)}
              label= 'Selected data'
            >
              Export only data macthing the selected filters <br/>
              {selectedFilterText}
            </RadioButton>
            <RadioButton
              selected={!downloadSelectedData}
              onClick={() => setDownloadSelectedData(false)}
              label= 'All data'
            >
              Export all available data all time
            </RadioButton>
            <div className='footer'>
              <div className='button' onClick={onExportClick}>EXPORT</div>
            </div>
          </div>
        </StatelessModal>
      )
    }
    return null
  }

  const onExportClick = () => {
    setExportModal(false)
    if(downloadSelectedData) {
      const opts = { filters, search }
      dispatch(actions.getExportReview(opts))
    } else {
      dispatch(actions.getExportReview({allData: true}))
    }
  }

  return (
    <div className='eg-export-review'>
      <div onClick={onExportModalOpen} className='button'>
        <div className='logo' />EXPORT
      </div>
      {renderCsvModal()}
    </div>
  )
}

ExportReview.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.any.isRequired,
}

export default ExportReview
