import { linkTypes } from '../../../../../services/stores'

const {
  FACEBOOK,
  GOOGLE,
  YELP,
  INSTAGRAM,
  TRIPADVISOR,
  LINKEDIN,
  TWITTER
} = linkTypes

export const SOCIAL_MEDIA_LINKS = [
  {
    label: 'FACEBOOK',
    key: FACEBOOK,
  },
  {
    label: 'GOOGLE',
    key: GOOGLE,
  },
  {
    label: 'YELP',
    key: YELP,
  },
  {
    label: 'INSTAGRAM',
    key: INSTAGRAM,
  },
  {
    label: 'TRIPADVISOR',
    key: TRIPADVISOR,
  },
  {
    label: 'LINKEDIN',
    key: LINKEDIN,
  },
  {
    label: 'TWITTER',
    key: TWITTER,
  } 
]
