import React from 'react'
import PropTypes from 'prop-types'

import { Input, Button } from '../../../FormComponents'
import { types as buttonTypes } from '../../../FormComponents/Button'
import useValidator from '../../../../hooks/validator'

import './styles.scss'

export const fields = {
  NAME: 'name',
  DESCRIPTION: 'description'
}

/**
 * SetupSegment - Step 1 of create dynamic segment
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  name: 'My segment' -> to be updated by this component
 * }
 *
 * return (
 *  <SetupSegment data={ data } onSubmit={ handleSubmit } />
 * )
*/
const SetupSegment = props => {
  const { data, submitting, onCancel, onSubmit } = props
  const { register, errors, formState, getValues } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.NAME]: data[fields.NAME] || '',
      [fields.DESCRIPTION]: data[fields.DESCRIPTION] || '',
    }
  })

  const formIsValid = () => {
    return formState.isValid
  }

  const handleSubmit = () => {
    const data = getValues()
    onSubmit('setup', {
      [fields.NAME]: data[fields.NAME],
      [fields.DESCRIPTION]: data[fields.DESCRIPTION]
    })
  }

  return (
    <div className='eg-segment-create-setup'>
      <div className='step-body'>
        <div className='title'>
          Let’s get started setting up your new segment.
        </div>
        <div className='subtitle'>
          Give your segment a name and a short description.
        </div>
        <div className='form'>
          <label htmlFor={fields.NAME}>Name</label>
          <div className='row'>
            <Input
              id={fields.NAME}
              name={fields.NAME}
              placeholder='E.g. New Subscribers'
              className={`${errors[fields.NAME] ? 'error' : ''}`}
              ref={register({ required: true })}
            />
            {errors[fields.NAME] && (
              <div className='error-msg'>
                {errors[fields.NAME].message}
              </div>
            )}
          </div>
          <div className='row'>
            <label htmlFor={fields.DESCRIPTION}>Description (optional)</label>
            <Input
              id={fields.DESCRIPTION}
              name={fields.DESCRIPTION}
              placeholder='E.g. Susbcribers who signed up in the last 7 days'
              className={`${errors[fields.DESCRIPTION] ? 'error' : ''}`}
              ref={ register() }
            />
          </div>
        </div>
      </div>
      <div className='actions'>
        <Button
          className='action-button'
          text='CANCEL'
          type={buttonTypes.SECONDARY}
          onClick={ onCancel }
        />
        <div>
          <Button
            className='action-button submit'
            text='CONTINUE'
            disabled={!formIsValid()}
            loading={ submitting }
            onClick={ handleSubmit }
          />
        </div>
      </div>
    </div>
  )
}

SetupSegment.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default SetupSegment
