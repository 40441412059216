import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import StatelessModal from '../../StatelessModal'
import { Input, Button } from '../../FormComponents'
import { types as buttonTypes } from '../../FormComponents/Button'
import useValidator from '../../../hooks/validator'
import './styles.scss'

const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

const fields = {
  URL: 'url',
  // UTM: 'utm',
}

const UrlShortnerModal = (props) => {
  const { url, onClose, loading=false, onSubmit } = props
  const { register, formState, errors, getValues } = useValidator({
    mode: 'all',
    defaultValues: {
      [fields.URL]: url || '',
      // [fields.UTM]: data[fields.UTM] || '',
    }
  })
  const handleSubmit = () => {
    onSubmit && onSubmit(getValues())
  }

  return (
    <StatelessModal onClose={onClose} className='eg-url-shortner-modal' >
      <div className='eg-url-shortner' data-testid='shorturl-modal'>
        <div className='title'>
          {
            url
              ? 'Edit short link'
              : 'Add a short link'
          }
        </div>
        <div className='form'>
          <div className='label'>Original URL</div>
          <div className='info'>
            Links must start with http:// or https://
          </div>
          <Input
            id={fields.URL}
            name={fields.URL}
            placeholder='http://zerostorefront.com/products'
            ref={register({
              required: true,
              pattern: {
                value: URL_REGEX,
                message: 'Invalid URL, Please type correct URL.'
              }})}
            errorMessage={get(errors, `[${fields.URL}].message`)}
            data-testid='short-link-input'
          />
          {/* <div className='label'>UTM Campaign (optional) </div>
          <div className='info'>The campaign name, promo code, etc.</div>
          <Input
            id={fields.UTM}
            name={fields.UTM}
            placeholder='2021-04-25-campaign'
            ref={register()}
          /> */}
          <div className='action-wrapper'>
            <Button
              className='action-button'
              text='CANCEL'
              type={buttonTypes.SECONDARY}
              onClick={onClose}
            />
            <Button
              className='action-button submit'
              text='Shorten and insert LINK'
              disabled={!formState.isValid}
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </StatelessModal>
  )
}

UrlShortnerModal.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  url: PropTypes.string,
}

export default UrlShortnerModal
