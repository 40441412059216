import { put } from 'redux-saga/effects'
import { actions as authActions } from '../actions/User'

const STORAGE_KEY = '__AUTH__'

export const storeUserData = function* (data) {
  const userData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  const updatedData = {
    ...userData,
    user: {
      ...userData.user,
      ...data
    }
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedData))
  yield put(authActions.UpdateAuthInfo(updatedData))
  return updatedData
}

export const updateSessionData = function* (data) {
  const currentData = JSON.parse(localStorage.getItem(STORAGE_KEY))
  const updatedData = {
    ...currentData,
    ...data
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedData))
  yield put(authActions.UpdateAuthInfo(updatedData))
  return updatedData
}
