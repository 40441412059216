import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { TextArea } from '../../../../FormComponents'
import { renderInput } from '../../../../FormComponents/utils'
import TabSelector from '../../../../TabSelector'
import PhonePreview, { HeaderBrowser } from '../../../../PhonePreview'
import PositiveFeedback from '../RatingPreviews/SuccessCard'
import NegativeFeedback from '../RatingPreviews/NegativeFeedbackCard'
import './styles.scss'

export const fields = {
  POSITIVE_HEADLINE: 'positive_headline',
  POSITIVE_SUBTEXT: 'positive_subtext',
  NEGATIVE_HEADLINE: 'negative_headline',
  NEGATIVE_SUBTEXT: 'negative_subtext',
  YELP_LINK: 'yelp_link',
  GOOGLE_LINK: 'google_link', 
  FACEBOOK_LINK: 'facebook_link', 
}

const tabItems = [
  { key: 'positive_rating', label: 'POSITIVE RATING' },
  { key:'negative_rating', label: 'NEGATIVE RATING' }
]

const ThankYou = props => {
  const { validator, store, values, visible } = props  
  const { register, errors } = validator
  const [ selectedItem, setSelectedItem ] = useState(tabItems[0])

  const handleTabChange = tab => {
    setSelectedItem(tab)
  }

  const renderForm = () => {
    const isPositiveRating = selectedItem.key === 'positive_rating'
    return (
        <>
        <div className={`form ${isPositiveRating ? '' : 'no-display'}`}>
          <div className='message'>
            Customers who rated 4-5 stars will see this page
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.POSITIVE_HEADLINE }>Headline</label>
            {
              renderInput(fields.POSITIVE_HEADLINE, validator)
            }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.POSITIVE_SUBTEXT }>Subtext</label>
            <TextArea
              id={ fields.POSITIVE_SUBTEXT }
              name={ fields.POSITIVE_SUBTEXT }
              className={ `textarea ${errors[fields.POSITIVE_SUBTEXT] ? 'error' : ''}` }
              forwardedRef={ register({ required: true }) }
            />
            { errors[fields.POSITIVE_SUBTEXT] && <div className='error-msg'>{ errors[fields.POSITIVE_SUBTEXT].message }</div> }
          </div>
          <div className='review-buttons'>Review buttons</div>
          <div className='social-media'>
            <label>
              <input type='checkbox' name={fields.GOOGLE_LINK} id={fields.GOOGLE_LINK} ref={register} defaultChecked={values[fields.GOOGLE_LINK]} />
              <span></span>
              Google
            </label>
            <label>
              <input type='checkbox' name={fields.YELP_LINK} id={fields.YELP_LINK} ref={register} defaultChecked={values[fields.YELP_LINK]}/>
              <span></span>
              Yelp
            </label>
            <label>
              <input type='checkbox' name={fields.FACEBOOK_LINK} id={fields.FACEBOOK_LINK} ref={register} defaultChecked={values[fields.FACEBOOK_LINK]}/>
              <span></span>
              Facebook
            </label>
          </div>
        </div>
        <div className={`form ${isPositiveRating ? 'no-display': ''}`}>
          <div className='message'>
          Customers who rated 1-3 stars will see this page
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.NEGATIVE_HEADLINE }>Headline</label>
            {
              renderInput(fields.NEGATIVE_HEADLINE, validator)
            }
          </div>
          <div className='field textarea-wrapper'>
            <label className='label' htmlFor={ fields.NEGATIVE_SUBTEXT }>Placeholder text</label>
            <TextArea
              id={ fields.NEGATIVE_SUBTEXT }
              name={ fields.NEGATIVE_SUBTEXT }
              className={ `textarea ${errors[fields.NEGATIVE_SUBTEXT] ? 'error' : ''}` }
              forwardedRef={ register({ required: true }) }
            />
            { errors[fields.NEGATIVE_SUBTEXT] && <div className='error-msg'>{ errors[fields.NEGATIVE_SUBTEXT].message }</div> }
          </div>
        </div>
        </>
    )
  }

  const renderPreview = () => {
    if(selectedItem.key === 'positive_rating') {
      return (
        <PositiveFeedback
          headline= {values[fields.POSITIVE_HEADLINE]}
          subtext= {values[fields.POSITIVE_SUBTEXT]}
          socialMedia= {{
            google: values[fields.GOOGLE_LINK],
            yelp: values[fields.YELP_LINK],
            facebook: values[fields.FACEBOOK_LINK]
          }}
        />
      )
    } else if(selectedItem.key === 'negative_rating') {
      return (
        <NegativeFeedback
          logoUrl={store.rating_logo}
          headline= {values[fields.NEGATIVE_HEADLINE]}
          placeholderText= {values[fields.NEGATIVE_SUBTEXT]}
        />
      )
    }
  }

  return (
    <div className={`eg-rating-thankyou ${visible ? '' : 'hidden'}`} data-testid='tankyou-page'>
      <div className='form-wrapper'>
        <div className='title'>Thank you page</div>
        <TabSelector
          items={ tabItems }
          selectedItem={ selectedItem }
          onChange={ handleTabChange }
          className='tabs-container'
          tabItemClass='tab-item'
        />
        {renderForm()}
      </div>
      <div className='preview' data-testid='tankyou-preview-page'>
        <div className='title'>PREVIEW</div>
        <PhonePreview className='phone'>
          <HeaderBrowser searchbarLabel='zerostorefront.com' />
          {renderPreview()}
        </PhonePreview>
      </div>
    </div>
  )
}

ThankYou.propTypes = {
  store: PropTypes.object,
  validator: PropTypes.object,
  visible: PropTypes.bool,
  values: PropTypes.object
}

export default ThankYou
