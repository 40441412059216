import React from 'react'
import NumberList from './NumberList'
import NumberSettings from './NumberSettings'
import NumberActivity from './NumberActivity'
import TabBar from './TabBar'
import StatusWidget from './StatusWidget'
import SectionHeader from './SectionHeader'
import CreateNumber from './CreateNumber'
import Forwarding from './Forwarding'
import StoreNumbers from '../Store/StoreNumbers'

export {
  NumberList,
  NumberSettings,
  NumberActivity,
  TabBar,
  StatusWidget,
  SectionHeader,
  CreateNumber
}

const numberSections = {
  CREATE: 'create',
  DETAILS: 'details',
  FORWARD: 'forward'
}

export const handleInternalRoute = (hash, restaurantUuid) => {
  if (hash === '#numbers') {
    return <StoreNumbers />
  }
  if (hash.match(/\/create$/)) {
    return <CreateNumber restaurantUuid={ restaurantUuid } />
  }
  const [, id, section] = hash.match(/^#numbers\/([^\/]+)\/(.+)$/)
  switch (section) {
    case numberSections.CREATE:
      return <CreateNumber restaurantUuid={ restaurantUuid } />
    case numberSections.DETAILS:
      return <StoreNumbers numberId={ id } />
    case numberSections.FORWARD:
      return <Forwarding numberId={ id } />
    default:
      return null
  }
}
