import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class OrderCell extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
  }

  static defaultProps = {
    className: ''
  }

  render() {
    return (
      <div className={ `${styles.itemField} ${this.props.className}` }>
        <div className={ styles.itemContent }>
          { this.props.children }
        </div>
      </div>
    )
  }
}
