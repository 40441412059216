import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

class PopOverMenu extends React.Component {
  static propTypes = {
    children: PropTypes.array,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: ''
  }

  constructor() {
    super()

    // bindings
    this.setContainer = this.setContainer.bind(this)
    this.renderTriggerContainer = this.renderTriggerContainer.bind(this)
    this.renderElements = this.renderElements.bind(this)
    this.triggerPopover = this.triggerPopover.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClickOnDropDown = this.handleClickOnDropDown.bind(this)

    // component state
    this.state = {
      active: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Sets a reference to this component container
   */
  setContainer(ref) {
    this.container = ref
  }

  /**
   * Renders the trigger container, which will open the menu
   *
   * @returns {React.Component}
   */
  renderTriggerContainer() {
    return React.Children.toArray(this.props.children).find(child => {
      return child.props.className === 'eg-menu-title'
    })
  }

  /**
   * Renders the items within the dropdown menu
   *
   * @returns {React.Component}
   */
  renderElements() {
    return React.Children.toArray(this.props.children).find(child => {
      return child.props.className === 'eg-menu-items'
    })
  }

  /**
   * Switches between visible and invisible popover menu
   */
  triggerPopover() {
    this.setState({
      active: !this.state.active
    })
  }

  /**
   * Close popover menu when user clicks outside
   */
  handleClickOutside(e) {
    if (!this.container.contains(e.target)) {
      this.setState({
        active: false
      })
    }
  }

  /**
   * if anyItem gets clicked from dropdown
   * we will close the dropdown.
   */
  handleClickOnDropDown() {
    this.setState({
      active: false
    })
  }

  render() {
    return (
      <div className="eg-popover-menu" ref={ this.setContainer }>
        <div className="eg-popover-trigger" onClick={ this.triggerPopover }>
          { this.renderTriggerContainer() }
        </div>
        <div className={ `eg-popover-dropdown ${this.props.className} ${!this.state.active ? 'hide' : ''}` } onClick={this.handleClickOnDropDown}>
          { this.renderElements() }
        </div>
      </div>
    )
  }
}

export default PopOverMenu
