export const types = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MODAL: 'OPEN_MODAL'
}
  
export const actions = {
  closeModal() {
    return {
      type: types.CLOSE_MODAL,
    }
  },
  openModal(){
    return {
      type: types.OPEN_MODAL,
    }
  }
}
  