import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import { getNumberWithCommas } from '../../../../utils/number'

const BreakDownRow = ({ storeList = []}) => {
 
  const storeBreakDownArray =  storeList.map((store, index) => {
    const averageRating = store.averageRating && store.averageRating.toFixed(1)
    return(
      <div className='eg-breakdown-row' key={index}>
        <div className='store'>{store.storeName}</div>
        <div className='avg'>
          {averageRating}
          <span className='zmdi zmdi-star zmdi-hc-fw star'/>
        </div>
        <div className='review'>{getNumberWithCommas(store.rateCount)}</div>
      </div>
    )
  })
  return storeBreakDownArray
}

BreakDownRow.propTypes = {
  storeList: PropTypes.array,
}

export default BreakDownRow
