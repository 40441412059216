import _ from 'lodash'

import { fields as smsFields } from '../components/Store/StoreAutomation/SmsRatingSettings/AutomationSms'
import { fields as feedbackFields } from '../components/Store/StoreAutomation/SmsRatingSettings/RatingPage'
import { fields as thankyouPageFields } from '../components/Store/StoreAutomation/SmsRatingSettings/ThankYou'

export const mapIvrOut = (ivr) => {
  const optimizedPage = String(ivr.optimized_page) === 'true'
  const result = {
    message: ivr.sms_text,
    optimized_page: optimizedPage,
    url: ivr.loyalty_link
  }

  if (ivr.sender) {
    result.sender_name = ivr.sender
  }

  if (optimizedPage) {
    result.loyalty_page = {
      discount_amount: ivr.discount_amount,
      headline: ivr.headline,
      subtext: ivr.subtext
    }
  }

  return result
}

export const mapRatingPayload = (ratingData) => {
  const mappedPayloadData = {
    rating: {
      message: ratingData[smsFields.SMS_TEXT], // string, REQUIRED
      url: ratingData[smsFields.RATING_LINK], // string, REQUIRED
      rating_page: { // object, REQUIRED
        headline: ratingData[feedbackFields.HEADLINE], // string, REQUIRED
        subtext: ratingData[feedbackFields.SUBTEXT] // string, REQUIRED
      },
      thanks_page: { // object, REQUIRED
        positive: { // object, REQUIRED
          headline: ratingData[thankyouPageFields.POSITIVE_HEADLINE], // string, REQUIRED
          subtext: ratingData[thankyouPageFields.POSITIVE_SUBTEXT], // string, REQUIRED
          review_buttons: { // object, REQUIRED
            yelp: ratingData[thankyouPageFields.YELP_LINK], // boolean, REQUIRED
            facebook: ratingData[thankyouPageFields.FACEBOOK_LINK], // boolean, REQUIRED
            google: ratingData[thankyouPageFields.GOOGLE_LINK] // boolean, REQUIRED
          }
        },
        negative: { // object, REQUIRED
          headline: ratingData[thankyouPageFields.NEGATIVE_HEADLINE], // string, REQUIRED
          placeholder: ratingData[thankyouPageFields.NEGATIVE_SUBTEXT], // string, REQUIRED
        }
      } 
    }
  }
  if(ratingData[smsFields.SENDER_NAME]) {
    mappedPayloadData.rating.sender_name = ratingData[smsFields.SENDER_NAME]
  }
  return mappedPayloadData
}

// Change these methods if some transformation is needed
export const mapIvrIn = (ivr) => {
  return ivr
}

export const validateSchema = (values) => {
  if (String(values.optimized_page) === 'true') {
    return !_.isEmpty(values.sms_text) &&
      !_.isEmpty(values.loyalty_link) &&
      !_.isEmpty(values.headline) &&
      !_.isEmpty(values.subtext) &&
      values.discount_amount !== ''
  }
  return !_.isEmpty(values.sender) &&
    !_.isEmpty(values.sms_text) &&
    !_.isEmpty(values.loyalty_link)
}
