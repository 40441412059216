import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions as numberActions } from '../../../actions/VirtualNumber'
import CreateNumber from './CreateNumber'

const mapStateToProps = state => ({
  posting: state.VirtualNumber.posting,
  virtualNumber: state.VirtualNumber.number,
  virtualNumbers: state.VirtualNumber.numbers
})

const mapDispatchToProps = dispatch => ({
  createNumber(data) {
    dispatch(numberActions.createNumber(data))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateNumber))
