/* eslint-disable no-undef */
import React,  { useState, useEffect, useCallback } from 'react'
import PropTypes from  'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IntegrationCard from './IntegrationCard'
import StateLessModal  from '../../StatelessModal'
import Gmail from './Integrations/Gmail'
// import Square from './Integrations/Square'
import BankAccount from './Integrations/BankAccount'
import Calendar from './Integrations/CalendarConnect'
import Shopify from './Integrations/ShopifyConnect'
import { actions as storeActions } from '../../../actions/Store'
import Facebook from './Integrations/Facebook'
import ConstantContact from './Integrations/ConstantContact'

import { INTEGRATION_KEYS, INTEGRATION_STATUS } from './constant'
// getting google gmail constant data from global constant file
import { GOOGLE_CONNECT_STATUSES } from '../../../constants'
import { hasIntegrationHappened } from '../../../services/oauth'
import './styles.scss'

const INTEGRATIONS = {
  gmail: 'GMAIL',
  square: 'SQUARE',
  plaid: 'PLAID',
  calendar: 'CALENDAR',
  shopify: 'SHOPIFY',
  facebook: 'FACEBOOK',
  constantContact: 'CONSTANT_CONTACT'
}

const StoreIntegration = (props) => {
  const { restaurantUuid } = props
  const storeState = useSelector(State => State.Store.store)
  const [ store, setStore ]  = useState(storeState)
  const [ selectedComponent, setSelectedComponent ]  = useState(null)
  const integration = useSelector(State => State.Store.integration)
  // const isSquareConnected = integration[INTEGRATION_KEYS.SQUARE] === INTEGRATION_STATUS.CONNECTED
  const isPlaidConnected = integration[INTEGRATION_KEYS.PLAID] === INTEGRATION_STATUS.CONNECTED
  const isGmailConnected = store.google_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_OK
  const isCalendarConnected = store.calendar_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_OK
  const isShopifyConnected = integration[INTEGRATION_KEYS.SHOPIFY] === INTEGRATION_STATUS.CONNECTED
  const isFacebookConnected = integration[INTEGRATION_KEYS.FACEBOOK] === INTEGRATION_STATUS.CONNECTED
  const isConstantContactConnected = integration[INTEGRATION_KEYS.CONSTANT_CONTACT] === INTEGRATION_STATUS.CONNECTED
  const integrationEmail = store.gmail_integration_email || ''
  const dispatch = useDispatch()

  const onSuccessfulIntegration = useCallback(
    (storageEvent) => {
      if(hasIntegrationHappened(storageEvent)) {
        dispatch(storeActions.getIntegrationStatus({ restaurantUuid }))
      }
    }, [dispatch, restaurantUuid])

  useEffect(() => {
    dispatch(storeActions.getIntegrationStatus({ restaurantUuid }))
    window.addEventListener('storage', onSuccessfulIntegration)
    return () => {
      window.removeEventListener('storage', onSuccessfulIntegration)
    }
  }, [dispatch, onSuccessfulIntegration, restaurantUuid])

  // To update card/rerender states
  // when gmail/calendar/plaid integration successfully completes.
  useEffect(() => {
    setStore(storeState)
  }, [storeState, integration])

  const onModelClose = () => {
    setSelectedComponent(null)
  }

  //On succesfull integration of square account we get redirected to
  // integration url from square page with having a url end like ?square=success
  // So we will open Square Modal if it's successful.
  const hasSquareInUrl = window.location.href.includes('square')
  useEffect(() => {
    if(hasSquareInUrl){
      setSelectedComponent(INTEGRATIONS.square)
    }
  }, [hasSquareInUrl])

  const renderIntegrationCards  =  () => {
    return (
      <>
        <IntegrationCard
          title='Gmail Inbox'
          subtitle='Import contacts and orders'
          logoClassName='gmail'
          isConnected={isGmailConnected}
          onClick= {() => setSelectedComponent(INTEGRATIONS.gmail)}
        />
        {/* <IntegrationCard
          title='Square'
          subtitle='Import sales and customer info'
          logoClassName='square'
          isConnected={isSquareConnected}
          onClick= {() => setSelectedComponent(INTEGRATIONS.square)}
        /> */}
        <IntegrationCard
          title='Bank Account'
          subtitle='Automate payments'
          logoClassName='bank'
          isConnected={isPlaidConnected}
          onClick= {() => setSelectedComponent(INTEGRATIONS.plaid)}
        />
        <IntegrationCard
          title='Google Calendar'
          subtitle='Sync order schedule'
          logoClassName='calendar'
          isConnected={isCalendarConnected}
          onClick= {() => setSelectedComponent(INTEGRATIONS.calendar)}
        />
        <IntegrationCard
          title='Constant Contact'
          subtitle='Import customer contacts'
          logoClassName='constant-contact'
          isConnected={ isConstantContactConnected }
          onClick= {() => setSelectedComponent(INTEGRATIONS.constantContact)}
        />
      {/*
        <IntegrationCard
          title='Shopify'
          subtitle='Connect with Shopify ecommerce'
          logoClassName='shopify'
          isConnected={ isShopifyConnected }
          onClick= { () => setSelectedComponent(INTEGRATIONS.shopify) }
        />
        <IntegrationCard
          title='Facebook Ads'
          subtitle='Create your custom audience'
          logoClassName='facebook'
          isConnected={isFacebookConnected}
          onClick= {() => setSelectedComponent(INTEGRATIONS.facebook)}
        />
      */}
      </>
    )
  }

  const renderIntegrationModal = () => {
    switch(selectedComponent) {
      case INTEGRATIONS.gmail:
        return (
          <Gmail
            restaurantUuid={restaurantUuid}
            isGmailConnected={isGmailConnected}
            integrationEmail={integrationEmail}
          />
        )
      // case INTEGRATIONS.square:
      //   return (
      //     <Square
      //       restaurant={store.storeInfo}
      //       isConnected={isSquareConnected}
      //     />
      //   )
      case INTEGRATIONS.plaid:
        return (
          <BankAccount
            restaurant={store.storeInfo}
            isConnected={isPlaidConnected}
          />
        )
      case INTEGRATIONS.calendar:
        return (
          <Calendar
            restaurantUuid={restaurantUuid}
            isCalendarConnected={isCalendarConnected}
            integrationEmail={integrationEmail}
          />
        )
      case INTEGRATIONS.shopify:
        return (
          <Shopify
            restaurantUuid={restaurantUuid}
            isConnected={ isShopifyConnected }
            onAuthorize= { () => setSelectedComponent(null) }
          />
        )

      case INTEGRATIONS.facebook:
        return (
          <Facebook
            restaurantUuid={restaurantUuid}
            isFacebookConnected={isFacebookConnected}
            onClose={onModelClose}
          />
        )

      case INTEGRATIONS.constantContact:
        return (
          <ConstantContact
            restaurantUuid={restaurantUuid}
            isConnected={isConstantContactConnected}
            onAuthorize= { () => setSelectedComponent(null) }
            onClose={onModelClose}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='eg-store-integration'>
      {renderIntegrationCards()}
      {
        selectedComponent &&
          <StateLessModal
            onClose={onModelClose}
            backGroundClassName='modal-background'
            className='modal'
          >
            {renderIntegrationModal()}
          </StateLessModal>
      }
    </div>
  )
}

StoreIntegration.propTypes = {
  restaurantUuid: PropTypes.string.isRequired,
}

export default withRouter(StoreIntegration)
