import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { getOrderStatus } from '../../../services/orders'
import { getFormattedAddress } from '../../../services/customers'
import ListItemCell from '../../../components/ListItemCell'

import styles from './index.module.scss'

export default class OrderList extends React.Component {
  static propTypes = {
    orders: PropTypes.array,
    onOrderClick: PropTypes.func
  }

  constructor() {
    super()

    // bindings
    this.handleOrderClick = this.handleOrderClick.bind(this)
  }

  /**
   * handle order item click
  */
  handleOrderClick(order) {
    if (this.props.onOrderClick) {
      this.props.onOrderClick(order)
    }
  }

  renderAddress(address) {
    if(!address) return null
    if(address.street || address.location) {
      return (
        <div className={styles.address}>
          { address.street }<span>{ address.location }</span>
        </div>
      )
    }
    return <div className={styles.address}> {address} </div>
  }

  renderDesktopTypeList() {
    const orderList = this.props.orders.map(order => {
      const address = getFormattedAddress(order.customer_address)
      const status = getOrderStatus(order)
      return (
        <div
          className={ styles.listItem }
          key={ order.restaurant_order_id }
          onClick={ () => this.handleOrderClick(order) }
        >
          <ListItemCell>
            #{ order.restaurant_order_id }
          </ListItemCell>

          <ListItemCell>
            { moment(order.order_date).format('MMM D, YYYY') }
            <span>
              { moment(order.order_date).format('H:mm A') }
            </span>
          </ListItemCell>

          <ListItemCell>
            {this.renderAddress(address)}
          </ListItemCell>

          <ListItemCell>
            ${ typeof order.total  === 'number'? order.total.toFixed(2): order.total }
            <span>{ order.order_items.length } items</span>
          </ListItemCell>

          <ListItemCell>
            {getCustomerName(order)}
          </ListItemCell>

          <ListItemCell>
            <div className={ `${styles.orderStatus} ${styles[status]}` }>
              { status }
            </div>
          </ListItemCell>
        </div>
      )
    })
    return orderList
  }

  renderMobileTypeList() {
    const orderList = this.props.orders.map(order => {
      const status = getOrderStatus(order)
      return (
        <div
          className={ styles.mobileListItem }
          key={ order.restaurant_order_id }
          onClick={ () => this.handleOrderClick(order) }
        >
          <div className={styles.upperRow}>
            <div className={styles.orderNumber}>
              #{ order.restaurant_order_id }
            </div>
            <div className={ `${styles.orderStatus} ${styles[status]}` }/>
          </div>
          <div className={styles.lowerRow} >
            <div className={styles.row}>
              <div className={styles.logoWrapper}>
                <div className={styles.invoiceLogo}/>
              </div>
              <div className={styles.eventDetail}>
                <div className={styles.eventDate}> 
                  { `${moment(order.order_date).format('MMM D, YYYY')} (${moment(order.order_date).format('dd')[0]})` }
                </div>
                <div className={styles.eventDate}>
                  { moment(order.order_date).format('H:mm A') }
                </div>
                <div className={styles.customerName}>
                  {getCustomerName(order)}
                </div>
              </div>
            </div>
            <div className={styles.orderTotal}>
              ${ typeof order.total  === 'number'? order.total.toFixed(2): order.total }
            </div>
          </div>
        </div>
      )
    })
    return orderList
  }

  render() {
    return (
      <div className={ styles.container }>
        <div className={styles.desktopWrapper}>
          {this.renderDesktopTypeList()}
        </div>
        <div className={styles.mobileWrapper}>
          {this.renderMobileTypeList()}
        </div>
      </div>
    )
  }
}

const getCustomerName = (order) => {
  return  _.trim(order.customer_first_name) || _.trim(order.customer_last_name) ? `${order.customer_first_name ? order.customer_first_name : '' } ${order.customer_last_name ? order.customer_last_name : ''}` : 'N/A'
}
