import React from 'react'
import {  useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { predicateTypes, predicateAttributes, predicateComparison } from '../../../../../services/segments'
import { capitalize } from '../../../../../utils/strings'
import DateStrings from './DateStrings'

const comparisonMap = {
  [predicateComparison.LTE]: 'less than',
  [predicateComparison.LT]: 'less than',
  [predicateComparison.GTE]: 'more than',
  [predicateComparison.EQ]: ''
}

const getIdString = (predicate, lists, predicateArray) => {
  const { campaigns, journeys } = lists
  const signupPredicate = predicateArray.find((predicate) => predicate.type === predicateTypes.SIGN_UP)
  // if the predicate array belongs to a signup query, then we are going to show trigger names( privy id or keyword)
  if(signupPredicate) {
    const journey = journeys && journeys.find((journey) => journey.id === predicate.value)
    if(signupPredicate.value === 'privy')
      return ` signed up with Popup ID ${_.get(journey, 'trigger_id', '')} `
    if(signupPredicate.value === 'webhook')
      return ` signed up with Keyword ${_.get(journey, 'trigger_id', '')} `
  }
  switch(predicate.attribute) {
    case predicateAttributes.JOURNEY_ID:
      const journey = journeys && journeys.find((journey) => journey.id === predicate.value)
      return ` on Journey with name ${_.get(journey, 'name')}`
    case predicateAttributes.CAMPAIGN_ID:
      const campaign = campaigns && campaigns.find((campaign) => campaign.id === predicate.value)
      return ` on Campaign with name ${_.get(campaign, 'name')}`
    default:
      return null
  }
}

/**
 *
 * @param {Object} predicate predicate Object
 * @returns returns a full string about purchase query. i.e.
 * `has purchased with Square 5 times with total order value is greater than $30 after Sep 01, 2021`
 */
const getPurchaseString = (predicate, predicates) => {
  const numberPredicate = predicates.find((predicate) => predicate.type === predicateTypes.NUMBER)
  let purchaseString = 'purchased'
  if(predicate.value) {
    purchaseString = `purchased on ${capitalize(predicate.value)}`
  }
  const amountComparison = _.get(predicate, 'amount.comparison')
  const amountValue  = _.get(predicate, 'amount.value')
  if(amountComparison && amountValue > 1) {
    purchaseString =  `${purchaseString} ${comparisonMap[amountComparison]} ${amountValue} times`
  }
  if(numberPredicate) {
    return ` ${purchaseString} where the total order value is ${comparisonMap[numberPredicate.comparison]} $${numberPredicate.value}`
  }
  return ` ${purchaseString}`
}

const getShortLinkString = (predicate) => {
  const amountComparision = _.get(predicate, 'amount.comparison')
  const amountValue  = _.get(predicate, 'amount.value')
  if(amountComparision && amountValue > 1) {
    return ` clicked ${comparisonMap[amountComparision]} ${amountValue} times`
  }
  return ' clicked'
}

const getPredicateString = (predicate, lists, predicates) => {
  switch(predicate.type) {
    case predicateTypes.PURCHASE:
      return getPurchaseString(predicate, predicates)
    case predicateTypes.SHORTLINK_CLICK:
      return getShortLinkString(predicate)
    case predicateTypes.ID:
      return `${getIdString(predicate, lists, predicates)}`
    default:
      return ''
  }
}

const PredicateString = ({predicate, predicates}) => {
  const { campaigns = [] } = useSelector(state => state.Segment.dynamicCampaignList)
  const { list }  = useSelector(state => state.Segment.dynamicJourneyList)
  const lists = {campaigns, journeys: _.get(list, 'items', [])}

  return <span className='predicate-string'>{`${getPredicateString(predicate, lists, predicates)}`}</span>
}

PredicateString.propTypes = {
  predicate: PropTypes.any,
  predicates: PropTypes.array,
}

const PredicateConditions = ({predicates}) => {
  return (
    <div className='predicate-condition-container'>
      {predicates.map((predicateObject, index) => {
        const predicateArray = predicateObject.predicates
        // We need to add the date at the end of sentence
        //  and in a structured way.
        const predicateWithoutDatePredicates = predicateArray.filter((item) => item.type !== predicateTypes.DATE )
        const datePredicates = predicateArray.filter((item) => item.type === predicateTypes.DATE )
        return (
          <div key={ `predicate-conditions-${index}` }>
            {(index !== 0 && _.get(predicateArray, 'length')) ? <div className='or-label'>or</div> : ''}
            <div className='predicate-conditions'>
              {_.get(predicateArray, 'length') ? <span className='predicate-string'>has</span> : ''}

              { predicateWithoutDatePredicates.map((predicate, index) =>{
                return (
                  <PredicateString
                    predicate={predicate}
                    predicates={predicateArray}
                    key={index}
                  />
                )
              })
              }
              {/* Add the date values at the end of the query string definition. */}
              {
                !!datePredicates && (
                  <DateStrings
                    datePredicates={datePredicates}
                  />
                )
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

PredicateConditions.propTypes = {
  predicates: PropTypes.array,
}

export default PredicateConditions
