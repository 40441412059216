import axios from 'axios'
import get from 'lodash/get'

import { METHODS, HeaderFactory } from './utils'

const JOB_STATUS = {
  SUCCESS: 'SUCCEEDED',
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
  ABORTED: 'ABORTED'
}

export const getJobStatus = (restaurant_uuid, jobToken, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${restaurant_uuid}/plaid/connect`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify({
      action: 'describe_execution',
      token: jobToken
    })
  })
}


/**
 * Sends token to eg-backend for exchanginng public token to access token for Plaid
 * @param {object} opts data to send to backend as query or in body as payload
 * @param {object} tokens - authentication tokens
 */
export const exchangePlaidToken = async (opts, tokens) => {
  const restaurant_uuid = opts.restaurantUuid
  const exchangeResponse = await axios({
    url: `${process.env.REACT_APP_INTEGRATION_URL}/restaurants/${restaurant_uuid}/plaid/connect`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify({
      action: 'start_execution',
      public_token: opts.publicToken // user's public token from plaid
    })
  })

  const jobResult = handleJobPolling(exchangeResponse, restaurant_uuid, tokens)
  return jobResult
}

/**
 * Handles polling of a job by taking it's response, restaurant id and auth tokens.
 * @param {*} exchangeResponse 
 * @param {*} restaurant_uuid 
 * @param {*} tokens 
 */
const handleJobPolling = async (exchangeResponse, restaurant_uuid, tokens ) => {
  // if job wasn't processed on the backend,
  // let's send the response without polling( because there is not any job processing on the backend)
  if(exchangeResponse.status !== 202 ) return exchangeResponse
  let jobResult = { status: JOB_STATUS.RUNNING }
  const jobToken = get(exchangeResponse, 'data.token')
  let tries = 0
  let response
  while (jobResult.status === JOB_STATUS.RUNNING && tries < process.env.REACT_APP_MAX_POLLING_RETRIES) {
    response =  await getJobStatus(restaurant_uuid, jobToken, tokens)
    jobResult = response.data
    tries++
  }
  if(jobResult.status === JOB_STATUS.SUCCESS) {
    return { ...jobResult, status: 200}
  }
  // JOB status is not successful, so operation was not successful.
  return { ...jobResult, status: 400}
}

