import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const EmptyResult = (props) => {
  const { list = {}, onPageChange } = props
  // If we are gettting customers for a particular page and 
  // there is no customer for that.
  const hasNoResultForPage = list.page > 1
  const titleMessage = hasNoResultForPage
    ? 'No customers found for this page'
    : 'No customers to show'
  
  return  (
    <div className='eg-empty-view'>
      <div className='title'>{titleMessage}</div>
      { hasNoResultForPage
        ? (
          <div onClick={() => onPageChange(list.lastPage)} className='go-back'>
            Go back to last page
          </div>
        )
        : (
          <p className='description'>
          This is where you will see the list of customers imported from your integrations.
          </p>
        )
      }
    </div>
  )
}

EmptyResult.propTypes = {
  list: PropTypes.object,
  onPageChange: PropTypes.func,
}


export default EmptyResult
