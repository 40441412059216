import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  useSelector,
  useDispatch
} from 'react-redux'

import { actions } from '../../../actions/Segment'
import StepsWidget from '../../StepsWidget'
import CustomerHeader from '../../CustomerHeader'
import BackLink from '../../BackLink'
import SetupSegment from './Setup'
import DesignSegment from './Design'
import ReviewSegment from './Review'
import { PATHS } from '../../../constants'
import { steps } from '../../../services/segments'
import './styles.scss'

const SegmentCreate = props => {
  const { history, step = steps[0] } = props
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(step)
  const segmentData = useSelector(state => _.get(state, 'Segment.dynamicSegment.data')) || {}
  let {
    loading,
    success: successSubmission,
  } = useSelector(state => state.Segment.dynamicSegment)
  const prevStep = useRef({})

  // handle success calls after each step
  useEffect(() => {
    // move ahead to the next step
    if (prevStep.current.key !== currentStep.key) {
      prevStep.current = { ...currentStep }
      return
    }
    if (successSubmission) {
      let stepIndex = 0
      steps.forEach((step, index) => {
        if (step.key === currentStep.key){
          stepIndex = index
        }
      })
      if (stepIndex < steps.length - 1) {
        setCurrentStep(steps[stepIndex + 1])
      }
      steps[stepIndex].completed = true
    }
  }, [successSubmission, currentStep])

  const handleCancel = () => {
    history.push(PATHS.SEGMENTS)
  }

  const handleStepClick = step => {
    setCurrentStep(step)
  }

  const handleSubmit = (step, data) => {
    const dataToProcess = {
      ...segmentData,
      ...data
    }
    dispatch(actions.upsertDynamicSegment(dataToProcess, step))
  }

  return (
    <div className='eg-segment-create'>
      <CustomerHeader />
      <BackLink to={PATHS.SEGMENTS}>Segments</BackLink>
      <div className='body'>
        <div className='steps-container'>
          <StepsWidget
            steps={steps}
            selectedStep={currentStep}
            onClick={handleStepClick}
          />
        </div>
        {
          currentStep.key === 'setup' && (
            <SetupSegment
              data={segmentData}
              submitting={loading}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'design' && (
            <DesignSegment
              data={segmentData}
              submitting={loading}
              onBackClick={() => setCurrentStep(steps[0])}
              onSubmit={handleSubmit}
            />
          )
        }
        {
          currentStep.key === 'review' && (
            <ReviewSegment
              submitting={loading}
              onBackClick={() => setCurrentStep(steps[1])}
              onSubmit={handleSubmit}
            />
          )
        }
      </div>
    </div>
  )
}

SegmentCreate.propTypes = {
  history: PropTypes.object,
  segment: PropTypes.object,
  step: PropTypes.object
}

export default SegmentCreate
