import { connect } from 'react-redux'

import { actions as gcActions } from '../../../../../actions/GoogleConnect'
import Gmail from './Gmail'


const mapStateToProps = state => ({
  googleConnectPosting: state.GoogleConnect.posting,
  googleConnectError: state.GoogleConnect.error,
})

const mapDispatchToProps = dispatch => ({
  getGoogleAuthCode(opts) {
    dispatch(gcActions.gmailConnect(opts))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Gmail)
