import React from 'react'
import PropTypes from 'prop-types'

import ListItemOptions from '../../../ListItemOptions'
import { getTimeDifference, segmentStatus, segmentTypes } from '../../../../services/segments'
import './styles.scss'

/**
 * Segment list row item view
 *
 * @component
 * @example
 * item = {
 *  name: 'test 1'
 *  description: 'description of segment'
 *  type: 'manual',
 *  status: 'processing',
 *  subscribers: 0,
 *  created_at: #dateISO string
 *  updated_at: #dateISO string
 * }
 *
 * return (
 *  <SegmentItem item={item} />
 * )
*/
const SegmentItem = ({ item, onItemClick, onEditClick }) => {

  const handleItemClick = () => {
    if (item.type === segmentTypes.DYNAMIC) {
      onItemClick(item)
    }
  }

  const description = item.description ? `, ${item.description}` : ''

  const options = [
    {
      label: 'Edit',
      onClick: () => {
        onEditClick(item)
      }
    }
  ]

  return (
    <div className={ `eg-segment-item ${item.type}` } onClick={handleItemClick} data-testid='segment-item'>
      <div className='name' >
        <div className='icon' />
        <div className='details'>
          <div className='title'>{item.name}</div>
          <div className='info' >
            <span>{`${item.type}${description}`}</span>
          </div>
        </div>
      </div>
      <div className='subscriber'>
        {item.status === segmentStatus.PROCESSED ?  item.subscribers : item.status}
      </div>
      <div className='date-updated'>{getTimeDifference(item.updated_at)}</div>
      <div className='date-added'>{getTimeDifference(item.created_at)}</div>
      <div className='options'>
        {
          item.type === segmentTypes.DYNAMIC && <ListItemOptions journey={item} options={ options } />
        }
      </div>
    </div>
  )
}

SegmentItem.propTypes = {
  item: PropTypes.object,
  onItemClick: PropTypes.func,
  onEditClick: PropTypes.func
}

export default SegmentItem
