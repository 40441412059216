import EgNotification from './EgNotification'
import { connect } from 'react-redux'
import { actions as notificationActions } from '../../actions/EgNotification'

const mapStateToProps = ({ EgNotification }) => {
  return { notification: EgNotification }
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(notificationActions.HideNotification()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EgNotification)
