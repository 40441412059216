import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from '../../../../FormComponents'
import './styles.scss'

const InteractionsFilterContent = props => {
  const handleItemClick = item => {
    if (props.onChange) {
      props.onChange(item, !item.value)
    }
  }

  const renderItem = item => {
    return (
      <div className='item' key={ `interactions-filter-${item.key}` } onClick={ () => handleItemClick(item) }>
        <Checkbox checked={ item.value } />
        <div className='label'>{ item.label }</div>
      </div>
    )
  }

  return (
    <div className='eg-interactions-filter-content'>
      { props.items.map(item => (renderItem(item))) }
    </div>
  )
}

InteractionsFilterContent.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  onChange: PropTypes.func
}

export default InteractionsFilterContent

