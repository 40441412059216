import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const HeaderBrowser = props => {
  const { searchbarLabel } = props

  return (
    <div className='eg-phone-preview-header-browser' data-testid='eg-header-browser'>
      <div className='search-bar'>{ searchbarLabel }</div>
    </div>
  )
}

HeaderBrowser.propTypes = {
  searchbarLabel: PropTypes.string
}

export default HeaderBrowser
