

// We are opening a new tab to do the oauth, Whenever we do a successful oauth integration we
// call this function to fire storage event that integration happened successfully. 
export const setStatusOfSuccessfulIntegration = () => {
  localStorage.setItem('isIntegrationSuccessful', 'yes')
}

// reference |> https://stackoverflow.com/questions/28230845/communication-between-tabs-or-windows
// Whenever some integration successfully happenes in a different tab
// We call this function to check whther integration was successful or not.
export const hasIntegrationHappened = (storageEvent) => {
  if (storageEvent.key === 'isIntegrationSuccessful') {
    if(storageEvent.newValue === 'yes') {
      return true
    } else {
      //Show a notification that last integration failed
      return false
    }
  }
  localStorage.removeItem('isIntegrationSuccessful')
}
