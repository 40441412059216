import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import SortingHeaders from '../../SortingHeaders'
import LocalSpinner from '../../LocalSpinner'
import { PATHS } from '../../../constants'
import { withRouter } from 'react-router'

import EmptyResult from './EmptyResult'
import ListItem from './ListItem'
import './styles.scss'


const sortingFields = [
  { label: 'CUSTOMER', key: 'registration_date', className: 'name', sortable: false },
  // { label: 'STORE', key: 'store', className: 'store', sortable: false },
  { label: 'TOTAL INTERACTIONS', key: 'total_interactions', className: 'total' },
  { label: 'LAST INTERACTION', key: 'last_activity.date', className: 'last-interaction' },
]

const CustomerList = props => {
  const { customers } = props.list
  const { loading = false, history } = props
  const sort = _.get(props.list, 'sort')

  const handleSortChange = (field) => {
    if(props.onSortChange) {
      props.onSortChange(field)
    }
  }

  const handleItemClick = customer => {
    history.push(
      {
        pathname: PATHS.CUSTOMER_DETAIL.replace(':id', customer.id),
        state: { referrerCustomerPageQuery: window.location.search}
      }
    )
  }

  return (
    <div className='eg-customer-list'>
      {
        loading ? (
          <LocalSpinner  className='loader'/>
        ) : (
          <>
            <SortingHeaders
              className='sort'
              fieldClassName='field'
              fields={ sortingFields }
              indicatorsClassName='indicators'
              currentSorting={ sort }
              onChange={ handleSortChange }
            />
            <div>
              {
                customers && customers.length ? (
                  <div className='body'>
                    {
                      customers.map(customer => <ListItem key={customer.id} customer={customer} onClick={handleItemClick}/>)
                    }
                  </div>
                ) : (
                  <EmptyResult
                    list = {props.list}
                    onPageChange = {props.onPageChange}
                  />
                )
              }
            </div>
          </>
        )
      }
    </div>
  )
}

CustomerList.propTypes = {
  list: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func
}

export default withRouter(CustomerList)
