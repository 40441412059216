import React from 'react'
import PropTypes from 'prop-types'

import { toCurrency } from '../../../../utils/number'
// import Button from '../../../FormComponents/Button'
// import { PATHS } from '../../../../constants'
import './styles.scss'

BarResult.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string
}

const maxBarWidth = 100

function BarResult({items, type}) {
  const maxResultIndex = type === 'positive' ? 0 : items.length - 1
  const maximumReturns = items && items.length && items[maxResultIndex].roi
  const barColor = type=== 'positive' ? '#2BBFB3' : '#CC3333'
  if(!items || !items.length) {
    return (
      <div className='eg-bar-result'>
        <div className='no-bars'>
          <div className='description'>
            No data to display for specified date range
          </div>
          {/*
          <div className='description'>Integrate your marketing channels to identify channels generating {type} return of investment (ROI).</div>
          <div className='footer'>
            <Button
              className='connect-button'
              text='CONNECT'
              onClick={() => {window.location.assign((PATHS.INTEGRATIONS))}}
            />
          </div>
          */}
        </div>
      </div>
    )
  }

  return (
    <div className='eg-bar-result'>
      {
        items && items.map((item, index) => {
          const barWidthRatio = item.roi/maximumReturns
          return (
            <div className='result-item' key={index}>
              <div className={`channel-logo ${item.source}`} data-testid='overview-channel-item-source' />
              <div className='roi' data-testid='overview-channel-item-roi'>{item.roi}x</div>
              <div
                className='bar'
                style={{
                  width: `${barWidthRatio * maxBarWidth}%`,
                  backgroundColor: barColor
                }}
              />
              <div className='return'>{toCurrency(item.income)}</div>
            </div>
          )
        })
      }
    </div>
  )
}

export default BarResult
