import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const RatingSource = props => {
  const source = props.source
  const dynamicStyles = {
    backgroundSize: `${props.imgWidth}px ${props.imgHeight}px`,
    height: props.imgHeight,
    width: props.imgWidth
  }

  if (props.displayLabel) {
    dynamicStyles.width = 'auto'
  }

  return (
    <div
      className={ `${styles.reviewSource} ${styles[source]} ${props.className}` }
      style={ dynamicStyles }
    >
      { props.displayLabel ? source : '' }
    </div>
  )
}

RatingSource.propTypes = {
  source: PropTypes.string.isRequired,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  displayLabel: PropTypes.bool,
  className: PropTypes.string
}

RatingSource.defaultProps = {
  imgWidth: 24,
  imgHeight: 24,
  displayLabel: true,
  className: ''
}

export default RatingSource
