import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * A component to render successful form submission information.
 * @param {*} props 
 */
const SucccessCard = (props) => {
  const { socialMedia, headline, subtext } = props

  return (
    <div className='feedback-positive-card'>
      <div className='logo' />
      <div className='title'>
        {headline}
      </div>
      <div className='info'>
        {subtext} 
      </div>
      {socialMedia.google
        && <div className='social-link google'>
          Review us on Google
        </div>
      }
      {socialMedia.yelp
        && <div className='social-link yelp'>
          Review us on Yelp
        </div>
      }
      {socialMedia.facebook
        && <div className='social-link facebook'>
          Review us on Facebook
        </div>
      }
    </div>
  )
}

SucccessCard.propTypes = {
  socialMedia: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
}

export default SucccessCard
