import { types } from '../actions/Modal'

const initialState = {active: false}

export default (state = initialState, action) => {
  switch (action.type) {
      
    case types.CLOSE_MODAL:
      return {
        ...state,
        active: false
      }
      
    case types.OPEN_MODAL:
      return {
        ...state,
        active: true
      } 

    default:
      return state
  }
}
