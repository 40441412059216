import moment from 'moment'
import axios from 'axios'
import get from 'lodash/get'

import { METHODS, HeaderFactory, constructStoreQuery, constructFilterQueryParams } from './utils'

/**
 * get ratings summary
 *
 * @param {object} opts - request options
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} promise with request results
*/
export const getRatingsSummary = async (opts, tokens) => {
  const { restaurantChainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const filterQueryParams = constructFilterQueryParams(opts)
  const qparams = [
    ...storeQueryParams,
    ...filterQueryParams
  ]
  const results = await Promise.all([
    axios({
      url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/reviews/summary?${qparams.join('&')}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens)
    }),
    axios({
      url: `${process.env.REACT_APP_CUSTOMERS_URL}/rating?chain_uuid=${restaurantChainUuid}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens)
    })
  ])

  const summary = {
    ...results[0].data.summary,
    ...results[1].data
  }

  return {
    ...results[0],
    summary
  }
}


/**
 * get reviews for chart/graph
 *
 * @param {object} opts - request options
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} promise with request results
*/
export const getChartData = (opts, tokens) => {
  const { restaurantChainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const  dateParams = constructDefaultDateParams(opts)
  const filterQueryParams = constructFilterQueryParams(opts)
  const qparams = [
    ...storeQueryParams,
    ...dateParams,
    ...filterQueryParams
  ]
  return axios({
    url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/ratings?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get all stores review by list
 *
 * @param {object} opts - request options
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} promise with request results
*/
export const getStoreBreakdown = (opts, tokens) => {
  const { restaurantChainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const filterQueryParams = constructFilterQueryParams(opts)
  const qparams = [
    ...storeQueryParams,
    ...filterQueryParams
  ]
  return axios({
    url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/reviews/store-breakdown?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get the list of reviews
 *
 * @param {object} opts - request options
 * @param {object} opts.sort - sorting options
 * @param {string} opts.sort.key - the field to be sorted
 * @param {string} opts.sort.direction - sort direction ('ASC' or 'DESC')
 *
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - promise with request results
 *
*/
export const getReviews = (opts, tokens) => {
  const { restaurantChainUuid, selectedStores } = opts
  const storeInfo = { restaurantChainUuid, selectedStores }
  const storeQueryParams = constructStoreQuery(storeInfo)
  // build parameters
  const filterQueryParams = constructFilterQueryParams(opts)
  const qparams = [
    ...storeQueryParams,
    ...filterQueryParams
  ]
  return axios({
    url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/reviews?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * get the list of reviews
 *
 * @param {object} opts - request options
 * @param {object} opts.restaurantChainUuid - store chain uuid
 * @param {string} opts.reviewId review id
 *
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - promise with request results
 *
*/
export const getReviewByid = (opts, tokens) => {
  const { restaurantChainUuid, reviewId } = opts
  const storeInfo = { restaurantChainUuid }
  const storeQueryParams = constructStoreQuery(storeInfo)
  const qparams = [
    ...storeQueryParams,
  ]
  return axios({
    url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/reviews/${reviewId}?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

/**
 * sends reply to a specific review
 *
 * @param {object} opts - request options
 * @param {object} opts.restaurantChainUuid - store chain uuid
 * @param {string} opts.reviewId review id
 *
 * @param {object} tokens - authentication tokens
 *
 * @returns {Promise} - promise with request results
 *
*/
export const sendReviewReply = (opts, tokens) => {
  const { restaurantChainUuid, reviewId, replyText } = opts
  const qparams = [
    `chain_uuid=${restaurantChainUuid}`
  ]

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/gmb/review/response?${qparams.join('&')}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(
      {
        'reviewName': reviewId,
        'comment': replyText
      }
    )
  })
}


export const getReviewCsvUrl = (opts, tokens) => {
  const { restaurantChainUuid, selectedStores, allData } = opts
  let qparams = []
  if(allData) {
    qparams = [`chain_uuid=${restaurantChainUuid}`]
  } else {
    const storeInfo = { restaurantChainUuid, selectedStores }
    const storeQueryParams = constructStoreQuery(storeInfo)
    const filterQueryParams = constructFilterQueryParams(opts)
    qparams = [
      ...storeQueryParams,
      ...filterQueryParams
    ]
  }

  return axios({
    url: `${process.env.REACT_APP_REVIEWS_URL}/restaurants/reviews/export-csv?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}


/**
 * puts time query parameter when it's not coming from the fitlers.
 * @param {*} opts
 */
const constructDefaultDateParams = (opts) => {
  const hasDateFilter = get(opts, 'filters.date.filterKeys.length')
  if (hasDateFilter) {
    // If the date filters exist in the filters,(i.e user has selected some time period in the date filter)
    //  we are not going to add any default date query parameter, and will return empty parameter
    return []
  }
  const dateParams = []
  let dateFrom = ''
  // Let's check what kind of data has been asked on the chart component
  // wheter it is daily, weekly or monthly.
  if(opts.timeRange === 'daily') {
    dateFrom= moment().subtract(32, 'days').format('YYYY-MM-DD')
  } else if (opts.timeRange === 'monthly'){
    dateFrom= moment().subtract(365, 'days').format('YYYY-MM-DD')
  } else {
    dateFrom = moment().subtract(180, 'days').format('YYYY-MM-DD')
  }
  // we are getting reviews till today's date.
  const dateTo= moment().format('YYYY-MM-DD')
  dateParams.push(`date_from=${dateFrom}`)
  dateParams.push(`date_to=${dateTo}`)
  return dateParams
}
