import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import styles from './index.module.scss'

export const headerStates = {
  ASC: 'sortAsc',
  DESC: 'sortDesc',
  NONE: 'sortNone'
}

/**
 * UI for sortered lists sorting
 *
*/
const SortingHeaders = props => {
  /**
   * get css className from the current state
   *
   * @param {object} field - header field to check on
   * @returns {string} headerState value which corresponds to the css classname
  */
  const getStateClassName = field => {
    if (field.key === _.get(props, 'currentSorting.key')) {
      return headerStates[props.currentSorting.direction]
    }
    return headerStates.NONE
  }

  /**
   * let the parent handle the action of clicking a field
  */
  const handleClick = (field, event) => {
    if (props.onChange && field.sortable) {
      let direction
      const currentField = _.get(props, 'currentSorting')
      if (currentField && currentField.key === field.key) {
        // if we click again on a previews selected field, invert the direction
        direction = currentField.direction === 'ASC' ? 'DESC' : 'ASC'
      }
      else {
        // if not, the direction will be ASC ny defailt
        direction = 'ASC'
      }
      props.onChange({ key: field.key, direction }, event)
    }
  }

  return (
    <div className={ `${styles.sortingHeaders} ${props.className}` } data-testid='sorting-headers'>
      {
        props.fields.map(field => {
          // TODO: check how to translate this to some defaultProp
          if (field.sortable === undefined) {
            field.sortable = true
          }
          return (
            <div
              className={ `${styles.field} ${props.fieldClassName} ${field.className}` }
              key={ field.key }
              data-testid={`sort-${field.key}`}
              onClick={ e => handleClick(field, e) }
            >
              <div className={ styles.fieldLabel }>
                { field.label }
              </div>
              {
                field.sortable && (
                  <div className={ `${styles.fieldState} ${styles[getStateClassName(field)]} ${props.indicatorsClassName}` }>
                    <i className="zmdi zmdi-caret-up"></i>
                    <i className="zmdi zmdi-caret-down"></i>
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

SortingHeaders.propTypes = {
  className: PropTypes.string,
  /**
   * array of column headers
  */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * header label, i.e. string to display
      */
      label: PropTypes.string.isRequired,

      /**
       * field unique identifier
      */
      key: PropTypes.string.isRequired,

      /**
       * set the field as sortable (default true)
      */
      sortable: PropTypes.bool
    })
  ),

  /**
   * state of the current selected field for sorting
  */
  currentSorting: PropTypes.shape({
    /**
     * sorting direction, this can be
     * - 'ASC': ascending sort
     * - 'DESC': descending sort
    */
    direction: PropTypes.string,

    /**
     * field selected
    */
    key: PropTypes.string,
  }),

  /**
   * css class for each field
  */
  fieldClassName: PropTypes.string,

  /**
   * css for indicators
  */
  indicatorsClassName: PropTypes.string,

  /**
   * action to be triggered when a field is clicked
  */
  onChange: PropTypes.func
}

SortingHeaders.defaultProps = {
  className: '',
  fieldClassName: ''
}

export default SortingHeaders
