import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { actions } from '../../../../actions/Upload'
import FileUploader from '../../../FileUploader'
import emptyLogo from '../assets/logo-default.svg'
import Button, { types } from '../../../FormComponents/Button'

import './styles.scss'

const LogoField = props => {
  const { logo, store, logoType } = props
  const isUploading = useSelector(state => state.Upload.uploading || _.get(state.LoyaltyIvr.storeLoading))
  const updatedLogo = useSelector(state => _.get(state, 'LoyaltyIvr.store'))
  const dispatch = useDispatch()

  const handleFileUpload = (files) => {
    const fileData = { files, type: logoType }
    const storeUuid = store.uuid
    dispatch(actions.uploadDoc({ fileData, storeUuid, reloadStore: false }))
  }

  const displayedLogo = _.get(updatedLogo, logoType) || logo

  return (
    <div className='eg-logo-field'>
      <FileUploader onFileUpload={ handleFileUpload } fileType='image/*'>
        <label className='label'>
          Logo
          <div className='frm-wrapper'>
            {
              displayedLogo ? (
                <img className='image' src={ displayedLogo } alt='logo' />
              ) : (
                <div className='logo-default'>
                  <img src={ emptyLogo } alt='logo' />
                </div>
              )
            }
            <Button
              className={ `btn-update ${isUploading ? 'loading' : ''}` }
              text='UPDATE'
              type={ types.SECONDARY }
              loading={ isUploading }
            />
          </div>
        </label>
      </FileUploader>
    </div>
  )
}

LogoField.propTypes = {
  logo: PropTypes.any,
  logoType: PropTypes.string.isRequired,
  store: PropTypes.object,
  onFileUpload: PropTypes.func
}

export default LogoField
