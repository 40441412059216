import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const Checkbox = props => {
  const activeClass = props.checked ? styles.checked : ''

  const handleClick = e => {
    if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <div
      className={ `${styles.chkContainer} ${props.className} ${activeClass}` }
      onClick={ handleClick }
    >
      {
        props.checked && (
          <i className="zmdi zmdi-check"></i>
        )
      }
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
}

Checkbox.defaultProps = {
  className: ''
}

export default Checkbox
