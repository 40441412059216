import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import styles from './index.module.scss'

export default class TabSelector extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    className: PropTypes.string,
    tabItemClass: PropTypes.string,
    selectedTabItemClass: PropTypes.string,
    selectedItem: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    containerId: PropTypes.string,// To put a id attribute on the wrapper element
  }

  static defaultProps = {
    className: '',
    containerId: shortid.generate()
  }

  constructor(props) {
    super(props)

    // bindings
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  /**
   * when item is clicked, 
   * and notify parent
   *
   * @param {object} item - tab item selected
   */
  handleItemClick(item) {
    if (this.props.onChange) {
      this.props.onChange(item)
    }
  }

  getTabTextClasses = (isSelected) => {
    const classesForSelectedTab = isSelected ?  `${styles.selected} ${this.props.selectedTabItemClass}`: ''
    return `${styles.tabItem} ${this.props.tabItemClass} ${classesForSelectedTab}`
  }

  render() {
    return (
      <div className={ `${styles.container} ${this.props.className}` } id={this.props.containerId} data-testid='tab-selector'>
        {
          this.props.items.map(item => {
            const isSelected = item.key === this.props.selectedItem.key
            return (
              <div
                className={ this.getTabTextClasses(isSelected) }
                key={ item.key }
                onClick={ () => this.handleItemClick(item) }
              >
                { item.label }
              </div>
            )
          })
        }
      </div>
    )
  }
}
