import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import CustomerHeader from '../../components/CustomerHeader'
import SegmentCreateOptions from '../../components/Segments/SegmentCreateOptions/index.js'
import { userRoles } from '../../services/auth'
import AccessControlledComponent from '../../components/AccessControlledComponent'
import { Button } from '../../components/FormComponents'
import PaginationSection from '../../components/PaginationSection'
import SegmentList from '../../components/Segments/SegmentList'
import { PATHS } from '../../constants'
import { actions as segmentActions } from '../../actions/Segment'
// import EGAnalytic, { analyticEvents } from '../../services/analytics'
import './styles.scss'


const DEFAULT_PAGE_SIZE = 25

const Segments = props => {
  const { history } = props
  const [showSegmentCreate, setShowSegmentCreate] = useState(false)
  const dispatch = useDispatch()
  const segmentList = useSelector(state => state.Segment.segmentList)
  const pageSize = get(segmentList, 'list.size') || DEFAULT_PAGE_SIZE

  useEffect(() => {
    // EGAnalytic.track(analyticEvents.CAMPAIGN_LIST_PAGE_VIEWED, {
    //   ...EGAnalytic.mapChain(chain),
    //   segmentPageName_: window.location.pathname
    // })
    dispatch(segmentActions.getSegments({ page: 1, size: DEFAULT_PAGE_SIZE }))
  }, [dispatch])

  const handlePageChange = (page) => {
    dispatch(segmentActions.getSegments({ page, size: pageSize }))
  }

  const handlePageSizeChange = (size) => {
    dispatch(segmentActions.getSegments({ page: 1, size }))
  }

  const handleCreateClick = () => {
    setShowSegmentCreate(true)
  }

  const onSegmentCreation = () => {
    dispatch(segmentActions.getSegments({ page: 1, size: pageSize }))
  }

  const onItemClick = (item) => {
    history.push(`${PATHS.SEGMENTS}/view-dynamic/${item.id}`)
  }

  const handleEditClick = (item) => {
    history.push(`${PATHS.SEGMENTS}/edit-dynamic/${item.id}`)
  }

  return (
    <div className='eg-segments'>
      <CustomerHeader />
      <div className='content'>
        <div className='top'>
          <h3>Segments</h3>
          <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_WRITE]}>
            <Button text='CREATE SEGMENT' onClick={handleCreateClick} />
          </AccessControlledComponent>
          {showSegmentCreate && <SegmentCreateOptions
            onClose={() => setShowSegmentCreate(false)}
            onSegmentCreation= {onSegmentCreation}
          />
          }
        </div>
        <SegmentList
          data={segmentList}
          onItemClick={onItemClick}
          onEditClick={ handleEditClick }
        />
        {
          segmentList.list &&
          <div className='pagination'>
            <PaginationSection
              total={get(segmentList, 'list.total')}
              size={get(segmentList, 'list.size')}
              page={get(segmentList, 'list.page')}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
            />
          </div>
        }
      </div>
    </div>
  )
}

Segments.propTypes = {
  history: PropTypes.object
}

export default Segments
