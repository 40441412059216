import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TabSelector from '../../TabSelector'

import './styles.scss'

const TabBar = props => {
  const { tabItems } = props
  const [ activeTab, setActiveTab ] = useState(props.selectedTab || tabItems[0])

  const handleTabChange = item => {
    setActiveTab(item)
    if (props.onChange) {
      props.onChange(item)
    }
  }

  return (
    <div className='eg-tabbar'>
      <TabSelector
        items={ tabItems }
        className='tab-container'
        tabItemClass = 'tab-item'
        selectedTabItemClass = 'tab-selected'
        selectedItem={ activeTab }
        onChange={ handleTabChange }
      />
    </div>
  )
}

TabBar.propTypes = {
  tabItems: PropTypes.array.isRequired,
  selectedTab: PropTypes.object,
  onChange: PropTypes.func
}

export default TabBar
