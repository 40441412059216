import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { toCurrency } from '../../../../utils/number'
import { channelTypeMappings } from '../../../../services/channels'
import ConnectCard from '../../ConnectCard'
import './styles.scss'

ChannelInsight.propTypes = {
  insight: PropTypes.object,
  selectedTime: PropTypes.object,
}


function ChannelInsight({ insight, selectedTime }) {
  const {best ={}, worst= {}} = insight || {}

  if(!insight) {
    return <div className='eg-channel-Insight' data-testid='insight'>
      <div className='title'>Insights</div>
      <ConnectCard className='connect-card' />
    </div>
  }

  const bestSource = channelTypeMappings[best.source] && channelTypeMappings[best.source].type
  const worstSource = channelTypeMappings[worst.source] && channelTypeMappings[worst.source].type
  return (
    <div className='eg-channel-Insight' data-testid='insight'>
      <div className='title'>Insights</div>
      <div className='insight-cards'>
        <div className='card'>
          <div className='positive'>
            <div className='logo' />
          </div>
          <div className='card-info'>
          Your best performing marketing campaign is “{best.name}” campaign with <span className='channel-name'>{bestSource}</span>.
          </div>
          <div className='card-detail'>
            {`Over the ${_.get(selectedTime, 'label')}, you spent ${toCurrency(best.spend)} on this campaign and generated ${toCurrency(best.income)} in revenue`}
            {best.customers ? `, while capturing ${best.customers} new customers` : ''}
          </div>
        </div>
        <div className='card'>
          <div className='negative'>
            <div className='logo' />
          </div>
          <div className='card-info'>
          Your worst performing marketing campaign is “{worst.name}” campaign with <span className='channel-name'>{worstSource}</span>.
          </div>
          <div className='card-detail'>
            {`Over the ${_.get(selectedTime, 'label')}, you spent ${toCurrency(worst.spend)} on this campaign and generated ${toCurrency(worst.income)} in revenue`}
            {worst.customers ? `, while capturing ${worst.customers} new customers` : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelInsight
