import { call, takeLatest, put, select } from 'redux-saga/effects'
import get from 'lodash/get'

import { types, actions } from '../actions/GoogleConnect'
import { actions as userActions } from '../actions/User'
import { getGoogleApiToken } from '../services/googleConnect'
import EGAnalytic, { analyticEvents } from '../services/analytics'
import { updateGoogleAuthCode } from '../api/Restaurant'
import { authorizedRequest } from './Auth'

import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

import {  actions as storeActions } from '../actions/Store'
import { getStore } from '../api/Store'
import { mapStoreToApp } from '../services/stores'

/**
 * handle google connect to retreive an authCode
 * and then send it to our backend
*/
function* gmailConnect(data) {
  // if the user is doing integration for a particular store from storelist
  // we will get this storeUuid in the data othersie won't.
  const storeUuid =  get(data, 'opts.restaurantUuid')
  let restaurant = null
  try {
    if(storeUuid){
      restaurant = yield select(({ Store }) =>  Store.store.storeInfo)
    } else {
      restaurant = yield select(state => get(state, 'User.chain'))
    }
    trackIntegrationStart(restaurant, 'Inbox integration')
    // prompt the user for gmail permissions
    const authCode = yield call(getGoogleApiToken, 'gmail')
    const response = yield call(authorizedRequest, updateGoogleAuthCode, {
      authCode,
      body: { restaurant_auth_code: authCode },
      restaurantUuid : restaurant.uuid,
    })
    if(response.data){
      // A boolean to show that we are doing integration on the main user selected restaurant
      // not on a store from store list.
      const isMainRestaurant = !storeUuid
      const updatedStoreData = yield storeUpdateHandler(restaurant, isMainRestaurant)
      yield put(actions.gmailConnectResolved(authCode))
      trackIntegrationComplete(updatedStoreData)
      const connectedEmail = updatedStoreData.gmail_integration_email
      const successMessage = `${connectedEmail ? connectedEmail : ''} Gmail succesfully connected.`
      yield showSuccessNotification(successMessage)
    } else {
      throw new Error(response)
    }
  }
  catch (error) {
    yield handleGmailIntegrationError(error, restaurant)
  }
}


const trackIntegrationStart = (restaurant, integrationName) => {
  // track start of gmail integration process
  EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_STARTED, {
    ...EGAnalytic.mapIntegrationTabDetail(restaurant),
    integrationTabName: integrationName
  }) 
}


const trackIntegrationComplete = (restaurant) => {
  // track successful completion of gmail integration
  EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_COMPLETED,  {
    ...EGAnalytic.mapIntegrationTabDetail(restaurant)
  })
}


function * showSuccessNotification(message) {
  const notification = {
    type: EgNotificationTypes.SUCCESS,
    message
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}


function * storeUpdateHandler(restaurant, isMainRestaurant = false ) {
  const res = yield call(authorizedRequest, getStore, {
    restaurantUuid: restaurant.uuid,
  })
  let updatedStoreData = {}
  if (res && res.data) {
    updatedStoreData = res.data
    // If we are integrating gmail on the `orders` page then we are updating
    // the user selected restaurant not an indivisual store
    if(isMainRestaurant) {
      yield put(userActions.UpdateChainInfo([ updatedStoreData ]))
    } else {
      const mappedData = yield mapStoreToApp(res.data)
      yield put(storeActions.getStoreResolved(mappedData))
    }
  } else {
    console.error('Failed to update store with recent changes.', res)
  }
  return updatedStoreData
}


function * handleGmailIntegrationError(error, restaurant) {
  console.error('error', error)
  if(error.error === 'popup_closed_by_user' || error.error === 'access_denied'){
    // track gmail connect abandoned
    EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_ABANDONED,  {
      ...EGAnalytic.mapIntegrationTabDetail(restaurant), 
      integrationTabName: 'Inbox integration'
    })
  }
  yield put(actions.gmailConnectRejected(error))
  const notification = {
    type: EgNotificationTypes.ERROR,
    message: 'Something went wrong with the request. Please try again or contact us for support.'
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}


function* calendarConnect(data) {
  const storeUuid =  get(data, 'opts.restaurantUuid')
  const restaurant = yield select(({ Store }) =>  Store.store.storeInfo)
  try {
    // prompt the user for permissions
    const authCode = yield call(getGoogleApiToken, 'calendar')
    const response = yield call(authorizedRequest, updateGoogleAuthCode, {
      body: {
        calendar: { auth_code: authCode }
      },
      restaurantUuid: storeUuid
    })
    if(response.data){
      const updatedStoreData = yield storeUpdateHandler(restaurant)
      trackIntegrationComplete(updatedStoreData)
      yield put(actions.calendarConnectResolved(authCode))
      showSuccessNotification('Calendar succesfully connected.')
    } else {
      throw new Error(response)
    }
  } catch (error) {
    yield handleCalendarIntegrationError(error, restaurant)
  }
}


function * handleCalendarIntegrationError(error, restaurant) {
  console.error('error while doing calendar integration: ', error)
  if(error.error === 'popup_closed_by_user' || error.error === 'access_denied'){
    // track calendar connect abandoned
    EGAnalytic.track(analyticEvents.INTEGRATION_PROCESS_ABANDONED,  {
      ...EGAnalytic.mapIntegrationTabDetail(restaurant), 
      integrationTabName: 'Calendar integration'
    })
  }
  yield put(actions.calendarConnectRejected(error))
  const notification = {
    type: EgNotificationTypes.ERROR,
    message: 'Something went wrong with the request. Please try again or contact us for support.'
  }
  yield put(EgNotificationActions.ShowNotification(notification))
}


export default function* () {
  yield takeLatest(types.GMAIL_CONNECT_INTENT, gmailConnect)
  yield takeLatest(types.CALENDAR_CONNECT_INTENT, calendarConnect)
}
