import React from 'react'
import PropTypes from 'prop-types'
import querystring from 'query-string'

import Appbar from '../../components/Appbar'
import CustomerMessage from '../../components/CustomerMessage'
import { types as msgTypes } from '../../components/CustomerMessage/CustomerMessage'
import TextField from '../../components/FormComponents/TextField'
import Button from '../../components/FormComponents/Button'
import FormValidator from '../../validators/FormValidator'
import {
  password,
  matchingFields,
  customRegex
} from '../../validators/customValidations'
import { PATHS } from '../../constants'
import styles from './index.module.scss'

const CODE_FIELD = 'verificationCode'
const PASSWORD_FIELD = 'password'
const CONFIRM_PASSWORD_FIELD = 'confirm_password'
const formFieldsNames = [
  CODE_FIELD,
  PASSWORD_FIELD,
  CONFIRM_PASSWORD_FIELD
]
const errorCodes = {
  CODE_MISMATCH: 'CodeMismatchException',
  CODE_EXPIRED: 'ExpiredCodeException'
}

const errorMessages = {
  [errorCodes.CODE_MISMATCH]: 'Code is invalid',
  [errorCodes.CODE_EXPIRED]: 'Code has expired'
}

export default class ConfirmNewPassword extends React.Component {

  static propTypes = {
    error: PropTypes.string,
    posting: PropTypes.bool,
    errorCode: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onSubmit: PropTypes.func
  }

  constructor() {
    super()

    // initial state
    this.state = {
      [CODE_FIELD]: { value: '' },
      [PASSWORD_FIELD]: { value: '' },
      [CONFIRM_PASSWORD_FIELD]: { value: '' }
    }

    // bindings
    this.renderErrors = this.renderErrors.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToSignin = this.goToSignin.bind(this)

    // validations
    this.validator = new FormValidator([
      ...customRegex(CODE_FIELD, /^\d{6}$/, 'Verification code should consist in 6 number digits'),
      ...password(PASSWORD_FIELD),
      ...matchingFields(CONFIRM_PASSWORD_FIELD, PASSWORD_FIELD, 'Passwords do not match')
    ])
  }

  /**
   * render error messages
  */
  renderErrors() {
    if (!this.props.errorCode) {
      return null
    }

    return (
      <CustomerMessage type={ msgTypes.MSG_WARNING } show={ true }>
        { errorMessages[this.props.errorCode] }
      </CustomerMessage>
    )
  }

  /**
   * update state with fields information
   *
   * @param event {object} input event
   */
  handleInputChange(event) {
    event.preventDefault()
    this.setState({
      [event.target.name]: {
        value: event.target.value,
        dirty: true
      }
    })
  }

  /**
   * check if the form is in a valid state
   */
  isValid() {
    return this.validation.isValid && !this.props.posting
  }

  /**
   * handle form submission
  */
  handleSubmit() {
    const qs = querystring.parse(this.props.location.search)
    if (qs.email) {
      // avoid to send spaces on emails with `+` sign
      const email = qs.email.replace(/\s+/, '+')
      this.props.onSubmit({
        email: email,
        [CODE_FIELD]: this.state[CODE_FIELD].value,
        [PASSWORD_FIELD]: this.state[PASSWORD_FIELD].value
      })
    }
  }

  /**
   * redirects the user to signin page
  */
  goToSignin() {
    this.props.history.push(PATHS.LOGIN)
  }

  render() {
    // Get the form input values and statuses from state to validate.
    const formFields = {}
    formFieldsNames.forEach(fieldName => formFields[fieldName] = this.state[fieldName])
    this.validation = this.validator.validate(formFields)

    return (
      <>
        <Appbar />
        <div className={ styles.container }>
          <div className={ styles.resetPassWrapper }>
            <h2>Enter your new Password</h2>
            <p>
              We sent you an email with a six-digit confirmation code.
              Enter it below to continue to reset your password.
            </p>

            { this.renderErrors() }

            <div className={ styles.formWrapper }>
              <TextField
                className={ styles.textField }
                name={CODE_FIELD}
                label="Six-digit confirmation code"
                placeholder="Confirmation code"
                errorMessage={this.validation[CODE_FIELD].message}
                error={!this.validation[CODE_FIELD].isValid && this.state[CODE_FIELD].dirty}
                onChange={this.handleInputChange}
                onEnterPress={ this.onSubmit }
              />

              <TextField
                className={ styles.textField }
                name={PASSWORD_FIELD}
                type="password"
                label="New password"
                placeholder="New password"
                errorMessage={this.validation[PASSWORD_FIELD].message}
                error={!this.validation[PASSWORD_FIELD].isValid && this.state[PASSWORD_FIELD].dirty}
                onChange={this.handleInputChange}
                onEnterPress={ this.onSubmit }
              />

              <TextField
                className={ styles.textField }
                name={CONFIRM_PASSWORD_FIELD}
                type="password"
                label="Confirm new password"
                placeholder="Confirm new password"
                errorMessage={this.validation[CONFIRM_PASSWORD_FIELD].message}
                error={!this.validation[CONFIRM_PASSWORD_FIELD].isValid && this.state[CONFIRM_PASSWORD_FIELD].dirty}
                onChange={this.handleInputChange}
                onEnterPress={ this.onSubmit }
              />

              <article className={styles.buttonContainer}>
                <Button
                  text="Confirm new password"
                  className={ styles.submitBtn }
                  disabled={ !this.isValid() }
                  loading={ this.props.posting }
                  onClick={ this.handleSubmit }
                  primary
                />

                <Button
                  text="Back to sign in"
                  className={ styles.backBtn }
                  loading={ this.props.posting }
                  onClick={ this.goToSignin }
                />
              </article>
            </div>

          </div>
        </div>
      </>
    )
  }
}
