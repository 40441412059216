import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { userHasAccess, userRoles } from '../../../../../services/auth'
import styles from './index.module.scss'

const Info = props => {
  const user = useSelector(state => state.User.user)
  const store = props.store

  const handleEditClick = () => {
    if (props.onEditClick) {
      props.onEditClick()
    }
  }

  const renderAddButton = () => {
    if (userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE])) {
      return  (
        <span
          className={ styles.addItemLink }
          onClick={ handleEditClick }
        >
          Add
        </span>
      )
    }
  }

  const description = get(store, 'businessDetails.description')
  const manager = get(store, 'businessDetails.manager') || {}
  return (
    <div className={ styles.infoContainer }>
      <h3>Business details</h3>
      <div className={styles.row}>
        <div className={styles.label}>About</div>
        {description ? <div>{description}</div> : renderAddButton() }
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Manager name</div>
        {manager.name ? <div>{manager.name}</div> : renderAddButton() }
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Manager email</div>
        {manager.email ? <div>{manager.email}</div> : renderAddButton() }
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Manager number</div>
        { manager.phone ? <div>{manager.phone}</div> : renderAddButton() }
      </div>
    </div>
  )
}

Info.propTypes = {
  store: PropTypes.object,
  onEditClick: PropTypes.func
}

Info.defaultProps = {}

export default Info
