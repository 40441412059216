import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FilterOptions from '../../../FilterOptions'
import FilterContent from './FilterContent'
import { filterTypes, interactionSources, sourceToLabelMap } from '../../../../services/customers'

const defaultActionLabel = 'Interaction Source'

const LastInteractionFilter = props => {
  const initialItemsState = Object.values(interactionSources).map(src => {
    return {
      key: src,
      label: sourceToLabelMap[src],
      value: false
    }
  })
  const [ actionLabel, setActionLabel ] = useState(defaultActionLabel)
  const [ filterOptions, setFilterOptions ] = useState([ ...initialItemsState ])

  const selectedItems = filterOptions.filter(op => op.value)
  const selectedCount = selectedItems.length

  const handleChange = (item, value) => {
    const opts = [ ...filterOptions ]
    const selected = opts.find(opt => opt.label === item.label)
    selected.value = value
    setFilterOptions(opts)
  }

  const handleApply = () => {
    setActionLabel(`${defaultActionLabel} (${selectedCount})`)
    if (props.onApply) {
      props.onApply({
        [filterTypes.LAST_SOURCE]: selectedItems
      })
    }
  }

  const handleClear = () => {
    setActionLabel(defaultActionLabel)
    setFilterOptions([ ...initialItemsState ])
    if (props.onClear) {
      props.onClear({
        filterType: filterTypes.LAST_SOURCE
      })
    }
  }

  return (
    <div data-testid='eg-customers-last-source-filter'>
      <FilterOptions
        actionLabel={ actionLabel }
        onApply={ handleApply }
        onClear={ handleClear }
        applyOnClickOut
        applyDisabled={ !selectedCount }
        selectedItems={ selectedItems }
        customItemsComponent={
          <FilterContent
            items={ filterOptions }
            selectedItems={ selectedItems }
            onChange={ handleChange }
          />
        }
      />
    </div>
  )
}

LastInteractionFilter.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

export default LastInteractionFilter
