import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { actions } from '../../../../../actions/Upload'
import { UPLOAD_FILE_TYPE } from '../../../../../services/upload'
import FileUploader from '../../../../FileUploader'
import Loader from '../../../../LocalSpinner'
import styles from './form.module.scss'

const { uploadDoc } = actions

const PhotoForm = props => {
  const store = props.store
  const dispatch = useDispatch()
  const { uploadingImage, loadingStore } = useSelector(({ Store, Upload }) => {
    const { loadingStore } = Store
    const { uploading } = Upload
    return { loadingStore, uploadingImage: uploading }
  })

  const onFileUpload = (files) => {
    const fileData = { files, type: UPLOAD_FILE_TYPE.PHOTO}
    const storeUuid = store.uuid
    dispatch(uploadDoc({ fileData, storeUuid }))
  }

  const UploaderBox = () => {
    // After uploading image, we again retrieve the 
    // Store details from backend, To get the uploaded image.
    if(uploadingImage || loadingStore) {
      return (
        <div className={styles.uploaderContainer} >
          <Loader />
        </div>
      )
    }
    return (
      <div className={styles.uploaderContainer} >
        <FileUploader onFileUpload={onFileUpload} fileType='image/*'>
          <label className={styles.label} >
            <div className={styles.addLogo}><div /></div> 
            <div>Add photos</div>
          </label>
        </FileUploader>
      </div>
    )
  }

  const Thumbnail = () => {
    const photos = get(store, 'imageLinks') || []
    const thumbnails = photos.map((photo, index) => {
      return <img alt="Restaurant photos" className={styles.thumbnail} src={photo} key={index} />
    })
    return thumbnails
  }

  return (
    <div className={ styles.container }>
      <h3>Photos</h3>
      <p> Spice up your business pages with some amazing photos.</p>
      <div className={styles.photoWrapper}>
        <UploaderBox />
        <Thumbnail />
      </div>
    </div>
  )
}

PhotoForm.propTypes = {
  store: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default PhotoForm
