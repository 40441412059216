import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import CustomerHeader from '../../components/CustomerHeader'
import TabSelector from '../../components/TabSelector'

import StoreProfile from '../../components/Store/StoreProfile'
import StoreIntegration from '../../components/Store/StoreIntegration'
import StoreAutomation from '../../components/Store/StoreAutomation'
import { handleInternalRoute as handleNumbersInternalRoute } from '../../components/VirtualNumber'
import LocalSpinner from '../../components/LocalSpinner'

import { PATHS } from '../../constants'
import './styles.scss'

const storeTabs = {
  PROFILE: 'profile',
  INTEGRATIONS: 'integrations',
  NUMBERS: 'numbers',
  AUTOMATIONS: 'automations'
}

const getTabs = (user) => {
  const result = [
    {
      label: 'PROFILE',
      key: storeTabs.PROFILE,
    }
  ]
  // if (userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE])) {
  //   result.push({
  //     label: 'INTEGRATIONS',
  //     key: storeTabs.INTEGRATIONS,
  //   })
  // }
  // if (userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.TWILIO_READ])) {
  //   result.push({
  //     label: 'NUMBERS',
  //     key: storeTabs.NUMBERS,
  //   })
  // }
  // if (userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.STORE_WRITE])) {
  //   result.push({
  //     label: 'AUTOMATIONS',
  //     key: storeTabs.AUTOMATIONS,
  //   })
  // }
  return result
}

const Store = props => {
  const {
    store = {},
    getStore,
    getCategories,
    loadingStore,
    savingStore,
    history,
    location
  } = props
  const user = useSelector(state => state.User.user)

  const tabs = getTabs(user)
  const tabFromUrl =  getCurrentTab(tabs)
  const [ currentTab, setCurrentTab ]  = useState(tabFromUrl)
  // retrieve store uuid from the url
  const { uuid } =  props.match.params

  useEffect(() => {
    getStore({ restaurantUuid: uuid })


    getCategories()
  }, [getStore, getCategories, uuid, user])


  const changeTabOption = (item) => {
    if(item.key !== currentTab.key) {
      //Put hash route for selected tab.
      history.push(`#${item.key}`)
      setCurrentTab(item)
    }
  }

  const renderSelectedTab = () => {
    switch(currentTab.key){
      case storeTabs.PROFILE:
        return <StoreProfile />
      case storeTabs.INTEGRATIONS:
        return <StoreIntegration restaurantUuid={uuid} />
      case storeTabs.NUMBERS:
        return handleNumbersInternalRoute(location.hash, uuid)
      case storeTabs.AUTOMATIONS:
        return <StoreAutomation store={store} hash={ location.hash } />
      default:
        return <StoreProfile />
    }
  }

  if(loadingStore || savingStore || !store) {
    return (
      <div className='eg-store-detail'>
        <LocalSpinner className={ 'spinner' } />
      </div>
    )
  }

  return (
    <>
      <CustomerHeader />
      <div className='eg-store-detail'>
        <div className='header'>
          <Link className={ 'back'} to={PATHS.STORES}>
            <div className={'arrow-container'}>
              <div className={'arrow'} />
            </div>
              BACK TO STORES
          </Link>
          <div className='name'>{get(store, 'storeInfo.name')} </div>
          <TabSelector
            items={ tabs }
            className= 'tab'
            tabItemClass = 'item'
            selectedTabItemClass = 'selected'
            selectedItem={ currentTab }
            onChange={ changeTabOption }
          />
        </div>
        {renderSelectedTab()}
      </div>
    </>
  )
}

const getCurrentTab = (tabs) => {
  const hashRouteTab = tabs.find((tab) => window.location.href && window.location.href.includes(tab.key))
  if(hashRouteTab) return hashRouteTab
  return tabs[0]
}

Store.propTypes = {
  store: PropTypes.object,
  categories: PropTypes.array,
  loadingStore: PropTypes.bool.isRequired,
  loadingCategories: PropTypes.bool.isRequired,
  savingStore: PropTypes.bool.isRequired,
  getStore: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Store
