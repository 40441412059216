import { connect } from 'react-redux'

import { actions } from '../../../../../actions/Plaid'
import BankAccount from './BankAccount'

const mapStateToProps = state => ({
  plaid: state.Plaid,
})

const mapDispatchToProps = dispatch => ({
  startTokenExchange(opts) {
    dispatch(actions.startTokenExchange(opts))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount)
