import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import IntegrationCard from '../../Store/StoreIntegration/IntegrationCard'
import StatelessModal from '../../StatelessModal'
import FivetranConnect from './FivetranConnect'
import './styles.scss'

const FivetranIntegration = props => {
  const { provider, providerName, title, subtitle, isBeta = false } = props
  const [showModal, setShowModal] = useState(false)
  const fivetranConnections = useSelector(state => _.get(state, 'OauthConnect.fivetranConnections.list', []))

  const handleClick = () => {
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const isConnected = service => {
    if(!fivetranConnections) return false
    return !!fivetranConnections.find(c => c.service === service && c.status === 'connected')
  }

  return (
    <div className='eg-fivetran-integration'>

      {showModal
        ? (
          <StatelessModal onClose={ handleModalClose } className='eg-fivetran-modal'>
            <FivetranConnect
              provider={provider}
              providerName={providerName}
            />
          </StatelessModal>
        )
        : null
      }
      <IntegrationCard
        title={title}
        subtitle={subtitle}
        logoClassName={provider}
        isConnected={ isConnected(provider) }
        onClick={ handleClick }
        isBeta={isBeta}
      />
    </div>
  )
}

FivetranIntegration.propTypes = {
  provider: PropTypes.string,
  providerName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logo: PropTypes.string,
  isBeta: PropTypes.bool,
}

export default FivetranIntegration
