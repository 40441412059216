import { types } from '../actions/Oauth'

export const initialState = {
  connecting: false,
  connected: false,
  error: null,
  toast: {
    sending: false,
    data: null,
    error: null
  },
  fivetranConnect: {
    sending: false,
    connected: false,
    error: null
  },
  fivetranConnections: {
    loading: false,
    list: null,
    error: null
  },
  confirmFivetran: {
    sending: false,
    connected: false,
    error: null
  }
}

export default (state = initialState, action) => {
  switch(action.type) {
    case types.OAUTH_CONNECT_INTENT:
      return {
        ...state,
        connecting: true,
        connected: false,
        error: null
      }

    case types.OAUTH_CONNECT_INTENT_RESOLVED:
      return {
        ...state,
        connecting: false,
        connected: true,
        error: null
      }

    case types.OAUTH_CONNECT_INTENT_REJECTED:
      return {
        ...state,
        connecting: false,
        connected: false,
        error: action.error
      }

    case types.FIVETRAN_CONNECT_INTENT:
      return {
        ...state,
        fivetranConnect: {
          sending: true,
          connected: false,
          error: null
        }
      }

    case types.FIVETRAN_CONNECT_INTENT_RESOLVED:
      return {
        ...state,
        fivetranConnect: {
          sending: false,
          connected: true,
          error: null
        }
      }

    case types.FIVETRAN_CONNECT_INTENT_REJECTED:
      return {
        ...state,
        fivetranConnect: {
          sending: false,
          connected: false,
          error: action.error
        }
      }

    case types.FIVETRAN_CONFIRM_INTENT:
      return {
        ...state,
        confirmFivetran: {
          sending: true,
          connected: false,
          error: null
        }
      }

    case types.FIVETRAN_CONFIRM_INTENT_RESOLVED:
      return {
        ...state,
        confirmFivetran: {
          sending: false,
          connected: true,
          error: null
        }
      }

    case types.FIVETRAN_CONFIRM_INTENT_REJECTED:
      return {
        ...state,
        confirmFivetran: {
          sending: false,
          connected: false,
          error: action.error
        }
      }

    case types.FIVETRAN_CONNECTIONS_INTENT:
      return {
        ...state,
        fivetranConnections: {
          loading: true,
          list: null,
          error: null
        }
      }

    case types.FIVETRAN_CONNECTIONS_INTENT_RESOLVED:
      return {
        ...state,
        fivetranConnections: {
          loading: false,
          list: action.data || [],
          error: null
        }
      }

    case types.FIVETRAN_CONNECTIONS_INTENT_REJECTED:
      return {
        ...state,
        fivetranConnections: {
          loading: false,
          list: null,
          error: action.error
        }
      }

    case types.OAUTH_ACTIVE_CONNECTIONS_INTENT:
      return {
        ...state,
        connecting: true,
        connected: false,
        error: null,
      }

    case types.OAUTH_ACTIVE_CONNECTIONS_INTENT_RESOLVED:
      return {
        ...state,
        connecting: false,
        connected: false,
        error: null,
        activeConnections: action.data
      }

    case types.OAUTH_ACTIVE_CONNECTIONS_INTENT_REJECTED:
      return {
        ...state,
        connecting: false,
        connected: false,
        error: null,
      }

    case types.TOAST_CRED_INTENT:
      return {
        ...state,
        toast: {
          sending: true,
          data: null,
          error: null
        }
      }

    case types.TOAST_CRED_INTENT_RESOLVED:
      return {
        ...state,
        toast: {
          sending: false,
          data: action.data,
          error: null
        }
      }

    case types.TOAST_CRED_INTENT_REJECTED:
      return {
        ...state,
        toast: {
          sending: false,
          data: null,
          error: action.error
        }
      }

    default:
      return state
  }
}
