import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'


import { Button } from '../../../FormComponents'
import LocalSpinner from '../../../LocalSpinner'
import { actions as oauthActions } from '../../../../actions/Oauth'
import { oauthProviders } from '../../../../services/auth'
import './styles.scss'

export const GOOGLE_BUSINESS_SCOPE = 'https://www.googleapis.com/auth/business.manage'


const GoogleMyBusinessConnect = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)
  const dispatch = useDispatch()
  const chain = useSelector(state => state.User.chain)
  const connecting = useSelector(state => state.OauthConnect.connecting)

  const handleSubmitClick = () => {
    dispatch(oauthActions.authorize({
      clientId: process.env.REACT_APP_GOOGLE_BUSINESS_CLIENT_ID,
      url: process.env.REACT_APP_GOOGLE_OAUTH_BASE_URL,
      chainUuid: chain.uuid,
      provider: oauthProviders.GOOGLE_BUSINESS,
      responseType: 'code',
      passRedirect: true,
      accessType: 'offline',
      scope: GOOGLE_BUSINESS_SCOPE,
      prompt: 'consent',
    }))
  }

  const isGoogleBusinessConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  const isConnected = isGoogleBusinessConnected('google_business')

  return (
    <div className='eg-gbusiness-connect'>
      <div className='logo'>
        { isConnected && <div className='check'></div> }
      </div>
      <div className='info-title'>
        { isConnected ? 'Connected' : 'Google My Business' }
      </div>
      <div className='info'>
        <ul className='description'>
          <li>
            <span className='list-icon icon-sync'></span>
            { isConnected 
              ? 'You are set to manage your Google Business with ease.'
              : 'Manage your Google My Business account from here.'
            }
          </li>
        </ul>
        <div className='action'>
          {
            connecting
              ? <LocalSpinner />
              : (
                <Button
                  id='gbusiness-connect'
                  className='connect-btn'
                  text={ isConnected ? 'Connect with a different account' : 'Connect with Google My Business Account' }
                  onClick={ handleSubmitClick }
                />
              )
          }
          
        </div>
      </div>
    </div>
  )
}

GoogleMyBusinessConnect.propTypes = {
  isConnected: PropTypes.bool,
}

export default GoogleMyBusinessConnect
