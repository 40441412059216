import moment from 'moment'
import momentTz from 'moment-timezone'

import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_FORMAT_WITH_DAY
} from '../constants'

export const getFormattedDate = (strDate, hasShortDay = true) => {
  if(!strDate) return ''
  const format = hasShortDay ? DATE_FORMAT_WITH_DAY : DATE_FORMAT
  return moment(strDate).format(format)
}

export const getFormattedTime = strDate => {
  return moment(strDate).format(TIME_FORMAT)
}

export const twoDigits = input => {
  return input < 10 ? `0${input}` : input
}

export const timeToAmPm = strTime => {
  if (!strTime || strTime.indexOf(':') < 0) return null
  let [h, m] = strTime.split(':')
  h = parseInt(h)
  m = parseInt(m)
  return h < 12
    ? `${twoDigits(h)}:${twoDigits(m)} AM`
    : `${twoDigits(h - 12 === 0 ? '12' : parseInt(h) - 12)}:${twoDigits(m)} PM`
}

export const timeTo24 = strTime => {
  if (!strTime || !strTime.match(/(AM|PM)$/)) return null
  const dayTime = strTime.replace(/^.+(AM|PM)$/, '$1')
  const time12 = strTime.replace(/^(\d\d:\d\d).*$/, '$1')
  let [h, m] = time12.split(':')
  h = parseInt(h)
  m = parseInt(m)
  return dayTime === 'AM'
    ? `${twoDigits(h)}:${twoDigits(m)}`
    : `${twoDigits(h + 12 === 24 ? '12' : parseInt(h) + 12)}:${twoDigits(m)}`
}

export const secondsToHuman = totalSeconds => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60)
  const seconds = totalSeconds - (hours * 3600) - (minutes * 60)

  let result = []
  if (hours) result.push(`${hours} hr`)
  if (minutes) result.push(`${minutes} min`)
  result.push(`${seconds} sec`)

  return result.join(', ')
}

export const getTimeFormatted = (dateString, format) => {
  if(!dateString) return ''
  return moment(dateString).format(format)
}

/**
 * Converts a date to given timezone time with given format
 * @param {*} date Date which needs to be converted. i.e ISOstring
 * @param {*} timezone timezone i.e'America/New_york`
 * @param {*} format Format in which the user wants the date string back.
 * @returns 
 */
export const getFormattedDateWithTimeZone = (date, timezone, format) => {
  format = format || 'ddd, MMM DD, YYYY h:mm A'
  if (timezone) {
    return momentTz.tz(date, timezone).format(format)
  }
  return moment(date).format(format)
}

/**
 * Converts a given time (UTC) to the given timezone
 * @param time {string} - time in UTC
 * @param timezone {string} - timezone to convert it to
 *
 * @returns {string} - converted time
*/
export const timeToTimezone = (time, timezone) => {
  return moment.utc(time, 'HH:mm').tz(timezone).format('HH:mm')
}
