import { types } from '../actions/User'
import { AUTH_KEY } from '../constants'

export default class {
  static initialState() {
    const state = localStorage.getItem(AUTH_KEY)
    if (state) {
      try {
        let parsedState = JSON.parse(state)
        return {
          User: parsedState
        }
      } catch (e) {
        return {}
      }
    } else return {}
  }

  static middleware() {
    return store => next => action => {
      if (action.type === types.AUTHENTICATE_INTENT_RESOLVED || action.type === types.UPDATE_CHAIN_INFO) {
        let result = next(action)
        const authState = store.getState().User
        localStorage.setItem(AUTH_KEY, JSON.stringify(authState))
        return result
      } else if (action.type === types.LOGOUT_INTENT) {
        let result = next(action)
        localStorage.removeItem(AUTH_KEY)
        return result
      }  else return next(action)
    }
  }
}
