export const types = {
  GET_EVENTS_INTENT: 'GET_EVENTS_INTENT',
  GET_EVENTS_INTENT_RESOLVED: 'GET_EVENTS_INTENT_RESOLVED',
  GET_EVENTS_INTENT_REJECTED: 'GET_EVENTS_INTENT_REJECTED',

  GET_EVENT_SUMMARY_INTENT: 'GET_EVENT_SUMMARY_INTENT',
  GET_EVENT_SUMMARY_INTENT_RESOLVED:  'GET_EVENT_SUMMARY_INTENT_RESOLVED',
  GET_EVENT_SUMMARY_INTENT_REJECTED: 'GET_EVENT_SUMMARY_INTENT_REJECTED', 

  CLEAR_EVENTS: 'CLEAR_EVENTS'
}

export const actions = {
  getEvents(opts) {
    return {
      type: types.GET_EVENTS_INTENT,
      opts
    }
  },

  getEventsResolved(data) {
    return {
      type: types.GET_EVENTS_INTENT_RESOLVED,
      data
    }
  },

  getEventsRejected() {
    return {
      type: types.GET_EVENTS_INTENT_REJECTED
    }
  },

  getEventSummary(opts) {
    return {
      type: types.GET_EVENT_SUMMARY_INTENT,
      data: { customerId: opts.customerId }
    }
  },

  getEventSummaryResolved(data) {
    return {
      type: types.GET_EVENT_SUMMARY_INTENT_RESOLVED,
      data
    }
  },

  getEventSummaryRejected(error) {
    return {
      type: types.GET_EVENT_SUMMARY_INTENT_REJECTED,
      error
    }
  },

  clearEvents() {
    return {
      type: types.CLEAR_EVENTS
    }
  }
}
