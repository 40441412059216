import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

/**
 * get list of chains/main stores
 *
 * @param {number} page - page number to request
 * @param {number} qty - how many elements per page
 * @param {object} tokens - authentication tokens
 */
export const getChains = (payload, tokens) => {
  const page = payload.opts.page || 1
  const qparams = [
    `page=${page}`
  ]
  if (payload.opts && payload.opts.search) {
    qparams.push(`filter_name=${payload.opts.search}`)
  }
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/chain?${qparams.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens)
  })
}

export const updateGoogleAuthCode = async (payload, tokens) => {
  // first, let's call the endpoint to update the restaurant
  const response = await axios({
    url: `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/restaurants/${payload.restaurantUuid}`,
    method: METHODS.PUT,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload.body)
  })

  // then, we have to 'poll' for a result
  const jobId = response.data.jobId
  if (!jobId) {
    throw new Error('Error while saving authorization code')
  }

  let jobCompleted
  let jobRes
  let retries = 0
  do {
    jobRes = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/jobs/${jobId}`,
      method: METHODS.GET,
      headers: HeaderFactory(tokens)
    })
    const steps = jobRes.data.steps
    jobCompleted = steps.find(s => s.status === 'COMPLETED_RDS')
    retries++
  } while(!jobCompleted && retries < process.env.REACT_APP_MAX_POLLING_RETRIES)

  if (!jobCompleted) {
    throw new Error('Timeout error')
  }
  return jobRes
}
