import React from 'react'
import PropTypes from 'prop-types'

import PercentBar from '../PercentBar'
import './styles.scss'

const FileProgressBar = (props) => {
  const { fileName, percentCompleted, className = '' } = props
  return (
    <div className={`file-progress-bar ${className}`}>
      <div className='row'>
        <div className='name'>
          <div className='file-logo' />
          <div className='file'>{fileName}</div>
        </div>
        <div className='percent'>{percentCompleted}%</div>
      </div>
      <PercentBar value={percentCompleted} displayValues={false} className="percent-bar" />
    </div>
  )
}

FileProgressBar.propTypes = {
  percentCompleted: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default FileProgressBar
