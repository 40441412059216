import { types } from '../actions/LoyaltyIvr'

export const initialState = {
  posting: false,
  saved: false,
  rating: {
    posting: false,
    saved: false,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_SMS_LOYALTY_INTENT:
      return {
        ...state,
        posting: true,
        saved: false
      }

    case types.SAVE_SMS_LOYALTY_INTENT_RESOLVED:
      return {
        ...state,
        posting: false,
        saved: true
      }

    case types.SAVE_SMS_LOYALTY_INTENT_REJECTED:
      return {
        ...state,
        posting: false,
        saved: false
      }

    case types.SAVE_SMS_RATING_INTENT:
      return {
        ...state,
        rating: {
          posting: true,
          saved: false
        }
      }
  
    case types.SAVE_SMS_RATING_INTENT_RESOLVED:
      return {
        ...state,
        rating: {
          posting: false,
          saved: true
        }
      }
  
    case types.SAVE_SMS_RATING_INTENT_REJECTED:
      return {
        ...state,
        rating: {
          posting: false,
          saved: false
        }
      }
      
    case types.GET_SAVED_STORE_INTENT:
      return {
        ...state,
        storeLoading: true
      }

    case types.GET_SAVED_STORE_INTENT_RESOLVED:
      return {
        ...state,
        storeLoading: false,
        store: action.data,
      }
      
    case types.GET_SAVED_STORE_INTENT_REJECTED:
      return {
        ...state,
        storeLoading: false,
        store: null,
      }

    case types.RESET_SAVED:
      return {
        ...state,
        store: null,
        saved: false,
        rating: {
          posting: false,
          saved: false,
        }
      }

    default:
      return state
  }
}
