export const types = {
  GET_CHANNELS_INTENT: 'GET_CHANNELS_INTENT',
  GET_CHANNELS_INTENT_RESOLVED: 'GET_CHANNELS_INTENT_RESOLVED',
  GET_CHANNELS_INTENT_REJECTED: 'GET_CHANNELS_INTENT_REJECTED',

  GET_CHANNELS_OVERVIEW_INTENT: 'GET_CHANNELS_OVERVIEW_INTENT',
  GET_CHANNELS_OVERVIEW_INTENT_RESOLVED: 'GET_CHANNELS_OVERVIEW_INTENT_RESOLVED',
  GET_CHANNELS_OVERVIEW_INTENT_REJECTED: 'GET_CHANNELS_OVERVIEW_INTENT_REJECTED'
}

export const actions = {
  getChannels(opts) {
    return {
      type: types.GET_CHANNELS_INTENT,
      opts
    }
  },

  getChannelsResolved(data) {
    return {
      type: types.GET_CHANNELS_INTENT_RESOLVED,
      data
    }
  },

  getChannelsRejected(error) {
    return {
      type: types.GET_CHANNELS_INTENT_REJECTED,
      error
    }
  },

  getChannelsOverview(opts) {
    return {
      type: types.GET_CHANNELS_OVERVIEW_INTENT,
      opts
    }
  },

  getChannelsOverviewResolved(data) {
    return {
      type: types.GET_CHANNELS_OVERVIEW_INTENT_RESOLVED,
      data
    }
  },

  getChannelsOverviewRejected(error) {
    return {
      type: types.GET_CHANNELS_OVERVIEW_INTENT_REJECTED,
      error
    }
  }
}
