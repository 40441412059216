import axios from 'axios'
import moment from 'moment'
import { METHODS, HeaderFactory } from './utils'
import { mapCampaignOut, campaignTypes, dateTypes } from '../services/campaigns'
import { replaceTestCustomFields } from '../services/sms'

/**
 * Create SMS campaign
 *
 * @param opts {Object} - method options
 * @param opts.campaign_name {string} - campaign name
 * @param opts.message {string} - text to be sent
 * @param opts.target {string} - segment target
 * @param tokens {Object} - authentication tokens
*/
export const createCampaign = async ({ data, chain }, tokens) => {
  const { campaign_name, campaign_segment, step } = data
  const chainUuid = chain.restaurant_chain_uuid
  const payload = {
    name: campaign_name,
    target: campaign_segment.value,
    step
  }

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/chain/${chainUuid}/campaign`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}

/**
 * update existing campaign
 *
 * @param opts {Object} - method options
 * @param opts.data {Object} - data to update
 * @param opts.chain {Object} - chain associated with the campaign
 * @param tokens {Object} - auth tokens
*/
export const updateCampaign = async ({ data, chain }, tokens) => {
  const chainUuid = chain.restaurant_chain_uuid
  const campaignId = data.campaignId
  if (data.message && !data.message.includes(`${chain.name}:`)) {
    data.message = `${chain.name}: ${data.message}`
  }
  const payload = {
    ...mapCampaignOut(data)
  }

  if (data.step) {
    payload.step = data.step
  }

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaignId}?chain_uuid=${chainUuid}`,
    method: METHODS.PATCH,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}

export const getCampaignList = (opts, tokens) => {
  const { chainUuid, page, size, status } = opts
  const queryParameters = [
    `chain_uuid=${chainUuid}`,
  ]
  if(page){
    queryParameters.push(`page=${page}`)
  }
  if(size) {
    queryParameters.push(`size=${size}`)
  }
  if(status) {
    queryParameters.push(`status[0]=${status}`)
    if(status === campaignTypes.SCHEDULED) {
      // While getting scheduled campaigns we will get them in ascending order
      queryParameters.push(`sort=${dateTypes[status]}`)
    } else {
      queryParameters.push(`sort=-${dateTypes[status]}`)
    }
  }
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/campaign`

  return axios({
    url: `${baseUrl}?${queryParameters.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

/**
 * Send test SMS message
 *
 * @param opts {Object} - method options
 * @param opts.numbers {Array} - list
 * @param opts.text {string} - text to be sent
*/
export const sendTestMessage = ({ data, chain, user }, tokens) => {
  const chainUuid = chain.restaurant_chain_uuid
  const numbers = data.numbers.map(num => `+1${num.key}`)
  const message = `${chain.name}: ${replaceTestCustomFields(data.message, user)}

  [ Test ]`

  const payload = {
    recipients: numbers,
    text: message
  }

  if (data.imageUrl) {
    payload.image_url = data.imageUrl
  }

  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/test?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}

/**
 * Get estimated recipients
 *
 * @param opts {Object} - method options
 * @param opts.chainUuid {string} - chain uuid
 *
 * @returns {Promise} - request promise
*/
export const getEstimatedRecipients = ({ chainUuid, campaignId }, tokens) => {
  const baseUrl = `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaignId}/customers-estimated`
  return axios({
    url: `${baseUrl}?chain_uuid=${chainUuid}&target=all_opted_in_customers`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

/**
 * Cancel a scheduled campaign
 *
 * @param opts {Object} - method options
 * @param opts.chainUuid {string} - chain uuid
 * @param opts.campaign {Object} - campaign to cancel
 *
 * @returns {Promise} - request promise
*/
export const cancelCampaign = ({ chainUuid, campaign }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaign.id}/cancel?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
  })
}

/**
 * Schedules a new campaign
 *
 * @param opts {Object} - method options
 * @param opts.chainUuid {string} - chain uuid
 * @param opts.campaign {Object} - campaign to schedule
 *
 * @returns {Promise} - request promise
*/
export const scheduleCampaign = ({ chainUuid, campaign }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaign.id}/schedule?chain_uuid=${chainUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
  })
}

export const getCampaign = ({ chainUuid, campaignId }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaignId}?chain_uuid=${chainUuid}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

export const getCampaignChartData = ({ chainUuid, campaign }, tokens) => {
  const queryParameters = [
    `chain_uuid=${chainUuid}`,
    `from_date=${campaign.send_date}`,
    `to_date=${moment(campaign.send_date).add(24, 'hour').toISOString()}`,
    'granularity=hour'
  ]
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaign.id}/chart?${queryParameters.join('&')}`,
    method: METHODS.GET,
    headers: HeaderFactory(tokens),
  })
}

export const createShortnedUrl = (options, tokens) => {
  const { chainUuid, campaignId, url } = options
  const payload = {
    url
  }
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/campaign/${campaignId}/shortlink?chain_uuid=${chainUuid}`,
    method: METHODS.PUT,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(payload)
  })
}
