import React from 'react'
import PropTypes from 'prop-types'

import TimeRangeInput from '../../../../../FormComponents/TimeRangeInput'
import Checkbox from '../../../../../Checkbox'
import { scheduleStatuses } from '../../../../../../services/stores'

import styles from './index.module.scss'

const ScheduleTimeRange = props => {
  const { closeIsDisabled, schedule, index } = props
  const closeDisabledClass = closeIsDisabled ? styles.closedDisabled : ''
  const time = schedule
  const from = time ? time.from : null
  const to = time ? time.to : null

  const handleTimeChange = (index, time, type) => {
    if (props.onTimeChange) {
      props.onTimeChange(index, time, type)
    }
  }

  const handleClosedClick = index => {
    if (props.onClosedClick) {
      props.onClosedClick(index)
    }
  }

  return (
    <div className={styles.container}>
      <TimeRangeInput
        from={{ time: from, placeholder: 'Opens' }}
        to={{ time: to, placeholder: 'Closes' }}
        disabled={ schedule.state === scheduleStatuses.CLOSED }
        onChange={ (time, type) => handleTimeChange(index, time, type) }
        show24hours={true}
      />
      <div
        className={ `${styles.closedCheck} ${closeDisabledClass}` }
        onClick={ () => !closeIsDisabled && handleClosedClick(index) }
      >
        <Checkbox
          className={ styles.checkbox }
          checked={ schedule.state === scheduleStatuses.CLOSED }
        />
        <span>Closed</span>
      </div>
      
    </div>
  )
}

ScheduleTimeRange.propTypes = {
  schedule: PropTypes.object,
  closeIsDisabled: PropTypes.bool,
  index: PropTypes.number,
  onTimeChange: PropTypes.func,
  onClosedClick: PropTypes.func
}

ScheduleTimeRange.defaultProps = {}

export default ScheduleTimeRange
