import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import CustomerHeader from '../../components/CustomerHeader'
import ReportList from '../../components/Reports/ReportList'
import PaginationSection from '../../components/PaginationSection'
import { actions as reportActions } from '../../actions/Report'

import './styles.scss'

const DEFAULT_PAGE_SIZE = 25

const Reports = (props) => {
  const dispatch = useDispatch()
  const reportList = useSelector(state => state.Report.reportList)
  const pageSize = _.get(reportList, 'list.size') || DEFAULT_PAGE_SIZE

  useEffect(() => {
    dispatch(reportActions.getReports({ page: 1, size: DEFAULT_PAGE_SIZE }))
  }, [dispatch])

  const handlePageChange = (page) => {
    dispatch(reportActions.getReports({ page, size: pageSize }))
  }

  const handlePageSizeChange = (size) => {
    dispatch(reportActions.getReports({ page: 1, size }))
  }

  return (
    <div className='eg-reports'>
      <CustomerHeader />
      <div className='content'>
        <div className='top'>
          <h3>Reports</h3>
        </div>
        <ReportList
          data={reportList}
        />
        {
          reportList.list &&
          <div className='pagination'>
            <PaginationSection
              total={_.get(reportList, 'list.total')}
              size={_.get(reportList, 'list.size')}
              page={_.get(reportList, 'list.page')}
              onPageChange={handlePageChange}
              onSizeChange={handlePageSizeChange}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default Reports
