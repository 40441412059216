import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { googleConnectInit } from '../../services/googleConnect'
import EGAnalytic, { analyticEvents } from '../../services/analytics'

import CustomerHeader from '../../components/CustomerHeader'
import TabSelector from '../../components/TabSelector'
import OrderDetails from './OrderDetails'
import Pagination from '../../components/Pagination'
import ListHeader from '../../components/ListHeader'
import { ORDER_STATUSES, ORDER_SORT_FIELDS } from '../../services/orders'
import Search from '../../components/Search'
import StoreFilter from '../../components/Store/StoreFilter'
import { SEARCH_INPUT_INTERVAL, GOOGLE_CONNECT_STATUSES } from '../../constants'
import OrderList from './OrderList'
import LocalSpinner from '../../components/LocalSpinner'
import OrderCalendar from '../../components/OrderCalendar'
import { viewTypes } from '../../components/Calendar'
import GoogleConnectButton from '../../components/GoogleConnectButton'
import GoogleConnectModalContent from '../../components/GoogleConnectModalContent'
import Modal from '../../components/Modal'
import { gcTypes } from '../../components/GoogleConnectButton'
import styles from './index.module.scss'
import GoogleConnectBanner from '../../components/GoogleConnectBanner'

const tabItems = [
  { label: 'All', key: 'all' },
  { label: 'Pending', key: ORDER_STATUSES.PENDING },
  { label: 'Completed', key: ORDER_STATUSES.COMPLETED },
  { label: 'Cancelled', key: ORDER_STATUSES.CANCELLED }
]

const listHeaders = [
  { label: 'Order No.', key: ORDER_SORT_FIELDS.ORDER_ID, sortKey: 'restaurant_order_id', isSortable: true },
  { label: 'Delivery Sched', key: ORDER_SORT_FIELDS.DELIVERY_DATE, sortKey: 'order_date', isSortable: true },
  { label: 'Delivery Address', key: ORDER_SORT_FIELDS.DELIVERY_ADDRESS, sortKey: 'customer_address', isSortable: true },
  { label: 'Total Price', key: ORDER_SORT_FIELDS.TOTAL_PRICE,  sortKey: 'total', isSortable: true },
  { label: 'Customer', key: ORDER_SORT_FIELDS.CUSTOMER, sortKey: 'customer_first_name', isSortable: true },
  { label: 'Status', key: ORDER_SORT_FIELDS.STATUS, sortKey: 'status', isSortable: false }
]

const orderViews = {
  CALENDAR: 'calendar',
  LIST: 'list'
}

export default class Orders extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    orders: PropTypes.array,
    page: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.number,
    loading: PropTypes.bool,
    searching: PropTypes.bool,
    googleConnectPosting: PropTypes.bool,
    googleConnectError: PropTypes.bool,
    getOrders: PropTypes.func,
    onPageChange: PropTypes.func,
    filterStatus: PropTypes.func,
    searchChanged: PropTypes.func,
    getGoogleAuthCode: PropTypes.func,
    disableGoogleConnectModal: PropTypes.func,
    googleConnectModalOffRestros: PropTypes.array,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    lastUsedChainUuid: PropTypes.string,
    getStoreList: PropTypes.func.isRequired,
  }

  static defaultProps = {
    restaurant: {},
    loading: false
  }

  constructor() {
    super()

    // initial state
    this.state = {
      statusFilter: tabItems[0],
      selectedOrder: null,
      searchValue: '',
      currentView: orderViews.LIST,
      calendarView: viewTypes.MONTH,
      calendarDate: null,
      startDate: null,
      endDate: null,
      currentSortColumnStatus: {},
      googleApiReady: false,
    }

    // bindings
    this.getStoreOrders = this.getStoreOrders.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.pageChange = this.pageChange.bind(this)
    this.handleOrderClick = this.handleOrderClick.bind(this)
    this.handleDetailsClose = this.handleDetailsClose.bind(this)
    this.searchChanged = this.searchChanged.bind(this)
    this.renderList = this.renderList.bind(this)
    this.renderCalendar = this.renderCalendar.bind(this)
    this.renderOrders = this.renderOrders.bind(this)
    this.renderGoogleConnect = this.renderGoogleConnect.bind(this)
    this.datesRenderHandler = this.datesRenderHandler.bind(this)
    this.switchToCalendar = this.switchToCalendar.bind(this)
    this.switchToList = this.switchToList.bind(this)
    this.handleEventClick = this.handleEventClick.bind(this)
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.restaurant)) {
      //track order page view
      EGAnalytic.track(analyticEvents.ORDER_LIST_PAGE_VIEWED, EGAnalytic.mapRestaurant(this.props.restaurant))
      this.getStoreOrders()
      const { lastUsedChainUuid, getStoreList, restaurant } = this.props
      const restaurantChainUuid = restaurant.restaurant_chain_uuid
      // get store list for filters,  if we havne't retrieved yet
      if(lastUsedChainUuid !== restaurantChainUuid){
        getStoreList()
      }
    }
    
    googleConnectInit() // setting gapi.

    const { restaurant } = this.props
    const modalOffRestros = this.props.googleConnectModalOffRestros ? this.props.googleConnectModalOffRestros : []
    if(restaurant.google_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_ERROR && !modalOffRestros.includes(restaurant.uuid)) { // IF this condition is true, we will open the modal.
      this.props.openModal()
    }
  }

  componentDidUpdate(prvProps){
    const { restaurant } = this.props
    if(restaurant.google_connect_status !== GOOGLE_CONNECT_STATUSES.TOKEN_ERROR){
      this.props.closeModal()
    }
  }

  getStoreOrders() {
    if (this.state.currentView === orderViews.CALENDAR) {
      this.props.getOrders({
        startDate: this.state.startDate,
        endDate: this.state.endDate
      })
    } else {
      this.props.getOrders()
    }
  }

  /**
   * change the selected status filter when tab changes
   *
   * @param {object} item - tab item selected
   */
  handleTabChange(item) {
    this.setState({
      statusFilter: item
    })
    const orderOpts = {
      page: 1,
      status: item.key,
      search: this.state.searchValue
    }
    if (this.state.currentView === orderViews.CALENDAR) {
      orderOpts.startDate = this.state.startDate
      orderOpts.endDate = this.state.endDate
    }
    this.props.getOrders(orderOpts)
  }

  /**
   * handle page change
   *
   * @param {number} page - new page to be requested
   */
  pageChange(page) {
    const { currentSortColumnStatus } = this.state
    const sortColumnData = currentSortColumnStatus.sortKey ? currentSortColumnStatus : undefined
    this.props.getOrders({
      page,
      status: _.get(this.state.statusFilter, 'key'),
      search: this.state.searchValue,
      sortColumnData,
    })
  }

  /**
   * handle click on an order item, by marking
   * it as selected
   *
   * @param {object} order - order to be selected
  */
  handleOrderClick(order) {
    this.setState({
      selectedOrder: order
    })
  }

  /**
   * called when user closes the details modal
  */
  handleDetailsClose() {
    this.setState({
      selectedOrder: null
    })
  }

  /**
   * handle search input changes
   *
   * @param {string} value - current input value
    */
  searchChanged(value) {
    this.setState({
      searchValue: value
    }, () => {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const orderOpts = {
          search: value,
          status: _.get(this.state.statusFilter, 'key')
        }
        if (this.state.currentView === orderViews.CALENDAR) {
          orderOpts.startDate = this.state.startDate
          orderOpts.endDate = this.state.endDate
        }
        this.props.getOrders(orderOpts)
      }, SEARCH_INPUT_INTERVAL)
    })
  }

  /**
   * render order list view
  */
  renderList() {
    return (
      <>
        <ListHeader
          items={ listHeaders } 
          applySorting = {this.applySortingOnColumn}  
          selectedColumnInfo= {this.state.currentSortColumnStatus}
          className={styles.listHeader}
        />

        {/* List of orders */}
        <OrderList
          orders={ this.props.orders }
          onOrderClick={ this.handleOrderClick }
        />

        {/* Pagination */}
        <Pagination
          className={ styles.pagination }
          currentPage={ this.props.page }
          pageCount={ Math.ceil(this.props.total / this.props.size) }
          onPageClick={ this.pageChange }
          marginPages= { 1 }
          pageRange= { 1 }
        />
      </>
    )
  }

  /**
   * render calendar view
  */
  renderCalendar() {
    return (
      <OrderCalendar
        orders={ this.props.orders }
        date={ this.state.calendarDate }
        view={ this.state.calendarView }
        onDatesRender={ this.datesRenderHandler }
        onEventClick={ this.handleEventClick }
      />
    )
  }

  /**
   * decide which view to render based on state
  */
  renderOrders() {
    switch (this.state.currentView) {
      case orderViews.LIST:
        return this.renderList()

      case orderViews.CALENDAR:
        return this.renderCalendar()

      default:
        return this.renderList()
    }
  }

  renderGoogleConnectButton = () => {
    return (
      <>
         <GoogleConnectButton
           className={ styles.googleConnectButton }
           text='Connect with Google'
           loading={ this.props.googleConnectPosting }
           onClick={ this.props.getGoogleAuthCode }
         />
          {
            this.props.googleConnectError ? (
              <div className={ styles.googleConnectError }>
                An error ocurred while trying to connect with Google.
                Please try again later
              </div>
            ) : null
          }
      </>
    )
  }

  /**
   * render Google Connect option. This should happen when the user
   * had skipped the connect option in onboarding process.
  */
  renderGoogleConnect() {
    return (
      <div className={ styles.googleConnectCard }>
        <div className={ styles.googleConnectWrapper }>
          <h4>Connect your inbox with Eatgeek System</h4>
          <p>
            Eatgeek will recognize and import your orders from your
            email to help you track and manage them. Make sure to
            integrate the email addresss where you currently receive
            your caterings orders.
          </p>
          {this.renderGoogleConnectButton()}
         
        </div>
      </div>
    )
  }

  /**
   * handle the event triggered when calendar is rendered
   *
   * @param {moment} startDate
   * @param {object} info - calendar information [optional]
  */
  datesRenderHandler(startDate, endDate, info) {
    if (startDate !== this.state.startDate || endDate !== this.state.endDate) {
      const calendarDate = moment(info.view.currentStart).format('YYYY-MM-DD')
      const calendarView = info.view.type
      this.setState({ startDate, endDate, calendarDate, calendarView }, () => {
        this.props.getOrders({
          status: _.get(this.state.statusFilter, 'key'),
          search: this.state.searchValue,
          startDate,
          endDate
        })
      })
    }
  }

  switchToCalendar() {
    if (this.state.currentView !== orderViews.CALENDAR) {
      //track order page view change
      EGAnalytic.track(analyticEvents.ORDER_LIST_VIEW_CHANGED, {
        ...EGAnalytic.mapRestaurant(this.props.restaurant),
        orderListViewType: 'Calendar View',
      })
      this.setState({ currentView: orderViews.CALENDAR }, () => {
        this.props.getOrders({
          status: _.get(this.state.statusFilter, 'key'),
          search: this.state.searchValue,
          startDate: this.state.startDate,
          endDate: this.state.endDate
        })
      })
    }
  }

  switchToList() {
    if (this.state.currentView !== orderViews.LIST) {
      //track order page view change
      EGAnalytic.track(analyticEvents.ORDER_LIST_VIEW_CHANGED, {
        ...EGAnalytic.mapRestaurant(this.props.restaurant),
        orderListViewType: 'List View',
      })
      this.setState({ currentView: orderViews.LIST }, () => {
        this.props.getOrders({
          status: _.get(this.state.statusFilter, 'key'),
          search: this.state.searchValue
        })
      })
    }
  }

  handleEventClick(order) {
    this.setState({
      selectedOrder: order
    })
  }
  
  /**
   * handles click on the column tab arrow to sort data according to that column.
   * @param {Object} column holds the key name on which the click has happened.
   */
  applySortingOnColumn = (column) => {
    this.setState(({currentSortColumnStatus}) => {
      if(currentSortColumnStatus.sortKey === column.sortKey){
        currentSortColumnStatus.order = currentSortColumnStatus.order === 'desc'? 'asc' : 'desc'
      } else{
        currentSortColumnStatus = {sortKey: column.sortKey, order: 'desc'}
      } 
      return {currentSortColumnStatus}
    }, () => {
      this.props.getOrders({
        page: 1,
        status: _.get(this.state.statusFilter, 'key'),
        search: this.state.searchValue,
        sortColumnData: this.state.currentSortColumnStatus
      })
    })
  }

  /**
   * Get Google Connect Modal Pop up.
   */
  getGoogleConnectModalContent = () => {
    return (
      <Modal onClose={this.handleModalClose}>
        <GoogleConnectModalContent restaurantEmail={this.props.restaurant.gmail_integration_email} >
          {this.renderGoogleConnectButton()}
        </GoogleConnectModalContent>
      </Modal>
    )
  }

  /**
   * Disables Google Connect Modal for current session
   */
  handleModalClose = () => {
    const restaurantUuid = this.props.restaurant.uuid
    this.props.disableGoogleConnectModal(restaurantUuid)
  }

  renderGoogleConnectBanner = () => {
    return this.props.restaurant.google_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_ERROR
      ? (<GoogleConnectBanner  restaurantEmail={this.props.restaurant.gmail_integration_email}>
        <GoogleConnectButton
          className={ styles.googleConnectButton }
          text='Connect with Google'
          type={ gcTypes.SECONDARY }
          loading={ this.props.googleConnectPosting }
          onClick={ this.props.getGoogleAuthCode }
        />
        {
          this.props.googleConnectError ? (
            <div className={ styles.googleConnectError }>
                An error ocurred while trying to connect with Google.
                Please try again later
            </div>
          ) : null
        }
      </GoogleConnectBanner>)
      : null
  }

  renderMainContent = () => {
    //Note: Adding chain list feature, hiding showing the gmail integration part for now.
    // return this.props.restaurant.google_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_MISSING 
    //   ? (
    //     <div className={ styles.listContainer }>
    //       { this.renderGoogleConnect() }
    //     </div>
    //   ) 
    //   : this.renderOrderData()
    return this.renderOrderData()
  }

  renderOrderData = () => {
    const orders = this.props.orders
    const currentView = this.state.currentView
    const integrationEmail = _.get(this.props, 'restaurant.gmail_integration_email') || ''
    return (
      (orders && orders.length) || currentView === orderViews.CALENDAR 
        ? (
          <div className={ styles.listContainer }>
            {
              this.props.searching ? (
                <LocalSpinner className={ styles.spinner } />
              ) : (
                <div>
                  { this.renderOrders() }

                  {/* Modal that will open when an order is selected, with order details */}
                  <OrderDetails
                    restaurant={ this.props.restaurant }
                    active={ !!this.state.selectedOrder }
                    order={ this.state.selectedOrder }
                    onClose={ this.handleDetailsClose }
                  />
                </div>
              )
            }
          </div>
        ) 
        : (
          <div>
            {
              !this.props.loading && !this.props.searching ? (
                <div className={ styles.emptyView }>
                  <div className={ styles.emptyViewContainer }>
                    <div className={ styles.emptyViewTitle }>
                    No orders to show
                    </div>
                    <p>
                    This is where you will see orders received from your connected inbox
                      { integrationEmail ? ` - ${integrationEmail}`: '.' }
                    </p>
                  </div>
                </div>
              ) : (
                <LocalSpinner className={ styles.spinner } />
              )
            }
          </div>
        )
    )
  }

  render() {
    const isCalendar = this.state.currentView === orderViews.CALENDAR
    const calActiveClass = isCalendar ? styles.active : ''
    const listActiveClass = !isCalendar ? styles.active : ''
    return (
      <div>
        <CustomerHeader />
        {/* {this.getGoogleConnectModalContent()} */}
        <div className={ styles.container }>
          {/* {this.renderGoogleConnectBanner()} */}
          <h3>Requests &amp; Orders</h3>
          <TabSelector
            items={ tabItems }
            className={ styles.tabContainer }
            selectedItem={ this.state.statusFilter }
            onChange={ this.handleTabChange }
          />

          <div className={ styles.searchWrapper }>
            
            <div className={styles.leftPart}>
              <StoreFilter onFilterChange={this.getStoreOrders}/>
              <Search
                className={ styles.search }
                value={ this.state.searchValue }
                onChange={ this.searchChanged }
              />
            </div>

            <div className={ styles.viewActions }>
              <div
                className={ `${styles.btnView} ${styles.btnCalendar} ${calActiveClass}` }
                onClick={ this.switchToCalendar }
              />
              <div
                className={ `${styles.btnView} ${styles.btnList} ${listActiveClass}` }
                onClick={ this.switchToList }
              />
            </div>
          </div>
          {this.renderMainContent()}     
        </div>
      </div>
    )
  }
}
