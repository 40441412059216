import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FilterOptions from '../../FilterOptions'

const MultipleSelectionFilter = props => {
  const {
    filterItems,
    defaultActionLabel,
    filterType,
    FilterContent
  } = props
  const initialItemsState = Object.values(filterItems).map(item => {
    return {
      key: item.key,
      label: item.label,
      value: false
    }
  })
  const [ actionLabel, setActionLabel ] = useState(defaultActionLabel)
  const [ filterOptions, setFilterOptions ] = useState([ ...initialItemsState ])

  const selectedItems = filterOptions.filter(op => op.value)
  const selectedCount = selectedItems.length

  const handleChange = (item, value) => {
    const opts = [ ...filterOptions ]
    const selected = opts.find(opt => opt.label === item.label)
    selected.value = value
    setFilterOptions(opts)
  }

  const handleApply = () => {
    setActionLabel(`${defaultActionLabel} (${selectedCount})`)
    if (props.onApply) {
      props.onApply({
        [filterType]: selectedItems
      })
    }
  }

  const handleClear = () => {
    setActionLabel(defaultActionLabel)
    setFilterOptions([ ...initialItemsState ])
    if (props.onClear) {
      props.onClear({ filterType })
    }
  }

  return (
    <div data-testid={ `${defaultActionLabel.replace(/\s/g, '_').toLowerCase()}-filter` }>
      <FilterOptions
        actionLabel={ actionLabel }
        onApply={ handleApply }
        onClear={ handleClear }
        applyOnClickOut
        applyDisabled={ !selectedCount }
        selectedItems={ selectedItems }
        customItemsComponent={
          <FilterContent
            items={ filterOptions }
            selectedItems={ selectedItems }
            onChange={ handleChange }
          />
        }
      />
    </div>
  )
}

MultipleSelectionFilter.propTypes = {
  filterItems: PropTypes.array,
  defaultActionLabel: PropTypes.string,
  filterType: PropTypes.string,
  FilterContent: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func
}

export default MultipleSelectionFilter
