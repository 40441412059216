import get from 'lodash/get'

export const mapChainListData = (response = {}) => {
  const { chains = [] }  = response
  const mappedChain =  chains.map((chain) => {
    const name = chain.name
    const restaurant_chain_uuid = chain.uuid
    const totalStores = get(chain, 'store_uuids.length')
    return {
      ...chain,
      name,
      restaurant_chain_uuid,
      totalStores
    }
  })
  const mappedResponse = {
    ...response,
    chains: mappedChain,
  }
  return mappedResponse
}

// Structure of a chain in chainlist
// "id":1,
// "uuid":"1f77a64c-7933-48fe-af05-34f29621d3d3",
// "name":"Halal Guys Chain",
// "contact_first_name":"Jorge",
// "contact_last_name":"Halal",
// "contact_email":"contact-halal@example.com",
// "created_at":null,
// "updated_at":null,
// "deleted_at":null,
// "restaurants":[
//   {
//     "restaurant_id":309,
//     "uuid":"ca41f2e1-767d-11e9-9bae-3f001b50f0ca",
//     "name":"The Halal Guys (1000 3rd St)",
//     "contact_name":"Manjit",
//     "address1":"1000 3rd St"
//   },
//   {
//    ...
//    }
// ]
