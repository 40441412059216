import { call, takeLatest, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import get from 'lodash/get'

import { types, actions } from '../actions/Store'
import { authorizedRequest } from './Auth'
import { PATHS } from '../constants'
import { getStoreList, createStore, getStore, updateStore, getIntegrationStatus } from '../api/Store'
import { mapStoreToApp, mapAppToStore, mapStoreList, gmbCategories } from '../services/stores'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'


function* getStoreListHandler() {
  const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  const res = yield call(authorizedRequest, getStoreList, {
    restaurantChainUuid,
  })
  if (res && res.data) {
    const { stores = [],  ...restResponseData }= res.data
    const { storeList, storeListLookup } = mapStoreList(stores)
    const storeListData = {
      ...restResponseData,
      restaurantChainUuid,
      storeList,
      storeListLookup
    }
    yield put(actions.getStoreListResolved(storeListData))
  } else {
    console.error('Failed to get the list for store chain.', res)
    const errorMessage = get(res, 'error.message')
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: `Something went wrong while getting store list. :(
        Error message: ${errorMessage}`
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getStoreListRejected(res))
  }
}

function* createStoreHandler({ data }) {
  const restaurantChainUuid = yield select(state => state.User.chain.restaurant_chain_uuid)
  const { name, address, phone, categories = [] } = data
  const caterer_types = categories.map(({caterer_type_id}) => ({ caterer_type_id }))
  const payload = {
    name,
    address1: address,
    phone,
  }
  if(caterer_types.length !== 0) {
    payload['caterer_types'] = caterer_types
  }

  const res = yield call(authorizedRequest, createStore, {
    restaurantChainUuid,
    payload,
  })

  if (res && res.data) {
    const storeUuid = res.data.uuid
    yield put(actions.createStoreResolved(res.data))
    yield put(push(`${PATHS.STORES}/edit/${storeUuid}`))
  } else {
    yield put(actions.createStoreRejected(res))
    console.error('Failed to create store with error: ', res)
    const errorMessage = get(res, 'error.message')
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: `Sorry, Something went wrong. Could you please try again? :(
        Error message: ${errorMessage}`
    }
    yield put(EgNotificationActions.ShowNotification(notification))
  }
}

function* getStoreHandler({ data }) {
  const restaurantUuid =  data.restaurantUuid
  const res = yield call(authorizedRequest, getStore, {
    restaurantUuid,
  })
  if (res && res.data) {
    const mappedData = yield mapStoreToApp(res.data)
    yield put(actions.getStoreResolved(mappedData))
  } else {
    const errorMessage = get(res, 'error.message')
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: `Something went wrong while fetching store details. :(
        Error message: ${errorMessage}`
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getStoreRejected(res))
  }
}

function* updateStoreHandler({ data, section }) {
  const res = yield call(authorizedRequest, updateStore, {
    restaurantUuid: data.uuid,
    data: mapAppToStore(data, section)
  })
  if (res && res.data) {
    yield put(actions.updateStoreResolved(res.data))
    yield put(actions.getStore({ restaurantUuid: data.uuid }))
  } else {
    const errorMessage = get(res, 'error.message')
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: `Something went wrong while updating store details. :(
        Error message: ${errorMessage}`
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.updateStoreRejected(res))
  }
}

function* getCategoriesHandler() {
  const res = gmbCategories.map(category => {
    return {
      name: category.name,
      caterer_type_id: category.id
    }
  })
  yield put(actions.getCategoriesResolved(res))
}

function* handleGetIntegrationStatus({ data }) {
  try {
    const { restaurantUuid } =  data
    const response = yield call(authorizedRequest, getIntegrationStatus, {
      restaurantUuid,
    })
    if(response && response.status === 200) {
      const status = response.data || {}
      yield put(actions.getIntegrationStatusResolved({...status}))
    } else {
      throw new Error(response)
    }
  }
  catch (error) {
    console.error('Failed to get integration application status: ', error)
    yield put(actions.getIntegrationStatusFailed(error))
  }
}


export default function* () {
  yield takeLatest(types.GET_STORE_LIST_INTENT, getStoreListHandler)
  yield takeLatest(types.CREATE_STORE_INTENT, createStoreHandler)
  yield takeLatest(types.GET_STORE_INTENT, getStoreHandler)
  yield takeLatest(types.UPDATE_STORE_INTENT, updateStoreHandler)
  yield takeLatest(types.GET_CATEGORIES_INTENT, getCategoriesHandler)
  yield takeLatest(types.GET_INTEGRATION_STATUS, handleGetIntegrationStatus)
}
