import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export const statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const StatusBubble = props => {
  const { label, status, className = '' } = props
  return (
    <div className={ `eg-status-bubble ${className}` } data-testid='status-bubble'>
      <div className={ `content ${status}` }>
        <div className='indicator' />
        { label }
      </div>
    </div>
  )
}

StatusBubble.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default StatusBubble
