import React from 'react'
import PropTypes from 'prop-types'

import PredicateTemplate from './PredicateTemplate'
import { Select, CloseButton } from '../../FormComponents'

import './styles.scss'

const OrDivider = () => {
  return (
    <div className='or-border'>
      <div className='box'>or </div>
      <div className='border' />
    </div>
  )
}

const conditions = [
  { value: 'activity', label: 'Customer\'s activity' }
]

const PredicateGroup = (props) => {
  const {
    predicates,
    onPredicateChange,
    onPredicateRemove,
    onGroupRemove,
    onOrAddClick
  } = props

  const addRow = () => {
    onOrAddClick()
  }

  const onChange = (updatedPredicates, predicatesIndex) => {
    const updateInfo = {
      updatedPredicates,
      predicatesIndex
    }
    onPredicateChange(updateInfo)
  }

  return (
    <div className='eg-dynamic-segments-predicate-group'>
      <Select
        className='condition'
        options={ conditions }
        selectedItem={ conditions[0] }
      />
      {
        predicates.map((predicateObject, index) => {
          return (
            <div key={index} className='predicate-item'>
              {/* add divider after first row or first query. */}
              { index > 0 && <OrDivider /> }
              { index > 0 && <CloseButton className='close' onClick={ () => onPredicateRemove(index) } /> }
              { index === 0 && !!onGroupRemove && <CloseButton className='close' onClick={ onGroupRemove } /> }
              <div className='predicate-container'>
                <div className='row'>
                  <PredicateTemplate
                    predicates={predicateObject.predicates}
                    onChange={(updatePredicates) => onChange(updatePredicates, index)}
                  />
                </div>
                <div className='or-add' onClick={() => addRow(index)}>OR</div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

PredicateGroup.propTypes = {
  predicates: PropTypes.array,
  onPredicateChange: PropTypes.func,
  onPredicateRemove: PropTypes.func,
  onGroupRemove: PropTypes.func,
  onOrAddClick: PropTypes.func,
}

export default PredicateGroup
