import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import querystring from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { actions as oauthActions } from '../../actions/Oauth'
import { actions as sidebarActions } from '../../actions/Sidebar'
import { oauthProviders } from '../../services/auth'
import  { setStatusOfSuccessfulIntegration } from '../../services/oauth'
import { PATHS } from '../../constants'

import './styles.scss'

const OauthCallback = (props) => {
  const qs = querystring.parse(props.location.search)
  const dispatch = useDispatch()
  const { connecting, connected, error } = useSelector(state => state.OauthConnect)
  const { code, state } = qs

  useEffect(() => {
    dispatch(sidebarActions.hideSidebar())
    dispatch(oauthActions.connect({
      code: code,
      redirect_uri: `https://${window.location.host}${PATHS.FACEBOOK_OAUTH_CALLBACK}`,
      restaurantUuid: state,
      provider: oauthProviders.FACEBOOK
    }))
  }, [dispatch, code, state])

  useEffect(() => {
    if (!connecting && connected) {
      setTimeout(() => {
        setStatusOfSuccessfulIntegration()
        window.close()
      }, 3000)
    }
  }, [connecting, connected])

  if (error) {
    return (
      <div className='eg-oauth-callback'>
        <div className='facebook' />
        <div className='title'>Uops! Something went wrong</div>
        <div className='description'>
          An error ocurred. Please try again later.
        </div>
      </div>
    )
  }

  return (
    <div className='eg-oauth-callback'>
      <div className='facebook' />
      <>
      {
        connecting ? (
          <div className='connecting'>
            <div className='title'>Connecting... </div>
            <div className='description'>
              We are connecting to your Facebook account.
            </div>
          </div>
        ) : (
          <>
            <div className='title'>Connected!</div>
            <div className='description'>
              Your account was successfully connected
            </div>
          </>
        )
      }
      </>
    </div>
  )
}

OauthCallback.propTypes = {
  location: PropTypes.object
}

export default withRouter(OauthCallback)
