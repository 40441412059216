import { types } from '../actions/Plaid'

const initialState = {
  posting: false,
  error: false,
}

export default (state = initialState, action) => {
  switch(action.type) {
    case types.INITIATE_TOKEN_EXCHANGE:
      return {
        ...state,
        posting: true,
        error: false
      }

    case types.TOKEN_EXCHANGE_SUCESSFULL:
      return {
        ...state,
        posting: false,
        error: false
      }

    case types.TOKEN_EXCHANGE_FAILED:
      return {
        ...state,
        posting: false,
        error: action.data
      }
    
    default:
      return state
  }
}
