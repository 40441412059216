import React from 'react'
import PropTypes from 'prop-types'

import { getAbsoluteUrl } from '../../../../../utils'
import { getLinkLabel } from '../../../../../services/stores'

import mainStyles from '../main.module.scss'

const Info = props => {
  const store = props.store

  const handleEditClick = () => {
    if (props.onEditClick) {
      props.onEditClick()
    }
  }

  return (
    <div className={ mainStyles.editableSection }>
      <h3>Website and links</h3>
      {
        store.links.map(link => {
          return (
            <div className={ mainStyles.editableSectionRow } key={ link.subtype }>
              <div className={ mainStyles.editableItemLabel }>{ getLinkLabel(link.subtype) }</div>
              <div className={ mainStyles.editableItemValue }>
                {
                  link.url ? (
                    <a href={ getAbsoluteUrl(link.url) } target='_blank' rel="noopener noreferrer">
                      { link.url }
                    </a>
                  ) : (
                    <span
                      className={ mainStyles.addItemLink }
                      onClick={ handleEditClick }
                    >
                      Add
                    </span>
                  )
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

Info.propTypes = {
  store: PropTypes.object,
  onEditClick: PropTypes.func
}

Info.defaultProps = {}

export default Info
