import { types } from '../actions/EgNotification'

export default (state = null, action) => {
  switch (action.type) {
    case types.SHOW:
      return action.notification

    // Clean the notification
    case types.HIDE:
      return null

    default:
      return state
  }
}
