import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const Input = React.forwardRef((props, ref) => {
  const { className, errorMessage, ...rest } = props
  const classes = classNames([{
    'eg-input': true
  }, className])

  return (
    <div className={ classes }>
      <input className='input' ref={ ref } { ...rest } />
      {errorMessage && <div className='error-msg'>{errorMessage}</div>}
    </div>
  )
})

Input.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  validations: PropTypes.object
}

export default Input
