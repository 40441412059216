import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import shortid from 'shortid'

import { actions as campaignActions } from '../../../../actions/Campaign'
import { actions as journeyActions } from '../../../../actions/Journey'
import { Button } from '../../../FormComponents'
import { types as buttonTypes } from '../../../FormComponents/Button'
import PhonePreview, { HeaderSMS } from '../../../PhonePreview'
import TestMessage from '../../../Campaigns/TestMessage'
import { htmlize } from '../../../../utils/strings'
import { parsePreview } from '../../../../services/campaigns'
import { zsfSmsFooter, getMessageShortlink } from '../../../../services/journeys'
import EGAnalytic, { analyticEvents } from '../../../../services/analytics'
import UrlShortnerModal from '../../../Modals/UrlShortnerModal'
import { fields as setupFields } from '../Setup'
import {
  TriggerNode,
  SMSNode,
  AddMessageNode
} from './MessageNodes'
import './styles.scss'

const createNode = () => {
  return {
    id: shortid.generate(),
    mms: { image: null },
    autoTrigger: false,
    formIsValid: false
  }
}

/**
 * Gets time from array object, if it doesn't exist then it replaces it with -1
 * @param {*} messageArray
 * @returns [ 0, 120, 180, -1]
 */
const getMessageDelays = (messageArray) => {
  return messageArray.map((message) => {
    if (message.time || message.time === 0) {
      return message.time
    }
    return -1
  })
}

/**
 * DesignJourney - Step 2 of create SMS journey flow
 *
 * @component
 * @example
 *
 * const handleSubmit = (step, data) => { ... }
 * const data = {
 *  journey_name: 'My new journey'
 * }
 *
 * return (
 *  <DesignJourney data={ data } onSubmit={ handleSubmit } />
 * )
*/
const DesignJourney = props => {
  const {
    data,
    submitting,
    onBackClick,
    onSubmit
  } = props
  const [ displayTestSmsModal, setDisplayTestSmsModal ] = useState(null)
  const [ msgToShortlink, setMsgToShortlink ] = useState(null)
  const [ testMsgSubmitted, setTestMsgSubmitted ] = useState(false)
  const { submittingTestMsg } = useSelector(state => state.Campaign)
  const shortlinkState = useSelector(state => state.Journey.shortLink)
  const [ messagesData, setMessagesData ] = useState(!_.isEmpty(data.journey_messages) ? data.journey_messages : [createNode()])
  const chain = useSelector(state => state.User.chain)
  const dispatch = useDispatch()
  const alreadyInitValidated = useRef(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // When the component mounts, if there were already messages
    // (e.g. comming back from step 3), then we should revalidate
    // those messages to avoid unwanted behaviour (e.g. after changing
    // a field, the submit button changes to disabled when it should remain
    // enabled).
    if (!alreadyInitValidated.current) {
      const msgs = [...messagesData]
      if (msgs[0].time || msgs[0].time === 0) {
        msgs.forEach((m) => {
          m.autoTrigger = true
        })
        setMessagesData(msgs)
      }
      alreadyInitValidated.current = true
    }
  }, [messagesData])

  useEffect(() => {
    if (testMsgSubmitted && !submittingTestMsg) {
      EGAnalytic.track(analyticEvents.TEST_MESSAGE_SENT, {
        ...EGAnalytic.mapChain(chain),
        messageContext: 'Journeys',
        messageContextStep: 'Design',
      })
      setDisplayTestSmsModal(null)
    }
  }, [chain, submittingTestMsg, testMsgSubmitted])

  useEffect(() => {
    if (shortlinkState.success) {
      dispatch(journeyActions.clearShortlinkState())
      setMsgToShortlink(null)
      if (shortlinkState.isNew) {
        const updatedMessage = _.get(shortlinkState, 'data.message')
        const messages = messagesData.map((m) => {
          if (m.id === updatedMessage.id) {
            return updatedMessage
          }
          return m
        })
        setMessagesData(messages)
      }
    }
  }, [shortlinkState.success, shortlinkState.isNew, shortlinkState, messagesData, dispatch])

  const formIsValid = () => {
    const validMessages = messagesData.filter(m => m.formIsValid)
    return validMessages.length === messagesData.length
  }

  const handleTestMessageClick = (msg) => {
    EGAnalytic.track(analyticEvents.SEND_TEST_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      messageContext: 'Journeys',
      messageContextStep: 'Design',
    })
    setDisplayTestSmsModal(msg)
  }

  const handleTestMessageClose = () => {
    setDisplayTestSmsModal(null)
  }

  const handleTestMessageSubmit = (numbers, message) => {
    dispatch(campaignActions.sendTestMessage({
      numbers,
      message: message.text,
      imageUrl: _.get(message, 'mms.imageUrl')
    }))
    setTestMsgSubmitted(true)
  }

  const handleBackClick = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_ABANDONED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Design',
      stepAbandonType: 'EDIT TRIGGER'
    })
    if (onBackClick) onBackClick()
  }

  const handleSmsNodeChange = (msg) => {
    const messages = messagesData.map(m => {
      return m.id === msg.id ? msg : m
    })
    setMessagesData(messages)
  }

  const handleAddMsgClick = () => {
    const newMessageDataArray = [
      ...messagesData,
      createNode()
    ]
    setMessagesData(newMessageDataArray)
    EGAnalytic.track(analyticEvents.JOURNEY_ADD_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      messageCount: newMessageDataArray.length,
      messageDelays: getMessageDelays(newMessageDataArray)
    })
  }

  const handleNodeRemove = msg => {
    const messages = messagesData.filter(m => m.id !== msg.id)
    setMessagesData(messages)
    EGAnalytic.track(analyticEvents.JOURNEY_REMOVE_MESSAGE_INTERACTION, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      messageCount: messages.length,
      messageDelays: getMessageDelays(messages)
    })
  }

  const handleShortlinkClick = msg => {
    setMsgToShortlink(msg)
  }

  const handleUrlSubmit = ({ url }) => {
    const shortlink = getMessageShortlink(data, msgToShortlink)
    if (shortlink) {
      dispatch(journeyActions.updateShortLink({
        journeyId: data.id,
        message: msgToShortlink,
        shortlink,
        url
      }))
    } else {
      dispatch(journeyActions.createShortLink({
        journeyId: data.id,
        message: msgToShortlink,
        url
      }))
    }
  }

  const checkFields = () => {
    if (!formIsValid()) {
      setMessagesData(messagesData.map(node => {
        return {
          ...node,
          autoTrigger: true
        }
      }))
    }
  }

  const handleSubmit = () => {
    EGAnalytic.track(analyticEvents.JOURNEY_STEP_COMPLETED, {
      ...EGAnalytic.mapChain(chain),
      journeyName: data[setupFields.NAME],
      triggerName: data[setupFields.TRIGGER].label,
      triggerId: data[setupFields.TRIGGER_ID],
      stepName: 'Design',
      messageCount: messagesData.length,
      messageDelays: getMessageDelays(messagesData)
    })
    if (onSubmit) {
      onSubmit('design', { journey_messages: messagesData })
      alreadyInitValidated.current = false
    }
  }

  return (
    <div className='eg-create-journey-design'>
      <div className='step-body'>
        <div className='panel inputs'>
          <div className='title'>Journey Message</div>
          <div className='subtitle'>Compose your message below.</div>
          <div className='msg-wrapper'>
            <TriggerNode trigger={data.journey_trigger} onEditTriggerClick={handleBackClick} />
            {
              messagesData.map((msg, i) => (
                <SMSNode
                  key={ `msg-${msg.id}` }
                  index={i}
                  journey={ data }
                  message={ msg }
                  removable={ i > 0 }
                  autoTrigger={ msg.autoTrigger }
                  onChange={ handleSmsNodeChange }
                  onRemove={ handleNodeRemove }
                  onShortlinkClick={ handleShortlinkClick }
                />
              ))
            }
            <AddMessageNode onAddClick={handleAddMsgClick} />
          </div>
          <div className='actions'>
            <Button
              className='action-button'
              text='BACK'
              type={buttonTypes.SECONDARY}
              onClick={handleBackClick}
            />
            <div onClick={checkFields}>
              <Button
                className='action-button submit'
                text='CONTINUE'
                loading={submitting}
                disabled={!formIsValid()}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className='panel preview'>
          <PhonePreview className='phone' scale='0.6'>
            <HeaderSMS title='' />
            <div className='preview-body'>
              {
                messagesData.map((message, i) => {
                  const msg = { ...message }
                  if (msg.include_reply_footer) {
                    msg.text = `${msg.text}\n${zsfSmsFooter()}`
                  }

                  return (
                    <div key={`msg-prev-${i}`}>
                      {
                        !!msg.time && !!msg.unit && (
                          <div className='msg-time-label'>
                            after { msg.time} { msg.time > 1 ? msg.unit.label : msg.unit.label.replace(/s$/, '')}
                          </div>
                        )
                      }
                      {
                        !!_.get(msg, 'mms.imageUrl') && (
                          <div className='message-wrapper-img' data-testid='sms-preview-message-wrapper'>
                            <img src={ msg.mms.imageUrl } alt='mms' width={ 135 } />
                          </div>
                        )
                      }
                      <div className='message-wrapper' data-testid='sms-preview-message-wrapper'>
                        <p dangerouslySetInnerHTML={
                          { __html: htmlize(parsePreview(msg.text, chain) || '<SMS message here>') }
                        } />
                        <Button
                          className='preview-test-btn'
                          text='SEND TEST MESSAGE'
                          type={buttonTypes.SECONDARY}
                          onClick={() => handleTestMessageClick(msg)}
                          disabled={ msg.uploading }
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </PhonePreview>
        </div>
      </div>
      {
        !!displayTestSmsModal && (
          <TestMessage
            data={data}
            step='Design'
            flowName='Journeys'
            message={displayTestSmsModal}
            submitting={submittingTestMsg}
            onClose={handleTestMessageClose}
            onSubmit={handleTestMessageSubmit}
          />
        )
      }
      {
        !!msgToShortlink && (
          <UrlShortnerModal
            url={ _.get(getMessageShortlink(data, msgToShortlink), 'url') }
            onClose={ () => setMsgToShortlink(false) }
            onSubmit={ handleUrlSubmit }
            loading={ shortlinkState.submitting }
          />
        )
      }
    </div>
  )
}

DesignJourney.propTypes = {
  data: PropTypes.object,
  submitting: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default DesignJourney
