import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'


import { Button, Input } from '../../../FormComponents'
import { renderInput } from '../../../FormComponents/utils'
import LocalSpinner from '../../../LocalSpinner'
import { actions as oauthActions } from '../../../../actions/Oauth'
import useValidator from '../../../../hooks/validator'
import './styles.scss'


export const fields = {
  USER_NAME: 'user_name',
  ACCESS_KEY: 'access_key',
  SECRET_KEY: 'secret_key'
}

const ToastConnect = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)
  const dispatch = useDispatch()
  const chain = useSelector(state => state.User.chain)
  const toast = useSelector(state => state.OauthConnect.toast)
  const validator = useValidator({
    mode: 'all',
  })

  const handleSubmitClick = () => {
    dispatch(oauthActions.sendToastCredIntent({
      chainUuid: chain.uuid,
      ...validator.getValues()
    }))
  }

  const handleCloseButton = () => {
    // Closing stateless modal
    if(props.onAuthorize) {
      props.onAuthorize()
    }
  }

  const isToastConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  const isConnected = isToastConnected('toast') || toast.data

  if(isConnected) {
    return (
      <div className='eg-toast-connect eg-toast-success'>
        <div className='logo' />
        <div className='info-title'>
          Thank you for connecting your Toast account
        </div>
        <div className='success-description'>
          The ZSF team is now verifying your Toast account. This may take up to 24 hours. We will notify you once integration has been completed.
          For immediate concerns you may reach us at <a href="tel:650-539-6310"> 650-539-6310 </a>.
        </div>
        <Button
          id='toast-gotit'
          className='connect-btn'
          text='GOT IT'
          onClick={ handleCloseButton }
        />
      </div>
    )
  }

  return (
    <div className='eg-toast-connect'>
      <div className='leftpart'>
        <div className='logo' />
        <div className='info-title' data-testid='toast-title'>
          Connect your Toast account
          {/* <div className='beta'>Beta</div> */}
        </div>
        <div className='info'>
          <div className='field'>
            <label className='label' htmlFor={ fields.USER_NAME }>USERNAME</label>
            {
              renderInput(fields.USER_NAME, validator, { required: true }, 'Username')
            }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.ACCESS_KEY }>ACCESS KEY</label>
            {
              renderInput(fields.ACCESS_KEY, validator, { required: true }, 'ABZCNCHDNCMDFFDSSSDLDLD')
            }
          </div>
          <div className='field'>
            <label className='label' htmlFor={ fields.SECRET_KEY }>SECRET KEY</label>
            <Input
              id={ fields.SECRET_KEY }
              name={ fields.SECRET_KEY }
              className={ validator.errors[fields.SECRET_KEY] ? 'error' : '' }
              ref={ validator.register({required: true }) }
              placeholder='***************************************'
              type='password'
            />
            { validator.errors[fields.SECRET_KEY] && <div className='error-msg'>{ validator.errors[fields.SECRET_KEY].message }</div> }
          </div>
          { toast.sending
            ? <LocalSpinner className='loader'/>
            : (
              <Button
                id='toast-connect'
                className='connect-btn'
                text='Connect'
                disabled={!validator.formState.isValid}
                onClick={ handleSubmitClick }
              />
            )
          }
        </div>
      </div>
      <div className='rightpart'>
        <div className='info-logo'>
          <div className='logo'/>
        </div>
        <div className='title'>
          Not sure where to get these credentials?
        </div>
        <div className='info-point'>
        You may need to
          <a href='mailto:integrations-support@toasttab.com?BCC=development@zerostorefront.com&Subject=Request%20to%20enable%20Data%20Exports%20and%20create%20a%20Data%20Export%20User&Body=Toast%20Customer%20Care%2C%0A%09I%20would%20like%20to%20enable%20data%20exports%20for%20my%20Toast%20account.%20Can%20you%20please%20help%20me%3F%20%20In%20addition%2C%20I%20will%20need%20to%20create%20a%20Data%20Export%20User%2C%20so%20that%20I%20can%20access%20my%20data.%20Please%20let%20me%20know%20if%20you%20need%20anything%20additional%20from%20me.%20Thank%20you.' target="_blank" rel="noopener noreferrer">
            email Toast
          </a>
        Customer Care to have them enable your access to data exports.
        </div>
        <div className='info-point'>
          More information on connecting to Toast can be found
          <a href='https://central.toasttab.com/s/article/Automated-Nightly-Data-Export-1492723819691' target="_blank" rel="noopener noreferrer">
            here
          </a>
        </div>
      </div>
    </div>
  )
}

ToastConnect.propTypes = {
  isConnected: PropTypes.bool,
  onAuthorize: PropTypes.func.isRequired,
}

export default ToastConnect
