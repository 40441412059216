export const links = [
  {
    label: 'Terms of Service',
    url: 'https://restaurant.eatgeek.com/terms-of-service'
  }, {
    label: 'Privacy Policy',
    url: 'https://restaurant.eatgeek.com/privacy-policy'
  }, {
    label: 'Security',
    url: 'https://restaurant.eatgeek.com/security'
  }
]
