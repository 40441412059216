import { call, takeLatest, takeEvery, put, select } from 'redux-saga/effects'
import _ from 'lodash'

import { authorizedRequest } from './Auth'
import { types, actions } from '../actions/Dashboard'
import { getChainInfo, getCardData } from '../api/Dashboard'
import { actions as EgNotificationActions } from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

/**
 *
 * Get chain info i.e phone number, google tags, qr code and sign up url
 *
 */
function* getChainInfoHandler() {
  try {
    const chainUuid = yield select(state => _.get(state, 'User.chain.uuid'))
    const res = yield call(authorizedRequest, getChainInfo, {
      chainUuid,
    })
    if (res && res.data) {
      yield put(actions.getChainInfoResolved(res.data))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Unknown error'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    yield put(actions.getChainInfoRejected(error))
  }
}

/**
 * Get card info. i.e subscriber card, campaign card, journey card
 *
 * @param {object} data
 * @param {string} data.type type which card information
 * we are getting. i.e Campaign card, Journey card or Subscription card.
 */
function* getCardInfoHandler({ data }) {
  const { type } = data
  try {
    const chainUuid = yield select(state => _.get(state, 'User.chain.uuid'))
    const res = yield call(authorizedRequest, getCardData, {
      ...data,
      chainUuid,
    })
    if (res) {
      const mappedData = {
        [type]:{
          data: res.data || {},
          loading: false,
          error: null,
        },
      }
      yield put(actions.getCardInfoResolved(mappedData))
    } else {
      throw new Error(res)
    }
  } catch (error) {
    const notification = {
      type: EgNotificationTypes.ERROR,
      message: error.message || 'Unknown error'
    }
    yield put(EgNotificationActions.ShowNotification(notification))
    const mappedError = {
      [type]:{
        data: null,
        loading: false,
        error: error,
      },
    }
    yield put(actions.getCardInfoRejected(mappedError))
  }
}

export default function* () {
  yield takeLatest(types.DASHBOARD_GET_CHAIN_INFO, getChainInfoHandler)
  yield takeEvery(types.DASHBOARD_GET_CARD_INFO, getCardInfoHandler)
}
