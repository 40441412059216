import React from 'react'
import PropTypes from 'prop-types'

import StoreLogo from '../../../StoreLogo'
import Checkbox from '../../../../FormComponents/Checkbox'
import './styles.scss'

const StoreOption = (props) => {
  const { store = {}, onClick, checked, index } = props
  const storeAddress =  store.address  || ''

  const handleCheckboxClick = () => {
    onClick(store)
  }

  return (
    <div className='eg-store-option' onClick={handleCheckboxClick}>
      <StoreLogo index={index} className='logo'/>
      <div className='detail'>
        <div className='name'>{store.name}</div>
        <div className='address'>{storeAddress}</div>
      </div>
      <Checkbox checked={checked} className='checkbox'/>
    </div>
  )
}

StoreOption.propTypes = {
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default StoreOption 
