export const types = {
  GMAIL_CONNECT_INTENT: 'GMAIL_CONNECT_INTENT',
  GMAIL_CONNECT_INTENT_RESOLVED: 'GMAIL_CONNECT_INTENT_RESOLVED',
  GMAIL_CONNECT_INTENT_REJECTED: 'GMAIL_CONNECT_INTENT_REJECTED',

  CALENDAR_CONNECT_INTENT: 'CALENDAR_CONNECT_INTENT',
  CALENDAR_CONNECT_INTENT_RESOLVED: 'CALENDAR_CONNECT_INTENT_RESOLVED',
  CALENDAR_CONNECT_INTENT_REJECTED: 'CALENDAR_CONNECT_INTENT_REJECTED'
}

export const actions = {
  gmailConnect(opts) {
    return {
      type: types.GMAIL_CONNECT_INTENT,
      opts
    }
  },

  gmailConnectResolved(authCode) {
    return {
      type: types.GMAIL_CONNECT_INTENT_RESOLVED,
      authCode
    }
  },

  gmailConnectRejected(error) {
    return {
      type: types.GMAIL_CONNECT_INTENT_REJECTED,
      error
    }
  },

  calendarConnect(opts) {
    return {
      type: types.CALENDAR_CONNECT_INTENT,
      opts
    }
  },

  calendarConnectResolved(authCode) {
    return {
      type: types.CALENDAR_CONNECT_INTENT_RESOLVED,
      authCode
    }
  },

  calendarConnectRejected(error) {
    return {
      type: types.CALENDAR_CONNECT_INTENT_REJECTED,
      error
    }
  }
}
