import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { toCurrency } from '../../../../utils/number'

import './styles.scss'

const Stat = (props) => {
  const { className = '', data = {} } = props
  const totalDelivery = _.get(data, 'statistic.deliver_count')
  const totalClicks = _.get(data, 'statistic.customer_click_count')
  const revenue = _.get(data, 'statistic.revenue.total_spent')
  const optOuts = _.get(data, 'statistic.opt_outs', '-')
  const grossProfit = _.get(data, 'statistic.revenue.gross_profit')
  const clickPercentage = totalDelivery && totalClicks ? totalClicks/totalDelivery : null
  return (
    <div className={`eg-campaign-stat ${className}`}>
      <div className='stat-item'>
        <div className='stat-label'>
            Delivered
        </div>
        <div className='stat-value'>
          {totalDelivery}
        </div>
        {/* {data.delivered.percentage &&
              <div className='stat-percentage'>
                {item.percentage && item.percentage.toFixed(2)}%
              </div>
          } */}
      </div>

      <div className='stat-item'>
        <div className='stat-label'>
            Clicks
        </div>
        <div className='stat-value'>
          {totalClicks}
        </div>
        {clickPercentage &&
        <div className='stat-percentage'>
          {(clickPercentage * 100).toFixed(2) } %
        </div>
        }
      </div>

      <div className='stat-item'>
        <div className='stat-label'>
          Unsubscribes
        </div>
        <div className='stat-value'>
          { optOuts }
        </div>
      </div>

      <div className='stat-item'>
        <div className='stat-label'>
          Revenue
        </div>
        <div className='stat-value'>
          { toCurrency(revenue) }
        </div>
      </div>

      <div className='stat-item'>
        <div className='stat-label'>
          Gross Profit
        </div>
        <div className='stat-value'>
          { toCurrency(grossProfit) || '-' }
        </div>
      </div>


      {/* <div className='stat-item'>
        <div className='stat-label'>
            UNSUBSCRIBED
        </div>
        <div className='stat-value'>
          {data.click_count}
        </div>
        {data.delivered.percentage &&
              <div className='stat-percentage'>
                {item.percentage && item.percentage.toFixed(2)}%
              </div>
        }
      </div> */}

    </div>
  )
}

Stat.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
}

export default Stat
