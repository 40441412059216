import _ from 'lodash'

const AUTH_KEY = '__AUTH__'

// User related constants
export const userRoles = {
  SUPER_ADMIN: 'super_admin', // seems we don't need this one
  CHAIN_READ: 'chain/read',
  RESTAURANT_ADMIN: 'admin',
  REVIEW_MANAGER: 'review/read',
  STORE_READ: 'restaurant/read',
  STORE_WRITE: 'restaurant/write',
  SQUARE_READ: 'square/read',
  SQUARE_WRITE: 'square/write',
  INTEGRATION_READ: 'integration/read',
  INTEGRATION_WRITE: 'integration/write',
  AUTOMATION_READ: 'automation/read',
  TWILIO_READ: 'twilio/read',
  TWILIO_WRITE: 'twilio/write',
  CUSTOMER_READ: 'customer/read',
  CONNECTIONS_READ: 'connections/read',
  CONNECTIONS_WRITE: 'connections/write',
  SMS_CAMPAIGNS_READ: 'sms_campaign/read',
  SMS_CAMPAIGNS_WRITE: 'sms_campaign/write',
  SMS_JOURNEYS_READ: 'journey/read',
  SMS_JOURNEYS_WRITE: 'journey/write',
  SEGMENT_READ: 'segment/read',
  SEGMENT_WRITE: 'segment/write',
  CHANNEL_READ: 'channel/read',
  REPORT_READ: 'report/read',

  // this is the default role, as at first all authenticated users
  // should be able to access the first screen
  // after selecting the chain, roles will be updated
  CHAIN_LIST: 'chain_list'
}

// define default paths for each role


// all new providers will be added here
export const oauthProviders = {
  SHOPIFY: 'shopify',
  FACEBOOK: 'facebook',
  CONSTANT_CONTACT: 'constant_contact',
  CLOVER: 'clover',
  MAILCHIMP: 'mailchimp',
  GOOGLE_BUSINESS: 'google_business',
  SQUARE: 'square',
  ATTENTIVE: 'attentive'
}

export const userHasAccess = (user, roles) => {
  if (!user || !user.roles) {
    return false
  }
  // if user is superadmin, go ahead ;-)
  if (user.isAdmin) {
    return true
  }

  let hasAccess = false
  roles.forEach(role => {
    if (user.roles.includes(role)) {
      hasAccess = true
    }
  })
  return hasAccess
}

/**
 * map user data fetched from cognito service
 *
 * @param {object} data - user data to be mapped
 */
export const mapCognitoUserData = data => {
  const user = data.user
  let tokenPayload
  if (data.tokens && data.tokens.idToken) {
    tokenPayload = data.tokens.idToken.payload
  }
  else if (data.idToken) {
    tokenPayload = data.idToken.payload
  }
  else {
    throw new Error('Token information not found')
  }
  let attrs = {}
  if (user && user.UserAttributes) {
    user.UserAttributes.forEach(attr => {
      attrs[attr.Name] = attr.Value
    })

    if (tokenPayload['cognito:groups'] && tokenPayload['cognito:groups'].length) {
      attrs.roles = tokenPayload['cognito:groups']
    }
    else {
      attrs.roles = [userRoles.CHAIN_LIST]
    }
    attrs.isAdmin = attrs.roles.includes('admin')
  }
  else {
    attrs = user
  }

  const tokens = data.tokens || data

  return {
    user: attrs,
    tokens: {
      idToken: tokens.idToken,
      refreshToken: tokens.refreshToken,
      accessToken: tokens.accessToken
    },
    authorizationData: data.authorizationData,
    hasMultipleChains: data.hasMultipleChains || false
  }
}

/**
 * Set the user roles, based on a selected chain, checking first
 * the scopes for the chain itself. Then, if the user has only
 * permissions for specific restaurants fallback to them.
 *
 * @param {object} userState - current user state
 * @param {object} chain - selected chain on which we check the scopes
 *
 * @returns updated user state with expected roles
*/
export const setUserRoles = (userState, chain) => {
  const storedData = JSON.parse(localStorage.getItem(AUTH_KEY))
  const authorizationData = userState.authorizationData

  if (_.get(userState, 'user.isAdmin')) {
    return userState
  }

  // check user permissions at chain level
  if (authorizationData.chains && authorizationData.chains.length) {
    const allowedChain = authorizationData.chains.find(c => c.uuid === chain.uuid)
    if (allowedChain) {
      storedData.user.roles = allowedChain.scopes
      localStorage.setItem(AUTH_KEY, JSON.stringify(storedData))
      return {
        ...userState,
        user: {
          ...userState.user,
          roles: allowedChain.scopes
        }
      }
    }
  }

  // check user permissions at restaurant level
  if (authorizationData.restaurants && authorizationData.restaurants.length) {
    const chainRestaurants = authorizationData.restaurants.filter(r => r.chain_uuid === chain.uuid)
    if (!chainRestaurants.length) {
      return userState
    }
    // for the restaurants found, first try with some which has the admin scope
    // so we prioritize the less restrictive scope
    let selectedRestaurant = chainRestaurants.find(r => {
      return r.scopes.includes(userRoles.RESTAURANT_ADMIN)
    })

    // if no admin scopes found, fallback to the first one
    if (!selectedRestaurant) {
      selectedRestaurant = chainRestaurants[0]
    }
    storedData.user.roles = selectedRestaurant.scopes
    localStorage.setItem(AUTH_KEY, JSON.stringify(storedData))
    return {
      ...userState,
      user: {
        ...userState.user,
        roles: selectedRestaurant.scopes
      }
    }
  }

  return userState
}

export const storePlanToken = (tokenData) => {
  const storedData = JSON.parse(localStorage.getItem(AUTH_KEY))
  storedData.user.plan = { tokenData }
  localStorage.setItem(AUTH_KEY, JSON.stringify(storedData))
}
