import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import { getNumberWithCommas } from '../../utils/number'

const PercentBar = props => {
  return (
    <div className={ `${styles.container} ${props.className}` }>
      <div className={ `${styles.coloredBar} ${props.barClassName}` } style={{ width: `${props.value}%` }} />
      {
        props.displayValues ? (
          <>
            <div className={ styles.fixedValue }>
              { getNumberWithCommas(props.quantity) }
            </div>
            <div className={ styles.percentageValue }>
              ({ props.value }%)
            </div>
          </>
        ) : null
      }
    </div>
  )
}

PercentBar.propTypes = {
  className: PropTypes.string,
  barClassName: PropTypes.string,
  //barClassName to handle color inside progress bar
  value: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  displayValues: PropTypes.bool
}

PercentBar.defaultProps = {
  className: '',
  barClassName: '',
  displayValues: true
}

export default PercentBar
