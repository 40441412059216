import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects'
import { types, actions } from '../actions/Journey'
import { authorizedRequest } from './Auth'
import { NetworkError } from '../services/error'
import { mapJourneys, mapJourneyIn } from '../services/journeys'
import {
  getJourneyList,
  getJourney,
  upsertJourney,
  setJourneyState,
  createShortLink,
  updateShortLink
} from '../api/Journeys'
import {actions as EgNotificationActions} from '../actions/EgNotification'
import { types as EgNotificationTypes } from '../components/EgNotification/EgNotification'

function* getJourneyListHandler(opts) {
  const { page = 1, size = 10, status, trigger } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getJourneyList, {
      page,
      size,
      status,
      trigger,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedJourneyList = { ...mapJourneys(res.data, chain), page, size, status, trigger}
      yield put(actions.getJourneysResolved(mappedJourneyList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getJourneysRejected(error))
  }
}


function* getPopupJourneyListHandler(opts) {
  const { page = 1, size = 10, status, trigger } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getJourneyList, {
      page,
      size,
      status,
      trigger,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedJourneyList = { ...mapJourneys(res.data, chain), page, size, status, trigger}
      yield put(actions.getPopupJourneysResolved(mappedJourneyList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getPopupJourneysRejected(error))
  }
}


function* getKeywordJourneyListHandler(opts) {
  const { page = 1, size = 10, status, trigger } = opts.data
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getJourneyList, {
      page,
      size,
      status,
      trigger,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      const mappedJourneyList = { ...mapJourneys(res.data, chain), page, size, status, trigger}
      yield put(actions.getKeywordJourneysResolved(mappedJourneyList))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getKeywordJourneysRejected(error))
  }
}

function* getJourneyHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, getJourney, {
      id: opts.id,
      chainUuid: chain.restaurant_chain_uuid
    })
    if (res && res.data) {
      yield put(actions.getJourneyResolved(mapJourneyIn(res.data)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    yield put(actions.getJourneyRejected(error))
  }
}

function* upsertJourneyHandler(opts) {
  const chain = yield select(state => state.User.chain)
  let journeyId = opts.data.id
  if (!journeyId) {
    journeyId = yield select(state => state.Journey.create.journeyId)
  }
  try {
    const res = yield call(authorizedRequest, upsertJourney, {
      chainUuid: chain.restaurant_chain_uuid,
      journeyId,
      opts
    })
    if (res && res.data) {
      yield put(actions.upsertJourneyResolved(mapJourneyIn(res.data)))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.upsertJourneyRejected(error))
  }
}

function* setJourneyStateHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, setJourneyState, {
      chainUuid: chain.restaurant_chain_uuid,
      opts
    })
    if (res && res.data) {
      yield put(actions.setJourneyStateResolved(res.data))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.setJourneyStateRejected(error))
  }
}

function* createShortLinkHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, createShortLink, {
      chainUuid: chain.restaurant_chain_uuid,
      opts
    })
    if (res && res.data) {
      yield put(actions.createShortLinkResolved({
        ...res.data,
        message: opts.data.message
      }))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.createShortLinkRejected(error))
  }
}

function* updateShortLinkHandler(opts) {
  const chain = yield select(state => state.User.chain)
  try {
    const res = yield call(authorizedRequest, updateShortLink, {
      chainUuid: chain.restaurant_chain_uuid,
      opts
    })
    if (res && res.data) {
      yield put(actions.updateShortLinkResolved({
        ...res.data,
        message: opts.data.message
      }))
    } else {
      throw new NetworkError(res)
    }
  } catch (error) {
    const errorMessage = (error && error.message) || 'Something went wrong'
    yield put(EgNotificationActions.ShowNotification({
      type: EgNotificationTypes.ERROR,
      message: `Error: ${errorMessage}`
    }))
    yield put(actions.updateShortLinkRejected(error))
  }
}

export default function* () {
  yield takeLatest(types.GET_JOURNEYS_INTENT, getJourneyListHandler)
  yield takeLatest(types.GET_POPUP_JOURNEYS_INTENT, getPopupJourneyListHandler)
  yield takeLatest(types.GET_KEYWORD_JOURNEYS_INTENT, getKeywordJourneyListHandler)
  yield takeLatest(types.GET_JOURNEY_INTENT, getJourneyHandler)
  yield takeLatest(types.UPSERT_JOURNEY_INTENT, upsertJourneyHandler)
  yield takeLatest(types.SET_JOURNEY_STATE_INTENT, setJourneyStateHandler)
  yield takeLatest(types.JOURNEY_SHORTLINK_CREATE_INTENT, createShortLinkHandler)
  yield takeLatest(types.JOURNEY_SHORTLINK_UPDATE_INTENT, updateShortLinkHandler)
}
