import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { cardTypes } from '../../../services/dashborad'
import LocalSpinner from '../../../components/LocalSpinner'
import { channelEmailProviders } from '../../../services/channels'
import '../styles.scss'

RevenueCard.propTypes = {
  onTimePeriodChange: PropTypes.func.isRequired,
  selectedDates: PropTypes.object
}

function RevenueCard({ onTimePeriodChange, selectedDates }) {
  const ChannelsState = useSelector(({ Channel }) => Channel)

  const messagingData = _.get(ChannelsState, 'channelList.channels', []).filter(c => c.source === 'text')[0]
  const emailData = _.get(ChannelsState, 'channelList.channels', []).filter(c => channelEmailProviders.includes(c.source))[0]

  useEffect(() => {
    onTimePeriodChange({ dates: selectedDates, type: cardTypes.CAMPAIGN })
  }, [onTimePeriodChange, selectedDates])


  if (ChannelsState.loadingChannels) {
    return (
      <div className='eg-dashboard-card'>
        <LocalSpinner />
      </div>
    )
  }

  return (
    <div className='eg-dashboard-card'>
      <div className='title'>
        ROI
      </div>
      <div className='value' data-testid='roi-total-value'>
        { _.get(ChannelsState, 'channelList.total.roi') ? `${_.get(ChannelsState, 'channelList.total.roi')}x` : '-' }
      </div>
      <div className='flex-hz'>
        <div>
          <div className='total-label'>Messaging ROI</div>
          <div className='total' data-testid='roi-msg-value'>
            { _.get(messagingData, 'roi') ? `${messagingData.roi}x` : '-' }
          </div>
        </div>
        <div>
          <div className='total-label'>Email ROI</div>
          <div className='total' data-testid='roi-email-value'>
            { _.get(emailData, 'roi') ? `${emailData.roi}x` : '-' }
          </div>
        </div>
      </div>
    </div>
  )
}

export default RevenueCard
