import { types } from '../actions/Channel'

export const initialState = {
  overview: {
    loading: false,
    data: null,
    error: null,
  },
  channelList: {
    total: {
      orders: 0,
      customers: 0,
      spend: 0,
      revenue: 0,
      roi: 0
    },
    channels: []
  },
  loadingChannels: true,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHANNELS_INTENT:
      return {
        ...state,
        error: null,
        loadingChannels: true
      }

    case types.GET_CHANNELS_INTENT_RESOLVED:
      return {
        ...state,
        channelList: action.data,
        loadingChannels: false
      }

    case types.GET_CHANNELS_INTENT_REJECTED:
      return {
        ...state,
        loadingChannels: false,
        error: action.error || null
      }

    case types.GET_CHANNELS_OVERVIEW_INTENT:
      return {
        ...state,
        overview: {
          loading: true,
          data: null,
          error: null,
        },
      }

    case types.GET_CHANNELS_OVERVIEW_INTENT_RESOLVED:
      return {
        ...state,
        overview: {
          loading: false,
          data: action.data,
          error: null,
        },
      }

    case types.GET_CHANNELS_OVERVIEW_INTENT_REJECTED:
      return {
        ...state,
        overview: {
          loading: false,
          data: null,
          error: action.error,
        },
      }

    default:
      return state
  }
}
