import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import IntegrationCard from '../../Store/StoreIntegration/IntegrationCard'
import AttentiveConnect from './AttentiveConnect'

import './styles.scss'

const AttentiveIntegration = props => {
  const connections = useSelector(state => state.OauthConnect.activeConnections)


  const handleClick = () => {
    if (props.onClick) {
      props.onClick(AttentiveConnect)
    }
  }

  const isConnected = provider => {
    if (!connections || !connections.length) {
      return false
    }
    return !!connections.find(c => c.provider === provider)
  }

  return (
    <div className='eg-attentive-integration'>
      <IntegrationCard
        title='Attentive'
        subtitle='Connect subscribers and campaigns'
        logoClassName='attentive'
        isConnected={ isConnected('attentive') }
        onClick={ handleClick }
      />
    </div>
  )
}

AttentiveIntegration.propTypes = {
  onClick: PropTypes.func
}

export default AttentiveIntegration
