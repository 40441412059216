import React from 'react'
import PropTypes from 'prop-types'

import TextField from '../TextField'
import styles from './index.module.scss'

const PhoneInput = props => {
  const handleChange = (val) => {
    if (props.onChange) {
      props.onChange(val)
    }
  }

  return (
    <div className={styles.container}>
      <TextField
        { ...props }
        onChange={ handleChange }
      />
    </div>
  )
}

PhoneInput.propTypes = {
  onChange: PropTypes.func
}

export default PhoneInput
