import React, { useState, useEffect } from 'react'
import PropType from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'

import AutomationHeader from '../AutomationHeader'
import AutomationCard from '../AutomationCard'
import AutomationSms from './AutomationSms'
import RatingPage from './RatingPage'
import ThankYou from './ThankYou'
import useValidator from '../../../../hooks/validator'
import { actions as ivrActions } from '../../../../actions/LoyaltyIvr'
import { actions as storeActions } from '../../../../actions/Store'
import { fields as smsFields } from './AutomationSms/'
import { fields as feedbackFields } from './RatingPage'
import { fields as thankyouPageFields } from './ThankYou'

import './styles.scss'

const tabItems = [
  { key: 'sms', label: 'SMS' },
  { key: 'rating_page', label: 'Rating page' },
  { key:'thank_you_page', label: 'THANK YOU PAGE' }
]


const SmsRatingSettings = (props) => {
  const { store } = props
  const storeUuid = store.uuid
  const storeName = get(store, 'storeInfo.name')
  const validator = useValidator({
    mode: 'all',
    defaultValues: {
      [smsFields.SENDER_NAME]: get(store, 'rating.sender_name') || '',
      [smsFields.SMS_TEXT]: get(store, 'rating.message') || `Thank you for visiting ${storeName} today! If you have a minute, we’d love to hear about your overall experience.`,
      [smsFields.RATING_LINK]: get(store, 'rating.url'),
      [feedbackFields.HEADLINE]: get(store, 'rating.rating_page.headline') || 'How was your visit?',
      [feedbackFields.SUBTEXT]: get(store, 'rating.rating_page.subtext') || 'Thank you for visiting today! If you have a minute, we’d love to hear about your overall experience so we can make your next visit even better.',
      [thankyouPageFields.POSITIVE_HEADLINE]: get(store, 'rating.thanks_page.positive.headline') || 'Thank you for your feedbacK!',
      [thankyouPageFields.POSITIVE_SUBTEXT]: get(store, 'rating.thanks_page.positive.subtext') || 'We’re glad you had an amazing experience with us. Don’t forget to leave us a review.' ,
      [thankyouPageFields.GOOGLE_LINK]: get(store, 'rating.thanks_page.positive.review_buttons.google'),
      [thankyouPageFields.YELP_LINK]: get(store, 'rating.thanks_page.positive.review_buttons.yelp'),
      [thankyouPageFields.FACEBOOK_LINK]: get(store, 'rating.thanks_page.positive.review_buttons.facebook'),
      [thankyouPageFields.NEGATIVE_HEADLINE]: get(store, 'rating.thanks_page.negative.headline') || 'Your feedback helps us get better. Can you tell us how we can improve your experience',
      [thankyouPageFields.NEGATIVE_SUBTEXT]: get(store, 'rating.thanks_page.negative.placeholder') || 'Tell us what we can improve' 
    }
  })
  const dispatch = useDispatch()
  const saved = useSelector(state => state.LoyaltyIvr.rating.saved)
  const [ selectedTab, setSelectedTab ] = useState(tabItems[0])

  useEffect(() => {
    if (saved) {
      window.location.hash = '#automations'
      dispatch(ivrActions.resetSaved())
      dispatch(storeActions.getStore({ restaurantUuid: storeUuid }))
    }
  }, [saved, dispatch, storeUuid])

  const handleTabChange = item => {
    setSelectedTab(item)
  }

  const handleSubmit = () => {
    dispatch(ivrActions.saveSmsRatingSetting({
      store,
      ...validator.getValues()
    }))
  }

  const values = validator.watch()

  const renderMainContent = () => {
    return (
      <>
        <AutomationSms
          store={ store }
          visible={ selectedTab.key === 'sms' }
          validator={ validator }
          values={ values }
        />
        <RatingPage
          store={ store }
          visible={selectedTab.key === 'rating_page'}
          validator={ validator }
          values={ values }
        />
        <ThankYou
          store={ store }
          visible={selectedTab.key === 'thank_you_page'}
          validator={ validator }
          values={ values }
        />
      </>
    )
  }

  return (
    <div className='eg-sms-loyalty-settings'>
      <AutomationHeader
        title="SMS Review Builder"
        validator={ validator }
        isValid={ validator.formState.isValid }
        // values={ values }
        onSubmit={ handleSubmit }
      />
      <AutomationCard
        tabItems={ tabItems }
        selectedItem={ selectedTab }
        onTabChange={ handleTabChange }
      >
        { renderMainContent() }
      </AutomationCard>
    </div>
  )
}

SmsRatingSettings.propTypes = {
  store: PropType.object
}

export default SmsRatingSettings

