import { connect } from 'react-redux'

import Spinner from './Spinner'

const mapStateToProps = (state, props) => ({
  loading: state.Spinner.loading,
  className: props.className
})

export default connect(mapStateToProps)(Spinner)
