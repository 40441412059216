import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import LocalSpinner from '../../LocalSpinner'
import JourneyItem from './JourneyItem'
import { getNumberWithCommas } from '../../../utils/number'
import './styles.scss'


/**
 * Journey list component to render delivered or scheduled journeys
 *
 * @component
 * @example
 *
 *
 *
 * const data = {
 *   loading: false,
 *   error: null,
 *   journeys: {
 *      list: []
 *   }
 * }
 *
 * return (
 *  <JourneyList data={JourneyList} />
 * )
*/
const JourneyList = ({ data, type, onStatusChange, onItemClick }) => {
  const { loading, error, list = {} } = data
  const items = get(list, 'items') || []

  if(loading) {
    return (
      <div className='eg-journey-list'>
        <LocalSpinner className='loader' />
      </div>
    )
  }

  if(error) {
    return (
      <div className='eg-journey-list'>
        <div className='title'>Something went wrong.</div>
        <div className='no-result'>
          Please refresh the page. If the issue persist please contact us.
        </div>
      </div>
    )
  }

  if(items && items.length === 0) {
    return (
      <div className='eg-journey-list'>
        <div className='title'>No journeys</div>
        <div className='no-result'>
        This is where you will see all created journeys
        </div>
      </div>
    )
  }
  const total= get(list, 'total')
  return (
    <div className='eg-journey-list'>
      <div className='total'> {getNumberWithCommas(total)} {type} Journeys </div>
      <div className='table'>
        <div className='column'>
          <div className='name'>NAME</div>
          <div className='status'>STATUS</div>
          {/* <div className='in_progress'>STARTED</div> */}
          <div className='sent'>COMPLETED</div>
          <div className='opted_out'>OPTED OUT</div>
        </div>
        {
          items.map((item, index) => (
            <JourneyItem
              item={item}
              key={index}
              onStatusChange={ (status) => onStatusChange(item, status) }
              onItemClick={ () => onItemClick(item) }
            />
          ))
        }
      </div>
    </div>
  )
}

JourneyList.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onStatusChange: PropTypes.func,
  onItemClick: PropTypes.func
}

export default JourneyList
