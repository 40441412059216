export const types = {
  GET_REPORTS_INTENT: 'GET_REPORTS_INTENT',
  GET_REPORTS_INTENT_RESOLVED: 'GET_REPORTS_INTENT_RESOLVED',
  GET_REPORTS_INTENT_REJECTED: 'GET_REPORTS_INTENT_REJECTED',
}

export const actions = {
  getReports(data) {
    return {
      type: types.GET_REPORTS_INTENT,
      data
    }
  },

  getReportsResolved(data) {
    return {
      type: types.GET_REPORTS_INTENT_RESOLVED,
      data
    }
  },

  getReportsRejected(error) {
    return {
      type: types.GET_REPORTS_INTENT_REJECTED,
      error
    }
  },

}
