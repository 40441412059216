import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import { PATHS } from '../../constants'
import { userRoles } from '../../services/auth'
import AccessControlledComponent from '../AccessControlledComponent'

import zeroStoreLogo from './assets/zsf-logo.svg'
import styles from './index.module.scss'

const optionTabs = {
  MESSAGING: 'messaging',
  RATING: 'rating'
}

export default class Sidebar extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    restaurant: PropTypes.object,
    activePath: PropTypes.string,
    user: PropTypes.object,
    hasMultipleChains: PropTypes.bool,
    sectionChanged: PropTypes.func
  }

  constructor() {
    super()

    // initial state
    this.state = {
      mobileActive: false,
      selectedOption: null,
    }

    // bindings
    this.isActivePath = this.isActivePath.bind(this)
    this.activeClass = this.activeClass.bind(this)
    this.handleBurgerClick = this.handleBurgerClick.bind(this)
    this.linkClick = this.linkClick.bind(this)
    this.optionLinkClick = this.optionLinkClick.bind(this)
    this.renderReviewPath = this.renderReviewPath.bind(this)
    this.renderMessagingPath = this.renderMessagingPath.bind(this)
    this.optionMenuClick = this.optionMenuClick.bind(this)
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.sectionChanged(location.pathname)
    })
    const urlPath = get(this.props, 'history.location.pathname')
    if (urlPath) {
      this.props.sectionChanged(urlPath)
    }
    // If no restaurant has been selected,
    // let's redirect to restaurant page
    if (!this.props.restaurant) {
      this.props.history.push(PATHS.CHAINS)
    }
  }

  componentWillUnmount() {
    this.unlisten()
  }

  isActivePath(path) {
    const pathRegex = new RegExp(`^/?${path}.*`)
    return this.props.activePath && this.props.activePath.match(pathRegex)
  }

  /**
   * get active class if the route matches current path
   *
   * @param {string} path - path to compare
  */
  activeClass(path) {
    if (!this.props.activePath) return ''
    return this.isActivePath(path) ? styles.active : ''
  }

  /**
   * switch sidebar state on mobile
  */
  handleBurgerClick() {
    this.setState({ mobileActive: !this.state.mobileActive })
  }

  linkClick() {
    this.setState({ mobileActive: false, selectedOption: false  })
  }

  optionLinkClick() {
    this.setState({ mobileActive: false })
  }

  renderMessagingPath() {
    const shouldShowMessagingOption = this.state.selectedOption === optionTabs.MESSAGING
    if (shouldShowMessagingOption) {
      return (
        <div className={styles.reviewOption}>
          <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}>
            <Link to={PATHS.SMS_CAMPAIGNS} onClick={this.optionLinkClick} className={`${styles.option} ${this.isActivePath(PATHS.SMS_CAMPAIGNS) ? styles.selectedOption : ''}`}>
            Campaigns
            </Link>
          </AccessControlledComponent>
          <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_JOURNEYS_READ]}>
            <Link to={PATHS.SMS_JOURNEYS} onClick={this.optionLinkClick} className={`${styles.option} ${this.isActivePath(PATHS.SMS_JOURNEYS) ? styles.selectedOption : ''}`}>
            Journeys
            </Link>
          </AccessControlledComponent>
        </div>
      )
    }
    return null
  }

  renderReviewPath() {
    if (this.state.selectedOption === optionTabs.RATING || this.props.activePath === PATHS.REVIEWS_OVERVIEW || this.props.activePath === PATHS.REVIEWS_LIST) {
      return (
        <div className={styles.reviewOption}>
          <Link to={PATHS.REVIEWS_OVERVIEW} onClick={this.optionLinkClick} className={`${styles.option} ${this.isActivePath(PATHS.REVIEWS_OVERVIEW) ? styles.selectedOption : ''}`}>
            Overview
          </Link>
          <Link to={PATHS.REVIEWS_LIST} onClick={this.optionLinkClick} className={`${styles.option} ${this.isActivePath(PATHS.REVIEWS_LIST) ? styles.selectedOption : ''}`}>
            Reviews
          </Link>
        </div>
      )
    }
    return null
  }

  optionMenuClick(selectedOption) {
    if(selectedOption === this.state.selectedOption) {
      this.setState({ ...this.state, selectedOption: null })
    } else {
      this.setState({ ...this.state, selectedOption })
    }
  }

  render() {
    const mobileActiveClass = this.state.mobileActive ? styles.mobileActive : ''
    const totalStores = get(this.props, 'stores.length')
    const singleChainStyle = !this.props.hasMultipleChains ? styles.singleChain : ''

    return (
      <div className={`${styles.sidebarContainer} ${mobileActiveClass}`}>
        <div className={styles.container}>
          <div className={styles.title}>
            <img src={zeroStoreLogo} alt='Eatgeek' className={styles.logo} />
            <div className={`${styles.restaurantName} ${singleChainStyle}`}>
              {
                this.props.hasMultipleChains ? (
                  <Link to={PATHS.CHAINS} onClick={this.linkClick}>
                    { this.props.restaurant && this.props.restaurant.name}
                  </Link>
                ) : (
                  <div>{this.props.restaurant && this.props.restaurant.name}</div>
                )
              }
            </div>
          </div>

          {/* Manage Section */}
          <div className={styles.menu}>
            <div className={styles.sectionTitle}>
              Manage
            </div>
            {/* <AccessControlledComponent roles={[]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.itemOrders} ${this.activeClass(PATHS.ORDERS)}`}>
                  <Link to={PATHS.ORDERS} onClick={this.linkClick}>Requests &amp; Orders</Link>
                </div>
              </div>
            </AccessControlledComponent> */}
            <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.dashboard} ${this.activeClass(PATHS.DASHBOARD)}`}>
                  <Link to={PATHS.DASHBOARD} onClick={this.linkClick}>Dashboard</Link>
                </div>
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.REVIEW_MANAGER]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.reviews} ${this.activeClass(PATHS.REVIEWS)}`}>
                  <div onClick={() => this.optionMenuClick(optionTabs.RATING)}>Ratings &amp; Reviews</div>
                </div>
                {this.renderReviewPath()}
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.CUSTOMER_READ]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.customers} ${this.activeClass(PATHS.CUSTOMERS)}`}>
                  <Link to={PATHS.CUSTOMERS} onClick={this.linkClick}>Customers</Link>
                </div>
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SEGMENT_READ]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.segments} ${this.activeClass(PATHS.SEGMENTS)}`}>
                  <Link to={PATHS.SEGMENTS} onClick={this.linkClick}>Segments</Link>
                </div>
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={ [] }>
              <div className={ styles.menuItems }>
                <div className={ `${styles.menuItem} ${styles.channels} ${this.activeClass(PATHS.CHANNELS)}` }>
                  <Link to={ PATHS.CHANNELS } onClick={ this.linkClick }>Channels</Link>
                </div>
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ, userRoles.SMS_JOURNEYS_READ]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.campaigns} ${this.activeClass(PATHS.SMS_CAMPAIGNS) || this.activeClass(PATHS.SMS_JOURNEYS)}`}>
                  <div onClick={() => this.optionMenuClick(optionTabs.MESSAGING)}>Messaging</div>
                </div>
                {this.renderMessagingPath()}
              </div>
            </AccessControlledComponent>
            <AccessControlledComponent roles={ [userRoles.REPORT_READ] }>
              <div className={ styles.menuItems }>
                <div className={ `${styles.menuItem} ${styles.report} ${this.activeClass(PATHS.REPORTS)}` }>
                  <Link to={ PATHS.REPORTS } onClick={ this.linkClick }>Reports</Link>
                </div>
              </div>
            </AccessControlledComponent>
            {/* <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.TWILIO_READ]}>
              <div className={styles.menuItems}>
                <div className={`${styles.menuItem} ${styles.calls} ${this.activeClass(PATHS.CALLS)}`}>
                  <Link to={PATHS.CALLS} onClick={this.linkClick}>Voice &amp; Text</Link>
                </div>
              </div>
            </AccessControlledComponent> */}
          </div>

          {/* Admin Section */}
          <div className={styles.menu}>
            <div className={styles.sectionTitle}>
              Admin
            </div>
            <div className={styles.menuItems}>
              <div className={`${styles.menuItem} ${styles.stores} ${this.activeClass(PATHS.STORES)}`}>
                <Link to={PATHS.STORES} onClick={this.linkClick}>
                  Stores {totalStores ? `(${totalStores})` : ''}
                </Link>
              </div>
              <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.CONNECTIONS_WRITE]}>
                <div className={styles.menuItems}>
                  <div className={`${styles.menuItem} ${styles.integrations} ${this.activeClass(PATHS.INTEGRATIONS)}`}>
                    <Link to={PATHS.INTEGRATIONS} onClick={this.linkClick}>Integrations</Link>
                  </div>
                </div>
              </AccessControlledComponent>
              {/*
              <div className={`${styles.menuItem} ${styles.plan} ${this.activeClass(PATHS.USER_PLAN)}`}>
                <Link to={PATHS.USER_PLAN} onClick={this.linkClick}>
                  Plans
                </Link>
              </div>
              */}
              <AccessControlledComponent roles={[userRoles.RESTAURANT_ADMIN, userRoles.SMS_CAMPAIGNS_READ]}>
                <div className={styles.menuItems}>
                  <div className={`${styles.menuItem} ${styles.settings} ${this.activeClass(PATHS.SETTINGS)}`}>
                    <Link to={PATHS.SETTINGS} onClick={this.linkClick}>Settings</Link>
                  </div>
                </div>
              </AccessControlledComponent>
            </div>
          </div>
        </div>
        <div className={styles.burger} onClick={this.handleBurgerClick} />
        <div className={styles.background} onClick={this.handleBurgerClick} />
      </div>
    )
  }
}
