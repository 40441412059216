import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {parse2DecimalFloat} from '../../../../utils/number'
import PercentBar from '../../../PercentBar'
import './styles.scss'

CityStat.propTypes = {
  campaign: PropTypes.object,
}

function CityStat(props) {
  const { campaign = {} } = props
  const cities = _.get(campaign, 'statistic.cities')
  const totalClicks = _.get(campaign, 'statistic.customer_click_count')
  if(!cities || cities.length === 0) {
    return null
  }
  return (
    <div className='eg-city-stat-container'>
      <div className='title'>
        City
      </div>
      {
        cities.map((city, key) => {
          const percentage = parse2DecimalFloat((city.customer_click_count/totalClicks) * 100)
          return (
            <div className='eg-city-stat' key={key}>
              <div className='label'>
                {city.key}
              </div>
              <PercentBar
                quantity={city.customer_click_count}
                value={percentage}
                displayValues={true} className='percent-bar'/>
            </div>
          )
        })
      }
    </div>
  )
}

export default CityStat