import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import shortid from 'shortid'

import CustomerHeader from '../../components/CustomerHeader'
import LocalSpinner from '../../components/LocalSpinner'
import Button, { types as buttonTypes } from '../../components/FormComponents/Button'
import Search from '../../components/Search'
import { SEARCH_INPUT_INTERVAL, GOOGLE_CONNECT_STATUSES } from '../../constants'
import styles from './index.module.scss'

export default class Chains extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    restaurant: PropTypes.object,
    chains: PropTypes.array,
    loading: PropTypes.bool,
    searching: PropTypes.bool,
    navigating: PropTypes.bool,
    hideSidebar: PropTypes.func,
    showSidebar: PropTypes.func,
    getChains: PropTypes.func,
    switchChain: PropTypes.func,
    searchChanged: PropTypes.func
  }

  static defaultProps = {
    chains: []
  }

  constructor() {
    super()

    this.state = {
      searchValue: ''
    }

    // bindings
    this.accessClicked = this.accessClicked.bind(this)
    this.getMore = this.getMore.bind(this)
    this.hasMorePages = this.hasMorePages.bind(this)
    this.searchChanged = this.searchChanged.bind(this)
  }

  componentDidMount() {
    this.props.hideSidebar()
    this.props.getChains(1, '')
  }

  componentWillUnmount() {
    this.props.showSidebar()
  }

  componentDidUpdate(prevProps) {
    const chains = this.props.chains
    const navigating = this.props.navigating
    if (chains && chains.length === 1 && !navigating) {
      this.accessClicked(this.props.chains[0])
    }
  }

  /**
   * Triggered when a user clicks on "Access" button
   * Will be redirected to the panel using the selected
   * chain as the active one.
   */
  accessClicked(chain) {
    this.props.switchChain(chain)
  }

  /**
   * Get more chains when user scrolls down
   *
   * @param {number} page - page to be gathered
  */
  getMore(page) {
    // second argument indicates that this is not
    // the first request
    this.props.getChains(page, this.state.searchValue, false)
  }

  /**
   * Check if we should gather more items
  */
  hasMorePages() {
    const chains = this.props.chains
    const searching = this.props.searching
    return chains && chains.length >= 10 && !searching
  }

  searchChanged(value) {
    this.setState({
      searchValue: value
    }, () => {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.props.searchChanged(value)
      }, SEARCH_INPUT_INTERVAL)
    })
  }

  render() {
    const user = this.props.user
    const chains = this.props.chains
    if (this.props.loading) {
      return null
    }

    return (
      <div className={ styles.container }>
        <CustomerHeader includeSettings={ false } />
        <div className={ styles.listContainer }>
          <h3 className={ styles.title }>
            {
              !this.props.restaurant ? <div>Welcome { user ? user.given_name : null }!</div> : null
            }
            Select partner account to access
          </h3>
          <Search
            className={ styles.search }
            value={ this.state.searchValue }
            onChange={ this.searchChanged }
          />
          {
            !this.props.searching ? (
              <div className={ styles.list }>
                {
                  chains ? (
                    <InfiniteScroll
                      pageStart={ 1 }
                      loadMore={ this.getMore }
                      hasMore={ this.hasMorePages() }
                      loader={ <LocalSpinner key={ shortid.generate() } className={ styles.spinner } /> }
                    >
                      {
                        chains.map((restaurant, index) => {
                          const isGmailTokenValid = restaurant.google_connect_status === GOOGLE_CONNECT_STATUSES.TOKEN_OK
                          return (
                            <div key={ index } className={ styles.restaurantRow }>
                              <div className={ styles.restaurantName }>
                                { restaurant.name }
                                {/* <span>{ restaurant.address1 }</span> */}
                                <div className={styles.totalStores}>
                                  {`${restaurant.totalStores} stores`}
                                </div>
                              </div>
                              <Button
                                className={styles.actionButton}
                                type={ isGmailTokenValid ? buttonTypes.PRIMARY_AQUA : buttonTypes.SECONDARY  }
                                text='ACCESS'
                                onClick={ () => this.accessClicked(restaurant) }
                              />
                            </div>
                          )
                        })
                      }
                    </InfiniteScroll>
                  ) : null
                }
              </div>
            ) : (
              <div className={ styles.spinnerWrapper }>
                <LocalSpinner />
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
