export const types = {
  GET_CHAINS_INTENT: 'GET_CHAINS_INTENT',
  GET_CHAINS_INTENT_RESOLVED: 'GET_CHAINS_INTENT_RESOLVED',
  GET_CHAINS_INTENT_REJECTED: 'GET_CHAINS_INTENT_REJECTED',
  SWITCH_CHAIN: 'SWITCH_CHAIN',
  SEARCH_CHAINS_INTENT: 'SEARCH_CHAINS_INTENT',
  SEARCH_CHAINS_INTENT_RESOLVED: 'SEARCH_CHAINS_INTENT_RESOLVED',
  SEARCH_CHAINS_INTENT_REJECTED: 'SEARCH_CHAINS_INTENT_REJECTED',
  RESET_LIST: 'RESET_LIST'
}

export const actions = {
  getChains(opts) {
    return {
      type: types.GET_CHAINS_INTENT,
      opts
    }
  },

  getChainsResolved(data) {
    return {
      type: types.GET_CHAINS_INTENT_RESOLVED,
      data
    }
  },

  getChainsRejected() {
    return {
      type: types.GET_CHAINS_INTENT_REJECTED
    }
  },

  switchChain(chain) {
    return {
      type: types.SWITCH_CHAIN,
      chain
    }
  },

  searchIntent(opts) {
    return {
      type: types.SEARCH_CHAINS_INTENT,
      opts
    }
  },

  searchIntentResolved(data) {
    return {
      type: types.SEARCH_CHAINS_INTENT_RESOLVED,
      data
    }
  },

  searchIntentRejected() {
    return {
      type: types.SEARCH_CHAINS_INTENT_REJECTED
    }
  },

  resetList() {
    return {
      type: types.RESET_LIST
    }
  }
}
