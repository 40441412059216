import _ from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { types, actions } from '../actions/VirtualNumber'
import { actions as notificationActions } from '../actions/EgNotification'
import {
  templates as notificationTemplates,
  types as notificationTypes
} from '../components/EgNotification/EgNotification'

import { authorizedRequest } from './Auth'
import { mapDataIn } from '../services/virtualNumbers'
import {
  createNumber,
  getNumber,
  getNumbers,
  forwardNumber
} from '../api/VirtualNumber'

/**
 * Create number
*/
function* createNumberHandler({ data }) {
  try {
    const res = yield call(authorizedRequest, createNumber, {
      data
    })

    if (res && res.data) {
      yield put(actions.createNumberResolved(mapDataIn(res.data)))

      // update number list
      yield put(actions.getNumbers({ restaurantUuid: data.restaurantUuid }))
    } else {
      throw res
    }
  } catch(e) {
    yield put(notificationActions.ShowNotification({
      type: notificationTypes.ERROR,
      message: _.get(e, 'error.message'),
      template: notificationTemplates.ERROR_SUPPORT
    }))
    yield put(actions.createNumberRejected(e))
  }
}

/**
 * get number by id
*/
function* getNumberHandler({ id, restaurantUuid }) {
  try {
    const res = yield call(authorizedRequest, getNumber, { id, restaurantUuid })

    if (res && res.data) {
      yield put(actions.getNumberResolved(mapDataIn(res.data)))
    } else {
      throw res
    }
  } catch(e) {
    yield put(notificationActions.ShowNotification({
      type: notificationTypes.ERROR,
      message: _.get(e, 'error.message'),
      template: notificationTemplates.ERROR_SUPPORT
    }))
    yield put(actions.getNumberRejected(e))
  }
}

/**
 * get number list
*/
function* getNumbersHandler({ opts }) {
  try {
    const { restaurantUuid } = opts
    const res = yield call(authorizedRequest, getNumbers, {
      restaurantUuid,
      opts
    })

    if (res && res.data) {
      yield put(actions.getNumbersResolved(res.data.map(item => ({
        ...mapDataIn(item)
      }))))
    } else {
      throw res
    }
  } catch(e) {
    yield put(notificationActions.ShowNotification({
      type: notificationTypes.ERROR,
      message: _.get(e, 'error.message'),
      template: notificationTemplates.ERROR_SUPPORT
    }))
    yield put(actions.getNumbersRejected(e))
  }
}

/**
 * forward a number
*/
function* forwardNumberHandler({ data }) {
  try {
    const { restaurantUuid } = data
    const res = yield call(authorizedRequest, forwardNumber, {
      restaurantUuid,
      data
    })

    if (res && res.data) {
      yield put(actions.forwardNumberResolved(mapDataIn(res.data)))
    } else {
      throw res
    }
  } catch (e) {
    yield put(notificationActions.ShowNotification({
      type: notificationTypes.ERROR,
      message: _.get(e, 'error.message'),
      template: notificationTemplates.ERROR_SUPPORT
    }))
    yield put(actions.forwardNumberRejected(e))
  }
}

function* redirectToDefaultHandler({ restaurantUuid }) {
  let numbers = yield select(state => state.VirtualNumber.numbers)
  if (!numbers) {
    const resNumbers = yield call(authorizedRequest, getNumbers, {
      restaurantUuid
    })
    if (resNumbers && resNumbers.data) {
      numbers = resNumbers.data.map(item => ({
        ...mapDataIn(item)
      }))
      yield put(actions.getNumbersResolved(numbers))
    }
  }

  if (numbers && numbers.length) {
    // if user already has a forwarded number, redirect to details
    // otherwise redirect to the screen to input number to be forwarded
    if (numbers[0].forwardNumbers && numbers[0].forwardNumbers.length) {
      yield put(push(`#numbers/${numbers[0].id}/details`))
    } else {
      yield put(push(`#numbers/${numbers[0].id}/forward`))
    }
    return
  }

  yield put(push('#numbers/create'))
}

export default function* () {
  yield takeLatest(types.CREATE_NUMBER_INTENT, createNumberHandler)
  yield takeLatest(types.GET_NUMBERS_INTENT, getNumbersHandler)
  yield takeLatest(types.GET_NUMBER_INTENT, getNumberHandler)
  yield takeLatest(types.FORWARD_NUMBER_INTENT, forwardNumberHandler)
  yield takeLatest(types.REDIRECT_TO_DEFAULT, redirectToDefaultHandler)
}
