import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

const FileUploader = ({ onFileUpload, multiple=true, children, className='', fileType='', maxSize=Infinity, onError }) => {
  const handleDropRejected = (p) => {
    if (onError && p[0].size > maxSize) {
      onError(`File size should be less than ${parseInt(maxSize / 1024 / 1024)}MB`)
    }
  }

  return (
    <Dropzone
      onDrop={ onFileUpload }
      accept={ fileType }
      multiple={ multiple }
      maxSize={ maxSize }
      onDropRejected={ handleDropRejected }
    >
      {({getRootProps, getInputProps}) => (
        <div {...getRootProps()} className={className}>
          <input {...getInputProps()} />
          { children }
        </div>
      )}
    </Dropzone>
  )
}

FileUploader.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  fileType: PropTypes.string,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  onError: PropTypes.func
}

export default FileUploader
