import CallLogs from './CallLogs'
import { connect } from 'react-redux'

import { actions as storeActions } from '../../actions/Store'
import { actions as filterActions } from '../../actions/Filter'
import { actions as interactionActions } from '../../actions/Interactions'
import {
  interactionSources,
  interactionTypes
} from '../../services/customers'

const mapStateToProps = state => ({
  callList: state.Interactions.interactionList,
  restaurant: state.User.chain,
  loading: state.Interactions.loading
})

const mapDispatchToProps = dispatch => ({
  getCallLogs(opts) {
    dispatch(interactionActions.getInteractions({
      ...opts,
      sources: [ interactionSources.VOICE ],
      sourceTypes: [
        interactionTypes[interactionSources.VOICE].INCOMING_CALL
      ]
    }))
  },
  
  getStoreList() {
    dispatch(storeActions.getStoreList())
  },

  clearStoreFilter() {
    dispatch(filterActions.setStoreFilter([]))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CallLogs)
