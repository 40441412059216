import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Select } from '../../../../FormComponents'
import { predicateComparison } from '../../../../../services/segments'
import Input from '../../../../FormComponents/Input'

import './styles.scss'

export const numberOptions = [
  { label: 'equal to', value: predicateComparison.EQ },
  { label: 'less than', value: predicateComparison.LT },
  { label: 'more than', value: predicateComparison.GTE },
  // { label: 'At least once', value: NUMBER_OPTIONS.AT_LEAST_ONCE},
]

AmountPredicate.propTypes = {
  predicate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

function AmountPredicate(props) {
  const { onChange, predicate } = props
  const amountComparision = _.get(predicate, 'amount.comparison')
  let selectedOption = numberOptions[0]
  if(amountComparision) {
    selectedOption = numberOptions.find((option) =>  option.value === amountComparision)
  }
  const [ selectedPredicate, setSelectedPredicate ] = useState(selectedOption)
  const [ value, setValue ] = useState(_.get(predicate, 'amount.value', 1))

  const handleOnSelect = (item) => {
    const updatedPredicate = {...predicate}
    if(updatedPredicate.amount)  {
      updatedPredicate.amount.comparison = item.value
    } else {
      updatedPredicate.amount = {
        comparison: item.value
      }
    }
    onChange(updatedPredicate)
    setSelectedPredicate(item)
  }

  const onNumberChange = (number) => {
    const updatedPredicate = {...predicate}
    updatedPredicate.amount = {
      comparison: selectedPredicate.value,
      value: number,
    }
    onChange(updatedPredicate)
  }

  const onValueChange = (newValue) => {
    if(newValue < 0) return
    setValue(newValue)
    onNumberChange(newValue)
  }

  return (
    <div className='eg-segments-predicates-date'>
      <Select
        className='condition'
        options={ numberOptions }
        selectedItem={ selectedPredicate }
        onChange={ handleOnSelect }
      />
      <Input
        type='number'
        value={ value }
        onChange={(event) => onValueChange(event.target.value)}
        className='number-input'
      />
    </div>
  )
}

export default AmountPredicate
