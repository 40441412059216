export const types = {
  CLEAR_JOURNEYS: 'CLEAR_JOURNEYS',
  GET_JOURNEYS_INTENT: 'GET_JOURNEYS_INTENT',
  GET_JOURNEYS_INTENT_RESOLVED: 'GET_JOURNEYS_INTENT_RESOLVED',
  GET_JOURNEYS_INTENT_REJECTED: 'GET_JOURNEYS_INTENT_REJECTED',

  GET_JOURNEY_INTENT: 'GET_JOURNEY_INTENT',
  GET_JOURNEY_INTENT_RESOLVED: 'GET_JOURNEY_INTENT_RESOLVED',
  GET_JOURNEY_INTENT_REJECTED: 'GET_JOURNEY_INTENT_REJECTED',

  GET_POPUP_JOURNEYS_INTENT: 'GET_POPUP_JOURNEYS_INTENT',
  GET_POPUP_JOURNEYS_INTENT_RESOLVED: 'GET_POPUP_JOURNEYS_INTENT_RESOLVED',
  GET_POPUP_JOURNEYS_INTENT_REJECTED: 'GET_POPUP_JOURNEYS_INTENT_REJECTED',

  GET_KEYWORD_JOURNEYS_INTENT: 'GET_KEYWORD_JOURNEYS_INTENT',
  GET_KEYWORD_JOURNEYS_INTENT_RESOLVED: 'GET_KEYWORD_JOURNEYS_INTENT_RESOLVED',
  GET_KEYWORD_JOURNEYS_INTENT_REJECTED: 'GET_KEYWORD_JOURNEYS_INTENT_REJECTED',

  UPSERT_JOURNEY_INTENT: 'UPSERT_JOURNEY_INTENT',
  UPSERT_JOURNEY_INTENT_RESOLVED: 'UPSERT_JOURNEY_INTENT_RESOLVED',
  UPSERT_JOURNEY_INTENT_REJECTED: 'UPSERT_JOURNEY_INTENT_REJECTED',

  SET_JOURNEY_STATE_INTENT: 'SET_JOURNEY_STATE_INTENT',
  SET_JOURNEY_STATE_INTENT_RESOLVED: 'SET_JOURNEY_STATE_INTENT_RESOLVED',
  SET_JOURNEY_STATE_INTENT_REJECTED: 'SET_JOURNEY_STATE_INTENT_REJECTED',

  JOURNEY_SHORTLINK_CREATE_INTENT: 'JOURNEY_SHORTLINK_CREATE_INTENT',
  JOURNEY_SHORTLINK_CREATE_INTENT_RESOLVED: 'JOURNEY_SHORTLINK_CREATE_INTENT_RESOLVED',
  JOURNEY_SHORTLINK_CREATE_INTENT_REJECTED: 'JOURNEY_SHORTLINK_CREATE_INTENT_REJECTED',
  JOURNEY_SHORTLINK_UPDATE_INTENT: 'JOURNEY_SHORTLINK_UPDATE_INTENT',
  JOURNEY_SHORTLINK_UPDATE_INTENT_RESOLVED: 'JOURNEY_SHORTLINK_UPDATE_INTENT_RESOLVED',
  JOURNEY_SHORTLINK_UPDATE_INTENT_REJECTED: 'JOURNEY_SHORTLINK_UPDATE_INTENT_REJECTED',
  JOURNEY_SHORTLINK_CLEAR: 'JOURNEY_SHORTLINK_CLEAR',

  JOURNEY_ADD_MESSAGE_NODE: 'JOURNEY_ADD_MESSAGE_NODE'
}

export const actions = {
  getJourneys(data) {
    return {
      type: types.GET_JOURNEYS_INTENT,
      data
    }
  },

  getJourneysResolved(data) {
    return {
      type: types.GET_JOURNEYS_INTENT_RESOLVED,
      data
    }
  },

  getJourneysRejected(error) {
    return {
      type: types.GET_JOURNEYS_INTENT_REJECTED,
      error
    }
  },


  getPopupJourneys(data) {
    return {
      type: types.GET_POPUP_JOURNEYS_INTENT,
      data
    }
  },

  getPopupJourneysResolved(data) {
    return {
      type: types.GET_POPUP_JOURNEYS_INTENT_RESOLVED,
      data
    }
  },

  getPopupJourneysRejected(error) {
    return {
      type: types.GET_POPUP_JOURNEYS_INTENT_REJECTED,
      error
    }
  },


  getKeywordJourneys(data) {
    return {
      type: types.GET_KEYWORD_JOURNEYS_INTENT,
      data
    }
  },

  getKeywordJourneysResolved(data) {
    return {
      type: types.GET_KEYWORD_JOURNEYS_INTENT_RESOLVED,
      data
    }
  },

  getKeywordJourneysRejected(error) {
    return {
      type: types.GET_KEYWORD_JOURNEYS_INTENT_REJECTED,
      error
    }
  },

  getJourney(id) {
    return {
      type: types.GET_JOURNEY_INTENT,
      id
    }
  },

  getJourneyResolved(data) {
    return {
      type: types.GET_JOURNEY_INTENT_RESOLVED,
      data
    }
  },

  getJourneyRejected(error) {
    return {
      type: types.GET_JOURNEY_INTENT_REJECTED,
      error
    }
  },

  upsertJourney(data, step) {
    return {
      type: types.UPSERT_JOURNEY_INTENT,
      data,
      step
    }
  },

  upsertJourneyResolved(data) {
    return {
      type: types.UPSERT_JOURNEY_INTENT_RESOLVED,
      data
    }
  },

  upsertJourneyRejected(error) {
    return {
      type: types.UPSERT_JOURNEY_INTENT_REJECTED,
      error
    }
  },

  setJourneyState(data) {
    return {
      type: types.SET_JOURNEY_STATE_INTENT,
      data
    }
  },

  setJourneyStateResolved(data) {
    return {
      type: types.SET_JOURNEY_STATE_INTENT_RESOLVED,
      data
    }
  },

  setJourneyStateRejected(error) {
    return {
      type: types.SET_JOURNEY_STATE_INTENT_REJECTED,
      error
    }
  },

  createShortLink(data) {
    return {
      type: types.JOURNEY_SHORTLINK_CREATE_INTENT,
      data
    }
  },

  createShortLinkResolved(data) {
    return {
      type: types.JOURNEY_SHORTLINK_CREATE_INTENT_RESOLVED,
      data
    }
  },

  createShortLinkRejected(error) {
    return {
      type: types.JOURNEY_SHORTLINK_CREATE_INTENT_REJECTED,
      error
    }
  },

  updateShortLink(data) {
    return {
      type: types.JOURNEY_SHORTLINK_UPDATE_INTENT,
      data
    }
  },

  updateShortLinkResolved(data) {
    return {
      type: types.JOURNEY_SHORTLINK_UPDATE_INTENT_RESOLVED,
      data
    }
  },

  updateShortLinkRejected(error) {
    return {
      type: types.JOURNEY_SHORTLINK_UPDATE_INTENT_REJECTED,
      error
    }
  },

  clearShortlinkState() {
    return {
      type: types.JOURNEY_SHORTLINK_CLEAR,
    }
  },

  clear() {
    return {
      type: types.CLEAR_JOURNEYS
    }
  }
}
