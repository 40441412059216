import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'

import EGAnalytic, { analyticEvents, eventPropertyValues } from '../../../services/analytics'

import StatelessModal from '../../StatelessModal'
import Loader from '../../LocalSpinner'
import RatingStars from '../../RatingStars'
import RatingSource from '../RatingSource'
import styles from './index.module.scss'
import profilePlaceholder from './assets/profile-placeholder.svg'
import ReviewResponse from './ReviewResponse'
import { toHTML } from '../../../utils'
import StatusWidget, { statuses } from '../../StatusWidget'
import { actions } from '../../../actions/Review'

const ReviewDetailModal = props => {
  const { selectedReviewFromList, reviewId } = props
  const dispatch = useDispatch()
  const reviewData = useSelector(state => state.Review.review)
  const { loading, error } = reviewData
  const review = selectedReviewFromList ? selectedReviewFromList : reviewData.data
  const replyRequest = useSelector(state => state.Review.reply)

  useEffect(() => {
    if(!selectedReviewFromList) {
      dispatch(actions.getReview({ reviewId }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    // if the modal is opened through search query, it takes a bit to get restaurant info, so putting a check 
    // to only track modal after we get restaurnt info.
    if(props.restaurant && review) {
      //track review detail opening event
      EGAnalytic.track(analyticEvents.REVIEW_MODAL_DISPLAYED, EGAnalytic.mapReviewDetial(props.restaurant, review))
    }
  }, [props.restaurant, review]) 

  const handleClose = () => {
    //track closing of review detaili modal
    EGAnalytic.track(analyticEvents.REVIEW_MODAL_ABANDONED, EGAnalytic.mapReviewDetial(props.restaurant, review))
    if (props.onClose) {
      props.onClose()
    }
  }

  const trackExternalLinkClick = () => {
    EGAnalytic.track(analyticEvents.REVIEW_EXTERNAL_URL_INTERACTION, { 
      ...EGAnalytic.mapReviewDetial(props.restaurant, review),
      page: review.url, // TODO: what does page means here, the review link address or soemthing else.
      externalButtonSource: eventPropertyValues.REVIEW_EXTERNAL_VIEW
    })
  }

  const userPic = get(review, 'profilePicture') || profilePlaceholder
  const hasResponse = get(review, 'response') || get(replyRequest, 'data.googleCommentId') === get(review, 'googleCommentId')
  return (
    <div className={ styles.reviewModalContainer }>
      <StatelessModal onClose={ handleClose }>
        { loading && <Loader className={ styles.loader } /> }
        { error && <div> Sorry, Something went wrong.</div>}
        { !!review &&
          <>
          <div className={ styles.modalHeader }>
            <RatingSource
              className={ styles.reviewSource }
              source={ review.source }
              imgWidth={ 32 }
              imgHeight={ 32 }
            />
            <div className={ styles.reviewLocation }>
              { review.location }
            </div>
          </div>

          {/* modal body */}
          <div className={ styles.modalBody }>
            <div className={ styles.userInfoWrapper }>
              <div className={ styles.user }>
                <img src={ userPic } alt='profile' />
                <div className={ styles.username }>
                  { review.name }
                </div>
              </div>

              <StatusWidget
                label={ hasResponse ? 'Responded' : 'Waiting for reply' }
                status={ hasResponse ? statuses.COMPLETED : statuses.PENDING }
              />
            </div>

            <div className={ styles.modalRatingWrapper }>
              <RatingStars rate={ review.rating } className={ styles.starsWrapper } />
              <div className={ styles.reviewDate }>
                { moment(review.date).format('MM/DD/YYYY') }
              </div>
            </div>
            <div
              className={ styles.ratingComment }
              dangerouslySetInnerHTML={ toHTML(review.comment) }
            />
            {
              !!review.url && (
                <a
                  className={ styles.reviewAction }
                  href={ review.url }
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={trackExternalLinkClick}
                >
                  View on { review.source }
                </a>
              )
            }
            <ReviewResponse review={ review } />
          </div>
          </>
        }
      </StatelessModal>
    </div>
  )
}

ReviewDetailModal.propTypes = {
  restaurant: PropTypes.object,
  reviewId: PropTypes.string,
  onClose: PropTypes.func,
  selectedReviewFromList: PropTypes.object,
}

export default ReviewDetailModal
