import React from 'react'
import PropTypes from 'prop-types'
// import { get } from 'lodash'

import './styles.scss'
import moment from 'moment'


/**
 * Report list row item view
 *
 * @component
 * @example
 *
 * const item ={
 *   name: 'random name 1',
 *   link: 'whatever.com',
 *   description: 'Analyze performance of all your marketing channels 1',
 *   from: '05-05-2022',
 *   to: '06-05-2022,
 * }
 *
 *
 * return (
 *  <ReportItem item={item} />
 * )
*/
const ReportItem = ({ item = {} }) => {

  const handleItemClick = () => {
    item.link && window.open(item.link, '_blank')
  }

  const from = moment(item.from).format('MMM DD, yyyy')
  const to = moment(item.to).format('MMM DD, yyyy')
  return (
    <div className='eg-report-item' onClick={handleItemClick}>
      <div className='name-container' >
        <div className='icon-container' />
        <div className='name'>
          {item.name}
        </div>
      </div>
      <div className='descri'>
        {item.description}
      </div>
      <div className='date'>
        {`${from} - ${to}`}
      </div>
    </div>
  )
}

ReportItem.propTypes = {
  item: PropTypes.object,
}

export default ReportItem
