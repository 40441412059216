import React from 'react'
import PropTypes from 'prop-types'

import { RadioButton, Input } from '../../../../FormComponents'
import './styles.scss'

const FilterContent = props => {
  const { items, selectedItem } = props

  const handleInputChange = (item, value) => {
    if (props.onInputChange) {
      props.onInputChange(item, value)
    }
  }

  const handleRadioClick = item => {
    if (props.onSelect) {
      props.onSelect(item)
    }
  }

  if (!items) {
    return null
  }

  const renderItem = item => {
    const isSelected = selectedItem ? selectedItem.label === item.label : false
    return (
      <div className='row' key={ `filterItem-${item.label}` }>
        <RadioButton
          selected={ isSelected }
          label={ item.label }
          className='radiobutton'
          onClick={ () => handleRadioClick(item) }
        />
        {
          isSelected && (
            <div className='input-wrapper'>
              <Input
                className='input'
                data-testid={ `eg-total-interactions-filter-input-${item.key}` }
                onChange={ (e) => handleInputChange(item, e.target.value) }
                value={ selectedItem.value }
              />
            </div>
          )
        }
      </div>
    )
  }

  return (
    <div className='eg-total-interactions-filter-content'>
      {
        items.map(f => (
          renderItem(f)
        ))
      }
    </div>
  )
}

FilterContent.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  onInputChange: PropTypes.func,
  onSelect: PropTypes.func
}

export default FilterContent
