import React from 'react'
import PropTypes from 'prop-types'

import JourneyCreate from '../../../components/Journeys/Create'

const JourneyCreateContainer = props => {
  return <JourneyCreate history={ props.history } />
}

JourneyCreateContainer.propTypes = {
  history: PropTypes.object
}

export default JourneyCreateContainer
