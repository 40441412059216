import axios from 'axios'
import { METHODS, HeaderFactory } from './utils'

export const getS3SignedUrl = ({ restaurantUuid, data }, tokens) => {
  return axios({
    url: `${process.env.REACT_APP_CUSTOMERS_URL}/document/presign_url?restaurant_uuid=${restaurantUuid}`,
    method: METHODS.POST,
    headers: HeaderFactory(tokens),
    data: JSON.stringify(data)
  })
}

export const uploadDocs = async ({ files, data }) => {
  // got presigned URLs, so let's create the correct request
  // for each file
  const filesData = files.map(file => {
    const preUrl = data.preSignedUrls.find(p => p.fileName === file.name)
    return {
      file: file,
      url: preUrl.url,
      config: { onUploadProgress: file.onUploadProgress }
    }
  })

  // Upload the files en parallel
  await Promise.all(filesData.map(data => {
    // Config contains a callback to keep track of file upload
    const config = data.config ? data.config : {}
    return axios.request({
      method: 'put',
      url: data.url,
      data: data.file,
      ...config,
      headers: {
        'Content-Type': data.file.type
      },
    })
  }
  ))
}
