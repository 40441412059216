import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { userHasAccess, userRoles } from '../../../../services/auth'
import { PATHS } from '../../../../constants'
import StoreLogo from '../../StoreLogo'
import ProfileComplete from './ProfileComplete'
import './styles.scss'

const StoreRow = ({ store, index, history }) => {
  const storeName = get(store, 'name')
  const storeAddress = get(store, 'address') || ''
  const managerName = get(store, 'managerName')
  const managerEmail = get(store, 'managerEmail')
  const user = useSelector((state => state.User.user))

  const detailsAllowed = userHasAccess(user, [userRoles.RESTAURANT_ADMIN, userRoles.STORE_READ])
  const allowedClass = detailsAllowed ? 'clickable' : ''

  const handleRowClick = () => {
    if (detailsAllowed) {
      history.push(`${PATHS.STORES}/edit/${store.uuid}`)
    }
  }

  return (
    <div className={ `eg-store-row ${allowedClass}` } onClick={ handleRowClick }>
      <div className='name-wrapper'>
        <StoreLogo index={index} />
        <div className='name'>{storeName}</div>
      </div>
      <div className='address'>
        {storeAddress}
      </div>
      <div className='manager'>
        <span className='name'>{managerName}</span>
        <span className='email'>{managerEmail}</span>
      </div>
      <ProfileComplete store={store}/>
    </div>
  )
}

StoreRow.propTypes = {
  store: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(StoreRow)
