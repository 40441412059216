export const types = {
  LOADING_INTENT: 'LOADING_INTENT',
  LOADING_INTENT_RESOLVED: 'LOADING_INTENT_RESOLVED',
}

export const actions = {
  loading() {
    return {
      type: types.LOADING_INTENT
    }
  },

  loadingResolved(data) {
    return {
      type: types.LOADING_INTENT_RESOLVED
    }
  }
}
