import React from 'react'
import PropTypes from 'prop-types'

import OrderSummary from './OrderSummary'
import CustomerCard from './CustomerCard'
import OrderCard from './OrderCard'
import { getOrderStatus } from '../../../services/orders'
import { getFormattedDate, getFormattedTime } from '../../../services/dates'
import CustomerMessage from '../../../components/CustomerMessage'
import { types as msgTypes } from '../../../components/CustomerMessage/CustomerMessage'
import { ORDER_STATUSES } from '../../../services/orders'
import EGAnalytic, { analyticEvents } from '../../../services/analytics'

import styles from './index.module.scss'

const CONTAINER_REF = 'orderContainer'

export default class OrderDetails extends React.Component {
  static propTypes = {
    restaurant: PropTypes.object,
    order: PropTypes.object,
    active: PropTypes.bool,
    onClose: PropTypes.func
  }

  static defaultProps = {
    active: false
  }

  constructor() {
    super()

    // bindings
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.renderMessages = this.renderMessages.bind(this)
  }

  componentDidUpdate(prvProps){
    if(!prvProps.active && this.props.active) {
      //track when model is being turned from non active to active(opening of order details modal)
      EGAnalytic.track(analyticEvents.ORDER_DETAILS_PAGE_VIEWED, {
        ...EGAnalytic.mapOrderDetail(this.props.restaurant, this.props.order),
        orderStatus: getOrderStatus(this.props.order),
      })
    }
    if(prvProps.active && !this.props.active) {
      //track when model is being turned from active to non active(closing of order details modal)
      EGAnalytic.track(analyticEvents.ORDER_DETAILS_PAGE_ABANDONED, {
        ...EGAnalytic.mapOrderDetail(prvProps.restaurant, prvProps.order),
        orderStatus: getOrderStatus(prvProps.order),
      })
    }
  }

  /**
   * handle click outside the container, so the modal is closed
   *
   * @param {object} e - click event
   */
  handleOutsideClick(e) {
    const containerClass = this.refs[CONTAINER_REF].className
    const elementClass = e.target.className
    e.persist()
    if (containerClass !== elementClass) {
      this.props.onClose()
    }
  }

  /**
   * handle click over close button, so the modal is closed
   */
  handleCloseClick() {
    this.props.onClose()
  }

  renderMessages() {
    const order = this.props.order
    return (
      <>
        {/* Order is pending message */}
        <CustomerMessage
          className={ styles.pendingMsg }
          type={ msgTypes.MSG_WARNING }
          show={ getOrderStatus(order) === ORDER_STATUSES.PENDING }
        >
          This order is still pending.
        </CustomerMessage>

        {/* Order was cancelled message */}
        <CustomerMessage
          className={ styles.pendingMsg }
          type={ msgTypes.MSG_ALERT }
          show={ getOrderStatus(order) === ORDER_STATUSES.CANCELLED }
        >
          This order was cancelled.
        </CustomerMessage>

        {/* Order completed message */}
        <CustomerMessage
          className={ styles.pendingMsg }
          type={ msgTypes.MSG_SUCCESS }
          show={ getOrderStatus(order) === ORDER_STATUSES.COMPLETED }
        >
          This order was completed.
        </CustomerMessage>
      </>
    )
  }

  render() {
    const order = this.props.order
    const activeClass = this.props.active ? styles.active : ''
    const hideClass = !this.props.active ? styles.hide : ''
    if (!order) {
      return null
    }

    return (
      <div className={ styles.container }>
        <div
          className={ `${styles.bckgnd} ${hideClass}` }
          onClick={ this.handleOutsideClick }
        />
        <div ref={ CONTAINER_REF } className={ `${styles.detailsContainer} ${activeClass}` }>
          {/* Header */}
          <div className={ styles.detailsHeader }>
            <div className={ styles.orderPlaced }>
              Placed on {
                `${getFormattedDate(order.created_date, false)}, ${getFormattedTime(order.created_date)}`
              }
            </div>
            <div className={ styles.close } onClick={ this.handleCloseClick } />
          </div>

          {/* Order title */}
          <h2 className={ styles.title }>Order #{ order.restaurant_order_id }</h2>

          {/* Order details */}
          <div className={ styles.detailsBody }>

            {/* Left panel */}
            <div className={ styles.leftPanel }>
              {/* Order status messages */}
              <div className={styles.displayOnDesktop}>{ this.renderMessages() }</div>
              <OrderSummary order={ order } />
            </div>

            {/* Right panel */}
            <div className={ styles.rightPanel }>
              <div className={styles.displayOnMobile}>{ this.renderMessages() }</div>
              <CustomerCard order={ order } />
              <OrderCard order={ order } />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
