import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import BarResult from './BarResult'
import ChannelInsight from './ChannelInsight'
import { toCurrency } from '../../../utils/number'
import { getOverview, getInsights } from '../../../services/channels'
import './styles.scss'

ChannelOverview.propTypes = {
  selectedTime: PropTypes.object,
  channelList: PropTypes.object
}

function ChannelOverview({selectedTime, channelList}) {
  const { error } = useSelector(state => state.Channel)
  const insight = getInsights(channelList)
  const overview = getOverview(channelList)
  const positiveItems = overview.positiveRoiChannels
  const negativeItems = overview.negativeRoiChannels

  if (error) {
    return (
      <div className='eg-channel-overview'>
        <div className='stats'>
          <div className='error'>
            Something went wrong, please refresh the page or contact us.
          </div>
        </div>
      </div>
    )
  }

  if (channelList.has_channels && !channelList.channels.length) {
    return (
      <div className='eg-channel-overview'>
        <div className='stats notfound'>
          <div className='title'>
            No data to display for specified date range
          </div>
          <div className='message'>
            There isn’t any data and activity for this time period.
          </div>
        </div>
      </div>
    )
  }

  const { total } = channelList

  return (
    <div className='eg-channel-overview'>
      <ChannelInsight insight={insight} selectedTime={selectedTime}/>
      <div className='stats'>
        <div className='brief' data-testid='channel-brief'>
          <div className='item'>
            <div className='label'>Marketing Spend</div>
            <div className='value'>{total.spend ? toCurrency(total.spend) : '-'}</div>
          </div>
          <div className='item'>
            <div className='label'>Gross Profit</div>
            <div className='value'>{total.income ? toCurrency(total.income) : '-'}</div>
          </div>
          <div className='item'>
            <div className='label'>ROI</div>
            <div className='value'>{total.roi ? `${total.roi}x` : '-' }</div>
          </div>
        </div>
        <div className='channel-results'>
          <div className='card' data-testid='overview-positive-roi'>
            <div className='title'>High ROI Channels</div>
            <BarResult items={positiveItems} type='positive' />
          </div>
          <div className='card' data-testid='overview-negative-roi'>
            <div className='title'>Low ROI Channels</div>
            <BarResult items={negativeItems} type='negative' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChannelOverview
