import React from 'react'

import ChainInfo from '../../components/Dashboard/ChainInfo'
import CustomerHeader from '../../components/CustomerHeader'

import './styles.scss'

const Settings = () => {
  return (
    <div className='eg-settings'>
      <CustomerHeader />
      <div className='container'>
        <div className='header'>
          <h3>Settings</h3>
        </div>
        <div className='content'>
          <ChainInfo></ChainInfo>
        </div>
      </div>
    </div>
  )
}

export default Settings
