import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

// we have to consider some exceptions of dynamically
// created elements displayed inside the component
// but created outside it
// TODO: find a way to handle this in a better way
const targetExceptions = [/^pac-/]

const StatelessModal = props => {
  const modalRef = useRef(null)
  const onClose = props.onClose

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    const handleClick = e => {
      if(!e || !e.target || typeof e.target.className !== 'string') return
      const isException = !!targetExceptions.find(t => e.target.className.match(t) || e.target.parentElement.className.match(t))
      if (modalRef.current.contains(e.target) || isException) {
        return
      }
      if (onClose) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [onClose])

  return (
    <div data-testid='stateless-modal' className={ `${styles.modalBackground} ${props.backGroundClassName}` } >
      <div className={ `${styles.modal} ${props.className}` } ref={ modalRef } id='stateless-modal-content'>
        <i data-testid='modal-close-cross' className={ `zmdi zmdi-close ${styles.close} ${props.closeStyle}` } onClick={ handleClose } id='stateless-close'></i>
        { props.children }
      </div>
    </div>
  )
}

StatelessModal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  backGroundClassName: PropTypes.string,
  closeStyle: PropTypes.string,
  onClose: PropTypes.func
}

StatelessModal.defaultProps = {
  className: '',
  backGroundClassName: '',
  closeStyle: '',
}

export default StatelessModal
