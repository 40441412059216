import { types } from '../actions/Report'

export const initialState = {
  reportList: {
    loading: false,
    error: null,
    list: null
  },
}


export default (state = initialState, action) => {
  switch(action.type) {
    // Get reports
    case types.GET_REPORTS_INTENT:
      return {
        ...state,
        reportList: {
          loading: true,
          error: null,
          list: null
        },
      }
    case types.GET_REPORTS_INTENT_RESOLVED:
      return {
        ...state,
        reportList: {
          loading: false,
          error: null,
          list: action.data
        },
      }
    case types.GET_REPORTS_INTENT_REJECTED:
      return {
        ...state,
        reportList: {
          loading: false,
          error: action.error,
          list: null
        },
      }

    default:
      return state
  }
}
