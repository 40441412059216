import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { timeToAmPm } from '../../../services/dates'
import TimeDropdown from './TimeDropdown'

import styles from './index.module.scss'

const types = {
  FROM: 'from',
  TO: 'to'
}

const TimeRangeInput = props => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)

  const handleClick = (event, type) => {
    if (props.disabled || !!selectedTimeSlot) {
      setSelectedTimeSlot(null)
    } else {
      setSelectedTimeSlot(type)
    }
  }

  const handleDropdownClose = () => {
    setSelectedTimeSlot(null)
  }

  const handleTimeChange = (time, type) => {
    if (props.onChange) {
      props.onChange(time, type)
    }
    setSelectedTimeSlot(null)
  }

  const fromActive = !props.disabled && selectedTimeSlot === types.FROM ? styles.timeActive : ''
  const toActive = !props.disabled && selectedTimeSlot === types.TO ? styles.timeActive : ''
  const containerActive = selectedTimeSlot && !props.disabled ? styles.active : ''
  const triggerFromRef = useRef(shortid.generate())
  const triggerToRef = useRef(shortid.generate())
  const displayFrom = timeToAmPm(props.from.time)
  const displayTo = timeToAmPm(props.to.time)
  const disabledClass = props.disabled ? styles.disabled : ''

  return (
    <div className={ `${styles.container} ${containerActive} ${disabledClass}` }>
      <div className={ `${styles.timeContainer} ${fromActive}` }>
        <div
          ref={ triggerFromRef }
          className={ styles.timeInput }
          onClick={ (e) => handleClick(e, types.FROM) }
        >
          { displayFrom || <span className={ styles.placeholder }>{ props.from.placeholder }</span> }
        </div>
        {
          selectedTimeSlot === types.FROM && (
            <TimeDropdown
              min='00:00'
              max={ props.show24hours ? '23:59' : props.to.time }
              triggerRef={ triggerFromRef }
              onClose={ handleDropdownClose }
              onChange={ (selectedTime) => handleTimeChange(selectedTime, types.FROM) }
            />
          )
        }
      </div>
      <div className={ styles.toLabel }>to</div>
      <div className={ `${styles.timeContainer} ${toActive}` }>
        <div
          ref={ triggerToRef }
          className={ styles.timeInput }
          onClick={ (e) => handleClick(e, types.TO) }
        >
          { displayTo || <span className={ styles.placeholder }>{ props.to.placeholder }</span> }
        </div>
        {
          selectedTimeSlot === types.TO && (
            <TimeDropdown
              min={ props.show24hours ? '00:00' : props.from.time }
              max='23:59'
              triggerRef={ triggerToRef }
              onClose={ handleDropdownClose }
              onChange={ (selectedTime) => handleTimeChange(selectedTime, types.TO) }
            />
          )
        }
      </div>
    </div>
  )
}

TimeRangeInput.propTypes = {
  from: PropTypes.shape({
    time: PropTypes.string,
    placeholder: PropTypes.string
  }).isRequired,
  to: PropTypes.shape({
    time: PropTypes.string,
    placeholder: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  // To show all day and night slots in the drop down. 00:00 to 23:59.
  show24hours: PropTypes.bool,
}

TimeRangeInput.defaultProps = {
  disabled: false
}

export default TimeRangeInput
