import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import _ from 'lodash'

import AddItem from '../../../AddItem'
import EditableCard from '../../../EditableCard'
import StatelessModal from '../../../StatelessModal'
import Uploader from '../../../Uploader'
import { types as EgNotificationTypes } from '../../../EgNotification/EgNotification'

import { actions as EgNotificationActions } from '../../../../actions/EgNotification'
import { UPLOAD_FILE_TYPE }from '../../../../services/upload'
import { actions } from '../../../../actions/Upload'

import styles from './index.module.scss'

const { uploadDoc } = actions


const StoreMainInfo = props => {
  const dispatch = useDispatch()
  const showingNotification = useSelector((state) => !!state.EgNotification)
  const [uploadModal, setUploadModal] = useState(false)
  const { store } = props

  const handleEditClick = () => {
    if (props.onEditClick) {
      props.onEditClick()
    }
  }

  const getCategories = () => {
    const categories = _.get(props, 'store.storeInfo.categories')
    if (!categories || !categories.length) {
      return ''
    }
    return categories.join(', ')
  }


  const onUploadModalOpen = () => {
    setUploadModal(true)
  }

  const onUploadModalClose = () => {
    // In case, if we are showing notification
    // We are not going to close the modal
    if(!showingNotification){
      setUploadModal(false)
    }
  }

  const onFileSelection = (acceptedFiles, errorFiles) => {
    if(acceptedFiles && acceptedFiles.length) {
      const storeUuid= store.uuid
      const fileData = { files: acceptedFiles, type: UPLOAD_FILE_TYPE.LOGO }
      dispatch(uploadDoc({ fileData, storeUuid } ))
    } else {
      const notification = {
        type: EgNotificationTypes.WARNING,
        message: 'Only single image upload is allowed.'
      }
      dispatch(EgNotificationActions.ShowNotification(notification))
    }
  }

  const categories = getCategories()
  const address = _.get(props, 'store.storeInfo.address')
  const phone = _.get(props, 'store.storeInfo.phone')
  const logo = _.get(props, 'store.storeInfo.logo') || {}
  const logoStyle = logo ? {backgroundImage: `url(${logo})`, backgroundColor: 'unset' } : {}
  const strAddress = address ? address.formatted_address : ''

  return (
    <div className={ styles.storeMainInfoContainer }>
      {uploadModal &&
        <StatelessModal className={ styles.modal } onClose={ onUploadModalClose }>
          <Uploader
            onFileSelection={ onFileSelection }
            onSuccess={ onUploadModalClose }
            title='Upload logo file'
            fileType='image/*'
            fileText='upload a logo file.'
            multipleFile={false}
          />
        </StatelessModal>
      }
      <EditableCard
        className={ styles.editableCard }
        editClassName={ styles.editButton }
        isEditable
        onEditClick={ handleEditClick }
      >
        <div className={ styles.storePicture } style={logoStyle} >
          <div onClick={onUploadModalOpen} className={styles.addPhoto}>
            ADD PHOTO
          </div>
        </div>
        <div className={ styles.storeName }>
          <span>{ _.get(props, 'store.storeInfo.name') }</span>
        </div>
        {
          !_.get(store, 'storeInfo.phone') && !strAddress ? (
            <div className={ styles.actionWrapper } onClick={ handleEditClick }>
              <div className={ styles.addItemsWrapper }>
                <AddItem className={ styles.addItem } label='Add address' />
                <AddItem className={ styles.addItem } label='Add phone number' />
              </div>
            </div>
          ) : (
            <div className={ styles.storeInfoItems }>
              <div className={ `${styles.itemRow} ${styles.category}` }>
                { categories 
                  ? categories
                  : <span className={ styles.addItemLink } onClick={ handleEditClick } >Add categories</span>
                }
              </div>

              <div className={ `${styles.itemRow} ${styles.address}` }>
                { strAddress 
                  ? strAddress
                  : <span className={ styles.addItemLink } onClick={ handleEditClick } >Add address</span> }
              </div>

              <div className={ `${styles.itemRow} ${styles.phone}` }>
                { phone 
                  ? phone
                  : <span className={ styles.addItemLink } onClick={ handleEditClick } >Add phone</span>
                }
              </div>
            </div>
          )
        }
      </EditableCard>
    </div>
  )
}

StoreMainInfo.propTypes = {
  store: PropTypes.object,
  onEditClick: PropTypes.func
}

export default StoreMainInfo
