import moment from 'moment'

/**
 * Definition of possible order statuses
*/
export const ORDER_STATUSES = {
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  PENDING: 'pending'
}

export const ORDER_SORT_FIELDS = {
  ORDER_ID: 'order_id',
  DELIVERY_DATE: 'delivery_date',
  DELIVERY_ADDRESS: 'delivery_address',
  TOTAL_PRICE: 'total_price',
  CUSTOMER: 'customer_id',      // TODO: how we should send this info?
  STATUS: 'order_status'
}

/**
 * get order status, also decides if a status
 * is `pending` based on the order date.
 * i.e. the order is un `pending` state if it's completed
 * and the order date is in the future
 */
export const getOrderStatus = order => {
  const status = order.order_status.toLowerCase()
  const orderCompleted = status === ORDER_STATUSES.COMPLETED
  if (orderCompleted && moment(order.order_date).isAfter(moment())) {
    // order is completed and date is in the future => it's a pending order
    return ORDER_STATUSES.PENDING
  }
  return status
}
