import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import EGAnalytic, { analyticEvents } from '../../services/analytics'
import { PATHS } from '../../constants'

import TextField from '../FormComponents/TextField'
import Button from '../FormComponents/Button'
import FormValidator from '../../validators/FormValidator'
import { required, email } from '../../validators/customValidations'

import styles from './index.module.scss'

// Define form fields
const EMAIL_FIELD = 'email'
const PASSWORD_FIELD = 'password'
const formFieldsNames = [ EMAIL_FIELD, PASSWORD_FIELD ]

export default class LoginForm extends React.Component {
  static propTypes = {
    pageReferrer: PropTypes.string,
    posting: PropTypes.bool,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    posting: false,
    className: ''
  }

  constructor() {
    super()

    // bindings
    this.handleInputChange = this.handleInputChange.bind(this)
    this.isValid = this.isValid.bind(this)
    this.submit = this.submit.bind(this)

    // set initial state
    this.state = {
      isFormDirty: false,
      [EMAIL_FIELD]: { value: '' },
      [PASSWORD_FIELD]: { value: '' }
    }

    // validations
    this.validator = new FormValidator([
      ...email(EMAIL_FIELD),
      ...required(PASSWORD_FIELD)
    ])
  }

  /**
   * update state with fields information
   *
   * @param event {object} input event
   */
  handleInputChange(event) {
    let value = get(event, 'target.value') || ''
    if(get(event, 'target.name') === EMAIL_FIELD) {
      value =  (typeof value === 'string') ? value.trim() : value
    }
    event.preventDefault()
    if (!this.state.isFormDirty) {
      EGAnalytic.track(analyticEvents.LOGIN_STARTED, {
        page: PATHS.LOGIN
      })
    }
    this.setState({
      isFormDirty: true,
      [event.target.name]: {
        value,
        dirty: true
      }
    })
  }

  /**
   * check if the form is in a valid state
   */
  isValid() {
    return this.validation.isValid && !this.props.posting
  }

  /**
   * submit email and password to login
   */
  submit() {
    if (this.isValid()) {
      this.props.onSubmit({
        email: this.state[EMAIL_FIELD].value,
        password: this.state[PASSWORD_FIELD].value,
        pageReferrer: this.props.pageReferrer,
      })
    }
  }

  render() {
    // Get the form input values and statuses from state to validate.
    const formFields = {}
    formFieldsNames.forEach(fieldName => formFields[fieldName] = this.state[fieldName])
    this.validation = this.validator.validate(formFields)

    return (
      <section className={ this.props.className }>
        <article>
          <TextField
            className={ styles.textField }
            name={EMAIL_FIELD}
            label="Email"
            errorMessage={this.validation[EMAIL_FIELD].message}
            error={!this.validation[EMAIL_FIELD].isValid && this.state[EMAIL_FIELD].dirty}
            onChange={this.handleInputChange}
            onEnterPress={ this.onSubmit }
            tabindex="1"
            value={this.state.email.value}
          />
          <div className={ styles.passwordWrapper }>
            <Link to='/reset-password' className={ styles.resetPasswordLink }>Forgot Password?</Link>
            <TextField
              name={PASSWORD_FIELD}
              label="Password"
              errorMessage={this.validation[PASSWORD_FIELD].message}
              error={!this.validation[PASSWORD_FIELD].isValid && this.state[PASSWORD_FIELD].dirty}
              onChange={this.handleInputChange}
              onEnterPress={ this.onSubmit }
              type="password"
              tabindex="2"
            />
          </div>
        </article>
        <article className={styles.buttonContainer}>
          <Button
            text="Sign in"
            data-testid='sign-in' 
            className={ styles.loginBtn }
            disabled={ !this.isValid() }
            loading={ this.props.posting }
            onClick={ this.submit }
            primary
          />
        </article>
        {/* <article>
          <a
            href='https://restaurant.eatgeek.com/security'
            target="_blank"
            rel="noopener noreferrer"
            className={ styles.linkLink }
          >Security</a>
        </article> */}
      </section>
    )
  }
}
