import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import './styles.scss'

/**
 * Screen preview for negative feedback
 * @param {*} props 
 */
const NegativeFeedbackCard = (props) => {
  const {
    logoUrl,
    headline,
    placeholderText
  } = props

  const updatedLogo = useSelector(state => get(state, 'LoyaltyIvr.store'))
  const displayLogoUrl = get(updatedLogo, 'rating_logo') || logoUrl

  return (
    <div className='eg-negative-feedback'>
      <div className='arrow-back' />
      { displayLogoUrl && <img src={displayLogoUrl} className='store-logo' alt='store' /> }
      <div className='title'>
        {headline}
      </div>
      <div className='feedback-input' >
        {placeholderText}
      </div>
      <div className={'submit disabled' } >SUBMIT</div>
    </div>
  )
}

NegativeFeedbackCard.propTypes = {
  logoUrl: PropTypes.string,
  headline: PropTypes.string,
  placeholderText: PropTypes.string,
}

export default NegativeFeedbackCard
