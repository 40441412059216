export const types = {
  CREATE_NUMBER_INTENT: 'CREATE_NUMBER_INTENT',
  CREATE_NUMBER_INTENT_RESOLVED: 'CREATE_NUMBER_INTENT_RESOLVED',
  CREATE_NUMBER_INTENT_REJECTED: 'CREATE_NUMBER_INTENT_REJECTED',

  GET_NUMBER_INTENT: 'GET_NUMBER_INTENT',
  GET_NUMBER_INTENT_RESOLVED: 'GET_NUMBER_INTENT_RESOLVED',
  GET_NUMBER_INTENT_REJECTED: 'GET_NUMBER_INTENT_REJECTED',

  GET_NUMBERS_INTENT: 'GET_NUMBERS_INTENT',
  GET_NUMBERS_INTENT_RESOLVED: 'GET_NUMBERS_INTENT_RESOLVED',
  GET_NUMBERS_INTENT_REJECTED: 'GET_NUMBERS_INTENT_REJECTED',

  FORWARD_NUMBER_INTENT: 'FORWARD_NUMBER_INTENT',
  FORWARD_NUMBER_INTENT_RESOLVED: 'FORWARD_NUMBER_INTENT_RESOLVED',
  FORWARD_NUMBER_INTENT_REJECTED: 'FORWARD_NUMBER_INTENT_REJECTED',

  CLEAR_NUMBER: 'CLEAR_NUMBER',
  CLEAR_NUMBER_LIST: 'CLEAR_NUMBER_LIST',

  REDIRECT_TO_DEFAULT: 'REDIRECT_TO_DEFAULT'
}

export const actions = {
  createNumber(data) {
    return {
      type: types.CREATE_NUMBER_INTENT,
      data
    }
  },

  createNumberResolved(data) {
    return {
      type: types.CREATE_NUMBER_INTENT_RESOLVED,
      data
    }
  },

  createNumberRejected(error) {
    return {
      type: types.CREATE_NUMBER_INTENT_REJECTED,
      error
    }
  },

  getNumber(id, restaurantUuid) {
    return {
      type: types.GET_NUMBER_INTENT,
      id,
      restaurantUuid
    }
  },

  getNumberResolved(data) {
    return {
      type: types.GET_NUMBER_INTENT_RESOLVED,
      data
    }
  },

  getNumberRejected(error) {
    return {
      type: types.GET_NUMBER_INTENT_REJECTED,
      error
    }
  },

  getNumbers(opts) {
    return {
      type: types.GET_NUMBERS_INTENT,
      opts
    }
  },

  getNumbersResolved(data) {
    return {
      type: types.GET_NUMBERS_INTENT_RESOLVED,
      data
    }
  },

  getNumbersRejected(error) {
    return {
      type: types.GET_NUMBERS_INTENT_REJECTED,
      error
    }
  },

  forwardNumber(data) {
    return {
      type: types.FORWARD_NUMBER_INTENT,
      data
    }
  },

  forwardNumberResolved(data) {
    return {
      type: types.FORWARD_NUMBER_INTENT_RESOLVED,
      data
    }
  },

  forwardNumberRejected(error) {
    return {
      type: types.FORWARD_NUMBER_INTENT_REJECTED,
      error
    }
  },

  clearNumber() {
    return {
      type: types.CLEAR_NUMBER
    }
  },

  clearNumberList() {
    return {
      type: types.CLEAR_NUMBER_LIST
    }
  },

  redirectToDefault(restaurantUuid) {
    return {
      type: types.REDIRECT_TO_DEFAULT,
      restaurantUuid
    }
  }
}
