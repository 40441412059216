import _ from 'lodash'

export class NetworkError extends Error {
  constructor(options) {
    super(options && options.message ? options.message : 'Unknown error')
    this.status = _.get(options, 'status') || 500
    this.headers = _.get(options, 'headers')
    this.data = _.get(options, 'data')
  }

  toJSON() {
    return {
      status: this.status,
      header: this.headers,
      data: this.data
    }
  }
}
