import moment from 'moment'
import _ from 'lodash'

import { ORDER_STATUSES } from './orders'

export const CUSTOMER_STATUSES = {
  WITH_PENDING_ORDERS: 'pending'
}

export const CUSTOMER_SORT_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone_number',
  LOCATION_AREA: 'last_address',
  TOTAL_SPENT: 'total_spent',
  LAST_ORDER: 'last_order'
}

export const filterTypes = {
  TOTAL_INTERACTIONS: 'total_interactions',
  LAST_SOURCE: 'last_source',
  SOURCE_MEDIUM: 'source_medium',
  INTERACTION_TYPES: 'interaction_types'
}

export const interactionSources = {
  VOICE: 'voice',
  PAYTRONIX: 'paytronix',
  SQUARE: 'square',
  // GOOGLE: 'google',
  // FACEBOOK: 'facebook',
  CLOVER: 'clover',
  CONSTANT_CONTACT: 'constant_contact',
  ZENREACH: 'zenreach',
  DOORDASH: 'doordash',
  REVEL: 'revel',
  // GRUBHUB: 'grubhub'
  ZEROSTOREFRONT: 'zero_storefront',
  TOAST: 'toast',
  TEXT: 'text',
  OLO: 'olo'
}

export const sourceTypes = {
  PHONE: 'phone',
  POS: 'pos',
  LOYALTY_PLATFORM: 'loyalty_platform',
  ONLINE_ORDERING_PLATFORM: 'online_ordering_platform',
  MARKETING_PLATFORM: 'marketing_platform'
}

export const sourceMediums = {
  TWILIO: 'twilio',
  WEBHOOK: 'webhook',
  API: 'api',
  CSV: 'csv',
  ZSF_WEB: 'zsf_web',
  PRIVY: 'privy',
  TOAD: 'toad',
  SHORTIO: 'shortio'
}

export const interactionTypes = {
  [interactionSources.VOICE]: {
    INCOMING_CALL: 'incoming_call',
    CALL_FINISHED: 'call_finished',
    IVR_SMS_OPT_IN: 'ivr_sms_opt_in',
    IVR_NO_SMS_OPT_IN: 'ivr_no_sms_opt_in'
  },
  [interactionSources.SQUARE]: {
    ORDER: 'order',
    TRANSACTION: 'transaction'
  },
  [interactionSources.PAYTRONIX]: {
    ACCOUNT_CHANGE: 'account_change',
    TRANSACTION: 'transaction',
    LOYALTY_SIGNUP: 'loyalty_signup'
  },
  [interactionSources.DOORDASH]: {
    VERIFICATION_SUCCESSFUL: 'verification_successful',
    VERIFICATION_UNSUCCESSFUL: 'verification_unsuccessful'
  },
  [interactionSources.REVEL]: {
    ORDER: 'order'
  },
  [interactionSources.LOYALTY_IVR]: {
    LOYALTY_SIGNUP: 'loyalty_signup'
  },
  [interactionSources.CLOVER]: {
    ORDER: 'order'
  },
  [interactionSources.TOAST]: {
    ORDER_PLACED: 'order_placed'
  },
  [interactionSources.TEXT]: {
    SMS_OPT_IN: 'sms_opt_in',
    SMS_OPT_IN_VERIFICATION: 'sms_opt_in_verification',
    SMS_OPT_OUT: 'sms_opt_out'
  },
  [interactionSources.OLO]: {
    ORDER_PLACED: 'order_placed'
  },
}

export const sourceToLabelMap = {
  [interactionSources.VOICE]: 'Voice',
  [interactionSources.PAYTRONIX]: 'Paytronix',
  [interactionSources.DOORDASH]: 'Doordash',
  [interactionSources.CONSTANT_CONTACT]: 'Constant Contact',
  [interactionSources.LOYALTY_IVR]: 'ZSF Loyalty Web',
  [interactionSources.SQUARE]: 'Square',
  [interactionSources.CLOVER]: 'Clover',
  [interactionSources.ZENREACH]: 'ZenReach',
  [interactionSources.REVEL]: 'Revel',
  [interactionSources.ZEROSTOREFRONT]: 'ZeroStorefront',
  [interactionSources.TOAST]: 'Toast',
  [interactionSources.TEXT]: 'Messaging Popup',
  [interactionSources.OLO]: 'OLO'
}

export const interactionTypeLabels = {
  [interactionTypes[interactionSources.VOICE].INCOMING_CALL]: 'Incoming Call',
  [interactionTypes[interactionSources.VOICE].CALL_FINISHED]: 'Call Finished',
  [interactionTypes[interactionSources.VOICE].IVR_SMS_OPT_IN]: 'IVR SMS Opt In',
  [interactionTypes[interactionSources.VOICE].IVR_NO_SMS_OPT_IN]: 'IVR No SMS Opt In',
  [interactionTypes[interactionSources.VOICE].OFFICE_RING]: 'Office Ring',
  [interactionTypes[interactionSources.VOICE].LOYALTY_IVR]: 'Loyalty IVR',

  [interactionTypes[interactionSources.SQUARE].ORDER]: 'Order',
  [interactionTypes[interactionSources.SQUARE].TRANSACTION]: 'Transaction',

  [interactionTypes[interactionSources.PAYTRONIX].ACCOUNT_CHANGE]: 'Account Change',
  [interactionTypes[interactionSources.PAYTRONIX].TRANSACTION]: 'Transaction',
  [interactionTypes[interactionSources.PAYTRONIX].LOYALTY_SIGNUP]: 'Loyalty Signup',

  [interactionTypes[interactionSources.DOORDASH].VERIFICATION_SUCCESSFUL]: 'Verification Successful',
  [interactionTypes[interactionSources.DOORDASH].VERIFICATION_UNSUCCESSFUL]: 'Verification Unuccessful',

  [interactionTypes[interactionSources.TOAST].ORDER_PLACED]: 'Order placed',

  [interactionTypes[interactionSources.TEXT].SMS_OPT_IN]: 'SMS Opt In',
  [interactionTypes[interactionSources.TEXT].SMS_OPT_IN_VERIFICATION]: 'SMS Opt In Verification',
  [interactionTypes[interactionSources.TEXT].SMS_OPT_OUT]: 'SMS Opt Out',

  [interactionTypes[interactionSources.OLO].ORDER_PLACED]: 'Order placed',
}

export const sourceMediumLabels = {
  [sourceMediums.API]: 'API',
  [sourceMediums.CSV]: 'CSV',
  [sourceMediums.TWILIO]: 'Twilio',
  [sourceMediums.WEBHOOK]: 'Webhook',
  [sourceMediums.ZSF_WEB]: 'ZSF Web',
  [sourceMediums.PRIVY]: 'Privy',
  [sourceMediums.TOAD]: 'Toad',
  [sourceMediums.SHORTIO]: 'Short.io'
}

const getSourceClass = (source, mediums) => {
  let sourceClass = source
  // map medium-dependant sources
  if (mediums && mediums.includes(sourceMediums.PRIVY)) {
    sourceClass = `${source}-${sourceMediums.PRIVY}`
  }
  return sourceClass
}

export const mapCustomerIn = customer => {
  const lastActivitySource = _.get(customer, 'last_activity.type')
  const sourceClass = getSourceClass(customer.source, customer.source_mediums)

  return {
    id: customer.id,
    fullName: customer.fullname,
    emails: customer.emails,
    phones: customer.phones,
    addresses: !_.isEmpty(customer.addresses) ? customer.addresses.map(a => a.formatted_address) : [],
    // store: customer.store,
    lastInteraction: {
      label: customer.source ? sourceToLabelMap[customer.source] || customer.source : 'Account Created',
      source: lastActivitySource || customer.source,
      date: _.get(customer, 'last_activity.date') || customer.registration_date || customer.created_at
    },
    totalInteraction: _.get(customer, 'total_interactions', 0) + 1,
    source: customer.source,
    sourceClass,
    mediums: customer.source_mediums || [],
    registrationDate: customer.registration_date,
    createdAt: customer.created_at
  }
}

/**
 * Get a location area (i.e. "Berkeley, CA") from
 * a full address string
 *
 * @param {string} address - address string to be converted
 */
export const getLocationArea = address => {
  if (!address) return 'N/A'
  return address
    .replace(/^.*,([^,]+),([^,]+),.+$/, '$1, $2')
    .replace(/\s+/g, ' ')
    .replace(/\d+/, '').trim()
}

/**
 * Get customer address in a more readable format
 * output example:
 * {
 *    street: '408 N Canal St STE A',
 *    location: 'South San Francisco, CA'
 * }
*/
export const getFormattedAddress = (addressStr) => {
  if (!addressStr) return null
  const address = addressStr.split(',')
  if (address.length === 2) {
    return {
      street: address[0].trim(),
      location: address[1].trim(),
    }
  }
  if (address.length < 3) {
    return addressStr
  }
  // for the last part of the address, get just the state,
  // and ignore the rest
  const state = address[2].trim().replace(/^(\w+)\s.*$/, ' $1')
  return {
    street: address[0].trim(),
    location: `${address[1]}, ${state}`.trim()
  }
}

export const mapInteractionsIn = (input, customer, isLastPage) => {
  if (isLastPage || _.isEmpty(input)) {
    // if this is last page, we will add a dummy event called `Account Created` with the registration date as date of the event.
    const lastActivityData = input[input.length - 1]
    // We will take the createdAt of customer, which is basically registration_date( we mapped and change it to createdAt in front-end side)
    // if we don't have createdAt we just take the first ever activity of the user which is just a fall back option.
    const activity_date = customer.createdAt ? customer.createdAt : lastActivityData.activity_date
    const item = {
      ...lastActivityData,
      transaction_type: 'Account Created',
      activity_date
    }
    if (!lastActivityData) {
      item.source = customer.source
    }
    input.push(item)
  }

  const interactions = input.map(activity => {
    let label = interactionTypeLabels[activity.type] ? interactionTypeLabels[activity.type] : activity.type
    const sourceClass = getSourceClass(_.get(customer, 'source', activity.source), _.get(customer, 'mediums'))

    if(activity.transaction_type) {
      // if transaction_type exist then we will transaction_type as a label for the activity
      label = activity.transaction_type
    }

    // replace '_'  with ' ' and '/' with '' in the label
    label = label.replace(/_/g, ' ').replace(/\//g, '')
    return {
      ...activity,
      activity_day: moment(activity.activity_date).format('YYYY-MM-DD'),
      label,
      sourceClass
    }
  })

  return interactions
}

/**
 * gets order status of a particular order
 * @param {*} order
 */
export function getStatus(order) {
  const minutesDiff = order.start_at ? moment().diff(order.start_at, 'minutes') : null
  //TODO: Update this when backend is ready to render the data.
  let status = minutesDiff > 0 ? ORDER_STATUSES.COMPLETED : ORDER_STATUSES.PENDING
  //if minuestesDill is null, we will assign status as '-'
  status = minutesDiff ? status : '-'
  return status
}
