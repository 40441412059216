import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../../../../FormComponents'
import { types as buttonTypes } from '../../../../../FormComponents/Button'
import './styles.scss'

/**
 * Journey creation: Node representation of a trigger unit
 *
 * @component
 * @example
 *
 * const trigger = {
 *   value: 'signup',
 *   label: 'Sign up'
 * }
 *
 * const handleClick = () => {
 *   // whatever needed to do when user
 *   // clicks on "Edit trigger" button
 * }
 *
 * return (
 *   <TriggerNode trigger={ trigger } onEditTriggerClick={ handleClick } />
 * )
*/
const TriggerNode = props => {
  const {
    trigger,
    onEditTriggerClick
  } = props

  const handleEditTriggerClick = () => {
    onEditTriggerClick()
  }

  return (
    <div className='eg-msg-trigger-node'>
      <div className='content'>
        <div className='side-icon'>
          <div className='vline' />
        </div>
        <div className='text'>
          <div className='name'>Journey trigger</div>
          <div className='description'>{ trigger.label }</div>
        </div>
      </div>
      <div className='action'>
        <Button
          className='btn-edit'
          type={ buttonTypes.SECONDARY }
          text='EDIT TRIGGER'
          onClick={ handleEditTriggerClick }
        />
      </div>
    </div>
  )
}

TriggerNode.propTypes = {
  trigger: PropTypes.object,
  onEditTriggerClick: PropTypes.func
}

export default TriggerNode
